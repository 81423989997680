import "./LoginForm.css";
import { Link } from "react-router-dom";

import {
  ButtonMob,
  NuevaAxiosURL,
  ModalInfo,
  userType,
  Loadingspinner,
  basename,
  entrenadorType,
  laptop575,
} from "../utils/utils";
import imgarriba from "../assets/img/Auth_images/arribaimagen.png";
import imgabajo from "../assets/img/Auth_images/abajoimagen.png";

import button1 from "../assets/img/Auth_images/Icon awesome-arrow-circle-left.png";
import button2 from "../assets/img/Auth_images/Icon awesome-arrow-circle-left.png";
import axios from "axios";

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import logo from "../assets/img/general/logo_alta.png";

import logo_blanco1 from "../assets/img/Auth_images/Grupo 100.png";

const LoginForm = (props) => {
  const type = props.type;

  const [displayError, setDisplayError] = useState("none");
  const [actualIconEye, setActualIcon] = useState(faEye);
  const [showOrNo, setShow] = useState("password");
  const [recover, setRecover] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(null);
  const [autenticado, setautenticado] = useState("norefrescado");
  const [modalInfo, setModalInfo] = useState(null);
  function hideModal() {
    setModalInfo(null);
  }
  const handleShowPass = () => {
    if (actualIconEye == faEye) {
      setActualIcon(faEyeSlash);
      setShow("text");
    } else {
      setActualIcon(faEye);
      setShow("password");
    }
  };

  const handleSubmit = (event) => {
    const usuario = event.target[0].value.trim();
    const password = event.target[1].value.trim();
    const servicio = "users/auth/login/";

    setDisplayLoading(
      <div className="my-3">
        <Loadingspinner customStyle="grow" size="2rem" />
      </div>
    );

    var formData = new FormData();
    formData.append("email", usuario);
    formData.append("password", password);
    axios({
      method: "post",
      contentType: "application/json",
      url: `${NuevaAxiosURL}${servicio}`,
      params: {
        t: new Date().getTime(),
      },
      data: formData,
    })
      .then((res) => {
        const data = res.data;

        if (data) {
          if (autenticado == "norefrescado") {
            localStorage.my_code = data.access;
            localStorage.refresh = data.refresh;
            localStorage.type = type;

            const instanceLogin = axios.create({
              baseURL: NuevaAxiosURL,
              params: {
                t: new Date().getTime(),
              },
              headers: { Authorization: "Bearer " + data.access },
            });

            instanceLogin.get("users/").then((response) => {
              let data_user = response.data;

              if (data_user.type == 20 && type == "Entrenador") {
                if (data_user.trainer.is_enabled) {
                  document.location.href = `${basename}/${type}/inicio`;
                } else {
                  setModalInfo(
                    <ModalInfo
                      error="none"
                      handleClick={() =>
                        localStorage.clear() ||
                        (document.location.href = `${basename}/`)
                      }
                      title="Hola entrenador"
                      text={
                        "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
                      }
                      classesMsg=""
                    />
                  );
                }
              } else if (data_user.type == 10 && type == "Usuario") {
                document.location.href = `${basename}/${type}/reservas`;
              } else {
                localStorage.clear();

                setModalInfo(
                  <ModalInfo
                    error="none"
                    handleClick={() =>
                      (document.location.href = `${basename}/`)
                    }
                    title="Error de ingreso"
                    text={"Tu tipo de ingreso es incorrecto"}
                    classesMsg=""
                  />
                );
              }
            });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          localStorage.clear();

          setDisplayError("inherit");
          setTimeout(() => {
            setDisplayError("none");
          }, 5000);
          setDisplayLoading(null);
        }
      });

    event.preventDefault();
  };

  return (
    <div className={`LoginFormContainer14_${type}_ingreso`}>
      <img className="imgarriba" src={imgarriba} />
      <img className="imgabajo" src={imgabajo} />
      <div className=" marco">
        <img src={logo_blanco1} className="login_area_logo1" alt="fondo" />
      </div>
      <div className="LoginFormContainer4">
        {!recover ? (
          <>
            <Link to={`/`}>
              <div id="position-absolute10">
                <div style={{ cursor: "pointer" }}>
                  <img
                    src={button2}
                    style={{ margin: "-7px 12px", width: "1.5vw" }}
                  />
                  Volver al inicio
                </div>
              </div>
            </Link>
            <Link to={`${basename}/`}>
              <img src={logo} className="login_logo_new" alt="logo" />
            </Link>

            <div className="flexusuario">
              <div className="SubTitle8">Ingreso {type}</div>
              <div className="lineflex"> </div>
            </div>
            <div className="flexusuariomobil">
              <div className="SubTitle8mobil">Ingreso {type}</div>
            </div>
            <h1 className="titleBienve">Bienvenido</h1>
            <div className="textodebajo12">
              Gestiona y programa tu acondicionamiento físico
            </div>
            <form
              className="LoginForm Registro"
              onSubmit={(e) => handleSubmit(e)}
            >
              <label htmlFor="mail" className="form-label">
                Correo Electrónico
              </label>
              <input
                type="email"
                placeholder="ejemplo@tucorreo.com"
                name="mail"
                id="mail"
                className="form-control"
                required
              ></input>

              <div
                id="passmail_error"
                className=""
                style={
                  laptop575.matches
                    ? {
                        display: displayError,
                        color: "#CB3D82",
                        fontWeight: "400",
                      }
                    : {
                        display: displayError,
                        color: "#CB3D82",
                        fontWeight: "400",
                        fontSize: "3.5vw",
                      }
                }
              >
                Correo y/o contraseña incorrectas.
              </div>
              <label htmlFor="pass" className="form-label">
                Contraseña
              </label>
              <div className="input-group">
                <input
                  type={showOrNo}
                  placeholder="************"
                  name="pass"
                  id="pass"
                  className="form-control"
                  required
                ></input>
                <span
                  className="input-group-text"
                  style={{
                    borderBottom: "1px solid #BEBEBE",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    paddingBottom: "5px",
                    color: "#CB3D82",
                    backgroundColor: "transparent",
                    fontSize: "1.5rem",
                  }}
                  onClick={handleShowPass}
                >
                  <FontAwesomeIcon icon={actualIconEye} />
                </span>
              </div>
              {displayLoading}
              {type == userType ? (
                <div
                  onClick={() => setRecover(true)}
                  className="olvido_pass d-flex  mt-1"
                >
                  <u> ¿Olvidaste tu contraseña?</u>
                </div>
              ) : null}
             
                <div
                  onClick={() => setRecover(true)}
                  className="olvido_pass d-flex  mt-4"
                >
                  <u> ¿Olvidaste tu contraseña?</u>
                </div>
          
              <div className="d-flex justify-content-center ">
                <ButtonMob
                  colorButton={"#CB3D82"}
                  text="Ingresar"
                  className="my-3 d-flex justify-content-center Botonentrar  "
                />
              </div>

              {type == userType ? (
                <div className="registro_nuevo d-flex justify-content-center">
                  ¿Eres nuevo?&nbsp;
                  <Link to={`/registro/${type}`}>
                    <u>crea tu cuenta aquí</u>
                  </Link>
                </div>
              ) : null}
              {type == entrenadorType ? (
                <div className="registro_nuevo d-flex justify-content-center">
                  ¿Eres nuevo?&nbsp;
                  <Link to={`/registro/${type}`}>
                    <u>Envíanos tus datos</u>
                  </Link>
                </div>
              ) : null}
            </form>
          </>
        ) : (
          <Recuperar recuperado={() => setRecover(false)} />
        )}
      </div>
      <div
        className="position-absolute  meroproblem"
        id="position-absolute"
        style={{
          color: "#8B8B8B",
          top: "4%",
          left: "8%",
          width: "50%",
        }}
      >
        <Link
          to={`/`}
          // onClick={() => (window.location.href = `${basename}/ingreso`)}
          style={{ cursor: "pointer" }}
          className="inicioregistroproblem d-flex"
        >
          <img src={button1} height="20px" />{" "}
          <div
            style={{
              margin: "auto 0 auto 5px",
              fontSize: "3.5vw",
              fontWeight: "700",
              color: "#7B86FA",
            }}
          >
            Volver al inicio
          </div>
        </Link>
      </div>
      {modalInfo}
    </div>
  );
};

const Recuperar = (props) => {
  const [displayError, setDisplayError] = useState("none");
  const [displayCorrect, setDisplayCorrect] = useState("none");

  const handleSubmit = (event) => {
    const email = event.target[0].value.trim();

    var formData = new FormData();
    formData.append("email", email);
    axios
      .post(`${NuevaAxiosURL}users/auth/forgot-password/`, formData)
      .then((res) => {
        const data = res.status;
        if (data == 200 || data == 202) {
          setDisplayCorrect("inherit");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      })
      .catch((err) => {
        setDisplayError("inherit");
        setTimeout(() => {
          setDisplayError("none");
        }, 5000);
      });

    event.preventDefault();
  };
  return (
    <>
      <div onClick={() => (window.location.href = `${basename}/ingreso`)}>
        <img src={logo} className="login_logo_new" alt="logo" />
      </div>
      <Link to={`/`}>
        <div id="position-absolute10">
          <div style={{ cursor: "pointer" }}>
            <img
              src={button2}
              style={{ width: "1.5vw", fontWeight: "700" }}
              alt="sd_imd"
            />{" "}
            Volver al inicio
          </div>
        </div>
      </Link>
      <div className="LoginFormContainer5">
        <h1 className="titlerecu2">Recuperar contraseña</h1>
        <div className="textodebajo">
          Introduce tu correo electrónico el cual tienes asociado para poder
          cambiar tu contraseña.
        </div>
        <form className="LoginForm" onSubmit={handleSubmit}>
          <label htmlFor="mail" className="form-label">
            Correo Electrónico
          </label>
          <input
            type="email"
            placeholder="correo@electronico.com"
            name="mail"
            id="mail"
            className="form-control"
            required
          ></input>
          <div
            id="passmail_error"
            className=""
            style={{
              display: displayError,
              color: "#CB3D82",
              fontWeight: "400",
            }}
          >
            El correo no es valido o no esta registrado
          </div>
          <div
            id="passmail_correct"
            className=""
            style={{ display: displayCorrect, color: "#9EACED" }}
          >
            El correo fue enviado.
          </div>

          <div className="info">
            Te enviamos un link a tu correo para recuperar tu cuenta
          </div>
          <div className="d-flex justify-content-center">
            <ButtonMob
              colorButton={"#CB3D82"}
              text="Enviar"
              className="my-5 d-flex justify-content-center Botonentrar"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
