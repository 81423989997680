import { combineReducers } from "redux";
import actualBook from "./actualBook";
import customerBooks from "./customerBookings";
import action_trainer from "./reducerBookingTrainer";
import rootReducer from "./reducerNotification";
import action_info_customer from "./reducer_info_customer";

const reducer = combineReducers({
  notifications: rootReducer,
  trainerbookings: action_trainer,
  trainerActualBook: actualBook,
  customerBookings: customerBooks,
  data_customer: action_info_customer,
});
export default reducer;
