import { UPDATE_DATA_CUSTOMER } from "../types";
import { promise_four } from "./promiseBookingsclient";
function customers_bookings(finislongdays, inicioday) {
  const promise_fourCustomer = promise_four(finislongdays, inicioday);
  return async (dispatch) => {
    promise_fourCustomer
      .then((info) => {
        if (info.length > 0) {
          dispatch({ type: UPDATE_DATA_CUSTOMER, payload: info });
        } else {
          dispatch({ type: UPDATE_DATA_CUSTOMER, payload: [] });
        }
      })
      .catch((err) => console.error(err));
  };
}
export { customers_bookings };
