import Media from "react-media";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import logo_eleva from "../assets/img/reservasimages/Logo eleva_giro.png";
import orientation from "../assets/img/reservasimages/orientation.jpg";
import giro1 from "../assets/img/reservasimages/giroTop.png";
import giro2 from "../assets/img/reservasimages/giroBot.png";

import fondo from "../assets/img/tipos_ejercicios.png";

import lupa from "../assets/img/reservasimages/lupa.png";

import camera from "../assets/img/reservasimages/camera.png";
import wifi from "../assets/img/reservasimages/wifi.png";
import mic from "../assets/img/reservasimages/mic.png";
import system from "../assets/img/reservasimages/system.png";
import check from "../assets/img/reservasimages/check.png";

import myCamera from "../assets/img/reservasimages/myCamera.png";
import customAvatar from "../assets/img/reservasimages/customAvatar.png";
import logo from "../assets/img/reservasimages/logo.png";

import "./Clase.css";
import { useEffect, useRef, useState } from "react";

import {
  showOrHidePanel,
  rtc,
  instance,
  disconnectPeer,
  connectPeerToPeer,
  connectPeerToPeerUser,
  ContenedorClase,
  fancyTimeFormat,
  ModalInfo,
  Panel,
  SetModalError,
  userType,
  timeOutcheckVideo,
  timeOutcheckAudio,
  toCheckRemoteAudioWebView,
  ButtonMob,
  Loadingspinner,
  basename,
} from "../utils/utils";
import axios from "axios";

import { RtcTokenBuilder, RtcRole } from "agora-access-token";
import AgoraRTC from "agora-rtc-sdk-ng";
import star from "../Perfil/iconos/star-unfill.png";
import starfill from "../Perfil/iconos/star-fill.png";

import flama from "../assets/img/reservasimages/flama.png";
import corazon from "../assets/img/reservasimages/corazon.png";
import cerrar from "../assets/img/reservasimages/Grupo 6107.png";
import Moment from "moment";
import "moment/locale/es";
import { extendMoment } from "moment-range";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation, Pagination } from "swiper";

import { Slider } from "@material-ui/core";

import { ThemeProvider } from "@material-ui/styles";

import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import muted from "../assets/img/svgcall/SonidoOff.svg";
import noMuted from "../assets/img/svgcall/SonidoOn.svg";
import videoOn from "../assets/img/svgcall/videoOn.svg";
import videoOff from "../assets/img/svgcall/videoOff.svg";
import headerWait from "../assets/img/svgcall/headerWait.svg";
import { ComprobarClase } from "./Clase";

SwiperCore.use([Navigation]);

const Clasestream = (props) => {
  AgoraRTC.setLogLevel(3);
  const [isChecked, setChecked] = useState(null);
  const user = props.user;
  const history = useHistory();
  const imagenCompanies = props.imagenCompanies || null;
  useEffect(() => {
    const checked = localStorage.checked;
    if (checked) {
      const now = Date.now();
      const diff = now - checked;
      if (diff <= 1800000) {
        // Less than 30 min, so no check again
        setChecked(true);
      } else {
        setChecked(false);
      }
    } else setChecked(false);
  }, []);

  return (
    <>
      <Media
        query="(max-width: 470px)"
        render={() => (
          <div
            className="claseFondo"
            // style={{ backgroundImage: `url(${orientation})` }}
          >
            <div
              style={{
                background: "#7886F5",
                color: "white",

                textAlign: "center",
              }}
            >
              <img
                src={logo_eleva}
                alt="giro"
                width={100}
                className="m-3 p-auto"
              />
            </div>
            <div className="container px-5">
              <div className="info">
                <img src={giro1} alt="giro" />
                <br />
                <div style={{ color: "#9EACED" }}>
                  Tu sesión de entrenamiento Iniciara en unos segundos.
                </div>
                <br />
                <br />
                <img src={giro2} alt="giro" />
                <br />
                <div style={{ color: "#9EACED" }}>
                  Por favor gira tu celular para iniciarla correctamente.
                </div>
              </div>
            </div>
            <div
              className="position-absolute fw-bold m-auto"
              style={{ color: "white", top: "1%", left: "2%" }}
            >
              <div
                onClick={() => history.goBack()}
                style={{ cursor: "pointer", margin: "14% auto" }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="2x" color="white" />
              </div>
            </div>
          </div>
        )}
      />
      <Media
        query="(min-width: 471px)"
        render={() => (
          <>
            <div
              className="containerBGClase"
              style={{ backgroundImage: `url(${fondo})` }}
            ></div>
            {isChecked === true ? (
              <ComenzarClase user={user} imagenCompanies={imagenCompanies} />
            ) : null}
            {isChecked === false ? (
              <ComprobarClase
                onChecked={(inf) => setChecked(inf)}
                isChecked={isChecked}
              />
            ) : null}
          </>
        )}
      />
    </>
  );
};

const ComenzarClase = (props) => {
  const moment = extendMoment(Moment);
  const imagenCompanies = props.imagenCompanies || null;
  const myPanelTab = useRef(null);
  const myPanel = useRef(null);

  const refRutinas = useRef(null);

  const { reserva } = useParams();
  const user = props.user;

  const [idClase, setIdClase] = useState(null);

  const [nombreCliente, setNombreCliente] = useState("Jhon Doe");
  const [estrellas, setEstrellas] = useState(1);
  const [avatar, setAvatar] = useState(null);

  const [modalInfo, setModalInfo] = useState(null);

  const submitCancelarClase = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui CancelarReserva">
            <h1 className="title text-center">Cancelar la clase</h1>
            <div className="msg text-center" style={{ fontSize: "2vw" }}>
              ¿Estas seguro de cancelar la clase?
            </div>
            <div className="buttons">
              <button
                className="No"
                onClick={onClose}
                style={{
                  width: "100%",
                  padding: "auto",
                }}
              >
                <strong>No</strong> quiero cancelar
              </button>
              <button
                style={{
                  width: "100%",
                  padding: "auto",
                }}
                className="Si"
                onClick={async () => {
                  const resC = await instance.post(
                    `booking/book/cancel/${reserva}`
                  );
                  onClose();

                  window.location.href = `/${userType}/inicio`;
                }}
              >
                Cancelar el entrenamiento
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const modalShowed = useRef(false);
  useEffect(() => {
    AgoraRTC.getDevices().then((devices) => {
      const audioDevices = devices.filter(function (device) {
        return device.kind === "audiooutput" || device.kind === "audioinput";
      });
    });
    AgoraRTC.getMicrophones().then((devices) => {
      const audioDevices1 = devices.filter(function (device) {
        return device.kind === "audiooutput";
      });
      const audioDevices2 = devices.filter(function (device) {
        return device.kind === "audioinput";
      });
    });

    async function conectarclase() {
      await instance
        .get(`/booking/book/prepare/${reserva}`)
        .then((resEn) => {
          const data = resEn.data;
          if (data) {
            setNombreCliente(data.trainer.full_name);
            setEstrellas(data.trainer.current_rating);
            setAvatar("https://api.elevva.com.co" + data.trainer.profile_pic);

            instance
              .post(`booking/book/start/${reserva}`)
              .then((res) => {
                const info = res.data;

                if (info) {
                  if (info.status == 60) {
                    localStorage.roomId = info.room_id;

                    if (!modalShowed.current) {
                      modalShowed.current = true;
                      setModalInfo(
                        <div className="containerComprobar">
                          <div className="containerModal_eleva_class">
                            <img
                              src={headerWait}
                              alt="header"
                              className="iconHeaderWait"
                            />
                            <div className="title scroll">
                              Hola atleta, tu profesor esta preparando
                              <br />
                              tu clase y en un momento ingresará.
                            </div>
                            <div className="items scroll">
                              <div
                                className="recomen"
                                style={{ overflow: "hidden" }}
                              >
                                Recuerda al ingresar a tu clase, verificar que
                                los iconos de audio y video en la parte inferior
                                de esta pantalla se encuentren activos.
                                <br />
                                <br />
                                <div className="iconsParentClase">
                                  <div className="iconsAfterClase">
                                    <div>
                                      <img src={videoOff} alt="video off" />
                                      <img src={muted} alt="sonido off" />
                                    </div>
                                    <span>Inactivos</span>
                                  </div>
                                  <div className="iconsAfterClase">
                                    <div>
                                      <img src={videoOn} alt="video on" />
                                      <img src={noMuted} alt="sonido on" />
                                    </div>
                                    <span>Activos</span>
                                  </div>
                                </div>
                              </div>
                              <div className="cancelar">
                                Tenemos una variedad de contenido al que puedes
                                acceder con un click, rétate a conocerte a ti
                                mismo !
                                <br />
                                {/* <button
                                  style={{
                                    background: "#CB3D82",
                                    padding: "0.8vw",
                                    fontWeight: "bold",
                                  }}
                                  onClick={submitCancelarClase}
                                >
                                  Cancelar Entrenamiento
                                </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }

                    setTimeout(() => {
                      conectarclase();
                    }, 5000);
                  } else {
                    setModalInfo(null);
                    const objcStart = {
                      id_clase: info.room_id,
                      uid: user.customer.user,
                      nameClient: user.firts_name,
                      reserva: reserva,
                      classType: "stream",
                      type: 10,
                      agoraToken: info.agora_token,
                    };
                    connectPeerToPeerUser(objcStart, true);
                    setIdClase(reserva);
                  }
                }
              })
              .catch((err) => {
                console.error(
                  "Error en api/clientes/reservas/conectar-clase",
                  err
                );
              });
          }
        })
        .catch((err) => {
          setModalInfo(
            <SetModalError handleClick={() => setModalInfo(null)} />
          );
        });
    }
    conectarclase();
    // returned function will be called on component unmount
    return () => {
      disconnectPeer();
    };
  }, []);

  const actualRutina = useRef(null);
  const actualTime = useRef(0);

  const [timeLeft, setTimeLeft] = useState(fancyTimeFormat(0));
  var intervalSearch = null;
  const [objReserva, setReserva] = useState(null);
  const [relojIniciar, setrelojIniciar] = useState(null);
  const [frecuenciaCustomer, setfrecuenciaCustomer] = useState(0);
  const [kcalCustomer, setkcalCustomer] = useState(0);

  useEffect(() => {
    intervalSearch = setInterval(() => {
      instance
        .get(`booking/book/${reserva}`)
        .then((resEn) => {
          const status_class = resEn.data.status;

          setrelojIniciar(status_class);
        })
        .catch((err) => console.log(err.response));
    }, 5000);
  }, []);
  let random = function () {
    return parseInt(Math.random() * 180);
  };
  useEffect(async () => {
    if (relojIniciar == 40) {
      setModalInfo(
        <CancelarClase
          user={user}
          step={2}
          imagenCompanies={imagenCompanies}
          idClase={idClase}
          reserva={reserva}
          noCancelar={noCancelar}
        />
      );
    } else if (relojIniciar == 30 && !localStorage.firstregisterclassstream) {
      var formData = new FormData();
      formData.append("percentage", 100);
      formData.append("heart_rate", -1);
      formData.append("workoutset", 10);
      formData.append("kcal", -1);

      instance
        .post(`booking/book/${reserva}/workoutlog/`, formData)
        .then((resEn) => {
          const status_class = resEn.data;
          if (status_class) {
            setfrecuenciaCustomer(status_class.heart_rate);
            setkcalCustomer(status_class.kcal);
            localStorage.firstregisterclassstream = "check";
          }
        })
        .catch((err) => console.log(err.response));
    }

    // }, 5000);
  }, [relojIniciar]);

  useEffect(() => {
    instance.get(`booking/book/${reserva}`).then((res) => {
      const data = res.data;
      const fecha = data.date.replace("T", " ").slice(0, 16);

      if (data != null) {
        setReserva({
          id: reserva,
          fecha: fecha,
        });
      }
    });
    return () => {
      clearInterval(timeOutcheckVideo); // clear the timer to check icon color
      clearInterval(timeOutcheckAudio); // clear the timer to check icon color
    };
  }, []);

  useEffect(() => {
    if (objReserva == null) return false;
    const fechaclar = moment(objReserva.fecha).format(
      "dddd DD [de] MMMM / HH:mm a"
    );
    const timeStart = moment();
    const timeEnd = moment(fechaclar, "dddd DD [de] MMMM / HH:mm a");
    timeEnd.add(1, "hour");
    const diff = timeEnd.diff(timeStart, "seconds");
    actualTime.current = diff;

    setInterval(() => {
      if (actualTime.current > 0) {
        actualTime.current -= 1;
        setTimeLeft(fancyTimeFormat(actualTime.current));
      }
    }, 1000);
  }, [relojIniciar == 30]);

  function cancelarClase() {
    showOrHidePanel(myPanel, myPanelTab, "left", true);
    setModalInfo(
      <CancelarClase
        imagenCompanies={imagenCompanies}
        user={user}
        idClase={idClase}
        reserva={reserva}
        noCancelar={noCancelar}
      />
    );
  }

  function noCancelar() {
    setModalInfo(null);
  }

  const paneles = (
    <>
      <Panel
        align="left"
        classNameContent="myPanel"
        contentRef={myPanel}
        tabRef={myPanelTab}
        tabCerrar={cerrar}
        tabAvatar={avatar ? avatar : customAvatar}
        classNameTab="myPanelTab"
      >
        <div className="logo" style={{ background: "#7886F5" }}>
          <img
            alt="logo"
            src={imagenCompanies ? imagenCompanies : logo_eleva}
            style={
              imagenCompanies
                ? { margin: "6% auto ", minWidth: "20vmin" }
                : { margin: "4% auto " }
            }
          />
        </div>
        <div className="imgEntrenador1">
          <img
            alt="av"
            src={avatar ? avatar : myCamera}
            className="rounded-circle"
          />
        </div>
        <div className="imgEntrenador">
          <div className="infoPeer align-center">
            <div className="name">{nombreCliente}</div>
            <div className="calificacionEntrenador">
              <img
                src={estrellas >= 1.0 ? starfill : star}
                alt="etrella"
                className=""
              />
              <img
                src={estrellas >= 2.0 ? starfill : star}
                alt="etrella"
                className=""
              />
              <img
                src={estrellas >= 3.0 ? starfill : star}
                alt="etrella"
                className=""
              />
              <img
                src={estrellas >= 4.0 ? starfill : star}
                alt="etrella"
                className=""
              />
              <img
                src={estrellas >= 5.0 ? starfill : star}
                alt="etrella"
                className=""
              />
            </div>
          </div>
        </div>

        <div className="Cardio infoClas ">
          <img src={corazon} alt="cardio" />
          <div className="datos">
            <span className="valor">
              {" "}
              {frecuenciaCustomer && frecuenciaCustomer > 0
                ? frecuenciaCustomer
                : "_ _ _ _"}
            </span>
            <br />
            Frecuencia cardiaca
          </div>
        </div>
        <div className="Calorias infoClas infoClas_F ">
          <img src={flama} alt="cardio" />
          <div className="datos">
            <span className="valor">
              {kcalCustomer && kcalCustomer > 0
                ? kcalCustomer.toFixed(0)
                : "_ _ _ _ "}
            </span>
            <br />
            Calorías quemadas
          </div>
        </div>
        <div className="textFinalizar">
          <div>¿Quieres finalizar tu sesión de entrenamiento?</div>
          Ten en cuenta que si finalizas tu sesión, no podrás volver a ingresar.
        </div>
        <div className="Finalizar">
          <button className="btnFinalizar" onClick={cancelarClase}>
            Finalizar sesión
          </button>
        </div>
      </Panel>
      <div
        className="tiempo"
        style={{
          background: "rgb(38,38,38,0.7)",
          borderRadius: "7px",
          position: "fixed",
          width: "16%",
          textAlign: "center",
          padding: "0.5vw 0",
          right: "0 ",
          zIndex: "999999",
          fontSize: "4vw",
          fontWeight: "700",
          fontWeight: "bold",
          color: "white",
        }}
      >
        <span>{timeLeft}</span>
        <br />
        <div
          style={{
            fontSize: "1vw",
            fontWeight: "700",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {" "}
          Tiempo de sesión
        </div>
      </div>
    </>
  );
  return (
    <>
      <ContenedorClase paneles={paneles} clientestream={true} />
      {modalInfo}
    </>
  );
};

const CancelarClase = (props) => {
  const reserva = props.reserva;
  const step = props.step || null;
  const imagenCompanies = props.imagenCompanies || null;
  const [msgCancelar, setMsgCancelar] = useState("");
  const [nivelCancelar, setNivelCaneclar] = useState(10);
  const [setp, setStep] = useState(step ? step : 1);
  const [loadingCancelar, setLoadingCancelar] = useState(null);

  function noCancelar() {
    props.noCancelar();
  }
  function valuetext(value) {
    if (value == 1) return `Facil`;
    else if (value == 3) return `Adecuado`;
    else if (value == 5) return `Muy dificil`;
    return "No deberia salir";
  }

  function renderStars(refStep) {
    return (
      <div className="estrellas" ref={refStep} value={0}>
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s1"
          onClick={() => setStars(refStep, 1) || NextSlider()}
        />

        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s2"
          onClick={() => setStars(refStep, 2) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s3"
          onClick={() => setStars(refStep, 3) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s4"
          onClick={() => setStars(refStep, 4) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s5"
          onClick={() => setStars(refStep, 5) || NextSlider()}
        />
      </div>
    );
  }

  function setStars(theRef, pos) {
    theRef.current.setAttribute("value", pos);

    for (let index = 1; index <= 5; index++) {
      const estrella = theRef.current.querySelector("#s" + index);
      if (pos >= index) estrella.src = starfill;
      else estrella.src = star;
    }
  }
  const [theSwipper, setTheSwipper] = useState(null);
  function NextSlider() {
    theSwipper.slideNext();
  }
  async function cancelarDefinitivo() {
    setLoadingCancelar(<Loadingspinner customStyle="grow" size="5rem" />);

    const array = [];
    array.push(
      nivelCancelar,
      Number(refPresentacion.current.getAttribute("value")),
      Number(refLugar.current.getAttribute("value")),
      Number(refConocmiento.current.getAttribute("value")),
      Number(refEnergia.current.getAttribute("value"))
    );

    instance
      .post(`/booking/book/finish/${reserva}`, {
        comment: msgCancelar,
        answers: array,
        hr: -1,
      })

      .then((res) => {
        if (res.data) {
          localStorage.removeItem("firstregisterclassstream");
          if (imagenCompanies) {
            window.location.href = `${basename}/ejercicios/resumen/${reserva}`;
          } else {
            window.location.href = `${basename}/${userType}/resumen/${reserva}`;
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingCancelar(
          <div style={{ color: "red" }}>
            Ocurrio un error al momento de finalizar
          </div>
        );
        setTimeout(() => {
          setLoadingCancelar(null);
        }, 3000);
      });
  }

  const refPresentacion = useRef(null);
  const refLugar = useRef(null);
  const refConocmiento = useRef(null);
  const refEnergia = useRef(null);

  return (
    <>
      <div className="containerFinalizar">
        <div className="containerModal">
          {setp == 1 ? (
            <>
              <form onSubmit={(e) => setStep(2)}>
                <h1 className="title">Finalizar sesión</h1>
                <div className="subtitle">
                  Cuéntanos como estuvo tu
                  <br />
                  sesión de entrenamiento.
                </div>
                <div className="observaciones">
                  <textarea
                    placeholder="Escribe aquí tu motivo"
                    rows={7}
                    required
                    onInput={(e) => setMsgCancelar(e.target.value)}
                  ></textarea>
                </div>
                <div className="botones">
                  <button className="sifin me-2 w-50">
                    Confirmar finalización
                  </button>
                  <button className="nofin w-50" onClick={noCancelar}>
                    No deseo finalizar
                  </button>
                </div>
              </form>
            </>
          ) : setp == 2 ? (
            <>
              <div className="swiper-pagination"></div>
              <h2 className="title">Ayudanos a mejorar</h2>
              <p className="subtitle">
                Responde estas 5 preguntas que nos permiten
                <br />
                mejorar continuamente nuestro servicio.
              </p>
              <Swiper
                pagination={{
                  el: ".swiper-pagination",
                  type: "custom",
                  renderCustom: function (swiper, current, total) {
                    if (theSwipper == null) {
                      setTheSwipper(swiper);
                    }
                  },
                }}
                spaceBetween={10}
                slidesPerView={"auto"}
                navigation
                allowTouchMove={false}
                className="preguntas"
                onSlideChange={(sp) => {
                  const toolt = document.querySelector(".MuiTooltip-popper");
                  if (toolt) {
                    if (sp.activeIndex == 0) {
                      toolt.classList.add("visible");
                      toolt.classList.remove("invisible");
                    } else {
                      toolt.classList.add("invisible");
                      toolt.classList.remove("visible");
                    }
                  }
                }}
              >
                <SwiperSlide className="pregunta">
                  <div>
                    ¿Cómo estuvo el nivel de dificultad del entrenamiento?
                    <br />
                    <br />
                  </div>
                  <div className="d-flex justify-evenly ">
                    <ButtonMob
                      colorButton={"#9EACED"}
                      className={`${
                        nivelCancelar == 10 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Fácil"}
                      onClick={() => setNivelCaneclar(10) || NextSlider()}
                    />
                    <ButtonMob
                      colorButton={"#9EACED"}
                      className={`${
                        nivelCancelar == 20 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Adecuado"}
                      onClick={() => setNivelCaneclar(20) || NextSlider()}
                    />
                    <ButtonMob
                      colorButton={"#9EACED"}
                      className={`${
                        nivelCancelar == 30 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Dificil"}
                      onClick={() => setNivelCaneclar(30) || NextSlider()}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div>La presentación personal del profesor fue</div>
                  {renderStars(refPresentacion)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div>
                    El espacio, ambiente y lugar en el que el profesor dicto la
                    clase fue
                  </div>
                  {renderStars(refLugar)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div>
                    El profesor demostró conocimiento y experiencia durante la
                    clase
                  </div>
                  {renderStars(refConocmiento)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div>
                    ¿El profesor durante el entrenamiento trasmitió una buena
                    energía y motivación?
                  </div>
                  {renderStars(refEnergia)}

                  <div className="botones">
                    {loadingCancelar ? (
                      loadingCancelar
                    ) : (
                      <button
                        className="sifin me-2 "
                        onClick={cancelarDefinitivo}
                      >
                        Confirmar finalización
                      </button>
                    )}
                  </div>
                </SwiperSlide>
              </Swiper>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Clasestream;

// export const ComprobarClase = (props) => {
//   const isChecked = props.isChecked;

//   const refSystem = useRef(null);
//   const refMic = useRef(null);
//   const refCamera = useRef(null);
//   const refWifi = useRef(null);
//   const [modalInfo, setModalInfo] = useState(null);
//   function hideModal() {
//     setModalInfo(null);
//   }

//   useEffect(() => {
//     async function checkSystem() {
//       if (refSystem.current == null) return false;
//       let timerSpeed = null;

//       var imageAddr =
//         "https://api.mob.fitness/uploads/trainings/mains/img3_xbrDtFw.jpg";
//       var downloadSize = 4995374; //bytes
//       function ShowProgressMessage(msg) {
//         if (console) {
//           if (typeof msg == "string") {
//           } else {
//             for (var i = 0; i < msg.length; i++) {
//               console.log(msg[i]);
//             }
//           }
//         }
//       }

//       function InitiateSpeedDetection() {
//         ShowProgressMessage("Loading the image, please wait...");
//         window.setTimeout(MeasureConnectionSpeed, 1);
//       }

//       function MeasureConnectionSpeed() {
//         var startTime, endTime;
//         var download = new Image();
//         download.onload = function () {
//           endTime = new Date().getTime();
//           showResults();
//         };
//         download.onerror = function (err, msg) {
//           ShowProgressMessage("Invalid image, or error downloading");
//         };
//         startTime = new Date().getTime();
//         var cacheBuster = "?nnn=" + startTime;
//         download.src = imageAddr + cacheBuster;
//         function showResults() {
//           var duration = (endTime - startTime) / 1000;
//           var bitsLoaded = downloadSize * 8;
//           var speedBps = (bitsLoaded / duration).toFixed(2);
//           var speedKbps = (speedBps / 1024).toFixed(2);
//           var speedMbps = (speedKbps / 1024).toFixed(2);
//           ShowProgressMessage([
//             "Your connection speed is:",
//             speedBps + " bps",
//             speedKbps + " kbps",
//             speedMbps + " Mbps",
//           ]);
//           if (speedMbps >= 1) {
//             console.log(
//               `Su velocidad (${speedMbps} Mbps) es aceptable para la clase.`
//             );
//           } else {
//             console.log(
//               `Su velocidad (${speedMbps} Mbps) no es recomendable para la clase.`
//             );
//             setModalInfo(
//               <ModalInfo
//                 error="true"
//                 handleClick={hideModal}
//                 title="Tu ancho de banda no es el adecuado para la clase."
//                 text="Es posible que experimentes caídas de señal durante la clase."
//                 classesMsg=""
//               />
//             );
//           }
//           localStorage.setItem("checked", Date.now());
//           clearTimeout(timerSpeed);
//           checked();
//         }
//       }

//       const handleDeviceError = (err, customMsg) => {
//         let reload = false;
//         let title = "Ocurrio un error";
//         let msg =
//           "Ocurrio un error al momento de obtener información del dispositivo";
//         if (err && err.code === "PERMISSION_DENIED") {
//           msg = "Debes permitir el acceso al dispositivo desde el navegador.";
//           reload = true;
//         }
//         if (customMsg) {
//           msg = customMsg;
//           if (customMsg.includes("correcta del micrófono")) {
//             reload = true;
//           }
//         }
//         if (reload === true) {
//           setModalInfo(
//             <ModalInfo
//               error="true"
//               handleClick={() => window.location.reload()}
//               title={title}
//               text={msg + (err ? "-" + err.code : "")}
//               classesMsg=""
//             />
//           );
//         } else {
//           setModalInfo(
//             <ModalInfo
//               error="true"
//               handleClick={hideModal}
//               title={title}
//               text={msg}
//               classesMsg=""
//             />
//           );
//         }
//         console.log("error", err);
//       };

//       const createVideo = async (camID) => {
//         const selected = document.querySelector("#listVideo");
//         const videoDiv = document.querySelector("#testDevice");
//         selected.setAttribute("disabled", true);
//         videoDiv.style = "width: 440px; height: 280px; display: none";
//         // console.log(camID);

//         const localTestDevice = await AgoraRTC.createCameraVideoTrack({
//           cameraId: camID,
//         }).catch((e) => handleDeviceError(e, "createCameraVideoTrack"));

//         localTestDevice.play("testDevice");
//         const checkVideo = await AgoraRTC.checkVideoTrackIsActive(
//           localTestDevice
//         ).catch((e) => handleDeviceError(e, "checkVideoTrackIsActive"));
//         // console.log(`${camID} is ${checkVideo ? "available" : "unavailable"}`);
//         localTestDevice.stop();
//         localTestDevice.close();
//         if (checkVideo) {
//           // all ok
//           rtc.selectedCamId = camID; // Save the cam selected to use later in current lesson
//           return true;
//         } else {
//           selected.value = "";
//           selected.removeAttribute("disabled");
//           return false;
//         }
//       };

//       const createAudio = async (audioID) => {
//         const selected = document.querySelector("#listMicro");
//         const audioDiv = document.querySelector("#testDevice");
//         audioDiv.innerHTML = "";
//         selected.setAttribute("disabled", true);
//         const localTestDevice = await AgoraRTC.createMicrophoneAudioTrack({
//           microphoneId: audioID,
//         }).catch((e) => handleDeviceError(e, "createMicrophoneAudioTrack"));
//         localTestDevice.setVolume(200);
//         const checkAudio = await AgoraRTC.checkAudioTrackIsActive(
//           localTestDevice
//         ).catch((e) => handleDeviceError(e, "checkAudioTrackIsActive"));
//         localTestDevice.stop();
//         localTestDevice.close();
//         audioDiv.style = "";
//         audioDiv.innerHTML = "";
//         if (checkAudio) {
//           // all ok
//           rtc.selectedMicId = audioID;
//           return true;
//         } else {
//           selected.value = "";
//           selected.removeAttribute("disabled");
//           return false;
//         }
//       };

//       const checkImg = document.createElement("img");
//       checkImg.src = check;
//       checkImg.style.marginLeft = "1%";
//       checkImg.style.width = "3.5%";
//       //Check the system requirements
//       refSystem.current.style.visibility = "visible";
//       const checkSysReq = AgoraRTC.checkSystemRequirements();
//       if (checkSysReq) {
//         refSystem.current.querySelector(".text").append(checkImg.cloneNode());
//       }

//       const cameraDiv = refCamera.current;
//       //creamos el span para poner la camara ahí (No se verá)
//       const testDevice = document.createElement("span");
//       testDevice.id = "testDevice";
//       cameraDiv.append(testDevice);
//       //check the mic
//       const micDiv = refMic.current;
//       const selectMicro = document.createElement("select");
//       selectMicro.id = "listMicro";
//       selectMicro.className = "form-select";
//       const spanMic = micDiv.querySelector("span");
//       // y lo añadimos al span despues del texto
//       spanMic.append(selectMicro);
//       micDiv.style.visibility = "visible";

//       // const audioDevices = await AgoraRTC.getDevices().catch((e) =>
//       //   handleDeviceError(e, "getMicrophones")
//       // );
//       const audioDevices1 = await AgoraRTC.getDevices(function (devices) {
//         devices.filter(function (device) {
//           return device.kind === "audiooutput" || device.kind === "audioinput";
//         });
//       });
//       const audioDevices = await AgoraRTC.getMicrophones().catch((e) =>
//         handleDeviceError(e, "getMicrophones")
//       );

//       if (!audioDevices) {
//         // No encontro microfonos

//         return false;
//       }
//       if (audioDevices.length == 0) {
//         setModalInfo(
//           <ModalInfo
//             error="true"
//             handleClick={hideModal}
//             title="Lo sentimos, no hemos encontrado ningún micrófono."
//             text="Por favor verifica que este bien conectado y funcione correctamente."
//             classesMsg=""
//           />
//         );
//       }
//       let audioOk = false;
//       for (let [index, micro] of audioDevices.entries()) {
//         const option = document.createElement("option");
//         option.value = micro.deviceId;
//         option.text = micro.label;
//         if (micro.label === "") {
//           option.text = "Microfono " + (index + 1);
//         }
//         selectMicro.append(option);

//         if (index === 0) {
//           option.selected = true;
//           audioOk = await createAudio(micro.deviceId);

//           if (audioOk) {
//             micDiv.querySelector(".text").append(checkImg.cloneNode());
//           } else {
//             handleDeviceError(
//               null,
//               "No se pudo obtener información correcta del micrófono. ¿Hay salida de audio conectada?."
//             );
//             return false;
//           }
//         }
//       }

//       //Check the camera
//       //Creamos el select que tendra la lista de camaras coenctadas
//       const selectVideo = document.createElement("select");
//       selectVideo.id = "listVideo";
//       selectVideo.className = "form-select";
//       const spanCamera = cameraDiv.querySelector("span"); // y lo añadimos al span despues del texto
//       spanCamera.append(selectVideo);
//       cameraDiv.style.visibility = "visible";
//       const cameras = await AgoraRTC.getCameras().catch((e) =>
//         handleDeviceError(e, "getCameras")
//       );
//       if (!cameras) {
//         // No encontro las camarás

//         return false;
//       }
//       let videoOk = null;
//       if (cameras.length === 0) {
//         setModalInfo(
//           <ModalInfo
//             error="true"
//             handleClick={hideModal}
//             title="Lo sentimos, no hemos encontrado ninguna cámara."
//             text="Por favor verifica que este bien conectada y funcione correctamente."
//             classesMsg=""
//           />
//         );
//       } else {
//         for (let [index, camera] of cameras.entries()) {
//           const option = document.createElement("option");
//           option.value = camera.deviceId;
//           option.text = camera.label;
//           selectVideo.append(option);
//           //if (index === 0) {
//           option.selected = true;
//           videoOk = await createVideo(camera.deviceId);
//           if (videoOk) {
//             cameraDiv.querySelector(".text").append(checkImg.cloneNode());
//             break;
//           }
//           //}
//         }
//       }

//       if (videoOk) {
//         refWifi.current.style.visibility = "visible";
//         InitiateSpeedDetection();
//         timerSpeed = setTimeout(() => {
//           setModalInfo(
//             <ModalInfo
//               error="true"
//               handleClick={hideModal}
//               title="Tu ancho de banda no es el adecuado para la clase."
//               text="Es posible que experimentes caídas de señal durante la clase."
//               classesMsg=""
//             />
//           );
//           localStorage.setItem("checked", Date.now());
//           checked();
//         }, 7000);
//       } else {
//         handleDeviceError(null);
//       }
//     }
//     if (refSystem.current != undefined) {
//       if (refSystem.current != null) {
//         checkSystem();
//       }
//     }
//   }, [refSystem]);
//   const checked = () => {
//     props.onChecked(true);
//   };
//   if (isChecked) return <></>;
//   return (
//     <div className="containerComprobar">
//       <div className="containerModal_eleva_class">
//         <div className="iconTitle text-center my-1">
//           <img src={lupa} alt="lupa" className="lupa_clase_eleva" />
//         </div>
//         <div
//           className="title text-center"
//           style={{ color: "#394F65", fontSize: "4vmin" }}
//         >
//           Estamos comprobando que tu sistema funcione
//           <br />
//           <strong> adecuadamente para que tu experiencia sea la mejor.</strong>
//         </div>
//         <div className="items">
//           <div
//             className="row g-0 mt-2"
//             ref={refSystem}
//             style={{ visibility: "hidden" }}
//           >
//             <div className="col-2 icon">
//               <img src={system} alt="" className="iconItem" />
//             </div>
//             <div className="col-10 text">Requisitos del sistema:</div>
//           </div>
//           <div
//             className="row g-0 mt-2"
//             ref={refCamera}
//             style={{ visibility: "hidden" }}
//           >
//             <div className="col-2 icon "></div>
//             <div className="col-10">
//               Obteniendo lista de dispositivos de audio y video.
//               <br />
//               Permite el uso de los mismos.
//             </div>
//             <div className="col-2 icon ">
//               <img src={camera} alt="" className="iconItem" />
//             </div>
//             <div className="col-10 text d-inline-flex align-items-center">
//               Seleccionando una cámara:
//               <span></span>
//             </div>
//           </div>
//           <div
//             className="row g-0 mt-2"
//             ref={refMic}
//             style={{ visibility: "hidden" }}
//           >
//             <div className="col-2 icon ">
//               <img src={mic} alt="" className="iconItem" />
//             </div>
//             <div className="col-10 text d-inline-flex align-items-center">
//               Seleccionando un micrófono:
//               <span></span>
//             </div>
//           </div>
//           <div
//             className="row g-0 mt-2"
//             ref={refWifi}
//             style={{ visibility: "hidden" }}
//           >
//             <div className="col-2 icon ">
//               <img src={wifi} alt="" className="iconItem" />
//             </div>
//             <div className="col-10 text">
//               Obteniendo información del ancho de banda
//             </div>
//             <div className="text-center">
//               <div
//                 className="spinner-border"
//                 role="status"
//                 style={{ width: "4vmin", height: "4vmin" }}
//               >
//                 <span className="visually-hidden">Loading...</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {modalInfo}
//     </div>
//   );
// };
