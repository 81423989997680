import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  ButtonMob,
  instance,
  entrenadorType,
  filterCheckDays,
  laptop575,
} from "../../utils/utils";
import Moment from "moment";

import { extendMoment } from "moment-range";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import grupales_true from "../../assets/img/UserIcons/iconos/Grupo.png";
import individual from "../../assets/img/UserIcons/iconos/individual.png";
import calendarA from "../../assets/img/UserIcons/iconos/calendarA.png";
import calendarB from "../../assets/img/reservasimages/calendar.png";
import equiscl from "../../Entrenador/assets/icons/equisv.png";
import check from "../assets/icons/check.png";
import checkCircle from "../assets/icons/check_circle.png";
import calendar from "../../assets/img/reservasimages/calendar_trainer_eleva.png";
import pc from "../../assets/img/reservasimages/pc.png";
import wrong from "../assets/icons/wrong.png";
import info from "../assets/icons/info.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import "./InicioEntrenador.css";
import "../../User/Inicio.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/components/pagination/pagination.min.css";
import Media from "react-media";
import initReactFastclick from "react-fastclick";
import { useLocation } from "react-router-dom";

import { action_trainer } from "../../actions/booking_trainer";
import { actualBook } from "../../actions/actualBook";
import ReservasTrainers from "./reservasTrainers";
SwiperCore.use([Pagination, Autoplay]);

function InicioEntrenador(props) {
  if (localStorage.actualEjercicio || localStorage.actualRutinaTime) {
    localStorage.removeItem("actualEjercicio");
    localStorage.removeItem("actualRutinaTime");
  }
  const moment = extendMoment(Moment);
  const day_hoy = moment();
  // const mes_hoy = moment(day_hoy, "YYYY-MM").daysInMonth();
  const diaya = moment().format();

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    // console.log(d, d.toLocaleString("en-US", { timeZone: "America/New_York" }));
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const iniciodayr = moment().format().slice(0, 16);
  const finislongdays =
    moment().add(30, "days").format().slice(0, 11) + "00:00";

  const inicioday =
    day_hoy.subtract(day_hoy.day(), "days").format().slice(0, 11) + "00:00";
  const findesemana =
    day_hoy
      .add(7 - day_hoy.day(), "days")
      .format()
      .slice(0, 11) + "23:59";
  const iniciodayReservas = moment().format().slice(0, 11) + "00:00";
  const finislongdaysReserva =
    moment().add(30, "days").format().slice(0, 11) + "00:00";
  var date = new Date();
  var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);

  var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  initReactFastclick();
  const user = props.user;
  const [verificarTrainning, setverificarTrainning] = useState(false);
  const [infoDays, setInfoDays] = useState(null);
  const [idcobro, setidcobro] = useState([]);
  const [zonahorairachange, setZonahorairachange] = useState(null);

  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const { database } = state.trainerbookings;
  const { datagroup } = state.trainerActualBook;

  let intervalSearchs = null;
  useEffect(async () => {
    dispatch(actualBook(finislongdaysReserva, iniciodayReservas));
  }, []);
  useEffect(() => {
    dispatch(action_trainer(finislongdays, iniciodayr));
    intervalSearchs = setInterval(async () => {
      dispatch(action_trainer(finislongdays, iniciodayr));
    }, 15000);
    return () => {
      clearInterval(intervalSearchs);
    };
  }, []);

  useEffect(() => {
    axios.get("https://ipwhois.app/json/").then((res) => {
      if (res.data) {
        setZonahorairachange(res.data.timezone);
      }
    });
  }, []);
  useEffect(() => {
    instance
      .get(`booking/?confirmed&date__lte=${findesemana}&date__gte=${inicioday}`)
      .then((res) => {
        const daysselected = res.data.filter(
          (e) => e.date > diaya.slice(0, 11) + "00:00"
        );
        if (daysselected != null) {
          setInfoDays(filterCheckDays(daysselected));
        }
      });

    instance
      .get(`trainers/account/income/`)

      .then((res) => {
        const info = res.data;
        if (info) {
          const summary = JSON.parse(res.data.summary);
          const summaryclasesporcobrar = summary.length;
          const summaryclasesmes = summary.filter(
            (e) =>
              e.value > 0 &&
              e.date.slice(0, 10) > formatDate(primerDia) &&
              e.date.slice(0, 10) < formatDate(ultimoDia)
          ).length;
          const total = res.data.total;

          setidcobro([total, summaryclasesporcobrar, summaryclasesmes]);
        }
      })
      .catch((err) => {
        if (err.response.data) {
          setidcobro([null, null, null]);
        }
      });
    return () => {};
  }, []);

  return laptop575.matches ? (
    <div className="entrenadorInicioContainer  mb-5">
      <Reservas
        finislongdaysReserva={finislongdaysReserva}
        iniciodayReservas={iniciodayReservas}
        user={user}
        database={database}
        datagroup={datagroup}
        mode="swiper"
        verificarTrainning={verificarTrainning}
      />

      <ClasesDisponibles
        finislongdaysReserva={finislongdaysReserva}
        iniciodayReservas={iniciodayReservas}
        finislongdays={finislongdays}
        inicioday={iniciodayr}
        user={user}
        setverificarTrainning={setverificarTrainning}
        zonahorairachange={zonahorairachange}
        database={database}
      />
      <div className="title">Resumen de actividad</div>
      <div className="d-flex jc-center tamañoestadistics ">
        <div className="m-4  ">
          <div className=" letrasestadis letrasdatosentrea">
            {idcobro && idcobro[2] ? idcobro[2] : "000k"}
          </div>

          <div className=" letrasestadis1 letrasdatosentrea1">
            clases dictadas en el mes{" "}
          </div>
        </div>
        <div className=" borderkm_clases m-4"></div>
        <div className="m-4 ">
          <div className=" letrasestadis letrasdatosentrea">
            {idcobro && idcobro[1] ? idcobro[1] : "0.00"}
          </div>
          <div className="d-flex m-auto letrasdatosentrea3"></div>
          <div className=" letrasestadis1 letrasdatosentrea1">
            clases por cobrar
          </div>
        </div>
        <div className=" borderkm_clases m-4"></div>
        <div className="m-4 ">
          <div className=" letrasestadis letrasdatosentrea">
            {idcobro && idcobro[0] ? "$" + idcobro[0] : "$0.00"}
          </div>
          <div className=" letrasestadis1 letrasdatosentrea1">
            Total ingresos por cobrar
          </div>
        </div>
      </div>
      <div className="title">Días reservados en la semana</div>
      <div className="containerGraficas my-4">
        <div
          className="semana mx-auto "
          style={{ margin: "2vh 0", padding: "0 20vw" }}
        >
          {infoDays}
        </div>
      </div>
    </div>
  ) : (
    <div className="entrenadorInicioContainer entrenadorInicioContainersa mb-5">
      <div className="sliderReservas listaReservas  listaReservasentre mx-auto">
        <Reservas
          finislongdaysReserva={finislongdaysReserva}
          iniciodayReservas={iniciodayReservas}
          database={database}
          datagroup={datagroup}
          user={user}
          mode="swiper"
          verificarTrainning={verificarTrainning}
        />
      </div>
      <div className="title">Días reservados en la semana</div>
      <div className="containerGraficas">
        <div className="semana mx-auto w-100">{infoDays}</div>
        <div className="semana mx-auto semanalinea"></div>
      </div>
      <ClasesDisponibles
        finislongdaysReserva={finislongdaysReserva}
        iniciodayReservas={iniciodayReservas}
        finislongdays={finislongdays}
        inicioday={iniciodayr}
        user={user}
        database={database}
        setverificarTrainning={setverificarTrainning}
        zonahorairachange={zonahorairachange}
      />
    </div>
  );
}

export function Reservas(props) {
  const path = useLocation();

  const datagroup = props.datagroup;
  const inicioday = props.iniciodayReservas;
  const finislongdays = props.finislongdaysReserva;
  const mode = props.mode;

  const moment = extendMoment(Moment);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(async () => {
    setIsLoaded(true);
  }, []);

  return (
    isLoaded && (
      <>
        {laptop575.matches ? (
          path.pathname.includes(`/${entrenadorType}/inicio`) ? (
            <div className="bievenidoainicio mt-5">
              <div className="d-flex">
                <div className="SubTitle8perfilcual dalepower">
                  Bienvenido a
                </div>
                <div className="lineainiciousuario"></div>
              </div>
              <div className="titlec text-left">Elevva</div>
              <br />
              <br />
              <div
                className="title_triner  text-left"
                style={{ marginLeft: "6vw" }}
              >
                Tus clases reservadas
              </div>
              <div className="SubTitle8perfilcual dalepowerl text-left col-12 w-100 mt-3">
                Interactúa con esta plataforma, construida para ti para que
                puedas monetizar tu tiempo{" "}
              </div>
            </div>
          ) : null
        ) : (
          <div className="title ">
            Tus clases reservadas
            <img src={calendarA} alt="calendar" className="calendar-title" />
          </div>
        )}

        {mode == "swiper" ? (
          <div className="listaReservas mx-auto ">
            <Media query="(max-width: 920px)">
              {(matches) =>
                matches ? (
                  <Swiper
                    className="sliderReservas "
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={false}
                    pagination={{
                      el: ".bulletsSlider",
                      type: "custom",
                      renderCustom: function (swip, current, total) {
                        current -= 1;
                        if (total == 1) return `<div class="mb-3"></div>`;
                        return datagroup
                          .map((value, index) => {
                            return `<div class='swiper-pagination-bullet mx-2 mt-1 ${
                              current == index ? "active" : ""
                            }'></div>`;
                          })
                          .slice(0, 5)
                          .join("");
                      },
                    }}
                  >
                    {datagroup
                      .sort(function (a, b) {
                        const fechaA = b.date;
                        const fechaB = a.date;
                        if (fechaA > fechaB) {
                          return -1;
                        }
                        if (fechaA < fechaB) {
                          return 1;
                        }
                        // names must be equal
                        return 0;
                      })
                      .map((el, index) => {
                        let bg_img = el.training.cover_picture;
                        const is_group = el.is_group;
                        const name = el.training.name;
                        const dateT = el.date;
                        const id = el.id;
                        const fecha = moment(
                          el.date.slice(0, 10),
                          "YYYY-MM-DD"
                        );
                        const hora = moment(el.date.slice(11, 16), "LT a");
                        const url = is_group
                          ? `/${entrenadorType}/reservasGrupales/ver/` + id
                          : `/${entrenadorType}/reservas/ver/` + id;
                        return (
                          <SwiperSlide
                            key={dateT}
                            className="col-12  col-lg-4 sliderReserva"
                          >
                            <Link to={url} className="m-3">
                              <div
                                className="itemReserva"
                                style={{ backgroundImage: `url(${bg_img})` }}
                              >
                                <div className="bg"></div>

                                <span className="text1">
                                  <div className="d-flex">
                                    <div>Entrenamiento</div>
                                  </div>

                                  <span className="text2 container_name1">
                                    {name}
                                  </span>
                                </span>

                                <div className="bot">
                                  <div className="type">
                                    <div style={{ padding: "0 2.7vw" }}>
                                      <img
                                        src={
                                          is_group ? grupales_true : individual
                                        }
                                        alt="individual"
                                        style={{ padding: "0 2.7vw" }}
                                      />
                                      <br />
                                      {is_group ? "Grupal" : "Individual"}
                                    </div>
                                  </div>

                                  <div className="date">
                                    <div className="content">
                                      <span className="letricaregu">
                                        {fecha.format("dddd")}
                                      </span>
                                      <br />
                                      <b style={{ textTransform: "lowercase" }}>
                                        {fecha.format("  DD [de] MMMM ")}
                                      </b>
                                      <b style={{ textTransform: "lowercase" }}>
                                        {hora.format("hh:mm a")}
                                      </b>
                                    </div>
                                  </div>
                                  <br />
                                  <div className=" cositaver ver ">Ver</div>
                                </div>
                              </div>
                            </Link>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                ) : (
                  <div className="mx-auto row">
                    {" "}
                    <ReservasTrainers datagroup={datagroup} />
                  </div>
                  /* Solo mostrara los sliders sin paginar ni nada */
                )
              }
            </Media>
            <div className="bulletsSlider"></div>
            <Link
              to={`/${entrenadorType}/reservas`}
              className="d-flex centrarbotonentre mb-4"
            >
              <ButtonMob
                colorButton={"#CB3D82"}
                text="Ver todas tus reservas"
                className="verReservasEntrenador lh-1 py-3"
              />
            </Link>
          </div>
        ) : (
          <>
            {" "}
            <ReservasTrainers datagroup={datagroup} />
          </>
        )}
      </>
    )
  );
}
const ModalContainer = (props) => {
  const user = props.user;
  const htmlData = props.htmlData;
  return (
    <div className="modalContainer">
      <div className="modalContent">
        <div className="contentInfo">{htmlData}</div>
      </div>
    </div>
  );
};

function ClasesDisponibles(props) {
  const dispatch = useDispatch();
  const database = props.database;
  const inicioday = props.inicioday;

  const finislongdaysReserva = props.finislongdaysReserva;
  const iniciodayReservas = props.iniciodayReservas;

  const finislongdays = props.finislongdays;
  const zonahorairachange = props.zonahorairachange;

  const moment = extendMoment(Moment);
  require("moment-timezone");

  const user = props.user;

  const [clasesPorReservar, setClasesPorReservar] = useState(null);
  const [ordenClases, setOrdenClases] = useState(null);

  useEffect(() => {
    if (ordenClases != null) {
      const info = database;

      if (ordenClases[0] == 1) {
        if (ordenClases[1] == true) {
          dispatch(action_trainer(finislongdays, inicioday, 1, true));
        } else {
          dispatch(action_trainer(finislongdays, inicioday, 1, false));
        }
      } else if (ordenClases[0] == 2) {
        if (ordenClases[1] == true) {
          dispatch(action_trainer(finislongdays, inicioday, 2, true));
        } else {
          dispatch(action_trainer(finislongdays, inicioday, 2, false));
        }
      } else if (ordenClases[0] == 3) {
        if (ordenClases[1] == true) {
          dispatch(action_trainer(finislongdays, inicioday, 3, true));
        } else {
          dispatch(action_trainer(finislongdays, inicioday, 3, false));
        }
      } else if (ordenClases[0] == 4) {
        if (ordenClases[1] == true) {
          dispatch(action_trainer(finislongdays, inicioday, 4, true));
        } else {
          dispatch(action_trainer(finislongdays, inicioday, 4, false));
        }
      } else {
        setClasesPorReservar(info.filter((e) => e));
      }
    }
    return () => {};
  }, [ordenClases]);

  const [modal, setModal] = useState(null);

  function torque(
    finislongdays,
    inicioday,
    finislongdaysReserva,
    iniciodayReservas
  ) {
    //actualiza, pero recordar que no debe replicar codigo please
    setModal(null);

    dispatch(actualBook(finislongdaysReserva, iniciodayReservas));
    dispatch(action_trainer(finislongdays, inicioday));
  }
  function clasedecolor(cost, trainer) {
    if (cost == 1) {
      return "text-left btnReserva corregr_botin";
    } else if (cost == 2) {
      if (trainer) {
        return "text-left btnReservacost3 corregr_botin";
      } else return "text-left btnReservacost1 corregr_botin";
    } else if (cost == 3) {
      return "text-left btnReservacost2 corregr_botin";
    }
  }

  function rechazarClase(id) {
    instance
      .post(`booking/book/reject/${id}`)

      .then((res) => {
        const info = res.data;
        if (info) {
          dispatch(action_trainer(finislongdays, inicioday));
        }
      });
  }

  function reservarClase(id) {
    const cal = info;
    instance
      .post(`booking/book/confirm/${id}`)
      .then((res) => {
        const info = res.data;

        if (info) {
          const date = info.date;
          // moment.tz(AllHours[0], zonahorairachange).format("LT")
          const rawHora = moment(date.slice(11, 16), "HH:mm")
            .format("LT a")
            .split(":");
          const fecha = moment(date.slice(0, 10), "YYYY-MM-DD");
          const hora = `${rawHora[0]}:${rawHora[1]} - ${
            parseInt(rawHora[0]) + 1
          }:${rawHora[1]}`;
          setModal(
            <ModalContainer
              user={user}
              htmlData={
                <>
                  <img
                    src={checkCircle}
                    alt="check"
                    style={
                      laptop575.matches
                        ? { width: "2.5rem", marginTop: "1rem" }
                        : { width: "30px" }
                    }
                  />
                  <div className="title" style={{ fontSize: "1.3rem" }}>
                    Tu entrenamiento
                    <br />
                    ha sido reservado
                  </div>
                  <img
                    src={calendar}
                    alt="calendar"
                    style={
                      laptop575.matches
                        ? { width: "2.5rem", marginTop: "1rem" }
                        : { width: "30px" }
                    }
                  />
                  <div
                    className="title "
                    style={{ fontSize: "1.3rem", color: "#CB3D82" }}
                  >
                    <span className="text-capitalize">
                      {fecha.format("dddd")}
                    </span>{" "}
                    <br />
                    {fecha.format("LL")}
                    <div className="subtitle my-1">{hora}</div>
                  </div>
                  <img
                    src={pc}
                    alt="pc"
                    style={
                      laptop575.matches
                        ? { width: "2.5rem", margin: "1rem" }
                        : { width: "70px" }
                    }
                  />
                  <div
                    style={
                      laptop575.matches
                        ? {
                            color: "#9DACBD",
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                          }
                        : { color: "#9DACBD", marginTop: "0.5rem" }
                    }
                  >
                    Recuerda que solo puedes dictar tus clases a través de un
                    computador.
                  </div>
                  <div>
                    <ButtonMob
                      colorButton={"#CB3D82"}
                      text="Confirmar"
                      className="mt-3 py-2 "
                      onClick={() =>
                        torque(
                          finislongdays,
                          inicioday,
                          finislongdaysReserva,
                          iniciodayReservas
                        )
                      }
                    />
                  </div>
                </>
              }
            />
          );
        } else if (!info.date) {
          setModal(
            <ModalContainer
              user={user}
              htmlData={
                <>
                  <img src={wrong} alt="check" style={{ width: "30px" }} />
                  <div className="title" style={{ color: "#394F65" }}>
                    Tu entrenamiento no ha sido reservado
                  </div>
                  <img src={cal} alt="error" style={{ width: "30px" }} />
                  <div
                    className="title text-capitalize"
                    style={{ fontSize: "1.3rem", color: "#CB3D82" }}
                  >
                    Tienes una clase asignada para este horario
                  </div>
                  <div style={{ color: "#9DACBD", marginTop: "0.5rem" }}>
                    Lo sentimos, Intenta asignar otra clase en un horario
                    diferente.
                  </div>
                  <ButtonMob
                    colorButton={"#CB3D82"}
                    text="Confirmar"
                    className="mt-2 marginbotonreservaentrenadors py-2"
                    onClick={() => setModal(null) || window.location.reload()}
                  />
                </>
              }
            />
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          setModal(
            <ModalContainer
              user={user}
              htmlData={
                <>
                  <img src={wrong} alt="check" style={{ width: "30px" }} />
                  <div className="title" style={{ color: "#394F65" }}>
                    Tu entrenamiento no ha sido reservado
                  </div>
                  <img src={cal} alt="error" style={{ width: "30px" }} />
                  <div style={{ color: "#9DACBD", marginTop: "0.5rem" }}>
                    Lo sentimos, algo ha fallado por favor revisa bien tu
                    reserva.
                  </div>
                  <ButtonMob
                    colorButton={"#CB3D82"}
                    text="Confirmar"
                    className="mt-2 "
                    onClick={() => setModal(null) || window.location.reload()}
                  />
                </>
              }
            />
          );
        }
      });
  }
  return (
    <>
      {modal}

      <div className="listClasesDisponibles">
        {laptop575.matches ? (
          <>
            <div className=" acomodar  text-center">
              <div className="title textocentradop">
                Nuevos entrenamientos
                <p className="textodebajotex " style={{ color: "#9DACBD" }}>
                  Estas clases están buscando profesor, escoge la que más se
                  ajuste a ti
                </p>
              </div>
            </div>
            <div>
              <div className=" acomodartitulo  text-center ">
                <div
                  style={{ cursor: "pointer", minWidth: "15rem" }}
                  className=" tipoClase py-3  text-left nuevovacio colorvacios"
                  onClick={() =>
                    ordenClases &&
                    ordenClases[0] == 1 &&
                    ordenClases[1] == false
                      ? setOrdenClases([1, true])
                      : setOrdenClases([1, false])
                  }
                >
                  Tipo de entrenamiento{" "}
                  <FontAwesomeIcon
                    icon={
                      ordenClases &&
                      ordenClases[0] == 1 &&
                      ordenClases[1] == false
                        ? faArrowDown
                        : faArrowUp
                    }
                    className="mx-auto"
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="horaClase   py-3 text-center  nuevovacio1 colorvacios"
                  onClick={() =>
                    ordenClases &&
                    ordenClases[0] == 2 &&
                    ordenClases[1] == false
                      ? setOrdenClases([2, true])
                      : setOrdenClases([2, false])
                  }
                >
                  Fecha
                  <FontAwesomeIcon
                    icon={
                      ordenClases &&
                      ordenClases[0] == 2 &&
                      ordenClases[1] == false
                        ? faArrowDown
                        : faArrowUp
                    }
                    className="mx-auto"
                  />
                </div>
                <div
                  className="horaClase  py-3 text-center nuevovacio2 colorvacios"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    ordenClases &&
                    ordenClases[0] == 4 &&
                    ordenClases[1] == false
                      ? setOrdenClases([4, true])
                      : setOrdenClases([4, false])
                  }
                >
                  Hora{" "}
                  <FontAwesomeIcon
                    icon={
                      ordenClases &&
                      ordenClases[0] == 4 &&
                      ordenClases[1] == false
                        ? faArrowDown
                        : faArrowUp
                    }
                    className="mx-auto"
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="tipoClase  py-3  colorvacios nuevovacio7"
                  onClick={() =>
                    ordenClases &&
                    ordenClases[0] == 3 &&
                    ordenClases[1] == false
                      ? setOrdenClases([3, true])
                      : setOrdenClases([3, false])
                  }
                >
                  Costo{" "}
                  <FontAwesomeIcon
                    icon={
                      ordenClases &&
                      ordenClases[0] == 3 &&
                      ordenClases[1] == false
                        ? faArrowDown
                        : faArrowUp
                    }
                    className="mx-auto"
                  />
                </div>
                <div
                  className=" d-flex justify-content-center botonReservar my-2 nuevovacio8"
                  style={{ width: "25%" }}
                ></div>
              </div>
            </div>
          </>
        ) : null}

        {database ? (
          !laptop575.matches ? (
            <>
              <div className="title ">
                Clases disponibles{" "}
                <img
                  src={check}
                  alt="clases disponbiles"
                  className="calendar-title"
                />
              </div>
              <p className="mx-5" style={{ color: "#9DACBD" }}>
                Estas clases están buscando profesor, escoge la que más se
                ajuste a ti.
              </p>

              <select
                className="selectFilterClases selectnuevofelcha form-select"
                defaultValue="0"
                onChange={(e) => setOrdenClases(e.target.value)}
              >
                <option disabled value="0">
                  Ordenar por
                </option>
                <option value="2">Fecha </option>
                <option value="3">Valor</option>
              </select>
            </>
          ) : null
        ) : laptop575.matches ? null : (
          <div className="form-text my-3">No hay clases disponibles.</div>
        )}
        {database && database.length > 0 ? (
          database.map((val, idx) => {
            return laptop575.matches ? (
              <div
                key={val.id}
                className="acomodartitulo  text-center "
                style={{ paddingBottom: "1rem" }}
              >
                <div
                  className="tipoClase py-3 text-left nuevovacio  "
                  style={{
                    minWidth: "15rem",
                  }}
                >
                  {val.name.length > 26
                    ? val.name.substring(0, 26) + "..."
                    : val.name}
                </div>
                <div className="horaClase   py-3 text-center  nuevovacio1  ">
                  {moment(val.fecha).format(" MMMM DD ")}
                </div>
                <div className="horaClase  py-3 text-center nuevovacio2  ">
                  {moment(val.hora, "hh:mm").format("hh:mm a")}
                </div>
                <div
                  className="tipoClase  py-3 nuevovacio7"
                  style={{
                    textTransform: "capitalize",

                    color:
                      val.cost == 1 && !val.trainer
                        ? "#6EE1FB"
                        : val.cost == 2 && !val.trainer
                        ? "#FFB6A4"
                        : val.cost == 3 && !val.trainer
                        ? "#B381BE"
                        : "#CB3D82",
                  }}
                >
                  {val.cost == 1
                    ? "$10.000"
                    : val.cost == 2
                    ? "$20.000"
                    : "$30.000"}
                </div>
                {!val.trainer ? (
                  <div
                    className="d-flex justify-content-center botonReservar my-2"
                    style={{ width: "25%" }}
                    onClick={() => reservarClase(val.id)}
                  >
                    <ButtonMob
                      text="Reservar clase"
                      className={clasedecolor(val.cost, val.trainer)}
                      image={calendarB}
                      classesImg="btnCalendar"
                    />
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center botonReservar nuevovacio8 "
                    style={{ width: "25%" }}
                  >
                    <div
                      className="d-flex justify-content-center botonReservar my-2 "
                      style={{ padding: "0 0.5vw" }}
                      onClick={() => reservarClase(val.id)}
                    >
                      <ButtonMob
                        text="Reservar clase"
                        className={clasedecolor(val.cost, val.trainer)}
                        image={calendarB}
                        classesImg="btnCalendar"
                      />
                    </div>
                    <div
                      className="d-flex justify-content-center botonReservar my-2"
                      onClick={() => rechazarClase(val.id)}
                      style={{ padding: "0 0.5vw" }}
                    >
                      <ButtonMob
                        text="Rechazar"
                        className={clasedecolor(val.cost, val.trainer)}
                        image={equiscl}
                        classesImg="btnCalendarequis"
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div key={val.id} className="">
                <div
                  className="tipoClase"
                  style={{ textTransform: "capitalize" }}
                >
                  {val.name}
                </div>
                <div className="horaClase">
                  {moment(val.fecha).format(" MMMM DD ")}/{" "}
                  {moment(val.hora, "HH:mm").format("hh:mm a")}
                </div>
                <div
                  className="tipoClase"
                  style={{
                    textTransform: "capitalize",
                    fontSize: "5vw",
                    color:
                      val.cost == 1 && !val.trainer
                        ? "#6EE1FB"
                        : val.cost == 2 && !val.trainer
                        ? "#FFB6A4"
                        : val.cost == 3 && !val.trainer
                        ? "#B381BE"
                        : "#CB3D82",
                  }}
                >
                  {val.cost == 1
                    ? "$10.000"
                    : val.cost == 2
                    ? "$20.000"
                    : "$30.000"}
                </div>
                {!val.trainer ? (
                  <div
                    className="d-flex justify-content-center botonReservar mt-3"
                    onClick={() => reservarClase(val.id)}
                  >
                    <ButtonMob
                      text="Reservar clase"
                      className={clasedecolor(val.cost, val.trainer)}
                      image={calendarB}
                      classesImg="btnCalendar"
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className="d-row justify-content-center botonReservar mt-3"
                      onClick={() => reservarClase(val.id)}
                    >
                      <ButtonMob
                        text="Reservar clase"
                        className={clasedecolor(val.cost, val.trainer)}
                        image={calendarB}
                        classesImg="btnCalendar"
                      />
                    </div>
                    <div
                      className="d-row justify-content-center botonReservar mt-3"
                      onClick={() => rechazarClase(val.id)}
                    >
                      <ButtonMob
                        text="Rechazar"
                        className={clasedecolor(val.cost, val.trainer)}
                        image={equiscl}
                        classesImg="btnCalendarequis"
                      />
                    </div>
                  </>
                )}
              </div>
            );
          })
        ) : (
          <div
            className="py-3"
            style={{
              background: "#E1E5F9",
              color: "#CB3D82",
              fontWeight: "500",
            }}
          >
            En este momento no tienes clases para reservar
          </div>
        )}
      </div>
    </>
  );
}

export default InicioEntrenador;
