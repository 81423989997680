import { UPDATE_DATA_TRAINER } from "../types";

const initialState = {
  database: [],
};

export default function action_trainer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA_TRAINER: {
      return {
        ...state,
        database: action.payload,
      };
    }

    default:
      return state;
  }
}
