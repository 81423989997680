import { useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  ButtonMob,
  ModalInfo,
  userType,
  NuevaAxiosURL,
  instance,
} from "../utils/utils.js";
import calendarB from "../assets/img/footerIcons/iconos/reservasc.png";
import home from "../assets/img/reservasimages/home.png";
import pc from "../assets/img/reservasimages/pc.png";
import calendar from "../assets/img/reservasimages/calendar.png";
import Moment from "moment";
import jstz from "jstz";
import { extendMoment } from "moment-range";
import Media from "react-media";
import devolution from "../assets/img/reservasimages/devolution.png";
import "../Reservas/Confirmado.css";
const ConfirmadoCreditosCompanyGroup = (props) => {
  const user = props.user;
  const [objReserva, setReserva] = useState([]);
  const moment = extendMoment(Moment);
  require("moment-timezone");
  const [cabecera, setCabecera] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [creditosreservados, setcreditosreservados] = useState(0);
  useEffect(() => {
    hacele();
  }, []);

  let { fecha } = useParams();
  const fechasi = fecha.split(",");

  async function hacele() {
    const entrenadores1 = [];
    await instance
      .get(`booking/`)
      .then((resEn) => {
        const entrel = entrenadores1;
        const info = resEn.data.filter((eh, i) =>
          fechasi.some((e) => e == eh.id)
        );
        setReserva(info);
        setCabecera(
          <div
            className="Entrenamiento reserva"
            style={{
              backgroundImage: `url(${info[0].training.cover_picture})`,
            }}
          >
            <div className="bg_cover reserva"></div>
            <div className="Cabecera reserva">
              Entrenamiento
              {/* <span className="lineop"></span> */}
              <br />
              <span className="container_name">{info[0].training.name}</span>
            </div>
          </div>
        );
      })
      .catch((err) => {
        console.error("Error en booking/", err);
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });
  }
  const totalcreditos = objReserva.map((objReserva) => objReserva.cost);
  useEffect(() => {
    setcreditosreservados(totalcreditos.reduce((a, b) => a + b, 0));
  });

  function hideModal() {
    setModalInfo(null);
  }
  return (
    <>
      <div
        className="bannervideos d-flex"
        style={{
          background: "rgb(241, 246, 249)",
          position: "sticky",
          top: "0",
          zIndex: "3",
          minheight: "10vmin",
          left: "0",
          flexDirection: "column",
        }}
      >
        <div
          className="py-2 mx-3 banner_one "
          style={{
            background: "rgb(241, 246, 249)",
            minHeight: "65px",
            color: "#394F65",
          }}
        >
          {" "}
          <Link
            to="/ejercicios"
            className=" position-absolute"
            style={{
              left: "10%",
              top: "35%",
              justifyContent: "center",

              fontWeight: "500",
              alignItems: "center",
            }}
          >
            {" "}
            <span className="d-flex m-auto">
              <span
                className="my-auto mx-1 px-2 py-1 d-flex"
                style={{
                  border: "1px solid #394F65",
                  justifyContent: "center",
                  color: "#394F65",
                  borderRadius: "10px",
                }}
              >
                {" "}
                <i className="fa fa-arrow-left  "></i>
              </span>
              <span className="my-auto m-1" style={{ color: "#394F65" }}>
                Regresar
              </span>
            </span>
          </Link>
          <div
            className="d-flex position-absolute"
            style={{
              left: "45%",
              top: "30%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <img
              style={{ width: "200px" }}
              src={process.env.PUBLIC_URL + "/img/logosEmpresas.png"}
            />{" "}
          </div>
        </div>
      </div>
      <div className="w-100">
        <div
          className="containerBackgroundCreateuser d-flex"
          style={{
            padding: "1.5vmin 5% 1.5vmin 55%",
            justifyContent: "center",
            flexDirection: "column",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/img/buscaralto.jpg"
            })`,
          }}
        >
          <span
            style={{
              fontWeight: "700",
              color: "#ffff",
              fontSize: "40px",
              margin: "4vmin auto",
            }}
          >
            Resumen de reserva
          </span>
        </div>
      </div>
      <Media queries={{ phoneIpad: { maxWidth: 576 } }}>
        {(matches) =>
          matches.phoneIpad ? (
            <div className="Reservando w-100 confirmado">
              {cabecera}
              <div className="title mx-auto confirmado">
                Entrenamiento reservado
              </div>

              <>
                <div className="iconCalendar">
                  <img src={calendarB} alt="calendario" />
                </div>
                <div className="title text-capitalize">
                  <div className="">
                    {objReserva
                      ? objReserva
                          .sort(function (a, b) {
                            return a.id - b.id;
                          })
                          .map((objReserva) => (
                            <>
                              <div
                                key={objReserva.id}
                                style={{ color: "#CB3D82" }}
                              >
                                {moment(
                                  objReserva.date
                                    .replace("T", " ")
                                    .slice(0, 16)
                                    .slice(0, 10),
                                  "YYYY-MM-DD"
                                ).format("dddd DD MMMM YYYY")}
                              </div>
                              <br />{" "}
                              <div
                                className="subtitle text-capitalize my-3"
                                style={{ borderBottom: "1px solid #BEBEBE" }}
                              >
                                <div className="mb-3" key={objReserva.id}>
                                  {" "}
                                  {moment
                                    .tz(
                                      moment(
                                        objReserva.date
                                          .replace("T", " ")
                                          .slice(0, 16)
                                          .slice(11, 16),
                                        "hh:mm"
                                      ).format(),
                                      objReserva.tz
                                    )
                                    .format("LT") +
                                    " - " +
                                    moment
                                      .tz(
                                        moment(
                                          objReserva.date
                                            .replace("T", " ")
                                            .slice(0, 16)
                                            .slice(11, 16),
                                          "hh:mm"
                                        ).format(),
                                        objReserva.tz
                                      )
                                      .add(1, "H")
                                      .format("LT a")}
                                </div>
                              </div>
                            </>
                          ))
                      : null}
                  </div>
                </div>
              </>
              <div className="mx-2 mb-2">
                <div className="">
                  <div className="costo_reserva ">
                    Tus créditos actuales:
                    <span className="créditos_actuales">
                      {" "}
                      {user.credits == 1
                        ? `${user.credits} crédito`
                        : `${user.credits} créditos`}
                    </span>
                  </div>
                </div>
                <div className="">
                  <div className="costo_reserva">
                    costo de tu reserva:{" "}
                    <span className="créditos_actuales">
                      {creditosreservados == 1
                        ? `- ${creditosreservados} crédito`
                        : `- ${creditosreservados} créditos`}
                    </span>
                  </div>
                </div>
                <div className="">
                  <div className="costo_reserva">
                    Créditos restantes:{" "}
                    <span className="créditos_actuales">
                      {user.credits - creditosreservados == 1
                        ? `${user.credits - creditosreservados} crédito`
                        : `${user.credits - creditosreservados} créditos`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="recomendacion">
                <img src={pc} alt="Pc" />
                <div className=" mt-3">
                  Recuerda que puedes tomar tus clases a través de un
                  computador, celular, ipad o tablet.
                  <br />
                  Para una experiencia optima, recomendamos usar el navegador
                  Google Chrome.
                  <br />
                  Si por algún motivo debes cancelar tu clase, recuerda
                  realizarla antes de 24 horas para obtener el reembolso de tu
                  crédito.
                </div>
              </div>
              <Link to={`/${userType}/inicio`} className="containerVolverHome ">
                <ButtonMob
                  text="Ir a"
                  className="text-left ps-4 btnvolverHome my-3"
                  image={home}
                  classesImg="btnHomeVolver ms-3"
                />
              </Link>
            </div>
          ) : (
            <div className="maxreservaselec">
              <br />
              <div
                className="textodebajoreservas  "
                style={{ marginLeft: "15%" }}
              >
                En ELEVVA eres dueño de tu tiempo, agenda tu entrenamiento en
                los días y horario de tu preferencia
              </div>
              <div
                className="Entrenamiento ReservandoDeskt confirmativo "
                style={{
                  backgroundImage: `url(${
                    objReserva[0] && objReserva[0].training.cover_picture
                  })`,
                }}
              >
                <div className="bg_cover"></div>
                <div className="d-flex g-0 inf">
                  <div className="g-0 inf">
                    <div
                      className="w-100"
                      style={{
                        marginTop: "50%",
                        textTransform: "capitalize",
                        paddingRight: "5%",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "WorkSans-Regular",
                          fontSize: "1vw",
                        }}
                      >
                        Entrenamiento
                      </div>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "2vw",
                        }}
                      >
                        {objReserva[0] &&
                          objReserva[0].training.name.charAt(0).toUpperCase() +
                            objReserva[0].training.name.slice(1).toLowerCase()}
                      </div>
                    </div>
                  </div>

                  <div className=" information">
                    <div className="content">
                      <div className="title_detitles mx-auto confirmado">
                        Entrenamiento <br /> reservado
                      </div>
                      <div className="iconCalendar">
                        <img src={calendarB} alt="calendario" />
                      </div>

                      <div className="title text-capitalize d-row  ">
                        <div className="">
                          {" "}
                          {objReserva
                            .sort(function (a, b) {
                              var nameA = b.date; // ignore upper and lowercase
                              var nameB = a.date; // ignore upper and lowercase
                              if (nameA > nameB) {
                                return -1;
                              }
                              if (nameA < nameB) {
                                return 1;
                              }
                              // names must be equal
                              return 0;
                            })
                            .map((objReserva) => (
                              <>
                                <div key={objReserva.id}>
                                  {moment(
                                    objReserva.date
                                      .replace("T", " ")
                                      .slice(0, 16)
                                      .slice(0, 10),
                                    "YYYY-MM-DD"
                                  ).format("dddd DD [de] MMMM")}
                                </div>

                                <div
                                  className="subtitle text-capitalize my-1"
                                  style={{ fontSize: "1rem" }}
                                >
                                  <div className="mb-3" key={objReserva.id}>
                                    {" "}
                                    {moment
                                      .tz(
                                        moment(
                                          objReserva.date
                                            .replace("T", " ")
                                            .slice(0, 16)
                                            .slice(11, 16),
                                          "HH:mm"
                                        ).format(),
                                        objReserva.tz
                                      )
                                      .format("LT") +
                                      " - " +
                                      moment
                                        .tz(
                                          moment(
                                            objReserva.date
                                              .replace("T", " ")
                                              .slice(0, 16)
                                              .slice(11, 16),
                                            "HH:mm"
                                          ).format(),
                                          objReserva.tz
                                        )
                                        .add(1, "H")
                                        .format("LT a")}
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>

                      <div className="recomendacion">
                        <img src={pc} alt="Pc" />
                        <div>
                          Para una mejor experiencia, recuerda que las clases
                          solo pueden ser a través de un computador
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to={`/ejercicios`} className="containerVolverHome ">
                <ButtonMob
                  colorButton={"#CB3D82"}
                  text="Continuar"
                  className="text-left ps-4 btnReserva izquierbuton"
                  image2={calendar}
                  classesImg="btnCalendar ms-3"
                />
              </Link>
              )
            </div>
          )
        }
      </Media>

      {modalInfo}
    </>
  );
};
export default ConfirmadoCreditosCompanyGroup;
