import { Redirect, useParams } from "react-router-dom";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { useState } from "react";
import { ModeloResults } from "../Hr/modeloResults.js";
import { AccordionItem } from "../Entrenador/Perfil/Perfil";

import { ButtonMob, userType, laptop575 } from "../utils/utils";

import calendar from "../assets/img/reservasimages/calendar.png";
import icon1 from "../assets/img/reservasimages/icon1.png";
import icon2 from "../assets/img/reservasimages/icon2.png";
import icon3 from "../assets/img/reservasimages/icon3.png";
import icon4 from "../assets/img/reservasimages/alimentate.png";
import "./Resumen.css";

SwiperCore.use([Pagination]);
function Resumen(props) {
  localStorage.removeItem("idCiclo");
  localStorage.removeItem("percentageactual");
  localStorage.removeItem("actualSet");
  localStorage.removeItem("actualCiclo");
  localStorage.removeItem("series");

  const { idclase } = useParams();
  const user = props.user;

  const [modalInfo, setModalInfo] = useState(null);

  return laptop575.matches ? (
    <div className="resumenContainer">
      <div className="maxreservarResumen">
        <div className="superiorMaxResumen  ">
          <div className="SubTitle8resumen">Resumen de tu sesión</div>
          <div className="lineareservatuclaseonlyresumen"></div>
        </div>
      </div>

      <ModeloResults
        idclase={idclase}
        age={user.customer.age}
        url={`booking/book/${idclase}/workoutlog/`}
        url2={`booking/book/${idclase}/workoutlog/`}
      />
      {modalInfo}

      <div className="titleresumensesion " style={{ color: "#394F65" }}>
        Recomendaciones
      </div>

      <div className="sliderResumen">
        <div className="recom">
          <div>
            <div className="recomendaciones">
              <div className="content">
                <div className="my-3 mx-3">
                  <span>
                    Somos compañeros en tu camino hacia un estilo de vida
                    saludable y sabemos cuán importante es generar hábitos para
                    optimizar tu entrenamiento y resultados ¡Felicidades
                    Elevva+!
                  </span>
                </div>
                <div className="d-flex">
                  <div className="itemRecom">
                    <img src={icon1} alt="Icon" />
                    <span>
                      <b>CREA UNA COSTUMBRE</b>: Haz del ejercicio una costumbre
                      cotidiana y reserva tus clases con antelación.{" "}
                    </span>
                  </div>
                  <br />
                  <div className="itemRecom">
                    <img src={icon2} alt="Icon" />
                    <span>
                      <b>CAMBIA TU ROPA:</b> La ropa que usaste durante tu
                      entrenamiento atrapa humedad que aumenta las posibilidades
                      del crecimiento de bacterias, hongos y gérmenes, lo que
                      conlleva a la aparición de granitos.{" "}
                    </span>
                  </div>

                  <div className="itemRecom">
                    <img src={icon3} alt="Icon" />
                    <span>
                      <b>TOMA UNA DUCHA DE AGUA FRIA Y DESPUES CALIENTE</b>: Los
                      baños de contraste (alternando agua fría y caliente) ayuda
                      a recuperarse más rápidamente, reduce el dolor muscular y
                      previene lesiones.{" "}
                    </span>
                  </div>

                  <div className="itemRecom">
                    <img src={icon4} alt="Icon" />
                    <span>
                      <b>ALIMENTATE</b>: Después de entrenar, es necesario que
                      recuperes energía a través de una buena alimentación, que
                      también te ayudará a reparar tejidos y recuperar fuerzas
                      para tu próximo reto.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="resumenContainer">
      {modalInfo}
      <ModeloResults
        idclase={idclase}
        age={user.customer.age}
        url={`booking/book/${idclase}/workoutlog/`}
        url2={`booking/book/${idclase}/workoutlog/`}
      />
      <div className="mb-3">
        <AccordionItem
          title={"Recomendaciones"}
          key={1}
          item={1}
          clase="ordenarrecomendaciones "
        >
          <div className="recom">
            <div className="recomendaciones">
              <span className="mb-2">
                Somos compañeros en tu camino hacia un estilo de vida saludable
                y sabemos cuán importante es generar hábitos para optimizar tu
                entrenamiento y resultados ¡Felicidades Elevvaplayer!
              </span>
              <div className="content">
                <br />
                <div className="itemRecom">
                  <img src={icon1} alt="Icon" />
                  <span>
                    <b>CREA UNA COSTUMBRE</b>: Haz del ejercicio una costumbre
                    cotidiana y reserva tus clases con antelación.{" "}
                  </span>
                </div>
                <br />
                <div className="itemRecom">
                  <img src={icon2} alt="Icon" />
                  <span>
                    <b>CAMBIA TU ROPA:</b> La ropa que usaste durante tu
                    entrenamiento atrapa humedad que aumenta las posibilidades
                    del crecimiento de bacterias, hongos y gérmenes, lo que
                    conlleva a la aparición de granitos.{" "}
                  </span>
                </div>
                <br />
                <div className="itemRecom">
                  <img src={icon3} alt="Icon" />
                  <span>
                    <b>TOMA UNA DUCHA DE AGUA FRIA Y DESPUES CALIENTE</b>: Los
                    baños de contraste (alternando agua fría y caliente) ayuda a
                    recuperarse más rápidamente, reduce el dolor muscular y
                    previene lesiones.{" "}
                  </span>
                </div>
                <br />
                <div className="itemRecom">
                  <img src={icon4} alt="Icon" />
                  <span>
                    <b>ALIMENTATE</b>: Después de entrenar, es necesario que
                    recuperes energía a través de una buena alimentación, que
                    también te ayudará a reparar tejidos y recuperar fuerzas
                    para tu próximo reto.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </AccordionItem>
      </div>
      <div className="stepsBitacora mx-auto"></div>

      <div className="containerResevar d-flex justify-content-center">
        <ButtonMob
          onClick={() =>
            setModalInfo(<Redirect to={`/${userType}/reservas`} />)
          }
          text="Nueva reserva"
          className="text-left ps-4 btnReserva"
          image={calendar}
          classesImg="btnCalendar ms-3"
        />
      </div>
    </div>
  );
}

export default Resumen;
// https://localhost:3000/Usuario/confirmado/7/2021-10-04/09:15
