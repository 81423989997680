import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/pagination/pagination.min.css";
import axios from "axios";
import {
  ButtonMob,
  Loadingspinner,
  userType,
  basename,
  instance,
  ModalInfo,
  laptop575,
  laptop,
} from "../../utils/utils";
import { extendMoment } from "moment-range";
import Moment from "moment";
import calendarB from "../../assets/img/reservasimages/calendar_trainer_eleva.png";
import elip from "../../assets/img/reservasimages/elip.png";
import Media from "react-media";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

SwiperCore.use([Pagination]);
const VerReservaGruplaEntrenador = (props) => {
  const { id } = useParams();
  const zonahorairachange = props.zonahorairachange;
  const [objReserva, setReserva] = useState(null);
  const moment = extendMoment(Moment);
  require("moment-timezone");

  useEffect(async () => {
    const idgroup = id.replace("d", "");
    
    instance.get(`booking/group/prepare/${idgroup}`).then((resEn) => {
      const info = resEn.data;
      if (info) {
        console.log(info)
        const id = info.training.id;
        const date = moment.tz(info.schedule_date, zonahorairachange);
        const bookings = info.bookings;
        const cost = info.cost;
        localStorage.elevvaAppReserva=bookings[0]?.booking_id;
        console.log(
          info.schedule_date,
          date.format(" dddd DD [de] MMMM [de] YYYY"),
          date.format("LT a")
        );
        if (date != null) {
          setReserva({
            id,
            fecha: date.format(" dddd DD [de] MMMM [de] YYYY"),
            hora: date.format("LT a") + " - " + date.add(1, "H").format("LT a"),
            name: unescape(info.training.name).toLowerCase(),
            info: unescape(info.training.description),
            is_group: info.training.group,
            img: info.training.cover_picture,
            description: info.training.description,
            minutos: info.training.time,
            bookings: bookings,
            valor_clase: cost,
          });
        }
      }
    });
  }, [zonahorairachange]);

  return (
    <>
      <div className="bievenidoainicio">
        <div className="d-flex">
          <div className="SubTitle8perfilcual dalepower">Bienvenido a</div>
          <div className="lineainiciousuario "></div>
        </div>
        <div
          className="titlec text-left  w-100"
          style={{
            fontWeight: "bold",
            fontSize: "3vw",
            marginLeft: "6.5%",
            color: "#394F65",
          }}
        >
          Resumen de clase
        </div>
      </div>
      <div className="VerReserva ">
        {objReserva ? (
          <>
            <Cabecera objReserva={objReserva} />
            <Informacion objReserva={objReserva} />
          </>
        ) : (
          <Loadingspinner customStyle="border" size="5rem" />
        )}
      </div>
    </>
  );
};

const Cabecera = (props) => {
  const { id } = useParams();
  const objReserva = props.objReserva;

  const [clase, setClase] = useState(null);
  const [Modal, setModal] = useState(null);
  const hideModal = () => {
    setModal(null);
  };
  const goToClass = () => {
    instance
      .post(`booking/group/start/${id}`)
      .then((resEn) => {
        const data = resEn.data;

        if (data) {
          localStorage.removeItem("actualRutinaTime");
          localStorage.removeItem("actualIndex");
          localStorage.removeItem("allBokkingsElevva");
          localStorage.agoraToken = data.agora_token;
          window.location.href = `${basename}/Entrenador/clasestream/${id}/${data.room_id}`;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          setModal(
            <ModalInfo
              error="false"
              handleClick={hideModal}
              title="Recuerda"
              text={err.response.data[0]}
            />
          );
        }
      });
  };

  const submit = async () => {
    // const res = await instance.post(`booking/book/cancel/${id}`);
    // console.log("reseses", res.data, res);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="cambioCuaro">
            <div
              className="custom-ui CancelarReserva"
              style={laptop.matches ? { fontSize: "1vw" } : null}
            >
              <h1 className="title text-center">Cancelar la clase</h1>
              {/* <p className="msg">{res.data}</p> */}
              <div
                className="buttons"
                style={
                  laptop575.matches ? { width: "70%", margin: "auto" } : null
                }
              >
                <button className="No" onClick={onClose}>
                  <strong>No</strong> quiero cancelar
                </button>
                <button
                  className="Si"
                  onClick={async () => {
                    const resC = await instance.post(
                      `booking/book/cancel/${id}`
                    );
                    // console.log("reseses", resC);
                    onClose();
                    setClase(<Redirect to={`/${userType}/inicio`} />);
                  }}
                >
                  Cancelar el entrenamiento
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  return laptop575.matches ? (
    <>
      <div className="cabeceraContent">
        <div className="bg_imggrup">
          <img
            className="w-100"
            style={{ objectFit: "cover" }}
            src={objReserva.img}
            alt="Tipo entrenamiento"
          />
        </div>

        <div className="btnBottom fgu" style={{ zIndex: "9999999" }}>
          <ButtonMob
            image5={elip}
            text="Regresar a reservas "
            className="btnCancelarReserva colorbuttoniniciar2 "
            onClick={() =>
              (window.location.href = `${basename}/Entrenador/inicio`)
            }
          />
          <ButtonMob
            text="Iniciar entrenamiento"
            className="btnIniciarReserva me-3 colorbuttoniniciar"
            onClick={goToClass}
          />
        </div>
        <div className="info">
          Clase grupal
          {/* <span className="lineop1"></span> */}
          <br />
          <span className="name">{objReserva.name}</span>
          <br />
          <span className="name">
            {objReserva.valor_clase == 0 ? "GRATIS" : null}
          </span>
        </div>
      </div>
      {Modal}
      {clase}
    </>
  ) : (
    <>
      {" "}
      <div
        className="entrenadorInicioContainer entrenadorVerReserva"
        style={{ minHeight: "0" }}
      >
        <div className="title">
          <div className="suptitle">Resumen de clase</div>
          {objReserva.name} grupal
        </div>
        <div className="separador"></div>
        <SwiperSlide className="col-12 sliderReservaClase mt-3">
          <img src={calendarB} alt="calendario" width={40} />
          <div className="titulo title">
            <span
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                textTransform: "capitalize",
              }}
            >
              {objReserva.fecha}
              <br />
              {objReserva.hora}
            </span>
          </div>
          <div className="separador"></div>

          <div className="descrgroup  my-1">
            <div className="content">
              <br />
              <div
                className="title mt-2 "
                style={{ fontWeight: "bold", fontSize: "1rem" }}
              >
                Asistentes a tu clase
              </div>
              <div
                className=" d-row  w-100"
                style={{ height: "40vh", overflowY: "auto" }}
              >
                {objReserva.bookings.map((e) => (
                  <div>
                    <div className="d-flex mx-5 my-2">
                      <div style={{ width: "80%" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            fontWeight: "700",
                            textAlign: "left",
                          }}
                        >
                          {e["full_name"]}
                        </div>

                        <div
                          style={{
                            fontSize: "1rem",
                            color: "#363636",
                            fontFamily: "WorkSans-Regular",
                            opacity: "0.3",
                            textAlign: "left",
                          }}
                        >
                          {e["age"] + " años"} -
                          {e["level"] == 20
                            ? "Intermedio"
                            : e["level"] == 20
                            ? "Avanzado"
                            : "Principiante"}
                        </div>
                      </div>

                      <img
                        src={e.profile_pic}
                        style={{
                          width: "3rem",
                          height: "3rem",
                          borderRadius: "50px",
                          padding: "0.5vw",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="separador"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </SwiperSlide>
      </div>
    </>
  );
};

const Informacion = (props) => {
  const objReserva = props.objReserva;

  const sliders = <></>;
  const slidersweb = (
    <>
      <SwiperSlide className="col-12 sliderReservaClase">
        <div className="titulo title">
          <img src={calendarB} alt="calendario" />
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.0vw",
              textTransform: "capitalize",
            }}
          >
            {objReserva.fecha}
            <br />
            {objReserva.hora}
          </span>
        </div>

        <div className="linedivisionrecomendaciones"> </div>

        <div className="descrgroup  my-1">
          <div className="content">
            <br />
            <div
              className="title my-1 "
              style={{ fontWeight: "bold", fontSize: "1.2vw" }}
            >
              Asistentes a tu clase
            </div>
            <div
              className=" d-row  w-100"
              style={{ height: "calc(100% - 1rem)", overflowY: "auto" }}
            >
              {objReserva.bookings.map((e) => (
                <div className="d-flex">
                  <div style={{ width: "80%" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2vw",
                        fontWeight: "700",
                      }}
                    >
                      {e["full_name"]}
                    </div>

                    <div
                      style={{
                        fontSize: "1.2vw",
                        color: "#363636",
                        fontFamily: "WorkSans-Regular",
                        opacity: "0.3",
                      }}
                    >
                      {e["age"] + " años"} -
                      {e["level"] == 20
                        ? "Intermedio"
                        : e["level"] == 20
                        ? "Avanzado"
                        : "Principiante"}
                    </div>
                    <div className="linedivisionrecomendaciones"> </div>
                  </div>

                  <img
                    src={e.profile_pic}
                    style={{
                      width: "4vw",
                      height: "4vw",
                      borderRadius: "50px",
                      padding: "0.5vw",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="col-lg-4 sliderReservaClase verReservaWeb">
        <div className="descrgroup">
          <div className="content">{objReserva.info}</div>
        </div>
      </SwiperSlide>
    </>
  );
  return (
    <>
      <Media query="(max-width: 920px)">
        {(matches) =>
          matches ? (
            <>
              <div className="contenedorInfo mx-auto ">
                <Swiper
                  style={{ height: "calc(100% - 1rem)", overflowY: "scroll" }}
                  className="sliderPublicidad"
                  spaceBetween={10}
                  slidesPerView="auto"
                  breakpoints={{
                    // when window width is >= 1024px
                    481: {
                      slidesPerView: 2,
                    },
                  }}
                  pagination={{
                    el: ".bulletsPublicidad",
                    type: "custom",
                    renderCustom: function (swip, current, total) {
                      return `<div class='swiper-pagination-bullet mx-2 ${
                        current === 1 ? "active" : ""
                      }'></div>
                                                    <div class='swiper-pagination-bullet mx-2 ${
                                                      current === 2
                                                        ? "active"
                                                        : ""
                                                    }'></div>
                                                    <div class='swiper-pagination-bullet mx-2 ${
                                                      current === 3
                                                        ? "active"
                                                        : ""
                                                    }'></div>`;
                    },
                  }}
                >
                  {sliders}
                </Swiper>
              </div>
              <div className="bulletsPublicidadgrupal"></div>
            </>
          ) : (
            <div className="d-flex   contenedorInfo mx-auto">{slidersweb}</div>
          )
        }
      </Media>
    </>
  );
};

export default VerReservaGruplaEntrenador;
// https://localhost:3000/Usuario/confirmado/5/2021-10-30/07:00
