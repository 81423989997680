import "./Hr.css";
import { useEffect, useState, useRef } from "react";
import { isMobile, isIOS } from "react-device-detect";
import { useHistory, useParams } from "react-router";
import { ModalInfo, ButtonMob } from "../utils/utils";
import descargalapp from "../assets/img/descargala app.png";
import { Swiper, SwiperSlide } from "swiper/react";
import correr from "../assets/img/HrIcons/iconos/Correr.png";
import caminar from "../assets/img/HrIcons/iconos/Caminar.png";
import bicicleta from "../assets/img/HrIcons/iconos/Bicicleta.png";
import cardio from "../assets/img/HrIcons/iconos/cardio.png";
import nadar from "../assets/img/HrIcons/iconos/Nadar.png";
import fire from "../assets/img/HrIcons/iconos/fire.png";
import km from "../assets/img/HrIcons/iconos/Km.png";
import xfinal from "../assets/img/HrIcons/iconos/xfinal.png";

import time from "../assets/img/HrIcons/iconos/time.png";
const Hr = () => {
  const { rutina } = useParams();
  const history = useHistory();
  const infoBitacora1 = [
    ["Correr", correr, useRef(null)],
    ["Caminar", caminar, useRef(null)],
    ["Nadar", nadar, useRef(null)],
    ["Bicicleta", bicicleta, useRef(null)],
  ];
  const [modal, setModal] = useState(null);
  const [Km, setKm] = useState(0.01);
  const [Kcal, setKcal] = useState(0.01);
  const [Bmp, setBmp] = useState(180);

  const hideModal = () => {
    setModal(null);
  };

  const slidershr = infoBitacora1.map((e, idx) => (
    <SwiperSlide className="bitacoraSlider col-xl-6">
      <div className="bitacoraTitle1">Ritmo Cardiaco</div>
      <div className="bitacoraTitle2">
        Selecciona la disciplina cardiovascular que desees practicar
      </div>
      <img src={e[1]} alt={e[1]} className="bitacoraTitle5" />
      <div className="bitacoraTitle" key={idx}>
        {e[0]}
      </div>

      <div className="stepsBitacoraz mx-auto"></div>
      <ButtonMob
        colorButton={"#CB3D82"}
        text="Empezar entrenamiento"
        className="mt-3 w-60 mx-auto  d-flex justify-content-center letrabotonemep "
        onClick={() => {
          setModal(
            <ModalInfo
              icono={descargalapp}
              handleClick={() =>
                isIOS
                  ? window.open(
                      `https://apps.apple.com/co/app/mob-fitness/id1565360052`
                    )
                  : window.open(
                      `https://play.google.com/store/apps/details?id=com.fitness.mob`
                    )
              }
              error="none"
              handleCancel={() => history.goBack()}
              title="Descarga la App"
              text={
                "Descarga la app en AppStore o PlayStore y disfruta de estos y mas beneficios que solo Elevva tiene para ti "
              }
              classesMsg=""
            />
          );
        }}
      />
    </SwiperSlide>
  ));

  let stopwatchInterval;
  let runningTime = 0;

  const playPause = () => {
    const playPauseButton = document.getElementById("play-pause");
    const isPaused = !playPauseButton.classList.contains("running");

    if (isPaused) {
      playPauseButton.classList.add("running");
      playPauseButton.querySelector(".paused").classList.add("running");
      start();
    } else {
      playPauseButton.classList.remove("running");
      playPauseButton.querySelector(".paused").classList.remove("running");
      pause();
    }
  };

  const pause = () => {
    clearInterval(stopwatchInterval);
  };

  const stop = () => {
    const stopwatch = document.getElementById("stopwatch");
    const playPauseButton = document.getElementById("play-pause");
    if (playPauseButton) {
      playPauseButton.classList.remove("running");
      let styleModal = {
        display: "flex",
        position: "fixed",
        zIndex: 999,
        whiteSpace: "pre-line",
      };
      setModal(
        <div
          className="modalInfo justify-content-center align-items-center"
          style={styleModal}
        >
          <div className="modalInfoContenido ">
            <div className="icon my-2">
              <img src={xfinal} width="60vw" alt="cara" />
            </div>
            <div className="title">¿Deseas finalizar tu entrenamiento?</div>

            <div
              className="btnAction letraspopfi1"
              onClick={() =>
                (window.location.href = `/Usuario/HR/results/${rutina}`)
              }
            >
              <ButtonMob
                text="Finalizar"
                className="mt-3 mb-3 btn-danger letraspopfi"
              />
            </div>
            <br />
            <div className="btnAction letraspopfi2" onClick={hideModal}>
              <ButtonMob
                text="Continuar entrenamiento"
                className="mb-3 letraspopfi"
              />
            </div>
          </div>
        </div>
      );
      playPauseButton.querySelector(".paused").classList.remove("running");
      runningTime = 0;
      clearInterval(stopwatchInterval);
      stopwatch.textContent = "00:00";
    }
  };

  const start = () => {
    let startTime = Date.now() - runningTime;
    const stopwatch = document.getElementById("stopwatch");
    stopwatchInterval = setInterval(() => {
      runningTime = Date.now() - startTime;
      stopwatch.textContent = calculateTime(runningTime);
    }, 1000);
  };

  const calculateTime = (runningTime) => {
    const total_seconds = Math.floor(runningTime / 1000);
    const total_minutes = Math.floor(total_seconds / 60);

    const display_seconds = (total_seconds % 60).toString().padStart(2, "0");
    const display_minutes = total_minutes.toString().padStart(2, "0");

    return `${display_minutes}:${display_seconds}`;
  };

  return !rutina ? (
    <div className="Hr">
      <Swiper
        pagination={{
          el: ".stepsBitacoraz",
          type: "custom",
          renderCustom: function (swiper, current, total) {
            current -= 1;
            return infoBitacora1
              .map((value, index) => {
                return `<div class='steps mx-1 rounded-circle ${
                  current == index ? "active" : ""
                }'></div>`;
              })
              .join("");
          },
        }}
      >
        {slidershr}
        {modal}
      </Swiper>
    </div>
  ) : (
    <>
      {modal}

      <div className="Hr bitacoraSlider1">
        <div className=" col-xl-6">
          <div className="bitacoraTitle1">{rutina}</div>
          <div className="bitacoraTitle">Información de entrenamiento</div>

          <div className="d-flex jc-center tamañoestadistics ">
            <div className="m-4  ">
              <img className=" imagesestadiskm" src={km} />
              <div className=" letrasestadis">{Km}</div>
              <div className=" letrasestadis1">Km</div>
            </div>
            <div className=" borderkm"></div>
            <div className="m-4 ">
              <img className=" imagesestadisfire" src={fire} />
              <div className=" letrasestadis">{Kcal}</div>
              <div className=" letrasestadis1">Kcal</div>
            </div>
            <div className=" borderkm"></div>
            <div className="m-4 ">
              <img className=" imagesestadiscardio" src={cardio} />
              <div className=" letrasestadis">{Bmp}</div>
              <div className=" letrasestadis1">Bmp</div>
            </div>
          </div>
        </div>
        <div className="d-flex jc-center mt-0">
          <div className="d-row jc-center mt-0">
            <img className="relojtime" src={time} />
            <div id="stopwatch" className="stopwatch letrasestadis">
              00:00
            </div>
            <div className=" letrasestadis1">Duración</div>
            <div className="cronometro">
              <button
                className="botonstart "
                id="play-pause"
                onClick={playPause}
              >
                <img className="paused " />
              </button>
              <button className="botonstop " onClick={stop}>
                {" "}
                <img className="stop " />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hr;
