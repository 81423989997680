import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customers_bookings } from "../actions/customerBookings";
import {
  ButtonMob,
  laptop575,
  NuevaAxiosURL,
  Loadingspinner,
  instance,
  userType,
  filterCheckDays,
  basename,
} from "../utils/utils";

import { useLocation } from "react-router-dom";
import intensidad_img from "../assets/img/reservasimages/icon_extra_1a.png";

import { Link, useHistory } from "react-router-dom";
import imagenarribablack from "../assets/img/UserIcons/iconos/imagenabajonegra (2).png";
import imagenabajoblack from "../assets/img/UserIcons/iconos/imagenabajonegra (2).png";
import pepitasnegras from "./pepitasnegras.png";
import "./Inicio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/components/pagination/pagination.min.css";
import axios from "axios";
import pesos from "../assets/img/UserIcons/iconos/dolar1B.png";

import calendar from "../assets/img/reservasimages/calendar.png";
import calendarA from "../assets/img/UserIcons/iconos/calendarA.png";
import individual from "../assets/img/UserIcons/iconos/individual.png";
import grupales_true from "../assets/img/UserIcons/iconos/Grupo.png";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Media from "react-media";
import aunno from "../assets/img/UserIcons/iconos/aunno.png";
import confirmado from "../assets/img/UserIcons/iconos/confirmad (1).png";

import cursoya from "../assets/img/UserIcons/iconos/encurso.png";
import imagenreservarmore from "../assets/img/fondo/bg_clase.png";

// 020491
SwiperCore.use([Pagination, Autoplay]);
const Usuario = (props) => {
  const dataCustomer = props.dataCustomer;

  const [Dashboard, setDashboard] = useState(null);
  const [copyAllEntrenamientos, setCopyAllEntrenamientos] = useState(null);

  function conversion(mins) {
    // getting the hours.
    let hrs = Math.floor(mins / 60);
    // getting the minutes.
    let min = mins % 60;
    // formatting the hours.
    hrs = hrs < 10 ? "0" + hrs : hrs;
    // formatting the minutes.
    min = min < 10 ? "0" + min : min;
    // returning them as a string.
    return `${hrs}:${min}`;
  }
  const [renderReturn, setReturn] = useState(null);

  const [infoDias, setDias] = useState(null);
  const [vencenCreditos, setVencimientoCreditos] = useState("DD/MM/YYYY");

  const [PublicidadBanner, setPublicidadBanner] = useState(null);
  const moment = extendMoment(Moment);
  const day_hoy = moment();

  const inicioday =
    day_hoy.subtract(day_hoy.day(), "days").format().slice(0, 11) + "00:00";
  const findesemana =
    day_hoy
      .add(6 - day_hoy.day(), "days")
      .format()
      .slice(0, 11) + "00:00";
  useEffect(() => {
    instance
      .get(`${NuevaAxiosURL}customers/dashboard/`)
      .then((resEn) => {
        const info = resEn.data;
        if (info) {
          setDashboard(info);
        }
      })
      .catch((err) => {
        console.error("Error en customers/dashboard/", err.response);
      });

    setVencimientoCreditos(
      dataCustomer.customer && dataCustomer.customer.credits_expiration_date
        ? dataCustomer.customer.credits_expiration_date
        : moment().add(30, "days").format(0, 11)
    );

    instance
      .get(`booking/?finished&date__lte=${findesemana}&date__gte=${inicioday}`)

      .then((res) => {
        const daysselected = res.data;
        if (daysselected != null) {
          setDias(filterCheckDays(daysselected));
        }
      });
  }, []);
  useEffect(() => {
    instance
      .get(`trainings/`)
      .then((resEn) => {
        setCopyAllEntrenamientos(resEn.data);
      })
      .catch((err) => console.log(err.response));
    instance.get(`app/slides`).then((res) => {
      const Ninfo = res.data;
      if (Ninfo) {
        setPublicidadBanner(res.data);
      }
    });
  }, []);

  const theme = {
    // background: "#222222",
    axis: {
      fontSize: "3px",

      tickColor: "#eee",
      ticks: {
        line: {
          stroke: "#555555",
        },
        text: {
          fill: "#555555",
        },
      },
      legend: {
        text: {
          fontSize: "1vw",
          fill: "#2A9DA5",
          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "#B5B2B2",
        opacity: "0.2",
      },
    },
  };
  const hideModalDesempeño = () => {
    setModalDesempeño(null);
  };
  const [alllevels, setalllevels] = useState(null);
  const [ModalDesempeño, setModalDesempeño] = useState(null);
  useEffect(() => {
    const levels = [];
    const getData = async () => {
      await instance.get(`customers/level/`).then(async (res) => {
        const daysselected = res.data;

        if (daysselected) {
          for (const i of daysselected) {
            const identrenamiento = i.training;
            const lev = i.level;
            const uuid = i.id;
            const sublev = i.sublevel;
            const current_sesio = i.current_session;
            await instance.get(`trainings/${identrenamiento}/`).then((res) => {
              let info = res.data;
              if (info && info.group == false) {
                levels[identrenamiento] = {
                  id: identrenamiento,
                  uuid: uuid,
                  titulo: info.name.toLowerCase(),
                  descripcion: info.description,
                  level: lev,
                  subnivel: sublev,
                  current_session: current_sesio,
                };
              }
            });
          }

          //
        }
      });

      setalllevels(levels.filter((e) => e));
    };
    getData();
  }, []);

  return (
    <>
      {renderReturn ? (
        renderReturn
      ) : (
        <>
          {" "}
          {/* <div className="rodarpues" id="box" onScroll={elementScrollData}> */}
          <div className="rodarpues" id="box">
            <div className="bievenidoainicio" style={{ marginLeft: "10vw" }}>
              <div className="d-flex">
                <div className="SubTitle8perfilcual corrgircoso">
                  Bienvenido a
                </div>
                <div className="lineainiciousuario corrgircoso1"></div>
              </div>
              <div className="title creditos">Elevva</div>
            </div>
            <div className="containerInicio Inicio">
              <div className="col-8"></div>
              {PublicidadBanner ? (
                <BannerPublicidad imagenes={PublicidadBanner} />
              ) : null}
              <div className="lineainiciousuariosolita"></div>
              {dataCustomer.customer &&
              dataCustomer.customer.credits_balance >= 0 &&
              vencenCreditos ? (
                <MisCreditos
                  dataCustomer={dataCustomer}
                  vencenCreditos={vencenCreditos}
                />
              ) : null}
              <MisClasess />
              <div className=" posicionbotonentrenamientos1">
                <div className="px-1">
                  <ButtonMob
                    colorButton={"#9EACED"}
                    onClick={() =>
                      (window.location.href = `${basename}/${userType}/MisClases`)
                    }
                    text="Ver todas las reservas"
                    className="verReservasEntrenador lh-1 py-3 "
                  />
                </div>
                <div className="px-1">
                  <ButtonMob
                    colorButton={"#CB3D82"}
                    onClick={() =>
                      (window.location.href = `${basename}/${userType}/reservas`)
                    }
                    text="Nueva reserva"
                    className="verReservasEntrenador lh-1 py-3  "
                  />
                </div>
              </div>
            </div>
            <div className="nuevosentrenamientos2">
              <div className="title">Datos de tu entrenamiento (Total)</div>
              <div className="d-flex jc-center tamañoestadistics ">
                <div className="m-4  ">
                  <div className=" letrasestadis letrasdatosentrea">
                    {Dashboard && Dashboard.total.activity_days}
                  </div>
                  <div className="  letrasdatosentrea2">días</div>
                  <div className=" letrasestadis1 letrasdatosentrea1">
                    tiempo de asistencia total{" "}
                  </div>
                </div>
                <div className=" borderkm"></div>
                <div className="m-4 ">
                  <div className=" letrasestadis letrasdatosentrea">
                    {conversion(Dashboard && Dashboard.total.activity_time)}
                  </div>
                  <div className="d-flex m-auto letrasdatosentrea3">
                    <div className="  letrasdatosentrea2 mx-4">horas</div>
                    <div className="  letrasdatosentrea2 mx-4">minutos</div>
                  </div>
                  <div className=" letrasestadis1 letrasdatosentrea1">
                    Tiempo de actividad total
                  </div>
                </div>
                <div className=" borderkm"></div>
                <div className="m-4 ">
                  <div className=" letrasestadis letrasdatosentrea">
                    {Dashboard && Dashboard.total.kcal}
                  </div>
                  <div className="d-flex m-auto letrasdatosentrea3 ">
                    <div className="  letrasdatosentrea2 my-2"></div>
                    <div className="  letrasdatosentrea2 my-2"></div>
                  </div>
                  <div className=" letrasestadis1 letrasdatosentrea1">
                    Total calorías quemadas
                  </div>
                </div>
              </div>
              <div className="title">
                Datos de tu entrenamiento (Semana actual)
              </div>
              <div className="d-flex jc-center tamañoestadistics ">
                <div className="m-4  ">
                  <div className=" letrasestadis letrasdatosentrea">
                    {Dashboard && Dashboard.weekly.activity_days}
                  </div>
                  <div className="  letrasdatosentrea2">días</div>
                  <div className=" letrasestadis1 letrasdatosentrea1">
                    tiempo de asistencia{" "}
                  </div>
                </div>
                <div className=" borderkm"></div>
                <div className="m-4 ">
                  <div className=" letrasestadis letrasdatosentrea">
                    {conversion(Dashboard && Dashboard.weekly.activity_time)}
                  </div>
                  <div className="d-flex m-auto letrasdatosentrea3">
                    <div className="  letrasdatosentrea2 mx-4">horas</div>
                    <div className="  letrasdatosentrea2 mx-4">minutos</div>
                  </div>
                  <div className=" letrasestadis1 letrasdatosentrea1">
                    Tiempo de actividad PROMEDIO
                  </div>
                </div>
                <div className=" borderkm "></div>
                <div className="m-4 ">
                  <div className=" letrasestadis letrasdatosentrea">
                    {Dashboard && Dashboard.weekly.kcal}
                  </div>
                  <div className="d-flex m-auto letrasdatosentrea3 ">
                    <div className="  letrasdatosentrea2 my-2"></div>
                    <div className="  letrasdatosentrea2 my-2"></div>
                  </div>
                  <div className=" letrasestadis1 letrasdatosentrea1">
                    Total calorías quemadas
                  </div>
                </div>
              </div>
            </div>
            <div className="containerGraficas containerGraficasweb">
              <div className="semana semana1">{infoDias}</div>
              <div className="msg">
                Querido(a) Elevva+ , acá podrás ver tu rendimiento por
                disciplinas. Supérate con cada entrenamiento y alcanza un nuevo
                nivel.
              </div>
            </div>
            <br />
            <div className="nuevosentrenamientos">
              <div className="colorblack">
                <div>
                  <div className="title">Nuevos entrenamientos</div>
                  <div className="titledebajo ">
                    Con ELEVVA puedes alternar tu entrenamiento con diferentes
                    disciplinas que complementan tu acondicionamiento físico.
                    ¡Elige y programa tus clases dirigidas y diseñadas a tu
                    medida!
                  </div>

                  <div className="contenedorGaleria ">
                    {copyAllEntrenamientos
                      ? copyAllEntrenamientos
                          .slice(0, 1)
                          .map((entrenamiento) => (
                            <div
                              className="content img1"
                              style={{
                                backgroundImage: `url(${entrenamiento.cover_picture})`,
                              }}
                            >
                              <img
                                className=" iconNetflixnew1"
                                src={entrenamiento.icon}
                                alt={entrenamiento.name}
                              />
                              <br />
                              <div className="letraswhite1">
                                <span
                                  style={{
                                    color: "white",
                                    opacity: "1",
                                    zIndex: "1",
                                  }}
                                >
                                  {" "}
                                  {entrenamiento.name}
                                </span>
                              </div>
                            </div>
                          ))
                      : null}
                    {copyAllEntrenamientos
                      ? copyAllEntrenamientos

                          .slice(1, 2)
                          .map((entrenamiento) => (
                            <div
                              className="content img2"
                              style={{
                                backgroundImage: `url(${entrenamiento.cover_picture})`,
                              }}
                            >
                              <img
                                className="iconNetflixnew2 "
                                src={entrenamiento.icon}
                                alt={entrenamiento.name}
                              />
                              <br />
                              <div className="letraswhite2">
                                {entrenamiento.name}
                              </div>
                            </div>
                          ))
                      : null}
                    <div className="d-row imagenconte">
                      {copyAllEntrenamientos
                        ? copyAllEntrenamientos

                            .slice(2, 3)
                            .map((entrenamiento) => (
                              <div
                                className="content img3"
                                style={{
                                  backgroundImage: `url(${entrenamiento.cover_picture})`,
                                }}
                              >
                                <img
                                  className="iconNetflixnew3 "
                                  src={entrenamiento.icon}
                                  alt={entrenamiento.name}
                                />
                                <br />
                                <div className="letraswhite3">
                                  {entrenamiento.name}
                                </div>
                              </div>
                            ))
                        : null}
                      {copyAllEntrenamientos
                        ? copyAllEntrenamientos

                            .slice(3, 4)
                            .map((entrenamiento) => (
                              <div
                                className="content img4"
                                style={{
                                  backgroundImage: `url(${entrenamiento.cover_picture})`,
                                }}
                              >
                                <img
                                  className=" iconNetflixnew4"
                                  src={entrenamiento.icon}
                                  alt={entrenamiento.name}
                                />
                                <br />
                                <div className="letraswhite4">
                                  {entrenamiento.name}
                                </div>
                              </div>
                            ))
                        : null}
                      <ButtonMob
                        colorButton={"#CB3D82"}
                        onClick={() =>
                          (window.location.href = `${basename}/${userType}/reservas`)
                        }
                        text="Ver todos los entrenamientos"
                        className="verReservasEntrenador lh-1 py-3  posicionbotonentrenamientos"
                      />
                    </div>
                  </div>

                  <img src={pepitasnegras} className="pepitasnegras"></img>
                </div>
              </div>
            </div>
          </div>
          {ModalDesempeño}
        </>
      )}
    </>
  );
};

const BannerPublicidad = (props) => {
  const imagenes = props.imagenes;
  function importAll(r) {
    return r.keys().map(r);
  }

  const render_img_mobile = imagenes.map((imagen, index) => {
    const def_img = imagen.mobile_picture;
    return (
      <SwiperSlide key={index} className="sliderimg">
        <img src={def_img} alt={"mobile" + index} />
      </SwiperSlide>
    );
  });
  const render_img_desk = imagenes.map((imagen, index) => {
    const def_img = imagen.picture;
    return (
      <SwiperSlide key={index} className="sliderimg">
        <img src={def_img} alt={"desk" + index} />
      </SwiperSlide>
    );
  });

  return (
    <>
      <Media query="(max-width: 480px)">
        {(matches) =>
          matches ? (
            <Swiper
              className="sliderPublicidad"
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
              }}
              pagination={{
                el: ".bulletsPublicidad",
                type: "custom",
                renderCustom: function (swip, current, total) {
                  current -= 1;
                  return render_img_mobile
                    .map((value, index) => {
                      return `<div class='swiper-pagination-bullet mx-2 ${
                        current == index ? "active" : ""
                      }'></div>`;
                    })
                    .join("");
                },
              }}
            >
              {render_img_mobile}
            </Swiper>
          ) : (
            <Swiper
              className="sliderPublicidad"
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
              }}
              pagination={{
                el: ".bulletsPublicidad",
                type: "custom",
                renderCustom: function (swip, current, total) {
                  current -= 1;
                  return render_img_desk
                    .map((value, index) => {
                      return `<div class='swiper-pagination-bullet mx-2 ${
                        current == index ? "active" : ""
                      }'></div>`;
                    })
                    .join("");
                },
              }}
            >
              {render_img_desk}
            </Swiper>
          )
        }
      </Media>
      <div className="bulletsPublicidad"></div>
    </>
  );
};
const MisCreditos = (props) => {
  const moment = extendMoment(Moment);

  const vencenCreditos = props.vencenCreditos;
  const dataCustomer = props.dataCustomer;
  return (
    <>
      {!laptop575.matches ? (
        <>
          <div className="title creditos">
            Mis créditos
            <img
              src={pesos}
              alt="Pesos1"
              className=""
              style={{ marginLeft: "2vw", width: "7vmin" }}
            />
          </div>
          <div
            className="misCreditos"
            style={{ padding: "auto", margin: "auto" }}
          >
            {("0000" + dataCustomer.customer.credits_balance).slice(-3)}
            <div className="vencenCreditos">
              Vencen el{" "}
              {moment(vencenCreditos).format("dddd DD MMMM [del] YYYY")}
            </div>
          </div>
          <div className="newBtnReserva">
            <Link to={`/${userType}/reservas`}>
              <ButtonMob
                style={{ marginTop: "1rem" }}
                text="Nueva reserva"
                className="text-left ps-4 btnNuevaReserva"
                image={calendar}
                classesImg="btnCalendar ms-3"
              />
            </Link>
          </div>
        </>
      ) : (
        <div className="totalacomode">
          <div className="titleladocreditos">Tus clases reservadas</div>
          <div className="listaReservas mx-auto d-flex completarelpor">
            <div className=" invertido">
              <div className="cajaazul">
                <div className="d-row">
                  <div className="title creditos " style={{ color: "#394F65" }}>
                    Mis créditos
                  </div>
                  <div className="misCreditos">
                    {("0000" + dataCustomer.customer.credits_balance).slice(-3)}
                  </div>
                </div>
                <div className="vencenCreditos">
                  Tus créditos vencen el{" "}
                  {moment(vencenCreditos).format("dddd DD MMMM [de] YYYY")}
                  <Link to={`/${userType}/creditos`}>
                    <ButtonMob
                      colorButton={"#CB3D82"}
                      invertido
                      text="Comprar créditos"
                      className="text-left px-3 btnNuevaReservabot"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const MisClasess = (props) => {
  const [zonahorairachange, setZonahorairachange] = useState(null);
  useEffect(() => {
    axios.get("https://ipwhois.app/json/").then((res) => {
      if (res.data) {
        setZonahorairachange(res.data.timezone);
      }
    });
  }, []);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { dataBookings } = state.customerBookings;
  const [modalInfo, setModalInfo] = useState(null);

  const path = useLocation();
  function hideModal() {
    setModalInfo(null);
  }

  const [renderReservas, setRenderReservas] = useState(
    <Loadingspinner
      customStyle="border"
      size="3rem"
      classloading={
        laptop575.matches ? "col-2 text-muted text-center m-auto" : null
      }
    />
  );

  const moment = extendMoment(Moment);
  require("moment-timezone");

  const inicioday = moment().format().slice(0, 11) + "00:00";
  const finislongdays =
    moment().add(30, "days").format().slice(0, 11) + "00:00";
  let intervalSearchs = null;
  useEffect(() => {
    dispatch(customers_bookings(finislongdays, inicioday));
    intervalSearchs = setInterval(async () => {
      dispatch(customers_bookings(finislongdays, inicioday));
    }, 10000);
    return () => {
      clearInterval(intervalSearchs);
    };
  }, []);
  useEffect(() => {
    if (dataBookings && dataBookings.length > 0) {
      const arrayde_nnu = laptop575.matches
        ? dataBookings
            .filter((e) => e)
            .sort(function (a, b) {
              var nameA = b.date; // ignore upper and lowercase
              var nameB = a.date; // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            })
            .slice(0, 3)
        : dataBookings
            .filter((e) => e)
            .sort(function (a, b) {
              var nameA = b.date; // ignore upper and lowercase
              var nameB = a.date; // ignore upper and lowercase
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            });
      setRenderReservas(
        arrayde_nnu
          .sort(function (a, b) {
            return (
              moment(a.date.slice(0, 10), "YYYY-MM-DD") -
              moment(b.date.slice(0, 10), "YYYY-MM-DD")
            );
          })
          .map((el, index) => {
            let bg_img = laptop575.matches ? el.rawImg : el.img;
            let is_group = el.is_group;
            const status_reserva = el.status_reserva;
            const name = el.titulo;
            const fecha = moment.tz(el.date, zonahorairachange);

            const hora = moment.tz(el.date, zonahorairachange).format("LT ");
            const journadeHour = moment
              .tz(el.date, zonahorairachange)
              .format("a");
            // console.log(hora.format("LTS"), el.date.slice(11, 16));

            const url = `/${userType}/reservas/ver/` + el.id;
            return (
              <>
                <Link
                  key={index}
                  push
                  to={url}
                  className={laptop575.matches ? "col-4" : "col-12"}
                >
                  <div
                    className="itemReserva"
                    style={{
                      backgroundImage: `url(${bg_img}) `,
                      position: "relative",
                    }}
                  >
                    <div className=" bg"></div>
                    <span
                      className={laptop575.matches ? "text1 " : "text1_usuario"}
                    >
                      <div className="d-flex" style={{ color: "white" }}>
                        <div>Entrenamiento</div>
                        {laptop575.matches ? (
                          <div className="linere"></div>
                        ) : null}
                      </div>

                      <span
                        className="text2 container_name1"
                        style={{ color: "white" }}
                      >
                        {name.toLowerCase()}
                      </span>
                    </span>
                    <div
                      className=""
                      style={
                        laptop575.matches
                          ? {
                              position: "absolute",
                              top: "5%",
                              left: "0%",
                            }
                          : {
                              position: "absolute",
                              top: "10%",
                              right: "0%",
                            }
                      }
                    >
                      <div
                        className=""
                        style={
                          laptop575.matches
                            ? {
                                fontSize: "0.8rem",
                                position: "absolute",
                                fontWeight: "700",
                                top: "24%",
                                left: "10%",
                                textAlign: "left",
                                zIndex: "1",
                              }
                            : {
                                fontSize: "0.5rem",
                                position: "absolute",
                                zIndex: "1",
                                fontWeight: "500",
                                padding: "0.5rem 1.5rem",
                              }
                        }
                      >
                        {status_reserva === 10 ? (
                          <div>
                            {" "}
                            <span>EN ESPERA DE</span>
                            <br />
                            <span> CONFIRMACIÓN</span>{" "}
                          </div>
                        ) : status_reserva === 20 ? (
                          <div>
                            {" "}
                            <span>RESERVA</span>
                            <br />
                            <span> CONFIRMADA</span>{" "}
                          </div>
                        ) : status_reserva === 30 ? (
                          <div>
                            {" "}
                            <span>RESERVA</span>
                            <br />
                            <span> EN CURSO</span>{" "}
                          </div>
                        ) : (
                          <span style={{ margin: "auto 2%" }}> EN ESPERA</span>
                        )}
                      </div>
                      <img
                        width={laptop575.matches ? 150 : 100}
                        style={
                          laptop575.matches
                            ? { transform: "rotate(180deg)" }
                            : { position: "relative" }
                        }
                        src={
                          status_reserva === 10
                            ? aunno
                            : status_reserva === 20
                            ? confirmado
                            : status_reserva === 30
                            ? cursoya
                            : cursoya
                        }
                        alt="sd_im"
                      ></img>
                    </div>
                    {laptop575.matches ? (
                      <div className="bot">
                        <div className="type">
                          <div>
                            <img
                              src={is_group ? grupales_true : individual}
                              alt="individual"
                            />
                            <br />
                            {is_group ? "Grupal" : "Individual"}
                          </div>
                        </div>
                        <div className="date">
                          <div className="content">
                            <div className="lineunitreser"></div>
                            <span>{fecha.format("dddd")}</span>
                            <br />
                            <span className="horasgrandes">
                              {fecha.format("DD")}
                            </span>
                            <br />
                            <span>{fecha.format("[De] MMMM")}</span>
                            <br />
                            <div className="lineunitreser"></div>
                            <span>Horario</span>
                            <br />
                            <span className="horasgrandes">{hora}</span>
                            <br />
                            <span>{journadeHour}</span>
                          </div>
                          <div className="ver">
                            Ver <i className="fa fa-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="bot">
                        <div className="type">
                          <div>
                            <img
                              style={{ padding: "0 2.7vw" }}
                              src={is_group ? grupales_true : individual}
                              alt="individual"
                            />
                            <br />
                            {is_group ? "  Grupal  " : "Individual"}
                          </div>
                        </div>

                        <div className="date">
                          <div className="content">
                            <span>{fecha.format("dddd")}</span>
                            <br />
                            <b>{fecha.format("  DD [de] MMMM ")}</b>
                            <b
                              style={{
                                textTransform: "lowercase",
                              }}
                            >
                              {fecha.format("LT a")}
                            </b>
                          </div>
                        </div>
                        <br />
                        <div
                          className="ver"
                          style={{ backgroundColor: "#7B86FA" }}
                        >
                          Ver
                        </div>
                      </div>
                    )}
                  </div>
                </Link>
              </>
            );
          })
      );
    } else {
      setRenderReservas(
        <div
          className={
            laptop575.matches
              ? "col-8 text-muted text-center"
              : "col-12 text-muted text-center mt-4"
          }
          style={
            laptop575.matches
              ? {
                  fontSize: "1.5rem",
                  justifyContent: "center",
                  margin: "auto",
                }
              : { fontSize: "1rem", margin: "auto" }
          }
        >
          No se encuentran reservas.
        </div>
      );
    }
  }, [dataBookings]);

  return (
    <>
      <div className="cajaDeClases">
        <div className="title tusClasesTitulo">
          Tus clases reservadas
          {!laptop575.matches ? (
            <img
              style={{ width: "7vmin", marginLeft: "3vw" }}
              src={calendarA}
              alt="calendario"
            />
          ) : null}
        </div>

        {
          <div className="listaReservas mx-auto row">
            {" "}
            {renderReservas.length > 0 ? null : (
              <img
                onClick={() => (window.location.href = `/${userType}/reservas`)}
                className="listaReservasimagenreservar"
                src={imagenreservarmore}
              />
            )}
            {renderReservas}
          </div>
        }
        {modalInfo}
      </div>
    </>
  );
};

export default Usuario;
