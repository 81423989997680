import { ButtonMob, basename } from "../utils/utils";
import alert from "../assets/img/creditos/imagenespayu/alert.png";
import check from "../assets/img/creditos/imagenespayu/check.png";
import exis from "../assets/img/creditos/imagenespayu/exis.png";
import time from "../assets/img/creditos/imagenespayu/time.png";
import { laptop575 } from "../utils/utils";
const ResponsePayu = () => {
  let params = new URLSearchParams(window.location.search);

  var contract = params.get("transactionState");

  let estadoTx = "";
  let text = "";
  let estadoTxdebajo = "";
  let imagen = null;
  let onclicks = null;
  if (contract == 4) {
    estadoTx = "Tu transacción ha sido aprobada";
    text = "Ir a reservar";
    estadoTxdebajo =
      "Puedes usar tus créditos para reservar tus clases y comenzar a entrenar al estilo Elevva.";
    imagen = check;
    onclicks = () => (window.location.href = `${basename}/Usuario/inicio`);
  } else if (contract == 6) {
    estadoTx = "Tu transacción ha sido rechazada";
    imagen = exis;
    text = "Volver";
    estadoTxdebajo = "Por favor intenta nuevamente en unos momentos.";

    onclicks = () => (window.location.href = `${basename}/Usuario/creditos`);
  } else if (contract == 104) {
    estadoTx = "Error";
    imagen = alert;
    text = "Volver";
    onclicks = () => (window.location.href = `${basename}/Usuario/creditos`);
  } else if (contract == 7) {
    estadoTx = "Tu transacción esta pendiente";
    imagen = time;
    estadoTxdebajo =
      "Por favor espera unos minutos para confirmación de la misma.";
    text = "Volver";
    onclicks = () => (window.location.href = `${basename}/Usuario/creditos`);
  } else {
    estadoTx = params.get("transactionState");
    imagen = alert;
    estadoTxdebajo = "Por favor intenta nuevamente en unos momentos.";
    text = "Volver";
    onclicks = () => (window.location.href = `${basename}/Usuario/creditos`);
  }
  return (
    <div
      style={laptop575.matches ? null : { margin: "auto", width: "80%" }}
      className={
        laptop575.matches
          ? "ContainerCreditos_eleva_response"
          : "ContainerCreditos"
      }
    >
      <div
        className={
          laptop575.matches
            ? "title_eleva_creditos mx-auto my-4"
            : "title mx-auto my-4"
        }
      >
        Compra tus créditos
      </div>

      <img
        src={imagen}
        style={{ margin: "auto", display: "block", width: "8vmin" }}
      />
      <div
        className={
          laptop575.matches
            ? "title_eleva_creditos mx-auto my-4"
            : "title mx-auto my-4"
        }
        style={{ fontSize: "1rem" }}
      >
        {estadoTx}
      </div>
      <div
        className={
          laptop575.matches
            ? "title_eleva_creditos mx-auto my-4"
            : "title mx-auto my-4"
        }
        style={{
          fontSize: "1rem",
          color: "#9DACBD",
          fontSize: "0.8rem",
          fontWeight: "400",
        }}
      >
        {estadoTxdebajo}
      </div>
      <ButtonMob
        colorButton={"#CB3D82"}
        onClick={onclicks}
        text={text}
        className="mt-5 d-flex justify-content-center py-2 px-4 w-50"
        classesImg="btnCalendar ms-3 "
      />
    </div>
  );
};
export default ResponsePayu;
