import { Reservas } from "../Inicio/InicioEntrenador";

import Moment from "moment";
import { extendMoment } from "moment-range";
import "./Reservas.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actualBook } from "../../actions/actualBook";
import { laptop575 } from "../../utils/utils";
const EntrenadorReserva = (props) => {
  const moment = extendMoment(Moment);
  const user = props.user;
  const iniciodayReservas = moment().format().slice(0, 11) + "00:00";
  const finislongdaysReserva =
    moment().add(30, "days").format().slice(0, 11) + "00:00";
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const { datagroup } = state.trainerActualBook;
  useEffect(() => {
    dispatch(actualBook(finislongdaysReserva, iniciodayReservas));
  }, [user]);
  return (
    <div className="entrenadorPerfil">
      <div className="cabeceraPerfil">
        <div
          className="entrenadorInicioContainer"
          style={{ overflowY: "hidden", height: "75%" }}
        >
          <div className="bievenidoa_reservas">
            <div className=" col-12 d-flex mt-5">
              <div className="SubTitle8perfilcual  ">Bienvenido a</div>
              <div className="lineainiciousuario "></div>
            </div>
            <div className="titlec1 text-left col-12 ">Mis clases</div>
            <div
              className="SubTitle8perfilcual text-left col-12  nosvemos mb-4"
              style={{ color: "#9DACBD", width: "100%" }}
            >
              Todas tus clases reservadas, siempre puedes consultarlas y revisar
              tus horarios.
            </div>
          </div>
          <div
            className="sliderReservas listaReservas  listaReservasentre mx-auto"
            style={
              laptop575.matches
                ? {
                    overflowX: "hidden",
                    overflowY: "auto",

                    width: "92%",
                    height: "calc(100vh - 220px)",
                  }
                : {
                    left: "4%",
                    overflowX: "hidden",
                    overflowY: "scroll",

                    width: "100%",
                    height: "75vh",
                  }
            }
          >
            <div
              className="row mx-auto"
              style={laptop575.matches ? { width: "88%" } : { width: "100%" }}
            >
              <Reservas
                limit={100}
                user={user}
                mode="linea"
                datagroup={datagroup}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrenadorReserva;
