import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import button2 from "../assets/img/Auth_images/Icon awesome-arrow-circle-left.png";
import logo from "../assets/img/general/logo_alta.png";
import { useState, useEffect } from "react";
import imgarriba from "../assets/img/Auth_images/arribaimagen.png";
import imgabajo from "../assets/img/Auth_images/abajoimagen.png";
import { Link, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import {
  ButtonMob,
  NuevaAxiosURL,
  ModalInfo,
  userType,
  entrenadorType,
  backendURL,
  basename,
  Loadingspinner,
  SetModalError,
  laptop575,
} from "../utils/utils";
import axios from "axios";

import logo_blanco from "../assets/img/Auth_images/Grupo 100.png";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Registro = (props) => {
  const type = props.type;

  const [actualIconEye1, setActualIcon1] = useState(faEye);
  const [actualIconEye2, setActualIcon2] = useState(faEye);
  const [showOrNo1, setShow1] = useState("password");
  const [showOrNo2, setShow2] = useState("password");
  const [displayError, setDisplayError] = useState("none");
  const [displayCorrect, setDisplayCorrect] = useState("none");
  const [DisplayCorrectphone, setDisplayCorrectphone] = useState("none");
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [Modal, setModal] = useState(null);
  const [tel, setTel] = useState(null);

  const Ocultar = () => {
    setTimeout(() => {
      setModal(null);
    }, 5000);
  };
  useEffect(() => {
    ReactGA.initialize("UA-238615776-1");
  }, []);
  const Activarcampaña = (e) => {
    // console.log("rancherias");
    ReactGA.pageview("bienvenido-a-elevva/");
  };
  const handleShowPass = (how) => {
    if (how == 1) {
      if (actualIconEye1 == faEye) {
        setActualIcon1(faEyeSlash);
        setShow1("text");
      } else {
        setActualIcon1(faEye);
        setShow1("password");
      }
    } else {
      if (actualIconEye2 == faEye) {
        setActualIcon2(faEyeSlash);
        setShow2("text");
      } else {
        setActualIcon2(faEye);
        setShow2("password");
      }
    }
  };

  const hideModal = () => {
    setModal(null);
  };

  const AutoLogin = (event) => {
    let servicio = "users/auth/login/";
    const usuario = event.target[0].value.trim();

    const password = event.target[3].value.trim();
    var formData = new FormData();

    formData.append("email", usuario);
    formData.append("password", password);
    axios({
      method: "post",
      contentType: "application/json",
      url: `${NuevaAxiosURL}${servicio}`,
      params: {
        t: new Date().getTime(),
      },
      data: formData,
    })
      .then((res) => {
        const data = res.data;

        if (!data) {
          setDisplayError("inherit");
          setTimeout(() => {
            setDisplayError("none");
          }, 5000);
        } else {
          localStorage.my_code = data.access;
          localStorage.type = type;

          const instance = axios.create({
            baseURL: NuevaAxiosURL,

            headers: { Authorization: "Bearer " + localStorage.my_code },
          });
          instance.get("users/current/").then((response) => {
            const data = response.data;
            localStorage.name = data.first_name;
            localStorage.lastname = data.last_name;
            localStorage.email = data.email;
            localStorage.type = type;
            localStorage.uuid = data.id;
            if (type == "Entrenador") {
              if (data.trainer.is_enabled) {
                document.location.href = `${basename}/${type}/inicio`;
              } else {
                document.location.href = `${basename}/OnboardingTrainer`;
              }
            } else {
              document.location.href = `${basename}/${type}/reservas`;
            }
          });
        }
      })
      .catch((err) => {
        setModal(<SetModalError msg={err.response} handleClick={hideModal} />);
      });
  };

  const handleSubmit = (event) => {
    if (event.target[7].checked == false) {
      return false;
    }
    if (tel.length < 13) {
      setTel(null);
      setDisplayCorrectphone("inherit");
      setTimeout(() => {
        setDisplayCorrectphone("none");
      }, 5000);
    }

    if (event.target[3].value.trim() != event.target[4].value.trim()) {
      setDisplayCorrect("inherit");
      setTimeout(() => {
        setDisplayCorrect("none");
      }, 5000);
    }
    if (tel.length >= 13) {
      var formData = new FormData();
      formData.append("email", event.target[0].value.trim()); //usuario
      formData.append("mobile_number", tel);
      formData.append("password", event.target[3].value.trim()); //pass1
      formData.append("password2", event.target[4].value.trim()); //pass2
      formData.append("type", type == "Usuario" ? 10 : 20); //pass2
      formData.append("first_name", event.target[5].value.trim()); //Nombre
      formData.append("last_name", event.target[6].value.trim()); //Apellido
    }

    if (type == entrenadorType) {
      axios({
        method: "post",
        contentType: "application/json",
        url: `${NuevaAxiosURL}users/auth/signin/`,
        params: {
          t: new Date().getTime(),
        },
        data: formData,
      })
        .then((res) => {
          const data = res.data;
          if (!data) {
            setDisplayError("inherit");
            setTimeout(() => {
              setDisplayError("none");
            }, 5000);
          } else {
            setModal(
              <ModalInfo
                error="false"
                handleClick={() => {
                  Ocultar();
                  AutoLogin(event);
                }}
                title="Tu solicitud
ha sido recibida"
                text="Ahora solo debes diligenciar el formato para ser un entrenador de nuestra plataforma."
              />
            );
          }
        })
        .catch((err) => {
          const msg_error = err.response.data;
          if (msg_error) {
            setDisplayCorrect("inherit");
            setTimeout(() => {
              setDisplayCorrect("none");
            }, 5000);
            setDisplayError("inherit");
            setTimeout(() => {
              setDisplayError("none");
            }, 5000);
            setDisplayCorrectphone("inherit");
            setTimeout(() => {
              setDisplayCorrectphone("none");
            }, 5000);

            seterroraGregado(msg_error);
          }
        });
      event.preventDefault();
    }
    if (type == userType) {
      axios({
        method: "post",
        contentType: "application/json",
        params: {
          t: new Date().getTime(),
        },
        url: `${NuevaAxiosURL}users/auth/signin/`,
        data: formData,
      })
        .then((res) => {
          const data = res.data;

          if (!data) {
            setDisplayError("inherit");
            setTimeout(() => {
              setDisplayError("none");
            }, 5000);
          } else {
            Activarcampaña();
            setModal(
              <ModalInfo
                error="false"
                handleClick={hideModal}
                title={`Tu cuenta ha <br/> sido creada <br/>correctamente`}
                text="Hemos enviado un correo de verificación, por favor revisa tu bandeja de correo."
              />
            );

            Ocultar();
            AutoLogin(event);
          }
        })
        .catch((err) => {
          const msg_error = err.response.data;
          if (msg_error) {
            setDisplayCorrect("inherit");
            setTimeout(() => {
              setDisplayCorrect("none");
            }, 5000);
            setDisplayError("inherit");
            setTimeout(() => {
              setDisplayError("none");
            }, 5000);
            setDisplayCorrectphone("inherit");
            setTimeout(() => {
              setDisplayCorrectphone("none");
            }, 5000);

            seterroraGregado(msg_error);
          }
        });
    }

    event.preventDefault();
  };
  return (
    <div className={`LoginFormContainer14_${type}_crearcuenta`}>
      <div className=" marco">
        <img src={logo_blanco} className="login_area_logo1 " alt="fondo" />
      </div>
      <div className="LoginFormContainer4">
        <img className="imgarriba" src={imgarriba} />
        <img className="imgabajo" src={imgabajo} />
        <Link to={`/`}>
          <img src={logo} className="login_logo_new1" alt="logo" />
        </Link>
        <div className={laptop575.matches ? "flexusuariocrear" : "flexusuario"}>
          <div className="SubTitle8 ">Registro {type}</div>
          <div
            className={type == "Usuario" ? "lineflex" : "lineflextriner"}
            alt="line"
          ></div>
          <Link to={`/`}>
            <div
              className="position-absolutecrear1 fw-bold "
              id="position-absolute"
              style={{
                color: "#7B86FA",
                top: "3vh",
                left: "15%",
                width: "50%",
              }}
            >
              <div style={{ cursor: "pointer" }}>
                <img
                  src={button2}
                  style={{
                    margin: "-7px 1px",
                    width: "1.5vw",
                    fontWeight: "600",
                  }}
                />{" "}
                Volver al inicio
              </div>
            </div>
          </Link>
        </div>
        <div
          className="position-absolutecrear"
          id="position-absolute"
          style={{
            color: "#BEBEBE",
            top: "3vh",
            left: "8%",
            width: "50%",
          }}
        >
          <Link
            to={`/`}
            style={{ cursor: "pointer", position: "relative", top: "2vh" }}
            className="d-flex"
          >
            <img src={button2} height="20px" />{" "}
            <div
              style={{
                margin: "auto 0 auto 5px",
                fontSize: "3.5vw",
                color: "#7B86FA",
                fontWeight: "600",
              }}
            >
              Volver al inicio
            </div>
          </Link>
        </div>
        <div className="flexusuariomobil1">
          <div className="SubTitle8mobil1">Registro {type}</div>
        </div>
        <h1 className="title1crear">Crear cuenta</h1>
        <h6 className="textodebajoregis">
          Entrenamiento personalizado desde casa, con la asesoría de un
          profesional.
        </h6>
        <form
          className="LoginForm Registro crearcuenta"
          onSubmit={handleSubmit}
        >
          <div className="flexcontraseñas">
            <div className="divcorreoelec1">
              <label htmlFor="mail" className="form-label porcentajecorreo">
                Correo Electrónico
              </label>
              <input
                type="email"
                placeholder="correo@electronico.com"
                name="mail"
                id="mail"
                className="form-control porcentajecorreo1 "
                required
              ></input>
              <div
                id="passmail_error"
                className=""
                style={{
                  display: displayError,
                  color: "#CB3D82",
                  fontSize: "0.9rem",
                }}
              >
                {ErroraGregado && ErroraGregado.email
                  ? ErroraGregado.email
                  : null}
              </div>
            </div>
            <div className="registrotelefono divcorreoelec2">
              <label htmlFor="number" className="">
                Teléfono
              </label>

              <div className="form-control telefonobackground">
                <PhoneInput
                  defaultCountry="CO"
                  initialValueFormat="national"
                  countryCallingCodeEditable={false}
                  name="telefono"
                  id="telefono"
                  placeholder="Ej:3156671354"
                  required
                  value={tel}
                  onChange={setTel}
                />
              </div>
              <div
                id="passmail_error"
                style={{
                  display: DisplayCorrectphone,
                  color: "#CB3D82",
                  fontSize: "0.9rem",
                }}
              >
                {ErroraGregado && ErroraGregado.mobile_number
                  ? ErroraGregado.mobile_number
                  : ErroraGregado && !ErroraGregado.mobile_number
                  ? ErroraGregado[0]
                  : "El teléfono debe ser válido"}
              </div>
            </div>
          </div>
          <div className=" flexcontraseñas">
            <div>
              <label htmlFor="pass" className="form-label">
                Contraseña
              </label>
              <div className="input-group divcorreoelecmedio">
                <input
                  type={showOrNo1}
                  placeholder="********"
                  name="pass"
                  id="pass"
                  className="form-control"
                  required
                ></input>

                <span
                  className="input-group-text"
                  style={{
                    bordeBottom: "1px solid #BEBEBE",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "#CB3D82",
                    backgroundColor: "transparent",
                    fontSize: "0.9rem",
                  }}
                  onClick={() => handleShowPass(1)}
                >
                  <FontAwesomeIcon icon={actualIconEye1} />
                </span>
              </div>
              <div
                id="passmail_error"
                className=""
                style={{
                  display: displayCorrect,
                  color: "#CB3D82",
                  fontSize: "0.9rem",
                }}
              >
                {ErroraGregado &&
                ErroraGregado.password == "Password fields didn't match."
                  ? "Las contraseñas no coinciden"
                  : null}
                {ErroraGregado &&
                ErroraGregado.password != "Password fields didn't match."
                  ? ErroraGregado.password
                  : null}
              </div>
            </div>
            <div className="divcorreoelecmediorepetir">
              <label htmlFor="pass2" className="form-label">
                Repetir contraseña
              </label>
              <div className="input-group">
                <input
                  type={showOrNo2}
                  placeholder="***********"
                  name="pass2"
                  id="pass2"
                  className="form-control"
                  required
                ></input>

                <span
                  className="input-group-text"
                  style={{
                    borderBottom: "1px solid #BEBEBE",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    color: "#CB3D82",
                    backgroundColor: "transparent",
                    fontSize: "0.9rem",
                  }}
                  onClick={() => handleShowPass(2)}
                >
                  <FontAwesomeIcon icon={actualIconEye2} />
                </span>
              </div>

              <div
                id="passmail_error"
                className=""
                style={{
                  display: displayCorrect,
                  color: "#CB3D82",
                  fontSize: "0.9rem",
                }}
              >
                {ErroraGregado &&
                ErroraGregado.password == "Password fields didn't match."
                  ? "Las contraseñas no coinciden"
                  : null}
                {ErroraGregado &&
                ErroraGregado.password != "Password fields didn't match."
                  ? ErroraGregado.password
                  : null}
              </div>
            </div>
          </div>
          <div className=" flexcontraseñas">
            <div className="divcorreoelec">
              <label htmlFor="name" className="form-label">
                Nombre
              </label>

              <input
                type="text"
                placeholder="John Doe"
                name="name"
                id="name"
                className="form-control"
                required
              ></input>
            </div>
            <div className="divcorreoelecapelldio">
              <label htmlFor="lastName" className="form-label">
                Apellido
              </label>
              <input
                type="text"
                placeholder="Smith Towers"
                name="lastName"
                id="lastName"
                className="form-control"
                required
              ></input>
            </div>
          </div>

          <div className="my-4 d-flex justify-content-center ">
            <div className=" m-auto text-align-center ">
              <div className="TyC text-center ">
                &nbsp;&nbsp;Al confirmar aceptas{" "}
                <a
                  onClick={() => window.open(`${basename}/tyc`)}
                  style={{ cursor: "pointer" }}
                >
                  Términos,Condiciones
                </a>{" "}
                <a
                  onClick={() => window.open(`${basename}/privacidad`)}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  y Políticas de privacidad
                </a>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <ButtonMob
              colorButton={"#CB3D82"}
              text={type === "Entrenador" ? "ENVIAR" : "CREAR"}
              className="my-2 d-flex justify-content-center Botonentrar"
            />
          </div>
        </form>
        {Modal}
      </div>
    </div>
  );
};

export default Registro;
