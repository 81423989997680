import { Fragment, useEffect, useState, useRef, createRef } from "react";
import "./Hresults.css";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import html2canvas from "html2canvas";

import {
  Loadingspinner,
  ModalInfo,
  ButtonMob,
  laptop575,
  userType,
  instance,
  basename,
} from "../utils/utils";
import Moment from "moment";
import fire from "../assets/img/HrIcons/iconos/fire.png";
import km from "../assets/img/HrIcons/iconos/Km.png";

import cardio from "../assets/img/HrIcons/iconos/cardio.png";
import time from "../assets/img/HrIcons/iconos/time.png";
import compartir from "../assets/img/HrIcons/iconos/compartir.png";
import pleasecompar from "../assets/img/HrIcons/iconos/pleasecompar.png";
import { extendMoment } from "moment-range";
import barra from "../assets/img/HrIcons/iconos/barra1.png";
import "react-vis/dist/style.css";
import mob from "../Perfil/iconosinfo/elevapointblack.png";
import {
  XYPlot,
  LineSeries,
  HorizontalBarSeries,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis,
} from "react-vis";

export function ModeloResults(props) {
  const moment = extendMoment(Moment);

  const idclase = props.idclase;
  const companie = props.companie || null;
  const ref = useRef(null);
  const [Infortodo, setInfortodo] = useState(null);

  const age = props.age ? props.age : null;

  const url2 = props.url2;
  var maxl0 = 220 - age;

  var axx0 = 0;
  var amin0 = 0;
  var scale0 = 0;
  const maximo = maxl0;
  const intenso = (maximo * 90) / 100;
  const moderado = (maximo * 80) / 100;
  const luz = (maximo * 70) / 100;
  const muy_suave = (maximo * 60) / 100;
  const base = (maximo * 50) / 100;
  var min0 = base;

  const [modalInfo, setModalInfo] = useState(null);
  const [horas_grafica1, sethoras1] = useState(null);
  const [maxl, setmaxl] = useState(0);
  const [min, setmin] = useState(0);
  const [axx, setaxx] = useState(0);
  const [amin, setamin] = useState(0);
  const [allcolors_5, setallcolors_5] = useState([]);
  const [Loading_data, setLoading_data] = useState(
    <ModalInfo
      error="none"
      title="Procesando"
      handleCancellcargue={() => {
        setLoading_data(null);
        // let bu = document.querySelector(".modalInfo");
        // bu.style = "display:none !important";
      }}
      text={<Loadingspinner customStyle="grow" size="4rem" />}
      classesMsg=""
    />
  );

  let getHour = function (date) {
    return date.getTime();
  };

  useEffect(async () => {
    let info = [];
    // function useComparecal(a, b) {
    //   if (parseInt(a.id) < parseInt(b.id)) return -1;
    //   if (parseInt(a.id) > parseInt(b.id)) return 1;
    //   return 0;
    // }

    await instance.get(`booking/book/${idclase}`).then(async (res) => {
      const infoEntrenamientoPUS = res.data;
      if (infoEntrenamientoPUS) {
        await instance.get(url2).then(async (res) => {
          const infoEntrenamiento = res.data;
          if (infoEntrenamiento) {
            await instance
              .get(url2)
              .then(async (res) => {
                const daysselected = res.data;
                // console.log(
                //   "-------------",
                //   daysselected.filter((e) => e).sort(useComparecal)
                // );
                if (daysselected.length > 0) {
                  let peso_graficads = [];
                  let peso_graficads1 = [];
                  let maximo1 = [];
                  let intenso1 = [];
                  let moderado1 = [];
                  let luz1 = [];
                  let muy_suave1 = [];
                  setLoading_data(null);
                  daysselected.map((e, index) => {
                    peso_graficads[e.id] = {
                      x: getHour(new Date(e.date)),
                      y: e.heart_rate,
                    };
                  });
                  daysselected.map((e, index) => {
                    peso_graficads1[e.id] = {
                      x: getHour(new Date(e.date)),
                      y:
                        intenso <= e.heart_rate && e.heart_rate < maximo
                          ? e.heart_rate
                          : moderado <= e.heart_rate && e.heart_rate < intenso
                          ? e.heart_rate - 20
                          : luz <= e.heart_rate && e.heart_rate < moderado
                          ? e.heart_rate - 40
                          : muy_suave <= e.heart_rate && e.heart_rate < luz
                          ? e.heart_rate - 50
                          : e.heart_rate - 75 > -30
                          ? e.heart_rate - 75
                          : null,
                    };
                  });

                  peso_graficads

                    .map((e) => e.y)
                    .forEach((e) => {
                      if (e > 0) {
                        if (intenso <= e && e <= maximo) {
                          maximo1.push(e);
                        } else if (moderado <= e && e < intenso) {
                          intenso1.push(e);
                        } else if (luz <= e && e < moderado) {
                          moderado1.push(e);
                        } else if (muy_suave <= e && e < luz) {
                          luz1.push(e);
                        } else {
                          muy_suave1.push(e);
                        }
                      }
                    });
                  setallcolors_5([
                    maximo1,
                    intenso1,
                    moderado1,
                    luz1,
                    muy_suave1,
                  ]);

                  peso_graficads
                    .filter((e) => e)
                    .forEach(function (e, i) {
                      axx0 = Math.max(axx0, e.x);
                      if (i === 0) {
                        min0 = maxl0;

                        amin0 = axx0;
                      }
                      min0 = Math.min(100, e.y);
                      amin0 = Math.min(amin0, e.x);
                    });
                  if (peso_graficads.filter((e) => e).length > 10) {
                    setamin(amin0);
                  } else {
                    setamin(100);
                  }

                  setaxx(axx0);
                  setmin(min0);
                  setmaxl(maxl0);
                  sethoras1(
                    peso_graficads1.length > 0 &&
                      peso_graficads1.filter((e) => e)
                  );
                } else {
                  setLoading_data(null);
                  setModalInfo(
                    <ModalInfo
                      handleClick={() =>
                        window.open(`https://tiendasportcheck.com/`)
                      }
                      error="none"
                      handleCancel={() => setModalInfo(null)}
                      title="Aquirir banda en línea "
                      text={
                        "Aún no tienes el dispositivo para ver tus mediciones de clase o disciplina ? No te preocupes,estas a un solo click de adquirirla y disfrutar de beneficios médicos y saludables "
                      }
                      classesMsg=""
                    />
                  );
                }
              })
              .catch((err) => {
                if (err.response) {
                  setModalInfo(
                    <ModalInfo
                      handleClick={() =>
                        (window.location.href = `https://tiendasportcheck.com/`)
                      }
                      error="none"
                      handleCancel={() =>
                        (window.location.href = `/Usuario/reservas`)
                      }
                      title="Aquirir banda en línea"
                      text={
                        "Aún no tienes el dispositivo para ver tus mediciones de clase o disciplina ?.No te preocupes,estas a un solo click de adquirirla y disfrutar de beneficios médicos y saludables "
                      }
                      classesMsg=""
                    />
                  );
                }
              });

            const reducer = (previousValue, currentValue) =>
              previousValue + currentValue;
            var total_rate = infoEntrenamiento.map((e, i) => e.heart_rate);
            var total_date = infoEntrenamiento
              .map((e, i) => e.date.replace("T", " ").slice(0, 16))
              .sort(function (a, b) {
                var nameA = a; // ignore upper and lowercase
                var nameB = b; // ignore upper and lowercase
                if (nameA > nameB) {
                  return -1;
                }
                if (nameA < nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              });

            var total_kcal = infoEntrenamiento.map((e, i) => e.kcal);
            var tot_f =
              infoEntrenamiento && total_date.length
                ? moment(total_date[0])
                : null;
            var tot_f1 =
              infoEntrenamiento && total_date.length
                ? moment(total_date[total_date.length - 1])
                : null;
            const secs = tot_f ? tot_f.diff(tot_f1, "seconds") : 0;
            info[infoEntrenamientoPUS.id] = {
              calorias:
                infoEntrenamiento && total_kcal.length > 0
                  ? total_kcal[0] + ".0"
                  : "0.0",
              tiempo:
                infoEntrenamiento && total_date.length > 0
                  ? moment.utc(secs * 1000).format("HH:mm:ss")
                  : "00:00:00",
              nombresesion: infoEntrenamientoPUS.training.name,
              icon: infoEntrenamientoPUS.training.icon,
              distance: "____",
              date: moment(
                infoEntrenamientoPUS.date,
                "YYYY-MM-DD HH:mm"
              ).format("dddd DD MMMM YYYY LT a"),
              puntosMob:
                infoEntrenamientoPUS && infoEntrenamientoPUS.status == 40
                  ? "1"
                  : "0",
              hr_med:
                total_rate.length > 0 && total_rate.reduce(reducer)
                  ? Math.round(total_rate.reduce(reducer) / total_rate.length)
                  : "0.00",
              hr_max:
                infoEntrenamiento && total_rate.length > 0
                  ? total_rate.sort(function (a, b) {
                      var nameA = b; // ignore upper and lowercase
                      var nameB = a; // ignore upper and lowercase
                      if (nameA > nameB) {
                        return -1;
                      }
                      if (nameA < nameB) {
                        return 1;
                      }
                      // names must be equal
                      return 0;
                    })[total_rate.length - 1]
                  : "0.00",
            };

            setInfortodo(info.filter((e) => e));
          }
        });
      }
    });
  }, []);

  function share() {
    // iife here
    (async () => {
      if (!("share" in navigator)) {
        return;
      }
      // `element` is the HTML element you want to share.
      // `backgroundColor` is the desired background color.
      const canvas = await html2canvas(ref.current);

      // canvas.style.width = "40px";
      // canvas.style.height = "20px";
      // canvas.width = 256;
      // canvas.height = 171;

      // console.log(canvas);
      canvas.toBlob(async (blob) => {
        // Even if you want to share just one file you need to
        // send them as an array of files.
        const files = [new File([blob], "image.png", { type: blob.type })];

        const shareData = {
          file: files,
          title: "results",
          text: "Elevvaplayer results",
          url: "https://app.elevva.com.co",
        };
        // console.log(files);
        if (shareData && navigator.canShare(shareData)) {
          try {
            await navigator.share(shareData);
          } catch (err) {
            alert("Lo sentimos, no fue posible compartir tu imagen");
            if (err.name !== "AbortError") {
              console.error(err.name, err.message);
            }
          }
        } else {
          alert("nada");
          console.warn("Sharing not supported", shareData);
        }
      });
    })();
  }
  const groupTypes1 = [
    {
      name: "1",
      id: "1",
      color: "#6EE1FB",
      opacity: 1,
      data: [
        {
          x: allcolors_5[4] ? allcolors_5[4].length : 0,
          y:
            allcolors_5[4] && allcolors_5[4].length > 0
              ? moment.utc(allcolors_5[4].length * 1000).format("  HH:mm:ss")
              : " 00:00:00 ",
        },
      ],
    },
    {
      name: "2",
      id: "2",
      color: "#7988F6",
      opacity: 1,
      data: [
        {
          x: allcolors_5[3] ? allcolors_5[3].length : 0,
          y:
            allcolors_5[3] && allcolors_5[3].length > 0
              ? moment.utc(allcolors_5[3].length * 1000).format("HH:mm:ss ")
              : moment.utc(0 * 1000).format("HH:mm:ss "),
        },
      ],
    },
    {
      name: "3",
      id: "3",
      color: "#F5D7CF",
      data: [
        {
          x: allcolors_5[2] ? allcolors_5[2].length : 0,
          y:
            allcolors_5[2] && allcolors_5[2].length > 0
              ? moment.utc(allcolors_5[2].length * 1000).format(" HH:mm:ss")
              : "00:00:00   ",
        },
      ],
    },
    {
      name: "4",
      id: "4",
      color: "#FFB6A4",
      data: [
        {
          x: allcolors_5[1] ? allcolors_5[1].length : 0,
          y:
            allcolors_5[1] && allcolors_5[1].length > 0
              ? moment.utc(allcolors_5[1].length * 1000).format(" HH:mm:ss  ")
              : "00:00:00  ",
        },
      ],
    },
    {
      name: "5",
      id: "5",
      color: "#E16250",
      data: [
        {
          x: allcolors_5[0] ? allcolors_5[0].length : 0,
          y:
            allcolors_5[0] && allcolors_5[0].length > 0
              ? moment.utc(allcolors_5[0].length * 1000).format("HH:mm:ss  ")
              : "00:00:00",
        },
      ],
    },
  ];

  function generargraf() {
    const colors = ["#6EE1FB", " #7988F6", "#F5D7CF", "#FFB6A4", "#E16250"];

    horas_grafica1 &&
      horas_grafica1.length > 0 &&
      horas_grafica1.forEach(function (e, i) {
        maxl0 = Math.max(maxl0, e.y);
        // console.log(maxl0);
        axx0 = Math.max(axx0, e.x);
        if (i === 0) {
          min0 = maxl0;

          amin0 = axx0;
        }
        min0 = Math.min(min0, e.y);
        amin0 = Math.min(amin0, e.x);
      });

    var dist = maxl0 - 0;
    scale0 = dist / 4;

    var ret = [];
    for (let i = 0; i <= 4; i++) {
      ret.push({ x: axx0, y: scale0 * i, color: colors[i] });
    }
    // console.log(ret) ;
    return ret;
  }

  return laptop575.matches ? (
    <>
      {" "}
      <div className=" " ref={ref} id="canvass">
        <div className="d-flex mt-4">
          <img
            className="ICONORESUMEN  "
            style={{ margin: "0 0 0 5%" }}
            src={Infortodo ? Infortodo[0].icon : null}
          />
          <div className="title">
            {Infortodo ? Infortodo[0].nombresesion : null}

            <div className="fechanowresultsresumen">
              {" "}
              {Infortodo ? Infortodo[0].date : "______"}
            </div>
          </div>
        </div>
        <div
          className="titleresumensesion pt-3"
          style={companie ? { margin: "2vh auto 2vh 5%" } : null}
        >
          Tu sesión
        </div>
        <div
          className={
            companie
              ? "d-flex jc-center tamañoestadisticsres_tearCompanie Hr  py-3 "
              : "d-flex jc-center tamañoestadisticsres_tear Hr  py-3 "
          }
        >
          <div className="m-auto  ">
            <img className=" imagesestadiskm" src={km} />
            <div className=" letrasestadis">
              {" "}
              {Infortodo ? Infortodo[0].tiempo : "0.0"}
            </div>
            <div className=" letrasestadis1" style={{ color: "#9DACBD" }}>
              Duración
            </div>
          </div>
          <div className=" borderkm"></div>
          <div className="m-auto ">
            <img className=" imagesestadisfire" src={fire} />
            <div className=" letrasestadis">
              {" "}
              {Infortodo && Infortodo[0] && Infortodo[0].calorias
                ? Infortodo[0].calorias.slice(0, 5)
                : "0.0  "}
            </div>
            <div className=" letrasestadis1" style={{ color: "#9DACBD" }}>
              Kcal
            </div>
          </div>
          <div className=" borderkm"></div>
          <div className="m-auto ">
            <img className=" imagesestadiskm" src={cardio} />
            <div className=" letrasestadis">
              {" "}
              {Infortodo ? Infortodo[0].hr_med : "0.00"}
            </div>
            <div className=" letrasestadis1" style={{ color: "#9DACBD" }}>
              Frecuencia media
            </div>
          </div>
          <div className=" borderkm"></div>
          <div className="m-auto ">
            <img className=" imagesestadiskm" src={cardio} />
            <div className=" letrasestadis">
              {" "}
              {Infortodo ? Infortodo[0].hr_max : "0.00"}
            </div>
            <div className=" letrasestadis1" style={{ color: "#9DACBD" }}>
              Frecuencia máxima
            </div>
          </div>

          {/* <div
              className="m-auto elevation-1"
              style={{
                textAlign: "center",
                padding: "1vmin",
                borderRadius: "10px",
              }}
            >
              <img
                className=" imagesestadiskmelevapoint"
                src={process.env.PUBLIC_URL + "/img/mediolo.png"}
              />

              <div
                className=" letrasestadis1 mb-1"
                style={{
                  fontSize: "1.5vmin",
                  color: "#394F65",
                  lineHeight: "100%",
                }}
              >
                ¿Quieres saber <br />
                <strong>
                  cuantas calorías
                  <br /> quemaste durante
                  <br /> tu entrenamiento?
                </strong>
              </div>
              <Link to="/ejercicios">
                <span
                  style={{
                    fontSize: "1.5vmin",
                    color: "#CB3D82",
                    textDecoration: "underline",
                  }}
                >
                  Enrolate a elevva +
                </span>
              </Link>
            </div> */}
          {companie ? null : (
            <div className="m-auto ">
              <img className=" imagesestadiskmelevapoint" src={mob} />
              <div className=" letrasestadis">
                {" "}
                {Infortodo ? Infortodo[0].puntosMob : "0"}
              </div>
              <div className=" letrasestadis1">Puntos Elevva</div>
            </div>
          )}
        </div>
        <div
          className="bitacoraTitleresu_te"
          style={companie ? { marginLeft: "5%" } : null}
        >
          Frecuencia sobre tiempo de entrenamiento
        </div>
        <div
          className="d-flex my-5 "
          style={
            companie
              ? {
                  margin: "5%",
                  width: "100%",
                }
              : {
                  margin: "  14%",
                  width: "100%",
                }
          }
        >
          {horas_grafica1 && horas_grafica1.length > 0 ? (
            <div className="d-flex " style={{ width: "40%" }}>
              <div
                className="d-flex jc-center roma_web  "
                style={{ width: "75%" }}
              >
                <FlexibleWidthXYPlot
                  height={220}
                  xType="linear"
                  xDomain={[amin, axx]}
                  yDomain={[0, maxl]}
                  margin={{ top: 21, left: 50, bottom: 56 }}
                  //  xDomain={xdomain ? xdomain : [0, 0]}
                >
                  <XAxis
                    tickFormat={(value) =>
                      new Date(value).toString().substr(16, 8)
                    }
                    tickLabelAngle={30}
                    tickPadding={50}
                    marginLeft={70}
                    style={{
                      text: {
                        fontColor: `#9DACBD `,

                        fontSize: "0.8vw",
                      },
                      line: { stroke: "#ffff" },
                      ticks: { stroke: "#9DACBD" },
                    }}
                  />

                  <YAxis
                    yDomain={[80, maxl]}
                    hideLine={true}
                    title="ppm"
                    orientation="left"
                    height={220}
                    style={{
                      text: {
                        fontColor: `#9DACBD `,

                        fontSize: "1vw",
                        transform: "translate(-12px, 0)",
                      },
                      title: {
                        fontSize: "1vw",
                        fontColor: `#9DACBD !important `,
                        fontWeight: "700",
                        color: `#9DACBD !important`,

                        transform: "translate(-2px, -5px)",
                      },
                      line: { stroke: "#ffff" },
                    }}
                  />

                  <YAxis
                    hideLine={true}
                    position="middle"
                    orientation="right"
                    title="%"
                    style={{
                      line: { stroke: "#ffff" },
                      title: {
                        fontSize: "1vw",
                        fontColor: `#9DACBD  `,
                        fontWeight: "700",
                        color: `#9DACBD `,

                        transform: "translate(14px, 1px)",
                      },
                      text: {
                        fontColor: `#9DACBD `,

                        fontSize: "1vw",
                        transform: "translate(15px, 7px)",
                      },
                    }}
                    yDomain={[80, 80]}
                  />

                  <HorizontalBarSeries
                    data={generargraf()}
                    barWidth={1}
                    colorType="literal"
                  />

                  <LineSeries
                    style={{ fill: "none", stroke: "white", strokeWidth: "4" }}
                    data={horas_grafica1}
                  />
                  <br />
                </FlexibleWidthXYPlot>
              </div>
            </div>
          ) : (
            Loading_data
          )}
          <div className="d-flex   mt-1  " style={{ width: "40%" }}>
            <img
              className="d-flex  "
              style={{ marginTop: "0.6rem", marginLeft: "0%" }}
              height="150vh "
              src={barra}
            />
            <div
              className="d-flex jc-center roma_web  "
              style={{ width: "80%" }}
            >
              <FlexibleWidthXYPlot
                height={200}
                yType="ordinal"
                className="corrergrafico"
                style={{
                  text: {
                    fontColor: `#9DACBD `,
                    fontWeight: "700",
                  },
                }}
                margin={{ right: 160, left: 0 }}
              >
                {groupTypes1.map((group) => (
                  <YAxis
                    tickValues={[`${group.data[0].y}`]}
                    searchPlaceholder={"lola"}
                    style={{
                      text: {
                        fontColor: "#9DACBD !important",
                        Color: "#9DACBD !important",
                        fontWeight: "700",
                        fontSize: "1rem",
                        transform: "translate(30vw, 0)",
                      },
                      line: { stroke: "#ffff" },
                    }}
                  />
                ))}

                {groupTypes1.map((group) => (
                  <HorizontalBarSeries
                    cluster={1}
                    color={group.color}
                    data={group.data}
                    opacity={group.opacity}
                    barWidth={1}
                  />
                ))}
              </FlexibleWidthXYPlot>
            </div>
          </div>
        </div>
        {modalInfo}
      </div>
      {companie ? (
        <div
          className="containerResevar d-flex w-50 "
          style={{
            boxShadow: "none",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <div className=" d-flex w-100">
            <ButtonMob
              onClick={() => setModalInfo(<Redirect to={`/ejercicios`} />)}
              text="Ir a menú principal"
              className="text-center"
              classesImg="btnCalendar_web ms-3"
            />
          </div>
        </div>
      ) : (
        <div className="containerResevar d-flex w-50 ">
          <div className=" d-flex w-100">
            <div className="d-flex w-50"></div>
            <div className="d-flex w-50">
              <ButtonMob
                onClick={share}
                text="Comparte tus resultados"
                className="text-center"
                imageweb={pleasecompar}
                classesImg="btnCalendar_web ms-3"
              />
              <ButtonMob
                onClick={() =>
                  setModalInfo(<Redirect to={`/${userType}/reservas`} />)
                }
                text="Continúa tu entrenamiento"
                className="text-center "
                image2={pleasecompar}
              />
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <>
      <div className={"resumenContainer Hr"}>
        <div className="title">
          <img
            className="ICONORESUMEN"
            src={Infortodo ? Infortodo[0].icon : null}
          />
          <div style={{ textTransform: "capitalize", margin: "1rem 2rem" }}>
            {Infortodo ? Infortodo[0].nombresesion : null}

            <img src={compartir} onClick={share} className=" imagencompartir" />
          </div>
        </div>
        <div className="fechanowresultsresumen text-center pt-4">
          {" "}
          {Infortodo ? Infortodo[0].date : "______"}
        </div>
        <div ref={ref} id="canvass">
          <>
            <div className="d-row borderresults mt-2 ">
              <div className="title">Resumen de tu sesión</div>
              <div className="d-flex jc-center tamañoestadisticsres  mt-2 ">
                <div className="m-3 mb-0 ">
                  <img className=" imagesestadiskm" src={time} />
                  <div className=" letrasestadis">
                    {Infortodo ? Infortodo[0].tiempo : "0.0"}
                  </div>
                  <div className=" letrasestadis1">Duración</div>
                </div>
                <div className=" borderkm"></div>
                <div className="m-3 ">
                  <img className=" imagesestadisfire" src={fire} />
                  <div className=" letrasestadis">
                    {Infortodo && Infortodo[0] && Infortodo[0].calorias
                      ? Infortodo[0].calorias.slice(0, 5)
                      : "0.0  "}
                  </div>
                  <div className=" letrasestadis1">Kcal</div>
                </div>
                <div className=" borderkm"></div>
                <div className="m-3 ">
                  <img className=" imagesestadiscardio" src={mob} />
                  <div className=" letrasestadis">
                    {Infortodo ? Infortodo[0].puntosMob : "0"}
                  </div>
                  <div className=" letrasestadis1">Puntos Elevva</div>
                </div>
              </div>
            </div>
          </>

          <div className="d-flex jc-center tamañoestadisticsres borderresults19  ">
            <div className="m-3  ">
              <img className=" imagesestadiscardio1" src={cardio} />
              <div className=" letrasestadis11">
                {Infortodo ? Infortodo[0].hr_med : "0.00"}
              </div>
              <div className=" letrasestadis1">Frecuencia media</div>
            </div>
            <div className=" borderkm"></div>
            <div className="m-3 ">
              <img className=" imagesestadiscardio1" src={cardio} />
              <div className=" letrasestadis11">
                {Infortodo ? Infortodo[0].hr_max : "0.00"}
              </div>
              <div className=" letrasestadis1">Frecuencia máxima</div>
            </div>
          </div>
          <div className="bitacoraTitleresu">
            Frecuencia sobre tiempo de entrenamiento
          </div>
          {horas_grafica1 && horas_grafica1.length > 0 ? (
            <div
              style={{
                height: 250,
                padding: "0 4%",
                width: "90vw",
              }}
            >
              <div className="d-flex jc-center roma   ">
                <FlexibleWidthXYPlot
                  height={200}
                  xType="linear"
                  xDomain={[amin, axx]}

                  //  xDomain={xdomain ? xdomain : [0, 0]}
                >
                  <XAxis
                    tickFormat={(value) =>
                      new Date(value).toString().substr(16, 8)
                    }
                    tickLabelAngle={30}
                    tickPadding={30}
                    style={{
                      text: {
                        fontColor: `#9DACBD `,

                        fontSize: "3vw",
                      },
                      line: { stroke: "#ffff" },
                      ticks: { stroke: "#9DACBD" },
                    }}
                  />

                  <YAxis
                    hideLine={true}
                    title="ppm"
                    orientation="left"
                    height={220}
                    //  position="middle"
                    style={{
                      text: {
                        fontColor: `#9DACBD `,
                        color: "#9DACBD !important",
                        fontSize: "3vw",
                      },
                      title: {
                        fontSize: "3vw",
                        fontColor: `#9DACBD !important `,
                        fontWeight: "700",
                        color: `#9DACBD !important`,

                        transform: "translate(-4px, 0)",
                      },
                      line: { stroke: "#ffff" },
                    }}
                    yDomain={[80, maxl]}
                  />

                  <YAxis
                    hideLine={true}
                    position="middle"
                    orientation="right"
                    title="%"
                    style={{
                      line: { stroke: "#ffff" },
                      title: {
                        fontSize: "3vw",
                        fontColor: `#9DACBD`,
                        fontWeight: "700",
                        color: `#9DACBD`,

                        transform: "translate(11px, 0)",
                      },
                      text: {
                        fontColor: `#9DACBD `,

                        fontSize: "3vw",
                        transform: "translate(15px, 0)",
                      },
                    }}
                    yDomain={[80, 80]}
                  />

                  <HorizontalBarSeries
                    data={generargraf()}
                    barWidth={1}
                    colorType="literal"
                    // stackBy="x"
                  />

                  <LineSeries
                    style={{ fill: "none", stroke: "white", strokeWidth: "4" }}
                    data={horas_grafica1}
                  />
                  <br />
                </FlexibleWidthXYPlot>
              </div>
            </div>
          ) : (
            Loading_data
          )}
          <div className="d-flex px-4" style={{ width: "100%" }}>
            <img
              className="d-flex  "
              style={{ marginTop: "0.6rem" }}
              width="50vw"
              height="132vh "
              src={barra}
            />
            <div style={{ width: "100%" }}>
              <XYPlot
                width={250}
                height={180}
                yType="ordinal"
                className="corrergrafico"
                style={{
                  text: {
                    fontColor: `#9DACBD`,
                    fontWeight: "700",
                  },
                }}
                margin={{ right: 100, left: 0 }}
              >
                {groupTypes1.map((group) => (
                  <YAxis
                    left={250}
                    tickValues={[`${group.data[0].y}`]}
                    searchPlaceholder={"lola"}
                    style={{
                      text: {
                        fontColor: "#9DACBD ",
                        color: "#9DACBD ",
                        fontWeight: "700",
                        fontSize: "3vw",
                      },
                      line: { stroke: "#ffff" },
                    }}
                  />
                ))}

                {groupTypes1.map((group) => (
                  <HorizontalBarSeries
                    cluster={1}
                    color={group.color}
                    data={group.data}
                    opacity={group.opacity}
                    barWidth={1}
                  />
                ))}
              </XYPlot>
            </div>
          </div>
        </div>
      </div>

      {modalInfo}
    </>
  );
}
