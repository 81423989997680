import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ButtonMob, ModalInfo, userType, instance } from "../utils/utils";
import error from "../assets/img/reservasimages/error.png";
import info from "../assets/img/reservasimages/infoB.png";
import calendar from "../assets/img/reservasimages/calendar.png";
import Media from "react-media";

import alerta from "../assets/img/reservasimages/alertacamarada.png";

const NoReservado = () => {
  let { id } = useParams();
  let history = useHistory();

  const [cabecera, setCabecera] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [imgBG, setBGImg] = useState(null);
  const [infoEntrenamiento, setInfoEntrenamiento] = useState(null);

  function hideModal() {
    setModalInfo(null);
  }

  useEffect(() => {
    instance
      .get(`trainings/${id}/`)
      .then((resEn) => {
        const info = resEn.data;

        var bg_img = info.cover_picture;
        const titulo = unescape(info.name).toLowerCase();
        setBGImg(bg_img);
        setInfoEntrenamiento(
          <div className="InformationEntrenamiento">
            Entrenamiento
            <br />
            <span className="container_name">{titulo}</span>
          </div>
        );
        setCabecera(
          <>
            <div
              className="Entrenamiento reserva"
              style={{ backgroundImage: `url(${bg_img})` }}
            >
              <div className="bg_cover reserva"></div>
              <div className="Cabecera reserva">
                Entrenamiento<span className="lineop"></span>
                <br />
                <span className="container_name">{titulo}</span>
              </div>
            </div>
          </>
        );
      })
      .catch((err) => {
        console.error("Error en api/clientes/clases/buscar", err);
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });
  }, [id]);
  function handleClick() {
    history.goBack();
  }
  return (
    <>
      <Media queries={{ phoneIpad: { maxWidth: 575 } }}>
        {(matches) =>
          matches.phoneIpad ? (
            <div className="Reservando w-100 confirmado">
              {cabecera}
              <div className="title mx-auto confirmado text-center">
                El Entrenamiento no ha sido reservado
              </div>
              <div className="iconCalendar">
                <img src={info} alt="Info" />
              </div>
              <div className="title text-capitalize text-center">
                Tienes una clase reservada en este horario
              </div>
              <div className="iconCalendar">
                <img src={error} alt="Error" />
              </div>
              <div className="info text-center m-auto">
                Lo sentimos, algo ha fallado por favor revisa bien tu reserva.
              </div>

              <div onClick={handleClick} className="containerVolverHome">
                <ButtonMob
                  text="Volver a reservar"
                  className="text-left ps-4 btnvolverHome reserva"
                  image={calendar}
                  classesImg="btnHomeVolver "
                />
              </div>
            </div>
          ) : (
            <div className="maxreservaselec">
              <div className="maxreservar1">
                <div className="d-flex ">
                  <div className="SubTitle8reservatuclase">Reservar</div>
                  <div className="lineareservatuclasenoreser"></div>
                </div>
                <div className="title tituloreservartuclase ">
                  Selecciona tu disciplina de interes
                </div>
                <div className="textodebajoreservas tituloreservartuclasedebajo">
                  En ELEVVA eres dueño de tu tiempo, agenda tu entrenamiento en
                  los días y horario de tu preferencia
                </div>
              </div>
              <div
                className="Entrenamiento ReservandoDeskt "
                style={{ backgroundImage: `url(${imgBG})` }}
              >
                <div className="bg_cover"></div>
                <div className="d-flex g-0 inf">
                  <div className="w-100">{infoEntrenamiento}</div>
                  <div className=" information">
                    <div className="content">
                      <div className="title mx-auto confirmado">
                        El Entrenamiento no ha sido reservado
                      </div>
                      <div className="iconalertacamarada">
                        <img src={alerta} alt="calendario" />
                      </div>
                      <div className="title mx-auto noconfirmado">
                        No fue posible aplicar el descuento
                      </div>

                      <div className="title mx-auto confirmado"></div>
                      <div className="recomendacion">
                        <img src={error} alt="error" />

                        <div className="mt-2">
                          Lo sentimos, algo ha fallado por favor revisa bien tu
                          reserva.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                to={`/${userType}/reservas`}
                className="containerVolverHome "
              >
                <ButtonMob
                  text="Continuar"
                  className="text-left ps-4 btnReserva izquierbuton"
                  image2={calendar}
                  classesImg="btnCalendar ms-3"
                />
              </Link>
            </div>
          )
        }
      </Media>

      {modalInfo}
    </>
  );
};

export default NoReservado;
