import {
  ButtonMob,
  laptop575,
  instance,
  basename,
  ModalInfo,
} from "../../utils/utils.js";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { useEffect, useState } from "react";
import React from "react";
import "./OnboardingEntrenador.css";

const OnboardingEntrenador = (props) => {
  const [docreqs, setdocreqs] = useState(true);
  const [uploadFile1, setuploadFile1] = useState(null);
  const [uploadFile2, setuploadFile2] = useState(null);
  const [uploadFile3, setuploadFile3] = useState(null);
  const [uploadFile4, setuploadFile4] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);

  function hideModalsalir() {
    setModalInfo(
      <ModalInfo
        error="false"
        handleClick={() => {
          localStorage.clear();
          // if (sessionStorage.onesignal_id) {
          //   localStorage.onesignal_id = sessionStorage.onesignal_id;
          // }
          window.location.href = `${basename}/ingreso`;
        }}
        title="Tu solicitud
ha sido recibida"
        text="Dentro de poco, un agente de nuestro equipo se pondrá en contacto para activar tu cuenta."
        classcorrecta={"titleleft"}
      />
    );
  }
  useEffect(() => {
    instance
      .get(`trainers/documentation/`)
      .then((res) => {
        if (res.data.length > 0) {
          setdocreqs(false);
        }
      })
      .catch((err) => console.log(err.response));
  }, []);

  const changeCer_estudios = (event) => {
    const changeCer_estudios = event.target.files[0];

    setuploadFile1(changeCer_estudios);
  };
  const changeCer_bancario = (event) => {
    const changeCer_bancario = event.target.files[0];

    setuploadFile2(changeCer_bancario);
  };
  const changeCer_cc = (event) => {
    const changeCer_cc = event.target.files[0];

    setuploadFile3(changeCer_cc);
  };
  const changeCer_rut = (event) => {
    const changeCer_rut = event.target.files[0];
    setuploadFile4(changeCer_rut);
  };
  const handleSubmit = (event) => {
    const formDataDocuments = new FormData();

    formDataDocuments.append("file", uploadFile1);
    formDataDocuments.append("name", "Certificado de estudios");
    instance
      .post(`trainers/documentation/`, formDataDocuments, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.response));
    const formDataDocuments2 = new FormData();

    formDataDocuments2.append("file", uploadFile2);
    formDataDocuments2.append("name", "Certificado bancario");
    instance
      .post(`trainers/documentation/`, formDataDocuments2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.response));
    const formDataDocuments3 = new FormData();

    formDataDocuments3.append("file", uploadFile3);
    formDataDocuments3.append("name", "Cédula");
    instance
      .post(`trainers/documentation/`, formDataDocuments3, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.response));
    const formDataDocuments4 = new FormData();

    formDataDocuments4.append("file", uploadFile4);
    formDataDocuments4.append("name", "Rut");
    instance
      .post(`trainers/documentation/`, formDataDocuments4, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.response));
    if (docreqs) {
      setModalInfo(
        <ModalInfo
          error="none"
          handleClick={hideModalsalir}
          title="Documentación enviada correctamente"
          text={""}
          classcorrecta={"titleleft"}
          classesMsg=""
        />
      );
    } else {
      setModalInfo(
        <ModalInfo
          error="none"
          handleClick={() =>
            (window.location.href = `${basename}/Entrenador/inicio`)
          }
          title="Documentación actualizada correctamente"
          text={""}
          classcorrecta={"titleleft"}
          classesMsg=""
        />
      );
    }
    // setTimeout(() => {
    //   window.location.href = `/Entrenador/inicio`;
    // }, 5000);

    event.preventDefault();
  };

  return (
    <>
      {laptop575.matches ? (
        <div className="ContainerCreditos">
          <div
            className="title mx-auto"
            style={{ position: "relative", top: "10%", left: "10%" }}
          >
            Adjunta tu documentación
          </div>
          <div
            style={{
              position: "relative",
              width: "70vw",
              top: "25%",
              left: "10%",
            }}
          >
            <form onSubmit={handleSubmit} action="/action_page.php">
              <div className=" flexcontraseñas">
                <div className="divcorreoelec">
                  <label
                    for="myfile"
                    htmlFor="Certificado de estudios"
                    className="form-label"
                  >
                    Certificado de estudios
                  </label>

                  <input
                    required={docreqs}
                    type="file"
                    disabled={false}
                    name="Certificado de estudios"
                    id="Certificadodeestudios"
                    className="form-control custom-file-input"
                    onChange={changeCer_estudios}
                  ></input>
                </div>
                <div className="divcorreoelecapelldio">
                  <label htmlFor="Cédula" className="form-label">
                    Cédula
                  </label>
                  <input
                    required={docreqs}
                    onChange={changeCer_cc}
                    type="file"
                    disabled={false}
                    name=" Cédula"
                    id=" Cédula"
                    className="form-control custom-file-input"
                  ></input>
                </div>
              </div>
              <div className=" flexcontraseñas">
                <div className="divcorreoelec ">
                  <label htmlFor="Certificado bancario" className="form-label ">
                    Certificado bancario
                  </label>
                  <input
                    required={docreqs}
                    onChange={changeCer_bancario}
                    type="file"
                    disabled={false}
                    name="Certificado bancario"
                    id="Certibank"
                    className="form-control custom-file-input"
                  ></input>
                </div>
                <div className="divcorreoelecapelldio">
                  <label htmlFor="RUT" className="form-label">
                    RUT
                  </label>
                  <input
                    required={docreqs}
                    onChange={changeCer_rut}
                    type="file"
                    name="RUT"
                    disabled={false}
                    id="rut"
                    className="form-control custom-file-input"
                  ></input>
                  <i></i>
                </div>
              </div>{" "}
              <div className="d-flex justify-content-center mt-5">
                <ButtonMob
                  text={
                    docreqs == false
                      ? "Actualizar documentos"
                      : "Enviar documentos"
                  }
                  className="marginforbotton p-3 d-flex justify-content-center "
                />
              </div>
            </form>
          </div>
          {modalInfo}
        </div>
      ) : (
        <div className="ContainerCreditos mobilesonboar">
          <div className="title mx-auto  " style={{ fontSize: "6.5vw" }}>
            Adjunta tu
            <br /> documentación
          </div>
          <div
            className="title mx-auto px-4"
            style={{
              fontSize: "4vw",
              color: "#7B7B7B",
              fontFamily: "Poppins-regular",
              textAlign: "left",
            }}
          >
            Para certificar tus conocimientos, debes adjuntar los siguientes
            documentos :
          </div>

          <form
            action=""
            enctype="multipart/form-data"
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="flexcontraseñas px-4 mt-4">
              <label htmlFor="Certificado bancario" className=" ">
                Certificado de estudios
              </label>
              <input
                type="file"
                accept=".heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, application/msword, application/pdf"
                required={docreqs}
                onChange={changeCer_estudios}
                name="Certificado bancario"
                id="Certibank"
                className="form-control"
                // className=" custom-file-input"
              ></input>
            </div>
            <div className="flexcontraseñas px-4">
              <label htmlFor="Certificado bancario" className="form-label ">
                Certificado bancario
              </label>
              <input
                accept=".heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, application/msword, application/pdf"
                required={docreqs}
                onChange={changeCer_bancario}
                type="file"
                name="Certificado bancario"
                id="Certibank"
                className="form-control "
              ></input>
            </div>
            <div className="flexcontraseñas px-4">
              <label htmlFor="Certificado bancario" className=" ">
                Cédula
              </label>
              <input
                accept=".heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, application/msword, application/pdf"
                required={docreqs}
                onChange={changeCer_cc}
                type="file"
                name="Certificado bancario"
                id="Certibank"
                className="form-control"
                // className="form-control custom-file-input"
              ></input>
            </div>
            <div className="flexcontraseñas px-4">
              <label htmlFor="Certificado bancario" className=" ">
                RUT
              </label>
              <input
                accept=".heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, .doc, .docx, application/msword, application/pdf"
                required={docreqs}
                onChange={changeCer_rut}
                type="file"
                name="Certificado bancario"
                id="Certibank"
                className="form-control"
              ></input>
            </div>
            <div className="d-flex justify-content-center">
              <ButtonMob
                text={
                  docreqs == false
                    ? "Actualizar documentos"
                    : "Enviar documentos"
                }
                className="my-5 p-2 d-flex justify-content-center Botonentrar"
              />
            </div>
          </form>
          {modalInfo}
        </div>
      )}
    </>
  );
};
export default OnboardingEntrenador;
