import "./Navbar.css";
import logo_nav from "../assets/img/general/logo_alta.png";
import casaweb from "./iconosweb/inicioweb.png";
import casawebelevaweb from "./iconosweb/inicioweb_eleva.png";
import casawebchange from "./iconosweb/inicioweb1.png";
import pesawebchange from "./iconosweb/pesawebchange.png";
import inicioweb_one from "./iconosweb/inicioelevaweb_white.png";
import incioweb_two from "./iconosweb/inicioele.png";
import niñoweb from "./iconosweb/niñoweb .png";
import niñoweb_eleva from "./iconosweb/niñoweb_eleva.png";
import pesasweb from "./iconosweb/pesasweb.png";
import pesosweb from "./iconosweb/pesosweb.png";
import pesosweb_eleva from "./iconosweb/pesos_web_eleva.png";
import adjuntar from "../Entrenador/OnboardingTrainer/subir blanco.png";
import adjuntarchange from "../Entrenador/OnboardingTrainer/subirchange.png";
import glosario from "./icons/glosario.png";
import glosariochange from "./icons/glosariochange.png";
import glosario_eleva from "./icons/glosario_eleva.png";
import glosario_eleva_change from "./icons/glosario_eleva_change.png";
import faq from "./icons/faq.png";
import faq_eleva_change from "./icons/faq_eleva_change.png";
import faq_eleva from "./icons/faq_eleva.png";
import faqchange from "./icons/faqchange.png";
import terminos from "./icons/terminos.png";
import terminoschange from "./icons/terminoschange.png";
import equiposeimplementos from "./carrito.png";
import equiposeimplementos_eleva from "./iconosweb/carrito_compras_eleva.png";
import equiposeimplementos_eleva_change from "./iconosweb/carrito_eleva_change.png";
import equiposeimplementoschange from "./carritochange.png";
import politicas from "./icons/politicas.png";
import politicaschange from "./icons/politicaschange.png";
import campana_notifit from "./icons/campananoti.png";
import transacciones from "./icons/transacciones.png";
import transaccioneschange from "./icons/transaccioneschange.png";
import salir from "./icons/salir.png";
import { useDispatch, useSelector } from "react-redux";
import { action1 } from "../actions/action1";
import goback from "./icons/goback.svg";
import transacciones_eleva from "./iconosweb/billtera_eleva_web.png";
import transacciones_eleva_change from "./iconosweb/billetera_eleva_change.png";
import flechita from "./pequeñaflecha.png";

import trazado from "./Trazado 4443.png";

import campana from "./camapana.png";
import rayas from "./rayas.png";

import { Link, Redirect, useHistory } from "react-router-dom";
import { useRef } from "react";
import {
  perlo,
  basename,
  checkPathNavFoot,
  entrenadorType,
  laptop575,
  userObj,
  userType,
  instance,
} from "../utils/utils";

import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { action_customer } from "../actions/user_customer";

export const NavItems = (props) => {
  const laptop = window.matchMedia("(max-width: 920px)");
  const path = useLocation();
  const [otherIcons, setOtherIcons] = useState(<></>);
  const [color_change, setcolor_change] = useState(0);
  const checkPath = () => {
    return path.pathname.includes(`/ejericios`);
  };

  if (checkPath()) {
    return null;
  } else
    return (
      <>
        {!path.pathname.includes("completar") && laptop.matches === true ? (
          <>
            <div className="nav-icologo d-inline-block">
              <img src={logo_nav} alt="home" />
            </div>
            <Link
              onClick={() => setcolor_change(1)}
              style={color_change == 1 ? { background: "#F7F3ED" } : null}
              to={props.user.type ? `/${props.user.type}/inicio` : "/"}
              className="nav-item  mt-2 py-3 inicionav "
            >
              <div className="nav-ico d-inline-block ">
                <img
                  src={color_change == 1 ? casawebchange : casaweb}
                  alt="home"
                />
              </div>
              <div
                className="nav-title d-inline-block letrasnavi "
                style={color_change == 1 ? { color: "#CB3D82" } : null}
              >
                Inicio
              </div>
            </Link>
            {otherIcons}
            <a
              style={
                color_change == 2
                  ? { background: "#ffff", color: "#CB3D82" }
                  : null
              }
              onClick={() =>
                setcolor_change(2) ||
                window.open(`https://tiendasportcheck.com/`)
              }
              className="nav-item my-0  py-3"
            >
              <div className="nav-ico d-inline-block">
                <img
                  src={
                    color_change == 2
                      ? equiposeimplementoschange
                      : equiposeimplementos
                  }
                  alt="home"
                />
              </div>
              <div
                className="nav-title d-inline-block letrasnavi"
                style={color_change == 2 ? { color: "#CB3D82" } : null}
              >
                Equipos e implementos
              </div>
            </a>

            {/* <Link
            to="/ejercicios"
            className="nav-item my-0  py-3"
            onClick={() => setcolor_change(3)}
            style={color_change == 3 ? { background: "#ffff" } : null}
          >
            <div className="nav-ico d-inline-block">
              <img
                src={color_change == 3 ? glosariochange : glosario}
                alt="home"
              />
            </div>
            <div
              className="nav-title d-inline-block letrasnavi"
              style={color_change == 3 ? { color: "#CB3D82" } : null}
            >
              Mis videos
            </div>
          </Link> */}
            <Link
              to="/faq"
              className="nav-item my-0 py-3"
              onClick={() => setcolor_change(4)}
              style={color_change == 4 ? { background: "#ffff" } : null}
            >
              <div className="nav-ico d-inline-block">
                <img src={color_change == 4 ? faqchange : faq} alt="home" />
              </div>
              <div
                className="nav-title d-inline-block letrasnavi"
                style={color_change == 4 ? { color: "#CB3D82" } : null}
              >
                Preguntas frecuentes
              </div>
            </Link>

            <Link
              to="/tyc"
              className="nav-item my-0 py-3"
              onClick={() => setcolor_change(5)}
              style={color_change == 5 ? { background: "#ffff" } : null}
            >
              <div className="nav-ico d-inline-block">
                <img
                  src={color_change == 5 ? terminoschange : terminos}
                  alt="home"
                />
              </div>
              <div
                className="nav-title d-inline-block letrasnavi"
                style={color_change == 5 ? { color: "#CB3D82" } : null}
              >
                Términos y condiciones
              </div>
            </Link>

            <Link
              to="/privacidad"
              className="nav-item my-0 py-3"
              onClick={() => setcolor_change(6)}
              style={color_change == 6 ? { background: "#ffff" } : null}
            >
              <div className="nav-ico d-inline-block">
                <img
                  src={color_change == 6 ? politicaschange : politicas}
                  alt="home"
                />
              </div>
              <div
                className="nav-title d-inline-block letrasnavi"
                style={color_change == 6 ? { color: "#CB3D82" } : null}
              >
                Políticas de privacidad
              </div>
            </Link>

            {props.user.type == entrenadorType ? (
              <Link
                to="/OnboardingTrainer"
                className="nav-item my-0 py-3 d-none"
                onClick={() => setcolor_change(7)}
                style={color_change == 7 ? { background: "#ffff" } : null}
              >
                <div className="nav-ico d-inline-block">
                  <img
                    src={color_change == 7 ? adjuntarchange : adjuntar}
                    alt="home"
                  />
                </div>
                <div
                  className="nav-title d-inline-block letrasnavi"
                  style={color_change == 7 ? { color: "#CB3D82" } : null}
                >
                  Documentación
                </div>
              </Link>
            ) : (
              <Link
                to="/ResumenTransacciones"
                className="nav-item my-0 py-3"
                onClick={() => setcolor_change(7)}
                style={color_change == 7 ? { background: "#ffff" } : null}
              >
                <div className="nav-ico d-inline-block">
                  <img
                    src={
                      color_change == 7 ? transaccioneschange : transacciones
                    }
                    alt="home"
                  />
                </div>
                <div
                  className="nav-title d-inline-block letrasnavi"
                  style={color_change == 7 ? { color: "#CB3D82" } : null}
                >
                  Resumen de compras
                </div>
              </Link>
            )}

            <div className="LogOutEnd1">
              <div className="nav-separador1 d-inline-block my-1 "></div>

              <div
                style={{
                  color: " #9DACBD",
                  fontWeight: "bold",
                  fontsize: "0.9rem",
                }}
                onClick={perlo}
                className="nav-item my-0 logout py-3"
              >
                <div className="nav-ico d-inline-block">
                  <img src={salir} alt="home" />
                </div>
                <div className="nav-title d-inline-block letrasnavi">
                  Cerrar sesión
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {!path.pathname.includes("completar") ? (
              <>
                <div className="nav-icologo d-inline-block "></div>
                <div className="navbarcorrection">
                  <Link
                    onClick={() => setcolor_change(1)}
                    style={color_change == 1 ? { background: "#ffff" } : null}
                    to={props.user.type ? `/${props.user.type}/inicio` : "/"}
                    className="nav-item   mb-3 inicionav  py-2"
                  >
                    <div className="nav-ico d-inline-block ">
                      <img
                        src={color_change == 1 ? incioweb_two : inicioweb_one}
                        alt="home"
                      />
                    </div>
                    <div
                      className="nav-title d-inline-block letrasnavi "
                      style={
                        color_change == 1
                          ? { color: "#CB3D82", fontWeight: "bold" }
                          : null
                      }
                    >
                      Inicio
                    </div>
                  </Link>

                  {props.user.type == entrenadorType ? (
                    <Link
                      to={
                        props.user.type ? `/${props.user.type}/reservas` : "/"
                      }
                      onClick={() => setcolor_change(2)}
                      style={color_change == 2 ? { background: "#ffff" } : null}
                      className="nav-item my-2 mb-3  py-2"
                    >
                      <div className="nav-ico d-inline-block">
                        <img
                          src={color_change == 2 ? pesawebchange : pesasweb}
                          alt="home"
                        />
                      </div>
                      <div
                        className="nav-title d-inline-block letrasnavi"
                        style={
                          color_change == 2
                            ? { color: "#CB3D82", fontWeight: "bold" }
                            : null
                        }
                      >
                        Mis clases
                      </div>
                    </Link>
                  ) : (
                    <Link
                      onClick={() => setcolor_change(2)}
                      style={color_change == 2 ? { background: "#ffff" } : null}
                      to={
                        props.user.type ? `/${props.user.type}/MisClases` : "/"
                      }
                      className="nav-item my-2 mb-3 py-2"
                    >
                      <div className="nav-ico d-inline-block">
                        <img
                          src={color_change == 2 ? pesawebchange : pesasweb}
                          alt="home"
                        />
                      </div>
                      <div
                        className="nav-title d-inline-block letrasnavi"
                        style={
                          color_change == 2
                            ? { color: "#CB3D82", fontWeight: "bold" }
                            : null
                        }
                      >
                        Mis clases
                      </div>
                    </Link>
                  )}
                  {props.user.type == entrenadorType ? (
                    <Link
                      onClick={() => setcolor_change(3)}
                      style={color_change == 3 ? { background: "#ffff" } : null}
                      to={props.user.type ? `/OnboardingTrainer` : "/"}
                      className="nav-item my-2 mb-3 d-none"
                    >
                      <div className="nav-ico d-inline-block">
                        <img src={adjuntar} alt="home" />
                      </div>
                      <div className="nav-title d-inline-block letrasnavi">
                        Documentación
                      </div>
                    </Link>
                  ) : null}
                  {props.user.type == entrenadorType ? (
                    <></>
                  ) : (
                    <Link
                      onClick={() => setcolor_change(5)}
                      style={color_change == 5 ? { background: "#ffff" } : null}
                      to={
                        props.user.type ? `/${props.user.type}/reservas` : "/"
                      }
                      className="nav-item my-2 mb-3 py-2"
                    >
                      <div className="nav-ico d-inline-block">
                        <img
                          src={color_change == 5 ? niñoweb_eleva : niñoweb}
                          alt="home"
                        />
                      </div>
                      <div
                        className="nav-title d-inline-block letrasnavi"
                        style={
                          color_change == 5
                            ? { color: "#CB3D82", fontWeight: "bold" }
                            : null
                        }
                      >
                        Tipos de entrenamiento
                      </div>
                    </Link>
                  )}
                  {props.user.type == entrenadorType ? null : (
                    <Link
                      onClick={() => setcolor_change(4)}
                      style={color_change == 4 ? { background: "#ffff" } : null}
                      to={
                        props.user.type
                          ? `/${props.user.type}/creditos`
                          : `${basename}/`
                      }
                      className="nav-item my-2 mb-3 py-2"
                    >
                      <div className="nav-ico d-inline-block">
                        <img
                          src={color_change == 4 ? pesosweb_eleva : pesosweb}
                          alt="home"
                        />
                      </div>
                      <div
                        className="nav-title d-inline-block letrasnavi"
                        style={
                          color_change == 4
                            ? { color: "#CB3D82", fontWeight: "bold" }
                            : null
                        }
                      >
                        Mis créditos
                      </div>
                    </Link>
                  )}

                  <a
                    onClick={() =>
                      setcolor_change(6) ||
                      window.open(`https://tiendasportcheck.com/`)
                    }
                    style={
                      color_change == 6
                        ? { background: "#F7F3ED", cursor: "pointer" }
                        : { cursor: "pointer" }
                    }
                    className="nav-item my-2 mb-3  py-2"
                  >
                    <div className="nav-ico d-inline-block">
                      <img
                        src={
                          color_change == 6
                            ? equiposeimplementos_eleva_change
                            : equiposeimplementos_eleva
                        }
                        alt="home"
                      />
                    </div>
                    <div
                      className="nav-title d-inline-block letrasnavi "
                      style={
                        color_change == 6
                          ? { color: "#CB3D82", fontWeight: "bold" }
                          : null
                      }
                    >
                      Tienda
                    </div>
                  </a>

                  {props.user.type == entrenadorType ? null : (
                    <Link
                      to="/ResumenTransacciones"
                      className="nav-item my-2 mb-3 py-2"
                      onClick={() => setcolor_change(9)}
                      style={
                        color_change == 9 ? { background: "#F7F3ED" } : null
                      }
                    >
                      <div className="nav-ico d-inline-block">
                        <img
                          src={
                            color_change == 9
                              ? transacciones_eleva_change
                              : transacciones_eleva
                          }
                          alt="transacciones"
                        />
                      </div>
                      <div
                        className="nav-title d-inline-block letrasnavi"
                        style={
                          color_change == 9
                            ? { color: "#CB3D82", fontWeight: "bold" }
                            : null
                        }
                      >
                        Resumen de compras
                      </div>
                    </Link>
                  )}
                  <div className="linenabvarn"></div>
                  <Link
                    to="/faq"
                    className="nav-item my-2 mb-3  py-2 "
                    onClick={() => setcolor_change(8)}
                    style={color_change == 8 ? { background: "#F7F3ED" } : null}
                  >
                    <div className="nav-ico d-inline-block">
                      <img
                        src={color_change == 8 ? faq_eleva_change : faq_eleva}
                        alt="home"
                      />
                    </div>
                    <div
                      className="nav-title d-inline-block letrasnavi"
                      style={
                        color_change == 8
                          ? { color: "#CB3D82", fontWeight: "bold" }
                          : null
                      }
                    >
                      Preguntas frecuentess
                    </div>
                  </Link>
                  {props.user.type == entrenadorType ? null : (
                    <Link
                      onClick={
                        () => setcolor_change(7)
                        //  ||
                        // (window.location.href = `${basename}/ejercicios`)
                      }
                      style={
                        color_change == 7 ? { background: "#F7F3ED" } : null
                      }
                      to="/ejercicios"
                      className=" videoJoyce nav-item my-2 py-2 mb-3 "
                    >
                      <div className="nav-ico d-inline-block">
                        <img
                          src={
                            color_change == 7
                              ? glosario_eleva_change
                              : glosario_eleva
                          }
                          alt="home"
                        />
                      </div>
                      <div
                        className="nav-title d-inline-block letrasnavi"
                        style={
                          color_change == 7
                            ? { color: "#CB3D82", fontWeight: "bold" }
                            : null
                        }
                      >
                        Mis videos
                      </div>
                    </Link>
                  )}
                </div>
              </>
            ) : null}
          </>
        )}
      </>
    );
};

const Navbar = (props) => {
  useEffect(() => {
    let me = document.querySelector("#profilexc");
    if (me && me.classList.contains("show")) {
      instance
        .post(`app/notification/read/all`)
        .then((res) => {
          if (res.data) {
            dispatch(action1());
          }
        })
        .catch((err) => console.log(err.response));
    }
  }, [props.valuecollapse]);
  const pathnurl = window.location.href;
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const { db } = state.notifications;
  const nav1 = useRef(null);
  const nav2 = useRef(null);
  const nav3 = useRef(null);

  const history = useHistory();
  const userP = props.user;

  const avatarName = props.user.firts_name + " " + props.user.last_name;
  const avatar = localStorage.profile_customer
    ? localStorage.profile_customer
    : props.user.img;

  let intervalNotifications = null;
  useEffect(() => {
    if (props.isLogged) {
      dispatch(action_customer());
    }
  }, [pathnurl]);
  useEffect(() => {
    dispatch(action1());
    intervalNotifications = setInterval(async () => {
      if (avatar) {
        dispatch(action1());
      }
    }, 10000);
    return () => {
      clearInterval(intervalNotifications);
    };
  }, []);

  function collapseNav() {
    if (nav1.current && nav1.current.classList.contains("show")) {
      nav1.current && nav1.current.classList.remove("show");
    }

    nav2.current && nav2.current.classList.remove("show");
  }

  function collapseNavm() {
    nav3.current && nav3.current.classList.remove("show");
  }
  useEffect(() => {
    collapseNavm();
    collapseNav();
  }, [props.valuecollapse]);
  function marcarcomoleida(id) {
    instance
      .post(`app/notification/read/${id}`)
      .then((res) => {
        if (res.data) {
          instance
            .get("app/notifications/unread")
            .then((res) => {
              if (res.data) {
                dispatch(action1());
              }
            })
            .catch((err) => console.log(err.response));
        }
      })
      .catch((err) => console.log(err.response));
  }

  function checkIfNeedLogin() {
    if (props.valuecollapse == "up") {
      props.setValuecollapse("down");
    } else {
      props.setValuecollapse("up");
    }
    if (props.user.type == null) {
      window.location.href = `${basename}/`;
    }
  }

  const path = useLocation();
  const checkPath = () => {
    return (
      checkPathNavFoot(path.pathname) ||
      path.pathname.includes(`/${userType}/clase`) ||
      path.pathname.includes(`/${entrenadorType}/clase`) ||
      path.pathname.includes(`/${entrenadorType}/clasestream`)
    );
  };

  if (checkPath()) {
    return null;
  }
  // if (
  //   userP.profile === true &&
  //   !path.pathname.includes(`/${userType}/completar`)
  // ) {
  //   return <Redirect push={true} to={`/${userType}/completar/Load`} />;
  // }

  const user = userObj();

  if (
    path.pathname != "/Usuario/completar" &&
    !path.pathname.includes("/Usuario/completarTriage")
    //  &&
    // path.pathname != "/Usuario/completar/Load"
  ) {
    return (
      <>
        <nav
          className="navHeader sticky-top navbar navbar-dark  text-light "
          style={{
            background: "#F1F6F9",
          }}
        >
          {/* {!path.pathname.includes(`/Usuario/completar/Load`) ? ( */}
          <div className="d-flex d-block ">
            {!path.pathname.includes(`/${userType}/completar`) &&
            path.pathname != `/${userType}/reservas` &&
            !path.pathname.includes(`/Entrenador/inicio`) &&
            props.user.type ? (
              <img
                src={goback}
                alt="goback"
                className="goback"
                onClick={() => {
                  path.pathname.includes(`/${userType}/resumen`)
                    ? history.push(`/Usuario/reservas`)
                    : history.goBack();
                }}
              />
            ) : null}
            {props.user.type ? (
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#nvDropDown"
                aria-controls="nvDropDown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <img
                  className={
                    path.pathname == `/${userType}/reservas` ||
                    (path.pathname.includes(`/Entrenador/inicio`) &&
                      !laptop575.matches)
                      ? "rayas_1"
                      : "rayas"
                  }
                  src={rayas}
                  alt="av"
                />
              </button>
            ) : null}
          </div>
          {/* ) : null} */}

          {!path.pathname.includes(`/${userType}/completar/Load`) ? (
            <>
              <div className=" logoContainerNav">
                <img src={logo_nav} alt="logo" className="logo-img" />
              </div>{" "}
              <div>
                <button
                  onClick={checkIfNeedLogin}
                  className={
                    `profile ` +
                    (path.pathname.includes(`/${userType}/completar`)
                      ? "invisible"
                      : null)
                  }
                  data-bs-toggle="collapse"
                  data-bs-target="#profilexc"
                  aria-controls="profilexc"
                  type="button"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  {path.pathname.includes(`/responsePayu`) ||
                  path.pathname.includes(`/pdf/:`) ||
                  path.pathname.includes(`/tyc`) ||
                  path.pathname == "/" ||
                  path.pathname.includes(`/privacidad`) ? null : (
                    <div>
                      {" "}
                      {db && db.filter((e) => e.read == false).length > 0 ? (
                        <div
                          style={
                            laptop575.matches
                              ? {
                                  position: "absolute",
                                  top: "20%",
                                  right: "22%",
                                  backgroundColor: "#CB3D82",
                                  width: "1.1rem",
                                  height: "1.1rem",
                                  borderRadius: "50px",
                                  padding: "0.1rem",
                                }
                              : {
                                  position: "absolute",
                                  top: "20%",
                                  right: "29%",
                                  backgroundColor: "#CB3D82",
                                  width: "1.1rem",
                                  height: "1.1rem",
                                  borderRadius: "50px",
                                  padding: "0.1rem",
                                }
                          }
                        >
                          <div
                            style={{
                              position: "relative",
                              color: "white",
                              padding: "3%",
                              fontSize: "10px",
                            }}
                          >
                            <span>
                              {db.filter((e) => e.read == false).length}
                            </span>{" "}
                          </div>
                        </div>
                      ) : null}
                      <img className="campana" src={campana} alt="av" />
                    </div>
                  )}
                </button>
              </div>
              {path.pathname !== `${basename}/` &&
              path.pathname !== "/privacidad" &&
              path.pathname !== "/tyc" &&
              !pathnurl.includes("/responsePayu?") ? (
                <div
                  className={
                    props.user.type !== "Usuario" && laptop575.matches
                      ? "menu_superior_menu"
                      : "menu_superior_menu"
                  }
                >
                  <img className="line" src={trazado} alt="av" />
                  <h6 className="bie">BIENVENIDO </h6>
                  <h6 className="bieN">
                    {" "}
                    {avatarName.length > 16
                      ? avatarName.slice(0, 16) + "..."
                      : avatarName}
                  </h6>
                </div>
              ) : null}
              {props.user.img ? (
                <button
                  onClick={checkIfNeedLogin}
                  className={
                    `profile ` +
                    (path.pathname.includes(`/${userType}/completar`)
                      ? "invisible"
                      : null)
                  }
                  data-bs-toggle="collapse"
                  data-bs-target="#profile"
                  aria-controls="profile"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img className="flechades" src={flechita} alt="av" />
                  <img
                    className=" avatar rounded-circle"
                    src={avatar}
                    alt="av"
                  />
                </button>
              ) : (
                <Link
                  to={props.user.type == null ? "/ingreso" : "/"}
                  className={`logprofile`}
                >
                  Ingresar
                </Link>
              )}
            </>
          ) : (
            <div>
              <div onClick={perlo} className="nav-item my-2 logout d-row">
                <div className="nav-ico d-inline-block">
                  <img src={salir} alt="home" />
                </div>
                <div className="nav-title d-inline-block salirinicio">
                  Salir
                </div>
              </div>
            </div>
          )}
        </nav>

        <div
          ref={nav1}
          id="nvDropDown"
          className="collapse navbar-collapse position-fixed w-100"
          style={{ padding: "0" }}
        >
          <div id="navbarNavDropdown" onClick={() => collapseNav()}>
            <ul className="navbar-nav">
              <NavItems user={props.user} />
            </ul>
          </div>
        </div>
        <div ref={nav2} className="collapse navbar-collapse" id="profile">
          {user.code ? (
            <ul className="navbar-nav" onClick={() => collapseNav()}>
              <Link
                to={
                  (props.user.trainer &&
                    props.user.trainer.is_enabled &&
                    userP.type == "Entrenador") ||
                  userP.type == "Usuario"
                    ? `/${userP.type}/perfil`
                    : `/registro/${userP.type}`
                }
                className="nav-item text-left py-2 mr-4 px-3 "
              >
                {(props.user.trainer &&
                  props.user.trainer.is_enabled &&
                  userP.type == "Entrenador") ||
                userP.type == "Usuario"
                  ? "Ver Perfil"
                  : "Volver"}
              </Link>
              <div
                style={{
                  color: " #9DACBD",
                  fontWeight: "bold",

                  fontsize: "0.9rem",
                }}
                onClick={perlo}
              >
                <div className="nav-item text-right my-1 mx-3"></div>
                <div
                  className="nav-item text-left py-2 mr-4 mb-3 px-3 cierre_session"
                  style={{ cursor: "pointer" }}
                >
                  Cerrar Sesión
                </div>
              </div>
            </ul>
          ) : null}
        </div>
        <div ref={nav3} className="collapse navbar-collapse" id="profilexc">
          {user.code && db && db.length > 0 ? (
            <ul className="navbar-nav" onClick={() => collapseNavm()}>
              {db &&
                db.map((e, index) => (
                  <div
                    key={index}
                    onClick={() => marcarcomoleida(e.id)}
                    style={{
                      position: "relative",
                      margin: "0.4rem 0.9rem",
                      cursor: "pointer",
                    }}
                  >
                    {e.read == true ? null : (
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#D86DA1",
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                          opacity: "0.6",
                        }}
                      ></div>
                    )}
                    <div
                      className="itemRecom d-flex"
                      style={{
                        padding: "1vw",
                      }}
                    >
                      <img
                        src={campana_notifit}
                        alt="Icon"
                        style={
                          laptop575.matches
                            ? {
                                margin: "auto 1vw",
                                width: "2vw",
                                height: "2vw",
                                zIndex: "1",
                              }
                            : {
                                margin: "auto 1vw",
                                width: "8vw",
                                height: "8vw",
                                zIndex: "1",
                              }
                        }
                      />

                      <span
                        style={
                          e.read == true
                            ? {
                                padding: "0.8rem",

                                zIndex: "1",
                              }
                            : {
                                padding: "0.8rem ",

                                zIndex: "1",
                                color: "white",
                              }
                        }
                      >
                        {e.message}
                      </span>
                    </div>
                  </div>
                ))}
            </ul>
          ) : (
            <div
              className="itemRecom d-flex"
              style={{
                padding: "1vw",
              }}
            >
              <img
                src={campana_notifit}
                alt="Icon"
                style={
                  laptop575.matches
                    ? {
                        margin: "auto 1vw",
                        width: "2vw",
                        height: "2vw",
                        zIndex: "1",
                      }
                    : {
                        margin: "auto 1vw",
                        width: "6vw",
                        height: "6vw",
                        zIndex: "1",
                      }
                }
              />
              <span
                style={{
                  padding: "0.3rem 0",
                  marginLeft: "2rem",
                  zIndex: "1",
                }}
              >
                Aún no tienes notificaciones
              </span>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <nav
          className="navHeader sticky-top navbar navbar-dark  text-light "
          style={{
            background: "#F1F6F9",
          }}
        >
          <div className="d-flex d-block  ">
            <div
              className={
                laptop575.matches ? "d-none" : "salirregistro d-flex px-2  "
              }
            >
              <div
                className=" d-flex"
                onClick={() =>
                  path.pathname.includes(`/${userType}/resumen`)
                    ? history.push(`/Usuario/reservas`)
                    : history.goBack()
                }
              >
                <img src={goback} alt="home" className="imagensalir px-1" />
              </div>
              <div
                className="nav-title salirletrasregistro p-1"
                style={{ color: "#F1F6F9" }}
              >
                Volver
              </div>
            </div>
          </div>

          <div
            // onClick={() => window.location.reload()}
            className="navbar-brand  logoContainerNav"
          >
            <img src={logo_nav} alt="logo" className="logo-imgsolis" />
          </div>

          <div
            className={
              props.user.type == "Usuario" && laptop575.matches
                ? "menu_superior_menu_trainer"
                : "menu_superior_menu"
            }
          >
            <img className="line" src={trazado} alt="av" />
            <h6 className="bie">BIENVENIDO </h6>
            <h6 className="bieN">
              {" "}
              {avatarName.length > 16
                ? avatarName.slice(0, 16) + "..."
                : avatarName}
            </h6>
          </div>

          {props.user.img ? (
            <button
              onClick={checkIfNeedLogin}
              className={
                `profile ` +
                (path.pathname.includes(`/${userType}/completar`) &&
                laptop575.matches
                  ? "visible"
                  : "invisible")
              }
              data-bs-toggle="collapse"
              data-bs-target="#profile"
              aria-controls="profile"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img className="flechades" src={flechita} alt="av" />
              <img className=" avatar rounded-circle" src={avatar} alt="av" />
            </button>
          ) : !props.user.img &&
            path.pathname.includes(`/${userType}/completar`) ? (
            <div></div>
          ) : (
            <Link
              to={props.user.type == null ? `${basename}/` : "/"}
              className={`logprofile`}
            >
              Ingresar
            </Link>
          )}
        </nav>

        {/* <div
          ref={nav1}
          id="nvDropDown"
          className="collapse navbar-collapse position-fixed w-100"
          style={{ padding: "0" }}
        >
          <div id="navbarNavDropdown">
            <ul className="navbar-nav" onClick={() => collapseNav()}>
              <NavItems user={props.user} />
            </ul>
          </div>
        </div> */}
        <div ref={nav2} className="collapse navbar-collapse" id="profile">
          {user.code ? (
            <ul className="navbar-nav" onClick={() => collapseNav()}>
              <Link
                to={`/${userP.type}/perfil`}
                className="nav-item text-left py-2 mx-5"
              >
                Ver Perfil
              </Link>
              <div className="nav-item text-right my-1 mx-5"></div>
              <div
                style={{
                  color: " #9DACBD",
                  fontWeight: "bold",
                  fontsize: "0.9rem",
                }}
                onClick={perlo}
                className="nav-item text-left py-2 mx-5 mb-3"
              >
                Cerrar Sessión
              </div>
            </ul>
          ) : null}
        </div>
      </>
    );
  }
};

export default Navbar;
