import { Redirect, useParams } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import Moment, { defineLocale, months } from "moment";
import "./VerReservaEntrenador.css";
import { isIOS } from "react-device-detect";

import ReactHtmlParser from "react-html-parser";
import {
  ButtonMob,
  entrenadorType,
  ModalInfo,
  laptop575,
  instance,
  basename,
  initSocketConnection,
  booking_trainer_connected_emit,
  hr_device_connected_emit,
  booking_started_emit,
} from "../../utils/utils";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/pagination/pagination.min.css";
import { Lesiones } from "../../Perfil/Perfil";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Media from "react-media";
import { extendMoment } from "moment-range";

SwiperCore.use([Pagination]);

const VerReservaEntrenador = (props) => {
  const user = props.user;
  const moment = extendMoment(Moment);
  const { idreserva } = useParams();

console.log(idreserva)
  const [infoReserva, setInfoReserva] = useState({
    name: null,
    nivel: null,
    edad: null,
    objetivo: null,
    clase: null,
    sesiones: null,
    fecha: null,
  });

  const [dataClase, setInfoClase] = useState(null);

  const [renderInfoClase, setRenderInfoClase] = useState(null);
  const [infoLesiones, setInfoLesiones] = useState(null);
  const [InfoComments, setInfoComments] = useState(null);

  const [modalInfo, setModalInfo] = useState(null);
  const [clickClass, setclickClass] = useState(false);
  const [Modal, setModal] = useState(null);
  const containerIniciar = useRef(null);

  function hideModal() {
    setModalInfo(null);
    setModal(null);
  }

  const [title, setTitle] = useState(null);
  const nombreClase = useRef(null);
  let intervalSearch = null;

  function buscarClaseIniciada(siesGrupal, now) {
    if (laptop575.matches) {
      // console.log(now);
      instance.get(`booking/book/${idreserva}`).then((resEn) => {
        // console.log(resEn.data);
        const idrroom = resEn.data;
        if (idrroom && idrroom.date <= now) {
          // play();
          setclickClass(true);
          // console.log(isgroup);
          if (containerIniciar.current) {
            if (siesGrupal) {
              // play();
              containerIniciar.current.classList.remove("disabled");
              containerIniciar.current.removeAttribute("disabled");
              // containerIniciar.current.children[0].removeAttribute("disabled");
              containerIniciar.current.classList.add("resplandor1");
            } else {
              if (idrroom.room_id == 0 || !idrroom.room_id > 0) {
                containerIniciar.current.classList.remove("disabled");
                containerIniciar.current.removeAttribute("disabled");
                // containerIniciar.current.children[0].removeAttribute("disabled");
                containerIniciar.current.classList.add("resplandor");
              } else {
                containerIniciar.current.classList.remove("disabled");
                containerIniciar.current.removeAttribute("disabled");
                // containerIniciar.current.children[0].removeAttribute("disabled");
                containerIniciar.current.classList.add("resplandor1");
              }
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    
    localStorage.elevvaAppReserva=idreserva;

    initSocketConnection(
      function (client) {
        console.log("estatus connected client", client);
        // Puedes agregar lógica adicional aquí si es necesario
      },
      (change) => {
        console.log("booking log data", change);
        // Puedes agregar lógica adicional aquí si es necesario
      },
      (workoutlog) => {
        // Puedes agregar lógica adicional aquí si es necesario
      },
      (band) => {
        console.log(band);
        localStorage.elevvaBandConected = true;
      },
      (bandDisconnected) => {
        console.log(bandDisconnected);
        localStorage.elevvaBandConected = false;
      },
      (hrData) => {
        console.log(hrData);
      },
      (lock) => {
        console.log(lock);
        if (lock) {
          console.log(lock);
        }
      }
    );
    var siesGrupal;

    instance
      .get(`/booking/book/prepare/${idreserva}`)
      .then((res) => {
        const info = res.data;
        if (info) {
          console.log(info);
          siesGrupal = info.is_group;

          setInfoClase(info.session.routine);
          setInfoReserva({
            name: info.customer.full_name,
            injuries: info.customer_injuries,
            nivel: info.customer.level,
            edad: info.customer.age,
            sesion_count: info.session_count,

            objetivo: info.customer.objectives.map((e) =>
              e == 10
                ? " Perder peso"
                : e == 20
                ? " Salud/Diversión "
                : e == 30
                ? " Mejorar físico "
                : e == 40
                ? " Masa muscular "
                : e == 50
                ? " Tonificar "
                : " Mejorar nivel "
            ),
            clase: info.training.name.toLowerCase(),
            sesiones: info.customer.age,
            fecha: moment(info.date.slice(0, 10), "YYYY-MM-DD").format(
              " dddd DD MMMM "
            ),
            hora: moment(info.date.slice(11, 16), "HH:mm").format("LT a"),

            equipment: info.customer.equipment,
          });
          setInfoComments(info.customer_comments);
          setInfoLesiones(info.customer_injuries);
          setTitle(info.training.name.toLowerCase());
          nombreClase.current = info.training.name.toLowerCase();
        }
      })
      .catch((err) => {
        console.log("Error load_user_data_a", err);
      });
    intervalSearch = setInterval(() => {
      var now = moment().format();
      buscarClaseIniciada(siesGrupal, now);
    }, 5000);
    return () => {
      clearInterval(intervalSearch);
    };
  }, [idreserva]);

  const InfoLesiones = (
    <SwiperSlide
      className="col-xl-6 col-12 "
      style={
        laptop575.matches
          ? null
          : { height: "100%", overflowY: "auto", overflowX: "hidden" }
      }
    >
      <Lesiones
        lesionesdesdetrainer={infoReserva.injuries}
        infoLesiones={infoLesiones}
        position="absolute"
        isProfile={false}
        trainer={true}
      />
    </SwiperSlide>
  );
  const InfoCliente = (
    <SwiperSlide className="mb-xl-5 col-xl-6 col-12">
      <div className="info darplus">
        <div className="item">
          <div className="tit">Usuario</div>
          <div className="dat">{infoReserva.name}</div>
        </div>
        <div className="item">
          <div className="tit">Nivel</div>
          <div className="dat">
            {infoReserva.nivel == 20
              ? "Intermedio"
              : infoReserva.nivel == 30
              ? "Avanzado"
              : "Principiante"}
          </div>
        </div>
        <div className="item">
          <div className="tit">Edad</div>
          <div className="dat">{infoReserva.edad} años</div>
        </div>
        <div className="item">
          {infoReserva.objetivo ? (
            <div className="tit">
              {infoReserva.objetivo.length > 1 ? "Objetivos" : "Objetivo"}
            </div>
          ) : null}
          {infoReserva.objetivo ? (
            <div className="dat">
              {infoReserva.objetivo.map((e) => (
                <div>{e}</div>
              ))}
            </div>
          ) : null}
        </div>
        {laptop575.matches ? (
          <div className="item">
            <div className="tit">Clase</div>
            <div className="dat">{title}</div>
          </div>
        ) : null}
        <div className="item">
          <div className="tit">Sesiones realizadas</div>
          <div className="dat">
            {infoReserva.sesion_count ? infoReserva.sesion_count : 0}
          </div>
        </div>
        <div className="item">
          <div className="tit ">
            Equipos para entrenar
            <br /> disponibles
          </div>
          <div className="dat">
            {infoReserva.equipment ? infoReserva.equipment : "No registrados"}
          </div>
        </div>
        <div className="item">
          <div className="tit">Agendado</div>
          <div className="dat">{infoReserva.fecha}</div>
          <div className="dat">{infoReserva.hora}</div>
        </div>
      </div>
    </SwiperSlide>
  );
  const comentariosInfo = (
    <SwiperSlide className="mb-xl-5 col-xl-6 col-12 scrolldolorcoments_eleva">
      {InfoComments &&
        InfoComments.map((comments) => {
          var a = comments.trainer;
          var b = a.indexOf("<");
          var c = a.indexOf(">");
          var mostrar = a.substring(b, c + 1);
          var name = a.replace(new RegExp(mostrar, "g"), "");
          return (
            <div className="info darplus1  " style={{ height: "auto" }}>
              <div className="item" style={{ marginBottom: "0px" }}>
                <div className="tit colorchangr">
                  {" "}
                  {name}- {moment(comments.date.slice(0, 10)).format("L")}
                </div>
                <div className="dat">{comments.comment}</div>
              </div>
            </div>
          );
        })}
    </SwiperSlide>
  );
  // console.log("dataClase", dataClase, "------------");

  useEffect(() => {
    if (dataClase != null) {
      let buffer = [];

      buffer.push(
        dataClase.map((e, i) => (
          <div>
            <div className="item metodologia d-flex " key={i + "met"}>
              <div
                className={
                  laptop575.matches
                    ? "name text-capitalize w-50 text-center"
                    : "name text-capitalize   w-50 text-center"
                }
              >
                {e.series > 1 ? e.name + " x" + e.series : e.name + ""}
              </div>
              <div
                style={{ width: "50%", textAlign: "center" }}
                className={laptop575.matches ? "time " : "time"}
              >
                Tiempo
                {" " + (e.time / 60).toFixed(0) + " "}
                Min
              </div>
            </div>
            <div className="item ejercicio">
              <div className={laptop575.matches ? "px-4" : ""}>
                <div className="d-flex w-100">
                  <div
                    style={!laptop575.matches ? { width: "50%" } : null}
                    className={
                      laptop575.matches
                        ? "name text-capitalize text-left "
                        : "name text-capitalize  text-left "
                    }
                  >
                    Ejercicio
                  </div>
                  <div
                    style={!laptop575.matches ? { width: "50%" } : null}
                    className={
                      laptop575.matches
                        ? "name text-capitalize text-center"
                        : "name text-capitalize  text-center "
                    }
                  >
                    {e.series > 1 ? "Repeticiones" : "Tiempo"}
                  </div>
                </div>
                {e.set.map((el, i) => (
                  <div className="d-flex ">
                    <div
                      key={"set" + i}
                      style={
                        !laptop575.matches
                          ? { width: "50%" }
                          : { width: "13.5vw", margin: "auto" }
                      }
                    >
                      {el.exercise}{" "}
                    </div>
                    <div
                      style={
                        !laptop575.matches
                          ? { width: "50%", margin: "auto" }
                          : { width: "13.5vw", margin: "auto" }
                      }
                      className={
                        laptop575.matches
                          ? "name text-capitalize "
                          : "name text-capitalize  text-center "
                      }
                    >
                      <div
                        key={"time" + i}
                        style={{
                          fontWeight: "0",
                        }}
                        className=" d-row text-center"
                      >
                        {el.time == 0 && el.repetitions
                          ? el.repetitions
                          : el.time}{" "}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div
                className={
                  laptop575.matches
                    ? "name text-capitalize mx-5"
                    : "name text-capitalize  text-center mx-4"
                }
              >
                {e.series > 1 ? "Repeticiones" : "Tiempo"}
                {e.set.map((el, i) => (
                  <div
                    key={"time" + i}
                    style={{
                    
                      fontWeight: "0",
                    }}
                    className=" d-row text-center"
                  >
                    {el.time == 0 && el.repetitions ? el.repetitions : el.time}{" "}
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        ))
      );

      setRenderInfoClase(buffer);
    }
    return () => {};
  }, [dataClase]);

  const InfoClase = (
    <SwiperSlide className="col-12 tamañoreal">
      <div className="infoClase">
        <div className="decirLesionTitulo seva " style={{ marginTop: "4vh" }}>
          Sesión de entrenamiento
        </div>
        <div className="infoD seva">
          Acá encontraras el resumen de tu clase, perfil del usuario, lesiones y
          rutinas.
        </div>
        <div className="content info darplus2">{renderInfoClase}</div>
      </div>
    </SwiperSlide>
  );

  const cancelar = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui CancelarReserva">
            <h1 className="title text-center ">
              ¿Deseas cancelar tu entrenamiento?
            </h1>
            <p className="msg">
              Ten en cuenta que si cancelas dentro de las 24 horas antes de tu
              clase, se te penalizara con el valor de tu clase.
            </p>

            <div className="buttons" style={{ justifyContent: "center" }}>
              <button className="No" onClick={onClose}>
                <strong>No</strong> quiero cancelar
              </button>
              <button
                className="Si"
                onClick={async () => {
                  const resC = await instance.post(
                    `booking/book/cancel/${idreserva}`
                  );

                  onClose();
                  setModalInfo(<Redirect to={`/${entrenadorType}/inicio`} />);
                }}
              >
                Cancelar el entrenamiento
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const iniciarClase = () => {
    
    
    instance.post(`booking/book/start/${idreserva}`).then((resEn) => {
      const data = resEn.data;

      if (data.room_id) {
        booking_trainer_connected_emit({booking_id:idreserva + "",trainer_id:localStorage.uuid})
        if (localStorage.room_id && localStorage.room_id != data.room_id) {
          localStorage.removeItem("room_id");
          localStorage.removeItem("actualEjercicio");
          localStorage.removeItem("actualRutinaTime");
          localStorage.removeItem("actualIndex");

          localStorage.removeItem("lastWorkoutlog");
          localStorage.removeItem("percentageClass");
          localStorage.removeItem("workoutSetClass");
          localStorage.removeItem("seriesClass");
          localStorage.removeItem("MethodologyClass");
          localStorage.removeItem("timestampClass");
        }
        localStorage.agoraToken = data.agora_token;

        window.location.href = `${basename}/${entrenadorType}/clase/${idreserva}/${data.room_id}`;
       
      } else {
        setModal(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Cliente sin ingresar"
            text={
              "Aún no ha ingresado el cliente, al hacerlo iniciará el entrenamiento"
            }
            classesMsg={"FaltanItems"}
          />
        );
      }
    });
  };

  return (
    <div className="entrenadorInicioContainer entrenadorVerReserva">
      {laptop575.matches ? (
        <div className="bievenidoainicio">
          <div className="d-flex">
            <div className="SubTitle8perfilcual dalepower">Bienvenido a</div>
            <div className="lineainiciousuario "></div>
          </div>
          <div className="titlec text-left dalepowerl w-100">
            Resumen de clase
          </div>
          <div className="SubTitle8perfilcual text-left col-12 w-100 mb-3 dalepowerl">
            Toda la información necesaria para dar tu mejor clase.{" "}
          </div>
        </div>
      ) : (
        <div className="title">
          <div className="suptitle">Resumen de clase</div>
          {title}
        </div>
      )}
      <div className="separador"></div>
      <Media query="(max-width: 844px)">
        {(matches) =>
          matches ? (
            <>
              <Swiper
                style={isIOS ? { height: "45vh" } : { height: "50vh" }}
                className="mb-4 "
                spaceBetween={10}
                slidesPerView={1}
                pagination={{
                  el: ".stepsBullets",
                  type: "custom",
                  renderCustom: function (_swiper, current) {
                    if (current === 1) {
                      setTitle(nombreClase.current);
                    } else if (current === 2) {
                      setTitle("Detalle de lesiones");
                    } else if (current === 3) {
                      setTitle("Comentarios adicionales");
                    } else if (current === 4) {
                      setTitle("Sesion de entrenamiento");
                    }
                    return `<div class='swiper-pagination-bullet Bullet  ${
                      current == 1 ? "active" : ""
                    }'></div>
                                                <div class='swiper-pagination-bullet Bullet ${
                                                  current == 2 ? "active" : ""
                                                }'></div>
                                                <div class='swiper-pagination-bullet Bullet      ${
                                                  current == 3 ? "active" : ""
                                                }'></div>
                                                <div class='swiper-pagination-bullet Bullet      ${
                                                  current == 4 ? "active" : ""
                                                }'></div>`;
                  },
                  clickable: false,
                }}
              >
                {InfoCliente}
                {InfoLesiones}
                {comentariosInfo}
                {InfoClase}
                {Modal}
              </Swiper>
              <div className="stepsBullets stepsBulletssubircancel"></div>
              <ButtonMob
                className=" cancelar mb-2 anterior"
                text="Cancelar entrenamiento"
                onClick={cancelar}
              />
            </>
          ) : (
            <>
              <div className=" d-flex  marginresumentotal ">
                <div className="colorfondo">{InfoCliente}</div>

                <div className="colorfondo1">
                  <div
                    style={{
                      position: "absolute",
                      top: "4vh",
                      width: "21.0vw",
                      zIndex: "1",
                    }}
                  >
                    <div className="decirLesionTitulo seva mx-2">Lesionado</div>
                    {infoLesiones && infoLesiones.length > 1 ? (
                      <div className=" text-left text colornuevaletra my-3">
                        El usuario a confirmado{" "}
                        {infoLesiones ? infoLesiones.length : null}{" "}
                        {infoLesiones
                          ? infoLesiones.length > 1
                            ? "lesiones."
                            : "lesión."
                          : null}
                      </div>
                    ) : (
                      <div className=" text-left text colornuevaletra my-3">
                        El usuario no presenta ninguna lesión
                      </div>
                    )}
                    <div>
                      <div className="scrolldolor">
                        {infoLesiones
                          ? infoLesiones.map((lesion) => {
                              return (
                                <div className="d-row ">
                                  <div className="d-flex">
                                    <div className="circulorojolesion "></div>
                                    <div className="text-left title colornuevaletra1">
                                      {lesion.part == 8
                                        ? "Lesión Abdomen"
                                        : lesion.part == 9
                                        ? "Lesión Ingle Cadera"
                                        : lesion.part == 1
                                        ? "Lesión Cuello"
                                        : lesion.part == 3
                                        ? "Lesión hombro derecho"
                                        : lesion.part == 5
                                        ? "Lesión codo derecho"
                                        : lesion.part == 7
                                        ? "Lesión Mano derecha"
                                        : lesion.part == 2
                                        ? "Lesión hombro izquierdo"
                                        : lesion.part == 4
                                        ? "Lesión codo izquierdo"
                                        : lesion.part == 6
                                        ? "Lesión Mano izquierda"
                                        : lesion.part == 10
                                        ? "Lesión rodilla izquierda"
                                        : lesion.part == 11
                                        ? "Lesión rodilla derecha"
                                        : lesion.part == 12
                                        ? "Lesión pie izquierdo"
                                        : lesion.part == 13
                                        ? "Lesión pie derecho"
                                        : lesion.part == 14
                                        ? "Lesión columna alta"
                                        : lesion.part == 17
                                        ? "Lesión lumbares"
                                        : lesion.part == 15
                                        ? "Lesión gluteós"
                                        : lesion.part == 16
                                        ? "Lesión columna baja"
                                        : null}
                                    </div>
                                  </div>
                                  <div
                                    className="text-left colornuevaletra my-2"
                                    style={{ paddingLeft: "1.8vw" }}
                                  >
                                    {lesion.summary}
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                 
                  <div
                    style={{
                      position: "absolute",
                      top: "39vh",
                      width: "21vw",
                      zIndex: "1",
                    }}
                  >
                    <div className="decirLesionTitulo seva w-100 ">
                      Comentarios adicionales
                    </div>
                    {comentariosInfo}
                  </div>
                  {InfoLesiones}
                </div>
                {/* <button onClick={()=>booking_started_emit({booking_id :"1111"}) }>hrconected</button> */}
                <div className="colorfondo2">
                  {InfoClase}
                 
                  <nav className="CompletarFooter navFooter  ">
                    <div
                      style={{ height: "7vh" }}
                      className="anterior colorcancelar"
                      onClick={cancelar}
                    >
                      Cancelar
                    </div>
                    <div
                      style={{ height: "7vh" }}
                      className="siguiente colorINICIAR"
                      onClick={clickClass ? iniciarClase : null}
                      ref={containerIniciar}
                      disabled
                    >
                      Iniciar <i className="fa fa-arrow-right m-1"></i>
                    </div>
                  </nav>
                 
                </div>
              </div>
            </>
          )
        }
      </Media>
      {Modal}
      {modalInfo}
    </div>
  );
};

export default VerReservaEntrenador;
