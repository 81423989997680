// Use the same css as Ejercicios.css
import search from "../Entrenador/assets/icons/search.png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

import { AccordionItem } from "../Entrenador/Perfil/Perfil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import {
  ButtonMob,
  laptop,
  laptop575,
  instance,
  Loadingspinner,
  ModalInfo,
  userType,
  filterCheckDays,
  NuevaAxiosURL,
} from "../utils/utils";
import tyc from "../assets/img/reservasimages/tyc.png";
import politicas from "../assets/img/reservasimages/politicas.png";

const marginTawk = {
  marginBottom: "75px",
};

const Faq = (props) => {
  const user = props.user;
  const [accordionItems, setAccordionItems] = useState(null);
  const searchText = useRef(null);

  useEffect(() => {
    searchFaq(); // search all the faqs
  }, []);

  function searchFaq(search = "") {
    instance
      .get(`app/faqs`)
      .then((res) => {
        const info = res.data.filter((e) =>
          e.question.toLowerCase().includes(search.toLowerCase())
        );

        const arrData = [];

        let count = 0;
        if (info) {
          for (const iterator of info) {
            const name = unescape(iterator.question);
            const desc = unescape(iterator.answer);
            arrData.push(
              <AccordionItem
                key={name}
                dataBsParent="#accordionGlosario"
                title={name}
                item={count}
              >
                <div className="mb-3 info">{desc}</div>
              </AccordionItem>
            );
            count += 1;
          }
        }
        if (count === 0) {
          setAccordionItems(
            <div className="form-text text-center" style={{ color: "#394F65" }}>
              No se encontrarón preguntas.
            </div>
          );
        } else {
          setAccordionItems(arrData);
        }
      })
      .catch((err) => {
        console.log("Error load_pregunras_a", err);
      });
  }

  return (
    <div className="contenedorFijo" style={marginTawk}>
      {laptop575.matches ? (
        <div className="bievenidoainicio">
          <div className="d-flex">
            <div className="SubTitle8perfilcual dalepower">Bienvenido a</div>
            <div className="lineainiciousuario"></div>
          </div>
          <div className="titlepreguntas creditos">Preguntas frecuentes</div>
        </div>
      ) : (
        <div className="title mt-3">
          Preguntas frecuentes
          <div className="breadcum">
            Inicio
            <FontAwesomeIcon className="mx-2" icon={faPlay} color="#9EACED" />
            Preguntas
          </div>
          <div className="separador my-3"></div>
        </div>
      )}

      <div className=" preguntasfrecuentesweb">
        <div className="searchEjercicio mx-auto">
          <input
            ref={searchText}
            type="search"
            className="input_search"
            onChange={() => searchFaq(searchText.current.value)}
          />
          <img
            src={search}
            alt="search icon"
            className="search_icon search_iconEjerci1"
          />
        </div>

        <div className="glosario glosariopreguntas">
          <div
            style={laptop575.matches ? { padding: "1rem 6rem 1rem 0 " } : null}
            className="accordion accordion-flush pt-3"
            id="accordionGlosario"
          >
            {accordionItems}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;

export const Terminos = (props) => {
  const [accordionItems, setAccordionItems] = useState(null);

  useEffect(() => {
    searchTerminos(); // search all the faqs
  }, []);

  function searchTerminos() {
    const formData = new FormData();
    formData.append("data", 1);
    axios
      .get(`${NuevaAxiosURL}app/config`)

      .then((res) => {
        const info = res.data;

        const arrData = [];
        let count = 0;
        if (info) {
          for (const iterator of info) {
            // const name = (
            //   <span className="terminos">Términos y condiciones</span>
            // );
            const desc_items = unescape(iterator.terms)
              .split("\n")
              .map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              });
            const desc = desc_items;
            arrData.push(
              // <AccordionItem
              //   key={count}
              //   dataBsParent="#accordionGlosario"
              //   title={name}
              //   item={count}
              // >
              <div className="mb-3 infotext_see p-2 m-auto">{desc}</div>
              // {/* </AccordionItem> */}
            );
            count += 1;
          }
        }
        if (count === 0) {
          setAccordionItems(
            <div className="form-text text-center" style={{ color: "#394F65" }}>
              No se encontrarón preguntas.
            </div>
          );
        } else {
          setAccordionItems(arrData);
        }
      })
      .catch((err) => {
        console.log("Error load_terminos_a", err);
      });
  }

  return (
    <div className="contenedorFijo" style={marginTawk}>
      <div className="text-center mt-2">
        <img
          src={tyc}
          alt="logo"
          style={laptop575.matches ? null : { width: "50px" }}
        />
      </div>

      <div className="title mt-3">
        Términos y condiciones
        {laptop575.matches ? null : (
          <div className="breadcum mx-auto text-center mt-1 ">
            Inicio
            <FontAwesomeIcon className="mx-2" icon={faPlay} color="#CB3D82" />
            Términos
          </div>
        )}
      </div>
      <div className="separador my-3"></div>
      <div className="w-100">
        <div
          className="glosario  "
          style={laptop575.matches ? { padding: "4%" } : null}
        >
          <div
            className="accordion accordion-flush pt-3"
            id="accordionGlosario"
          >
            {accordionItems}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Politicas = (props) => {
  const user = props.user;
  const [accordionItems, setAccordionItems] = useState(null);

  useEffect(() => {
    searchPoliticas(); // search all the faqs
  }, []);

  function searchPoliticas() {
    axios
      .get(`${NuevaAxiosURL}app/config`)
      .then((res) => {
        const info = res.data;
        const arrData = [];
        let count = 0;
        if (info) {
          for (const iterator of info) {
            const desc_items = unescape(iterator.privacy_policy)
              .split("\n")
              .map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              });
            const desc = desc_items;
            arrData.push(
              <div
                key={count}
                className={
                  laptop575.matches
                    ? "desc p-5 infotext_see m-auto"
                    : "mb-3 infotext_see p-2 m-auto"
                }
              >
                {desc}
              </div>
            );
            count += 1;
          }
        }
        if (count === 0) {
          setAccordionItems(
            <div className="form-text text-center" style={{ color: "#394F65" }}>
              No se encontrarón preguntas.
            </div>
          );
        } else {
          setAccordionItems(arrData);
        }
      })
      .catch((err) => {
        console.log("Error load_politicas_a", err);
      });
  }

  return (
    <div className="contenedorFijo" style={marginTawk}>
      <div className="text-center mt-2">
        <img
          src={politicas}
          alt="logo"
          style={laptop575.matches ? null : { width: "50px" }}
        />
      </div>
      <div className="title mt-3">
        Políticas de privacidad
        {laptop575.matches ? null : (
          <div className="breadcum mt-1">
            Inicio
            <FontAwesomeIcon className="mx-2" icon={faPlay} color="#CB3D82" />
            Políticas
          </div>
        )}
      </div>
      <div className="separador my-3"></div>
      <div className="w-100">
        <div className="glosario">
          <div
            className="accordion accordion-flush pt-3"
            id="accordionGlosario"
          >
            {accordionItems}
          </div>
        </div>
      </div>
    </div>
  );
};
