import { useHistory, useParams } from "react-router";
import { ButtonMob, laptop575 } from "../utils/utils";
import { useState, useRef } from "react";
import "./vertipoindividual.css";
import { Videos_two } from "../Ejercicios/othervideos";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const VerinfoIndividual = () => {
  const { id } = useParams();
  const history = useHistory();
  const searchText = useRef(null);
  const videosTime = [
    ["Intro", process.env.PUBLIC_URL + "/img/video1.png"],
    ["Clase 1", process.env.PUBLIC_URL + "/img/video2.png"],
    // ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
    // ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
    // ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
    // ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
    // ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
    // ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
  ];
  const videoIndividual = [
    process.env.PUBLIC_URL + "/img/imagenunidad.png",
    "Pierna y abdomen",
    "35min",
  ];
  const [reproducir, setreproducir] = useState(false);
  return (
    <div
      className="d-flex m-auto"
      style={{
        height: "100vh",
      }}
    >
      {/* {laptop575.matches ? (
        <div className="mx-auto" style={{ width: "7.1%" }}></div>
      ) : null} */}
      {laptop575.matches ? (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex"
            style={{
              background: "rgb(241, 246, 249)",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
              flexDirection: "column",
            }}
          >
            <div
              className="py-2 mx-3 banner_one "
              style={{
                background: "rgb(241, 246, 249)",

                color: "#394F65",
              }}
            >
              {" "}
              <div
                onClick={() => history.goBack()}
                className=" position-absolute"
                style={{
                  left: "5%",
                  top: "35%",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {" "}
                <span className="d-flex m-auto">
                  <span
                    className="my-auto mx-1 px-2 py-1 d-flex"
                    style={{
                      border: "1px solid #394F65",
                      justifyContent: "center",
                      color: "#394F65",
                      borderRadius: "10px",
                    }}
                  >
                    {" "}
                    <i className="fa fa-arrow-left  "></i>
                  </span>
                  <span className="my-auto m-1" style={{ color: "#394F65" }}>
                    Regresar
                  </span>
                </span>
              </div>
              <div
                className="d-flex position-absolute"
                style={{
                  left: "45%",
                  top: "30%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <img
                  style={{ width: "200px" }}
                  src={process.env.PUBLIC_URL + "/img/logosEmpresas.png"}
                />{" "}
              </div>
              <Link
                className="form searchEjercicio mx-auto py-2 d-flex"
                style={{ justifyContent: "flex-end" }}
                to="/ejercicios/Buscar"
              >
                <input
                  placeholder="Buscar..."
                  ref={searchText}
                  type="search"
                  className="input_searchvideos ejercicios_c py-2"
                  // onChange={(e) => changeOptionVideos(e.target.value)}
                ></input>
              </Link>
            </div>
          </div>
          <div
            className="d-flex col-12"
            style={{
              paddingLeft: "5%",
            }}
          >
            <div
              className="col-6 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="position-relative" style={{ padding: "2%" }}>
                <img
                  style={{ width: "100%", borderRadius: "50px" }}
                  className="position-relative playervideoIndividual"
                  src={
                    id == "Entrenamiento" || id == "Entrenamiento0"
                      ? process.env.PUBLIC_URL + "/img/programabalance.jpg"
                      : id == "Entrenamiento1"
                      ? process.env.PUBLIC_URL + "/img/Entrenamiento1.jpg"
                      : id.includes("Nutrición")
                      ? process.env.PUBLIC_URL + "/img/nutricionprograma.jpg"
                      : id.includes("Mente")
                      ? process.env.PUBLIC_URL + "/img/autocompaprograma.jpg"
                      : process.env.PUBLIC_URL + "/img/autocompaprograma.png"
                  }
                />
                <div
                  className="position-absolute"
                  style={{ bottom: "7%", left: "7%" }}
                >
                  <button
                    style={{
                      marginTop: "1vmin",
                      marginBottom: "1vmin",
                      width: "15vmin",
                    }}
                    onClick={() => setreproducir(!reproducir)}
                    className="buttonIndividualTypesverahora"
                  >
                    Programa
                  </button>
                </div>
              </div>
            </div>

            <div
              className="col-6 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "#394F65",
                  fontSize: "3vmin",
                  padding: "0 2%",
                  fontWeight: "600",
                }}
              >
                {id == "Entrenamiento1"
                  ? "Elevva funcional"
                  : id == "Entrenamiento" || id == "Entrenamiento0"
                  ? "Balance training"
                  : id.includes("Mente")
                  ? "El poder de la autocompasión"
                  : id.includes("Nutrición")
                  ? "Comida y emociones"
                  : id == "Mente1"
                  ? "El poder de la autocompasión"
                  : "Cuerpo y mente saludable"}
              </span>

              <span
                style={{
                  color: "#394F65",
                  fontSize: "1.6vmin",
                  padding: "0 2%",
                }}
              >
                El programa de entrenamiento funcional de elevva, tiene como
                objetivo aumentar la resistencia cardiovascular, el desarrollo
                de la fuerza y la perdida de grasa residual del cuerpo.
              </span>
              <br />
              <span
                style={{
                  color: "#394F65",
                  fontSize: "2.5vmin",
                  padding: "0 2%",
                  fontWeight: "500",
                }}
              >
                Progreso
              </span>
              <br />
              <div
                style={{
                  padding: "0 2%",
                }}
              >
                <img src={process.env.PUBLIC_URL + "/img/progreso.png"} />{" "}
              </div>
              <br />
              <div
                className="position-relative d-flex"
                style={{
                  justifyContent: "flex-start",
                  left: "0",
                  alignItems: "flex-start",
                }}
              >
                <button
                  style={{ marginTop: "1vmin", marginBottom: "1vmin" }}
                  onClick={() => history.push(`/ejercicios/card/${id}`)}
                  className="buttonIndividualTypesverahora"
                >
                  EMPIEZA YA
                </button>
              </div>
            </div>
          </div>

          <br />
          <div
            style={{
              backgroundColor: "#FDF1E8",
              paddingLeft: "5%",
              paddingTop: "3%",
            }}
          >
            <Videos_two
              contenido={id}
              textTitleOne="Clases "
              textTitleTwo="del programa"
              videosTime={videosTime}
              id="pin"
            />
            <br />
            <br />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default VerinfoIndividual;
