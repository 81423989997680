import React from "react";

import stars from "./iconos/stars.png";
import { instance, laptop575 } from "../utils/utils";
import { useEffect, useRef, useState } from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import interrogante from "./iconos/interrogante.png";
import { faLaptop } from "@fortawesome/free-solid-svg-icons";
const sw = window.screen.width;
export function Nivelesydiscliplinas(props) {
  const [alllevels, setalllevels] = useState(null);
  useEffect(() => {
    const levels = [];
    const getData = async () => {
      await instance.get(`customers/level/`).then(async (res) => {
        const daysselected = res.data;

        if (daysselected) {
          for (const i of daysselected) {
            const identrenamiento = i.training;
            const lev = i.level;
            const uuid = i.id;
            const sublev = i.sublevel;
            const current_sesio = i.current_session;
            await instance.get(`trainings/${identrenamiento}/`).then((res) => {
              let info = res.data;
              if (info && info.group == false) {
                levels[identrenamiento] = {
                  id: identrenamiento,
                  uuid: uuid,
                  tiene_valoration: info.is_valoration,
                  titulo: info.name.toLowerCase(),
                  descripcion: info.description,
                  level: lev,
                  subnivel: sublev,
                  current_session: current_sesio - 1,
                };
              }
            });
          }

          //
        }
      });
      console.log(levels.filter((e) => e && e.tiene_valoration == false));
      setalllevels(levels.filter((e) => e && e.tiene_valoration == false));
    };
    getData();
  }, []);
  // const [entrenamientos, setEntrenamientos] = useState(null);
  // setEntrenamientos(entrenamientos);

  const totalallevel = alllevels ? alllevels.length : 0;
  const unidadtotal = alllevels
    ? alllevels.map((e) => e.current_session).reduce((a, b) => a + b, 0)
    : 0;
  const ver_level = unidadtotal > 0 ? unidadtotal / totalallevel : 0;
  const now =
    totalallevel == 0
      ? 0
      : Math.round(((unidadtotal / totalallevel) * 100) / 120);

  return (
    <div>
      {" "}
      <div className="nivel text-center">
        {" "}
        Nivel de dificultad general: <br />
        {Math.round(ver_level) >= 84
          ? "Avanzado"
          : Math.round(ver_level) <= 52
          ? "Principiante"
          : "Intermedio"}
      </div>
      <div
        className="progressvideogame px-auto py-3  "
        style={{ position: "relative" }}
      >
        {Math.round(ver_level) > 0 ? (
          <img
            src={stars}
            style={
              laptop575.matches
                ? {
                    position: "absolute",
                    zIndex: "3",
                    left: "1vw",
                    width: "3.5vw",
                    display: "none",
                    marginTop: "-2vh",
                  }
                : {
                    position: "absolute",
                    zIndex: "3",
                    left: "1vw",
                    width: "11vw",
                    marginTop: "-1vh",
                  }
            }
          />
        ) : null}
        {Math.round(ver_level) > 52 ? (
          <img
            src={stars}
            style={
              laptop575.matches
                ? {
                    position: "absolute",
                    zIndex: "2",
                    left: "2.5vw",
                    display: "none",
                    width: "3.5vw",
                    marginTop: "-2vh",
                  }
                : {
                    position: "absolute",
                    zIndex: "2",
                    left: "5vw",
                    width: "11vw",
                    marginTop: "-1vh",
                  }
            }
          />
        ) : null}
        {Math.round(ver_level) >= 84 ? (
          <img
            src={stars}
            style={
              laptop575.matches
                ? {
                    position: "absolute",
                    zIndex: "1",
                    left: "2.5vw",
                    display: "none",
                    width: "3.5vw",
                    marginTop: "-2vh",
                  }
                : {
                    position: "absolute",
                    zIndex: "1",
                    left: "9vw",
                    width: "11vw",
                    marginTop: "-1vh",
                  }
            }
          />
        ) : null}
        <div style={{ position: "relative" }}>
          <ProgressBar
            now={now}
            style={{
              border: "2px solid #C1CCFC",
              height: "3vh",
              borderRadius: "1rem",
              backgroundColor: "#C1CCFC",
            }}
          />
          <label
            style={
              laptop575.matches
                ? {
                    position: "absolute",
                    top: "50%",
                    color: "white",
                    left: "50%",
                    fontWeight: "bold",
                    transform: "translate(-50%, -50%)",
                  }
                : {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    color: "white",
                    fontWeight: "bold",
                    transform: "translate(-50%, -50%)",
                  }
            }
          >
            {" "}
            {`${now}%`}
          </label>
        </div>
        <br />
        <div
          className={
            laptop575.matches
              ? "msginfo_genre  text-center"
              : "msg px-3 text-center"
          }
        >
          Este es el nivel de dificultad promedio según el nivel que tienes en
          cada disciplina que has practicado.
        </div>
      </div>
      <div className=" borderlineiconospruebas mt-4 mx-auto "></div>
      <div className="nivel"> Tu progreso por disciplinas</div>
      <div
        style={
          laptop575.matches
            ? { height: "53vh", overflowY: "scroll", width: "52vw" }
            : null
        }
      >
        {alllevels &&
          alllevels.map((clase, idx) => (
            <div key={idx}>
              <div
                className={
                  laptop575.matches ? "text-left col-10 " : "text-center "
                }
                style={
                  laptop575.matches
                    ? {
                        color: "#CB3D82",
                        textTransform: "capitalize",
                        margin: "2vh auto ",
                        fontWeight: "700",
                        fontSize: "1vw",
                      }
                    : {
                        color: "#CB3D82",
                        textTransform: "capitalize",
                        margin: "2vh auto ",
                        fontWeight: "700",
                      }
                }
              >
                {clase.titulo}
              </div>
              <div className="row col-12 " style={{ justifyContent: "center" }}>
                <div
                  className={
                    laptop575.matches
                      ? "row col-9 progressvideogame1 "
                      : "row col-10 progressvideogame1 "
                  }
                >
                  {" "}
                  <div className="col-4 px-1 ">
                    <span
                      style={
                        laptop575.matches
                          ? {
                              color: " #394F65",
                              fontWeight: "700",
                              fontSize: "1vw",
                            }
                          : {
                              color: " #394F65",
                              fontWeight: "700",
                              fontSize: "3vw",
                            }
                      }
                    >
                      Principiante{" "}
                    </span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <ProgressBar
                        now={
                          clase.current_session < 52
                            ? Math.round((clase.current_session / 52) * 100)
                            : 100 + "%"
                        }
                        style={
                          laptop575.matches
                            ? {
                                marginTop: "1vh",
                                width: "100%",
                                border: "2px solid #C1CCFC",
                                height: "1.5rem",
                                borderRadius: "1rem",
                                backgroundColor: "#C1CCFC",
                              }
                            : {
                                marginTop: "1vh",
                                width: "22vw",
                                border: "2px solid #C1CCFC",
                                height: "1.5rem",
                                borderRadius: "1rem",
                                backgroundColor: "#C1CCFC",
                              }
                        }
                      />
                      <label
                        style={
                          laptop575.matches
                            ? {
                                position: "absolute",
                                top: "50%",
                                color: "white",
                                left: "50%",
                                fontWeight: "bold",
                                transform: "translate(-50%, -50%)",
                              }
                            : {
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                color: "white",
                                fontWeight: "bold",
                                transform: "translate(-50%, -50%)",
                              }
                        }
                      >
                        {" "}
                        {clase.current_session < 52
                          ? Math.round((clase.current_session / 52) * 100) + "%"
                          : 100 + "%"}
                      </label>
                    </div>
                  </div>
                  <div className="col-4 px-1">
                    <span
                      style={
                        laptop575.matches
                          ? {
                              color: " #394F65",
                              fontWeight: "700",
                              fontSize: "1vw",
                            }
                          : {
                              color: " #394F65",
                              fontWeight: "700",
                              fontSize: "3vw",
                            }
                      }
                    >
                      {" "}
                      Intermedio{" "}
                    </span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <ProgressBar
                        now={
                          clase.current_session > 52
                            ? Math.round(
                                ((clase.current_session > 84
                                  ? 32
                                  : clase.current_session - 52) /
                                  32) *
                                  100
                              )
                            : 0
                        }
                        style={
                          laptop575.matches
                            ? {
                                marginTop: "1vh",
                                width: "100%",
                                border: "2px solid #C1CCFC",
                                height: "1.5rem",
                                borderRadius: "1rem",
                                backgroundColor: "#C1CCFC",
                              }
                            : {
                                marginTop: "1vh",
                                width: "22vw",
                                border: "2px solid #C1CCFC",
                                height: "1.5rem",
                                borderRadius: "1rem",
                                backgroundColor: "#C1CCFC",
                              }
                        }
                      />
                      <label
                        style={
                          laptop575.matches
                            ? {
                                position: "absolute",
                                top: "50%",
                                color: "white",
                                left: "50%",
                                fontWeight: "bold",
                                transform: "translate(-50%, -50%)",
                              }
                            : {
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                color: "white",
                                fontWeight: "bold",
                                transform: "translate(-50%, -50%)",
                              }
                        }
                      >
                        {" "}
                        {clase.current_session > 52
                          ? `${Math.round(
                              ((clase.current_session > 84
                                ? 32
                                : clase.current_session - 52) /
                                32) *
                                100
                            )}%`
                          : null}
                      </label>
                    </div>
                  </div>
                  <div className="col-4 px-1">
                    <span
                      style={
                        laptop575.matches
                          ? {
                              color: " #394F65",
                              fontWeight: "700",
                              fontSize: "1vw",
                            }
                          : {
                              color: " #394F65",
                              fontWeight: "700",
                              fontSize: "3vw",
                            }
                      }
                    >
                      {" "}
                      Avanzado{" "}
                    </span>
                    <div style={{ position: "relative", width: "100%" }}>
                      <ProgressBar
                        now={
                          clase.current_session > 84
                            ? Math.round(
                                ((clase.current_session - 84) / 36) * 100
                              )
                            : 0
                        }
                        style={
                          laptop575.matches
                            ? {
                                marginTop: "1vh",
                                width: "100%",
                                border: "2px solid #C1CCFC",
                                height: "1.5rem",
                                borderRadius: "1rem",
                                backgroundColor: "#C1CCFC",
                              }
                            : {
                                marginTop: "1vh",
                                width: "22vw",
                                border: "2px solid #C1CCFC",
                                height: "1.5rem",
                                borderRadius: "1rem",
                                backgroundColor: "#C1CCFC",
                              }
                        }
                      />
                      <label
                        style={
                          laptop575.matches
                            ? {
                                position: "absolute",
                                top: "50%",
                                color: "white",
                                left: "50%",
                                fontWeight: "bold",
                                transform: "translate(-50%, -50%)",
                              }
                            : {
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                color: "white",
                                fontWeight: "bold",
                                transform: "translate(-50%, -50%)",
                              }
                        }
                      >
                        {" "}
                        {clase.current_session > 84
                          ? `${Math.round(
                              ((clase.current_session - 84) / 36) * 100
                            )}%`
                          : null}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <img
                    onClick={() => props.handleClick(clase.uuid)}
                    src={interrogante}
                    style={
                      laptop575.matches
                        ? {
                            width: "2vw",
                            marginTop: "23px",
                            cursor: "pointer",
                          }
                        : {
                            width: "7vw",
                            marginTop: "23px",
                            cursor: "pointer",
                          }
                    }
                  />
                </div>
              </div>
              <div className=" borderlineiconospruebas mt-4 mx-auto "></div>
            </div>
          ))}
      </div>
    </div>
  );
}
