import { UPDATE_BOOK_TRAINER } from "../types";
import { promise_one, promise_two } from "./promises";
function actualBook(finislongdays, inicioday) {
  const promise_ones = promise_one(finislongdays, inicioday);
  const promise_twos = promise_two(finislongdays, inicioday);
  return async (dispatch) => {
    Promise.all([promise_ones, promise_twos])
      .then(async (response) => {
        let [promise1, promise2] = response;
        return promise1.concat(promise2);
      })
      .then((resData) => {
        dispatch({
          type: UPDATE_BOOK_TRAINER,
          payload: resData.filter((e) => e),
        });
      });
  };
}
export { actualBook };
