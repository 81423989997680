import grupales_true from "../../assets/img/UserIcons/iconos/Grupo.png";
import individual from "../../assets/img/UserIcons/iconos/individual.png";

import Moment from "moment";
import { entrenadorType, laptop575 } from "../../utils/utils";
import { extendMoment } from "moment-range";

import React from "react";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";

import { useLocation } from "react-router-dom";

const ReservasTrainers = (props) => {
  const moment = extendMoment(Moment);
  const path = useLocation();
  const data =
    props.datagroup.length > 0
      ? props.datagroup
          .sort(function (a, b) {
            const fechaA = b.date;
            const fechaB = a.date;
            if (fechaA > fechaB) {
              return -1;
            }
            if (fechaA < fechaB) {
              return 1;
            }
            // names must be equal
            return 0;
          })
          .slice(0, 6)
      : props.datagroup;

  return (
    <>
      {data && data.length > 0 ? (
        data.map((el) => (
          <SwiperSlide key={el.date} className="col-12  col-lg-4 sliderReserva">
            <Link
              push
              to={
                el.is_group
                  ? `/${entrenadorType}/reservasGrupales/ver/` + el.id
                  : `/${entrenadorType}/reservas/ver/` + el.id
              }
              className={laptop575.matches ? "" : "m-3"}
            >
              <div
                className="itemReserva"
                style={{ backgroundImage: `url(${el.training.main_picture})` }}
              >
                {laptop575.matches ? (
                  <div
                    className={
                      path.pathname.includes("inicio") ? "bginicio" : "bg"
                    }
                  ></div>
                ) : (
                  <div className="bg"></div>
                )}

                <span
                  className={
                    laptop575.matches && path.pathname.includes("inicio")
                      ? "text1 textmodi"
                      : "text1"
                  }
                >
                  <div className="d-flex">
                    <div>Entrenamiento</div>
                    {laptop575.matches ? <div className="linere"></div> : null}
                  </div>

                  <span
                    className={
                      laptop575.matches
                        ? "text2 container_name1"
                        : "text2 container_name1"
                    }
                  >
                    {el.training.name}
                  </span>
                </span>
                {laptop575.matches ? (
                  <div
                    className={
                      path.pathname.includes("inicio") ? "bot botija" : "bot"
                    }
                  >
                    <div
                      className={
                        laptop575.matches && path.pathname.includes("inicio")
                          ? "typein"
                          : "type"
                      }
                    >
                      <div>
                        <img
                          src={el.is_group ? grupales_true : individual}
                          alt="individual"
                        />
                        <br />
                        {el.is_group ? "Grupal" : "Individual"}
                      </div>
                    </div>
                    <div
                      className="date"
                      style={{ width: "66%", marginLeft: "19%" }}
                    >
                      <div className="content">
                        <div className="lineunitreser"></div>
                        <span style={{ fontFamily: "Poppins-regular" }}>
                          {moment(el.date.slice(0, 10), "YYYY-MM-DD").format(
                            "dddd"
                          )}
                        </span>
                        <br />
                        <span className="horasgrandes">
                          {moment(el.date.slice(0, 10), "YYYY-MM-DD").format(
                            "DD"
                          )}
                        </span>
                        <br />
                        <span>
                          {moment(el.date.slice(0, 10), "YYYY-MM-DD").format(
                            "[De ]MMMM"
                          )}
                        </span>
                        <br />
                        <div className="lineunitreser"></div>
                        <span>Horario</span>
                        <br />
                        <span className="horasgrandes">
                          {moment(el.date.slice(11, 16), "LT a").format(
                            "hh:mm"
                          )}
                        </span>
                        <br />
                        <span>
                          {moment(el.date.slice(11, 16), "LT a").format("a")}
                        </span>
                      </div>
                      <div className="ver">
                        Ver <i className="fa fa-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bot">
                    <div className="type">
                      <div style={{ padding: "0 2.7vw" }}>
                        <img
                          src={el.is_group ? grupales_true : individual}
                          alt="individual"
                          style={{ padding: "0 2.7vw" }}
                        />
                        <br />
                        {el.is_group ? "Grupal" : "Individual"}
                      </div>
                    </div>

                    <div className="date">
                      <div className="content">
                        <span className="letricaregu">
                          {moment(el.date.slice(0, 10), "YYYY-MM-DD").format(
                            "dddd"
                          )}
                        </span>
                        <br />
                        <b style={{ textTransform: "lowercase" }}>
                          {moment(el.date.slice(0, 10), "YYYY-MM-DD").format(
                            "  DD [de] MMMM "
                          )}
                        </b>
                        <b style={{ textTransform: "lowercase" }}>
                          {moment(el.date.slice(11, 16), "LT a").format(
                            "hh:mm a"
                          )}
                        </b>
                      </div>
                    </div>
                    <br />
                    <div className=" cositaver ver ">Ver</div>
                  </div>
                )}
              </div>
            </Link>
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide key={1}>
          <div className={laptop575.matches ? "NoClase1" : "NoClase"}>
            <u> No tienes ninguna clase reservada. </u>
          </div>
        </SwiperSlide>
      )}
    </>
  );
};

export default ReservasTrainers;
