import { entrenadorType, userType } from "../utils/utils";
import LoginForm from "./LoginForm";
import Registro from "./Registro";

const LoginUser = () => {
  return <LoginForm type={userType} />;
};

export const RegisterUser = () => {
  return <Registro type={userType} />;
};

export const LoginEntrenador = () => {
  return <LoginForm type={entrenadorType} />;
};

export const RegisterEntrenador = () => {
  return <Registro type={entrenadorType} />;
};

export default LoginUser;
