import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import {
  basename,
  checkIfLogged,
  Loadingspinner,
  ModalInfo,
  SetModalError,
  NuevaAxiosURL,
  Silueta,
  ButtonMob,
  userObj,
  userType,
  ModalInfoEmpiezaya,
  laptop,
  laptop575,
} from "../utils/utils";
import "./Completar.css";
import ReactGA from "react-ga";
import imgniña from "./niñafoto.png";
import imgarriba1 from "../assets/img/Auth_images/arribaimagen.png";
import imgabajo1 from "../assets/img/Auth_images/abajoimagen.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/pagination/pagination.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import React from "react";

import Media from "react-media";
import axios from "axios";

import "react-phone-number-input/style.css";

import { useEffect, useRef, useState } from "react";
import Switch from "react-switch";
SwiperCore.use([Pagination]);
const CompletarTriage = (props) => {
  const data_user = props.user;
  const id = props.id;
  const [contactoName, setContactoName] = useState(null);
  const salvedadMedico = useRef(null);

  const [canNext, setCanNext] = useState(false);
  const [currentSlider, setCurrentSlider] = useState(1);
  const hideModal = () => {
    setModal(null);
  };
  const Activarcampaña = (e) => {
    // console.log("rancherias");
    ReactGA.pageview("conversion_grupales/");
  };
  useEffect(() => {
    ReactGA.initialize("UA-238615776-1");
  }, []);
  const [theSwipper, setTheSwipper] = useState(null);
  const [Modal, setModal] = useState(null);
  const [preguntasMedicas, setPreguntasMedicas] = useState(false);
  const [preguntaEnfermedad, setPreguntaEnfermedad] = useState(false);
  const [confirmado, setModalConfirm] = useState(null);
  const [contactoTel, setContactoTel] = useState(null);
  const [checkPreg1, setCheckPreg1] = useState(false);
  const [checkPreg2, setCheckPreg2] = useState(false);
  const [checkPreg3, setCheckPreg3] = useState(false);
  const [checkPreg4, setCheckPreg4] = useState(false);
  const [checkPreg5, setCheckPreg5] = useState(false);
  const [checkPreg6, setCheckPreg6] = useState(false);
  const [checkPreg7, setCheckPreg7] = useState(false);
  const [textodolor, settextodolor] = useState("");

  useEffect(() => {
    if (checkCurrentForm(false)) {
      setCanNext(true);
    }
    return () => {};
  }, [contactoName, contactoTel]);
  const preguntas_salud = [
    [
      "¿Algún médico le ha dicho que tiene problemas del corazón y que sólo debe hacer actividades físicas recomendadas por un médico?",
      "preg_salud1",
      checkPreg1,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Tiene dolor en el pecho cuando hace alguna actividad física?",
      "preg_salud2",
      checkPreg2,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿En el último mes, ¿ha tenido dolor en el pecho cuando no estaba haciendo actividades físicas?",
      "preg_salud3",
      checkPreg3,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Pierde el equilibrio por mareos, o ha perdido alguna vez el conocimiento?",
      "preg_salud4",
      checkPreg4,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Tiene problema en algún hueso o articulación que pueda ser agravado por un cambio en su actividad física?",
      "preg_salud5",
      checkPreg5,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Está tomando medicamentos recetados por el médico para la presión arterial o para el corazón (por ejemplo, pastillas diuréticas)?",
      "preg_salud6",
      checkPreg6,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Sabe de cualquier otra razón en contra de que ejercite?",
      "preg_salud7",
      checkPreg7,
      "col-2 ps-3 align-self-center",
    ],
    // [
    //   "¿Sufres de alguna enfermedad?",
    //   "preg_salud8",
    //   checkPreg8,
    //   "col-2 ps-3 align-self-center",
    // ],
  ];
  const handleCheckedPreg = (index) => {
    switch (index) {
      case 0:
        setCheckPreg1(checkPreg1 ? false : true);
        break;
      case 1:
        setCheckPreg2(checkPreg2 ? false : true);
        break;
      case 2:
        setCheckPreg3(checkPreg3 ? false : true);
        break;
      case 3:
        setCheckPreg4(checkPreg4 ? false : true);
        break;
      case 4:
        setCheckPreg5(checkPreg5 ? false : true);
        break;
      case 5:
        setCheckPreg6(checkPreg6 ? false : true);
        break;
      case 6:
        setCheckPreg7(checkPreg7 ? false : true);
        break;
      // case 7:
      //   setCheckPreg8(checkPreg8 ? false : true);
      //   break;
      default:
        break;
    }
  };
  const renderSwitchh = (
    index,
    label,
    name,
    isCheck,
    textclasses,
    switchClasses
  ) => {
    return (
      <>
        <div className={textclasses}>{label}</div>
        <Switch
          name={name}
          onChange={() => handleCheckedPreg(index)}
          checked={isCheck}
          className={switchClasses}
          handleDiameter={20}
          height={25}
          width={65}
          offColor="#E0E7FF"
          onColor="#9EACED"
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "white",
                paddingRight: 2,
                fontWeight: "700",
              }}
            >
              NO
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "white",
                paddingRight: 2,
                fontWeight: "700",
              }}
            >
              SI
            </div>
          }
        />
      </>
    );
  };
  const labelInput = (
    name,
    labelClasses,
    label,
    inputClasses,
    placeholder,
    type,
    functionChange
  ) => {
    return (
      <>
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
        <input
          type={type}
          name={name}
          className={inputClasses}
          placeholder={placeholder}
          onInput={functionChange}
        />
      </>
    );
  };
  function checkCurrentForm(show_warn = true) {
    const checkSlider = currentSlider;
    let lleno = false;

    function showModalWarn(faltaLLenar) {
      if (show_warn) {
        setModal(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Falta algo por llenar"
            text={
              "Faltan algunos datos por llenar: \n\n" + faltaLLenar.join("\n")
            }
            classesMsg={"FaltanItems"}
          />
        );
      }
    }
    const faltaLLenar = [];
    switch (checkSlider) {
      case 6:
        if (!data_user.customer.emergency_contact) {
          if (!contactoName)
            faltaLLenar.push("▶ Nombre del contacto de emergencia");
          if (!contactoTel)
            faltaLLenar.push("▶ Telefono del contacto de emergencia");

          if (faltaLLenar.length !== 0) {
            if (show_warn) {
              showModalWarn(faltaLLenar);
            }
            return false;
          }
        }
      default:
        return true;
    }
  }
  useEffect(() => {
    if (
      checkPreg1 == true ||
      checkPreg2 == true ||
      checkPreg3 == true ||
      checkPreg4 == true ||
      checkPreg5 == true ||
      checkPreg6 == true ||
      checkPreg7 == true
    ) {
      setPreguntasMedicas(true);
    }
    if (
      checkPreg1 == false &&
      checkPreg2 == false &&
      checkPreg3 == false &&
      checkPreg4 == false &&
      checkPreg5 == false &&
      checkPreg6 == false &&
      checkPreg7 == false
    ) {
      setPreguntasMedicas(false);
    }
    if (checkPreg7 == true) {
      setPreguntaEnfermedad(true);
    } else {
      setPreguntaEnfermedad(false);
    }
  }, [
    checkPreg1,
    checkPreg2,
    checkPreg3,
    checkPreg4,
    checkPreg5,
    checkPreg6,
    checkPreg7,
    // checkPreg8,
  ]);
  const Paso7 = (
    <SwiperSlide className="slider_form mx-auto ">
      {laptop575.matches ? null : (
        <div className="subtitle">Preguntas de salud</div>
      )}
      <div
        className={
          laptop575.matches
            ? "formSteps step6acomodaronly  pt-3 "
            : "formSteps step6acomodar mx-auto pt-3 "
        }
      >
        <div className="d-row">
          <div
            className={
              laptop575.matches
                ? "d-inline-flex pt-5 col-12 mx-2  "
                : "d-row-salud"
            }
          >
            <div className={laptop575.matches ? "col-3  mx-2 " : null}>
              {labelInput(
                "name_contacto",
                "d-flex",
                "Contacto de emergencia",
                "form-control",
                "Ej: Ana Maria",
                "text",
                (event) => setContactoName(event.target.value)
              )}
            </div>

            <div className={laptop575.matches ? "col-3 mx-2 " : null}>
              {labelInput(
                "tel_contacto",
                "d-flex",
                "Teléfono",
                "form-control",
                "Ej. 3006835437",
                "number",
                (event) => setContactoTel(event.target.value)
              )}
            </div>
          </div>
          <div className="pt-3 preguntas row step6onlytriage Paso6slider">
            {preguntas_salud.map((value, index, this_array) => {
              return (
                <div key={index} className="d-inline-flex py-3 col-12  ">
                  {renderSwitchh(
                    index,
                    value[0],
                    value[1],
                    value[2],
                    "col-9 text-left lh-1 d-flex align-items-center tamañoletrassalud",
                    value[3]
                  )}
                </div>
              );
            })}
            <div style={{ height: "100px", margin: "auto" }}>
              {preguntaEnfermedad ? (
                <textarea
                  placeholder="Cuentanos acerca de tu enfermedad"
                  rows="5"
                  onInput={(e) => settextodolor(e.target.value)}
                ></textarea>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );
  const instance = axios.create({
    baseURL: `${NuevaAxiosURL}`,

    headers: { Authorization: "Bearer " + localStorage.my_code },
  });
  const completeProfile = () => {
    // console.log(doloresPosteriores, doloresFrontales);
    const faltaLLenar = [];
    if (!contactoName) faltaLLenar.push("▶ Contacto de emergencia");
    if (!contactoTel) faltaLLenar.push("▶ Teléfono de contacto de emergencia");

    sendData();
  };

  const sendData = () => {
    setModal(
      <ModalInfo
        error="none"
        handleClick={hideModal}
        title="Procesando"
        text={<Loadingspinner customStyle="grow" size="5rem" />}
        classesMsg=""
      />
    );
    sendDataFinal();
  };
  const sendDataFinal = () => {
    var formData = new FormData();
    formData.append("health_safe_heart", checkPreg1);
    formData.append("health_safe_chest", checkPreg2);
    formData.append("health_safe_chest_last", checkPreg3);
    formData.append("health_safe_balance", checkPreg4);
    formData.append("health_safe_bones", checkPreg5);
    formData.append("health_safe_medicated", checkPreg6);
    formData.append("health_safe_other", checkPreg7);
    formData.append("terms", true);
    if (checkPreg7) {
      formData.append("health_safe_other_text", textodolor);
    }
    formData.append("emergency_contact_phone", contactoTel);
    formData.append("emergency_contact", contactoName);

    instance
      .patch(`${NuevaAxiosURL}customers/`, formData)
      .then((res) => {
        if (preguntasMedicas == true) {
          // setModal(<Redirect   to={`/${userType}/reservas`} />);
          window.location.href = `${basename}/${userType}/reservas`;
        } else {
          Activarcampaña();
          setModal(<Redirect to={`/${userType}/reservas/${id}`} />);

          // window.location.href = `${basename}/${userType}/reservas/${id}`;
        }
      })
      .catch((err) => {
        setModal(
          <SetModalError
            handleClick={() => setModal(null)}
            title="Algo salio mal"
          />
        );
      });
  };

  if (preguntasMedicas == true) {
    salvedadMedico.current.style.display = "flex";
  }
  const renderFootBar = (
    <>
      <nav className="footerStep6 ubicarfooter" onClick={completeProfile}>
        <div className="lh-1 siguiente">
          Empieza a reservar{" "}
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="2x"
            color="#2A9DA5"
            className="ms-2"
          />
        </div>
      </nav>
    </>
  );

  return (
    <>
      <ModalInfo
        handleClick={() => {
          salvedadMedico.current.style.display = "none";
        }}
        error="false"
        handleCancellcargue={() => {
          salvedadMedico.current.style.display = "none";
        }}
        title="Antes de empezar a hacer actividad física"
        ref={salvedadMedico}
        display="none"
        text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
      />

      <div className="CompletarPerfil text-center">
        <div className="flexusuarioregistro">
          <div className="SubTitle8perfil">Perfil Usuario</div>
          <div className="lineacompletarususario"></div>
          <img className="imgarriba1" src={imgarriba1} />
          <img className="imgabajo1" src={imgabajo1} />
        </div>
        <div className="titleregistro">
          {laptop575.matches ? "Preguntas de salud" : "Completa tu perfil"}
        </div>
        <div className="titleregistrodebajo">
          Tenemos muchas opciones para ayudarte a alcanzar el objetivo que
          siempre has deseado, completa la información del formulario y crea tu
          perfil.
        </div>

        <img className="niñafoto" src={imgniña} />

        <div className="content d-flex justify-content-center align-items-center contenidototal">
          <Media query=" (max-width: 3000px)">
            {(matches) =>
              matches ? (
                <>{Paso7}</>
              ) : (
                <div className="row-g-0">
                  {Paso7}

                  {renderFootBar}
                </div>
              )
            }
          </Media>
        </div>
      </div>
      {Modal}
      {confirmado}
      {/* {footBar ? renderFootBar : null} */}

      <CompletarFooterTriage
        contactoName={contactoName}
        contactoTel={contactoTel}
        completeProfile={completeProfile}
      />
    </>
  );
};

const CompletarFooterTriage = (props) => {
  const [desaparecer, Setdesaparecer] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const salvedadMedico = useRef(null);
  const contactoName = props.contactoName;
  const contactoTel = props.contactoTel;

  function completeProfile() {
    props.completeProfile();
  }
  const hideModal = () => {
    setModalInfo(null);
  };

  function mostraryatusabes() {
    const faltaLLenar = [];
    if (!contactoName) faltaLLenar.push("▶ Contacto de emergencia");
    if (!contactoTel) faltaLLenar.push("▶ Teléfono");
    if (contactoName && contactoTel) {
      Setdesaparecer(true);
      setModalInfo(
        <ModalInfoEmpiezaya
          handleClick={completeProfile}
          title="¡Ahora eres Elevva +!"
          text={
            "Tu cuenta a sido creada exitosamente. Al dar click vivirás una nueva experiencia."
          }
          classesMsg={"FaltanItemsmobster"}
        />
      );
    } else {
      setModalInfo(
        <ModalInfo
          error="false"
          handleClick={hideModal}
          handleCancellcargue={hideModal}
          title="Falta algo por llenar"
          text={
            "Faltan algunos datos por llenar: \n\n" + faltaLLenar.join("\n")
          }
          classesMsg={"FaltanItems"}
        />
      );
    }
  }
  const classNext = `siguiente ${contactoName && contactoTel ? "canNext" : ""}`;

  const renderFootBar11 = (
    <>
      <nav className="footerStep6 ubicarfooter " onClick={completeProfile}>
        <div className="lh-1 siguiente1">
          Empieza a reservar
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="2x"
            color="#2A9DA5"
            className="ms-2 ubicarfont"
          />
        </div>
      </nav>
    </>
  );
  return (
    <>
      <ModalInfo
        handleClick={() => {
          salvedadMedico.current.style.display = "none";
        }}
        handleCancellcargue={() => {
          salvedadMedico.current.style.display = "none";
        }}
        error="false"
        title="Antes de empezar a hacer actividad física"
        ref={salvedadMedico}
        display="none"
        text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
      />{" "}
      <nav
        className={desaparecer == true ? "d-none" : "CompletarFooter navFooter"}
        style={
          laptop575.matches && desaparecer == false ? { left: "84vw" } : null
        }
      >
        <div className={classNext} onClick={mostraryatusabes}>
          Finalizar
        </div>
      </nav>
      {modalInfo}
    </>
  );
};

export default CompletarTriage;
