import "./Multirange.css";
import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

export const MultiRangeSlider = ({ mini, maxi, onChange }) => {
  const [minVal, setMinVal] = useState(mini);
  const [maxVal, setMaxVal] = useState(maxi);
  const minValRef = useRef(mini);
  const maxValRef = useRef(maxi);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - mini) / (maxi - mini)) * 100),
    [mini, maxi]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ mini: minVal, maxi: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <div className=" container">
      <input
        type="range"
        min={mini}
        max={maxi}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > maxi - 100 && "5" }}
      />
      <input
        type="range"
        min={mini}
        max={maxi}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{`${minVal} min`} </div>
        <div className="slider__right-value">{`${maxVal} min`}</div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  mini: PropTypes.number.isRequired,
  maxi: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
