import { ModalInfo, userType, instance } from "../utils/utils";
import aunno from "../assets/img/UserIcons/iconos/aunno.png";
import confirmado from "../assets/img/UserIcons/iconos/confirmad (1).png";
import minicalendar from "../assets/img/UserIcons/iconos/minicalendar.png";
import cursoya from "../assets/img/UserIcons/iconos/encurso.png";
import aerobico from "../assets/img/reservasimages/aerobico.png";
import boton from "../assets/img/reservasimages/boton.png";
import boton1 from "../assets/img/reservasimages/boton1.png";
import "./MisClases.css";
import intensidad_img from "../assets/img/reservasimages/icon_extra_1a.png";
import Moment from "moment";
import axios from "axios";
import "swiper/swiper.min.css";

import "swiper/components/pagination/pagination.min.css";

import { Link } from "react-router-dom";
import React from "react";

import "react-phone-number-input/style.css";

import { extendMoment } from "moment-range";
import { useEffect, useState } from "react";
import reloj from "../assets/img/reservasimages/reloj.png";

import corazon from "../assets/img/reservasimages/corazon_reserva.png";

const MisClases = (props) => {
  const moment = extendMoment(Moment);
  require("moment-timezone");
  const [renderReservas, setRenderReservas] = useState(null);
  // console.log(renderReservas.filter((e) => e.key.includes("true")));
  const [modalInfo, setModalInfo] = useState(null);
  const [individuales, setIndividuales] = useState(true);
  const [copyAllEntrenamientos, setCopyAllEntrenamientos] = useState(null);
  function hideModal() {
    setModalInfo(null);
  }
  const zonahorairachange = props.zonahorairachange;

  useEffect(() => {
    const inicioday = moment().format().slice(0, 11) + "00:00";

    const finislongdays =
      moment().add(30, "days").format().slice(0, 11) + "00:00";

    const copyEntrenamientos = [];
    var formData = new FormData();
    formData.append("data", localStorage.my_code);
    instance
      .get(
        `booking/?available&date__lte=${finislongdays}&date__gte=${inicioday}`
      )
      .then((resEn) => {
        if (resEn.data.length > 0) {
          const info = resEn.data;

          if (info != null && info.length > 0) {
            for (const i of info) {
              const identrenamiento = i.training.id;

              const tz = i.tz;
              const date = moment.tz(i.date, tz).format();
              const id = i.id;
              const trainer = i.trainer;
              const is_group = i.is_group;
              const status = i.status;
              instance
                .get(`trainings/${identrenamiento}/`)
                .then(async (resEn) => {
                  const data1 = resEn.data;

                  if (data1 != null) {
                    copyEntrenamientos[id] = {
                      id: id,
                      date: date,
                      titulo: unescape(data1.name).toLowerCase(),
                      descripcion: data1.description,
                      img: data1.cover_picture,
                      status_reserva: status,
                      rawImg: data1.main_picture,
                      icon: data1.icon,
                      is_group: is_group,
                      intensidadMsg:
                        data1.intensity == 10
                          ? " Baja Intensidad"
                          : data1.intensity == 20
                          ? " Media Intensidad"
                          : " Alta Intensidad", //.match(/<p>([\s\w]*)<\/p>/)[1],
                      intensidadImg: intensidad_img,
                      duracion: data1.time, //.match(/([\d]*)<b class/)[1],
                      calorias: data1.calories,
                      entrenador: data1.trainers.filter((e) => e.id == trainer),
                      // categoria,
                    };
                  }

                  setRenderReservas(
                    copyEntrenamientos
                      .filter((e) => e)
                      .map((el, index) => {
                        const bg_img = el.img;
                        const intensidadImg = el.intensidadImg;
                        const entrenador = el.entrenador;
                        const is_group = el.is_group;

                        const name = el.titulo;
                        const icon = el.icon;
                        const status_reserva = el.status_reserva;
                        const fecha = moment
                          .tz(el.date, zonahorairachange)
                          .format("DD [de] MMMM");
                        const intensidadMsg = el.intensidadMsg;
                        const duracion = el.duracion;
                        const calorias = el.calorias;
                        const hora = moment
                          .tz(el.date, zonahorairachange)
                          .format("LT a");

                        // console.log(hora.format("LTS"));

                        const url = `/${userType}/reservas/ver/` + el.id;

                        return (
                          <div
                            key={el.id + "/" + `${is_group}`}
                            className={index == 0 ? "" : "my-1"}
                          >
                            <Link key={index} to={url} className="col-12">
                              <div
                                className="Entrenamientomisclases reserva reserva1 "
                                style={{
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  src={bg_img}
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    // backgroundSize: "cover",
                                    // transform: "rotate(180deg) scale(1,-1)",
                                    height: "auto",
                                  }}
                                ></img>
                                <div className="bg_cover reserva"> </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    left: "1vw",
                                    top: "33%",

                                    background: "#9EACED",
                                    borderRadius: "50px",
                                  }}
                                >
                                  <img
                                    src={icon}
                                    alt="intensidad"
                                    style={{
                                      width: "4vw",
                                      heigth: "4vw",
                                    }}
                                  />
                                </div>
                                <div className="Cabecera reservamisclases">
                                  Entrenamiento
                                  {/* <span className="lineop"></span> */}
                                  <br />
                                  <span className="container_name">
                                    {name.toLowerCase()}
                                  </span>
                                </div>
                                <div
                                  className=""
                                  style={{
                                    position: "absolute",
                                    top: "0%",
                                    left: "0%",
                                  }}
                                >
                                  <div
                                    className=""
                                    style={{
                                      fontSize: "0.8rem",
                                      position: "absolute",
                                      fontWeight: "700",
                                      top: "15%",
                                      left: "10%",
                                      textAlign: "left",
                                      zIndex: "1",
                                    }}
                                  >
                                    {status_reserva == 10 ? (
                                      <div>
                                        {" "}
                                        <span>EN ESPERA DE</span>
                                        <br />
                                        <span> CONFIRMACIÓN</span>{" "}
                                      </div>
                                    ) : status_reserva == 20 ? (
                                      <div>
                                        {" "}
                                        <span>RESERVA</span>
                                        <br />
                                        <span> CONFIRMADA</span>{" "}
                                      </div>
                                    ) : status_reserva == 30 ? (
                                      <div>
                                        {" "}
                                        <span>RESERVA</span>
                                        <br />
                                        <span> EN CURSO</span>{" "}
                                      </div>
                                    ) : null}
                                  </div>
                                  <img
                                    width={150}
                                    style={{ transform: "rotate(180deg)" }}
                                    src={
                                      status_reserva == 10
                                        ? aunno
                                        : status_reserva == 20
                                        ? confirmado
                                        : status_reserva == 30
                                        ? cursoya
                                        : null
                                    }
                                  ></img>
                                </div>
                                {entrenador[0] ? (
                                  <div
                                    className="Cabecera reservamisclases letra_cabeza"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Tus clases preferidas a un click
                                    <br />
                                    <span style={{ fontWeight: "600" }}>
                                      {entrenador[0].name}/{fecha}/{hora}
                                    </span>
                                  </div>
                                ) : null}
                                <div className=" informacion informacionmisclases d-flex ">
                                  <div className="step intensidad">
                                    <div>
                                      <img
                                        src={intensidad_img}
                                        alt="intensidad"
                                      />
                                      <br />
                                      {intensidadMsg}
                                    </div>
                                  </div>
                                  <div className="step duracion">
                                    <div>
                                      <img src={reloj} alt="Duración" />
                                      <br />
                                      <span>{duracion}</span>Min
                                      <br />
                                      Duración
                                    </div>
                                  </div>
                                  <div className="step tamañosaerobico intensidad">
                                    <div className="aero">
                                      <img src={aerobico} alt="Duración" />
                                      <br />
                                      Tipo Aerobicos
                                    </div>
                                  </div>
                                  <div className="step calorias">
                                    <div>
                                      <img src={corazon} alt="Calorias" />
                                      <br />
                                      <div>{calorias}</div>Cal. promedio
                                    </div>
                                  </div>
                                  <div className="step ver tamañosver ">
                                    <div>
                                      <i className="fa fa-arrow-right m-1"></i>
                                      <br />
                                      <div>Iniciar</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                  );
                });
            }
          }
        }
      })
      .catch((err) => {
        console.error(
          "Error en api/clientes/reservas/cargar-cronograma-home",
          err
        );
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });
  }, [individuales]);

  function muestrame() {
    const boton1 = document.querySelector(".letrasbotones2doc");
    const boton2 = document.querySelector(".letrasbotones1doc");
    boton1.style = "z-index:3";
    boton2.style = "z-index:2";
    setIndividuales(false);
  }
  function muestrame1() {
    const boton1 = document.querySelector(".letrasbotones1doc");
    const boton2 = document.querySelector(".letrasbotones2doc");
    boton1.style = "z-index:3";
    boton2.style = "z-index:2";
    setIndividuales(true);
  }
  return (
    <div>
      <div className="bievenidoainicio" style={{ marginLeft: "10vw" }}>
        <div className="d-flex">
          <div className="SubTitle8perfilcual corrgircoso">Reservar</div>
          <div className="lineainiciousuario corrgircoso1"></div>
        </div>
        <div className="title creditos">Mis clases</div>
        <div
          className="textodebajoreservas tituloreservartuclasedebajo"
          style={{ marginLeft: "4.5VW" }}
        >
          En MOB eres dueño de tu tiempo, agenda tu entrenamiento en los días y
          horario de tu preferencia
        </div>
      </div>
      <div className="">
        <div className="botonespestañasall col-12 position-relative">
          <div className="letrasbotones1doc col-1 mx-4" onClick={muestrame1}>
            <div className="letrasbotones1 text-center  position-relative">
              Clases <br /> individuales
            </div>
            <img className="d-inline-flex" src={boton}></img>
          </div>
          <div className="letrasbotones2doc col-1 mx-4 " onClick={muestrame}>
            <div className="letrasbotones text-center position-relative">
              Clases <br /> grupales
            </div>
            <img className="d-inline-flex " src={boton1}></img>
          </div>
        </div>
        <div className="grupales">
          <div className="listaReservasmisclases mx-auto   ">
            {individuales &&
            renderReservas &&
            renderReservas.filter((e) => e.key.includes("false")).length > 0 ? (
              renderReservas.filter((e) => e.key.includes("false"))
            ) : !individuales &&
              renderReservas &&
              renderReservas.filter((e) => e.key.includes("true")).length >
                0 ? (
              renderReservas.filter((e) => e.key.includes("true"))
            ) : (
              <div
                className=" "
                style={{
                  marginTop: "4rem",
                  color: "#CB3D82",
                  fontWeight: "bold",
                }}
              >
                <div
                  className="title mx-auto  text-center"
                  style={{ fontSize: "1.5vw" }}
                >
                  <div className="title mx-auto">
                    <img src={minicalendar} />
                  </div>
                  <br></br>
                  No se encuentran reservas
                </div>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MisClases;
