export function useCompareduracion(a, b) {
  if (parseInt(a.duracion) < parseInt(b.duracion)) return -1;
  if (parseInt(a.duracion) > parseInt(b.duracion)) return 1;
  return 0;
}
export function useCompareduracion1(a, b) {
  if (parseInt(a.duracion) > parseInt(b.duracion)) return -1;
  if (parseInt(a.duracion) < parseInt(b.duracion)) return 1;
  return 0;
}

export function useComparecal(a, b) {
  if (parseInt(a.calorias) < parseInt(b.calorias)) return -1;
  if (parseInt(a.calorias) > parseInt(b.calorias)) return 1;
  return 0;
}
export function useComparecal1(a, b) {
  if (parseInt(a.calorias) > parseInt(b.calorias)) return -1;
  if (parseInt(a.calorias) < parseInt(b.calorias)) return 1;
  return 0;
}
export function useCompareIn(a, b) {
  if (a.intensidadMsg[1] < b.intensidadMsg[1]) return -1;
  if (a.intensidadMsg[1] > b.intensidadMsg[1]) return 1;
  return 0;
}
export function useCompareIn1(a, b) {
  if (a.intensidadMsg[1] > b.intensidadMsg[1]) return -1;
  if (a.intensidadMsg[1] < b.intensidadMsg[1]) return 1;
  return 0;
}
