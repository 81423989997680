import { UPDATE_DATA_TRAINER } from "../types";
import { promise_three } from "./promises";
function action_trainer(
  finislongdays,
  inicioday,
  idf = null,
  condition = null
) {
  const promise_threed = promise_three(
    finislongdays,
    inicioday,
    idf,
    condition
  );
  return async (dispatch) => {
    promise_threed
      .then((info) => {
        if (info.length > 0) {
          dispatch({ type: UPDATE_DATA_TRAINER, payload: info });
        } else {
          dispatch({ type: UPDATE_DATA_TRAINER, payload: [] });
        }
      })
      .catch((err) => console.error(err));
  };
}
export { action_trainer };
