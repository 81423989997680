import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { isIOS } from "react-device-detect";
import Navbar, { NavItems } from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import axios from "axios";
import Filtrars from "./Filtrar/Filtrar";
import "./App.css";
import Login from "./Auth/Auth";
import MisClases from "./MisClases/MisClases";
import OneSignal from "react-onesignal";
import LoginUser, {
  LoginEntrenador,
  RegisterEntrenador,
  RegisterUser,
} from "./Auth/Usuario";
import "./assets/css/style.css";
import Usuario from "./User/Usuario";
import Completar from "./User/Completar";
import CompletarTriage from "./User/CompletarTriage.js";
// import Load from "./User/Load";

import Reservas from "./Reservas/Reservas";

import Creditos from "./Creditos/Creditos";
import Hr from "./Hr/Hr";
import Hrresults from "./Hr/Hrresults";
import {
  basename,
  instance,
  entrenadorType,
  tawkToKey,
  tawkToPropertyId,
  userType,
} from "./utils/utils";
import VerReserva from "./Reservas/VerReserva";
import Perfil from "./Perfil/Perfil";
import Clase from "./Clase/Clase";
import Clasestream from "./Clase/Clasestream";
import Resumen from "./Clase/Resumen";

import TrackingAgora from "./trackingAgora/TrakingAgora";

import ConfirmadoCreditos from "./Reservas/ConfirmadoCreditos";
import ResponsePayu from "./Creditos/ResponsePayu.js";
import NoReservado from "./Reservas/NoReservado";
import InicioEntrenador from "./Entrenador/Inicio/InicioEntrenador";
import EntrenadorReserva from "./Entrenador/Reservas/Reservas";
import PerfilEntrenador from "./Entrenador/Perfil/Perfil";
import VerReservaEntrenador from "./Entrenador/Reservas/VerReservaEntrenador";
import VerReservaGruplaEntrenador from "./Entrenador/Reservas/VerReservaGrupalEntrenador";
import VerCuenta from "./Entrenador/Perfil/VerCuenta";

import OnboardingEntrenador from "./Entrenador/OnboardingTrainer/OnboardingTrainer";
import Faq, { Politicas, Terminos } from "./FAQ/FAQ";
import EntrenadorClase from "./Entrenador/Clase/EntrenadorClase";
import EntrenadorClasestream from "./Entrenador/Clase/EntrenadorClasestream";
import ResumenTransacciones from "./Transacciones/ResumenTransacciones";
import ScrollToTop from "./ScrollToTop";
import tawkTo from "tawkto-react";
import Media from "react-media";
import Sidebar from "react-sidebar";

import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import ReservasOnly from "./Reservas/reservasOnly";
import VerTipo from "./vertipo/vertipo";
import VerTipoIndividual from "./vertipo/vertipoindividual";
import VerinfoIndividual from "./vertipo/verinfoindividual";
import EjerciciosReservasGrupales from "./vertipo/EjerciciosReservasGrupales";
import VerReservaCompanies from "./vertipo/VerReservaCompanies";
import ClaseCompanies from "./vertipo/ClaseCompanies";
import ReservarClaseCompanies from "./vertipo/ReservarClaseCompanies";
import ConfirmadoCreditosCompanyGroup from "./vertipo/ConfirmadoCreditosCompanyGroup";
import ResumenCompanies from "./vertipo/ResumenCompanies";

export default function App() {
  const state = useSelector((state) => state);

  const { dataCustomer } = state.data_customer;
  const pathnurl = window.location.href;
  const [valuecollapse, setValuecollapse] = useState(null);

  function changeless() {
    setValuecollapse("up");
  }

 

  const videos = {
    "abs Oblicuos con peso":
      process.env.PUBLIC_URL + "/videos/absOblicuosconpeso.mp4",
    "abs plancha brazos extendidos":
      process.env.PUBLIC_URL + "/videos/ABSPlanchabrazosextendidos.mp4",
    "runing mountain":
      process.env.PUBLIC_URL + "/videos/ABSRunningMountaingclimbercruzado.mp4",
    "abs sit Ups": process.env.PUBLIC_URL + "/videos/ABSSitups.mp4",
    "Spiderman mountain":
      process.env.PUBLIC_URL + "/videos/ABSSPIDERMOUNTAINCLIMBER.mp4",
    "Abs superman": process.env.PUBLIC_URL + "/videos/absOblicuosconpeso.mp4",
    "Posición del niño": process.env.PUBLIC_URL + "/videos/Posiciondelnino.mp4",
    "Rotación corta de homrbos":
      process.env.PUBLIC_URL + "/videos/Rotacioncortadehombros.mp4",
  };
  const [zonahorairachange, setZonahorairachange] = useState(null);

  useEffect(() => {
    document.body.addEventListener("click", changeless, true);
  }, [valuecollapse]);

  if (pathnurl.includes("wv=true")) {
    // is in webview
    localStorage.inWebView = true;
  }
  const [isLogged, setLogged] = useState(null);
  const [user, setUser] = useState({
    username: null,
    type: null,
    code: null,
    img: null,
    credits: null,
  });

  useEffect(() => {
    axios.get("https://ipwhois.app/json/").then((res) => {
      if (res.data) {
        setZonahorairachange(res.data.timezone);
      }
    });
  }, []);
  const [renderroute, setRenderroute] = useState(false);
  // middleware
  function checkData(id_generado) {
    if (id_generado) {
      const formData_o = new FormData();
      formData_o.append("onesignal_id", id_generado);
      instance
        .post(`users/push/onesignal-id`, formData_o)
        .then((res) => {})
        .catch((err) => console.log(err.response));
    }
    instance
      .get("users/")
      .then((response) => {
        const data_user = response.data;
        if (data_user) {
          const formData = new FormData();
          formData.append("data", localStorage.my_code);
          if (localStorage.type == userType) {
            if (!localStorage.type) {
              localStorage.removeItem("my_code");
              localStorage.removeItem("type");
              setUser({
                username: null,
                type: null,
                code: null,
              });
              setLogged(false);
            } else {
              localStorage.uuid = data_user.id;

              setUser({
                type: localStorage.type,
                code: localStorage.my_code,
                firts_name: data_user.first_name,
                last_name: data_user.last_name,
                customer: data_user.customer ? data_user.customer : null,
                email: data_user.email,
                img: data_user.customer.profile_pic,
                credits: data_user.customer
                  ? data_user.customer.credits_balance
                  : 0,
              });
              setLogged(true);
            }
          } else {
            if (!localStorage.type) {
              localStorage.removeItem("my_code");
              localStorage.removeItem("type");
              setUser({
                username: null,
                type: null,
                code: null,
              });
              setLogged(false);
            } else {
              localStorage.uuid = data_user.id;
              setUser({
                type: localStorage.type,
                code: localStorage.my_code,
                firts_name: data_user.first_name,
                last_name: data_user.last_name,
                trainer: data_user.trainer ? data_user.trainer : null,
                email: data_user.email,
                img: data_user.trainer.profile_pic,
                credits: data_user.trainer
                  ? data_user.trainer.credits_balance
                  : 0,
              });
              setLogged(true);
            }
          }
          setRenderroute(true);
        }
      })
      .catch((err) => {
        if (err.response) {
          localStorage.clear();
          window.location.href = `${basename}/`;
          console.log("Error", err);
        }
      });
  }

  function moveTawk() {
    const intervalTawk = setInterval(() => {
      const Tawk_API = window.Tawk_API || {};
      if (!Tawk_API) {
        clearInterval(intervalTawk);
        startTawk();
      }
      const footer = document.querySelector(".navFooter");
      const header = document.querySelector(".navHeader");
      const actualHeight = window.innerHeight;
      if (!Tawk_API.isChatMaximized()) {
        const widgetOriginal = document.querySelector(
          "iframe[title='chat widget']"
        );

        if (widgetOriginal) {
          const widget2 = widgetOriginal.contentDocument.querySelector(
            "body .tawk-min-container"
          );

          const laptop = window.matchMedia("(max-width:600px)");

          if (widget2) {
            if (laptop.matches === true) {
              if (window.location.pathname !== `${basename}/`) {
                widgetOriginal.style = `margin-right:0rem !important;margin-bottom:0rem !important;position:fixed !important ;bottom:12%;right:0% !important`;
                widget2.style = `transform:scale(0.7) !important ;`;
              } else {
                widgetOriginal.style = `margin-right:0.3rem !important;margin-bottom:1rem !important`;
                widget2.style = `transform:scale(0.7) !important `;
              }
            } else {
              if (window.location.pathname == `${basename}/Usuario/completar`) {
                widget2.style = `transform:scale(0.7) !important`;
                widgetOriginal.style = `margin-right:1rem !important;margin-bottom:-0.7rem !important`;
              } else if (
                window.location.pathname.includes(
                  `${basename}/Usuario/clasestream/`
                )
              ) {
                widget2.style = `display:none !important`;
              } else {
                widget2.style = `transform:scale(0.7) !important`;
                widgetOriginal.style = `margin-right:0.7rem !important;margin-bottom:0rem !important`;
              }
            }
          }
        }

        const customWidget = document.querySelector("#chatWidget");
        if (customWidget) {
          if (!widgetOriginal) {
            customWidget.style.hi = "none";
            return;
          }
          if (footer) {
            // if have footer, move up the chat widget
            customWidget.style.bottom = `${footer.offsetHeight + 7}px`;
          } else {
            // If not have footer, move down
            customWidget.style.bottom = `15px`;
          }
          if (actualHeight < 500 && !pathnurl.includes("/clase/")) {
            // If height is less than 500 (When open the keyboard, for example), not show the chat button
            customWidget.style.display = "none";
          } else if (customWidget.style.display == "none") {
            customWidget.style.display = "inherit";
          }
          if (pathnurl.includes("/completar")) {
            // if is in "completar" page, zIndex is 1 to show complete date born modal
            customWidget.style.zIndex = `1001`;
          } else {
            customWidget.style.zIndex = `9999999999998`;
          }
        }
      }
      // Hide footer and/or header if is neccesary (i.e: When open the mobile keyboard)
      if (actualHeight < 300) {
        if (header) {
          header.classList.add("d-none");
        }
        if (footer) {
          footer.classList.add("d-none");
        }
      } else {
        if (header) {
          header.classList.remove("d-none");
        }
        if (footer) {
          footer.classList.remove("d-none");
        }
      }
    }, 100);
  }

  async function startTawk() {
    await tawkTo(tawkToPropertyId, tawkToKey);

    if (window.Tawk_API != null) {
      const Tawk_API = window.Tawk_API || {};
      Tawk_API.onLoad = function () {
        moveTawk();
      };
    }
  }
  useEffect(async () => {
    startTawk();
    document.title = "ELEVVA";
    if (localStorage.type) {
      if (
        !pathnurl.includes("/ingreso") &&
        !pathnurl.includes("/registro") &&
        window.location.pathname !== `${basename}/` &&
        process.env.NODE_ENV != "development" &&
        isIOS != true
      ) {
        // OneSignal.init({
        //   appId: "4d0933a4-7731-43a4-a4e3-92f838c942d0",
        //   safari_web_id:
        //     "web.onesignal.auto.3fd215f3-eebf-45ec-a840-b342bc8d8df9",
        //   notifyButton: {
        //     enable: true,
        //   },
        // });

        // OneSignal.getUserId(function (userId_onesignal) {
        //   if (userId_onesignal && userId_onesignal.length > 20) {
        //     sessionStorage.onesignal_id = userId_onesignal;
        //     localStorage.onesignal_id = userId_onesignal;
        //     checkData(userId_onesignal);
        //   } else {
        //     OneSignal.on("subscriptionChange", function (isSubscribed) {
        //       if (isSubscribed == true) {
        //         OneSignal.getUserId(function (userId) {
        //           if (userId && typeof userId == "string") {
        //             OneSignal.setExternalUserId(localStorage.uuid);
        //             sessionStorage.onesignal_id = userId;
        //             localStorage.onesignal_id = userId;
        //             const formData_o = new FormData();
        //             formData_o.append("onesignal_id", userId);
        //             instance
        //               .post(`users/push/onesignal-id`, formData_o)
        //               .then((res) => {})
        //               .catch((err) => console.log(err.response));
        //           }
        //         });
        //       } else {
        //         if (
        //           localStorage.onesignal_id &&
        //           localStorage.onesignal_id != undefined
        //         ) {
        //           sessionStorage.onesignal_id = localStorage.onesignal_id;
        //           const formData = new FormData();
        //           formData.append("onesignal_id", localStorage.onesignal_id);
        //           instance
        //             .delete(`users/push/onesignal-id`, {
        //               data: { onesignal_id: `${localStorage.onesignal_id}` },
        //             })
        //             .then((res) => {})
        //             .catch((err) => console.log(err.response));
        //         }
        //       }
        //     });
        //   }
        //   checkData();
        // });
        checkData();
      } else {
        checkData();
      }
    } else {
      setLogged(false);
      setRenderroute(true);
    }
  }, []);

  useEffect(() => {
    if (isLogged === false) {
      if (pathnurl.includes(`/${userType}/`)) {
        window.location.href = `${basename}/`;
      }
      if (pathnurl.includes(`/${entrenadorType}/`)) {
        window.location.href = `${basename}/`;
      }
    } else {
    }
    return () => {};
  }, [isLogged]);

  const laptop = window.matchMedia("(min-width: 921px)");

  // console.log(window.location.pathname, user, localStorage.refresh);
  return (
    <>
      {renderroute ? (
        <>
          <div>
            <Router basename={basename}>
              <div id="top"></div>
              <ScrollToTop />
              <Navbar
                user={user}
                isLogged={isLogged}
                valuecollapse={valuecollapse}
                setValuecollapse={setValuecollapse}
              />
              <div className="row g-0">
                <Media
                  queries={{
                    laptop:
                      window.location.pathname !== `${basename}/` &&
                      window.location.pathname !== "/Usuario" &&
                      window.location.pathname !== "/usuario" &&
                      !pathnurl.includes("/Usuario/clase") &&
                      window.location.pathname !== `${basename}/entrenador` &&
                      window.location.pathname !==
                        `${basename}/OnboardingTrainer` &&
                      window.location.pathname !== `${basename}/tyc` &&
                      window.location.pathname !== `${basename}/privacidad` &&
                      window.location.pathname !== `${basename}/Entrenador` &&
                      !pathnurl.includes("/ejercicios") &&
                      window.location.pathname !==
                        `${basename}/registro/Usuario` &&
                      window.location.pathname !==
                        `${basename}/registro/Entrenador` &&
                      window.location.pathname !==
                        `${basename}/registrosagora` &&
                      !pathnurl.includes(`/pdf/:`) &&
                      window.location.pathname.slice(0, 10) !== "/recuperar"
                        ? "(min-width: 921px)"
                        : "(min-width: 30000px)",
                  }}
                >
                  {(matches) =>
                    matches.laptop ? (
                      <div className="col" id="navbarNavDropdown">
                        <Sidebar
                          sidebar={
                            <>
                              <NavItems user={user} />
                            </>
                          }
                          docked={true}
                          sidebarClassName={
                            user.type == "Entrenador"
                              ? "sideBarMobTrainer"
                              : "sideBarMob"
                          }
                          rootClassName={
                            pathnurl.includes(`/ejercicios`)
                              ? "d-none"
                              : pathnurl.includes(`/${userType}/completar`)
                              ? //  || pathnurl.includes(`/${userType}/Load`)
                                "rootSideBarMob"
                              : "rootSideBarMob1"
                          }
                        >
                          <></>
                        </Sidebar>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </Media>
                <div
                  className=""
                  style={{
                    width: !laptop.matches ? "" : "calc(100% - 0px)",

                  }}
                >
                  <Switch>
                    <Route path="/" exact>
                      <LazyLoad height={200} once>
                      <LoginEntrenador />{" "}
                      </LazyLoad>
                    </Route>
                    <Route path="/usuario" exact>
                      <LazyLoad height={200} once>
                        <LoginUser />
                      </LazyLoad>
                    </Route>
                    {/* <Route path="/entrenador" exact>
                      <LazyLoad height={200} once>
                        <LoginEntrenador />{" "}
                      </LazyLoad>
                    </Route> */}
                    <Route path="/registro/Usuario" exact>
                      <LazyLoad height={200} once>
                        <RegisterUser />{" "}
                      </LazyLoad>
                    </Route>
                    <Route path="/registrosagora" exact>
                      <TrackingAgora />
                    </Route>
                    {/* <Route path="/recuperar/:code" exact>
                      <RecuperarPass />
                    </Route> */}
                    <Route path="/registro/Entrenador" exact>
                      <LazyLoad height={200} once>
                        <RegisterEntrenador />
                      </LazyLoad>
                    </Route>
                    <Route path="/recuperar" exact>
                      <LazyLoad height={200} once>
                        <RegisterEntrenador />
                      </LazyLoad>
                    </Route>
                    <Route path={`/OnboardingTrainer`} exact>
                      <LazyLoad height={200} once>
                        <OnboardingEntrenador user={user} />
                      </LazyLoad>
                    </Route>

                    <Route path="/faq" exact>
                      <Faq user={user} />
                    </Route>
                    <Route path="/tyc" exact>
                      <Terminos user={user} />
                    </Route>
                    <Route path="/privacidad" exact>
                      <Politicas user={user} />
                    </Route>
                    <Route path="/ResumenTransacciones" exact>
                      <LazyLoad height={200} once>
                        <ResumenTransacciones user={user} />
                      </LazyLoad>
                    </Route>

                    {/* <Route path="/ejercicios" exact>
                      <LazyLoad height={200} once>
                        <Ejercicios
                          user={user}
                          videos={videos}
                          isLogged={isLogged}
                        />
                      </LazyLoad>
                    </Route> */}
                    {/* Rutas del usuario  */}
                    <Route path={`/${userType}/inicio`} exact>
                      <LazyLoad height={200} once>
                        <Usuario dataCustomer={dataCustomer} />
                      </LazyLoad>
                    </Route>

                    <Route path={`/${userType}/completar`} exact>
                      <LazyLoad height={200} once>
                        <Completar user={user} />
                      </LazyLoad>
                    </Route>
                    <Route path={`/${userType}/completarTriage`} exact>
                      <CompletarTriage
                        user={user}
                        id={localStorage?.completarTriage}
                      />
                    </Route>
                    <Route path={`/${userType}/MisClases`} exact>
                      <LazyLoad height={200} once>
                        <MisClases
                          user={user}
                          zonahorairachange={zonahorairachange}
                        />
                      </LazyLoad>
                    </Route>
                    {/* <Route path={`/${userType}/completar/Load`} exact>
                      <Load user={userType} />
                    </Route> */}

                    <Route path={`/${userType}/reservas/only/:id`} exact>
                      <ReservasOnly dataCustomer={dataCustomer} />
                    </Route>
                    <Route path={`/${userType}/reservas`} exact>
                      <LazyLoad height={200} once>
                        <Reservas dataCustomer={dataCustomer} />
                      </LazyLoad>
                    </Route>
                    <Route path={`/${userType}/reservas/Filtrar`} exact>
                      <LazyLoad height={200} once>
                        <Reservas />
                      </LazyLoad>
                    </Route>
                    <Route path={`/${userType}/reservas/Filtrar`} exact>
                      <Filtrars />
                    </Route>
                    {/* <Route path={`/${userType}/reservas/:id`} exact>
                      <Reservando user={user} />
                    </Route> */}
                    <Route
                      path={`/${userType}/confirmadoCreditos/:fecha`}
                      exact
                    >
                      <ConfirmadoCreditos user={user} />
                    </Route>

                    <Route path={`/${userType}/noreservado/:id`} exact>
                      <NoReservado />
                    </Route>

                    <Route path={`/${userType}/reservas/ver/:id`} exact>
                      <VerReserva zonahorairachange={zonahorairachange} />
                    </Route>
                    <Route path={`/${userType}/creditos`} exact>
                      <Creditos dataCustomer={dataCustomer} />
                    </Route>
                    <Route path={`/responsePayu`} exact>
                      <ResponsePayu />
                    </Route>
                    {/* <Route
                      path={`/${userType}/Pasarelapagos/:id/:percentaje/:currency`}
                      exact
                    >
                      <Pasarelapagos user={user} />
                    </Route> */}
                    {/* <Route path={`/${userType}/mediopago/:id/:medio`} exact>
                      <PasarelapagosMedio user={user} />
                    </Route> */}
                    <Route path={`/${userType}/HR`} exact>
                      <Hr />
                    </Route>
                    <Route path={`/${userType}/HR/:rutina`} exact>
                      <Hr />
                    </Route>
                    <Route path={`/${userType}/HR/results/:rutina/:uuid`} exact>
                      <Hrresults user={user} />
                    </Route>

                    <Route path={`/${userType}/clase/:reserva`} exact>
                      <Clase user={user} />
                    </Route>
                    <Route path={`/${userType}/clasestream/:reserva`} exact>
                      <Clasestream user={user} />
                    </Route>
                    <Route path={`/${userType}/resumen/:idclase`} exact>
                      <Resumen user={user} />
                    </Route>
                    <Route path={`/${userType}/:option?`} exact>
                      <Perfil user={user} />
                    </Route>

                    <Route path={`/${entrenadorType}/inicio`} exact>
                      <InicioEntrenador user={user} />
                    </Route>
                    <Route path={`/${entrenadorType}/reservas`} exact>
                      <EntrenadorReserva user={user} />
                    </Route>
                    <Route
                      path={`/${entrenadorType}/reservas/ver/:idreserva`}
                      exact
                    >
                      <VerReservaEntrenador user={user} />
                    </Route>
                    <Route
                      path={`/${entrenadorType}/reservasGrupales/ver/:id`}
                      exact
                    >
                      <VerReservaGruplaEntrenador
                        user={user}
                        zonahorairachange={zonahorairachange}
                      />
                    </Route>
                    <Route
                      path={`/${entrenadorType}/clase/:reserva/:idclase`}
                      exact
                    >
                      <EntrenadorClase user={user} />
                    </Route>
                    <Route
                      path={`/${entrenadorType}/clasestream/:reserva/:idclase`}
                      exact
                    >
                      <EntrenadorClasestream user={user} />
                    </Route>
                    <Route path={`/${entrenadorType}/:option?`} exact>
                      <PerfilEntrenador user={user} />
                    </Route>
                    <Route path={`/pdf/:cuenta`} exact>
                      <VerCuenta />
                    </Route>
                    <Route path={`/ejercicios/:tipo`} exact>
                      <VerTipo />
                    </Route>
                    <Route path={`/ejercicios/card/:id`} exact>
                      <VerTipoIndividual />
                    </Route>
                    <Route path={`/ejercicios/info/:id`} exact>
                      <VerinfoIndividual />
                    </Route>
                    <Route path={`/ejercicios/ver/reservasGrupales`} exact>
                      <EjerciciosReservasGrupales
                        user={user}
                        zonahorairachange={zonahorairachange}
                      />
                    </Route>
                    <Route path={`/ejercicios/ver/reservasGrupal/:id`} exact>
                      <VerReservaCompanies
                        user={user}
                        zonahorairachange={zonahorairachange}
                      />
                    </Route>
                    <Route
                      path={`/ejercicios/iniciar/ClaseCompanies/:reserva`}
                      exact
                    >
                      <ClaseCompanies
                        user={user}
                        zonahorairachange={zonahorairachange}
                      />
                    </Route>
                    <Route
                      path={`/ejercicios/iniciar/ClaseCompanies/:reserva`}
                      exact
                    >
                      <ClaseCompanies
                        user={user}
                        zonahorairachange={zonahorairachange}
                      />
                    </Route>
                    <Route path={`/ejercicios/reservando/:id`} exact>
                      <ReservarClaseCompanies
                        user={user}
                        zonahorairachange={zonahorairachange}
                      />
                    </Route>
                    <Route path={`/ejercicios/confirmadoCompanie/:fecha`} exact>
                      <ConfirmadoCreditosCompanyGroup
                        user={user}
                        zonahorairachange={zonahorairachange}
                      />
                    </Route>
                    <Route path={`/ejercicios/resumen/:idclase`} exact>
                      <ResumenCompanies
                        user={user}
                        zonahorairachange={zonahorairachange}
                      />
                    </Route>
                  </Switch>
                </div>
              </div>

              <Footer />
            </Router>
          </div>
        </>
      ) : null}
    </>
  );
}
