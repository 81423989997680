import { UPDATE_DATA } from "../types";
import { basename, instance } from "../utils/utils";
function action1() {
  return async (dispatch) => {
    if (
      !window.location.pathname.includes("/clase/") &&
      !window.location.pathname.includes("/clase/") &&
      !window.location.pathname.includes("/clasestream/") &&
      !window.location.pathname.includes("/ingreso") &&
      !window.location.pathname.includes("/registrosagora") &&
      !window.location.pathname.includes("/ejercicios/Buscar") &&
      window.location.pathname !== `${basename}/`
    )
      instance
        .get("app/notifications/unread")
        .then((res) => res.data)
        .then((data) => {
          dispatch({ type: UPDATE_DATA, payload: data });
        })
        .catch((err) => console.error(err));
  };
}
export { action1 };
