import { useHistory, useParams, Redirect } from "react-router-dom";
import perfil from "./iconos/perfil.png";
import bitacora from "./iconos/bitacora.png";
import Moment from "moment";
import cara from "./iconos/cara.png";
import kms from "./iconosinfo/distance.png";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import carpeta from "./iconos/carpeta11.png";
import pesasnuevas from "./iconos/pesass11 (1).png";
import "./Perfil.css";
import { Nivelesydiscliplinas } from "./Nivelesydiscliplinas";
import "rc-slider/assets/index.css";
import Estandarimagenes from "./Estandarimagenes.js";
import { useEffect, useRef, useState } from "react";
import levelupi from "./iconos/starchanle.png";
import levelupintermediate from "./iconos/starchangeintermedio.png";
import avanzanivelmax from "./iconos/Grupo 6541.png";
import caneca from "./iconos/caneca.png";
import candado from "./iconos/candado.png";
import xfinal from "../assets/img/HrIcons/iconos/xfinal.png";
import equis from "./iconos/EQUIS.png";
import flechaback from "../Perfil/iconosinfo/flechabackpruebas.png";
import { AccordionItem } from "../Entrenador/Perfil/Perfil";
import "./listitem.css";
import { ResponsiveLine } from "@nivo/line";
import agrandar from "../assets/img/footerIcons/iconos/agrandar.png";
import subio from "./iconoscuerpo/subio.png";
import bajo from "./iconoscuerpo/bajo.png";
import sigueasi from "./iconos/sigueasi.png";
import avanzanivel from "./iconos/avannivel.png";
import twoflechas from "./iconos/twoflechas.png";
import NiveldeUsuario from "../utils/NiveldeDificualtad.json";
import ReactTooltip from "react-tooltip";
import camera from "../assets/img/general/camara.png";
import warning2 from "../assets/img/general/warning2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import {
  ModalInfo,
  ModalInfo1,
  ButtonMob,
  NuevaAxiosURL,
  Loadingspinner,
  Silueta,
  userType,
  filterCheckDays,
  ModalInfoGraphics,
  laptop575,
  instance,
  basename,
} from "../utils/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import flechaverde from "./iconosinfo/felchaverde.png";
import dobleflechaverde from "./iconosinfo/doblefelchaverde.png";
import dobleflechaverdeabajo from "./iconosinfo/doblefelchaverdeabajo.png";
import imggraficas from "./iconosinfo/graficas.png";
import igual from "./iconosinfo/igual.png";
import chulito from "./iconosinfo/chulito.png";
import heart from "./iconosinfo/heart6.png";
import mob from "./iconosinfo/elevapoint.png";
import axios from "axios";
import star1 from "./iconos/star-unfill.png";
import star2 from "./iconos/star-fill.png";
import reloj_black from "./iconosinfo/clock_black.png";
import fire_black from "./iconosinfo/fire_black.png";
import Media from "react-media";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import dolorA from "./iconos/dolorA.svg";
import dolorB from "./iconos/dolorB.svg";
import brazoderecho from "./iconoscuerpo/brazofuerza.png";
import abdomen from "./iconoscuerpo/abdomen.png";
import flechasubioverde from "./iconoscuerpo/flechasubioverde.png";
import flechabajored from "./iconoscuerpo/flechabajoored.png";
import piernaderecha from "./iconoscuerpo/piernade.png";
import peso1 from "./iconoscuerpo/junke.png";
import gramera from "./iconoscuerpo/gramera.png";
import gramera1 from "./iconoscuerpo/gramera1.png";
import IMCfoto from "./iconoscuerpo/maletamala.png";
import grasafoto1 from "./iconoscuerpo/corporal.png";
import subcutaneafoto from "./iconoscuerpo/pepitas.png";
import visceral_img from "./iconoscuerpo/gordo.png";
import aguafoto from "./iconoscuerpo/gotaagua.png";
import hueso_img from "./iconoscuerpo/reloj.png";
import musculo_img from "./iconoscuerpo/muscu.png";
import canino from "./iconoscuerpo/hueso.png";
import proteina_img from "./iconoscuerpo/adn.png";
import BMRfoto from "./iconoscuerpo/agu.png";
import nene from "./iconoscuerpo/niño.png";
import { extendMoment } from "moment-range";

const Perfil = (props) => {
  const data_user = props.user;

  const nivel =
    data_user.customer && data_user.customer.level == 0
      ? data_user.customer.initial_level
      : data_user.customer
      ? data_user.customer.level
      : 0;
  const [modal1, setModal1] = useState(null);
  const [actualView, setView] = useState();
  const [estrella, setEstrella] = useState(0);
  const [Imc, setImc] = useState(null);
  const [pesoActual, setPeso] = useState(null);
  const [grasa, setgrasa] = useState(null);

  const [perdida, setperdida] = useState(null);
  const [subcutanea, setsubcutanea] = useState(null);
  const [visceral, setvisceral] = useState(null);
  const [water, setwater] = useState(null);
  const [hueso, sethueso] = useState(null);
  const [esqueletica, setesqueletica] = useState(null);
  const [musculo, setmusculo] = useState(null);
  const [proteina, setproteina] = useState(null);
  const [bmr, setbmr] = useState(null);
  const [edad, setedad] = useState(null);
  const [objetives_user, setobjetives_user] = useState(null);

  function hideModal() {
    setModal1(null);
  }
  const selOption = [
    ["Información personal", perfil, "perfil"], // 0
    ["Información de entrenamiento", pesasnuevas, "Informacióndeentrenamiento"], // 1
    ["Bitácora", bitacora, "bitacora"], // 2

    ["Cuerpo", carpeta, "cuerpo"], // 2
  ];
  useEffect(() => {
    instance.get(`customers/healthlog/?type=1`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        // console.log("PESO", daysselected);

        let peso_grafica = [];
        daysselected.map((e) => {
          peso_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "Peso",

            data: peso_grafica.filter((e) => e),
          },
        ];
        // console.log(daysselected.slice(1, -1)[0].numeric_value);
        if (daysselected.length == 1) {
          setPeso([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setPeso([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=2`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let IMC_grafica = [];
        daysselected.map((e) => {
          IMC_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "IMC",

            data: IMC_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setImc([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setImc([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=3&part=10`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        // console.log(daysselected);
        let grasa_grafica = [];
        daysselected.map((e) => {
          grasa_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "grasa",

            data: grasa_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setgrasa([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setgrasa([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=4`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let perdida_grafica = [];
        daysselected.map((e) => {
          perdida_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "perdida",

            data: perdida_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setperdida([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setperdida([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=5`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let subcutanea_grafica = [];
        daysselected.map((e) => {
          subcutanea_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "subcutanea",

            data: subcutanea_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setsubcutanea([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setsubcutanea([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=6`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let visceral_grafica = [];
        daysselected.map((e) => {
          visceral_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "visceral",

            data: visceral_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setvisceral([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setvisceral([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=7`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let water_grafica = [];
        daysselected.map((e) => {
          water_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "water",

            data: water_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setwater([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setwater([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=8`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let hueso_grafica = [];
        daysselected.map((e) => {
          hueso_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "hueso",

            data: hueso_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          sethueso([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          sethueso([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=9`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let esqueletica_grafica = [];
        daysselected.map((e) => {
          esqueletica_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "esqueletica",

            data: esqueletica_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setesqueletica([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setesqueletica([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=10&part=10`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        // console.log("------------------------------------------", daysselected);
        let musculo_grafica = [];
        daysselected.map((e) => {
          musculo_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "musculo",

            data: musculo_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setmusculo([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setmusculo([daysselected.slice(0, -1)[0].numeric_value, data, 0]);
      }
    });
    instance.get(`customers/healthlog/?type=11`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let proteina_grafica = [];
        daysselected.map((e) => {
          proteina_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "proteina",

            data: proteina_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setproteina([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setproteina([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=12`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let bmr_grafica = [];
        daysselected.map((e) => {
          bmr_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "bmr",

            data: bmr_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setbmr([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setbmr([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
    instance.get(`customers/healthlog/?type=13`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        let edad_grafica = [];
        daysselected.map((e) => {
          edad_grafica[e.id] = {
            y: e.numeric_value,
            x: e.date.slice(5, 10),
          };
        });
        const data = [
          {
            id: "edad",

            data: edad_grafica.filter((e) => e),
          },
        ];
        if (daysselected.length == 1) {
          setedad([daysselected.slice(-1)[0].numeric_value, data, 0]);
        } else
          setedad([
            daysselected.slice(0, -1)[0].numeric_value,
            data,
            daysselected[1].numeric_value,
          ]);
      }
    });
  }, []);

  useEffect(() => {
    if (nivel != null) {
      if (nivel == 10) {
        setEstrella(1);
      } else if (nivel == 20) {
        setEstrella(2);
      } else if (nivel == 30) {
        setEstrella(3);
      }
    }
  }, [nivel]);
  useEffect(() => {
    instance
      .get("customers/metrics/weekly/")
      .then((res) => {
        if (res.data) {
          // console.log("............................", res.data);
          setobjetives_user(res.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          setModal1(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Algo salio mal"
              text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
              classesMsg=""
            />
          );
        }
      });
  }, []);

  return (
    <div
      className={
        actualView == "perfil"
          ? "UpdateProfile perfil"
          : actualView == "cuerpo"
          ? "UpdateProfile cuerpol"
          : "UpdateProfile"
      }
    >
      <Cabecera selOption={selOption} onClick={(type) => setView(type)} />
      {actualView == "perfil" ? (
        <InfoPersonal
          selOption={selOption}
          actualView={actualView}
          user={data_user}
        />
      ) : null}
      {actualView == "Informacióndeentrenamiento" ? (
        <Estadisticas
          objetives_user={objetives_user}
          nivel={nivel}
          estrella={estrella}
          selOption={selOption}
          actualView={actualView}
        />
      ) : null}
      {actualView == "bitacora" ? (
        <Bitacora selOption={selOption} actualView={actualView} />
      ) : null}
      {actualView == "cuerpo" ? (
        <Cuerpo
          selOption={selOption}
          actualView={actualView}
          data_user={data_user}
          Imc={Imc}
          pesoActual={pesoActual}
          grasa={grasa}
          perdida={perdida}
          subcutanea={subcutanea}
          visceral={visceral}
          water={water}
          hueso={hueso}
          esqueletica={esqueletica}
          musculo={musculo}
          proteina={proteina}
          bmr={bmr}
          edad={edad}
        />
      ) : null}
      {modal1}
    </div>
  );
};

const Cuerpo = (props) => {
  const [brazoiz, setbrazoiz] = useState(0);
  const [brazoizgrasa, setbrazoizgrasa] = useState(0);
  const [dataexist, setdataexist] = useState(false);
  const [brazode, setbrazode] = useState(0);
  const [brazodegrasa, setbrazodegrasa] = useState(0);
  const [piernaiz, setpiernaiz] = useState(0);
  const [piernaizgrasa, setpiernaizgrasa] = useState(0);
  const [piernade, setpiernade] = useState(0);
  const [piernadegrasa, setpiernadegrasa] = useState(0);
  const [abdomenp, setabdomen] = useState(0);
  const [abdomenpgrasa, setabdomengrasa] = useState(0);

  // console.log(brazoiz, brazoizgrasa);
  const data_user = props.data_user;
  const Imc = props.Imc;
  const pesoActual = props.pesoActual;
  const grasa = props.grasa;
  const perdida = props.perdida;
  const subcutanea = props.subcutanea;
  const visceral = props.visceral;
  const water = props.water;
  const hueso = props.hueso;
  const esqueletica = props.esqueletica;
  const musculo = props.musculo;
  const proteina = props.proteina;
  const bmr = props.bmr;
  const edad = props.edad;
  function darsigno(x, y) {
    if (x - y >= 0) {
      return [+x];
    } else {
      return [-x];
    }
  }

  useEffect(() => {
    instance.get(`customers/healthlog/?part=20`).then((res) => {
      const daysselected = res.data;
      // console.log(daysselected);
      if (daysselected.length > 0) {
        const grasabrazo_iz = daysselected.filter((e) => e.type == 3);
        const masabrazo_iz = daysselected.filter((e) => e.type == 10);
        // console.log(masabrazo_iz);
        setbrazoiz(
          masabrazo_iz.length == 1
            ? masabrazo_iz[0].numeric_value
            : masabrazo_iz.length > 1
            ? darsigno(
                masabrazo_iz[0].numeric_value,
                masabrazo_iz[1].numeric_value
              )
            : 0
        );
        setbrazoizgrasa(
          grasabrazo_iz.length == 1
            ? grasabrazo_iz[0].numeric_value
            : grasabrazo_iz.length > 1
            ? darsigno(
                grasabrazo_iz[0].numeric_value,
                grasabrazo_iz[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=30`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        // console.log(daysselected);
        let grasabrazo_de = daysselected.filter((e) => e.type == 3);
        let masabrazo_de = daysselected.filter((e) => e.type == 10);
        // console.log(grasabrazo_de, masabrazo_de);
        setbrazode(
          masabrazo_de.length == 1
            ? masabrazo_de[0].numeric_value
            : masabrazo_de.length > 1
            ? darsigno(
                masabrazo_de[0].numeric_value,
                masabrazo_de[1].numeric_value
              )
            : 0
        );
        setbrazodegrasa(
          grasabrazo_de.length == 1
            ? grasabrazo_de[0].numeric_value
            : grasabrazo_de.length > 1
            ? darsigno(
                grasabrazo_de[0].numeric_value,
                grasabrazo_de[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=40`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasapierna_iz = daysselected.filter((e) => e.type == 3);
        const masapierna_iz = daysselected.filter((e) => e.type == 10);

        setpiernaiz(
          masapierna_iz.length == 1
            ? masapierna_iz[0].numeric_value
            : masapierna_iz.length > 1
            ? darsigno(
                masapierna_iz[0].numeric_value,
                masapierna_iz[1].numeric_value
              )
            : 0
        );
        setpiernaizgrasa(
          grasapierna_iz.length == 1
            ? grasapierna_iz[0].numeric_value
            : grasapierna_iz.length > 1
            ? darsigno(
                grasapierna_iz[0].numeric_value,
                grasapierna_iz[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=50`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasapierna_de = daysselected.filter((e) => e.type == 3);
        const masapierna_de = daysselected.filter((e) => e.type == 10);

        setpiernade(
          masapierna_de.length == 1
            ? masapierna_de[0].numeric_value
            : masapierna_de.length > 1
            ? darsigno(
                masapierna_de[0].numeric_value,
                masapierna_de[1].numeric_value
              )
            : 0
        );
        setpiernadegrasa(
          grasapierna_de.length == 1
            ? grasapierna_de[0].numeric_value
            : grasapierna_de.length > 1
            ? darsigno(
                grasapierna_de[0].numeric_value,
                grasapierna_de[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=60`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasaabdomen = daysselected.filter((e) => e.type == 3);
        const masaabdomen = daysselected.filter((e) => e.type == 10);

        setabdomen(
          masaabdomen.length == 1
            ? masaabdomen[0].numeric_value
            : masaabdomen.length > 1
            ? darsigno(
                masaabdomen[0].numeric_value,
                masaabdomen[1].numeric_value
              )
            : 0
        );
        setabdomengrasa(
          grasaabdomen.length == 1
            ? grasaabdomen[0].numeric_value
            : grasaabdomen.length > 1
            ? darsigno(
                grasaabdomen[0].numeric_value,
                grasaabdomen[1].numeric_value
              )
            : 0
        );
      }
    });
  }, []);

  const [estrella1, setEstrella1] = useState([]);

  const array001 = [
    {
      data: [{ x: 0, y: 0 }],
    },
  ];
  const formula =
    pesoActual && pesoActual[0]
      ? pesoActual[0] / Math.pow(data_user.customer.height / 100, 2)
      : null;
  function formula2(dato) {
    if (data_user.customer.gender == 1) {
      if (data_user.customer.age < 40) {
        if (dato < 8) return "BAJO";
        else if (dato < 21 && dato > 7) return "SALUDABLE";
        else if (dato < 26 && dato > 19) return "ALTO";
        else return "MUY ALTO";
      } else if (data_user.customer.age > 39 && data_user.customer.age < 60) {
        if (dato < 11) return "BAJO";
        else if (dato < 23 && dato > 10) return "SALUDABLE";
        else if (dato < 29 && dato > 21) return "ALTO";
        else return "MUY ALTO";
      } else if (data_user.customer.age > 59) {
        if (dato < 13) return "BAJO";
        else if (dato < 26 && dato > 12) return "SALUDABLE";
        else if (dato < 31 && dato > 24) return "ALTO";
        else return "MUY ALTO";
      }
    } else {
      if (data_user.customer.age < 40) {
        if (dato < 21) return "BAJO";
        else if (dato < 34 && dato > 20) return "SALUDABLE";
        else if (dato < 40 && dato > 32) return "ALTO";
        else return "MUY ALTO";
      } else if (data_user.customer.age > 39 && data_user.customer.age < 60) {
        if (dato < 23) return "BAJO";
        else if (dato < 36 && dato > 22) return "SALUDABLE";
        else if (dato < 41 && dato > 34) return "ALTO";
        else return "MUY ALTO";
      } else if (data_user.customer.age > 59) {
        if (dato < 24) return "BAJO";
        else if (dato < 37 && dato > 23) return "SALUDABLE";
        else if (dato < 43 && dato > 35) return "ALTO";
        else return "MUY ALTO";
      }
    }
  }
  function formula3(dato) {
    if (data_user.customer.gender == 1) {
      if (dato < 1) return "BAJO";
      else if (dato < 4 && dato > 0) return "ESENCIAL";
      else if (dato < 21 && dato > 3) return "NORMAL";
      else return "ALTO";
    } else {
      if (dato < 1) return "BAJO";
      else if (dato < 13 && dato > 0) return "ESENCIAL";
      else if (dato < 29 && dato > 12) return "NORMAL";
      else return "ALTO";
    }
  }
  function formula4(dato) {
    if (data_user.customer.gender == 1) {
      if (data_user.customer.age < 31) {
        if (dato < 43) return "BAJO";
        else if (dato < 57 && dato > 42) return "NORMAL";
        else return "ALTO";
      } else if (data_user.customer.age > 30 && data_user.customer.age < 61) {
        if (dato < 40) return "BAJO";
        else if (dato < 51 && dato > 39) return "NORMAL";
        else return "ALTO";
      } else if (data_user.customer.age > 59) {
        if (dato < 38) return "BAJO";
        else if (dato < 58 && dato > 37) return "NORMAL";
        else return "ALTO";
      }
    } else {
      if (data_user.customer.age < 31) {
        if (dato < 35) return "BAJO";
        else if (dato < 42 && dato > 34) return "NORMAL";
        else return "ALTO";
      } else if (data_user.customer.age > 30 && data_user.customer.age < 61) {
        if (dato < 33) return "BAJO";
        else if (dato < 39 && dato > 32) return "NORMAL";
        else return "ALTO";
      } else if (data_user.customer.age > 59) {
        if (dato < 28) return "BAJO";
        else if (dato < 34 && dato > 27) return "NORMAL";
        else return "ALTO";
      }
    }
  }
  function formula5(dato) {
    if (data_user.customer.gender == 1) {
      if (dato < 6 && dato > 2) return "PROMEDIO";
      else return "PARTICULAR";
    } else {
      if (dato < 4 && dato > 1) return "PROMEDIO";
      else return "PARTICULAR";
    }
  }
  function formula6(dato) {
    if (data_user.customer.gender == 1) {
      if (dato < 51) return "BAJO";
      else if (dato < 66 && dato > 50) return "SALUDABLE";
      else return "ALTO";
    } else {
      if (dato < 46) return "BAJO";
      else if (dato < 61 && dato > 45) return "SALUDABLE";
      else return "ALTO";
    }
  }
  function formula7(dato) {
    if (data_user.customer.gender == 1) {
      if (data_user.customer.age < 40) {
        if (dato < 33.3) return "BAJA";
        else if (dato < 39.4 && dato > 33.2) return "NORMAL";
        else if (dato < 45 && dato > 39.3) return "ALTA";
        else return "MUY ALTA";
      } else if (data_user.customer.age > 39 && data_user.customer.age < 60) {
        if (dato < 33.1) return "BAJA";
        else if (dato < 39.2 && dato > 33.0) return "NORMAL";
        else if (dato < 43.9 && dato > 39.1) return "ALTA";
        else return "MUY ALTA";
      } else if (data_user.customer.age > 59) {
        if (dato < 32.9) return "BAJA";
        else if (dato < 39 && dato > 32.8) return "NORMAL";
        else if (dato < 43.7 && dato > 38.9) return "ALTA";
        else return "MUY ALTA";
      }
    } else {
      if (data_user.customer.age < 40) {
        if (dato < 24.3) return "BAJA";
        else if (dato < 30.4 && dato > 24.2) return "NORMAL";
        else if (dato < 35.4 && dato > 30.3) return "ALTA";
        else return "MUY ALTA";
      } else if (data_user.customer.age > 39 && data_user.customer.age < 60) {
        if (dato < 24.1) return "BAJA";
        else if (dato < 30.2 && dato > 24) return "NORMAL";
        else if (dato < 35.2 && dato > 30.1) return "ALTA";
        else return "MUY ALTA";
      } else if (data_user.customer.age > 59) {
        if (dato < 23.9) return "BAJA";
        else if (dato < 30 && dato > 23.8) return "NORMAL";
        else if (dato < 35 && dato > 29.9) return "ALTA";
        else return "MUY ALTA";
      }
    }
  }
  const selOptionCuerpo = [
    [
      "Peso",
      peso1,
      formula < 18.5
        ? "MUY BAJO"
        : formula > 18.4 && formula < 25
        ? "SALUDABLE"
        : formula > 24.9 && formula < 30
        ? "ALTO"
        : "MUY ALTO",
      pesoActual ? pesoActual[0].toFixed(1) + " Kg" : 0,
      pesoActual ? pesoActual[1] : array001,
      pesoActual ? (pesoActual[0] - pesoActual[2]).toFixed(1) + " Kg" : 0,
    ],
    [
      "IMC",
      IMCfoto,
      Imc && Imc[0] < 18.5
        ? "MUY BAJO"
        : Imc && Imc[0] > 18.4 && Imc[0] < 25
        ? "SALUDABLE"
        : Imc && Imc[0] > 24.9 && Imc[0] < 30
        ? "ALTO"
        : "MUY ALTO",
      Imc ? Imc[0].toFixed(1) : 0,
      Imc ? Imc[1] : array001,
      Imc ? (Imc[0] - Imc[2]).toFixed(1) : 0,
    ],
    [
      "Grasa corporal",
      grasafoto1,
      grasa ? formula2(grasa[0]) : "____",
      grasa ? grasa[0].toFixed(1) + " %" : 0,
      grasa ? grasa[1] : array001,

      grasa ? (grasa[0] - grasa[2]).toFixed(1) + " %" : 0,
    ],
    // [
    //   "Pérdida de grasa",
    //   perdidafoto,
    //   "____",
    //   perdida ? perdida[0].toFixed(1) + " kg" : 0,
    //   perdida ? perdida[1] : array001,
    //   perdida ? (perdida[0] - perdida[2]).toFixed(1) : 0,
    // ],
    [
      "Grasa subcutaánea",
      subcutaneafoto,
      subcutanea ? formula3(subcutanea[0]) : "____",
      subcutanea ? subcutanea[0].toFixed(1) + " %" : 0,
      subcutanea ? subcutanea[1] : array001,
      subcutanea ? (subcutanea[0] - subcutanea[2]).toFixed(1) + " %" : 0,
    ],
    [
      "Grasa visceral",
      visceral_img,
      visceral && visceral[0] > 11 ? "EXCESIVO" : "SALUDABLE",
      visceral ? visceral[0].toFixed(1) : 0,
      visceral ? visceral[1] : array001,
      visceral ? (visceral[0] - visceral[2]).toFixed(1) : 0,
    ],
    [
      "Agua corporal",
      BMRfoto,
      water ? formula6(water[0]) : "____",
      water ? water[0].toFixed(1) + " %" : 0,
      water ? water[1] : array001,
      water ? (water[0] - water[2]).toFixed(1) + " %" : 0,
    ],
    [
      " Músculo esquelético",
      hueso_img,
      hueso ? formula7(hueso[0]) : "____",
      hueso ? hueso[0].toFixed(1) + " %" : 0,
      hueso ? hueso[1] : array001,
      hueso ? (hueso[0] - hueso[2]).toFixed(1) + " %" : 0,
    ],
    [
      "Masa esquelética",
      canino,
      esqueletica ? formula5(esqueletica[0]) : "____",
      esqueletica ? esqueletica[0].toFixed(1) + " %" : 0,
      esqueletica ? esqueletica[1] : array001,
      esqueletica ? (esqueletica[0] - esqueletica[2]).toFixed(1) + " %" : 0,
    ],
    [
      "  Masa muscular",
      musculo_img,
      musculo ? formula4(musculo[0]) : "____",
      musculo ? musculo[0].toFixed(1) + " Kg" : 0,
      musculo ? musculo[1] : array001,
      musculo ? (musculo[0] - musculo[2]).toFixed(1) + " Kg" : 0,
    ],
    [
      " Proteina",
      proteina_img,
      proteina && proteina[0] > 20
        ? "EXCESIVO"
        : proteina && proteina[0] < 16
        ? "BAJO"
        : "NORMAL",
      proteina ? proteina[0].toFixed(1) + " %" : 0,
      proteina ? proteina[1] : array001,
      proteina ? (proteina[0] - proteina[2]).toFixed(1) + " %" : 0,
    ],
    [
      "BMR",
      aguafoto,
      "____",
      bmr ? bmr[0] : 0,
      bmr ? bmr[1] : array001,
      bmr ? (bmr[0] - bmr[2]).toFixed(1) : 0,
    ],
    [
      " Edad corporal",
      nene,
      "____",
      edad ? edad[0].toFixed(1) : 0,
      edad ? edad[1] : array001,
      edad ? (edad[0] - edad[2]).toFixed(1) : 0,
    ],
  ];

  function hideModal() {
    setModal(null);
  }
  const [modal, setModal] = useState(null);

  const medidasCuerpo = [
    ["Brazo Izquierdo", "29%", "34%", useRef(null), useRef(null)],
    ["Brazo Derecho", "29%", "59%", useRef(null), useRef(null)],
    ["Pecho", "23%", "46.5%", useRef(null), useRef(null)],
    ["Abdomen", "36%", "46.5%", useRef(null), useRef(null)],
    ["Cadera", "47%", "46.5%", useRef(null), useRef(null)],
    ["Pierna Izquierdo", "60%", "40.5%", useRef(null), useRef(null)],
    ["Pierna Derecho", "60%", "51.5%", useRef(null), useRef(null)],
  ];
  useEffect(() => {
    const arrData = [];
    let count = 0;
    let title = selOptionCuerpo.map((e, i) => (
      <div
        className={
          laptop575.matches
            ? "d-flex row-cols-3 fivecolumnascuerpo  "
            : "d-flex row-cols-3 fivecolumnas "
        }
        key={i}
      >
        <div className="d-flex  jc-center cajafotos row-cols-12">
          <div className="d-flex  jc-center mitadesdefoto1">
            <img
              className="d-flex m-auto p-auto imagenizquierdacolumns"
              src={e[1]}
            />
          </div>
          <div className=" d-flex mitadesdefoto m-auto p-auto  letrasviscerasplum">
            {e[0]}
          </div>
        </div>
        {e[2] == "ALTO" ||
        e[2] == "BAJO" ||
        e[2] == "MUY BAJO" ||
        e[2] == "ALTO" ||
        e[2] == "EXCESIVO" ||
        e[2] == "MUY ALTO" ? (
          <div className=" d-flex m-auto p-auto jc-center letrasvisceras1RED">
            {e[2]}
          </div>
        ) : (
          <div
            className={
              laptop575.matches
                ? " d-flex m-auto p-auto jc-center letrasvisceras1cuerpo"
                : " d-flex m-auto p-auto jc-center letrasvisceras1"
            }
          >
            {e[2]}
          </div>
        )}

        <div
          className={
            laptop575.matches
              ? " d-flex m-auto p-auto jc-center letrasvisceras1cuerpo"
              : " d-flex m-auto p-auto jc-center letrasvisceras1"
          }
        >
          {e[3] ? e[3] : 0}
        </div>
      </div>
    ));

    arrData.push(title);
    if (selOptionCuerpo.length > 0) {
      count += 1;
    }
    if (count > 0) {
      setEstrella1(arrData);
    }
  }, []);

  function hideModal() {
    setModal(null);
  }

  const theme1 = {
    axis: {
      fontSize: "3px",

      domain: {
        line: {
          stroke: "#959595",
          strokeWidth: 0.5,
        },
      },
      ticks: {
        line: {
          stroke: "#959595",
          strokeWidth: 0.5,
        },
        text: {
          fill: "#555555",
        },
      },
      legend: {
        text: {
          fontSize: laptop575.matches ? "1vw" : "3vw",
          fill: "#2A9DA5",
          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "#ffffff",

        strokeWidth: 1,
      },
    },
  };

  function zoomphoto(dat, indice, valor, subiobajo, valorgeneral) {
    setModal(
      <ModalInfoGraphics
        data={dat}
        img={subiobajo >= 0 ? subio : bajo}
        valor_subio_bajo={subiobajo}
        valorgeneral={valorgeneral}
        img2={agrandar}
        dateObjetivo={valor}
        nameObjetivo={indice}
        handleClick={hideModal}
      />
    );
  }
  function dejarsolonumber(x) {
    if (x < 0) {
      return x * -1;
    } else {
      return x;
    }
  }

  return (
    <div className=" positionitemscrol">
      {" "}
      {laptop575.matches ? (
        <></>
      ) : (
        <>
          <div className="titulo mt-1"> Conoce tu cuerpo</div>
          <div className=" borderlineiconos mb-3 mx-auto "></div>
          <img className="d-flex m-auto" src={gramera} width="40vw" />
          <div className="titulopeso">
            {" "}
            {pesoActual && pesoActual[0] > 0
              ? pesoActual[0].toFixed(1)
              : data_user.customer.weight}{" "}
            Kg
          </div>
        </>
      )}
      {laptop575.matches ? (
        <SwiperSlide
          className={
            laptop575.matches ? "bitacoraSlider col-12  " : "bitacoraSlider "
          }
        >
          {laptop575.matches ? (
            <div className="d-flex " style={{ marginLeft: "12%" }}>
              <div className="bitacoraForm Medida row col-6 mx-auto">
                <div className=" relativegramera   ">
                  <div
                    className=" d-flex  "
                    style={{
                      background: "#7B86FA",
                      width: "90%",

                      justifyContent: "left",
                      marginLeft: "0.5vw",
                    }}
                  >
                    <div
                      className="w-50 d-flex justify-content-center   "
                      style={{
                        borderRight: "0.1rem solid rgba(249, 251, 251)",
                        margin: "0.1rem 0 ",
                      }}
                    >
                      <div className="titulopeso ">
                        {" "}
                        Peso <br /> actual
                      </div>

                      <div
                        className=" mx-2  "
                        style={{
                          alignItems: "center",
                          justifyContent: "center",

                          margin: "0.8vh 3vw 0 0",
                        }}
                      >
                        {" "}
                        <img
                          src={gramera1}
                          width="30vw"
                          style={{ margin: "0 1vw" }}
                        />
                        <div className="titulopeso1 text-center">
                          {" "}
                          {pesoActual
                            ? pesoActual[0].toFixed(1) + " Kg"
                            : " ___"}
                        </div>
                      </div>
                    </div>
                    <div className="w-50 d-flex justify-content-center ">
                      <div className="titulopeso">
                        {" "}
                        Peso <br /> objetivo
                      </div>
                      <div
                        className=" mx-2 "
                        style={{
                          alignItems: "center",
                          justifyContent: "center",

                          margin: "0.8vh 3vw 0 0",
                        }}
                      >
                        {" "}
                        <img
                          src={gramera1}
                          width="30vw"
                          style={{ margin: "0 1vw" }}
                        />
                        <div className="titulopeso1 text-center">
                          {" "}
                          {data_user.customer.optimal_weight
                            ? data_user.customer.optimal_weight.min.toFixed(1) +
                              "-" +
                              data_user.customer.optimal_weight.max.toFixed(1) +
                              " Kg"
                            : " ___"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="containerCuerpo "
                    style={{ display: "block" }}
                  >
                    {/* {botonesMedidas} */}
                    <div className="flexiohnar">
                      <div className="mx-auto pt-3 siluetaMedidas">
                        <div className="brazoderecho">
                          <img src={brazoderecho} className="brazo_web" />
                          <div className="titulo3 ">Brazo Derecho</div>
                          <div className="row   ">
                            <div
                              className="letrasbrazoderecho1 "
                              style={{ marginLeft: "-15%", lineHeight: "70%" }}
                            >
                              <div className="d-flex">
                                {Array.isArray(brazodegrasa) ? (
                                  <div className=" d-row ">
                                    {" "}
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          brazodegrasa[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          brazode[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                  </div>
                                ) : null}{" "}
                                <div>
                                  <div className="titulo4 ">
                                    Grasa corporal:
                                    {Array.isArray(brazodegrasa)
                                      ? dejarsolonumber(
                                          brazodegrasa[0].toFixed(1)
                                        )
                                      : brazodegrasa.toFixed(1)}{" "}
                                    %
                                  </div>
                                  <div className="titulo4 ">
                                    Masa muscular:{" "}
                                    {Array.isArray(brazode)
                                      ? dejarsolonumber(brazode[0].toFixed(1))
                                      : brazode.toFixed(1)}
                                    Kg
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="brazoizquierdo">
                          <img
                            src={brazoderecho}
                            className="brazoderecho_web"
                          />
                          <div className="titulo3 ayudadlo_titulo3 ">
                            Brazo izquierdo
                          </div>
                          <div className="row row-cols-2  ">
                            <div
                              className="letrasbrazoderecho1 "
                              style={{ marginLeft: "-5%", lineHeight: "70%" }}
                            >
                              <div className="d-flex">
                                {" "}
                                <div>
                                  {" "}
                                  <div className="titulo4 ">
                                    Grasa corporal:
                                    {Array.isArray(brazoizgrasa)
                                      ? dejarsolonumber(
                                          brazoizgrasa[0].toFixed(1)
                                        )
                                      : brazoizgrasa.toFixed(1)}
                                    %
                                  </div>
                                  <div className="titulo4 ">
                                    Masa muscular:
                                    {Array.isArray(brazoiz)
                                      ? dejarsolonumber(brazoiz[0].toFixed(1))
                                      : brazoiz.toFixed(1)}
                                    Kg
                                  </div>
                                </div>
                                {Array.isArray(brazoizgrasa) ? (
                                  <div className=" d-row ">
                                    {" "}
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          brazoizgrasa[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          brazoiz[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="abdomen">
                          <img src={abdomen} className="abdomen_web" />
                          <div className="titulo3 ">Abdomen</div>
                          <div className="row row-cols-2  ">
                            <div
                              className="letrasbrazoderecho1 "
                              style={{ marginLeft: "-15%", lineHeight: "70%" }}
                            >
                              <div className="d-flex">
                                {Array.isArray(abdomenpgrasa) ? (
                                  <div className=" d-row ">
                                    {" "}
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          abdomenpgrasa[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          abdomenp[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div>
                                  <div className="titulo4 ">
                                    Grasa corporal:{" "}
                                    {Array.isArray(abdomenpgrasa)
                                      ? dejarsolonumber(
                                          abdomenpgrasa[0].toFixed(1)
                                        )
                                      : abdomenpgrasa.toFixed(1)}
                                    %
                                  </div>
                                  <div className="titulo4 ">
                                    Masa muscular:
                                    {Array.isArray(abdomenp)
                                      ? dejarsolonumber(abdomenp[0].toFixed(1))
                                      : abdomenp.toFixed(1)}
                                    Kg
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="piernaderecha">
                          {" "}
                          <img
                            src={piernaderecha}
                            className="piernaizquierda_web"
                          />
                          <div className="titulo3 ayudadlo_titulo6">
                            {" "}
                            Pierna izquierda
                          </div>
                          <div className="row row-cols-2  ">
                            <div
                              className="letrasbrazoderecho1  "
                              style={{ marginLeft: "-55%", lineHeight: "70%" }}
                            >
                              <div className="d-flex">
                                {" "}
                                <div>
                                  <div className="titulo4 ">
                                    Grasa corporal:{" "}
                                    {Array.isArray(piernaizgrasa)
                                      ? dejarsolonumber(
                                          piernaizgrasa[0].toFixed(1)
                                        )
                                      : piernaizgrasa.toFixed(1)}
                                    %{" "}
                                  </div>
                                  <div>
                                    {" "}
                                    <div className="titulo4 ">
                                      Masa muscular:{" "}
                                      {Array.isArray(piernaiz)
                                        ? dejarsolonumber(
                                            piernaiz[0].toFixed(1)
                                          )
                                        : piernaiz.toFixed(1)}
                                      Kg{" "}
                                    </div>
                                  </div>
                                </div>
                                {Array.isArray(piernaizgrasa) ? (
                                  <div className=" d-row ">
                                    {" "}
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          piernaizgrasa[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          piernaiz[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="piernaizquierda">
                          <img
                            src={piernaderecha}
                            className="piernadercha_web"
                          />
                          <div className="titulo3 ">Pierna derecha</div>
                          <div className="row row-cols-2  ">
                            <div
                              className="letrasbrazoderecho1 "
                              style={{ marginLeft: "-15%", lineHeight: "70%" }}
                            >
                              <div className="d-flex">
                                {Array.isArray(piernadegrasa) ? (
                                  <div className=" d-row ">
                                    {" "}
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          piernadegrasa[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                    <div className="m-auto p-auto  ">
                                      {" "}
                                      <img
                                        src={
                                          piernade[0] >= 0
                                            ? flechasubioverde
                                            : flechabajored
                                        }
                                        style={{ width: "0.7rem" }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <div>
                                  {" "}
                                  <div className="titulo4 ">
                                    Grasa corporal:{" "}
                                    {Array.isArray(piernadegrasa)
                                      ? dejarsolonumber(
                                          piernadegrasa[0].toFixed(1)
                                        )
                                      : piernadegrasa.toFixed(1)}
                                    %
                                  </div>
                                  <div className="titulo4 ">
                                    Masa muscular:{" "}
                                    {Array.isArray(piernade)
                                      ? dejarsolonumber(piernade[0].toFixed(1))
                                      : piernade.toFixed(1)}
                                    Kg
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <Silueta className="mx-auto d-flex " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <input type="hidden" value={localStorage.my_code} name="data" />
                <input type="hidden" value="-1" name="pos" />
              </div>
              <div className={laptop575.matches ? "d-flex col-6" : null}>
                <div className="acordeonbum  ">
                  {!estrella1[0]
                    ? null
                    : estrella1[0].map((acordeon, indice) => (
                        <AccordionItem
                          title={acordeon}
                          key={indice}
                          item={indice}
                          clase="quitarcuadro"
                        >
                          <div className="lomores">
                            <div className=" d-flex  jc-center kilogramos ">
                              <div className=" d-flex m-auto p-auto jc-center  letrasviscerasadentro">
                                {selOptionCuerpo[indice][3]}
                              </div>

                              <div className="d-flex m-auto p-auto jc-center  letrasvisceras">
                                {selOptionCuerpo[indice][5]}
                              </div>
                              <div style={{ margin: "1vh 3vw" }}>
                                <img
                                  height="13vh"
                                  className=" flechakilogramos"
                                  src={
                                    selOptionCuerpo[indice][5] >= 0
                                      ? subio
                                      : bajo
                                  }
                                />
                              </div>
                              <img
                                src={agrandar}
                                className="imagenexpanded"
                                onClick={() =>
                                  zoomphoto(
                                    selOptionCuerpo[indice][4],
                                    indice,
                                    selOptionCuerpo[indice][2],
                                    selOptionCuerpo[indice][5],
                                    selOptionCuerpo[indice][3]
                                  )
                                }
                              />
                            </div>
                            <div>
                              <div
                                style={{
                                  height: 320,
                                  marginBottom: "0vh",
                                  width: "35vw",
                                  marginLeft: "0%",
                                }}
                              >
                                <ResponsiveLine
                                  data={selOptionCuerpo[indice][4]}
                                  margin={{
                                    top: 50,
                                    right: 28,
                                    bottom: 80,
                                    left: 28,
                                  }}
                                  xScale={{ type: "point" }}
                                  yScale={{
                                    type: "linear",
                                    min: "auto",
                                    max: "auto",

                                    reverse: false,
                                  }}
                                  axisTop={null}
                                  axisRight={null}
                                  enableGridX={false}
                                  lineWidth={1}
                                  axisBottom={{
                                    orient: "bottom",
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "",
                                    legendOffset: 36,
                                    legendPosition: "middle",
                                  }}
                                  axisLeft={{
                                    orient: "left",
                                    tickSize: 4,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendOffset: -40,
                                    legendPosition: "middle",
                                  }}
                                  colors={["#CB3D82"]}
                                  theme={theme1}
                                  pointSize={9}
                                  pointColor={"#ffffff"}
                                  pointBorderWidth={2}
                                  pointBorderColor={{ from: "serieColor" }}
                                  pointLabel="y"
                                  enableArea={true}
                                  areaBaselineValue={
                                    selOptionCuerpo[indice][4][0].data
                                      .map((e, i) => e.y)
                                      .sort()[0]
                                  }
                                  areaOpacity={0.3}
                                  defs={[
                                    {
                                      id: "gradientC",

                                      type: "linearGradient",

                                      colors: [
                                        { offset: 15, color: "#9EACED" },

                                        { offset: 100, color: "#ffff" },
                                      ],
                                    },
                                  ]}
                                  fill={[{ match: "*", id: "gradientC" }]}
                                  useMesh={true}
                                />
                              </div>
                              {indice == 3 ||
                              indice == 11 ||
                              indice == 12 ? null : (
                                <Estandarimagenes
                                  nameObjetivo={indice}
                                  dateObjetivo={selOptionCuerpo[indice][2]}
                                />
                              )}
                            </div>
                          </div>
                        </AccordionItem>
                      ))}
                </div>
              </div>
              {modal}
            </div>
          ) : (
            <div className="containerCuerpo " style={{ display: "block" }}>
              {/* {botonesMedidas} */}
              {/* <div className="flexiohnar">
                <div className="mx-auto pt-3 siluetaMedidas">
                  <div className="brazoderecho">
                    <img src={brazoderecho} className="brazo_web" />
                    <div className="titulo3 ">
                      Brazo Derechooooooooooooooooooo
                    </div>
                    <div className="row row-cols-2  ">
                      <div>
                        <img
                          src={flechasarriba}
                          className="letrasbrazoderecho1img "
                        />
                      </div>
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          Grasa corporal:{brazodegrasa.toFixed(1)}%
                        </div>
                        <div className="titulo4 ">
                          Masa muscular:{brazode.toFixed(1)}Kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="brazoizquierdo">
                    <img src={brazoderecho} className="brazoderecho_web" />
                    <div className="titulo3 ayudadlo_titulo3 ">
                      Brazo izquierdo
                    </div>
                    <div className="row row-cols-2  ">
                      <div>
                        <img
                          src={flechasarriba}
                          className="letrasbrazoderecho1img "
                        />
                      </div>
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          Grasa corporal:{brazoizgrasa.toFixed(1)}%
                        </div>
                        <div className="titulo4 ">
                          Masa muscular:{brazoiz.toFixed(1)}Kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="abdomen">
                    <img src={abdomen} className="abdomen_web" />
                    <div className="titulo3 ">Abdomen</div>
                    <div className="row row-cols-2  ">
                      <div>
                        <img
                          src={flechasarriba}
                          className="letrasbrazoderecho1img "
                        />
                      </div>
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          Grasa corporal:{abdomenpgrasa.toFixed(1)}%
                        </div>
                        <div className="titulo4 ">
                          Masa muscular:{abdomenp.toFixed(1)}Kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="piernaderecha">
                    <img src={piernaderecha} className="piernaizquierda_web" />
                    <div className="titulo3 ayudadlo_titulo3">
                      Pierna izquierda
                    </div>
                    <div
                      className="row row-cols-2  "
                      style={{ display: "inline-flex" }}
                    >
                      <div>
                        <img
                          src={flechasarriba}
                          className="letrasbrazoderecho1img  "
                        />
                      </div>
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          Grasa corporal:{piernaizgrasa.toFixed(1)}%
                        </div>
                        <div className="titulo4 ">
                          Masa muscular:{piernaiz.toFixed(1)}Kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="piernaizquierda">
                    <img src={piernaderecha} className="piernadercha_web" />
                    <div className="titulo3 ">Pierna derecha</div>
                    <div className="row row-cols-2  ">
                      <div>
                        <img
                          src={flechasarriba}
                          className="letrasbrazoderecho1img "
                        />
                      </div>
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          Grasa corporal:{piernadegrasa.toFixed(1)}%
                        </div>
                        <div className="titulo4 ">
                          Masa muscular:{piernade.toFixed(1)}Kg
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {laptop575.matches ? null : (
                <div className="titulo2 ">
                  Objetivo:{" "}
                  {data_user.customer.optimal_weight
                    ? data_user.customer.optimal_weight.min.toFixed(2) +
                      "-" +
                      data_user.customer.optimal_weight.max.toFixed(2) +
                      " Kg"
                    : " ___"}
                </div>
              )}
            </div>
          )}
          {/* <div className=" borderlineiconos  mx-auto "></div> */}
        </SwiperSlide>
      ) : (
        <SwiperSlide className="bitacoraSlider ">
          <form
            className="bitacoraForm Medida row row-cols-2 mx-auto"
            // ref={infoBitacora[idx][2]}
            // onSubmit={(e) => e.preventDefault()}
          >
            <div className="containerCuerpo " style={{ display: "block" }}>
              {/* {botonesMedidas} */}
              <div className="flexiohnar">
                <div className="mx-auto pt-3 siluetaMedidas">
                  <div className="brazoderecho">
                    <img src={brazoderecho} className="brazo_web" />
                    <div className="titulo3 ">Brazo Derecho</div>
                    <div
                      className="letrasbrazoderecho1 "
                      style={{ marginLeft: "1rem" }}
                    >
                      <div className="titulo4 ">
                        {Array.isArray(brazodegrasa) ? (
                          <img
                            src={
                              brazodegrasa[0] >= 0
                                ? flechasubioverde
                                : flechabajored
                            }
                            style={{ width: "0.6rem" }}
                          />
                        ) : null}
                        Grasa corporal:{" "}
                        {Array.isArray(brazodegrasa)
                          ? dejarsolonumber(brazodegrasa[0].toFixed(1))
                          : brazodegrasa.toFixed(1)}
                        %
                      </div>
                      <div className="titulo4 ">
                        {Array.isArray(brazode) ? (
                          <img
                            src={
                              brazode[0] >= 0 ? flechasubioverde : flechabajored
                            }
                            style={{ width: "0.6rem" }}
                          />
                        ) : null}
                        Masa muscular:{" "}
                        {Array.isArray(brazode)
                          ? dejarsolonumber(brazode[0].toFixed(1))
                          : brazode.toFixed(1)}
                        Kg
                      </div>
                    </div>
                  </div>
                  <div className="brazoizquierdo">
                    <img src={brazoderecho} className="brazoderecho_web" />
                    <div className="titulo3 ayudadlo_titulo3 ">
                      Brazo izquierdo
                    </div>
                    <div className="row row-cols-2  ">
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          {Array.isArray(brazoizgrasa) ? (
                            <img
                              src={
                                brazoizgrasa[0] >= 0
                                  ? flechasubioverde
                                  : flechabajored
                              }
                              style={{ width: "0.6rem" }}
                            />
                          ) : null}
                          Grasa corporal:{" "}
                          {Array.isArray(brazoizgrasa)
                            ? dejarsolonumber(brazoizgrasa[0].toFixed(1))
                            : brazoizgrasa.toFixed(1)}
                          %
                        </div>
                        <div className="titulo4 ">
                          {Array.isArray(brazoiz) ? (
                            <img
                              src={
                                brazoiz[0] >= 0
                                  ? flechasubioverde
                                  : flechabajored
                              }
                              style={{ width: "0.6rem" }}
                            />
                          ) : null}{" "}
                          Masa muscular:{" "}
                          {Array.isArray(brazoiz)
                            ? dejarsolonumber(brazoiz[0].toFixed(1))
                            : brazoiz.toFixed(1)}
                          Kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="abdomen">
                    <img src={abdomen} className="abdomen_web" />
                    <div className="titulo3 ">Abdomen</div>
                    <div className="row row-cols-2  ">
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          {Array.isArray(abdomenpgrasa) ? (
                            <img
                              src={
                                abdomenpgrasa[0] >= 0
                                  ? flechasubioverde
                                  : flechabajored
                              }
                              style={{ width: "0.6rem" }}
                            />
                          ) : null}{" "}
                          Grasa corporal:{" "}
                          {Array.isArray(abdomenpgrasa)
                            ? dejarsolonumber(abdomenpgrasa[0].toFixed(1))
                            : abdomenpgrasa.toFixed(1)}
                          %
                        </div>
                        <div className="titulo4 ">
                          {Array.isArray(abdomenp) ? (
                            <img
                              src={
                                abdomenp[0] >= 0
                                  ? flechasubioverde
                                  : flechabajored
                              }
                              style={{ width: "0.6rem" }}
                            />
                          ) : null}{" "}
                          Masa muscular:{" "}
                          {Array.isArray(abdomenp)
                            ? dejarsolonumber(abdomenp[0].toFixed(1))
                            : abdomenp.toFixed(1)}
                          Kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="piernaderecha">
                    <img src={piernaderecha} className="piernaizquierda_web" />
                    <div className="titulo3 ayudadlo_titulo3">
                      Pierna izquierda
                    </div>
                    <div className="row row-cols-2  ">
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          {Array.isArray(piernaizgrasa) ? (
                            <img
                              src={
                                piernaizgrasa[0] >= 0
                                  ? flechasubioverde
                                  : flechabajored
                              }
                              style={{ width: "0.6rem" }}
                            />
                          ) : null}{" "}
                          Grasa corporal:{" "}
                          {Array.isArray(piernaizgrasa)
                            ? dejarsolonumber(piernaizgrasa[0].toFixed(1))
                            : piernaizgrasa.toFixed(1)}
                          %
                        </div>
                        <div className="titulo4 ">
                          {Array.isArray(piernaiz) ? (
                            <img
                              src={
                                piernaiz[0] >= 0
                                  ? flechasubioverde
                                  : flechabajored
                              }
                              style={{ width: "0.6rem" }}
                            />
                          ) : null}{" "}
                          Masa muscular:{" "}
                          {Array.isArray(piernaiz)
                            ? dejarsolonumber(piernaiz[0].toFixed(1))
                            : piernaiz.toFixed(1)}
                          Kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="piernaizquierda">
                    <img src={piernaderecha} className="piernadercha_web" />
                    <div className="titulo3 ">Pierna derecha</div>
                    <div className="row row-cols-2  ">
                      <div className="letrasbrazoderecho1 ">
                        <div className="titulo4 ">
                          {Array.isArray(piernadegrasa) ? (
                            <img
                              src={
                                piernadegrasa[0] >= 0
                                  ? flechasubioverde
                                  : flechabajored
                              }
                              style={{ width: "0.6rem" }}
                            />
                          ) : null}{" "}
                          Grasa corporal:{" "}
                          {Array.isArray(piernadegrasa)
                            ? dejarsolonumber(piernadegrasa[0].toFixed(1))
                            : piernadegrasa.toFixed(1)}
                          %
                        </div>
                        <div className="titulo4 ">
                          {Array.isArray(piernade) ? (
                            <img
                              src={
                                piernade[0] >= 0
                                  ? flechasubioverde
                                  : flechabajored
                              }
                              style={{ width: "0.6rem" }}
                            />
                          ) : null}
                          Masa muscular:{" "}
                          {Array.isArray(piernade)
                            ? dejarsolonumber(piernade[0].toFixed(1))
                            : piernade.toFixed(1)}
                          Kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Silueta className="mx-auto d-flex silueta12" />
                    <div className="relativegramera ">
                      <div className="ubicarpesoencuerpo">
                        <div className="titulopeso"> Peso actual</div>
                        <img
                          className="d-flex m-auto"
                          src={gramera}
                          width="40vw"
                        />
                        <div className="titulopeso1">
                          {" "}
                          {data_user.customer.optimal_weight
                            ? data_user.customer.optimal_weight.min.toFixed(0) +
                              "-" +
                              data_user.customer.optimal_weight.max.toFixed(0) +
                              " Kg"
                            : " ___"}
                        </div>
                        <div
                          className=" borderlineiconos mx-auto mb-3  "
                          style={{ width: "7vw" }}
                        ></div>
                      </div>
                      <div className="ubicarpesoencuerpo1">
                        <div className="titulopeso"> Peso objetivo</div>
                        <img
                          className="d-flex m-auto"
                          src={gramera}
                          width="40vw"
                        />
                        <div className="titulopeso1"> ??? Kg</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="titulo2 ">
                Objetivo:{" "}
                {data_user.customer.optimal_weight
                  ? data_user.customer.optimal_weight.min.toFixed(2) +
                    "-" +
                    data_user.customer.optimal_weight.max.toFixed(2) +
                    " Kg"
                  : " ___"}
              </div>
            </div>
            <input type="hidden" value={localStorage.my_code} name="data" />
            <input type="hidden" value="-1" name="pos" />
          </form>
          {modal}
          <div className={laptop575.matches ? "d-flex" : null}>
            <div className="acordeonbum  ">
              {!estrella1[0]
                ? null
                : estrella1[0].map((acordeon, indice) => (
                    <AccordionItem
                      title={acordeon}
                      key={indice}
                      item={indice}
                      clase="quitarcuadro"
                    >
                      <div className="lomores">
                        <div className=" d-flex  jc-center kilogramos ">
                          <div className=" d-flex m-auto p-auto jc-center  letrasviscerasadentro">
                            {selOptionCuerpo[indice][3]}
                          </div>

                          <div className="d-flex m-auto p-auto jc-center  letrasvisceras">
                            {selOptionCuerpo[indice][5]}
                          </div>
                          <div style={{ margin: "1vh 3vw" }}>
                            <img
                              height="13vh"
                              className=" flechakilogramos"
                              src={
                                selOptionCuerpo[indice][5] >= 0 ? subio : bajo
                              }
                            />
                          </div>
                          <img
                            src={agrandar}
                            className="imagenexpanded"
                            onClick={() =>
                              zoomphoto(
                                selOptionCuerpo[indice][4],
                                indice,
                                selOptionCuerpo[indice][2],
                                selOptionCuerpo[indice][5],
                                selOptionCuerpo[indice][3]
                              )
                            }
                          />
                        </div>
                        <div>
                          <div
                            style={{
                              height: 250,
                              marginBottom: "0vh",
                            }}
                          >
                            <ResponsiveLine
                              data={selOptionCuerpo[indice][4]}
                              margin={{
                                top: 50,
                                right: 28,
                                bottom: 80,
                                left: 28,
                              }}
                              xScale={{ type: "point" }}
                              yScale={{
                                type: "linear",
                                min: "auto",
                                max: "auto",

                                reverse: false,
                              }}
                              axisTop={null}
                              axisRight={null}
                              enableGridX={false}
                              lineWidth={1}
                              axisBottom={{
                                orient: "bottom",
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "",
                                legendOffset: 36,
                                legendPosition: "middle",
                              }}
                              axisLeft={{
                                orient: "left",
                                tickSize: 4,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendOffset: -40,
                                legendPosition: "middle",
                              }}
                              colors={["#CB3D82"]}
                              theme={theme1}
                              pointSize={9}
                              pointColor={"#ffffff"}
                              pointBorderWidth={2}
                              pointBorderColor={{ from: "serieColor" }}
                              pointLabel="y"
                              enableArea={true}
                              areaBaselineValue={
                                selOptionCuerpo[indice][4][0].data
                                  .map((e, i) => e.y)
                                  .sort()[0]
                              }
                              areaOpacity={0.3}
                              defs={[
                                {
                                  id: "gradientC",

                                  type: "linearGradient",

                                  colors: [
                                    { offset: 15, color: "#9EACED" },

                                    { offset: 100, color: "#ffff" },
                                  ],
                                },
                              ]}
                              fill={[{ match: "*", id: "gradientC" }]}
                              useMesh={true}
                            />
                          </div>
                          {indice == 3 ||
                          indice == 11 ||
                          indice == 12 ? null : (
                            <Estandarimagenes
                              nameObjetivo={indice}
                              dateObjetivo={selOptionCuerpo[indice][2]}
                            />
                          )}
                        </div>
                      </div>
                    </AccordionItem>
                  ))}
            </div>
          </div>

          {/* <div className=" borderlineiconos  mx-auto "></div> */}
        </SwiperSlide>
      )}
    </div>
  );
};
const Estadisticas = (props) => {
  const moment = extendMoment(Moment);
  const day_hoy = moment();
  const inicioday =
    day_hoy.subtract(day_hoy.day(), "days").format().slice(0, 11) + "00:00";
  const findesemana =
    day_hoy
      .add(6 - day_hoy.day(), "days")
      .format()
      .slice(0, 11) + "00:00";

  // console.log(inicioday, findesemana);
  const actualView = props.actualView;
  const estrellas = props.estrella;
  const nivel = props.nivel;

  const objetives_user = props.objetives_user;
  console.log(objetives_user);
  const slidersInfo = [
    ["Nivel", null],
    ["Graficas", null],
    ["Información total", null],
  ];

  const [infoSemana, setSemana] = useState({
    calorias: null,
    tiempo: null,
    clases: null,
  });
  const [infoMes, setMes] = useState({
    calorias: null,
    tiempo: null,
    clases: null,
  });
  const [infoTotal, setTotal] = useState({
    calorias: null,
    tiempo: null,
    clases: null,
  });
  const [infoDias, setDias] = useState(null);
  // console.log("............................", infoDias);
  const [isLoaded, setIsLoaded] = useState(false);
  const [alllevels, setalllevels] = useState(null);
  // console.log(alllevels);
  useEffect(() => {
    instance
      .get(`booking/?any&date__lte=${findesemana}&date__gte=${inicioday}`)

      .then((res) => {
        // console.log(res.data);
        const daysselected = res.data.filter((e) => e.room_id != "");
        if (daysselected != null) {
          setDias(filterCheckDays(daysselected));
        }
      });
  }, []);
  useEffect(() => {
    const levels = [];
    const getData = async () => {
      await instance.get(`customers/level/`).then(async (res) => {
        const daysselected = res.data;
        // console.log(daysselected);
        if (daysselected) {
          for (const i of daysselected) {
            const identrenamiento = i.training;
            const lev = i.level;
            const uuid = i.id;
            const sublev = i.sublevel;
            const current_sesio = i.current_session;
            await instance.get(`trainings/${identrenamiento}/`).then((res) => {
              let info = res.data;
              if (info && info.group == false) {
                // console.log(info);
                levels[identrenamiento] = {
                  id: identrenamiento,
                  uuid: uuid,
                  tiene_valoration: info.is_valoration,
                  titulo: info.name.toLowerCase(),
                  descripcion: info.description,
                  level: lev,
                  subnivel: sublev,
                  current_session: current_sesio,
                };
              }
            });
          }

          //
        }
      });
      // console.log(levels);
      setalllevels(levels.filter((e) => e && e.tiene_valoration == false));
    };
    getData();
  }, []);

  // console.log(infoDias);
  const [ModalDesempeño, setModalDesempeño] = useState(null);
  const mostrarmodal = (idx) => {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 99999999,
      whiteSpace: "pre-line",
    };

    setModal1(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido ">
          <div className="icon my-2 h-4">
            <img className="imagenx" src={xfinal} alt="cara" />
          </div>
          <div className="title">¿Deseas ocultar este registro?</div>
          <div className={laptop575.matches ? "d-flex" : null}>
            <div
              className="btnAction letraspopfi1"
              onClick={() => eliminartarea(idx)}
            >
              <ButtonMob
                text="Ocultar registro"
                className="mt-3 mb-3 btn-danger letraspopfi"
              />
            </div>
            <br />
            <div className="btnAction letraspopfi2" onClick={hideModal}>
              <ButtonMob text="Cancelar" className="mb-3 letraspopfi" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const mostrarmodalDesempeño = (idx) => {
    // console.log(alllevels);
    const onelevel = alllevels.filter((e) => e.uuid == idx);
    const nivelcliente = onelevel[0].current_session;
    const sublev = onelevel[0].subnivel;
    // console.log(nivelcliente);
    const nameL = onelevel[0].titulo;
    const Level =
      nivelcliente > 84
        ? "Avanzado"
        : nivelcliente < 53 && nivelcliente > 1
        ? "Principiante"
        : "Intermedio";

    const NiveldeUsuarios = NiveldeUsuario[Level]["NiveldeDificultad"];
    const leve_up = sublev;
    // console.log("NiveldeUsuario", Object.entries(NiveldeUsuarios));

    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 99999999,
      whiteSpace: "pre-line",
    };
    const cuantoleFalta =
      4 -
      Object.entries(NiveldeUsuarios)
        .filter((e) => e[1].includes(nivelcliente))[0][1]
        .indexOf(nivelcliente);
    if (
      (leve_up == 14 && nivelcliente == 53) ||
      (leve_up == 22 && nivelcliente == 85)
    ) {
      setModalDesempeño(
        <div
          className="modalInfo justify-content-center align-items-center"
          style={styleModal}
        >
          <div className="modalInfoContenido ">
            <div className="icon my-2 h-4">
              <img
                style={{
                  backgroundSize: "3px",
                  position: "absolute",
                  top: "6%",
                  cursor: "pointer",
                  right: "6%",
                }}
                className="imagenx2"
                src={equis}
                alt="avanzanivel"
                onClick={hideModalDesempeño}
              />
            </div>
            <div className="icon my-2 h-4">
              <img
                className="imagenx1"
                src={avanzanivelmax}
                alt="avanzanivel"
              />
            </div>
            <div className="title" style={{ fontWeight: "700" }}>
              Felicidades atleta <br /> ¡alcanzaste un nuevo nivel <br /> en tu
              disciplina!
            </div>
            <div className="msg">Nos complace decirte que ahora eres</div>
            <br />
            <div
              className="title"
              style={{
                textTransform: "uppercase",
                color: "#CB3D82",
                fontWeight: "700",
              }}
            >
              Nivel {Level} <br /> en {nameL}
            </div>
            <div className="d-flex px-4">
              <div
                className="title"
                style={
                  laptop575.matches ? { fontSize: "3vw" } : { fontSize: "11vw" }
                }
              ></div>
              <div className="py-3 ">
                <img
                  src={Level == "Intermedio" ? levelupintermediate : levelupi}
                  style={
                    laptop575.matches
                      ? { width: "17rem", padding: "0 3vw" }
                      : { width: "7rem", padding: "0 3vw" }
                  }
                />
              </div>
              <div
                className="title"
                style={
                  laptop575.matches ? { fontSize: "5vw" } : { fontSize: "11vw" }
                }
              ></div>
            </div>
            <div className={laptop575.matches ? "d-flex" : null}>
              <div
                className="m-auto"
                style={
                  laptop575.matches
                    ? {
                        fontWeight: "700",
                        fontSize: "2vw",
                        color: "#394F65",
                      }
                    : {
                        fontWeight: "700",
                        fontSize: "5.5vw",
                        color: "#394F65",
                      }
                }
              >
                NO PARES!
              </div>
              <br />
            </div>
          </div>
        </div>
      );
    } else if (cuantoleFalta == 4) {
      setModalDesempeño(
        <div
          className="modalInfo justify-content-center align-items-center"
          style={styleModal}
        >
          <div className="modalInfoContenido ">
            <div className="icon my-2 h-4">
              <img
                style={{
                  backgroundSize: "3px",
                  position: "absolute",
                  top: "6%",
                  cursor: "pointer",
                  right: "6%",
                }}
                className="imagenx2"
                src={equis}
                alt="avanzanivel"
                onClick={hideModalDesempeño}
              />
            </div>
            <div className="icon my-2 h-4">
              <img className="imagenx1" src={avanzanivel} alt="avanzanivel" />
            </div>
            <div className="title" style={{ fontWeight: "700" }}>
              Felicidades atleta <br />
              ¡avanzaste de nivel!
            </div>

            <div className="d-flex px-4">
              <div
                className="title"
                style={
                  laptop575.matches
                    ? { fontSize: "3vw", color: "#CB3D82", fontWeight: "700" }
                    : { fontSize: "11vw", color: "#CB3D82", fontWeight: "700" }
                }
              >
                {parseFloat(leve_up)}
              </div>
              <div className="py-3 ">
                <img
                  src={twoflechas}
                  style={
                    laptop575.matches
                      ? { width: "3vw", padding: "1vw" }
                      : { width: "12vw", padding: "3vw" }
                  }
                />
              </div>
              <div
                className="title"
                style={
                  laptop575.matches
                    ? { fontSize: "3vw", color: "#CB3D82", fontWeight: "700" }
                    : { fontSize: "11vw", color: "#CB3D82", fontWeight: "700" }
                }
              >
                {parseFloat(leve_up) + parseFloat(1)}
              </div>
            </div>
            <div
              className={laptop575.matches ? "d-flex " : null}
              style={laptop575.matches ? { margin: "auto 2.5vw" } : null}
            >
              <div
                style={
                  laptop575.matches
                    ? {
                        fontWeight: "700",
                        fontSize: "2vw",
                        color: "#394F65",
                      }
                    : {
                        fontWeight: "700",
                        fontSize: "5.5vw",
                        color: "#394F65",
                      }
                }
              >
                NO PARES!
              </div>
              <br />
            </div>
          </div>
        </div>
      );
    } else {
      setModalDesempeño(
        <div
          className="modalInfo justify-content-center align-items-center"
          style={styleModal}
        >
          <div className="modalInfoContenido ">
            <div className="icon my-2 h-4">
              <img
                style={{
                  backgroundSize: "3px",
                  position: "absolute",
                  top: "6%",
                  right: "6%",
                }}
                className="imagenx2"
                src={equis}
                alt="avanzanivel"
                onClick={hideModalDesempeño}
              />
            </div>
            <div className="icon my-2 h-4">
              <img className="imagenx1" src={sigueasi} alt="avanzanivel" />
            </div>
            <div className="title" style={{ fontWeight: "700" }}>
              Vas muy bien <br /> ¡sigue así!
            </div>
            <div className="msg">
              Te faltan {cuantoleFalta} clases más <br /> para llegar a nivel{" "}
              {parseFloat(leve_up) + parseFloat(1)}
              ¡ANIMO!
            </div>
            <div className="d-flex px-4">
              <div
                className="title"
                style={
                  laptop575.matches
                    ? { fontSize: "5vw", color: "#CB3D82" }
                    : { fontSize: "11vw", color: "#CB3D82" }
                }
              >
                {parseFloat(leve_up)}
              </div>
              <div className="py-3 ">
                <img
                  src={twoflechas}
                  style={
                    laptop575.matches
                      ? { width: "5vw", padding: "1vw" }
                      : { width: "12vw", padding: "3vw" }
                  }
                />
              </div>
              <div
                className="title"
                style={
                  laptop575.matches
                    ? { fontSize: "5vw", color: "#CB3D82", fontWeight: "700" }
                    : { fontSize: "11vw", color: "#CB3D82", fontWeight: "700" }
                }
              >
                {parseFloat(leve_up) + parseFloat(1)}
              </div>
            </div>
            <div className={laptop575.matches ? "d-flex" : null}>
              <br />
            </div>
          </div>
        </div>
      );
    }
  };
  const hideModal = () => {
    setModal1(null);
  };
  const hideModalDesempeño = () => {
    setModalDesempeño(null);
  };
  const cerrarDesempeño = () => {
    hideModalDesempeño();
  };

  const eliminartarea = (idx) => {
    const borrar1 = document.getElementsByClassName("contenedortareasall");
    if (borrar1) {
      borrar1[idx].style = "display:none !important";
    }
    hideModal();
  };

  const [spinnerLoad, setspinnerLoad] = useState(
    <Loadingspinner customStyle="grow" size="3rem" />
  );
  const [modal1, setModal1] = useState(null);
  const [copyAllEntrenamientos, setCopyAllEntrenamientos] = useState(null);
  // console.log(copyAllEntrenamientos);

  useEffect(() => {
    const copyEntrenamientos = [];
    const copyDisciplines = [];
    const getData = async () => {
      await instance.get("customers/disciplines/").then(async (res) => {
        const mor = res.data;

        if (mor) {
          for (const i of mor) {
            const idDiscipline = i.discipline.id;
            const UUid = i.id;
            const kcal = i.kcal;
            const data = i.date;
            const hr_max = i.hr_max;
            const hr_med = i.hr_med;
            const status = i.status;
            copyDisciplines[UUid] = {
              id: "d" + idDiscipline,
              UUid: UUid,
              data: data,
              status: i.discipline.active,
              titulo: i.discipline.name.toLowerCase(),
              descripcion: i.discipline.summary,
              icon: i.discipline.icon,
              numeric_value: hr_med,
              duracion: i.duration,
              calorias: kcal,
            };
          }
          // console.log(copyDisciplines);
        }
      });

      await instance.get("booking/?any").then(async (res) => {
        const data =
          res.data.length > 10
            ? res.data
                .filter(
                  (e) =>
                    e.status > 30 &&
                    e.status != 70 &&
                    e.status != 90 &&
                    e.status != 50 &&
                    e.date < moment().format()
                )
                .slice(0, 10)
            : res.data.filter(
                (e) =>
                  e.status > 30 &&
                  e.status != 70 &&
                  e.status != 90 &&
                  e.status != 50 &&
                  e.date < moment().format()
              );

        if (data) {
          for (const i of data.filter((e) => e.training)) {
            const name = i.training.name;
            const description = i.training.description;
            const icon = i.training.icon;
            const UUid = i.id;
            const dataT = i.date;
            const group = i.is_group;
            const status = i.status;
            const img = i.training.cover_picture; //.match(/(img[\d]*b.png)/)[0]

            if (data != null) {
              await instance
                .get(`customers/healthlog/?booking=${UUid}`)
                .then((resEn) => {
                  const user_band = resEn.data;
                  // console.log(user_band);
                  if (user_band.length > 0) {
                    copyEntrenamientos[UUid] = {
                      id: UUid,
                      status: status,
                      titulo: name.toLowerCase(),
                      descripcion: description,
                      img: img,

                      data: dataT,
                      icon: icon,
                      individual: group == false ? "Individual" : "Grupal",
                      text_value: user_band[0].text_value,
                      numeric_value: user_band[0].numeric_value,
                      duracion: 10,
                      calorias: 10,
                    };
                  } else {
                    copyEntrenamientos[UUid] = {
                      id: UUid,
                      status: status,
                      titulo: name.toLowerCase(),
                      descripcion: description,
                      img: img,

                      data: dataT,
                      icon: icon,
                      individual: group == false ? "Individual" : "Grupal",

                      duracion: 10,
                      calorias: 10,
                    };
                  }
                });
            }
          }
        }
      });
      setspinnerLoad(null);
      setCopyAllEntrenamientos(
        copyEntrenamientos
          .filter((e) => e)
          .concat(copyDisciplines.filter((e) => e))
      );
    };
    getData();
  }, []);
  const sliders = slidersInfo.map((val, idx) => {
    const name = val[0];
    const img = val[1];

    const totalallevel = alllevels ? alllevels.length : 0;
    const unidadtotal = alllevels
      ? alllevels.map((e) => e.current_session).reduce((a, b) => a + b, 0)
      : 0;
    const ver_level = unidadtotal > 0 ? unidadtotal / totalallevel : 0;
    const now =
      totalallevel == 0
        ? 0
        : Math.round(((unidadtotal / totalallevel) * 100) / 120);

    return laptop575.matches ? (
      <div className="estadisticaSlider scrollturesumen " key={name}>
        <div className="contentEstadistica d-row ">
          <div className="d-flex">
            <div className="perfilgraf" style={{ marginLeft: "3%" }}>
              <div>
                <div
                  className="containerGraficas"
                  style={{
                    marginLeft: "10%",
                    marginRight: "0%",
                    width: "100%",
                  }}
                >
                  <div className="titulodiasreser text-center">
                    Días reservados en la semana
                  </div>
                  <div>
                    <div className="semana ">{infoDias}</div>
                    <div className="msg text-center">
                      Acá podrás ver tu rendimiento por disciplinas. Supérate
                      con cada entrenamiento y alcanza un nuevo nivel.
                    </div>
                  </div>
                </div>
              </div>
              <div className="withcontainer">
                <div className="titulo" style={{ marginLeft: "21%" }}>
                  Tu resumen
                </div>

                <div
                  className="flexstarts"
                  style={{ marginLeft: "5%", width: "40vw" }}
                >
                  <div className="estrellas">
                    <img
                      src={estrellas >= 1 ? star2 : star1}
                      alt="etrella"
                      className="estrella"
                    />
                    <img
                      src={estrellas >= 2 ? star2 : star1}
                      alt="etrella"
                      className="estrella"
                    />
                    <img
                      src={estrellas >= 3 ? star2 : star1}
                      alt="etrella"
                      className="estrella"
                    />
                  </div>
                  <div className="nivel">
                    {" "}
                    Nivel :{" "}
                    {nivel == 20
                      ? "Intermedio"
                      : nivel == 30
                      ? "Avanzado"
                      : "Principiante"}
                  </div>
                </div>

                <div className="d-row fivecolumnas">
                  <div className="d-flex row-cols-6">
                    <div className="  iconCalorias" />

                    <div className="  jc-center  calorias1"> </div>
                    <div className="d-flex m-auto p-auto jc-center letrasviscerasinfo11">
                      SEMANA <br /> ANTERIOR
                    </div>
                    <div className="  flechaverde1"></div>
                    <div className=" d-flex m-auto p-auto jc-center  letrasviscerasinfo11">
                      ESTA <br />
                      SEMANA
                    </div>
                    <div className=" flechaverde2 "></div>
                  </div>
                  <div className="d-flex row-cols-6 py-2">
                    <img
                      className="  iconCalorias"
                      src={reloj_black}
                      alt={name}
                    />

                    <div className="  jc-center  calorias1">
                      {" "}
                      Sesiones realizadas
                    </div>
                    <div className="d-flex m-auto p-auto jc-center letrasviscerasinfo  ">
                      {objetives_user
                        ? objetives_user.last.days_activity + " "
                        : 0 + " "}
                    </div>
                    <div className="  flechaverde1">
                      <img className="  " src={flechaverde} />
                    </div>
                    <div className=" d-flex m-auto p-auto jc-center  letrasviscerasinfo">
                      {objetives_user
                        ? objetives_user.current.days_activity + " "
                        : 0 + " "}
                    </div>
                    <div className=" flechaverde2 ">
                      <img
                        src={
                          objetives_user &&
                          objetives_user.last.days_activity -
                            objetives_user.current.days_activity ==
                            0
                            ? igual
                            : objetives_user &&
                              objetives_user.last.days_activity -
                                objetives_user.current.days_activity >
                                0
                            ? dobleflechaverdeabajo
                            : dobleflechaverde
                        }
                      />
                    </div>
                  </div>
                  <div className=" borderlineiconos mx-auto "></div>
                </div>
                <div>
                  <div className="d-row fivecolumnas">
                    <div className="d-flex row-cols-6 py-2 ">
                      <img
                        className="  firecambiartamaño"
                        src={fire_black}
                        alt={name}
                      />

                      <div className="  jc-center  calorias1calorias ">
                        {" "}
                        Calorías quemadas
                      </div>
                      <div className="d-flex m-auto p-auto jc-center letrasviscerasinfoquemadas  ">
                        {objetives_user && objetives_user.last.kcal
                          ? objetives_user.last.kcal.toFixed(1)
                          : 0}
                      </div>
                      <div className="  flechaverde1caloriasq">
                        <img className="  " src={flechaverde} />
                      </div>
                      <div className=" d-flex m-auto p-auto jc-center  letrasviscerasinfoquemadas">
                        {objetives_user && objetives_user.current.kcal
                          ? objetives_user.current.kcal.toFixed(1)
                          : 0}
                      </div>
                      <div className=" flechaverde2caloriasq ">
                        <img
                          src={
                            objetives_user &&
                            objetives_user.last.kcal -
                              objetives_user.current.kcal ==
                              0
                              ? igual
                              : objetives_user &&
                                objetives_user.last.kcal -
                                  objetives_user.current.kcal >
                                  0
                              ? dobleflechaverdeabajo
                              : dobleflechaverde
                          }
                        />
                      </div>
                    </div>
                    <div className=" borderlineiconos mx-auto "></div>
                  </div>
                  <div className="d-row fivecolumnas">
                    <div className="d-flex row-cols-6 py-2">
                      <img
                        className="  iconCaloriasHEART"
                        src={heart}
                        alt={name}
                      />

                      <div className="  jc-center  calorias1 ">
                        {" "}
                        Frecuencia cardiaca promedio(fcm)
                      </div>
                      <div className="d-flex m-auto p-auto jc-center letrasviscerasinfoquemadas  ">
                        {objetives_user
                          ? Math.round(objetives_user.last.hr)
                          : 0}
                      </div>
                      <div className="  flechaverde1caloriasq1">
                        <img className="  " src={flechaverde} />
                      </div>
                      <div className=" d-flex m-auto p-auto jc-center  letrasviscerasinfoquemadas">
                        {objetives_user
                          ? Math.round(objetives_user.current.hr)
                          : 0}
                      </div>
                      <div className=" flechaverde2caloriasq ">
                        <img
                          src={
                            objetives_user &&
                            objetives_user.last.hr -
                              objetives_user.current.hr ==
                              0
                              ? igual
                              : objetives_user &&
                                objetives_user.last.hr -
                                  objetives_user.current.hr >
                                  0
                              ? dobleflechaverdeabajo
                              : dobleflechaverde
                          }
                        />
                      </div>
                    </div>
                    <div className=" borderlineiconos mx-auto "></div>
                  </div>
                  <div className="d-row fivecolumnas">
                    <div className="d-flex row-cols-6 py-2">
                      <img className="  iconCalorias" src={mob} alt={name} />

                      <div className="  jc-center  calorias1 ">
                        {" "}
                        Puntos Elevva
                      </div>
                      <div className="d-flex m-auto p-auto jc-center letrasviscerasinfo  ">
                        {objetives_user ? objetives_user.last.mob_points : 0}
                      </div>
                      <div className="  flechaverde1">
                        <img className="  " src={flechaverde} />
                      </div>
                      <div className=" d-flex m-auto p-auto jc-center  letrasviscerasinfo">
                        {objetives_user ? objetives_user.current.mob_points : 0}
                      </div>
                      <div className=" flechaverde2 ">
                        <img
                          src={
                            objetives_user &&
                            objetives_user.last.mob_points -
                              objetives_user.current.mob_points ==
                              0
                              ? igual
                              : objetives_user &&
                                objetives_user.last.mob_points -
                                  objetives_user.current.mob_points >
                                  0
                              ? dobleflechaverdeabajo
                              : dobleflechaverde
                          }
                        />
                      </div>
                    </div>
                    <div className=" borderlineiconos mx-auto "></div>
                  </div>
                  <div className="d-row fivecolumnas">
                    <div className="d-flex row-cols-6 py-2">
                      <img
                        className="  iconCalorias"
                        src={imggraficas}
                        alt={name}
                      />

                      <div className="  jc-center  calorias1 ">
                        {" "}
                        Nivel de dificultad
                      </div>
                      <div className="d-flex m-auto p-auto jc-center letrasviscerasinfo  ">
                        {objetives_user
                          ? objetives_user.last.level_percent.toFixed(2) + "%"
                          : "___"}
                      </div>
                      <div className="  flechaverde1">
                        <img className="  " src={flechaverde} />
                      </div>
                      <div className=" d-flex m-auto p-auto jc-center  letrasviscerasinfo">
                        {objetives_user
                          ? objetives_user.current.level_percent.toFixed(2) +
                            "%"
                          : "___"}
                      </div>
                      <div className=" flechaverde2 ">
                        <img
                          src={
                            objetives_user &&
                            objetives_user.last.level_percent -
                              objetives_user.current.level_percent ==
                              0
                              ? igual
                              : objetives_user &&
                                objetives_user.last.level_percent -
                                  objetives_user.current.level_percent >
                                  0
                              ? dobleflechaverdeabajo
                              : dobleflechaverde
                          }
                        />
                      </div>
                    </div>
                    <div className=" borderlineiconos mx-auto "></div>
                  </div>
                  <div className="d-row fivecolumnas">
                    <div className="d-flex row-cols-6 py-2">
                      <img className="  iconCalorias" src={kms} alt={name} />

                      <div className="  jc-center  calorias1 ">
                        {" "}
                        Distancia recorrida en actividad libre
                      </div>
                      <div className="d-flex m-auto p-auto jc-center letrasviscerasinfo  ">
                        {objetives_user ? objetives_user.last.distance : 0}
                      </div>
                      <div className="  flechaverde1">
                        <img className="  " src={flechaverde} />
                      </div>
                      <div className=" d-flex m-auto p-auto jc-center  letrasviscerasinfo">
                        {objetives_user ? objetives_user.current.distance : 0}
                      </div>
                      <div className=" flechaverde2 ">
                        <img
                          src={
                            objetives_user &&
                            objetives_user.last.distance -
                              objetives_user.current.distance ==
                              0
                              ? igual
                              : objetives_user &&
                                objetives_user.current.distance -
                                  objetives_user.last.distance >
                                  0
                              ? dobleflechaverdeabajo
                              : dobleflechaverde
                          }
                        />
                      </div>
                    </div>
                    <div className=" borderlineiconos mx-auto "></div>
                  </div>
                  <div className="d-row fivecolumnas ">
                    <div className="d-flex row-cols-6 py-2">
                      <img className="iconCalorias" src={chulito} alt={name} />
                      <div className="calorias1 jc-center ">
                        Total de puntos Elevva
                      </div>
                      {/* d-flex m-auto p-auto jc-center letrasviscerasinfo */}
                      <div className="letrasviscerasinfo d-flex m-auto p-auto jc-center"></div>
                      <div className="  flechaverde1"></div>

                      <div
                        className="letrasviscerasinfo d-flex jc-center m-auto p-auto"
                        style={{ fontSize: "1vw" }}
                      >
                        {objetives_user &&
                          objetives_user.current.total_mob_points}
                      </div>
                      <div className=" flechaverde2 "></div>
                    </div>
                    <div className=" borderlineiconos mx-auto "></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="perfilgraf">
              <div className="containerGraficas">
                {laptop575.matches ? (
                  <div
                    className="contentEstadistica_web containerGraficas_web"
                    style={{ marginLeft: "0%" }}
                  >
                    <Nivelesydiscliplinas handleClick={mostrarmodalDesempeño} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="contenedortareas">
            <div className="nivel"> Sesiones recientes</div>

            <div className=" gridtareas">
              {copyAllEntrenamientos &&
                copyAllEntrenamientos
                  .sort(function (a, b) {
                    var nameA = a.data; // ignore upper and lowercase
                    var nameB = b.data; // ignore upper and lowercase
                    if (nameA > nameB) {
                      return -1;
                    }
                    if (nameA < nameB) {
                      return 1;
                    }
                    // names must be equal
                    return 0;
                  })
                  .map((data, idx) => (
                    <div className="contenedortareasall" key={data.id}>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        className="mySwiper"
                        slidesPerView={1.6}
                        reachEnd={true}
                        onClick={() =>
                          setModal1(
                            typeof data.id == "number" ? (
                              <Redirect
                                to={`/${userType}/resumen/${data.id}`}
                              />
                            ) : (
                              <Redirect
                                to={`/${userType}/HR/results/${data.id}/${data.UUid}`}
                              />
                            )
                          )
                        }
                      >
                        <div className="mySwiper2">
                          <div className="cuadrogrande"> </div>

                          <div
                            className="letras "
                            style={{
                              textTransform: "capitalize",
                              width: "23vw",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {data.titulo}
                          </div>
                          <div className="imagen">
                            <img src={data.icon} className="imagen1" />{" "}
                          </div>
                          {data.numeric_value ? (
                            <div className="letraspeque15">
                              FCM: {data.numeric_value}
                            </div>
                          ) : null}
                          {data.duracion ? (
                            <div className="letraspeque14">
                              {moment
                                .utc(data.duracion * 60 * 1000)
                                .format("HH:mm:ss")}
                            </div>
                          ) : null}
                          {data.data ? (
                            <div className="letraspeque12">
                              {" "}
                              {moment(
                                data.data.slice(0, 10),
                                "YYYY-MM-DD"
                              ).format(" DD MMMM [de] YYYY")}
                            </div>
                          ) : null}
                          {data.data ? (
                            <div className="letraspeque13">
                              {" "}
                              {moment(data.data.slice(11, 16), "HH:mm").format(
                                "LT a"
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        )
      </div>
    ) : (
      <SwiperSlide className="estadisticaSlider col-xl-6" key={name}>
        {img != null ? (
          <div className="containerImg">
            <img className="icon" src={img} alt={name} />
          </div>
        ) : null}

        <div className="contentEstadistica">
          {name == "Nivel" && "Calorias" ? (
            <div>
              <div className="titulo">
                Información
                <br />
                de entrenamiento
              </div>

              <div className=" borderlineiconos mx-auto "></div>
              <div className="flexstarts">
                <div className="estrellas">
                  <img
                    src={estrellas >= 1 ? star2 : star1}
                    alt="etrella"
                    className="estrella"
                  />
                  <img
                    src={estrellas >= 2 ? star2 : star1}
                    alt="etrella"
                    className="estrella"
                  />
                  <img
                    src={estrellas >= 3 ? star2 : star1}
                    alt="etrella"
                    className="estrella"
                  />
                </div>
                <div className="nivel">
                  {" "}
                  Nivel:
                  {nivel == 20
                    ? "Intermedio"
                    : nivel == 30
                    ? "Avanzado"
                    : "Principiante"}
                </div>
              </div>
              <div>
                <div className=" borderlineiconos mx-auto "></div>
                <div className="d-flex row-cols-6 containerTotal">
                  <div className="iconCalorias1foto"></div>
                  <div className="calorias1 d-flex"> </div>
                  <div
                    className="cal  calestasemana1 d-flex margin-auto p-auto jc-center "
                    style={{ color: "#CB3D82" }}
                  >
                    SEMANA <br />
                    ANTERIOR
                  </div>
                  <div className="withespaciowhite "></div>
                  <div
                    className="cal  calestasemana d-flex margin-auto p-auto jc-center "
                    style={{ color: "#CB3D82" }}
                  >
                    ESTA <br />
                    SEMANA
                  </div>
                  <div className="  "></div>
                </div>

                <div className="d-flex row-cols-6 containerTotal">
                  <img className="iconCalorias" src={reloj_black} alt={name} />
                  <div className="calorias1 d-flex"> Sesiones realizadas</div>

                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center  ">
                    {objetives_user ? objetives_user.last.days_activity : 0}
                  </div>
                  <div>
                    <img className=" flechaverde1 " src={flechaverde} />
                  </div>
                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center ">
                    {objetives_user ? objetives_user.current.days_activity : 0}
                  </div>
                  <div>
                    <img
                      className=" flechaverde1 "
                      src={
                        objetives_user &&
                        objetives_user.last.days_activity -
                          objetives_user.current.days_activity ==
                          0
                          ? igual
                          : objetives_user &&
                            objetives_user.last.days_activity -
                              objetives_user.current.days_activity >
                              0
                          ? dobleflechaverdeabajo
                          : dobleflechaverde
                      }
                    />
                  </div>
                </div>
                <div className=" borderlineiconos mx-auto "></div>
                <div className="d-flex row-cols-6 containerTotal">
                  <img className="iconCalorias" src={fire_black} alt={name} />
                  <div className="calorias1 d-flex"> Calorías quemadas</div>

                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center  ">
                    {objetives_user && objetives_user.last.kcal
                      ? objetives_user.last.kcal.toFixed(0)
                      : 0}
                  </div>
                  <div className="">
                    <img className=" flechaverde1 " src={flechaverde} />
                  </div>
                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center ">
                    {objetives_user && objetives_user.last.kcal
                      ? objetives_user.current.kcal.toFixed(0)
                      : 0}
                  </div>
                  <div>
                    <img
                      className=" flechaverde1 "
                      src={
                        objetives_user &&
                        objetives_user.last.kcal -
                          objetives_user.current.kcal ==
                          0
                          ? igual
                          : objetives_user &&
                            objetives_user.last.kcal -
                              objetives_user.current.kcal >
                              0
                          ? dobleflechaverdeabajo
                          : dobleflechaverde
                      }
                    />
                  </div>
                </div>

                <div className=" borderlineiconos mx-auto "></div>

                <div className="d-flex row-cols-6 containerTotal">
                  <img className="iconCalorias" src={heart} alt={name} />
                  <div className="calorias1 d-flex">
                    {" "}
                    Frecuencia cardiaca promedio(fcm)
                  </div>

                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center  ">
                    {objetives_user ? Math.round(objetives_user.last.hr) : 0}
                  </div>
                  <div className="">
                    <img className=" flechaverde1 " src={flechaverde} />
                  </div>
                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center ">
                    {objetives_user ? Math.round(objetives_user.current.hr) : 0}
                  </div>
                  <div>
                    <img
                      className=" flechaverde1 "
                      src={
                        objetives_user &&
                        objetives_user.last.hr - objetives_user.current.hr == 0
                          ? igual
                          : objetives_user &&
                            objetives_user.last.hr - objetives_user.current.hr >
                              0
                          ? dobleflechaverdeabajo
                          : dobleflechaverde
                      }
                    />
                  </div>
                </div>

                <div className=" borderlineiconos mx-auto "></div>
                <div className="d-flex row-cols-6 containerTotal">
                  <img className="iconCalorias" src={mob} alt={name} />
                  <div className="calorias1 d-flex"> Puntos Elevva</div>

                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center  ">
                    {objetives_user ? objetives_user.last.mob_points : 0}
                  </div>
                  <div className="">
                    <img className=" flechaverde1 " src={flechaverde} />
                  </div>
                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center ">
                    {objetives_user ? objetives_user.current.mob_points : 0}
                  </div>
                  <div>
                    <img
                      className=" flechaverde1 "
                      src={
                        objetives_user &&
                        objetives_user.last.mob_points -
                          objetives_user.current.mob_points ==
                          0
                          ? igual
                          : objetives_user &&
                            objetives_user.last.mob_points -
                              objetives_user.current.mob_points >
                              0
                          ? dobleflechaverdeabajo
                          : dobleflechaverde
                      }
                    />
                  </div>
                </div>

                <div className=" borderlineiconos mx-auto "></div>
                <div className="d-flex row-cols-6 containerTotal">
                  <img className="iconCalorias" src={imggraficas} alt={name} />
                  <div className="calorias1 d-flex"> Nivel de dificultad</div>

                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center  ">
                    {objetives_user
                      ? objetives_user.last.level_percent.toFixed(2) + "%"
                      : "___"}
                  </div>
                  <div>
                    <img className=" flechaverde1 " src={flechaverde} />
                  </div>
                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center ">
                    {objetives_user
                      ? objetives_user.current.level_percent.toFixed(2) + "%"
                      : "___"}
                  </div>
                  <div className="  ">
                    <img
                      className=" flechaverde1 "
                      src={
                        objetives_user &&
                        objetives_user.last.level_percent -
                          objetives_user.current.level_percent ==
                          0
                          ? igual
                          : objetives_user &&
                            objetives_user.last.level_percent -
                              objetives_user.current.level_percent >
                              0
                          ? dobleflechaverdeabajo
                          : dobleflechaverde
                      }
                    />
                  </div>
                </div>
                <div className=" borderlineiconos mx-auto "></div>
                <div className="d-flex row-cols-6 containerTotal">
                  <img className="iconCalorias" src={kms} alt={name} />
                  <div className="calorias1 d-flex">
                    {" "}
                    Distancia recorrida en actividad libre
                  </div>

                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center  ">
                    {objetives_user ? objetives_user.last.distance : 0}
                  </div>
                  <div>
                    <img className=" flechaverde1 " src={flechaverde} />
                  </div>
                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center ">
                    {objetives_user ? objetives_user.current.distance : 0}
                  </div>
                  <div className="  ">
                    <img
                      className=" flechaverde1 "
                      src={
                        objetives_user &&
                        objetives_user.last.distance -
                          objetives_user.current.distance ==
                          0
                          ? igual
                          : objetives_user &&
                            objetives_user.last.distance -
                              objetives_user.current.distance >
                              0
                          ? dobleflechaverdeabajo
                          : dobleflechaverde
                      }
                    />
                  </div>
                </div>

                <div className=" borderlineiconos mx-auto "></div>
                <div className="d-flex row-cols-6 containerTotal">
                  <img className="iconCalorias" src={chulito} alt={name} />
                  <div className="calorias1 d-flex">
                    {" "}
                    Total de puntos Elevva
                  </div>

                  <div className="cal  cal2 d-flex margin-auto p-auto jc-center  "></div>
                  <div className=""></div>
                  <div
                    className="cal  cal2 d-flex margin-auto p-auto jc-center "
                    style={{ fontSize: "1rem", color: "#394F65" }}
                  >
                    {objetives_user && objetives_user.current.total_mob_points}
                  </div>
                  <div className="  "></div>
                </div>
              </div>
            </div>
          ) : null}

          {name == "Información total" ? (
            <div className="contenedortareas">
              <div className="nivel"> Sesiones recientes</div>
              {spinnerLoad}
              {copyAllEntrenamientos &&
                copyAllEntrenamientos

                  .filter((e) => e)
                  .sort(function (a, b) {
                    var nameA = a.data; // ignore upper and lowercase
                    var nameB = b.data; // ignore upper and lowercase
                    if (nameA > nameB) {
                      return -1;
                    }
                    if (nameA < nameB) {
                      return 1;
                    }
                    // names must be equal
                    return 0;
                  })
                  .map((data, idx) => (
                    <div className="contenedortareasall" key={data.id}>
                      <Swiper
                        className="mySwiper"
                        slidesPerView={1.6}
                        reachEnd={true}
                      >
                        <SwiperSlide
                          className="mySwiper2"
                          onClick={() =>
                            setModal1(
                              typeof data.id == "number" ? (
                                <Redirect
                                  to={`/${userType}/resumen/${data.id}`}
                                />
                              ) : (
                                <Redirect
                                  to={`/${userType}/HR/results/${data.id}/${data.UUid}`}
                                />
                              )
                              // <Redirect
                              //   to={`/${userType}/HR/results/${data.id}/${data.UUid}`}
                              // />
                            )
                          }
                        >
                          <div className="cuadrogrande"> </div>

                          <div
                            className="letras"
                            style={{
                              textTransform: "capitalize",
                              fontSize: "4vw",

                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {data.titulo}
                          </div>
                          <div className="imagen">
                            <img src={data.icon} className="imagen1" />{" "}
                          </div>
                          {data.numeric_value ? (
                            <div className="letraspeque15">
                              FCM: {data.numeric_value}
                            </div>
                          ) : null}
                          {data.duracion ? (
                            <div className="letraspeque14">
                              {moment
                                .utc(data.duracion * 60 * 1000)
                                .format("HH:mm:ss")}
                            </div>
                          ) : null}
                          {data.data ? (
                            <div className="letraspeque12">
                              {" "}
                              {moment(
                                data.data.slice(0, 10),
                                "YYYY-MM-DD"
                              ).format(" DD MMMM [de] YYYY")}
                            </div>
                          ) : null}
                          {data.data ? (
                            <div className="letraspeque13">
                              {" "}
                              {moment(data.data.slice(11, 16), "HH:mm").format(
                                "LT a"
                              )}
                            </div>
                          ) : null}
                        </SwiperSlide>

                        <SwiperSlide
                          key={data.id}
                          className="borrar"
                          onClick={() => mostrarmodal(idx)}
                        >
                          <img className="imagencaneca" src={caneca} />
                          <div className="letraspequeborrar"> Ocultar</div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  ))}
            </div>
          ) : null}
          {name == "Graficas" ? (
            <div className="containerGraficas">
              {ModalDesempeño}
              <div className="nivel"> Información de desempeño</div>
              <div className=" borderlineiconospruebas mt-4 mx-auto "></div>
              <div
                className="msg"
                style={{ color: "#394F65", fontWeight: "700" }}
              >
                ESTA SEMANA ENTRENASTE
                <br />
              </div>
              <div className="semana mx-auto">{infoDias}</div>
              <div className=" borderlineiconospruebas mt-4 mx-auto "></div>
              <div className=" jazmin d-flex jc-center"></div>
              <Nivelesydiscliplinas handleClick={mostrarmodalDesempeño} />
            </div>
          ) : null}
        </div>
      </SwiperSlide>
    );
  });

  if (actualView != "Informacióndeentrenamiento") return <></>;
  return (
    <div className="estadisticas">
      <div className={`${isLoaded == true ? "" : "d-none"} my-3`}>
        <Loadingspinner customStyle="grow" size="3rem" />
      </div>
      <div
        className={`${
          isLoaded == true ? "invisible" : ""
        } SwipSlide mx-auto row g-0`}
      >
        <Media query="(max-width: 920px)">
          {(matches) =>
            matches ? (
              <>
                <Swiper
                  style={{ height: "calc(50vh - 1rem)", overflowY: "scroll" }}
                  className=" "
                  spaceBetween={10}
                  slidesPerView="auto"
                  // Responsive breakpoints
                  breakpoints={{
                    // when window width is >= 1024px
                    576: {
                      slidesPerView: 1,
                      pagination: {},
                    },
                  }}
                  pagination={{
                    el: ".stepsBitacora ",
                    type: "custom",
                    renderCustom: function (swiper, current, total) {
                      current -= 1;
                      return slidersInfo
                        .map((value, index) => {
                          return `<div class='steps1 mx-1 rounded-circle ${
                            current == index ? "active" : ""
                          }'></div>`;
                        })
                        .join("");
                    },
                  }}
                >
                  {modal1}

                  {sliders}
                </Swiper>
                {ModalDesempeño}
              </>
            ) : (
              <>
                {sliders}
                {ModalDesempeño}
                {modal1}
              </>
            )
          }
        </Media>
        <div>
          <div className="stepsBitacora changestepsBitacora  mx-auto"></div>
        </div>
      </div>
    </div>
  );
};

const Bitacora = (props) => {
  const selOption = props.selOption;
  const actualView = props.actualView;

  const [isLoaded, setIsLoaded] = useState(false);
  const [modal, setModal] = useState(null);
  const [textEquipos, settextEquipos] = useState(null);
  const [infoBitacoraactivo, setinfoBitacoraactivo] = useState(null);
  // console.log(infoBitacoraactivo);
  const infoBitacora = [
    ["Medidas", null, useRef(null), null],
    ["Equipos", null, useRef(null), null],
  ];
  const formRefEquipos = useRef(null);
  const formRefTest = useRef(null);
  useEffect(() => {
    instance
      .get(`trainings/exercises/`)
      .then((res) => {
        const info = res.data;
        // console.log("data", info);
        if (info != null) {
          setinfoBitacoraactivo(info);
        }
      })
      .catch((err) => console.log(err.response));

    instance.get(`${NuevaAxiosURL}users/`).then((res) => {
      const info = res.data;
      // console.log("data", info);
      if (info != null) {
        if (laptop575.matches) {
          formRefEquipos.current.querySelector("textarea").value =
            info.customer.equipment;
          settextEquipos(info.customer.equipment);
        } else {
          formRefEquipos.current.children.namedItem("Equipos").value =
            info.customer.equipment;
          settextEquipos(info.customer.equipment);
        }
      }
    });
  }, []);

  const [pruebasDisciplina, setpruebasDisciplina] = useState(null);

  const [startPrueba, SetstartPrueba] = useState(false);
  const [disciplinatest, setdisciplinatest] = useState(null);
  const [IdDiscpline, setIdDiscpline] = useState(null);

  const sliders = infoBitacora.map((val, idx) => {
    const restNum = 2;

    const inputs = [];
    for (let i = 1; i < 5; i++) {
      inputs.push(
        <div className="bitacoraInput col-6 mx-auto " key={i}>
          <label htmlFor={"item" + i} className="form-label">
            Test {i}
          </label>
          <input
            style={{ backgroundColor: "white" }}
            type="number"
            name={"item" + i}
            id={"item" + i}
            className="bitInputNum form-control"
            placeholder=""
            disabled={true}
          />
        </div>
      );
    }

    function AgregarTest(event) {
      const testMetric = event.target[0].value;
      var formData = new FormData();
      formData.append("metric", testMetric);
      // console.log(disciplinatest, IdDiscpline);
      formData.append("exercise", IdDiscpline);
      instance
        .post(`customers/logs/`, formData)
        .then((res) => {
          if (res.data) {
            disciplinaIndividual(res.data.exercise.id);
            setModal(null);
          }
        })
        .catch((err) => console.log(err.response));

      event.preventDefault();
    }
    function AgregarTester(event, id) {
      const testMetric = event.target[0].value;
      var formData = new FormData();
      formData.append("metric", testMetric);

      formData.append("exercise", id);
      instance
        .post(`customers/logs/`, formData)
        .then((res) => {
          if (res.data) {
            setModal(null);
            disciplinaIndividual(res.data.exercise.id);
          }
        })
        .catch((err) => console.log(err.response));

      event.preventDefault();
    }
    function disciplinaIndividual(id) {
      if (laptop575.matches) {
        instance
          .get(`trainings/exercises/${id}`)
          .then((res) => {
            const info = res.data;
            // console.log("data", info);
            if (info != null) {
              SetstartPrueba(true);
            }
          })
          .catch((err) => console.log(err.response));
        instance
          .get(`customers/logs/`)
          .then(async (res) => {
            const info = await res.data.filter((e) => e.exercise.id == id);
            // console.log(info);
            formRefTest.current.children[0].children.namedItem("item1").value =
              info.length >= 1 ? info.slice(0, 1)[0].metric : null;
            formRefTest.current.children[1].children.namedItem("item2").value =
              info.length > 1 ? info.slice(1, 2)[0].metric : null;
            formRefTest.current.children[2].children.namedItem("item3").value =
              info.length > 2 ? info.slice(2, 3)[0].metric : null;
            formRefTest.current.children[3].children.namedItem("item4").value =
              info.length > 3 ? info.slice(3, 4)[0].metric : null;
          })
          .catch((err) => console.log(err.response));
      } else {
        setIdDiscpline(id);

        instance
          .get(`trainings/exercises/${id}`)
          .then((res) => {
            const info = res.data;
            // console.log("data", info);
            if (info != null) {
              setdisciplinatest(info);
              SetstartPrueba(true);
            }
          })
          .catch((err) => console.log(err.response));
        instance
          .get(`customers/logs/`)
          .then(async (res) => {
            const info = await res.data.filter((e) => e.exercise.id == id);
            // console.log(info);
            formRefTest.current.children[0].children.namedItem("item1").value =
              info.length >= 1 ? info.slice(0, 1)[0].metric : null;
            formRefTest.current.children[1].children.namedItem("item2").value =
              info.length > 1 ? info.slice(1, 2)[0].metric : null;
            formRefTest.current.children[2].children.namedItem("item3").value =
              info.length > 2 ? info.slice(2, 3)[0].metric : null;
            formRefTest.current.children[3].children.namedItem("item4").value =
              info.length > 3 ? info.slice(3, 4)[0].metric : null;
          })
          .catch((err) => console.log(err.response));
      }
    }
    function guardarPruebas(idx1, indice, id) {
      const changeColordis = document.querySelector(".cuboizquier");
      const changeColordisone = changeColordis.children[indice].querySelector(
        ".mySwiper3 .imagenbit "
      );

      // console.log("indiceuno por uno", changeColordis.children[0]);
      const changeColordistwo = changeColordis.children[indice].querySelector(
        ".mySwiper3 .imagenbit .imagenw1"
      );

      for (
        let i = 0;
        i <
        infoBitacoraactivo.filter((e) => e.is_for_customer_log == true).length;
        i++
      ) {
        const changeColordisonen = changeColordis.children[i].querySelector(
          ".mySwiper3 .imagenbit "
        );
        const changeColordistwon = changeColordis.children[i].querySelector(
          ".mySwiper3 .imagenbit .imagenw1"
        );
        changeColordisonen.style = "background-color: #9EACED !important";
        changeColordistwon.style = "display: none";
      }

      changeColordisone.style = "background-color: #9EACED !important";
      changeColordistwo.style = "display: block";

      setpruebasDisciplina(
        <div className="mt-5 marginparapruebas">
          <SwiperSlide className="bitacoraSlider w-50 " key={indice}>
            <div className="staticDescpru1">
              <span>{idx1}</span>
            </div>
            <div className="staticDescpru">
              Dinos cuantas repeticiones hiciste del siguiente ejercicio para
              ver tu progreso.
              <br />
            </div>
            <form className="bitacoraForm d-flex " ref={formRefTest}>
              {inputs}
            </form>
            <div style={{ width: "100%", marginLeft: "30%" }}>
              <ButtonMob
                text="Agregar test"
                className="mt-4 py-2 w-70 mx-auto d-flex"
                onClick={() => {
                  let styleModal = {
                    display: "flex",
                    position: "fixed",
                    zIndex: 999,
                    whiteSpace: "pre-line",
                  };
                  setModal(
                    <div
                      className="modalInfo justify-content-center align-items-center"
                      style={styleModal}
                    >
                      <div className="modalInfoContenido ">
                        <div className="icon my-2"></div>
                        <div className="title">Ingresar prueba</div>
                        <form onSubmit={(e) => AgregarTester(e, id)}>
                          <input
                            style={
                              laptop575.matches
                                ? {
                                    marginTop: "2vh",
                                    width: "70%",
                                    fontSize: "2rem",
                                  }
                                : {
                                    marginBottom: "3vh",
                                    width: "70%",
                                  }
                            }
                            type="number"
                            name="tester"
                            placeholder="Ej:20"
                            required
                          />
                          <div className="col-12">
                            <div
                              className=" col-6 btnAction_pruebas letraspopfi1 "
                              onClick={hideModal}
                            >
                              <ButtonMob
                                text="Cancelar"
                                className="mt-3 mb-3 btn-danger letraspopfi2actualizar"
                              />
                            </div>

                            <div className="col-6 btnAction_pruebas letraspopfi2">
                              <ButtonMob
                                colorButton={"#9EACED"}
                                text="Agregar"
                                className="mb-3 mt-3 letraspopfi2actualizar"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </SwiperSlide>
        </div>
      );
    }

    if (laptop575.matches) {
      if (idx == 0) {
        return (
          <div
            className="bitacoraSlider col-xl-12 scrollturesumenc "
            key={val[0]}
          >
            <div className="titulobita">Tus equipos</div>
            <div className="staticDesc">
              Dinos con que equipos cuentas para entrenar y así, usarlos en tu
              rutina.
            </div>
            <form
              className="bitacoraForm row row-cols-12 "
              ref={formRefEquipos}
            >
              <textarea
                placeholder="Escribe aquí tus equipos"
                rows="9"
                className=" textareatamaño "
                style={{ margin: "2vh 13.5vw" }}
                onInput={(e) => settextEquipos(e.target.value)}
              ></textarea>
              <input type="hidden" value={localStorage.my_code} name="data" />
              <input type="hidden" value="0" name="pos" />
            </form>
            <ButtonMob
              text="Actualizar"
              className=" btnActualizarequipos "
              onClick={() => saveBitacora(idx)}
            />
            <div className="titulobita " style={{ marginTop: "5vh" }}>
              {" "}
              Pruebas por disciplina
            </div>
            <div className="d-flex">
              <div className="cuboizquier">
                {infoBitacoraactivo &&
                  infoBitacoraactivo
                    .filter((e) => e.is_for_customer_log == true)
                    .map((data1, indice) => (
                      <div key={data1.id} className="haciaizquierpruebas">
                        <div
                          className="mySwiper3"
                          onClick={(e) =>
                            guardarPruebas(data1.name, indice, data1.id) ||
                            disciplinaIndividual(data1.id, e)
                          }
                        >
                          <div className="imagenbit">
                            <img src={data1.icon} className="imagenw" />{" "}
                            <div className="letras">{data1.name}</div>
                            <img src={flechaverde} className="imagenw1" />
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {pruebasDisciplina}
            </div>
          </div>
        );
      } else if (idx == 1) {
        return null;
      }
    } else {
      if (idx == 0) {
        return (
          <SwiperSlide
            className="bitacoraSlider col-xl-6 scrollturesumen "
            key={val[0]}
          >
            <div className="titulo">Bitácora</div>
            <div className="staticDesc">
              Dinos con que equipos cuentas para entrenar y así, usarlos en tu
              rutina.
            </div>
            <form
              className="bitacoraForm row row-cols-2 mx-auto "
              ref={formRefEquipos}
            >
              <textarea
                name="Equipos"
                placeholder="Escribe aquí tus equipos"
                rows="9"
                className=" textareatamaño mt-4 mx-auto"
                onInput={(e) => settextEquipos(e.target.value)}
              ></textarea>
              <input type="hidden" value={localStorage.my_code} name="data" />
              <input type="hidden" value="0" name="pos" />
            </form>
            <ButtonMob
              text="Actualizar"
              className="mt-3 w-50  d-flex justify-content-center"
              onClick={() => saveBitacora(idx)}
            />
          </SwiperSlide>
        );
      } else if (idx == 1) {
        if (startPrueba == false) {
          return (
            <>
              <SwiperSlide
                className="bitacoraSlider col-xl-6"
                key={val[idx]}
                style={{ height: "calc(50vh - 1rem)" }}
              >
                <div className="titulo"> Pruebas por disciplina</div>

                {infoBitacoraactivo &&
                  infoBitacoraactivo
                    .filter((e) => e.is_for_customer_log == true)
                    .map((data1, indice) => (
                      <div key={"bitacora " + indice}>
                        <div
                          className="mySwiper3"
                          onClick={
                            () => {
                              disciplinaIndividual(data1.id);
                            }
                            // (window.location.href = `/Usuario/bitacora/${data1.id}`)
                          }
                        >
                          <div className="cuadrogrande1"> </div>

                          <div className="letras">{data1.name}</div>
                          <div className="imagenbit">
                            <img src={data1.icon} className="imagenw" />{" "}
                          </div>
                        </div>
                      </div>
                    ))}
              </SwiperSlide>
            </>
          );
        } else {
          return (
            <div>
              <SwiperSlide className="bitacoraSlider col-xl-6">
                <div className="titulo">
                  {" "}
                  <img
                    onClick={
                      () => SetstartPrueba(false)
                      // (window.location.href = `/Usuario/bitacora/${data1.id}`)
                    }
                    src={flechaback}
                    width="12vw"
                    className=" p-auto"
                    style={{ margin: "0 1.3vw" }}
                  />
                  Pruebas por disciplina
                </div>
                <div className=" borderlineiconospruebas mb-3 mx-auto "></div>
                <div className="staticDesc">
                  Dinos cuantas repeticiones hiciste del siguiente ejercicio
                  para ver tu progreso.
                </div>
                {disciplinatest ? (
                  <div className="bitacoraTitle">
                    <span className="spanimg">
                      <img
                        src={disciplinatest.icon}
                        alt={disciplinatest.name}
                      />
                    </span>
                    <span>{disciplinatest.name}</span>
                  </div>
                ) : null}

                <form
                  className="bitacoraForm row row-cols-2 mx-4 "
                  ref={formRefTest}
                >
                  {inputs.slice(0, 2)}
                </form>
                <ButtonMob
                  text=" + Agregar un nuevo test"
                  colorButton={"#CB3D82"}
                  className="mt-4 py-3 w-50 mx-auto d-flex justify-content-center"
                  onClick={() => {
                    let styleModal = {
                      display: "flex",
                      position: "fixed",
                      zIndex: 999,
                      whiteSpace: "pre-line",
                    };
                    setModal(
                      <div
                        className="modalInfo justify-content-center align-items-center"
                        style={styleModal}
                      >
                        <div className="modalInfoContenido ">
                          <div className="icon my-2"></div>
                          <div className="title">Ingresar prueba</div>
                          <form onSubmit={AgregarTest}>
                            <input
                              style={{
                                marginBottom: "3vh",
                                width: "70%",
                                marginTop: "1vh",
                                fontSize: "1rem",
                              }}
                              type="number"
                              name="tester"
                              placeholder="Ej:20"
                              required
                            />
                            <div className="btnAction letraspopfi2">
                              <ButtonMob
                                text="Agregar"
                                className="mb-3 letraspopfi"
                              />
                            </div>
                            <div
                              className="btnAction letraspopfi1"
                              onClick={hideModal}
                            >
                              <ButtonMob
                                text="Cancelar"
                                className="mt-3 mb-3 btn-danger letraspopfi"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    );
                  }}
                />
              </SwiperSlide>
            </div>
          );
        }
      }
    }
  });

  function hideModal() {
    setModal(null);
  }

  useEffect(() => {
    instance
      .get(`customers/`)
      .then((res) => {
        const data = res.data;
        if (data) {
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        console.log("Error en api/clientes/perfil/cargar-bitacora", err);
        setModal(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });
  }, [infoBitacora]);

  const saveBitacora = (index) => {
    setModal(
      <ModalInfo
        error="none"
        handleClick={hideModal}
        title="Procesando"
        text={<Loadingspinner customStyle="grow" size="5rem" />}
        classesMsg=""
      />
    );
    var formData1 = new FormData();
    formData1.append("equipment", textEquipos);
    instance
      .patch(`customers/`, formData1)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.response));

    // const formData = new FormData(infoBitacora[index][2].current);
    // Hay que crear la base de datos para guardar las medidas y los equipos

    setModal(
      <ModalInfo
        error="false"
        handleClick={hideModal}
        title="Tu información ha sido actualizada correctamente"
      />
    );
  };
  if (actualView != "bitacora") return <></>;
  return (
    <>
      <div className="bitacora">
        {/* <div className="titulo">Bitácora</div> */}
        <div className={`${isLoaded == false ? "" : "d-none"} my-3`}>
          <Loadingspinner customStyle="grow" size="5rem" />
        </div>
        <div
          className={`${
            isLoaded == false ? "invisible" : ""
          } SwipSlide mx-auto row g-0`}
        >
          <Media query="(max-width: 920px)">
            {(matches) =>
              matches ? (
                <Swiper
                  style={{ height: "100%", overflowY: "scroll" }}
                  spaceBetween={10}
                  slidesPerView={"auto"}
                  // Responsive breakpoints
                  breakpoints={{
                    // when window width is >= 1024px
                    576: {
                      slidesPerView: 1,
                      pagination: {
                        renderCustom: function (swiper, current, total) {
                          current -= 1;
                          const bull = infoBitacora.map((value, index) => {
                            return `<div class='steps mx-1 rounded-circle ${
                              current == index ? "active" : ""
                            }'></div>`;
                          });
                          bull.pop();
                          return bull.join("");
                        },
                      },
                    },
                  }}
                  pagination={{
                    el: ".stepsBitacora",
                    type: "custom",
                    renderCustom: function (swiper, current, total) {
                      current -= 1;
                      return infoBitacora
                        .map((value, index) => {
                          return `<div class='steps mx-1 rounded-circle ${
                            current == index ? "active" : ""
                          }'></div>`;
                        })
                        .join("");
                    },
                  }}
                >
                  {sliders}
                </Swiper>
              ) : (
                sliders
              )
            }
          </Media>

          {startPrueba ? null : <div className="stepsBitacora mx-auto"></div>}
        </div>
      </div>
      {modal}
    </>
  );
};

const InfoPersonal = (props) => {
  const dataUser = props.user;
  const selOption = props.selOption;
  const actualView = props.actualView;

  const formRef = useRef(null);

  const [previewPhotoProfile, setPreviewPhotoProfile] = useState(camera);
  const [photoProfile, setPhotoProfile] = useState(null);
  // console.log(photoProfile);
  const [nombre, setNombre] = useState(null);
  // console.log(nombre);
  const [apellido, setApellido] = useState(null);
  const [genero, setGenero] = useState("M");
  const [correo, setCorreo] = useState(null);
  const [nacimiento, setNacimiento] = useState("2000-01-01");
  const [estatura, setEstatura] = useState(null);
  const [peso, setPeso] = useState(null);

  const [tel, setTel] = useState(null);

  // const [ciudad, setciudad] = useState(null);
  const [direccion, setDireccion] = useState(null);
  const [Equipos, setEquipos] = useState(null);
  const [modificarLesiones, setModificarLesiones] = useState(false);
  const [infoLesiones, setInfoLesiones] = useState(null);
  // console.log(infoLesiones);
  const moment = require("moment-timezone");
  const allzones = moment.tz.names();
  const [isLoaded, setIsLoaded] = useState(false);
  // console.log("isLoaded", isLoaded);
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [displayCorrect, setDisplayCorrect] = useState(null);
  const [confirm, setModalConfirm] = useState(null);

  const [data, setOldData] = useState();

  useEffect(() => {
    instance
      .get(`customers/injuries/?customer=${localStorage.uuid}`)
      .then((response) => {
        const info = response.data;
        // console.log("´´´´´´´´´´´´´´´´´´´´´´´´´´´´", info);
        if (info != null) {
          setInfoLesiones(info);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return "axios request cancelled";
        }
        return err;
      });
  }, []);
  useEffect(() => {
    if (displayCorrect) handleAtualizarcontraseña();
  }, [displayCorrect]);
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const formData = new FormData();
    formData.append("data", localStorage.my_code);

    instance
      .get(`${NuevaAxiosURL}users/`)
      .then((res) => {
        setOldData(res.data);
        const info = res.data;

        if (info != null) {
          laptop575.matches
            ? (formRef.current.children[0].children[0].children[1].children[0].children[0].children.namedItem(
                "nombres"
              ).value = info.first_name)
            : (formRef.current.children.namedItem("nombres").value =
                info.first_name);
          setNombre(info.first_name);
          laptop575.matches
            ? (formRef.current.children[0].children[0].children[1].children[0].children[1].children.namedItem(
                "apellidos"
              ).value = info.last_name)
            : (formRef.current.children.namedItem("apellidos").value =
                info.last_name);

          setApellido(info.last_name);
          laptop575.matches
            ? (formRef.current.children[0].children[0].children[1].children[0].children[2].children.namedItem(
                "email"
              ).value = info.email)
            : (formRef.current.children.namedItem("email").value = info.email);

          setCorreo(info.email);
          if (info.customer) {
            if (laptop575.matches) {
              formRef.current.children[0].children[0].children[1].children[3].children[0].children.namedItem(
                "equipos"
              ).value = info.customer.equipment;
            }

            laptop575.matches
              ? (formRef.current.children[0].children[0].children[1].children[1].children[0].children.namedItem(
                  "genero"
                ).value =
                  info.customer.gender == 2
                    ? "M"
                    : info.customer.gender == 1
                    ? "H"
                    : "N/A")
              : (formRef.current.children.namedItem("genero").value =
                  info.customer.gender == 2
                    ? "M"
                    : info.customer.gender == 1
                    ? "H"
                    : "N/A");

            setGenero(
              info.customer.gender == 2
                ? "M"
                : info.customer.gender == 1
                ? "H"
                : "N/A"
            );
            laptop575.matches
              ? (formRef.current.children[0].children[0].children[1].children[1].children[1].children[0].children.namedItem(
                  "nacimiento"
                ).value = info.customer.birthday)
              : (formRef.current.children.namedItem("nacimiento").value =
                  info.customer.birthday);
            setNacimiento(info.customer.birthday);
            laptop575.matches
              ? (formRef.current.children[0].children[0].children[1].children[1].children[2].children[0].children.namedItem(
                  "estatura"
                ).value = info.customer.height)
              : (formRef.current.children.namedItem("estatura").value =
                  info.customer.height);

            setEstatura(info.customer.height);
            laptop575.matches
              ? (formRef.current.children[0].children[0].children[1].children[1].children[2].children[1].children.namedItem(
                  "peso"
                ).value = info.customer.weight)
              : (formRef.current.children.namedItem("peso").value =
                  info.customer.weight);
            setPeso(info.customer.weight);

            laptop575.matches
              ? (formRef.current.children[0].children[0].children[1].children[2].children[0].children.namedItem(
                  "direccion"
                ).value = info.address)
              : (formRef.current.children.namedItem("direccion").value =
                  info.address);

            setDireccion(info.address);
            setTel(info.mobile_number);

            setPreviewPhotoProfile(
              `${info.customer.profile_pic}?cache=${Math.random()}`
            );
          }
          setPreviewPhotoProfile(
            info.customer.profile_pic ? info.customer.profile_pic : dataUser.img
          );
          setIsLoaded(true);

          laptop575.matches
            ? (formRef.current.children[0].children[0].children[1].children[2].children[1].children[1].namedItem(
                "telefono"
              ).value = info.mobile_number)
            : (formRef.current
                .querySelector(".PhoneInput")
                .children.namedItem("telefono").value = info.mobile_number);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return "axios request cancelled";
        }
        return err;
      });
    return () => {
      source.cancel("axios request cancelled");
    };
  }, []);
  if (actualView != "perfil") return <></>;

  const labelInput = (
    name,
    labelClasses,
    label,
    inputClasses,
    placeholder,
    type,
    functionChange,
    disabled = false
  ) => {
    return (
      <>
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
        <input
          type={type}
          name={name}
          id={name}
          className={inputClasses}
          placeholder={placeholder}
          onInput={functionChange}
          disabled={disabled}
        />
      </>
    );
  };
  const labelInputweb = (
    name,
    labelClasses,
    label,
    inputClasses,
    placeholder,
    type,
    functionChange,
    disabled = false
  ) => {
    return (
      <div className="d-row containerweb my-3 mx-2">
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
        <input
          type={type}
          name={name}
          id={name}
          className={inputClasses}
          placeholder={placeholder}
          onInput={functionChange}
          disabled={disabled}
        />
      </div>
    );
  };

  const changePhoto = (event) => {
    const photo = event.target.files[0];
    // console.log("event.target.files[0]", event.target.files[0]);
    setPhotoProfile(photo);
    setPreviewPhotoProfile(URL.createObjectURL(photo));
  };

  function handleSubmitProfile(e) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalConfirm(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "7%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #7B7B7B",
              cursor: "pointer",
            }}
            onClick={() => setModalConfirm(null)}
          >
            X
          </div>
          <div className="icon my-1">
            <img src={cara} alt="cara" />
          </div>
          {laptop575.matches ? <br></br> : <></>}
          <div className="title">¿Deseas modificar tu perfil?</div>
          {laptop575.matches ? <br></br> : <></>}
          <div className="btnAction" onClick={handleOk}>
            <ButtonMob
              colorButton={"rgb(158, 172, 237)"}
              text="Confirmar"
              className="mb-3"
            />
          </div>
          <div className="btnAction" onClick={() => setModalConfirm(null)}>
            <ButtonMob text="Descartar" className="mt-3 mb-3 btn-danger" />
          </div>
        </div>
      </div>
    );
  }
  function modalactualizar() {
    setModalConfirm(null);
  }
  function Change_contraseña(event) {
    const change1 = event.target[0].value;
    const change2 = event.target[1].value;

    var formData = new FormData();
    formData.append("password", change1.trim()); //pass1
    formData.append("password2", change2.trim()); //pass1

    instance
      .post(`users/auth/change-password/`, formData)
      .then((res) => {
        if (res.data) {
          setModalConfirm(null);
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        console.log(msg_error);
        if (msg_error) {
          seterroraGregado(msg_error);

          setDisplayCorrect("inherit");
          // setTimeout(() => {
          //   setDisplayCorrect("none");
          // }, 5000);
        }
      });

    event.preventDefault();
  }

  function handleAtualizarcontraseña() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalConfirm(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div className="icon my-3">
            <img
              src={candado}
              alt="cara"
              style={
                laptop575.matches ? null : { height: "3rem", width: "3rem" }
              }
            />
          </div>
          <div className="title mb-3 py-1">Ingresa tu nueva contraseña</div>
          <form onSubmit={(e) => Change_contraseña(e)}>
            {" "}
            <label
              className=" text-left"
              style={
                laptop575.matches
                  ? {
                      width: "80%",
                      fontSize: "1rem",
                      margin: "auto",
                      color: "#394F65",
                    }
                  : { width: "70%", paddingTop: "1rem" }
              }
            >
              Nueva contraseña
            </label>
            <input
              style={
                laptop575.matches
                  ? {
                      width: "80%",
                      fontSize: "1rem",
                    }
                  : { width: "70%", color: "#9DACBD" }
              }
              name="tester"
              placeholder="**********"
              required
            />
            {ErroraGregado ? (
              <div
                id="passmail_error1"
                className=""
                style={
                  laptop575.matches
                    ? {
                        display: `${displayCorrect}`,
                        color: "#CB3D82",
                        fontSize: "0.8rem",
                        margin: "0 4rem",
                      }
                    : {
                        display: `${displayCorrect}`,
                        color: "#CB3D82",
                        fontSize: "0.7rem",
                        marginRight: "14%",
                        marginLeft: "14%",
                        textAlign: "left",
                      }
                }
              >
                {ErroraGregado &&
                ErroraGregado.password == "Password fields didn't match."
                  ? "Las contraseñas no coinciden"
                  : ErroraGregado.password}
              </div>
            ) : null}
            {laptop575.matches ? (
              <>
                {" "}
                <br />
                <br />{" "}
              </>
            ) : null}
            <label
              className=" text-left"
              style={
                laptop575.matches
                  ? {
                      width: "80%",
                      fontSize: "1rem",
                      margin: "auto",
                      color: "#394F65",
                    }
                  : { width: "70%" }
              }
            >
              Confirmar nueva contraseña
            </label>
            <input
              style={
                laptop575.matches
                  ? {
                      width: "80%",
                      fontSize: "1rem",
                    }
                  : { marginBottom: "0vh", width: "70%", color: "#9DACBD" }
              }
              name="tester"
              placeholder="**********"
              required
            />
            {ErroraGregado ? (
              <div
                id="passmail_error1"
                className=""
                style={
                  laptop575.matches
                    ? {
                        display: `${displayCorrect}`,
                        color: "#CB3D82",
                        fontSize: "0.8rem",
                        margin: "0 4rem",
                      }
                    : {
                        display: `${displayCorrect}`,
                        color: "#CB3D82",
                        fontSize: "0.7rem",
                        marginRight: "14%",
                        marginLeft: "14%",
                        textAlign: "left",
                      }
                }
              >
                {ErroraGregado &&
                ErroraGregado.password == "Password fields didn't match."
                  ? "Las contraseñas no coinciden"
                  : ErroraGregado.password}
              </div>
            ) : null}
            <div className="col-12">
              <div className="col-6 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  text="Confirmar"
                  className={
                    laptop575.matches
                      ? "mb-3 mt-3 letraspopfi2actualizar"
                      : "btn-confirmar-new letraspopfi2actualizar py-2"
                  }
                />
              </div>
              <div
                className="col-6 btnAction_pruebas letraspopfi1"
                onClick={modalactualizar}
              >
                <ButtonMob
                  text="Cancelar"
                  className={
                    laptop575.matches
                      ? "mt-3 mb-3 btn-danger letraspopfi2actualizar"
                      : " btn-danger letraspopfi2actualizar py-2"
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  async function handleOk() {
    sendData();
  }
  // console.log("nombre", nombre);
  // console.log("direccion", direccion);

  const sendData = () => {
    sendDataFinal();

    setModalConfirm(<></>);
  };

  const sendDataFinal = () => {
    const formData = new FormData();
    const formData1 = new FormData();
    formData.append("data", localStorage.my_code);

    formData.append("first_name", nombre);
    formData.append("last_name", apellido);
    formData.append("email", correo);
    formData.append("address", direccion); // The index 8 is refer to the img
    formData.append("mobile_number", tel);

    formData1.append(
      "gender",

      genero == "M" ? 2 : genero == "H" ? 1 : 9
    );
    if (nacimiento) {
      formData1.append("birthday", nacimiento);
    }
    formData1.append("height", estatura);
    formData1.append("weight", peso);
    if (Equipos) formData1.append("equipment", Equipos);
    formData1.append("profile_pic", photoProfile ? photoProfile : "");

    instance
      .patch(`${NuevaAxiosURL}customers/`, formData1)
      .then((response) => {
        const data = response.data;
        instance
          .patch(`${NuevaAxiosURL}users/`, formData)
          .then((response) => {
            const data = response.data;

            setModalConfirm(
              <ModalInfo
                error="false"
                handleClick={() => window.location.reload()}
                title="Perfil actualizado"
                text=""
                classesMsg=""
              />
            );
            setModificarLesiones(true); // Enviar los cambios en las lesiones
            setModificarLesiones(false);
            setTimeout(() => {
              // window.location.reload();
            }, 2500);
          })
          .catch((err) => {
            console.error(`Error en users/${localStorage.uuid}`, err.response);
          });
      })
      .catch((err) => {
        console.error(`Error en customers/`, err.response);
      });
  };

  return laptop575.matches ? (
    <div className="perfil scrollperfil">
      <div className={`${isLoaded == false ? "" : "d-none"} my-3`}>
        <Loadingspinner customStyle="grow" size="3rem" />
      </div>

      <form
        ref={formRef}
        className={`${
          isLoaded == false ? "invisible" : ""
        } formSteps mx-auto perfilcorregirparam `}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="d-row containerweb">
          <div className="d-flex ">
            <div className="d-row containerweb2">
              <div
                className="d-flex justify-content-center "
                style={{
                  position: "relative",
                  top: "7%",
                  left: "-5%",
                }}
              >
                <img
                  src={warning2}
                  alt="info"
                  data-effect="solid"
                  data-place="bottom"
                  className="btnInfocamera position-absolute "
                  data-event="click focus"
                  data-background-color="#CB3D82"
                  data-text-color="white"
                  data-class="tooltipPhoto"
                  data-tip="<strong>¿Por qué necesitamos tu foto?</strong><br/>Todas tus rutinas son personalizadas y solamente para ti. Si alguien más toma tus clases, es posible que pueda sufrir algún tipo de lesión."
                  data-html={true}
                />
                <ReactTooltip globalEventOff="click" />
                <input
                  type="file"
                  accept="image/*"
                  className="photoProfile"
                  name="photo"
                  id="photo"
                  onChange={changePhoto}
                />
                <div className="contetfototoalusuario">
                  <label
                    htmlFor="photo"
                    className="labelPhotocamera rounded-circle labelPhotocamera1"
                    style={{ backgroundImage: `url(${previewPhotoProfile})` }}
                  ></label>
                </div>
              </div>
            </div>
            <div className="containerweb">
              <div className="d-flex">
                {labelInputweb(
                  "nombres",
                  "d-flex colornuevo1",
                  "Nombres",
                  "form-control colornuevo",
                  "Jhon",
                  "text",
                  (e) => setNombre(e.target.value)
                )}

                {labelInputweb(
                  "apellidos",
                  "d-flex colornuevo1",
                  "Apellidos",
                  "form-control colornuevo",
                  "Doe",
                  "text",
                  (e) => setApellido(e.target.value)
                )}

                {labelInputweb(
                  "email",
                  "d-flex colornuevo1",
                  "Correo electrónico",
                  "form-control colornuevo",
                  "correo@electronico.com",
                  "email",
                  (e) => setCorreo(e.target.value),
                  true
                )}
              </div>
              <div className="d-flex w-100">
                <div className="d-row anchogenero my-3  mx-2 ">
                  <label htmlFor="genero" className="d-flex colornuevo1">
                    Género
                  </label>
                  <select
                    name="genero"
                    className="form-select  colornuevo selectnuevofelcha "
                    onChange={(event) => {
                      setGenero(event.target.value);
                    }}
                  >
                    <option style={{ color: "#BEBEBE" }} value="M">
                      Mujer
                    </option>
                    <option style={{ color: "#BEBEBE " }} value="H">
                      Hombre
                    </option>
                    <option style={{ color: "#BEBEBE " }} value="N/A">
                      N/A
                    </option>
                  </select>
                </div>
                <div className=" anchogenero mxfecha   " id="nacimiento1">
                  {labelInputweb(
                    "nacimiento",
                    "d-flex colornuevo1",
                    "Fecha de nacimiento",
                    "form-control dateBorn colornuevo  ",
                    "DD/MM/AAAA",
                    "date",
                    (event) => setNacimiento(event.target.value)
                  )}
                </div>
                <div className=" anchogenero d-flex   ">
                  {labelInputweb(
                    "estatura",
                    "d-flex colornuevo1",
                    "Estatura (cm)",
                    "form-control colornuevo mx-1 ",
                    "Ej: 150",
                    "number",
                    (e) => setEstatura(e.target.value)
                  )}

                  {labelInputweb(
                    "peso",
                    "d-flex colornuevo1",
                    "Peso (Kg)",
                    "form-control colornuevo",
                    "Ej: 70",
                    "number",
                    (e) => setPeso(e.target.value)
                  )}
                </div>
              </div>
              <div className="d-flex  direccionwhata ">
                {labelInputweb(
                  "direccion",
                  "d-flex  colornuevo1",
                  "Dirección",
                  "form-control colornuevo containerweb3   ",
                  "Ej: Calle 57 # 24-65",
                  "text",
                  (e) => setDireccion(e.target.value)
                )}

                <div className="d-row my-3 containerweb5 colornuevo">
                  <label
                    htmlFor="telefono"
                    className="form-label mb-2 colornuevo1"
                  >
                    Teléfono
                  </label>

                  <PhoneInput
                    className="  telefonoroto colornuevo"
                    defaultCountry="CO"
                    initialValueFormat="national"
                    countryCallingCodeEditable={false}
                    name="telefono"
                    value={tel}
                    placeholder="3156671354"
                    onChange={setTel}
                  />
                </div>
              </div>
              <div className="containerweb4 ml-1 mxfecha1">
                {labelInputweb(
                  "equipos",
                  "d-flex colornuevo1 ",
                  "Equipos de entrenamiento disponibles",
                  "form-control colornuevo containerweb3",
                  "Mancuerna de 10 libras",
                  "text",

                  (e) => setEquipos(e.target.value)
                )}
              </div>
              <button
                style={{ marginRight: "1rem" }}
                className="btnActualizar "
                onClick={handleSubmitProfile}
              >
                Actualizar
              </button>
              <button
                className="btnActualizar "
                onClick={handleAtualizarcontraseña}
              >
                Cambiar contraseña
              </button>
            </div>
          </div>
          {/* <div>
                  {labelInput("pais_ciudad", "d-flex", "Pais / Ciudad", "form-control", "Ej: Colombia / Cartagena", "text", (e) => setciudad(e.target.value))}
              </div> */}
          <div>
            <Lesiones
              infoLesiones={infoLesiones}
              sendDataLes={modificarLesiones}
              isProfile={true}
              handleSubmitProfile={handleSubmitProfile}
            />
          </div>
        </div>
      </form>

      {confirm}
    </div>
  ) : (
    <div className="perfil">
      <div className="titulo">Información Personal</div>

      <div className={`${isLoaded == false ? "" : "d-none"} my-3`}>
        <Loadingspinner customStyle="grow" size="3rem" />
      </div>

      <form
        ref={formRef}
        className={`${isLoaded == false ? "invisible" : ""} formSteps mx-auto`}
        onSubmit={(e) => e.preventDefault()}
      >
        <div
          className="d-flex justify-content-center perfil_tooltip"
          style={{
            position: "relative",
            top: "0%",
            left: "0%",
          }}
        >
          <img
            src={warning2}
            alt="info"
            data-effect="solid"
            data-place="bottom"
            className="btnInfocamera position-absolute "
            data-event="click focus"
            data-background-color="#CB3D82"
            data-text-color="white"
            data-class="tooltipPhoto"
            data-tip="<strong>¿Por qué necesitamos tu foto?</strong><br/>Todas tus rutinas son personalizadas y solamente para ti. Si alguien más toma tus clases, es posible que pueda sufrir algún tipo de lesión."
            data-html={true}
          />
          <ReactTooltip globalEventOff="click" />
          <input
            type="file"
            accept="image/*"
            className="photoProfile"
            name="photo"
            id="photo"
            onChange={changePhoto}
          />
          <div className="contetfototoalusuario">
            <label
              htmlFor="photo"
              className="labelPhotocamera rounded-circle labelPhotocamera1"
              style={{ backgroundImage: `url(${previewPhotoProfile})` }}
            ></label>
          </div>
        </div>

        {labelInput(
          "nombres",
          "d-flex colornuevo1",
          "Nombres",
          "form-control colornuevo",
          "Jhon",
          "text",
          (e) => setNombre(e.target.value)
        )}

        {labelInput(
          "apellidos",
          "d-flex",
          "Apellidos",
          "form-control colornuevo",
          "Doe",
          "text",
          (e) => setApellido(e.target.value)
        )}

        {labelInput(
          "email",
          "d-flex colornuevo1",
          "Correo electrónico",
          "form-control colornuevo",
          "correo@electronico.com",
          "email",
          (e) => setCorreo(e.target.value)
        )}

        <label htmlFor="genero" className="d-flex colornuevo1">
          Género
        </label>
        <select
          name="genero"
          className="form-select  colornuevo selectnuevofelcha"
          onChange={(event) => {
            setGenero(event.target.value);
          }}
        >
          <option style={{ color: "#BEBEBE" }} value="M">
            Mujer
          </option>
          <option style={{ color: "#BEBEBE " }} value="H">
            Hombre
          </option>
          <option style={{ color: "#BEBEBE " }} value="N/A">
            N/A
          </option>
        </select>

        {labelInput(
          "nacimiento",
          "d-none",
          "Fecha de nacimiento",
          "form-control dateBorn d-none ",
          "DD/MM/AAAA",
          "date ",
          (event) => setNacimiento(event.target.value)
        )}

        {labelInput(
          "estatura",
          "d-flex",
          "Estatura (cm)",
          "form-control colornuevo",
          "Ej: 150",
          "number",
          (e) => setEstatura(e.target.value)
        )}

        {labelInput(
          "peso",
          "d-flex",
          "Peso (Kg)",
          "form-control colornuevo",
          "Ej: 70",
          "number",
          (e) => setPeso(e.target.value)
        )}
        {labelInput(
          "direccion",
          "d-flex  ",
          "Dirección",
          "form-control colornuevo ",
          "Ej: Calle 57 # 24-65",
          "text",
          (e) => setDireccion(e.target.value)
        )}

        {/* {labelInput("telefono", "d-flex", "Teléfono", "form-control", "Ej: 3124567890", "number", (e) => setTel(e.target.value))} */}
        <label htmlFor="telefono" className="form-label mb-2">
          Teléfono
        </label>
        <PhoneInput
          className=" mb-4 telefonoroto"
          defaultCountry="CO"
          initialValueFormat="national"
          countryCallingCodeEditable={false}
          name="telefono"
          value={tel}
          placeholder="3156671354"
          onChange={setTel}
        />
        <button className="btnActualizar " onClick={handleAtualizarcontraseña}>
          Cambiar contraseña
        </button>
        <Lesiones sendDataLes={modificarLesiones} isProfile={true} />

        <button className="btnActualizar" onClick={handleSubmitProfile}>
          Actualizar
        </button>
      </form>

      {confirm}
    </div>
  );
};

export const Lesiones = (props) => {
  const trainer = props.trainer;

  const lesionesdesdetrainer = props.lesionesdesdetrainer;
  const isProfile = props.isProfile;
  const [confirm, setModalConfirm] = useState(null);
  const frontalView = useRef(null);
  const backView = useRef(null);
  const infoLesiones = props.infoLesiones || null;
  const forceMediaMatch = props.forceMediaMatch || "true";

  const doloresFrontales = [
    [
      "Cuello",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "10vh"
        : window.location.pathname.includes(`/Usuario/perfil`) &&
          laptop575.matches
        ? "17%"
        : "13%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "48.0%"
        : "47.3%",
      useRef(null),
      useRef(null),
    ],
    [
      "Hombro derecho",
      "20%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "33.3%"
        : "36.3%",
      useRef(null),
      useRef(null),
    ],
    [
      "Codo derecho",
      "33%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "31.5%"
        : window.location.pathname.includes(`/Usuario/perfil`) &&
          laptop575.matches
        ? "31%"
        : "34%",
      useRef(null),
      useRef(null),
    ],
    [
      "Muñeca/Mano derecha",
      "43%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "27.4%"
        : window.location.pathname.includes(`/Usuario/perfil`) &&
          laptop575.matches
        ? "27.5%"
        : "29.4%",
      useRef(null),
      useRef(null),
    ],

    [
      "Hombro izquierdo",
      "20%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "62.3%"
        : "58.3%",
      useRef(null),
      useRef(null),
    ],
    [
      "Codo izquierdo",
      "33%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "64.0%"
        : window.location.pathname.includes(`/Usuario/perfil`) &&
          laptop575.matches
        ? "62%"
        : "61%",
      useRef(null),
      useRef(null),
    ],
    [
      "Muñeca/Mano izquierda",
      "43%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "68.4%"
        : window.location.pathname.includes(`/Usuario/perfil`) &&
          laptop575.matches
        ? "66.3%"
        : "65.3%",
      useRef(null),
      useRef(null),
    ],

    ["Abdomen", "34.5%", "47.5%", useRef(null), useRef(null)],
    ["Ingle / Cadera", "45%", "47.5%", useRef(null), useRef(null)],

    [
      "Rodilla Izquierda",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "63.4%"
        : "65%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "54.4%"
        : "53.5%",
      useRef(null),
      useRef(null),
    ],
    [
      "Rodilla derecha",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "63.4%"
        : "65%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "40%"
        : "41%",
      useRef(null),
      useRef(null),
    ],

    [
      "Pie Izquierdo",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "83.2%"
        : "82.6%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "52.4%"
        : "51.5%",
      useRef(null),
      useRef(null),
    ],
    [
      "Pie derecho",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "83.2%"
        : "82.6%",
      window.location.pathname.includes(`ver`) && laptop575.matches
        ? "42.4%"
        : "43.3%",
      useRef(null),
      useRef(null),
    ],
  ];

  const [msgDoloresFrontales, setFrontalMsg] = useState(
    new Array(doloresFrontales.length).fill("")
  );
  const [oldMsgFront, setOldFront] = useState(
    new Array(doloresFrontales.length).fill("")
  );
  const dolorActivoFrontal = new Array(doloresFrontales.length).fill(false);
  // console.log(doloresFrontales[3]);
  const changeDolorFrontal = (index, msg, placeTextarea = false) => {
    const copy = msgDoloresFrontales;

    copy[index] = msg;
    // console.log(copy[index]);
    dolorActivoFrontal[index] = msg != "";
    doloresFrontales[index][4].current.src = dolorActivoFrontal[index]
      ? dolorB
      : dolorA;
    if (placeTextarea) {
      const modalText = doloresFrontales[index][3];

      if (modalText.current) {
        modalText.current.querySelector("textarea").value = msg;
      }

      // console.log(modalText.current.querySelector("textarea"));
      const otherCopy = [...copy];
      setOldFront(otherCopy);
    }

    setFrontalMsg(copy);
  };
  const changeDolorFrontalcompletar = (index, msg, placeTextarea = false) => {
    const copy = msgDoloresFrontales;

    copy[index] = msg;

    dolorActivoFrontal[index] = msg != "";
    doloresFrontales[index][4].current.src = dolorActivoFrontal[index]
      ? dolorB
      : dolorA;
    if (placeTextarea) {
      const modalText = doloresFrontales[index][3];

      modalText.current.querySelector("textarea").value = msg;

      const otherCopy = [...copy];
      setOldFront(otherCopy);
    }

    setFrontalMsg(copy);
  };

  const sendDataLes = props.sendDataLes;

  const doloresPosteriores = [
    ["Columna alta", "17%", "47.3%", useRef(null), useRef(null), useRef(null)],
    [
      "Columna baja",
      "26.5%",
      "47.3%",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    ["Lumbares", "36%", "47.3%", useRef(null), useRef(null), useRef(null)],
    ["Gluteos", "46%", "47.3%", useRef(null), useRef(null), useRef(null)],
  ];
  const [msgDoloresPosteriores, setPosteriorMsg] = useState(
    new Array(doloresPosteriores.length).fill("")
  );

  const [oldMsgBack, setOldBack] = useState(
    new Array(doloresPosteriores.length).fill("")
  );

  const dolorActivoBack = new Array(doloresPosteriores.length).fill(false);
  const changeDolorPosterior = (index, msg, placeTextarea = false) => {
    const copy = msgDoloresPosteriores;

    copy[index] = msg;
    dolorActivoBack[index] = msg != "";
    //doloresPosteriores[index][4].current.style.backgroundColor = (dolorActivoBack[index] ? "red" : "")
    doloresPosteriores[index][4].current.src = dolorActivoBack[index]
      ? dolorB
      : dolorA;
    if (placeTextarea) {
      const modalText = doloresPosteriores[index][3];

      if (modalText.current) {
        modalText.current.querySelector("textarea").value = msg;
      }
      const otherCopy = [...copy];
      setOldBack(otherCopy);
    }
    setPosteriorMsg(copy);
  };
  const changeDolorPosteriorcompletar = (index, msg, placeTextarea = false) => {
    const copy = msgDoloresPosteriores;

    copy[index] = msg;

    dolorActivoBack[index] = msg != "";
    doloresPosteriores[index][4].current.src = dolorActivoBack[index]
      ? dolorB
      : dolorA;
    if (placeTextarea) {
      const modalText = doloresPosteriores[index][3];
      modalText.current.querySelector("textarea").value = msg;

      const otherCopy = [...copy];
      setOldBack(otherCopy);
    }

    setPosteriorMsg(copy);
  };
  const changeDolorPosteriord = (index, msg, placeTextarea = false) => {
    const copy = msgDoloresPosteriores;

    if (msg) {
      copy[index] = msg;
      dolorActivoBack[index] = msg != "";
      //doloresPosteriores[index][4].current.style.backgroundColor = (dolorActivoBack[index] ? "red" : "")
      doloresPosteriores[index][4].current.src = dolorActivoBack[index]
        ? dolorB
        : dolorA;
      if (placeTextarea) {
        const modalText = document.getElementById("polar");

        modalText.children[0].value = msg;
        const otherCopy = [...copy];
        setOldBack(otherCopy);
      }
      setPosteriorMsg(copy);
    } else {
      const modalText = document.getElementById("polar");

      modalText.children[0].value = msg;
      const otherCopy = [...copy];
      setOldBack(otherCopy);
    }
  };
  const changeDolorFrontald = (index, msg, placeTextarea = false) => {
    const copy = msgDoloresFrontales;

    if (msg) {
      copy[index] = msg;

      dolorActivoFrontal[index] = msg != "";
      doloresFrontales[index][4].current.src = dolorActivoFrontal[index]
        ? dolorB
        : dolorA;
      if (placeTextarea) {
        const modalText = document.getElementById("polar");

        modalText.children[0].value = msg;
        const otherCopy = [...copy];
        setOldFront(otherCopy);
      }
    } else {
      const modalText = document.getElementById("polar");

      modalText.children[0].value = msg;
      const otherCopy = [...copy];
      setOldFront(otherCopy);
    }

    setFrontalMsg(copy);
  };
  const showDolorinicial = () => {
    const all = document.getElementsByClassName("all");

    for (const i of all) {
      i.style.display = "none";
    }

    all[0].style.display = "flex";
  };

  const showDolor = (ref) => {
    const all = document.getElementsByClassName("all");

    for (const i of all) {
      i.style.display = "none";
    }

    ref.current.style.display = "flex";
  };
  const ocultarDolor = (ref) => {
    ref.current.style.display = "none";
  };
  const changeView = (ref) => {
    if (ref == backView) {
      backView.current.style.display = "block";
      frontalView.current.style.display = "none";
    } else {
      backView.current.style.display = "none";
      frontalView.current.style.display = "block";
    }
  };
  const laptop = window.matchMedia("(min-width: 920px)");
  const [dolorsilueta, setdolorsilueta] = useState(null);
  const [numerodedolor, setnumerodedolor] = useState([]);

  const [mostrardolor, setmostrardolor] = useState(null);

  useEffect(() => {}, []);
  function hideModal() {
    setmostrardolor(null);
  }

  const botonesDolores = doloresFrontales.map((dolor, index) => {
    return (
      <span key={index} style={{ cursor: "pointer" }}>
        <img
          src={dolorA}
          ref={dolor[4]}
          className={`dolor rounded-circle`}
          style={{ top: dolor[1], left: dolor[2] }}
          onClick={() =>
            !path.pathname.includes(`ver`) &&
            !window.location.pathname.includes`Entrenador/clase/`
              ? showDolor(dolor[3])
              : infoLesiones &&
                infoLesiones.filter((e) => e.part < 14).length > 0
              ? setmostrardolor(
                  <ModalInfo
                    error="false"
                    array={infoLesiones.filter((e) => e.part < 14)}
                    position={props.position}
                    handleClick={hideModal}
                    handleCancellcargue={hideModal}
                    title="Lesionado"
                    text={`El usuario a confirmado ${
                      infoLesiones.filter((e) => e.part < 14).length
                    }  ${
                      infoLesiones.filter((e) => e.part < 14).length > 1
                        ? "lesiones."
                        : "lesión."
                    }`}
                  />
                )
              : setmostrardolor(
                  <ModalInfo
                    position={props.position}
                    error="false"
                    handleClick={hideModal}
                    title="El usuario
no presenta 
ninguna lesión"
                  />
                ) || setdolorsilueta(dolor[0])
          }
        ></img>
      </span>
    );
  });

  const botonesDoloresweb = doloresFrontales.map((dolor, index) => {
    return (
      <span key={index} style={{ cursor: "pointer" }}>
        <img
          src={dolorA}
          ref={dolor[4]}
          className={`dolor rounded-circle`}
          style={{ top: dolor[1], left: dolor[2] }}
          onClick={() =>
            !laptop.matches
              ? showDolor(dolor[3])
              : escogersilueta1(dolor[0], index, dolor[3])
          }
        ></img>
      </span>
    );
  });
  function escogersilueta(dolor, index, dolor3) {
    if (window.location.pathname.includes(`Usuario/completar`)) {
      setnumerodedolor([index, "post"]);
      showDolor(dolor3);
      setdolorsilueta(dolor);
    } else {
      setDoloresRenderd(infoLesiones, index);
      setdolorsilueta(dolor);
      setnumerodedolor([index, "post"]);
    }
  }
  function escogersilueta1(dolor, index, dolor3) {
    if (window.location.pathname.includes(`Usuario/completar`)) {
      setnumerodedolor([index, "front"]);
      showDolor(dolor3);
      setdolorsilueta(dolor);
    } else {
      setDoloresRenderdpos(infoLesiones, index);
      setdolorsilueta(dolor);
      setnumerodedolor([index, "front"]);
    }
  }
  const botonesDoloresweb1 = doloresPosteriores.map((dolor, index) => {
    return (
      <span key={index} style={{ cursor: "pointer" }}>
        <img
          src={dolorA}
          ref={dolor[4]}
          className={`dolor rounded-circle`}
          style={{ top: dolor[1], left: dolor[2] }}
          onClick={() =>
            !laptop.matches
              ? showDolor(dolor[3])
              : escogersilueta(dolor[0], index, dolor[3])
          }
        ></img>
      </span>
    );
  });

  function eliminarDolor(entrada) {
    if (entrada) {
      instance
        .get(`customers/injuries/?customer=${localStorage.uuid}`)
        .then((response) => {
          const info = response.data;

          const buscarId = info
            .filter((e) => e.part == entrada)
            .sort(function (a, b) {
              const fechaA = a.id;
              const fechaB = b.id;
              if (fechaA > fechaB) {
                return -1;
              }
              if (fechaA < fechaB) {
                return 1;
              }
              // names must be equal
              return 0;
            });

          if (buscarId.length > 0) {
            let formData = new FormData();
            formData.append("active", false);
            instance
              .patch(`customers/injuries/${buscarId[0].id}`, formData)
              .then((response) => {
                window.location.reload();
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }

  const modalesDolores = doloresFrontales.map((dolor, index) => {
    return laptop575.matches ? (
      <></>
    ) : (
      <ModalInfo1
        key={index}
        position={props.position}
        ref={dolor[3]}
        handleDelete={(e) =>
          eliminarDolor(convertId(parseInt(index), false, false))
        }
        textDelete="Borrar lesión"
        handleClick={() => ocultarDolor(dolor[3])}
        title={dolor[0]}
        text="input"
        display="none"
        handleInput={(e) => changeDolorFrontal(index, e.target.value)}
      />
    );
  });
  const botonesDoloresBack = doloresPosteriores.map((dolor, index) => {
    return (
      <span key={index}>
        <img
          src={dolorA}
          ref={dolor[4]}
          className="dolor rounded-circle"
          style={{ top: dolor[1], left: dolor[2] }}
          onClick={() =>
            !path.pathname.includes(`ver`) &&
            !window.location.pathname.includes`Entrenador/clase/`
              ? showDolor(dolor[3])
              : infoLesiones &&
                infoLesiones.filter((e) => e.part > 13).length > 0
              ? setmostrardolor(
                  <ModalInfo
                    error="false"
                    array={infoLesiones.filter((e) => e.part > 13)}
                    position={props.position}
                    handleClick={hideModal}
                    title="Lesionado"
                    text={`El usuario a confirmado ${
                      infoLesiones.filter((e) => e.part > 13).length
                    }  ${
                      infoLesiones.filter((e) => e.part > 13).length > 1
                        ? "lesiones."
                        : "lesión."
                    }`}
                    classesMsg=""
                  />
                )
              : setmostrardolor(
                  <ModalInfo
                    position={props.position}
                    error="false"
                    handleClick={hideModal}
                    title="El usuario
no presenta 
ninguna lesión"
                  />
                ) || setdolorsilueta(dolor[0])
          }
        ></img>
      </span>
    );
  });
  const modalesDoloresBack = doloresPosteriores.map((dolor, index) => {
    return laptop575.matches ? (
      <></>
    ) : (
      <ModalInfo1
        key={index}
        position={props.position}
        ref={dolor[3]}
        textDelete="Borrar lesión"
        handleDelete={(e) =>
          eliminarDolor(convertId(parseInt(index), false, true))
        }
        handleClick={() => ocultarDolor(dolor[3])}
        title={dolor[0]}
        text="input"
        display="none"
        handleInput={(e) => changeDolorPosterior(index, e.target.value)}
      />
    );
  });

  function convertId(id, id_to_index = true, is_back = false) {
    if (id_to_index) {
      switch (id) {
        case 1:
          return [0, true]; //p2_cuello_data => Cuello
        case 3:
          return [1, true]; // p1_ombro1_data => hombro derecho
        case 5:
          return [2, true]; //p2_codo2_data => Codo derecho
        case 7:
          return [3, true]; //p1_mano1_data => Mano Derecha
        case 2:
          return [4, true]; //p1_ombro2_data => hombro izquierdo
        case 4:
          return [5, true]; //p2_codo1_data => Codo izq
        case 6:
          return [6, true]; //p1_mano2_data => Mano Izquierda
        case 8:
          return [7, true]; //p1_abdomen_data => Abdomen
        case 9:
          return [8, true]; //p1_cadera_data => Ingle Cadera
        case 10:
          return [9, true]; //p1_rodilla2_data => Rodilla Izquierda
        case 11:
          return [10, true]; //p1_rodilla1_data => Rodilla  derecha
        case 12:
          return [11, true]; //p1_pie2_data => Pie izqueirdo
        case 13:
          return [12, true]; //p1_pie1_data => Pie derecho

        case 14:
          return [0, false]; //p2_espalda_data => Columna alta
        case 16:
          return [1, false]; //p2_colubna_data => Columna baja
        case 17:
          return [2, false]; //p1_muslo2_data => Lumbares
        case 15:
          return [3, false]; //p1_muslo1_data => Gluteos
        default:
          return [-1, false];
      }
    } else {
      if (is_back) {
        switch (id) {
          case 0:
            return 14; //p2_espalda_data => Columna alta
          case 1:
            return 16; //p2_colubna_data => Columna baja
          case 2:
            return 17; //p1_muslo2_data => Lumbares
          case 3:
            return 15; //p1_muslo1_data => Gluteos
          default:
            return [-1, false];
        }
      } else {
        switch (id) {
          case 0:
            return 1; //p2_cuello_data => Cuello
          case 1:
            return 3; // p1_ombro1_data => hombro derecho
          case 2:
            return 5; //p2_codo2_data => Codo derecho
          case 3:
            return 7; //p1_mano1_data => Mano Derecha
          case 4:
            return 2; //p1_ombro2_data => hombro izquierdo
          case 5:
            return 4; //p2_codo1_data => Codo izq
          case 6:
            return 6; //p1_mano2_data => Mano Izquierda
          case 7:
            return 8; //p1_abdomen_data => Abdomen
          case 8:
            return 9; //p1_cadera_data => Ingle Cadera
          case 9:
            return 10; //p1_rodilla2_data => Rodilla Izquierda
          case 10:
            return 11; //p1_rodilla1_data => Rodilla  derecha
          case 11:
            return 12; //p1_pie2_data => Pie izqueirdo
          case 12:
            return 13; //p1_pie1_data => Pie derecho
          default:
            return [-1, false];
        }
      }
    }
    return false;
  }

  function setDoloresRender(info) {
    if (
      window.location.pathname.includes(`Usuario/completar`) &&
      laptop575.matches.matches
    ) {
      for (const les of info.sort(function (a, b) {
        const fechaA = b.id;
        const fechaB = a.id;
        if (fechaA > fechaB) {
          return -1;
        }
        if (fechaA < fechaB) {
          return 1;
        }
        // names must be equal
        return 0;
      })) {
        // alert(info);
        const msg = les.summary;

        const [id, isFront] = convertId(les.part, true, true);

        if (id == -1) {
          continue;
        }
        if (isFront) {
          changeDolorFrontalcompletar(id, msg, true);
        } else {
          changeDolorPosteriorcompletar(id, msg, true);
        }
      }
    } else {
      for (const les of info.sort(function (a, b) {
        const fechaA = b.id;
        const fechaB = a.id;
        if (fechaA > fechaB) {
          return -1;
        }
        if (fechaA < fechaB) {
          return 1;
        }
        // names must be equal
        return 0;
      })) {
        // alert(info);
        const msg = les.summary;

        const [id, isFront] = convertId(les.part, true, true);

        if (id == -1) {
          continue;
        }
        if (isFront) {
          changeDolorFrontal(id, msg, true);
        } else {
          changeDolorPosterior(id, msg, true);
        }
      }
    }
  }
  function setDoloresRenderd(info, index) {
    for (const les of info.sort(function (a, b) {
      const fechaA = b.id;
      const fechaB = a.id;
      if (fechaA > fechaB) {
        return -1;
      }
      if (fechaA < fechaB) {
        return 1;
      }
      // names must be equal
      return 0;
    })) {
      // alert(info);
      let idf = convertId(index, false, true);

      let msg =
        info.filter((e) => e.part == idf).length > 0
          ? info
              .filter((e) => e.part == idf)
              .sort(function (a, b) {
                const fechaA = a.id;
                const fechaB = b.id;
                if (fechaA > fechaB) {
                  return -1;
                }
                if (fechaA < fechaB) {
                  return 1;
                }
                // names must be equal
                return 0;
              })[0].summary
          : null;

      changeDolorPosteriord(index, msg, true);
    }
  }
  function setDoloresRenderdpos(info, index) {
    for (const les of info.sort(function (a, b) {
      const fechaA = b.id;
      const fechaB = a.id;
      if (fechaA > fechaB) {
        return -1;
      }
      if (fechaA < fechaB) {
        return 1;
      }
      // names must be equal
      return 0;
    })) {
      // alert(info);
      let idf = convertId(index, false, false);

      let msg =
        info.filter((e) => e.part == idf).length > 0
          ? info
              .filter((e) => e.part == idf)
              .sort(function (a, b) {
                const fechaA = a.id;
                const fechaB = b.id;
                if (fechaA > fechaB) {
                  return -1;
                }
                if (fechaA < fechaB) {
                  return 1;
                }
                // names must be equal
                return 0;
              })[0].summary
          : null;

      changeDolorFrontald(index, msg, true);
    }
  }

  useEffect(() => {
    if (infoLesiones && infoLesiones.length > 0 && laptop.matches) {
      setDoloresRender(infoLesiones);
    }
    return () => {};
  }, [infoLesiones]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    if (lesionesdesdetrainer) {
      setDoloresRender(lesionesdesdetrainer);
    } else {
      if (!trainer) {
        instance
          .get(`customers/injuries/?customer=${localStorage.uuid}`)
          .then((response) => {
            const info = response.data;

            if (info != null) {
              setDoloresRender(info);
              if (
                laptop575.matches &&
                window.location.pathname == `${basename}/Usuario/completar`
              )
                showDolorinicial();
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              return "axios request cancelled";
            }
            return err;
          });
      }
    }
  }, [infoLesiones]);

  useEffect(() => {
    if (sendDataLes) {
      for (const key in msgDoloresPosteriores) {
        if (
          msgDoloresPosteriores[key].normalize() != oldMsgBack[key].normalize()
        ) {
          let to_url = "modificar-lesion";
          if (msgDoloresPosteriores[key].normalize() == "") {
            to_url = "del-lesion";
          }
          const instance = axios.create({
            baseURL: `${NuevaAxiosURL}`,

            headers: { Authorization: "Bearer " + localStorage.my_code },
          });

          const formData1 = new FormData();
          formData1.append("part", convertId(parseInt(key), false, true));
          formData1.append("summary", msgDoloresPosteriores[key]);
          formData1.append("customer", localStorage.uuid);
          formData1.append("active", msgDoloresPosteriores[key] ? true : false);

          instance
            .post(`${NuevaAxiosURL}customers/injuries/new/`, formData1)
            .then((response) => {
              const data = response.data;
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
      }
      for (const key in msgDoloresFrontales) {
        if (
          msgDoloresFrontales[key].normalize() != oldMsgFront[key].normalize()
        ) {
          let to_url = "modificar-lesion";
          if (msgDoloresFrontales[key].normalize() == "") {
            to_url = "del-lesion";
          }
          const instance = axios.create({
            baseURL: `${NuevaAxiosURL}`,

            headers: { Authorization: "Bearer " + localStorage.my_code },
          });

          const formData1 = new FormData();
          formData1.append("part", convertId(parseInt(key), false, false));
          formData1.append("summary", msgDoloresFrontales[key]);
          formData1.append("customer", localStorage.uuid);
          formData1.append("active", msgDoloresFrontales[key] ? true : false);
          instance
            .post(`${NuevaAxiosURL}customers/injuries/new/`, formData1)
            .then((response) => {
              const data = response.data;
              // console.log("lesionesnuevo", data);
            })
            .catch((err) => {
              console.log("---re-----", err.response);
            });
        }
      }
    }
  }, [sendDataLes]);
  function estas_seguro(e) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalConfirm(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div className="icon my-3">
            <img src={cara} alt="cara" />
          </div>
          <div className="title">¿Deseas modificar tu perfil?</div>

          <div className="btnAction" onClick={() => setModalConfirm(null)}>
            <ButtonMob text="Descartar" className="mt-3 mb-3 btn-danger" />
          </div>
          <div
            className="btnAction"
            onClick={(e) =>
              numerodedolor[1] && numerodedolor[1] == "post"
                ? eliminarDolor(
                    convertId(parseInt(numerodedolor[0]), false, true)
                  )
                : eliminarDolor(
                    convertId(parseInt(numerodedolor[0]), false, false)
                  )
            }
          >
            <ButtonMob text="Confirmar" className="mb-3" />
          </div>
        </div>
      </div>
    );
  }
  const path = useLocation();
  return window.location.pathname == `${basename}/Usuario/perfil` &&
    laptop575.matches ? (
    <>
      <div className="acomodarsiluetaperfil">
        <div
          className="row justify-content-evenly "
          style={{ minWidth: "200px" }}
        >
          <div
            className={`containerCuerpoperfil text-center col-12 ${
              props.forceMediaMatch === undefined ? "col-lg-6" : null
            } `}
            ref={frontalView}
            style={{ display: "block" }}
          >
            {botonesDoloresweb}
            <div className="mx-auto  siluetaperfil2 ">
              <Silueta />
            </div>
            <div className="colortextvista">
              Vista <strong className="ms-1">frontal</strong>
            </div>
          </div>
          <div
            className={`containerCuerpoperfil col-12 ${
              props.forceMediaMatch === undefined ? "col-lg-6 d-lg-block" : null
            }`}
            ref={backView}
            style={{ display: "none" }}
          >
            {botonesDoloresweb1}
            <div className="siluetaperfil1 mx-auto  ">
              <Silueta />
            </div>
            <div className="mx-auto text-center colortextvista">
              Vista <strong className="mx-auto">posterior</strong>
            </div>
          </div>
        </div>
        <div className="decirLesion_perfil" id="melomano">
          {" "}
          <div className="decirLesionTituloperfil">Tus lesiones</div>
          <div className="decirLesion01">
            Indicanos la zona del cuerpo y haz una breve descripción de tu
            lesión.
          </div>
          <div className="decirLesion09_categ d-flex">
            <div className="circulorojolesionperfil mx-1 "></div> Lesión{" "}
            {dolorsilueta}
          </div>
          <div className="decirLesion09" id="polar">
            <textarea
              disabled={dolorsilueta ? false : true}
              style={{
                width: "30vw",
                marginTop: "0%",
                height: "20vh",
                border: "1px solid #E2E2E2",
                borderRadius: "10px",
                fontStyle: "italic",
                color: "#9DACBD",
              }}
              onInput={(e) =>
                numerodedolor[1] && numerodedolor[1] == "post"
                  ? changeDolorPosteriord(numerodedolor[0], e.target.value)
                  : changeDolorFrontald(numerodedolor[0], e.target.value)
              }
              placeholder={"Describe aquí tu lesión."}
              rows="5"
            ></textarea>
          </div>
        </div>
        {dolorsilueta ? (
          <div
            className=""
            style={{ position: "absolute", bottom: "0%", left: "40%" }}
          >
            {" "}
            <div className="btnActualizarnue_boton d-flex w-70">
              <button
                className="btnActualizar mx-1"
                onClick={props.handleSubmitProfile}
              >
                Adicionar lesión
              </button>
              <button
                className="btnActualizar mx-1"
                style={{ backgroundColor: "#9EACED" }}
                onClick={estas_seguro}
              >
                Borrar lesión
              </button>
            </div>
          </div>
        ) : (
          <div
            className="py-3"
            style={{
              position: "absolute",
              bottom: "0%",
              left: "40%",
              color: "#9DACBD",
              fontSize: "1rem",
            }}
          >
            #Debes seleccionar una lesión
          </div>
        )}
      </div>
      {confirm}
      {modalesDolores}
      {modalesDoloresBack}
    </>
  ) : (
    <>
      {path.pathname.includes(`ver`) && laptop575.matches ? (
        <>
          <div></div>
          <div className="acomodarsiluetaentrenador">
            <div
              className="row justify-content-evenly "
              style={{ minWidth: "10vw" }}
            >
              <div
                className={`containerCuerpoentrenador col-6 ${
                  props.forceMediaMatch === undefined ? "col-lg-6" : null
                } `}
                ref={frontalView}
                style={{ display: "block" }}
              >
                {botonesDolores}
                <div className="mx-auto pt-3 widthtrainer">
                  <Silueta />
                </div>
                <div
                  className={` d-flex justify-content-center align-items-end mx-auto  changeView pt-0 d-block ${
                    props.forceMediaMatch === undefined
                      ? "col-lg-6 w-100 my-3"
                      : null
                  }`}
                  onClick={() => changeView(backView)}
                >
                  Ver Vista <strong className="ms-1 ">posterior</strong>
                  <div className="ms-2">
                    <FontAwesomeIcon icon={faPlay} color="white" />
                  </div>
                </div>
              </div>
              <div
                className={"containerCuerpoentrenador"}
                ref={backView}
                style={{ display: "none" }}
              >
                {botonesDoloresBack}
                <div className="widthtrainer mx-auto pt-3">
                  <Silueta />
                </div>
                <div
                  className={`  d-flex justify-content-center align-items-end mx-auto mb-2 changeView pt-3 d-block ${
                    props.forceMediaMatch === undefined ? "col-lg-12" : null
                  }`}
                  onClick={() => changeView(frontalView)}
                >
                  <div className="me-2">
                    <FontAwesomeIcon
                      icon={faPlay}
                      color="white"
                      style={{ transform: "scale(-1, 1)" }}
                    />
                  </div>
                  Ver Vista <strong className="ms-1">frontal</strong>
                </div>
              </div>
            </div>
          </div>
          {modalesDolores}

          {modalesDoloresBack}
        </>
      ) : (
        <>
          <div>
            <div
              className="row justify-content-evenly "
              style={{ minWidth: "300px" }}
            >
              {isProfile ? (
                <>
                  <div className="titulo">Tus lesiones</div>
                  <div className="subtitle_text mx-auto">
                    Indícanos la zona del cuerpo y haz una breve descripción de
                    tu lesión.
                  </div>
                </>
              ) : null}
              <div className={laptop575.matches ? "d-flex" : null}>
                <div
                  className={
                    path.pathname.includes(`Entrenador/clase`)
                      ? "  row justify-content-evenly"
                      : " acomodarsilueta row justify-content-evenly"
                  }
                >
                  <div
                    className={
                      path.pathname.includes(`Entrenador/clase`)
                        ? `containerCuerpo containerCuerpo_progres col-12 i ${
                            props.forceMediaMatch === undefined
                              ? "col-lg-6"
                              : null
                          } `
                        : `containerCuerpo col-12 i ${
                            props.forceMediaMatch === undefined
                              ? "col-lg-6"
                              : null
                          } `
                    }
                    //aqui tengo el de clase
                    ref={frontalView}
                    style={{ display: "block" }}
                  >
                    {path.pathname.includes(`Usuario/completar`) &&
                    laptop575.matches
                      ? botonesDoloresweb
                      : botonesDolores}
                    <div className="mx-auto pt-3 w-50">
                      <Silueta />
                    </div>
                    {path.pathname.includes(`Usuario/completar`) &&
                    laptop575.matches ? (
                      <div
                        className={` d-flex justify-content-center align-items-end mx-auto  changeView pt-0 d-block ${
                          props.forceMediaMatch === undefined ? "d-lg-6" : null
                        }`}
                        onClick={() => {
                          path.pathname.includes(`Usuario/completar`) &&
                          laptop575.matches
                            ? changeView(null)
                            : changeView(backView);
                        }}
                      >
                        Vista <strong className="ms-1">posterior</strong>
                      </div>
                    ) : (
                      <div
                        className={` d-flex justify-content-center align-items-end mx-auto  changeView pt-0 d-block ${
                          props.forceMediaMatch === undefined ? "d-lg-6" : null
                        }`}
                        onClick={() => {
                          path.pathname.includes(`Usuario/completar`) &&
                          laptop575.matches
                            ? changeView(null)
                            : changeView(backView);
                        }}
                      >
                        Ver Vista <strong className="ms-1">posterior</strong>
                        <div className="ms-2">
                          <FontAwesomeIcon icon={faPlay} color="white" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`containerCuerpo col-12 ${
                      props.forceMediaMatch === undefined
                        ? "col-lg-6 d-lg-block"
                        : null
                    }`}
                    ref={backView}
                    style={{ display: "none" }}
                  >
                    {path.pathname.includes(`Usuario/completar`) &&
                    laptop575.matches
                      ? botonesDoloresweb1
                      : botonesDoloresBack}

                    <div className="w-50 mx-auto pt-3">
                      <Silueta />
                    </div>
                    {path.pathname.includes(`Usuario/completar`) &&
                    laptop575.matches ? (
                      <div
                        className={
                          path.pathname.includes(`Entrenador/clase`)
                            ? ` d-flex justify-content-center align-items-end mx-auto mb-2 changeView pt-1 d-block ${
                                props.forceMediaMatch === undefined
                                  ? "d-lg-none"
                                  : null
                              }`
                            : ` d-flex justify-content-center align-items-end mx-auto mb-2 changeView pt-1 d-block ${
                                props.forceMediaMatch === undefined
                                  ? "d-lg-6"
                                  : null
                              }`
                        }
                        onClick={() => {
                          path.pathname.includes(`Usuario/completar`) &&
                          laptop575.matches
                            ? changeView(null)
                            : changeView(frontalView);
                        }}
                      >
                        Vista <strong className="ms-1">frontal</strong>
                      </div>
                    ) : (
                      <div
                        className={
                          path.pathname.includes(`Entrenador/clase`)
                            ? ` d-flex justify-content-center align-items-end mx-auto mb-2 changeView pt-1 d-block ${
                                props.forceMediaMatch === undefined
                                  ? "d-lg-none"
                                  : null
                              }`
                            : ` d-flex justify-content-center align-items-end mx-auto mb-2 changeView pt-1 d-block ${
                                props.forceMediaMatch === undefined
                                  ? "d-lg-6"
                                  : null
                              }`
                        }
                        onClick={() => {
                          path.pathname.includes(`Usuario/completar`) &&
                          laptop575.matches
                            ? changeView(null)
                            : changeView(frontalView);
                        }}
                      >
                        <div className="me-2">
                          <FontAwesomeIcon
                            icon={faPlay}
                            color="white"
                            style={{ transform: "scale(-1, 1)" }}
                          />
                        </div>
                        Ver Vista <strong className="ms-1">frontal</strong>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {" "}
                  {path.pathname.includes(`Entrenador/clase`) ? null : (
                    <div className="decirLesion">
                      <div className="decirLesionTitulo">
                        Tienes alguna lesión
                      </div>
                      <div className="decirLesion01">
                        Indicanos la zona del cuerpo y haz una breve descripción
                        de tu lesión.
                      </div>
                      {doloresPosteriores.map((e, index) => (
                        <div ref={e[3]} key={index} className="all">
                          <div>
                            <div className="decirLesion09">{e[0]}</div>
                            <textarea
                              style={{
                                width: "90%",
                                marginRight: "4vw",
                                marginTop: "5%",
                                height: "20vh",
                                border: "1px solid #E2E2E2",
                                borderRadius: "10px",
                                fontStyle: "italic",
                                color: "#9DACBD",
                              }}
                              placeholder="Describe aquí tu lesión."
                              rows="5"
                              onInput={(e) =>
                                changeDolorPosteriorcompletar(
                                  index,
                                  e.target.value
                                )
                              }
                            ></textarea>
                            <div
                              className="botonesModalInfo "
                              style={{ width: "20vw" }}
                            >
                              <ButtonMob
                                style={{ paddingRight: "0vw" }}
                                text="Guardar lesión"
                                className="btnAction1guardar "
                                onClick={() => ocultarDolor(e[3])}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      {doloresFrontales.map((e, index) => (
                        <div ref={e[3]} key={index} className="all">
                          <div>
                            <div className="decirLesion09">{e[0]}</div>
                            <textarea
                              style={{
                                width: "90%",
                                marginRight: "4vw",
                                marginTop: "5%",
                                height: "20vh",
                                borderRadius: "10px",
                                border: "1px solid #E2E2E2",

                                fontStyle: "italic",
                                color: "#9DACBD",
                              }}
                              placeholder="Describe aquí tu lesión."
                              rows="5"
                              onInput={(e) =>
                                changeDolorFrontalcompletar(
                                  index,
                                  e.target.value
                                )
                              }
                            ></textarea>
                            <div
                              className="botonesModalInfo "
                              style={{ width: "20vw" }}
                            >
                              <ButtonMob
                                style={{ paddingRight: "0vw" }}
                                text="Guardar lesión"
                                className="btnAction1guardar "
                                onClick={() => ocultarDolor(e[3])}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {window.location.pathname.includes(`Entrenador/clase`)
            ? mostrardolor
            : null}

          {modalesDolores}
          {modalesDoloresBack}
          {path.pathname.includes(`ver`) && !laptop575.matches
            ? mostrardolor
            : null}
        </>
      )}
    </>
  );
  {
    /* ); */
  }
};

const Cabecera = (props) => {
  let { option } = useParams();
  let history = useHistory();
  const selOption = props.selOption;
  const [optActive, setActive] = useState(null);

  useEffect(() => {
    if (optActive == null) {
      if (option == "bitacora") {
        setActive(2);
      } else if (option == "estadistica") {
        setActive(1);
      } else {
        setActive(0);
      }
    } else {
      props.onClick(selOption[optActive][2]);
    }
  }, [optActive]);

  function changeActive(index) {
    setActive(index);
    const opt = selOption[index][2];
    props.onClick(opt);
    history.push(`/${userType}/${ReactHtmlParser(unescape(opt))}`);
  }

  return laptop575.matches ? (
    <>
      <div className="maxreservar1" style={{ left: "13%" }}>
        <div className="d-flex ">
          <div className="SubTitle8reservatuclase">Bienvenido a</div>
          <div className="lineareservatuclasemis"></div>
        </div>
        <div className="title tituloreservartuclase ">Tu perfil</div>
        <div className="textodebajoreservas tituloreservartuclasedebajo">
          En MOB eres dueño de tu tiempo, agenda tu entrenamiento en los días y
          horario de tu preferencia
        </div>
      </div>
      <div className="cabeceraPerfil">
        <div className="Iconos mx-auto  ">
          {selOption.map((el, idx) => {
            const active = optActive == idx ? "active" : "";
            return (
              <span
                className={`icono letrascolorchange  ${active}`}
                key={idx}
                onClick={() => changeActive(idx)}
              >
                <div className={`bordealo ${active} py-3 `}>{el[0]}</div>
              </span>
            );
          })}
        </div>
        <br />
      </div>
    </>
  ) : (
    <>
      <div className="cabeceraPerfil">
        <div className="Iconos mx-auto  ">
          {selOption.map((el, idx) => {
            const active = optActive == idx ? "active" : "";
            return (
              <span
                className={`icono rounded-circle ${active}`}
                key={idx}
                onClick={() => changeActive(idx)}
              >
                <img src={el[1]} alt={el[0]} />
              </span>
            );
          })}
        </div>
        <div className=" borderlineiconos mb-0 mx-auto "></div>
      </div>
    </>
  );
};

export default Perfil;
// "@material-ui/core": "^4.11.4",
// "@material-ui/lab": "^5.0.0-alpha.44",
