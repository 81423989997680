import "./Footer.css";
import { Link } from "react-router-dom";

import logoBlanco from "../assets/img/footerIcons/iconos/logo_blanco.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  checkPathNavFoot,
  entrenadorType,
  notInPublic,
  urlFB,
  urlIG,
  userObj,
  userType,
} from "../utils/utils";

import homeA from "../assets/img/footerIcons/iconos/HomeA.png";
import ReservasA from "../assets/img/footerIcons/iconos/ReservasA.png";
import PerfilA from "../assets/img/footerIcons/iconos/PerfilA.png";
import CreditosA from "../assets/img/footerIcons/iconos/CreditosA.png";
import PerfilD from "../assets/img/footerIcons/iconos/Perfil.png";
import PerfilC from "../assets/img/footerIcons/iconos/perfilb.png";

import homeB from "../assets/img/footerIcons/iconos/HomeB.png";
import ReservasB from "../assets/img/footerIcons/iconos/ReservasB.png";
import PerfilB from "../assets/img/footerIcons/iconos/perfilBB.png";
import CreditosB from "../assets/img/footerIcons/iconos/CreditosB.png";

import { useLocation } from "react-router-dom";

// If the user is logged
const user = userObj();
const inicio = "/" + user.type + "/inicio";
const reservas = "/" + user.type + "/reservas";
const perfil = "/" + user.type + "/perfil";
const bitacora = "/" + user.type + "/bitacora";
const estadistica = "/" + user.type + "/estadistica";
const creditos = "/" + user.type + "/creditos";
const HR = "/" + user.type + "/HR";

const entrenadorPerfil = "/" + user.type + "/perfil";
const entrenadorSesiones = "/" + user.type + "/sesiones";
const entrenadorDisciplinas = "/" + user.type + "/disciplinas";
const entrenadorIngresos = "/" + user.type + "/ingresos";

export const FootItems = (props) => {
  const user = props.user;
  const path = useLocation();
  if (user.type == entrenadorType) {
    return (
      <>
        <Link to={inicio} className="">
          <img
            src={path.pathname.includes(inicio) ? homeB : homeA}
            alt="Inicio"
            className="iconFooter "
          />
          <div
            className={
              path.pathname.includes(inicio) ? "text_change_color" : "text"
            }
          >
            Inicio
          </div>
        </Link>
        <Link to={reservas} className="">
          <img
            src={path.pathname.includes(reservas) ? ReservasB : ReservasA}
            alt="Reservas"
            className="iconFooter "
          />
          <div
            className={
              path.pathname.includes(reservas) ? "text_change_color" : "text"
            }
          >
            Tus reservas
          </div>
        </Link>
        <Link to={perfil} className="">
          <img
            src={
              path.pathname.includes(entrenadorPerfil) ||
              path.pathname.includes(entrenadorSesiones) ||
              path.pathname.includes(entrenadorDisciplinas) ||
              path.pathname.includes(entrenadorIngresos)
                ? PerfilC
                : PerfilD
            }
            alt="Perfil"
            className="iconFooter "
          />
          <div
            className={
              path.pathname.includes(perfil) ? "text_change_color" : "text"
            }
          >
            Perfil
          </div>
        </Link>
      </>
    );
  }
  return (
    <>
      <Link to={inicio} className="">
        <img
          src={path.pathname.includes(inicio) ? homeB : homeA}
          alt="Inicio"
          className="iconFooter"
        />
        <div
          className={
            path.pathname.includes(inicio) ? "text_change_color" : "text"
          }
        >
          Inicio
        </div>
      </Link>
      <Link to={reservas} className="">
        <img
          src={path.pathname.includes(reservas) ? ReservasB : ReservasA}
          alt="Reservas"
          className="iconFooter "
        />
        <div
          className={
            path.pathname.includes(reservas) ? "text_change_color" : "text"
          }
        >
          Reservar
        </div>
      </Link>
      <Link to={HR} className="">
        <img
          src={path.pathname.includes(HR) ? PerfilB : PerfilA}
          alt="Perfil"
          className="iconFooter "
        />
        <div
          className={path.pathname.includes(HR) ? "text_change_color" : "text"}
        >
          HR
        </div>
      </Link>
      <Link to={creditos} className="">
        <img
          src={path.pathname.includes(creditos) ? CreditosB : CreditosA}
          alt="Creditos"
          className="iconFooter "
        />
        <div
          className={
            path.pathname.includes(creditos) ? "text_change_color" : "text"
          }
        >
          Creditos
        </div>
      </Link>
      <Link to={perfil} className="">
        <img
          src={
            path.pathname.includes(perfil) ||
            path.pathname.includes(estadistica) ||
            path.pathname.includes(bitacora)
              ? PerfilC
              : PerfilD
          }
          alt="pERFIL"
          className="iconFooter  "
        />
        <div
          className={
            path.pathname.includes(perfil) ||
            path.pathname.includes(estadistica) ||
            path.pathname.includes(bitacora)
              ? "text_change_color"
              : "text"
          }
        >
          Perfil
        </div>
      </Link>
    </>
  );
};

const Footer = (props) => {
  const path = useLocation();
  if (
    checkPathNavFoot(path.pathname) ||
    path.pathname.includes(`/${userType}/completar`) ||
    path.pathname.includes(`/${userType}/clase`) ||
    path.pathname.includes(`/${entrenadorType}/clase`) ||
    path.pathname.includes(`/OnboardingTrainer`)
  ) {
    return null;
  }
  if (user.code && !notInPublic(path.pathname)) {
    return (
      <nav
        className={
          user.type == entrenadorType
            ? "navFooter navbar mx-auto entrenador  d-xl-none"
            : "navFooter navbar mx-auto navfooterdelete    "
        }
      >
        <div className="container-fluid text-center">
          <FootItems user={user} />
        </div>
      </nav>
    );
  }
  // If not logged
  return (
    <div
      className={
        path.pathname.includes(`/responsePayu`) ||
        path.pathname.includes(`/pdf/:`) ||
        path.pathname.includes(`/tyc`) ||
        path.pathname.includes(`/privacidad`)
          ? "d-none"
          : "d-none"
      }
    >
      {/* <div className="container  row">
        <div className="col-sm-12 col-md-3 d-flex justify-content-center align-items-center">
          <img src={logoBlanco} alt="logo" className="w-50" />
        </div>
        <div className="col-sm-12 col-md-6 row">
          <div className="row col-sm-10 mt-3 mx-5 mx-sm-auto">
            <h4 className="col-12">Accesos rápidos</h4>
            <ul className="col-12 col-sm-6">
              <li>
                <h5>Usuarios</h5>
              </li>
              <li>
                <Link to="/tipos_entrenamientos">Tipos de entrenamiento</Link>
              </li>
              <li>
                <Link to="/lineamientos">Lineamientos de entrenadores</Link>
              </li>
              <li>
                <Link to="/servicios">Servicios adicionales</Link>
              </li>
              <li>
                <Link to="/">Planes</Link>
              </li>
            </ul>
            <ul className="col-12 col-sm-6">
              <li>
                <h5>Entrenadores</h5>
              </li>
              <li>
                <Link to="/beneficios">Beneficios</Link>
              </li>
              <li>
                <Link to="/como_funciona">Cómo funciona</Link>
              </li>
            </ul>
          </div>
          <div className="row col-sm-10 mt-3 mx-5 mx-sm-auto">
            <h4 className="col-12">Enlaces de interes</h4>
            <ul className="col-12 col-sm-6">
              <li>
                <Link to="/faq">Preguntas frecuentes</Link>
              </li>
              <li>
                <Link to="/tyc">Términos y condiciones</Link>
              </li>
              <li>
                <Link to="/privacidad">Políticas de privacidad</Link>
              </li>
              <li>
                <a
                  onClick={() => window.open(`https://tiendasportcheck.com/`)}
                  className=""
                >
                  <div className="text">Tienda</div>
                </a>
              </li>
            </ul>
            <ul className="col-12 col-sm-6"></ul>
          </div>
        </div>
        <div className="col-sm-10 col-md-3 mx-sm-auto">
          <h4 className="col-12">Síguenos en nuestras redes</h4>
          <div className="col-12 col-sm-6 d-flex justify-content-evenly">
            <a href={urlFB} className="">
              <FontAwesomeIcon icon={faFacebookF} size="2x" />
            </a>
            <a href={urlIG} className="">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
