import React, { useState, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import ReactPlayer from "react-player";
import "swiper/swiper-bundle.min.css";
import { ButtonMob, laptop575 } from "../utils/utils";
import { Link, Redirect } from "react-router-dom";
import { useEffect } from "react";

export function Allvideos(props) {
  const images = props.videos;
  const contenido = props.contenido;
  const textTitleOne = props.textTitleOne || null;
  const textTitleTwo = props.textTitleTwo || null;
  const id = props.id;
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const render_img_desk =
    images &&
    images.slice(images.length - 3, images.length).map((imagen, index) => {
      const def_img = imagen;

      return (
        <SwiperSlide key={index} className={props.className}>
          <div className="d-flex position-relative">
            <div style={{ minWidth: "200px" }}>
              <img
                className="position-absolute"
                style={
                  index + 1 == 1
                    ? { top: "0", left: "35%" }
                    : { top: "0", left: "20%" }
                }
                src={process.env.PUBLIC_URL + `/img/${index + 1}.png`}
              />
            </div>

            <div
              className="content position-relative"
              style={{ minWidth: "233px" }}
            >
              {" "}
              <img
                src={def_img[0]}
                style={{ borderRadius: "20px" }}
                width={laptop575.matches ? "100%" : "70%"}
                height="100%"
              />
              <div
                className="position-absolute w-100"
                style={
                  contenido != "vivo"
                    ? {
                        top: "0",
                        color: "#394F65",
                        padding: "8%",
                      }
                    : {
                        top: "0",
                        color: "#ffff",
                        zIndex: "2",
                        background: "rgba(0, 0, 0, 0.5)",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        padding: "8%",
                      }
                }
              >
                {def_img[1]}

                <br />
                <span
                  className="fw-bold"
                  style={{ fontSize: "20px", lineHeight: "100%" }}
                >
                  {def_img[2]}
                </span>
              </div>
              {imagen[4] == false ? (
                <div
                  className="w-100 h-100"
                  style={{
                    top: "0",
                    position: "absolute",
                    color: "#ffff",
                    background: "rgba(235, 237, 239, 0.8)",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    padding: "8%",
                  }}
                >
                  <div
                    className="position-absolute w-100 mx-auto "
                    style={{ top: "50%", left: "22%" }}
                  >
                    <span className="p-2 fw-bold" style={{ color: "#9EACED" }}>
                      Disponible
                      <br /> próximamente
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </SwiperSlide>
      );
    });

  return laptop575.matches ? (
    <>
      <div className="position-relative reconocer">
        <div
          className="d-flex swiper-pagination_p  my-3"
          style={{ justifyContent: "space-between" }}
        >
          <h2 style={{ color: "#394F65", fontSize: "25px" }}>
            <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
            <strong> {textTitleTwo}</strong>
          </h2>
          <div className="d-flex">
            <div className={`swiper-button-prev-${id}`}></div>
            <div className={`swiper-button-next-${id}`}></div>
          </div>
        </div>
        <Swiper
          className="slidersNetflixReservas py-2"
          navigation={{
            nextEl: `.swiper-button-next-${id}`,
            prevEl: `.swiper-button-prev-${id}`,
          }}
          modules={[Navigation]}
          slidesPerView="auto"
          spaceBetween={170}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          slidesPerGroup={1}
          slidesPerGroupSkip={1}
        >
          {render_img_desk}
        </Swiper>
      </div>
    </>
  ) : (
    <Swiper className="px-2" spaceBetween={-80} slidesPerView={1}>
      {render_img_desk}
    </Swiper>
  );
}
export function AllImagenes(props) {
  const images = props.videos;
  const textTitleOne = props.textTitleOne || null;
  const textTitleTwo = props.textTitleTwo || null;
  const id = props.id;
  const contenido = props.contenido;
  const textealo = props.textealo || null;
  const [color_change, setcolor_change] = useState(1);
  const [ModalInfo, setModalInfo] = useState(null);
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const valornum = id == "medicos" ? images.length - 3 : 0;

  const render_img_desk =
    images &&
    images.slice(valornum, images.length).map((imagen, index) => {
      const def_img = imagen;

      return (
        <SwiperSlide key={index} className={props.className}>
          <div
            onClick={() =>
              setModalInfo(
                <Redirect
                  push={true}
                  to={
                    contenido == "vivo"
                      ? `/ejercicios/reservando/24`
                      : `/ejercicios/info/${contenido}${index}`
                  }
                />
              )
            }
            className="content position-relative"
          >
            <img
              src={def_img[0]}
              style={{ borderRadius: "20px" }}
              width={laptop575.matches ? "100%" : "70%"}
              height="100%"
            />
            {imagen[3] == false ? (
              <div
                className="w-100 h-100"
                style={{
                  top: "0",
                  position: "absolute",
                  color: "#ffff",
                  background: "rgba(235, 237, 239, 0.8)",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  zIndex: "4",
                }}
              >
                {" "}
                <div
                  className="position-absolute w-100 mx-auto d-flex"
                  style={{
                    top: "50%",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <span
                    className="p-2 fw-bold"
                    style={{ color: "rgb(57, 79, 101)", zIndex: "3" }}
                  >
                    Disponible
                    <br /> próximamente
                  </span>
                </div>
              </div>
            ) : null}
            {imagen[4] == false ? (
              <div
                className="w-100 h-100"
                style={{
                  top: "0",
                  position: "absolute",
                  color: "#ffff",
                  background: "rgba(235, 237, 239, 0.8)",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                {" "}
                <div
                  className="position-absolute w-100 mx-auto d-flex"
                  style={{
                    top: "50%",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <span
                    className="p-2 fw-bold"
                    style={{ color: "rgb(57, 79, 101)", zIndex: "3" }}
                  >
                    Disponible
                    <br /> próximamente
                  </span>
                </div>
              </div>
            ) : null}
            <div
              className="position-absolute w-100"
              style={
                contenido == "vivo" ||
                (id == "comidas" &&
                  contenido != "Entrenamiento" &&
                  contenido != "Mente")
                  ? {
                      top: "0",
                      color: "white",
                      padding: "10%",
                      width: "100%",
                      background: "rgb(0,0,0,0.5)",
                      borderRadius: "20px",
                      fontSize: "13px",
                    }
                  : {
                      minHeight: "100px",
                      top: "0",
                      color: "#394F65",

                      borderRadius: "20px",
                      padding: "10%",
                      fontSize: "13px",
                    }
              }
            >
              {def_img[4] && id != "vertipo" ? (
                <div
                  className="d-flex "
                  style={{ justifyContent: "space-between" }}
                >
                  {def_img[2] == "Salsa y pierna" ? (
                    <>
                      <span> {def_img[1]}</span>
                      <span style={{ pointerEvents: "none" }}>
                        <img
                          src={process.env.PUBLIC_URL + "/img/playred.svg"}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span> {def_img[1]}</span>
                      <span style={{ pointerEvents: "none" }}>
                        <img
                          src={process.env.PUBLIC_URL + "/img/playverde.svg"}
                        />
                      </span>
                    </>
                  )}
                </div>
              ) : (
                <div
                  className="d-flex "
                  style={{ justifyContent: "space-between" }}
                >
                  <span> {def_img[1]}</span>

                  {/* <span>
                    <img src={process.env.PUBLIC_URL + "/img/playred.svg"} />
                  </span> */}
                </div>
              )}
              <span
                className="fw-bold"
                style={
                  id == "medicos"
                    ? { fontSize: "20px", lineHeight: "100%" }
                    : { fontSize: "15px", lineHeight: "100%" }
                }
              >
                {def_img[2]}
              </span>{" "}
              <br /> {def_img[3] ? def_img[3] : null}
            </div>
          </div>
        </SwiperSlide>
      );
    });

  return laptop575.matches ? (
    <>
      <div className="position-relative reconocer">
        <div
          className="d-flex swiper-pagination_p  my-3 tomesamba"
          style={{ justifyContent: "space-between" }}
        >
          {textTitleOne == "Más" ? (
            <h2
              style={{ color: "#394F65", fontSize: "25px", marginLeft: "5%" }}
            >
              <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
              <strong> {textTitleTwo}</strong>
            </h2>
          ) : (
            <h2 style={{ color: "#394F65", fontSize: "25px" }}>
              <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
              <br /> <strong> {textTitleTwo}</strong>
            </h2>
          )}

          <div className=" d-flex">
            <div className={`swiper-button-prev-${id}`}></div>
            <div className={`swiper-button-next-${id}`}></div>
          </div>
        </div>

        <Swiper
          style={id == "comidas" ? { margin: "auto 5%" } : null}
          className="slidersNetflixReservas py-2"
          navigation={{
            nextEl: `.swiper-button-next-${id}`,
            prevEl: `.swiper-button-prev-${id}`,
          }}
          modules={[Navigation]}
          slidesPerView="auto"
          loop={render_img_desk.length < 3 ? false : true}
          spaceBetween={10}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          slidesPerGroup={1}
          slidesPerGroupSkip={1}
        >
          {render_img_desk}
        </Swiper>
      </div>
      {ModalInfo}
    </>
  ) : (
    <Swiper className="px-2" spaceBetween={-80} slidesPerView={1}>
      {render_img_desk}
    </Swiper>
  );
}

export function AllvideosBanner() {
  const videoEl = useRef(null);
  const videoEl1 = useRef(null);
  const videoImg1 = useRef(null);
  const videoImg2 = useRef(null);
  const videoImg3 = useRef(null);
  const videoImg4 = useRef(null);
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const [ModalInfo, setModalInfo] = useState(null);
  const [deseoplayer4, setdeseoplayer4] = useState(false);
  const [deseoplayer3, setdeseoplayer3] = useState(false);
  const [deseoplayer2, setdeseoplayer2] = useState(false);
  const [deseoplayer1, setdeseoplayer1] = useState(false);

  const bannerselect3 = document.getElementById("banner4");
  const bannersele4 = document.getElementById("banner4");
  const bannerselect2 = document.getElementById("banner3");
  const bannersele3 = document.getElementById("banner3");
  const bannerselect1 = document.getElementById("banner2");
  const bannersele2 = document.getElementById("banner2");
  const bannerselect0 = document.getElementById("banner1");
  const bannersele1 = document.getElementById("banner1");
  // var bannersele4ct3 = document.getElementById("imagenre3");
  // var bannersele4 = document.getElementById("banner4");
  function func4() {
    if (deseoplayer4 == false) {
      setdeseoplayer4(true);
    }
  }
  function funcout4() {
    if (deseoplayer4) {
      setdeseoplayer4(false);
    }
  }
  function func3() {
    if (deseoplayer3 == false) {
      setdeseoplayer3(true);
    }
  }
  function funcout3() {
    if (deseoplayer3) {
      setdeseoplayer3(false);
    }
  }
  function func2() {
    if (deseoplayer2 == false) {
      setdeseoplayer2(true);
    }
  }
  function funcout2() {
    if (deseoplayer2) {
      setdeseoplayer2(false);
    }
  }
  function func1() {
    if (deseoplayer1 == false) {
      setdeseoplayer1(true);
    }
  }
  function funcout1() {
    if (deseoplayer1) {
      setdeseoplayer1(false);
    }
  }
  useEffect(() => {
    const bannersele4 = document.getElementById("banner4");

    var videoElem4 = bannersele4.querySelector("video");

    if (deseoplayer4) {
      if (videoElem4.paused) {
        playVideo();
      } else {
        videoElem4.pause();
      }
      async function playVideo() {
        try {
          await videoElem4.play();
        } catch (err) {
          console.log(err);
        }
      }

      playVideo();

      // videoEl1 &&
      //   videoEl1.current &&
      //   videoEl1.current.play().catch((error) => {
      //     console.error("Error attempting to play", error);
      //   });
    } else {
      videoElem4.pause();
    }
  }, [deseoplayer4]);
  useEffect(() => {
    const bannersele3 = document.getElementById("banner3");

    var videoElem3 = bannersele3.querySelector("video");
    if (deseoplayer3) {
      if (videoElem3.paused) {
        playVideo();
      } else {
        videoElem3.pause();
      }
      async function playVideo() {
        try {
          await videoElem3.play();
        } catch (err) {
          console.log(err);
        }
      }

      playVideo();

      // videoEl1 &&
      //   videoEl1.current &&
      //   videoEl1.current.play().catch((error) => {
      //     console.error("Error attempting to play", error);
      //   });
    } else {
      videoElem3.pause();
    }
  }, [deseoplayer3]);
  useEffect(() => {
    const bannersele2 = document.getElementById("banner2");

    var videoElem2 = bannersele2.querySelector("video");
    if (deseoplayer2) {
      if (videoElem2.paused) {
        playVideo();
      } else {
        videoElem2.pause();
      }
      async function playVideo() {
        try {
          await videoElem2.play();
        } catch (err) {
          console.log(err);
        }
      }

      playVideo();

      // videoEl1 &&
      //   videoEl1.current &&
      //   videoEl1.current.play().catch((error) => {
      //     console.error("Error attempting to play", error);
      //   });
    } else {
      videoElem2.pause();
    }
  }, [deseoplayer2]);
  useEffect(() => {
    const bannersele1 = document.getElementById("banner1");

    var videoElem1 = bannersele1.querySelector("video");
    if (deseoplayer1) {
      if (videoElem1.paused) {
        playVideo();
      } else {
        videoElem1.pause();
      }
      async function playVideo() {
        try {
          await videoElem1.play();
        } catch (err) {
          console.log(err);
        }
      }

      playVideo();

      // videoEl1 &&
      //   videoEl1.current &&
      //   videoEl1.current.play().catch((error) => {
      //     console.error("Error attempting to play", error);
      //   });
    } else {
      videoElem1.pause();
    }
  }, [deseoplayer1]);

  bannerselect3 &&
    bannerselect3.addEventListener("mousemove", func4, { once: true });
  bannersele4 &&
    bannersele4.addEventListener("mouseleave", funcout4, { once: true });

  bannerselect2 &&
    bannerselect2.addEventListener("mousemove", func3, { once: true });
  bannersele3 &&
    bannersele3.addEventListener("mouseleave", funcout3, { once: true });

  bannerselect1 &&
    bannerselect1.addEventListener("mousemove", func2, { once: true });

  bannersele2 &&
    bannersele2.addEventListener("mouseleave", funcout2, { once: true });
  bannerselect0 &&
    bannerselect0.addEventListener("mousemove", func1, { once: true });

  bannersele1 &&
    bannersele1.addEventListener("mouseleave", funcout1, { once: true });

  const videos = [
    [
      process.env.PUBLIC_URL + "/img/Enmarcarar3.jpg",
      "Elevva funcional",
      "Nuestro programa de método elevva funcional, tiene como objetivo ayudarte a mejorar considerablemente tu estilo de vida, impactando positivamente tu salud y síndrome metabólico, con un sistema de ejercicios concretos adaptados según cada individuo.",
      "Entrenamiento1",
      "https://elevva-b1.s3.us-east-1.amazonaws.com/MINICLIPjairo.mp4",
    ],
    [
      process.env.PUBLIC_URL + "/img/Enmascar.jpg",
      "Balance training",
      "Nuestro programa Balance Elevva, tiene como objetivo mejorar y fortalecer considerablemente tu movilidad o capacidad de articulación, gracias a su combinación multidisciplinar de yoga, pilates, stretching y tono muscular, dando como resultado un ejercicio laxo, pero sumamente efectivo.",
      "Entrenamiento",
      "https://elevva-b1.s3.us-east-1.amazonaws.com/cortobalance.mp4",
    ],
    [
      process.env.PUBLIC_URL + "/img/hipotiroides.jpg",
      "Comida y emociones",
      "Nuestro programa de Nutrición consciente, tiene como objetivo enseñarte sobre la relación de la comida con nuestro sentir, entender como lo que consumimos puede ayudarnos a regular nuestras emociones y cómo podemos alimentarnos en cada caso.",
      "Nutrición",
      "https://elevva-b1.s3.us-east-1.amazonaws.com/cortoesposaJuan.mp4",
    ],
    [
      process.env.PUBLIC_URL + "/img/estres.png",
      "El poder de la autocompasión",
      "Con nuestro programa de Autocompasión, aprenderás las ventajas de perdonarte tanto en cuerpo y mente, comprendiendo que los errores hacen parte de la naturaleza humana, dándote espacio para mejorar y potenciarte.",
      "Mente",
      "https://elevva-b1.s3.us-east-1.amazonaws.com/mindfulnesscorto.mp4",
    ],
  ];

  const sw = window.screen.width;
  const render_img_desk = videos.map((imagen, index) => {
    const def_img = imagen;

    return (
      <SwiperSlide key={index} className="sliderNetFlixvideoNew">
        <div
          id={`banner${index + 1}`}
          style={
            deseoplayer4 || deseoplayer3 || deseoplayer2 || deseoplayer1
              ? {
                  zIndex: "1",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  minHeight: "calc(80vmin - 0rem)",
                }
              : {
                  position: "relative",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundRepeat: "no-repeat",
                  minHeight: "calc(80vmin - 0rem)",
                }
          }
          className={"content mx-auto d-flex"}
        >
          <video
            ref={videoEl1}
            style={{
              width: "100%",
              margin: "0 auto",
              height: "80vmin",
            }}
            playsInline
            loop
            alt="All the devices"

            // ref={videoEl}
          >
            <source src={def_img[4]} type="video/mp4" />
          </video>

          <div
            className="position-absolute w-100"
            style={{
              background: "rgba(0, 0, 0, 0.2)",
              width: "100%",
              height: "100%",
              zIndex: "1",
            }}
          ></div>
          <div
            className="position-absolute d-flex "
            style={{
              flexDirection: "column",
              justifyContent: "center",
              left: "10vmin",
              zIndex: "2",
              maxWidth: "50vmin",
              color: "white",
            }}
          >
            <span style={{ fontSize: "2.5vmin", fontWeight: "600" }}>
              Nuestro plan
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "7vmin",
                fontWeight: "800",
                lineHeight: "100%",
              }}
            >
              {def_img[1]}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "1.5vmin",
              }}
            >
              {def_img[2]} <br />
            </span>{" "}
            <div style={{ marginTop: "1vmin" }}>
              {" "}
              <span style={{ fontSize: "1.5vmin" }}>DURACIÓN: 8 CLASES</span>
            </div>
            <br />
            <div className="d-flex ms-0 " style={{ marginRight: "6rem" }}>
              <ButtonMob
                text="Ver ahora"
                className="butonvideobanner"
                onClick={() =>
                  setModalInfo(
                    <Redirect
                      push={true}
                      to={`/ejercicios/card/${def_img[3]}`}
                    />
                  )
                }
              />

              <ButtonMob
                onClick={() =>
                  setModalInfo(
                    <Redirect
                      push={true}
                      to={`/ejercicios/info/${def_img[3]}`}
                    />
                  )
                }
                text="Más información"
                className="butonvideobanner_two ms-4"
              />
            </div>
          </div>
        </div>

        {/* <div
          id={"imagenre" + index}
          className={"content mx-auto d-flex "}
          style={
            deseoplayer4 || deseoplayer3 || deseoplayer2 || deseoplayer1
              ? {
                  zIndex: "-1",
                  flexDirection: "column",
                  position: "absolute",
                  justifyContent: "center",
                  backgroundRepeat: "no-repeat",
                  minHeight: "calc(80vmin - 0rem)",
                  backgroundSize: "cover",
                  backgroundImage: `url(${def_img[0]})`,
                }
              : {
                  zIndex: "1",
                  position: "relative",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundRepeat: "no-repeat",
                  minHeight: "calc(80vmin - 0rem)",
                  backgroundSize: "cover",
                  backgroundImage: `url(${def_img[0]})`,
                }
          }
        >
          <div
            className="position-absolute d-flex "
            style={{
              flexDirection: "column",
              justifyContent: "center",
              left: "10vmin",
              //   top: "10vmin",
              maxWidth: "50vmin",
              color: "white",
            }}
          >
            <span style={{ fontSize: "2.5vmin", fontWeight: "600" }}>
              Nuestro plan
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "7vmin",
                fontWeight: "800",
                lineHeight: "100%",
              }}
            >
              {def_img[1]}
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "1.5vmin",
              }}
            >
              {def_img[2]} <br />
            </span>{" "}
            <div style={{ marginTop: "1vmin" }}>
              {" "}
              <span style={{ fontSize: "1.5vmin" }}>DURACIÓN: 8 CLASES</span>
            </div>
            <br />
            <div className="d-flex ms-0 " style={{ marginRight: "6rem" }}>
              <ButtonMob
                text="Ver ahora"
                className="butonvideobanner"
                onClick={() =>
                  setModalInfo(
                    <Redirect
                      push={true}
                      to={`/ejercicios/card/${def_img[3]}`}
                    />
                  )
                }
              />

              <ButtonMob
                onClick={() =>
                  setModalInfo(
                    <Redirect
                      push={true}
                      to={`/ejercicios/info/${def_img[3]}`}
                    />
                  )
                }
                text="Más información"
                className="butonvideobanner_two ms-4"
              />
            </div>
          </div>
        </div> */}
      </SwiperSlide>
    );
  });

  return (
    <div className="position-relative">
      <Swiper
        className="sliderPublicidadnew slidersNetflixReservasNew"
        spaceBetween={0}
        slidesPerView={1}
        // autoplay={{
        //   delay: 14000,
        // }}
        pagination={{
          clickable: true,
          el: ".bulletsVideos",
          type: "custom",
          renderCustom: function (swip, current, total) {
            current -= 1;
            return render_img_desk
              .map((value, index) => {
                return `<div class='pointerCompanie swiper-pagination-bullet mx-2 ${
                  current == index ? "active" : ""
                }'></div>`;
              })
              .join("");
          },
        }}
      >
        {render_img_desk}
      </Swiper>
      {ModalInfo}
      <div className="bulletsVideos"></div>
    </div>
  );
}
