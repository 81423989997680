import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/pagination/pagination.min.css";

import {
  ButtonMob,
  Loadingspinner,
  userType,
  basename,
  instance,
  laptop575,
  laptop,
} from "../utils/utils";
import { extendMoment } from "moment-range";
import Moment from "moment";
import calendarB from "../assets/img/footerIcons/iconos/ReservasB.png";
import elip from "../assets/img/reservasimages/elip.png";

import "./VerReserva.css";
import Media from "react-media";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

SwiperCore.use([Pagination]);
const VerReserva = (props) => {
  const moment = extendMoment(Moment);
  require("moment-timezone");
  const { id } = useParams();
  const [objReserva, setReserva] = useState(null);
  const now = moment().format();
  const zonahorairachange = props.zonahorairachange;

  useEffect(() => {
    instance.get(`booking/`).then((resEn) => {
      const info = resEn.data.filter((e) => e.id == id);
      console.log(zonahorairachange);
      for (const i of info) {
        const identrenamiento = i.training.id;
        const tz = i.tz;
        const date = moment.tz(i.date, tz);
        const id = i.id;
        const status = i.status;
        instance.get(`trainings/${identrenamiento}/`).then(async (resEn) => {
          const data = resEn.data;
          console.log(date.format("LT a"));

          if (data != null) {
            setReserva({
              id,
              fecha_normal: date.format(),
              fecha: date.format(" dddd DD MMMM [de] YYYY"),
              zonatz: tz,
              hora: date.format("LT a"),
              name: unescape(data.name).toLowerCase(),
              info: unescape(data.description),
              is_group: data.group,
              main_picture: data.main_picture,
              status: status,
              img: data.cover_picture,
              description: data.description,
              minutos: data.time,
            });
          }
        });
      }
    });
  }, []);

  return (
    <div className="VerReserva ">
      {objReserva ? (
        <>
          <Cabecera
            objReserva={objReserva}
            now={moment.tz(now, objReserva.zonatz).format()}
          />
          <Informacion objReserva={objReserva} />
        </>
      ) : (
        <Loadingspinner customStyle="border" size="5rem" />
      )}
    </div>
  );
};

const Cabecera = (props) => {
  const { id } = useParams();
  const now = props.now;
  const objReserva = props.objReserva;
  let imagen = objReserva.img;
  console.log(now, objReserva && objReserva.fecha_normal);
  let imagenweb = objReserva.main_picture;

  const [clase, setClase] = useState(null);
  const goToClass = () => {
    if (objReserva.is_group) {
      window.location.href = `${basename}/Usuario/clasestream/${id}`;
    } else {
      if (localStorage.room_idUser && localStorage.room_idUser != id) {
        localStorage.removeItem("room_idUser");
        localStorage.removeItem("workoutsetActual");
        localStorage.removeItem("workoutsetActual");
        localStorage.removeItem("contadorActual");
      }
      //setClase(<Redirect   to={`/Usuario/clase/${id}`} />)
      window.location.href = `${basename}/Usuario/clase/${id}`;
    }
  };

  const submit = async () => {
    // const res = await instance.post(`booking/book/cancel/${id}`);
    // console.log("reseses", res.data, res);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="cambioCuaro">
            <div
              className="custom-ui CancelarReserva"
              style={laptop.matches ? { fontSize: "1vw" } : null}
            >
              <h1
                className="title text-center"
                style={
                  laptop575.matches
                    ? { fontSize: "2rem" }
                    : { fontSize: "1.5rem" }
                }
              >
                ¿Deseas cancelar tu entrenamiento?
              </h1>
              <div
                className=" text-center my-3"
                style={{
                  fontFamily: "WorkSans-Regular",
                  color: "#9DACBD",
                  fontSize: "1rem",
                }}
              >
                Ten en cuenta que si cancelas dentro de las 24 horas antes de tu
                clase, se te descontara el valor de tu reserva y perderás los
                créditos usados.{" "}
              </div>
              {/* <p className="msg">{res.data}</p> */}
              <div
                className="buttons"
                style={laptop575.matches ? { margin: "auto" } : null}
              >
                <button className="No" onClick={onClose}>
                  <strong>No</strong> quiero cancelar
                </button>
                <button
                  className="Si"
                  onClick={async () => {
                    const resC = await instance.post(
                      `booking/book/cancel/${id}`
                    );
                    // console.log("reseses", resC);
                    onClose();
                    setClase(<Redirect to={`/${userType}/inicio`} />);
                  }}
                >
                  Cancelar el entrenamiento
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  return laptop575.matches ? (
    <>
      <div className="cabeceraContent">
        <div className="bg_img">
          <img src={imagenweb} alt="Tipo entrenamiento" />
        </div>
        <Link to={`/Usuario/inicio`} className="btnBackReservas">
          <ButtonMob
            image5={elip}
            text="Regresar a reservas"
            className="btnBackReservaschange"
          />
        </Link>
        <div className="btnBottom" style={{ zIndex: "98" }}>
          <ButtonMob
            text="Cancelar entrenamiento"
            className="btnCancelarReserva colorbuttoniniciar2"
            onClick={submit}
          />
          <ButtonMob
            text={
              objReserva.status > 10
                ? "Iniciar entrenamiento"
                : "En espera de confirmación"
            }
            disabled={
              objReserva.status > 10 && objReserva.fecha_normal <= now
                ? false
                : true
            }
            className={
              objReserva.status > 10 && objReserva.fecha_normal <= now
                ? "btnIniciarReserva me-3 colorbuttoniniciar"
                : "btnIniciarReserva me-3 colorbuttoniniciargray"
            }
            onClick={goToClass}
          />
        </div>
        <div className="info">
          Entrenamiento
          {/* <span className="lineop1"></span> */}
          <br />
          <span className="name">{objReserva.name}</span>
        </div>
      </div>
      {clase}
    </>
  ) : (
    <>
      <div className="cabeceraContent">
        <div className="bg_img">
          <img src={imagen} alt="Tipo entrenamiento" />
        </div>

        <Link to={`/Usuario/inicio`} className="btnBackReservas">
          <ButtonMob
            text="Regresar a reservas"
            className="btnBackReservaschange"
          />
        </Link>
        <div className="btnBottom" style={{ zIndex: "9999999" }}>
          <ButtonMob
            disabled={
              objReserva.status > 10 && objReserva.fecha_normal <= now
                ? false
                : true
            }
            text={
              objReserva.status > 10
                ? "Iniciar <br/> entrenamiento"
                : "En espera de<br/> confirmación"
            }
            className={
              objReserva.status > 10 && objReserva.fecha_normal <= now
                ? "btnIniciarReserva me-3 colorbuttoniniciar"
                : "btnIniciarReserva me-3 colorbuttoniniciargray"
            }
            onClick={goToClass}
          />
          <ButtonMob
            text="Cancelar<br/>entrenamiento"
            className="btnCancelarReserva colorbuttoniniciar2"
            onClick={submit}
          />
        </div>
        <div className="info">
          Entrenamiento
          {/* <span className="lineop"></span> */}
          <br />
          <span className="name">{objReserva.name}</span>
        </div>
      </div>
      {clase}
    </>
  );
};

const Informacion = (props) => {
  const moment = extendMoment(Moment);

  const objReserva = props.objReserva;
  const palabraBuscar = ReactHtmlParser(unescape(objReserva.description));
  const palabraBuscarubicar = palabraBuscar[0].search("EQUIPOS REQUERIDOS");

  const sliders = (
    <>
      <SwiperSlide className="col-lg-4 sliderReservaClase">
        <div className="titulo">
          <img src={calendarB} alt="calendario" />
          <span>
            {objReserva.fecha}
            <br />
            {objReserva.hora}
          </span>
        </div>
        <div className="title mt-2">Metodologia:</div>
        <div className="inforesevatuclase">
          Inicia clase de {objReserva.name}
        </div>
        <div className="title mt-1">Tiempo circuito:</div>
        <div className="inforesevatuclase">{objReserva.minutos + " min"} </div>
        <div className="title mt-1">Ejercicio:</div>
        <div className="inforesevatuclase text-capitalize">
          {objReserva.name}
        </div>
      </SwiperSlide>
      <SwiperSlide className="col-lg-4 sliderReservaClase">
        <div className="title ">Recomendaciones:</div>
        <div className="descr">
          <div className="content">
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />
              <div>
                Recuerda siempre estar cerca de tu wifi o conectar tu computador
                por cable a tu router
              </div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div> Verifica que tienes buen internet</div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div>
                {" "}
                Si tu clase se congela o se pierde la imagen de tu profesor
                durante el desarrollo de esta, no te preocupes
                actualiza/refresca la página de tu sesión para que vuelva a
                iniciar.
              </div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />
              <div> Siempre ten a la mano agua para hidratarte.</div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />
              <div> Siempre ten a la mano una toalla para secar el sudor.</div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div>
                Siempre ten a la mano los equipos de entrenamiento que puedes
                llegar usar en tu clase como colchoneta, pesas, lazo etc.
              </div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div>
                Recuerda que esta clase está hecha exclusivamente para ti. Por
                esta razón, no puedes invitar a amigos o familiares a hacerla
                contigo pues pueden sufrir lesiones y retrasar tu progreso.
              </div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div>
                En el caso que un profesor vea una persona distinta al usuario
                registrado podrá dar por terminada la clase y cerrar la sesión.
              </div>{" "}
            </div>
            <br />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide className="col-lg-4 sliderReservaClase verReservaWeb">
        <div className="title mt-1">Descripción:</div>
        <div className="descr">
          <div className="content">{objReserva.info}</div>
        </div>
      </SwiperSlide>
    </>
  );
  const slidersweb = (
    <>
      <SwiperSlide className="col-12 sliderReservaClase">
        <div className="tituloreservaver">Descripción</div>
        <div className="titulo">
          <img src={calendarB} alt="calendario" />
          <span>
            {objReserva.fecha}
            <br />
            {objReserva.hora}
          </span>
        </div>
        <div className="title mt-2">Metodologia:</div>
        <div className="inforesevatuclase my-1">
          Inicia clase de {objReserva.name}.
        </div>
        <div className="title mt-2">Información:</div>
        <div className="inforesevatuclase my-1">
          {ReactHtmlParser(objReserva.description)}
        </div>
        <div className="title my-1">Tiempo circuito:</div>
        <div className="inforesevatuclase">{objReserva.minutos + " min"}</div>
        <div className="title my-1">Ejercicio:</div>
        <div className="inforesevatuclase text-capitalize">
          {objReserva.name}
        </div>
        <div className="linedivisionrecomendaciones"> </div>
        <div className="title my-1 ">Recomendaciones:</div>
        <div className="descr my-1">
          <div className="content">
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />
              <div>
                Recuerda siempre estar cerca de tu wifi o conectar tu computador
                por cable a tu router
              </div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div> Verifica que tienes buen internet</div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div>
                {" "}
                Si tu clase se congela o se pierde la imagen de tu profesor
                durante el desarrollo de esta, no te preocupes
                actualiza/refresca la página de tu sesión para que vuelva a
                iniciar.
              </div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />
              <div> Siempre ten a la mano agua para hidratarte.</div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />
              <div> Siempre ten a la mano una toalla para secar el sudor.</div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div>
                Siempre ten a la mano los equipos de entrenamiento que puedes
                llegar usar en tu clase como colchoneta, pesas, lazo etc.
              </div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div>
                Recuerda que esta clase está hecha exclusivamente para ti. Por
                esta razón, no puedes invitar a amigos o familiares a hacerla
                contigo pues pueden sufrir lesiones y retrasar tu progreso.
              </div>
            </div>
            <br />
            <div className="recomendacionreserva">
              <img src={elip} className="elipse" />{" "}
              <div>
                En el caso que un profesor vea una persona distinta al usuario
                registrado podrá dar por terminada la clase y cerrar la sesión.
              </div>{" "}
            </div>
            <br />
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="col-lg-4 sliderReservaClase verReservaWeb">
        <div className="title mt-1">Descripción:</div>
        <div className="descr">
          <div className="content">{objReserva.info}</div>
        </div>
      </SwiperSlide>
    </>
  );
  return (
    <>
      <Media query="(max-width: 920px)">
        {(matches) =>
          matches ? (
            <>
              <div className="contenedorInfo mx-auto">
                <Swiper
                  className="sliderPublicidad"
                  spaceBetween={10}
                  slidesPerView="auto"
                  breakpoints={{
                    // when window width is >= 1024px
                    481: {
                      slidesPerView: 2,
                    },
                  }}
                  pagination={{
                    el: ".bulletsPublicidad",
                    type: "custom",
                    renderCustom: function (swip, current, total) {
                      return `<div class='swiper-pagination-bullet mx-2 ${
                        current === 1 ? "active" : ""
                      }'></div>
                                                    <div class='swiper-pagination-bullet mx-2 ${
                                                      current === 2
                                                        ? "active"
                                                        : ""
                                                    }'></div>
                                                    <div class='swiper-pagination-bullet mx-2 ${
                                                      current === 3
                                                        ? "active"
                                                        : ""
                                                    }'></div>`;
                    },
                  }}
                >
                  {sliders}
                </Swiper>
              </div>
              <div className="bulletsPublicidad"></div>
            </>
          ) : (
            <div className="d-flex   contenedorInfo mx-auto">{slidersweb}</div>
          )
        }
      </Media>
    </>
  );
};

export default VerReserva;
// https://localhost:3000/Usuario/confirmado/5/2021-10-30/07:00
