import AgoraRTC from "agora-rtc-sdk-ng";
import { useEffect, useRef, useState } from "react";

import Moment from "moment";
import "moment/locale/es";
import { extendMoment } from "moment-range";
import flama from "../../assets/img/reservasimages/flama.png";
import {
  ContenedorClase,
  PanelGroup,
  fancyTimeFormat,
  SetModalError,
  instance,
  disconnectPeer,
  connectPeerToPeerTrainer,
  timeOutcheckVideo,
  timeOutcheckAudio,
  basename,
  CommentsClients,
  ModalInfo,
  initSocketConnection,
  SetModaldespedida,
  workoutlog_data_emit,
  booking_finish_trainer_emit,
  sync_clock,
  closeSocketConnection,
  get_users_room_emit,
} from "../../utils/utils";

import fondo from "../../assets/img/tipos_ejercicios.png";
import Media from "react-media";
import orientation from "../../assets/img/reservasimages/orientation.jpg";
import { ComprobarClase } from "../../Clase/Clase";
import cerrar from "../../assets/img/reservasimages/Grupo 6107.png";
import corazon from "../../assets/img/reservasimages/corazon.png";

import customAvatar from "../../assets/img/reservasimages/avatargroupal.png";
import logo from "../../assets/img/general/logo_alta.png";

import "./EntrenadorClase.css";

import { useHistory, useParams } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const EntrenadorClasestream = (props) => {
  AgoraRTC.setLogLevel(3);

  const [isChecked, setChecked] = useState(null);
  const user = props.user;
  const history = useHistory();

  useEffect(() => {
    if (!window.location.pathname.includes`Entrenador/clasestream/`) {
      document.getElementById("navbarNavDropdown").style =
        "display:block !important";
    } else {
      document.getElementById("navbarNavDropdown").style = "display:none ";
    }
    const checked = localStorage.checked;

    if (checked) {
      const now = Date.now();
      const diff = now - checked;
      if (diff <= 1800000) {
        // Less than 30 min, so no check again
        setChecked(true);
      } else {
        setChecked(false);
      }
    } else setChecked(false);
  }, []);

  return (
    <>
      <Media
        query="(max-width: 1000px)"
        render={() => (
          <div
            className="claseFondo"
            style={{ backgroundImage: `url(${orientation})` }}
          >
            <div className="container  px-5">
              <div className="info mx-auto">
                <div>
                  Para poder dictar la clase correctamente necesitas estar en
                  una pantalla mas grande
                </div>
                <br />
                <br />
                <br />
                <div>
                  Por favor, dicta la clase en un computador portatil o de
                  escritorio.
                </div>
              </div>
              <div
                className="position-absolute fw-bold"
                style={{ color: "white", top: "5%" }}
              >
                <div
                  onClick={() => history.goBack()}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} color="white" /> Volver
                </div>
              </div>
            </div>
          </div>
        )}
      />
      <Media
        query="(min-width: 1001px)"
        render={() => (
          <>
            <div
              className="containerBGClase"
              style={{ backgroundImage: `url(${fondo})` }}
            ></div>
            {isChecked === true ? <ComenzarClase user={user} /> : null}
            {isChecked === false ? (
              <ComprobarClase
                onChecked={(inf) => setChecked(inf)}
                isChecked={isChecked}
              />
            ) : null}
          </>
        )}
      />
    </>
  );
};

const ComenzarClase = (props) => {
  const user = props.user;

  const { reserva, idclase } = useParams();

  const myPanelTab = useRef(null);
  const myPanel = useRef(null);
  const [hourSync, sethourSync] = useState(null);
  const [StartClock, setStartClock] = useState(false);
  const actualTime = useRef(0);
  const [bandConnected, setbandConnected] = useState(
    localStorage.elevvaBandConected
      ? JSON.parse(localStorage.elevvaBandConected)
      : false
  );
  const [timeLeft, setTimeLeft] = useState(fancyTimeFormat(0));
  const [modalInfo, setModalInfo] = useState(null);
  const [HrData, setHrData] = useState({ heart_rate: 0, kcal: 0 });
  const [bookings, setbookings] = useState(null);
  const [NowConnected, setNowConnected] = useState([]);
  const [objReserva, setReserva] = useState(null);
  let intervalSearch = null;

  function funcionConRetraso() {
    setModalInfo(
      <SetModaldespedida
        title="Hola entrenador"
        msg="Te quedan 5 minutos de clase,recuerda que debes ir terminando tu entrenamiento"
        handleClick={() => setModalInfo(null)}
      />
    );
  }

  useEffect(() => {
    let novoInterval;
    if (StartClock) {
      clearInterval(novoInterval);
      const fecha = objReserva.schedule_date.replace("T", " ").slice(0, 16);
      const fechaclar = moment(fecha).format("dddd DD [de] MMMM / HH:mm a");
      // console.log(fechaclar); // Lunes 29 de marzo / 11:00 am
      const timeStart = hourSync;
      const timeEnd = moment(fechaclar, "dddd DD [de] MMMM / HH:mm a");

      timeEnd.add(1, "hour");

      const diff = timeEnd.diff(timeStart, "seconds");
      if (diff < -500) {
        localStorage.removeItem("actualRutinaTime");
        // localStorage.removeItem("actualIndex");
        localStorage.removeItem("actualEjercicio");
      }
      actualTime.current = diff;

      novoInterval = setInterval(() => {
        if (actualTime.current > 0) {
          actualTime.current -= 1;

          setTimeLeft(fancyTimeFormat(actualTime.current));
          localStorage.timestampClass = 3600 - actualTime.current;
          // console.log(actualTime.current);
          if (actualTime.current == 300) {
            funcionConRetraso();
          }
        }
      }, 1000);
    }
  }, [StartClock]);

  // useEffect(() => {
  //   intervalSearch = setInterval(() => {
  //     instance
  //       .get(`booking/group/stats/${reserva}`)
  //       .then((resEn) => {
  //         const status_class = resEn.data;
  //         if (status_class) {
  //           instance.get(`/booking/group/prepare/${reserva}`).then((res) => {
  //             const info = res.data;

  //             setstats(status_class);
  //             setbookings(info.bookings);
  //           });
  //         }
  //       })
  //       .catch((err) => console.log(err.response));
  //   }, 1000);
  //   return () => {
  //     clearInterval(intervalSearch);
  //   };
  // }, []);

  useEffect(() => {
    async function trackingworkoutlog() {
      initSocketConnection(
        (client) => {
          console.log("estatus connected client", client);
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (change) => {
          console.log("booking log data", change);
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (workoutlog) => {
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (band) => {
          console.log(band);
          setbandConnected(true);

          // setModalInfo(
          //   <ModalInfo
          //     error="false"
          //     handleClick={() => setModalInfo(null)}
          //     title="Hola entrenador."
          //     text="La banda del atleta se ha conectado"
          //     classesMsg=""
          //   />
          // );
          // console.log('se conecto la banda');
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (bandDisconnected) => {
          console.log(bandDisconnected);
          setbandConnected(false);
          // setModalInfo(
          //   <SetModalError
          //     handleClick={() => setModalInfo(null)}
          //     title="La Banda HR se ha desconectado"
          //     msgError="El atleta intentará conectarse de nuevo"
          //   />
          // );

          console.log("se desconecto la banda");
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (hrData) => {
          setHrData((val) => ({ ...val, [hrData.booking_id]: hrData }));

          workoutlog_data_emit(
            JSON.stringify({
              booking_id: Number(hrData.booking_id),
              percentage: 100,
              kcal: hrData.kcal,
              duration_timestamp: localStorage.timestampClass
                ? localStorage.timestampClass
                : 0,
              heart_rate: hrData.heart_rate,

              trainer_change: false,
            })
          );
        },
        (lock) => {
          if (lock) {
            sethourSync(moment(lock * 1000));
            setStartClock(true);
            // if (bookings) {
            //   setbookings(bookings);
            // }
          }
        },
        (reasonDisconnect) => {
          if (reasonDisconnect && localStorage.allBokkingsElevva) {
            // let userDisconected = JSON.parse(reasonDisconnect);
            // let oneCustomer = JSON.parse(localStorage.allBokkingsElevva);
            // let totConnectedBool = oneCustomer.find(
            //   (e) => e.id == userDisconected.user
            // );
            // if (totConnectedBool) {
            //   totConnectedBool.connected = false;
            //   console.log("del disconected", oneCustomer);
            //   setbookings(
            //     oneCustomer
            //       .filter(
            //         (e) =>
            //           e.id != userDisconected.user && e.id != localStorage.uuid
            //       )
            //       .concat(totConnectedBool)
            //   );
            //   localStorage.allBokkingsElevva = JSON.stringify(
            //     oneCustomer
            //       .filter(
            //         (e) =>
            //           e.id != userDisconected.user && e.id != localStorage.uuid
            //       )
            //       .concat(totConnectedBool)
            //   );
            // }
            get_users_room_emit();
          }
        },
        (whoConected) => {
          if (whoConected && localStorage.allBokkingsElevva) {
            console.log(whoConected);
            get_users_room_emit();
            // let userconected = JSON.parse(whoConected);
            // console.log(JSON.parse(localStorage.allBokkingsElevva));
            // let oneCustomer = JSON.parse(localStorage.allBokkingsElevva);
            // let totConnectedBool = oneCustomer.find(
            //   (e) => e.id == userconected.customer
            // );
            // totConnectedBool.connected = true;
            // console.log(totConnectedBool);
            // setbookings(
            //   oneCustomer
            //     .filter((e) => e.id != userconected.customer)
            //     .concat(totConnectedBool)
            // );
            // localStorage.allBokkingsElevva = JSON.stringify(
            //   oneCustomer
            //     .filter((e) => e.id != userconected.customer)
            //     .concat(totConnectedBool)
            // );
          }
        },
        (usersRoom) => {
          if (usersRoom && localStorage.allBokkingsElevva) {
            let usersRoomConnected = usersRoom.users;
            let inputArray = JSON.parse(localStorage.allBokkingsElevva);

            const uniqueSet = new Set(inputArray.map(JSON.stringify));

            // Convertir el Set de nuevo a un array
            const oneCustomer = Array.from(uniqueSet).map(JSON.parse);
            console.log("cutomers-localstorgafe", oneCustomer, usersRoom);
            let totConnectedBool = oneCustomer.filter((e) =>
              usersRoomConnected.includes(e.id)
            );
            console.log("------antes de-----", totConnectedBool);
            if (totConnectedBool) {
              totConnectedBool.map((element) => {
                console.log(element);
                element.connected = true;
                return element;
              });

              console.log(totConnectedBool);

              setbookings(totConnectedBool);
            }
          }
        }
      );
    }
    trackingworkoutlog();
  }, []);

  const moment = extendMoment(Moment);
  function finalizarEntrenamiento() {
    instance
      .post(`booking/group/finish/${reserva}`)
      .then((res) => {
        const info = res.data;
        if (info) {
          bookings &&
            bookings.forEach((e) => {
              booking_finish_trainer_emit({
                booking_id: e.booking_id,
                trainer_id: user.trainer.user,
              });
            });

          setTimeout(() => {
            window.location.href = `${basename}/Entrenador/inicio`;
          }, 1000);
        }
      })
      .catch((err) => console.log(err.response));
  }
  useEffect(() => {
    setTimeout(() => {
      const objcStart = {
        id_clase: idclase,
        uid: user.trainer.user,
        reserva: reserva,
        type: 20,
        roleTrainer: "host",
        agoraToken: localStorage.agoraToken,
      };
      connectPeerToPeerTrainer(objcStart); // Iniciar la clase con el obj.id_clase
    }, 1000);

    instance
      .get(`/booking/group/prepare/${reserva}`)
      .then((res) => {
        const info = res.data;

        setbookings(info.bookings.map((e) => ({ ...e, connected: false })));
        console.log(
          "info.bookiing--------------->>>>>",
          info.bookings.map((e) => ({ ...e, connected: false }))
        );
        localStorage.allBokkingsElevva = JSON.stringify(
          info.bookings.map((e) => ({ ...e, connected: false }))
        );

        // const fecha = info.schedule_date.replace("T", " ").slice(0, 16);
        // const fechaclar = moment(fecha).format("dddd DD [de] MMMM / HH:mm a");
        // console.log(fechaclar); // Lunes 29 de marzo / 11:00 am
        // const timeStart = moment();
        // const timeEnd = moment(fechaclar, "dddd DD [de] MMMM / HH:mm a");

        setReserva(info);
        // timeEnd.add(1, "hour");

        // const diff = timeEnd.diff(timeStart, "seconds");
        // if (diff < -500) {
        //   localStorage.removeItem("actualRutinaTime");
        //   localStorage.removeItem("actualIndex");
        // }
        // actualTime.current = diff;
        sync_clock();
        get_users_room_emit();
      })
      .catch((err) => {
        setModalInfo(<SetModalError handleClick={() => setModalInfo(null)} />);
      });

    return () => {
      clearInterval(timeOutcheckVideo);
      clearInterval(timeOutcheckAudio);
      disconnectPeer();
    };
  }, []);

  const paneles = (
    <>
      <div
        className="tiempo"
        style={{
          background: "rgb(38,38,38,0.7)",
          borderRadius: "7px",
          position: "fixed",
          width: "16%",
          textAlign: "center",
          padding: "0.5vw 0",
          right: "0 ",
          zIndex: "999999",
          fontSize: "4vw",
          fontWeight: "700",
          fontWeight: "bold",
          color: "white",
        }}
      >
        <span>{timeLeft}</span>
        <br />
        <div
          style={{
            fontSize: "1vw",
            fontWeight: "700",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {" "}
          Tiempo de sesión
        </div>
      </div>
      <CommentsClients />
      <PanelGroup
        align="bottom"
        classNameContent="myPanel"
        contentRef={myPanel}
        tabCerrar={cerrar}
        tabRef={myPanelTab}
        tabAvatar={customAvatar}
        classNameTab="myPanelTab"
      >
        <div className="d-flex w-100">
          <div
            className="logo d-flex"
            style={{
              width: "20%",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              alt="logo"
              src={logo}
              style={{ width: "54%", margin: "5% 23%" }}
            />
            <div
              className="Finalizar1 "
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  color: "#394F65",

                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                Participantes
              </span>
              <button
                id="stopRecord"
                className="btnFinalizar_clase_stream mx-3 mt-2 d-initial"
                onClick={finalizarEntrenamiento}
              >
                Finalizar sesión
              </button>
            </div>
          </div>
          <div
            className="py-2"
            style={{
              width: "80%",
              display: "grid",
              gridTemplateColumns: "repeat(5,1fr)",
            }}
          >
            {" "}
            {bookings &&
              bookings
                .filter(
                  (person) =>
                    Object.keys(HrData) &&
                    Object.keys(HrData).includes(String(person.booking_id)) &&
                    person.connected == true
                )
                .map((e, indice) => (
                  <div
                    className="py-2"
                    style={{
                      width: "80%",
                    }}
                  >
                    <div className="d-flex">
                      <div style={{ padding: "0.6vw 0" }}>
                        <img
                          src={e.profile_pic}
                          style={{
                            width: "4vw",
                            height: "4vw",
                            borderRadius: "60px",
                            padding: "0.5vw",
                            objectFit: "cover",
                          }}
                        />{" "}
                      </div>
                      <div
                        style={{
                          padding: "0.5vw",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "#394F65",
                            fontSize: "0.7vw",
                          }}
                        >
                          {e["full_name"]}
                        </div>

                        <div className="d-flex">
                          <div style={{ padding: "0.4vw 0" }}>
                            <img
                              src={corazon}
                              style={{
                                width: "1.2vw",
                                height: "1.2vw",
                              }}
                            />
                          </div>

                          {HrData &&
                          HrData[e.booking_id] &&
                          HrData[e.booking_id].heart_rate > 0 ? (
                            <div
                              style={
                                HrData[e.booking_id].heart_rate >=
                                (220 - e["age"]) * 0.9
                                  ? {
                                      color: "#E16250",
                                      fontSize: "1.7vw",
                                      padding: "0 0.6vw",
                                    }
                                  : (220 - e["age"]) * 0.8 <=
                                      HrData[e.booking_id].heart_rate &&
                                    HrData[e.booking_id].heart_rate <
                                      (220 - e["age"]) * 0.9
                                  ? {
                                      color: "#FFB6A4",
                                      fontSize: "1.7vw",
                                      padding: "0 0.6vw",
                                    }
                                  : (220 - e["age"]) * 0.7 <=
                                      HrData[e.booking_id].heart_rate &&
                                    HrData[e.booking_id].heart_rate <
                                      (220 - e["age"]) * 0.8
                                  ? {
                                      color: "#F5D7CF",
                                      fontSize: "1.7vw",
                                      padding: "0 0.6vw",
                                    }
                                  : (220 - e["age"]) * 0.6 <=
                                      HrData[e.booking_id].heart_rate &&
                                    HrData[e.booking_id].heart_rate <
                                      (220 - e["age"]) * 0.7
                                  ? {
                                      color: "#7988F6",
                                      fontSize: "1.7vw",
                                      padding: "0 0.6vw",
                                    }
                                  : {
                                      color: "#6EE1FB",
                                      fontSize: "1.7vw",
                                      padding: "0 0.6vw",
                                    }
                              }
                            >
                              {HrData[e.booking_id].heart_rate &&
                              HrData[e.booking_id].heart_rate > 0
                                ? HrData[e.booking_id].heart_rate
                                : "--"}
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "#394F65",
                                fontSize: "1.7vw",
                                padding: "0 0.6vw",
                              }}
                            >
                              --
                            </div>
                          )}
                        </div>

                        {HrData &&
                        HrData[e.booking_id] &&
                        HrData[e.booking_id].kcal ? (
                          <div className="d-flex">
                            <div style={{ padding: "0.4vw 0" }}>
                              {HrData[e.booking_id].kcal > -2 ? (
                                <img
                                  src={flama}
                                  style={{
                                    width: "1.2vw",
                                    height: "1.2vw",
                                    objectFit: "contain",
                                  }}
                                />
                              ) : null}
                            </div>{" "}
                            <div
                              style={{
                                color: "#394F65",
                                fontSize: "1.7vw",
                                padding: "0 0.6vw",
                              }}
                            >
                              {HrData[e.booking_id].kcal > 0
                                ? HrData[e.booking_id].kcal
                                : "--"}
                            </div>
                          </div>
                        ) : null}
                        <div style={{ fontSize: "0.5vw", color: "#394F65" }}>
                          {" "}
                          <div style={{ fontWeight: "bold" }}> Nivel :</div>
                          <div>
                            {e["level"] == 20
                              ? "Intermedio"
                              : e["level"] == 30
                              ? "Avanzado"
                              : "Principiante"}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                ))}
          </div>
        </div>
      </PanelGroup>
    </>
  );
  return (
    <>
      <ContenedorClase
        paneles={paneles}
        timeLeft={timeLeft}
        trainerMusic={true}
      />

      <div id="camera-list" className="dropdown-menu dropdown-menu-right">
        {" "}
      </div>
      {modalInfo}
    </>
  );
};

export default EntrenadorClasestream;
