import { useHistory, useParams } from "react-router";
import { ButtonMob, laptop575 } from "../utils/utils";
import { useState, useRef } from "react";
import "./vertipoindividual.css";
import { Videos_two } from "../Ejercicios/othervideos";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const VerTipoIndividual = () => {
  const { id } = useParams();
  const history = useHistory();
  const searchText = useRef(null);
  const videosTime = [
    ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
    ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
    ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
    ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
    ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
    ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
    ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
    ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
  ];
  const videoIndividualBalance =
    id == "Entrenamiento1"
      ? [
          "https://elevva-b1.s3.us-east-1.amazonaws.com/VIDEOMETODOELEVVAAVANZADO.mp4",
          "Elevva funcional",
          "35min",
          "Elevva funcional clase 1",
        ]
      : id == "Entrenamiento"
      ? [
          "https://elevva-b1.s3.us-east-1.amazonaws.com/balanceVideo.mp4",
          "Balance",
          "35min",
          "Balance clase 1",
        ]
      : id.includes("Nutrición")
      ? [
          "https://elevva-b1.s3.us-east-1.amazonaws.com/comidayemociones.mp4",
          "Comida y emociones",
          "55min",
          "Comida y Emociones clase 1",
        ]
      : id.includes("Mente")
      ? [
          "https://elevva-b1.s3.us-east-1.amazonaws.com/Elpoderdelaautocompasi%C3%B3n.mp4",
          "El poder de la autocompasión",
          "29min",
          "El poder de la autocompasión",
        ]
      : [
          process.env.PUBLIC_URL +
            "https://elevva-b1.s3.us-east-1.amazonaws.com/HerramientsMindfulnessAtenci%C3%B3nPlena.mp4",
          "Comida y emociones",
          "55min",
          "Comida y emociones",
        ];

  const [reproducir, setreproducir] = useState(false);
  return (
    <div
      className="d-flex m-auto"
      style={{
        height: "100vh",
      }}
    >
      {/* {laptop575.matches ? (
        <div className="mx-auto" style={{ width: "7.1%" }}></div>
      ) : null} */}
      {laptop575.matches ? (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex"
            style={{
              background: "rgb(241, 246, 249)",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
              flexDirection: "column",
            }}
          >
            <div
              className="py-2 mx-3 banner_one "
              style={{
                background: "rgb(241, 246, 249)",

                color: "#394F65",
              }}
            >
              {" "}
              <div
                onClick={() => history.push("/ejercicios")}
                className=" position-absolute"
                style={{
                  left: "5%",
                  top: "35%",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {" "}
                <span className="d-flex m-auto">
                  <span
                    className="my-auto mx-1 px-2 py-1 d-flex"
                    style={{
                      border: "1px solid #394F65",
                      justifyContent: "center",
                      color: "#394F65",
                      borderRadius: "10px",
                    }}
                  >
                    {" "}
                    <i className="fa fa-arrow-left  "></i>
                  </span>
                  <span className="my-auto m-1" style={{ color: "#394F65" }}>
                    Regresar
                  </span>
                </span>
              </div>
              <div
                className="d-flex position-absolute"
                style={{
                  left: "45%",
                  top: "30%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <img
                  style={{ width: "200px" }}
                  src={process.env.PUBLIC_URL + "/img/logosEmpresas.png"}
                />{" "}
              </div>
              <Link
                className="form searchEjercicio mx-auto py-2 d-flex"
                style={{ justifyContent: "flex-end" }}
                to="/ejercicios/Buscar"
              >
                <input
                  placeholder="Buscar..."
                  ref={searchText}
                  type="search"
                  className="input_searchvideos ejercicios_c py-2"
                  // onChange={(e) => changeOptionVideos(e.target.value)}
                ></input>
              </Link>
            </div>
          </div>
          <br />
          <div
            className="d-flex col-12"
            style={{
              paddingLeft: "3%",
            }}
          >
            <div
              className="col-6 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ padding: "2%" }}>
                <ReactPlayer
                  className="position-relative playervideoIndividual"
                  url={videoIndividualBalance[0]}
                  config={
                    id.includes("Mente")
                      ? {
                          file: {
                            attributes: {
                              preload: "true",
                              poster:
                                process.env.PUBLIC_URL +
                                "/img/autocompaprograma.jpg",
                            },
                          },
                        }
                      : id.includes("Nutrición")
                      ? {
                          file: {
                            attributes: {
                              preload: "true",
                              poster:
                                process.env.PUBLIC_URL +
                                "/img/nutricionprograma.jpg",
                            },
                          },
                        }
                      : id == "Entrenamiento1"
                      ? {
                          file: {
                            attributes: {
                              preload: "true",
                              poster:
                                process.env.PUBLIC_URL + "/img/programaele.jpg",
                            },
                          },
                        }
                      : id == "Entrenamiento" || id == "Entrenamiento0"
                      ? {
                          file: {
                            attributes: {
                              preload: "true",
                              poster:
                                process.env.PUBLIC_URL +
                                "/img/prevideobalance.jpg",
                            },
                          },
                        }
                      : {
                          file: {
                            attributes: {
                              preload: "true",
                            },
                          },
                        }
                  }
                  pip={true}
                  height="100%"
                  width={"100%"}
                  playing={reproducir}
                  controls={reproducir}
                />
              </div>
            </div>

            <div
              className="col-6 d-flex"
              style={{
                marginLeft: "2%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "#394F65",
                  fontSize: "3vmin",
                  padding: "0 2%",
                  fontWeight: "600",
                }}
              >
                {videoIndividualBalance[3]}
              </span>

              <span
                style={{
                  color: "#CB3D82",
                  fontSize: "1.5vmin",
                  padding: "0% 2%",
                  fontWeight: "600",
                }}
              >
                {videoIndividualBalance[2]} - {videoIndividualBalance[1]}
              </span>

              <span
                style={{
                  color: "#394F65",
                  fontSize: "1.6vmin",
                  padding: "0 2%",
                }}
              >
                En nuestra clase de hoy tendremos un trabajo muy completo de{" "}
                {videoIndividualBalance[1]}.
              </span>

              <div
                className="position-relative d-flex"
                style={{
                  justifyContent: "flex-start",
                  left: "0",
                  alignItems: "flex-start",
                }}
              >
                <button
                  style={{ marginTop: "1vmin", marginBottom: "1vmin" }}
                  onClick={() => setreproducir(!reproducir)}
                  className="buttonIndividualTypesverahora"
                >
                  VER AHORA
                </button>
              </div>
              <div>
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",
                    padding: "0% 2%",
                    fontWeight: "600",
                  }}
                >
                  Tipo de entrenamiento
                </span>
                <div
                  className="d-flex "
                  style={{
                    justifyContent: "flex-start",
                    marginTop: "1vmin",
                    marginBottom: "1vmin",
                  }}
                >
                  <button className="buttonIndividualTypes">Fuerza</button>
                  <button className="buttonIndividualTypes">
                    Coordinación
                  </button>
                  <button className="buttonIndividualTypes">Cardio</button>
                  <button className="buttonIndividualTypes">Resistencia</button>
                </div>
              </div>
              <div>
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",
                    padding: "0% 2%",
                    fontWeight: "600",
                  }}
                >
                  Zona a trabajar
                </span>
                <div
                  className="d-flex "
                  style={{
                    justifyContent: "flex-start",
                    marginTop: "1vmin",
                    marginBottom: "1vmin",
                  }}
                >
                  <button className="buttonIndividualTypes">Pierna</button>
                  <button className="buttonIndividualTypes">Abdomen</button>
                </div>
              </div>
              <div>
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",
                    padding: "0% 2%",
                    fontWeight: "600",
                  }}
                >
                  Intensidad
                </span>
                <div
                  className="d-flex "
                  style={{
                    justifyContent: "flex-start",
                    marginTop: "1vmin",
                    marginBottom: "1vmin",
                  }}
                >
                  <button className="buttonIndividualTypes">Media</button>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              paddingLeft: "3%",
            }}
          >
            <span
              style={{
                color: "#394F65",
                fontSize: "2vmin",
                padding: "0 2%",
                fontWeight: "600",
              }}
            >
              Programa {videoIndividualBalance[1]}
            </span>
            <br />
            <div
              className="my-1"
              style={{
                color: "#394F65",
                fontSize: "1.3vmin",
                padding: "0 2%",
                maxWidth: "600px",
              }}
            >
              El programa de {videoIndividualBalance[1]} de elevva, tiene como
              objetivo aumentar la resistencia cardiovascular, el desarrollo de
              la fuerza y la perdida de grasa residual del cuerpo.
            </div>

            <span
              style={{
                color: "#CB3D82",
                fontSize: "13px",
                padding: "1% 2%",
                fontWeight: "600",
              }}
            >
              DURACIÓN: 8 CLASES
            </span>
          </div>
          <br />
          <div
            style={{
              backgroundColor: "#FDF1E8",
              paddingLeft: "5%",
              paddingTop: "1%",
            }}
          >
            <Videos_two
              contenido={id.slice(0, id.length - 1)}
              textTitleOne="Siguientes"
              textTitleTwo="Clases"
              videosTime={videosTime}
              id="pin"
            />
            <br />
            <br />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default VerTipoIndividual;
