import "./Creditos.css";
import { Fragment, useEffect, useState, useRef } from "react";
import {
  Loadingspinner,
  ModalInfo,
  ModalInforedimir,
  frontURL,
  userType,
  basename,
  instance,
  NuevaAxiosURL,
  testPayu,
  laptop575,
} from "../utils/utils";
import ReactGA from "react-ga";
import { Redirect } from "react-router-dom";
import axios from "axios";
import flecha from "../Navbar/icons/goback.svg";

import imgarriba from "../assets/img/Auth_images/arribaimagen.png";
import imgabajo from "../assets/img/Auth_images/abajoimagen.png";
import cuponImg from "../assets/img/creditos/imagenes/cupon.png";
import pesoImg from "../assets/img/creditos/imagenes/dolar.png";

import iconcheck from "../assets/img/creditos/imagenes/seguimos.png";
// e18a24f4daf54ec7bc47ff2ac70f791f
// https://webdemo.agora.io/agora_webrtc_troubleshooting/
// https://webdemo.agora.io/agora-websdk-api-example-4.x/recordingDeviceControl/index.html#
// https://api.mob.fitness/admin/
// para graficar tiempo nivo
// https://codesandbox.io/s/affectionate-currying-oh80bs?file=/src/App.js
// 323 yanitza
// admin
// p@251000..
// admin_mob clave Supremacy.2021
// https://github.com/Genymobile/scrcpy/releases/download/v1.23/scrcpy-win64-v1.23.zip
// SELECT id,email, nombre, apellido, telefono, log from clientes where log between '2021-11-09' and '2021-11-10';
// consultas mob x2
// SELECT id,email, nombre, apellido, telefono, log from clientes where log between '2021-09-29' and '2021-10-15';
// felipe- user y clave consultas mob x2C:\Users\User\AppData\Local\Android\Sdk
// https://mob.fitness/phpmyadmin/index.php?route=/table/sql&db=admin_mobplatform&table=clientes
// https://mob.fitness/app/sistema/views/entrenador/perfil/cuentas/ac607280845b56bfeff8cf53913b1259.pdf
// https://developers.google.com/tag-manager/enhanced-ecommerce?hl=es  trnasaccion
// https://github.com/AgoraIO-Community/AgoraWebSDK-NG/blob/master/Demo/basicVideoCall/basicVideoCall.js    demovideocall
// https://www.urldecoder.org/  decoder
// https://mob.fitness/phpmyadmin/index.php?route=/&route=%2F       ingresardata
// https://codebeautify.org/htmlviewer  ver errores en backend
// ios.app@sportcheck.com.co Supremacy2021
// warning: LF will be replaced by CRLF in src/Creditos/dapp/TodoList.json.
// export NODE_OPTIONS="--max-old-space-size=8192"
const Creditos = (props) => {
  const data_user = props.dataCustomer;

  const [listPlans, setListPlans] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [modalInforedimir, setmodalInforedimir] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const cupon = useRef(null);
  const [cuponValido, setCuponValido] = useState(false);
  const formPayu = useRef(null);
  const refCupon = useRef(null);
  const listaPlanes = useRef(null);
  const refRedimir = useRef(null);
  const [valor_pagar, setValor] = useState(null);
  const [total_pagar, setTotal] = useState(null);
  const [percentage_descuento, setDescuento] = useState(null);
  const [creditos_obsequio, setcreditos_obsequio] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [typedCupon, setTypedCupon] = useState(null);
  const [isValidcupon, setIsValidcupon] = useState(true);
  const [DescripcionPlan, setDescripcionPlan] = useState(null);
  const [toCurrency, setToCurrency] = useState(null);
  const [cuponListo, setCuponListo] = useState(false);
  const [cuponValidoingresado, setcuponValidoingresado] = useState(false);
  const [sesionsclass, setsesionsclass] = useState(null);
  const [total_pagar1, settotal_pagar1] = useState(null);
  const [percentaje, setpercentaje] = useState(0);

  const locales = {
    USD: "en-US",
    MXN: "es-MX",
    EUR: "es-ES",
    COP: "es-CO",
    PEN: "es-PE",
    CLP: "es-CL",
  };

  function hideModal() {
    setModalInfo(null);
  }
  function hideModalred() {
    window.location.href = `${basename}/${userType}/reservas/10`;
    // setModalInfo(<Redirect to={`/${userType}/reservas/10`} />);
  }
  function hideModalredimir() {
    if (data_user.customer.transportation == null) {
      // window.location.href = `${basename}/${userType}/completar`;
      setModalInfo(<Redirect to={`/${userType}/completar`} />);
    } else {
      window.location.href = `${basename}/${userType}/reservas/10`;
      // setModalInfo(<Redirect to={`/${userType}/reservas/10`} />);
    }
  }
  function hideModalredimirsitieneval() {
    setModalInfo(<Redirect to={`/${userType}/reservas`} />);
  }
  const [misCreditos, setMisCreditos] = useState(0);
  const [saludhabilitado, setsaludhabilitado] = useState(true);
  const [valoracion, setvaloracion] = useState(false);
  useEffect(() => {
    ReactGA.initialize("UA-238615776-1");
  }, []);
  const Activarcampaña = (e) => {
    // console.log("rancherias");
    ReactGA.pageview("conversion_creditos/");
  };
  function checkData(data_user) {
    if (data_user.customer) {
      setsaludhabilitado(data_user.customer.can_reserve);
      if (
        data_user.customer.had_valoration == false &&
        data_user.customer.credits_balance > 0
      )
        setvaloracion(true);

      setMisCreditos(data_user.customer.credits_balance);
    }
  }

  useEffect(() => {
    checkData(data_user);

    instance.get(`${NuevaAxiosURL}customers/plans/`).then((resEn) => {
      const info = resEn.data;

      const current = info[0].currency;

      setToCurrency(current);
    });
  }, [data_user]);
  useEffect(() => {
    if (toCurrency != null) {
      var formData = new FormData();
      formData.append("data", localStorage.my_code);

      instance
        .get(`${NuevaAxiosURL}customers/plans/`)
        .then((resEn) => {
          const info = resEn.data;

          const current = info[0].currency;

          setToCurrency(current);

          const count = info.length;
          const descripcion = [];
          const ids = [];
          const disi = [];
          const enumerar = [];
          const descuento = [];
          const nombre = [];
          const valor = [];

          for (let i = 0; i < info.length; i++) {
            descripcion.push(info[i]["description"]);

            descuento.push(0);
            ids.push(info[i]["id"]);
            enumerar.push([i]);
            disi.push(
              [i] == 0
                ? "1"
                : [i] == 1
                ? "2"
                : [i][0] == 2
                ? "3"
                : [i][0] == 3
                ? "4"
                : [i][0] == 4
                ? "5"
                : [i][0] == 5
                ? "6"
                : [i][0] == 6
                ? "7"
                : null
            );
            nombre.push(info[i]["name"]);
            valor.push(info[i]["cost"]);
          }
          if (count <= 0) {
            setModalInfo(
              <ModalInfo
                error="true"
                handleClick={hideModal}
                title="Algo salio mal"
                text="No podemos encontrar los planes. Intentalo nuevamente en unos minutos"
                classesMsg=""
              />
            );
          } else {
            const arr = [];

            for (let i = 0; i < count; i++) {
              const valor_descuento =
                descuento[i] > 1 ? descuento[i] / 100 : descuento[i];

              arr[ids[i]] = [
                nombre[i].match(/([\d]*)/)[1],
                valor[i],
                descripcion[i],
                valor_descuento,
              ];
            }

            setListPlans(arr);
            setSelectedPlan(1);
          }
          setIsLoaded(true);
        })
        .catch((err) => {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Algo salio mal"
              text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
              classesMsg=""
            />
          );
        });
    }
    return () => {};
  }, [toCurrency]);

  function selectPlan(id) {
    setSelectedPlan(id);
  }

  const description = () => {
    if (!selectedPlan) {
      return <></>;
    }
    const cantidad = listPlans[selectedPlan][0];
    return (
      <div>
        <div className="description mx-auto pe-3">
          Este plan te permitirá tomar {cantidad} clase{cantidad > 1 ? "s" : ""}
          .
          <span className="vigencia">
            &nbsp;Tiene una vigencia de 30 días a partir de la fecha que haga la
            compra.{" "}
          </span>
        </div>
        <img className="linegruesa" />
      </div>
    );
  };

  useEffect(() => {
    // console.log(
    //   "listPlans[selectedPlan][3]",
    //   listPlans[selectedPlan][1] ? listPlans[selectedPlan] : null
    // );
    if (selectedPlan != null) {
      refCupon.current.value = "";
      const mirepercentasje = !percentaje
        ? listPlans[selectedPlan][3]
        : percentaje;
      const descontar = 1 - mirepercentasje;
      const total = new Intl.NumberFormat(locales[toCurrency], {
        localeMatcher: "lookup",
        style: "currency",
        currency: toCurrency,
        minimumFractionDigits: 2,
      }).format(listPlans[selectedPlan][1] * descontar);
      const desc = new Intl.NumberFormat(locales[toCurrency], {
        localeMatcher: "lookup",
        style: "percent",
        minimumFractionDigits: 2,
      }).format(listPlans[selectedPlan][3]);
      setTotal(total);
      setDescripcionPlan(listPlans[selectedPlan][2]);
      settotal_pagar1(listPlans[selectedPlan][1]);
      setDescuento(desc);
      const valor = new Intl.NumberFormat(locales[toCurrency], {
        localeMatcher: "lookup",
        style: "currency",
        currency: toCurrency,
        minimumFractionDigits: 2,
      }).format(listPlans[selectedPlan][1]);
      setValor(valor);
    }
  }, [selectedPlan, percentaje]);

  const infoValor = () => {
    if (selectedPlan == null) {
      return <></>;
    }

    return (
      <table className="infoValor mx-auto">
        <tbody>
          <tr>
            <th className="info12">Valor:</th>
            <th className="value">{valor_pagar}</th>
          </tr>
          <tr>
            <th className="info12">Descuento:</th>
            <th className="value">
              {!percentage_descuento ? "0,00%" : percentage_descuento}
            </th>
          </tr>
          <tr>
            <th className="info12">Creditos de regalo:</th>
            <th className="value">
              {!creditos_obsequio ? "0" : creditos_obsequio}
            </th>
          </tr>
          <tr>
            <th className="info12">Total:</th>
            <th className="value">{total_pagar}</th>
            <th className="value currency">{toCurrency}</th>
          </tr>
        </tbody>
      </table>
    );
  };
  const infoValormitad = () => {
    if (selectedPlan == null) {
      return <></>;
    }
    return (
      <div className="  descuentobtn019 py-4">
        {" "}
        <table className="infoValormedio mx-auto py-auto">
          <tbody>
            <tr>
              <tr>
                <th className="info12">Valor:</th>
                <th className="value">{valor_pagar}</th>
                <th className="value currency">{toCurrency}</th>
              </tr>
              <tr>
                <th className="info12">Descuento:</th>
                <th className="value">
                  {!percentage_descuento ? "0,00%" : percentage_descuento}
                </th>
              </tr>
              <tr>
                <th className="info12">Creditos de regalo:</th>
                <th className="value">
                  {!creditos_obsequio ? "0" : creditos_obsequio}
                </th>
              </tr>
              <tr>
                <th className="info12">Total:</th>
                <th className="value">{total_pagar}</th>
                <th className="value currency">{toCurrency}</th>
              </tr>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const goToPay = () => {
    if (saludhabilitado == false) {
      setModalInfo(
        <>
          <ModalInfo
            error="none"
            handleClick={hideModal}
            title="Antes de empezar a hacer actividad física"
            text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
          />
        </>
      );
    } else {
      if (valoracion) {
        setModalInfo(
          <>
            <ModalInfo
              error="none"
              handleClick={hideModalred}
              title="Antes de empezar a hacer actividad física"
              text="Queremos recordarte que aún no has hecho tu clase de valoración, sigue conociendote a ti mismo"
            />
          </>
        );
      } else {
        setModalInfo(
          <ModalInfo
            error="none"
            handleClick={hideModal}
            title="Procesando"
            text={<Loadingspinner customStyle="grow" size="3rem" />}
            classesMsg=""
          />
        );
        proccessCupon(true);
      }
    }
  };

  const goRedimir = () => {
    if (saludhabilitado == false) {
      setModalInfo(
        <>
          <ModalInfo
            error="none"
            handleClick={hideModal}
            title="Antes de empezar a hacer actividad física"
            text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
          />
        </>
      );
    } else {
      instance
        .post(`${NuevaAxiosURL}customers/coupons/validate/${typedCupon}`)
        .then((res) => {
          const data = res.data;
        })
        .catch((err) => {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Algo salio mal"
              text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
              classesMsg=""
            />
          );
        });
      if (data_user.customer.had_valoration) {
        setmodalInforedimir(
          <ModalInforedimir
            icon={iconcheck}
            error="none"
            handleClick={hideModalredimirsitieneval}
            title="Sigue así"
            text="Continúa con la misma disciplina cada día"
            classesMsg=""
          />
        );
      } else {
        setmodalInforedimir(
          <ModalInforedimir
            icon={iconcheck}
            error="none"
            handleClick={hideModalredimir}
            title="Recuerda agendar tu clase de valoración"
            text="Para poder generar tu plan de entrenamiento, es necesario que tu primera clase sea de valoración.<br/><br/>No te preocupes, por la compra de cualquiera de nuestros planes, MOB te obsequia un crédito para que puedas agendar tu clase sin problemas.<br/><br/>Este crédito estará disponible en tu paquete tan pronto hagas tu primera compra."
            classesMsg=""
          />
        );
      }
    }
  };

  function proccessCupon(redirect = false, check_again = false) {
    const formDataPayu = formPayu.current;
    const inputForm = formPayu.current.children;

    let formData = new FormData();

    formData.append("currency", toCurrency);

    formData.append("discount", percentaje * 100);
    formData.append("plan", selectedPlan);
    formData.append(
      "description",
      DescripcionPlan ? DescripcionPlan.slice(0, 128) : null
    );
    formData.append("amount", total_pagar1 - total_pagar1 * percentaje);
    instance
      .post(`${NuevaAxiosURL}customers/transactions/new/`, formData)
      .then((resEn) => {
        if (resEn.data) {
          const referenceCode = resEn.data.reference;
          const amount = resEn.data.amount;
          const currency = resEn.data.currency;
          let formDataSignature = new FormData();
          formDataSignature.append("currency", currency);
          formDataSignature.append("amount", amount);
          formDataSignature.append("referenceCode", referenceCode);

          instance
            .get(
              `customers/payments/payu/signature/?currency=${currency}&referenceCode=${referenceCode}&amount=${amount}`
            )
            .then((resEn) => {
              const Signature = resEn.data;

              inputForm["buyerEmail"].value = data_user.email;
              inputForm["signature"].value = Signature.signature;
              inputForm["accountId"].value = Signature.accountId;
              inputForm["merchantId"].value = Signature.merchantId;
              inputForm["taxReturnBase"].value = "0";
              inputForm["referenceCode"].value = referenceCode;

              inputForm["currency"].value = currency;
              inputForm["tax"].value = "0";
              inputForm["amount"].value = amount;
              inputForm["description"].value = DescripcionPlan;

              if (creditos_obsequio > 0) {
                instance
                  .post(
                    `${NuevaAxiosURL}customers/coupons/validate/${typedCupon}`
                  )
                  .then((res) => {
                    const data = res.data;
                  })
                  .catch((err) => {
                    setModalInfo(
                      <ModalInfo
                        error="true"
                        handleClick={hideModal}
                        title="Algo salio mal"
                        text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
                        classesMsg=""
                      />
                    );
                  });
              }
              Activarcampaña();
              formDataPayu.submit();
              return;
            })
            .catch((err) => {
              alert("perra");
              console.log("transactionnews", err.response);
            });

          // document.location.href = `${basename}/Usuario/Pasarelapagos/${selectedPlan}/${percentaje}/${toCurrency}`;
        }
      })
      .catch((err) => {
        console.error("Error en customers/plans/", err.response);
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });

    if (check_again !== false) {
      formData.append("final", "final");
      formData.append("valor", check_again);
      formData.append("currency", toCurrency);
    }
  }

  const redimirOComprar = cuponValido ? (
    <>
      <div
        className="btnReclamar btnRedPag"
        ref={refRedimir}
        onClick={goRedimir}
      >
        Redimir
        {/* aki */}
        <img src={cuponImg} alt="cupon" />
      </div>
    </>
  ) : cuponValidoingresado ? (
    <>
      <div
        className="btnReclamar btnRedPag"
        onClick={() => document.location.reload()}
      >
        Volver
        <img src={flecha} alt="cupon" style={{ width: "10px" }} />
      </div>
    </>
  ) : (
    <>
      <div className="btnPagar btnRedPag" onClick={goToPay}>
        <div className="btnPagar2">Pagar</div>
        <img src={pesoImg} alt="cupon" />
      </div>
    </>
  );
  const redimir = cuponValido ? (
    <>
      <div
        className="btnReclamar btnRedPag"
        ref={refRedimir}
        onClick={goRedimir}
      >
        Redimir
        <img src={cuponImg} alt="cupon" />
      </div>
    </>
  ) : (
    <>
      <div className="btnPagar btnRedPag" onClick={goToPay}>
        <div className="btnPagar2">Redimir bono</div>
        <img src={pesoImg} alt="cupon" />
      </div>
    </>
  );
  const comprarplan = cuponValido ? (
    <>
      <div
        className={
          laptop575.matches
            ? "btnReclamar btnRedPag d-none"
            : "btnReclamar btnRedPag "
        }
        ref={refRedimir}
        onClick={goRedimir}
      >
        Redimir
        <img src={cuponImg} alt="cupon" />
      </div>
    </>
  ) : (
    <>
      <div className={`d-row w-100 `}>
        <div>
          <div className="btnPagar btnRedPag my-3" onClick={goToPay}>
            <div className="btnPagar2">Comprar plan</div>
            <img src={pesoImg} alt="cupon" />
          </div>
          {/* <div
            className="btnPagar btnRedPag my-3"
            onClick={() =>
              setModalInfo(<Redirect   to={`/${userType}/Dapp`} />)
            }
          >
            <div className="btnPagar2">Pagar con ether</div>
            <img src={pesoImg} alt="cupon" />
          </div> */}
        </div>
      </div>
    </>
  );
  // técnico.co@payu.co
  function verifyCupon(strCupon, descuento = false) {
    console.log(strCupon, typeof strCupon);
    if (!strCupon) return;
    cupon.current = strCupon;
    if (!descuento) {
      instance
        .get(`${NuevaAxiosURL}customers/coupons/validate/${strCupon}`)
        .then((res) => {
          const data = res.data;

          if (!data) {
            setCuponValido(false);
            setIsValidcupon(false);
            verifyCupon(strCupon, true); // Ahora verificamos si hay descuento, ya que no hay cupon de clase gratis
          } else if (data.credits == 0 && data.percentage > 0) {
            setCuponValido(false);
            setIsValidcupon(true);
            setcuponValidoingresado(false);
            setpercentaje(data.percentage / 100);
            setSelectedPlan(selectedPlan);
            const tt = (
              <span style={{ textDecoration: "line-through" }}>
                {valor_pagar}
              </span>
            );
            setValor(tt);
            setDescuento(data.percentage + "%");
            listaPlanes.current.style = "pointer-events:none; opacity: 0.7";
          } else if (data.credits > 0 && data.percentage == 0) {
            setCuponValido(selectedPlan == 1 ? true : false);
            setIsValidcupon(true);
            setcuponValidoingresado(false);
            setSelectedPlan(selectedPlan);
            const total = new Intl.NumberFormat(locales[toCurrency], {
              style: "currency",
              currency: toCurrency,
              minimumFractionDigits: 0,
            }).format(0);
            const tt = (
              <span style={{ textDecoration: "line-through" }}>
                {valor_pagar}
              </span>
            );
            setcreditos_obsequio(data.credits);
            setDescuento(
              selectedPlan == 1 ? "Clase gratis" : data.percentage + "%"
            );
            if (selectedPlan == 1) {
              setValor(tt);
              setTotal(total);
            }

            listaPlanes.current.style = "pointer-events:none; opacity: 0.7";
          } else {
            const id = localStorage.idClaseCompra;
            localStorage.idClaseCompra = 0;
            if (id && id > 0) {
              refRedimir.current.style = "pointer-events:none; opacity: 0.7";
              setModalInfo(
                <ModalInfo
                  error="none"
                  handleClick={hideModal}
                  title="Procesando"
                  text={<Loadingspinner customStyle="grow" size="3rem" />}
                  classesMsg=""
                />
              );
              // var formData = new FormData();
              // formData.append("idclase", id);
              // formData.append("data", localStorage.my_code);
              // axios
              //   .post(`${AxiosURL}api/clientes/clases/reservar`, formData)
              //   .then((resEn) => {
              //     const info = resEn.data;
              //     console.log(info);
              //     const razon = info[0];
              //     const fecha = info[1];
              //     const hora = info[2];
              //     if (razon == "ok") {
              //       setModalInfo(
              //         <ModalInfo
              //           error="false"
              //           handleClick={hideModal}
              //           title="Entrenamiento reservado"
              //           text={`Se ha reservado la clase para el día ${fecha} en la hora ${hora}`}
              //           classesMsg=""
              //         />
              //       );
              //     } else if (razon == "creditos") {
              //       setModalInfo(
              //         <ModalInfo
              //           error="true"
              //           handleClick={hideModal}
              //           title="El entrenamiento no ha sido reservado"
              //           text="No tiene los creditos suficientes para reservar la clase"
              //           classesMsg=""
              //         />
              //       );
              //       localStorage.idClaseCompra = id;
              //       setTimeout(() => {
              //         setModalInfo(
              //           <Redirect   to={`/${userType}/creditos`} />
              //         );
              //       }, 4000);
              //     } else {
              //       setModalInfo(
              //         <ModalInfo
              //           error="true"
              //           handleClick={hideModal}
              //           title="El entrenamiento no ha sido reservado"
              //           text="Ya tiene una clase reservada a esta hora"
              //           classesMsg=""
              //         />
              //       );
              //     }
              //     setTimeout(() => {
              //       setModalInfo(<Redirect   to={`/${userType}/inicio`} />);
              //     }, 2000);
              //   })
              //   .catch((err) => {
              //     console.error("Error en api/clientes/clases/reservar", err);
              //     setModalInfo(
              //       <ModalInfo
              //         error="true"
              //         handleClick={hideModal}
              //         title="Algo salio mal"
              //         text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
              //         classesMsg=""
              //       />
              //     );
              //   });
            }
          }
        })
        .catch((err) => {
          console.error(
            "Error en api/clientes/clases/verificar-cupon",
            err.response
          );
          setIsValidcupon(false);
          if (err.response.data.detail == "No encontrado.") {
            setCuponValido(false);
            setIsValidcupon(false);
            listaPlanes.current.style = "pointer-events:none; opacity: 0.7";
            setcuponValidoingresado(true);
          } else {
            setModalInfo(
              <ModalInfo
                error="true"
                handleClick={hideModal}
                title="Información de Cupon"
                text={
                  "Hola Elevva+, lo sentimos " +
                  err.response.data +
                  " o su fecha de vencimiento expiró"
                }
                classesMsg=""
              />
            );
          }
        });
    } else {
      proccessCupon();
    }
  }
  const laptop = window.matchMedia("(max-width: 830px)");

  return (
    <>
      {laptop.matches === true ? (
        <>
          {" "}
          <div className="">
            <div className={`${isLoaded == false ? "" : "d-none"} my-3`}>
              <Loadingspinner customStyle="grow" size="3rem" />
            </div>
            <div
              className={`${
                isLoaded == false ? "invisible" : ""
              } ContainerCreditos`}
            >
              <div className="title mx-auto">Compra tus créditos</div>
              <div className="subtitle mx-auto">Selecciona tu plan</div>

              <div className={`row g-0`}>
                <div className="col-lg-6">
                  <div className="listPlans" ref={listaPlanes}>
                    {listPlans
                      ? listPlans.map((el, id) => {
                          const cantidad_clases = el[0];
                          return (
                            <div key={id} className="creditoPlan00">
                              <label
                                key={id}
                                className="creditoPlan"
                                onClick={() => selectPlan(id)}
                              >
                                <input
                                  type="radio"
                                  value={id}
                                  name="plan"
                                  defaultChecked={
                                    id == selectedPlan ? true : false
                                  }
                                />
                                <i></i>
                                <span className="checkmark"></span>
                                <span
                                  className="name text-left"
                                  style={{ color: "#9DACBD" }}
                                >
                                  <span>
                                    {cantidad_clases} crédito
                                    {cantidad_clases > 1
                                      ? "s" + " hasta "
                                      : "" + " hasta "}
                                    {cantidad_clases} clase
                                    {cantidad_clases > 1 ? "s " : " "}
                                  </span>
                                  de entrenamiento
                                </span>
                              </label>
                              <br />
                            </div>
                          );
                        })
                      : null}
                    <div>{description()}</div>
                  </div>

                  <div className=" pt-2">
                    <div>
                      <div className=" borderlineiconoscreditos mb-3 mx-auto "></div>
                      <div className="btnCupon">
                        <div className="tienescodigo">¿Tienes un código?</div>
                        <div id="input_container">
                          <input
                            className="tienescodigo1"
                            type="text"
                            name="cupon"
                            placeholder="REDÍMELO AQUí"
                            ref={refCupon}
                            onChange={(e) => {
                              verifyCupon(e.target.value);
                              setCuponListo(false);
                              setTypedCupon(e.target.value);
                            }}
                            // onBlur={(e) => {
                            //   verifyCupon(e.target.value);
                            // }}
                          />
                          {/* <FontAwesomeIcon icon={faGift} id="input_img" /> */}
                        </div>

                        {/* <div className="col-6 d-flex justify-content-center">
                                        <button className="comprobarBtn">Comprobar</button>
                                    </div> */}
                      </div>
                      {isValidcupon ? (
                        infoValor()
                      ) : (
                        <div
                          style={{
                            color: "#CB3D82",
                            textAlign: "center",
                            fontWeight: "bold",
                            width: "80%",
                            margin: "1rem auto",
                          }}
                        >
                          Tu código es invalido, por favor verifícalo para poder
                          continuar.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="btnCreditos">{redimirOComprar}</div>
            </div>
            {modalInforedimir}
            {modalInfo}
            <form
              method="post"
              action={
                testPayu == 1
                  ? `https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/`
                  : `https://checkout.payulatam.com/ppp-web-gateway-payu`
              }
              ref={formPayu}
            >
              <input id="merchantId" name="merchantId" type="hidden" value="" />
              <input id="accountId" name="accountId" type="hidden" value="" />
              <input
                id="description"
                name="description"
                type="hidden"
                value=""
              />
              <input
                id="referenceCode"
                name="referenceCode"
                type="hidden"
                value=""
              />
              <input id="amount" name="amount" type="hidden" value="" />
              <input id="tax" name="tax" type="hidden" value="" />
              <input
                id="taxReturnBase"
                name="taxReturnBase"
                type="hidden"
                value=""
              />
              <input name="currency" type="hidden" value="" />
              <input name="test" type="hidden" value={testPayu} />
              <input id="signature" name="signature" type="hidden" value="" />
              <input id="buyerEmail" name="buyerEmail" type="hidden" value="" />
              <input
                name="responseUrl"
                type="hidden"
                value={
                  testPayu == 1
                    ? `https://192.168.0.6:3000/responsePayu`
                    : `https://app.elevva.com.co/responsePayu`
                }
              />
              <input
                name="confirmationUrl"
                type="hidden"
                value={`${NuevaAxiosURL}customers/payments/payu/confirmation/`}
                // instalaciones@sistecredito.com//luis contreras
              />
            </form>
          </div>
        </>
      ) : (
        <>
          <div className={`${isLoaded == false ? "" : "d-none"} my-3`}>
            <Loadingspinner customStyle="grow" size="3rem" />
          </div>

          <div
            className={`${
              isLoaded == false ? "invisible" : ""
            } ContainerCreditos`}
          >
            <div className="bievenidoainicio" style={{ marginLeft: "3vw" }}>
              <div className="d-flex">
                <div className="SubTitle8perfilcual corrgircoso">Créditos</div>
                <div className="lineainiciousuario corrgircoso1"></div>
              </div>
              <div className="title creditos" style={{ left: "0" }}>
                Selecciona tu plan
              </div>
              <div
                className="textodebajoreservas tituloreservartuclasedebajo"
                style={{ marginLeft: "4.5VW" }}
              >
                En ELEVVA eres dueño de tu tiempo, agenda tu entrenamiento en
                los días y horario de tu preferencia
              </div>
            </div>
            <div className="muestramecreditos">
              <div className="totalcrename">Tus créditos</div>
              <div className="totalcresub">
                {" "}
                {("0000" + misCreditos).slice(-3)}
              </div>
            </div>
            <div className={`row g-0`}>
              <div className="">
                <img className="imgarribacreditos" src={imgarriba} />

                <div className="d-flex  onecolumna">
                  <div className="listPlans" ref={listaPlanes}>
                    <div className="subtitlewebcreditos1 mx-auto">Planes</div>
                    <div className="subtitlewebcreditosdown mx-auto mb-3">
                      Elige el plan ideal para ti y vive una experiencia de
                      entrenamiento personalizada, por un precio increíble.
                    </div>
                    {listPlans
                      ? listPlans.map((el, id) => {
                          const cantidad_clases = el[0];

                          return (
                            <div key={id} className="creditoPlan00">
                              <label
                                key={id}
                                className="creditoPlan"
                                onClick={() =>
                                  selectPlan(id) ||
                                  setsesionsclass(cantidad_clases)
                                }
                              >
                                <input
                                  type="radio"
                                  value={id}
                                  name="plan"
                                  defaultChecked={
                                    id == selectedPlan ? true : false
                                  }
                                />
                                <i></i>
                                <span className="checkmark"></span>
                                <span
                                  className="name text-left"
                                  style={{ color: "#9DACBD" }}
                                >
                                  <span>
                                    {cantidad_clases} crédito
                                    {cantidad_clases > 1
                                      ? "s" + " hasta "
                                      : "" + " hasta "}
                                    {cantidad_clases} clase
                                    {cantidad_clases > 1 ? "s " : " "}
                                  </span>
                                  de entrenamiento
                                </span>
                              </label>
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <div className="columna2creditos">
                    <div className="subtitlewebcreditos mx-auto">
                      {" "}
                      <span>
                        {sesionsclass}
                        {sesionsclass > 1 ? " sesiones" : " sesión"}{" "}
                      </span>
                      de entrenamiento
                    </div>
                    <div className="subtitlewebcreditosdowndelmediao mx-auto">
                      El plan de {sesionsclass} clase
                      {sesionsclass > 1 ? "s" : ""} está compuesto por un
                      crédito que le permitirá al usuario tomar una clase en el
                      horario que desee y la clase de su preferencia.
                    </div>
                    <div className="  threcolumna subtitlewebcreditoesteplan  mx-auto">
                      Este plan no tiene renovación automática y tiene una
                      vigencia de 30 días a partir de la fecha que haga la
                      compra.
                    </div>
                    <div className="  descuentobtn0 mx-auto">
                      <div className="btnCuponmitad ">
                        {/* <div className="tienescodigo">¿Tienes un código?</div> */}
                        <div id="input_container">
                          <input
                            className="tienescodigomitad d-none"
                            type="text"
                            name="cupon"
                            placeholder="REDÍMELO AQUí"
                            ref={refCupon}
                            onChange={(e) => {
                              setCuponListo(false);
                              setTypedCupon(e.target.value);
                            }}
                            onBlur={(e) => {
                              verifyCupon(e.target.value);
                            }}
                          />
                        </div>
                        {isValidcupon ? (
                          infoValormitad()
                        ) : typedCupon ? (
                          <div
                            style={{
                              color: "#CB3D82",
                              textAlign: "left",
                              fontWeight: "bold",
                              width: "30vw",

                              top: "85%",

                              position: "absolute",
                              margin: "3vh auto",
                            }}
                          >
                            Tu código es invalido, por favor verifícalo para
                            poder continuar.
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {modalInfo}
                    <div className="btnCreditosmit">{comprarplan}</div>
                  </div>
                  <div className="twocolumna">
                    <div className=" pt-2">
                      <div>
                        <div className="  descuentobtn01">
                          <div className="btnCuponmitad0">
                            <div className="subtitlewebcreditos1 mx-auto">
                              Redime un código regalo
                            </div>
                            <div className="subtitlewebcreditosdown mx-auto">
                              ¿Tienes un código de regalo ELEVVA?,
                            </div>
                            <div className="subtitlewebcreditosdown mx-auto">
                              ingrésalo y valida tu código de regalo.
                            </div>
                            <div id="input_containerregalo">
                              <div className="subtitlewebcreditosdown3 mx-auto">
                                Código
                              </div>
                              <input
                                className="tienescodigomitad"
                                type="text"
                                name="cupon"
                                placeholder="REDÍMELO AQUí"
                                ref={refCupon}
                                onChange={(e) => {
                                  verifyCupon(e.target.value);
                                  setCuponListo(false);
                                  setTypedCupon(e.target.value);
                                }}
                              />
                            </div>
                            <div className="btnCreditostercer">{redimir}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="imgabajocreditos" src={imgabajo} />
            </div>
          </div>
          {modalInforedimir}
          {modalInfo}
          <form
            method="post"
            action={
              testPayu == 1
                ? `https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/`
                : `https://checkout.payulatam.com/ppp-web-gateway-payu`
            }
            ref={formPayu}
          >
            <input id="merchantId" name="merchantId" type="hidden" value="" />
            <input id="accountId" name="accountId" type="hidden" value="" />
            <input id="description" name="description" type="hidden" value="" />
            <input
              id="referenceCode"
              name="referenceCode"
              type="hidden"
              value=""
            />
            <input id="amount" name="amount" type="hidden" value="" />
            <input id="tax" name="tax" type="hidden" value="" />
            <input
              id="taxReturnBase"
              name="taxReturnBase"
              type="hidden"
              value=""
            />
            <input name="currency" type="hidden" value="" />
            <input name="test" type="hidden" value={testPayu} />
            <input id="signature" name="signature" type="hidden" value="" />
            <input id="buyerEmail" name="buyerEmail" type="hidden" value="" />
            <input
              name="responseUrl"
              type="hidden"
              value={
                testPayu == 1
                  ? `https://192.168.0.6:3000/responsePayu`
                  : `https://app.elevva.com.co/responsePayu`
              }
            />
            <input
              name="confirmationUrl"
              type="hidden"
              value={`${NuevaAxiosURL}customers/payments/payu/confirmation/`}
            />
          </form>
        </>
      )}
    </>
  );
};

export default Creditos;
