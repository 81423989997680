import { INFO_CUSTOMER } from "../types";

const initialState = {
  dataCustomer: [],
};

export default function action_info_customer(state = initialState, action) {
  switch (action.type) {
    case INFO_CUSTOMER: {
      return {
        ...state,
        dataCustomer: action.payload,
      };
    }

    default:
      return state;
  }
}
