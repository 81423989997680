import { ModeloResults } from "../Hr/modeloResults";
import icon1 from "../assets/img/reservasimages/icon1.png";
import icon2 from "../assets/img/reservasimages/icon2.png";
import icon3 from "../assets/img/reservasimages/icon3.png";
import icon4 from "../assets/img/reservasimages/alimentate.png";
import { laptop575 } from "../utils/utils";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { Videos_two } from "../Ejercicios/othervideos";
const ResumenCompanies = (props) => {
  const { idclase } = useParams();
  const user = props.user;
  const [modalInfo, setModalInfo] = useState(null);
  const allcomidas = [
    ["Ensaladas verdes", process.env.PUBLIC_URL + "/img/videocomidas1.png"],
    ["Como preparar pimientos", process.env.PUBLIC_URL + "/img/pimientos.png"],
    ["Ensaladas con lacteos", process.env.PUBLIC_URL + "/img/lacteos.png"],
    ["No dejes de comer", process.env.PUBLIC_URL + "/img/nodejescomer.png"],
    ["Ensaladas verdes", process.env.PUBLIC_URL + "/img/videocomidas1.png"],
    ["Ensaladas con lacteos", process.env.PUBLIC_URL + "/img/pimientos.png"],
    ["No dejes de comer", process.env.PUBLIC_URL + "/img/lacteos.png"],
    ["Ensaladas verdes", process.env.PUBLIC_URL + "/img/videocomidas1.png"],
    ["Ensaladas con lacteos", process.env.PUBLIC_URL + "/img/pimientos.png"],

    ["Como preparar pimientos", process.env.PUBLIC_URL + "/img/lacteos.png"],
    [
      "Ensaladas con lacteos",
      process.env.PUBLIC_URL + "/img/videocomidas1.png",
    ],
  ];
  return laptop575.matches ? (
    <div className="resumenContainer" style={{ height: "100vh" }}>
      <div
        className="bannervideos d-flex"
        style={{
          background: "rgb(241, 246, 249)",
          position: "sticky",
          top: "0",
          zIndex: "3",
          minheight: "10vmin",
          left: "0",
          flexDirection: "column",
        }}
      >
        <div
          className="py-2 mx-3 banner_one "
          style={{
            background: "rgb(241, 246, 249)",
            minHeight: "65px",
            color: "#394F65",
          }}
        >
          <Link
            className="form searchEjercicio  py-2 d-flex"
            style={{ justifyContent: "flex-end", marginRight: "5%" }}
            to="/ejercicios/Buscar"
          >
            <input
              placeholder="Buscar..."
              type="search"
              className="input_searchvideos ejercicios_c  py-2 "
              // onChange={(e) => changeOptionVideos(e.target.value)}
            ></input>
          </Link>
          <div
            className="d-flex position-absolute"
            style={{
              left: "45%",
              top: "30%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <img
              style={{ width: "200px" }}
              src={process.env.PUBLIC_URL + "/img/logosEmpresas.png"}
            />{" "}
          </div>
        </div>
      </div>
      <div className="maxreservarResumen" style={{ marginLeft: "5%" }}>
        <div className="superiorMaxResumen  ">
          <div className="SubTitle8resumen">Resumen de tu sesión</div>
          <div className="lineareservatuclaseonlyresumen"></div>
        </div>
      </div>

      <ModeloResults
        companie={process.env.PUBLIC_URL + "/img/mediolo.png"}
        idclase={idclase}
        age={user.customer.age}
        url={`booking/book/${idclase}/workoutlog/`}
        url2={`booking/book/${idclase}/workoutlog/`}
      />

      <div className="titleresumensesionCompanie " style={{ color: "#394F65" }}>
        Recomendaciones
      </div>

      <div className="sliderResumen">
        <div className="recom">
          <div>
            <div className="recomendacionesCompanie" style={{ margin: "0 5%" }}>
              <div className="content">
                <div className="my-3 mx-0">
                  <span>
                    Somos compañeros en tu camino hacia un estilo de vida
                    saludable y sabemos cuán importante es generar hábitos para
                    optimizar tu entrenamiento y resultados ¡Felicidades
                    Elevva+!
                  </span>
                </div>
                <div className="d-flex">
                  <div className="itemRecom">
                    <img src={icon1} alt="Icon" />
                    <span>
                      <b>CREA UNA COSTUMBRE</b>: Haz del ejercicio una costumbre
                      cotidiana y reserva tus clases con antelación.{" "}
                    </span>
                  </div>
                  <br />
                  <div className="itemRecom">
                    <img src={icon2} alt="Icon" />
                    <span>
                      <b>CAMBIA TU ROPA:</b> La ropa que usaste durante tu
                      entrenamiento atrapa humedad que aumenta las posibilidades
                      del crecimiento de bacterias, hongos y gérmenes, lo que
                      conlleva a la aparición de granitos.{" "}
                    </span>
                  </div>

                  <div className="itemRecom">
                    <img src={icon3} alt="Icon" />
                    <span>
                      <b>TOMA UNA DUCHA DE AGUA FRIA Y DESPUES CALIENTE</b>: Los
                      baños de contraste (alternando agua fría y caliente) ayuda
                      a recuperarse más rápidamente, reduce el dolor muscular y
                      previene lesiones.{" "}
                    </span>
                  </div>

                  <div className="itemRecom">
                    <img src={icon4} alt="Icon" />
                    <span>
                      <b>ALIMENTATE</b>: Después de entrenar, es necesario que
                      recuperes energía a través de una buena alimentación, que
                      también te ayudará a reparar tejidos y recuperar fuerzas
                      para tu próximo reto.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div
        style={{
          backgroundColor: "#FDF1E8",
          paddingLeft: "5%",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <Videos_two
          textTitleOne={"Siguientes"}
          textTitleTwo="Programas"
          videosTime={allcomidas}
          id="pindos"
        />
        <br />
        <br />
        <br />
      </div>
      {modalInfo}
    </div>
  ) : (
    <></>
  );
};
export default ResumenCompanies;
