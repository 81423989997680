import AgoraRTC from "agora-rtc-sdk-ng";
import { useEffect, useRef, useState } from "react";

import Moment from "moment";
import "moment/locale/es";
import { extendMoment } from "moment-range";

import {
  ContenedorClase,
  Panel,
  fancyTimeFormat,
  SetModaldespedida,
  connectPeerToPeer,
  SetModalError,
  getFirstIdx,
  entrenadorType,
  instance,
  disconnectPeer,
  timeOutcheckVideo,
  timeOutcheckAudio,
  basename,
  initSocketConnection,
  booking_started_emit,
  change_exercise_emit,
  booking_finish_trainer_emit,
  ModalInfo,
  workoutlog_data_emit,
  sync_clock,

} from "../../utils/utils";

import fondo from "../../assets/img/tipos_ejercicios.png";
import banda_connect_img from "../assets/icons/BANDA CONECTADA.svg";
import banda_disconnect_img from "../assets/icons/BANDA NO CONECTADA.svg";
import Media from "react-media";
import orientation from "../../assets/img/reservasimages/orientation.jpg";
import { ComprobarClase } from "../../Clase/Clase";
import cerrar from "../../assets/img/reservasimages/Grupo 6107.png";
import corazon from "../../assets/img/reservasimages/corazon.png";
import rutinaavatar from "../../assets/img/reservasimages/rutinaavatar.png";

import customAvatar from "../../assets/img/reservasimages/customAvatar.png";
import logo from "../../assets/img/reservasimages/Logo eleva_giro.png";
import star from "../../Perfil/iconos/star-unfill.png";
import starfill from "../../Perfil/iconos/star-fill.png";
import "./EntrenadorClase.css";

import { useHistory, useParams } from "react-router";

import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Lesiones } from "../../Perfil/Perfil";

const EntrenadorClase = (props) => {


  AgoraRTC.setLogLevel(3);
  const [isChecked, setChecked] = useState(null);
  const user = props.user;
  const history = useHistory();

  useEffect(() => {
    if (!window.location.pathname.includes`Entrenador/clase/`) {
      document.getElementById("navbarNavDropdown").style =
        "display:block !important";
    } else {
      document.getElementById("navbarNavDropdown").style = "display:none ";
    }
    const checked = localStorage.checked;

    if (checked) {
    
      const now = Date.now();
      const diff = now - checked;
      if (diff <= 1800000) {
        // Less than 30 min, so no check again
        setChecked(true);
      } else {
        setChecked(false);
      }
    } else setChecked(false);
  }, []);

  return (
    <>
      <Media
        query="(max-width: 1000px)"
        render={() => (
          <div
            className="claseFondo"
            style={{ backgroundImage: `url(${orientation})` }}
          >
            <div className="container  px-5">
              <div className="info mx-auto">
                <div>
                  Para poder dictar la clase correctamente necesitas estar en
                  una pantalla mas grande
                </div>
                <br />
                <br />
                <br />
                <div>
                  Por favor, dicta la clase en un computador portatil o de
                  escritorio.
                </div>
              </div>
              <div
                className="position-absolute fw-bold"
                style={{ color: "white", top: "5%" }}
              >
                <div
                  onClick={() => history.goBack()}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} color="white" /> Volver
                </div>
              </div>
            </div>
          </div>
        )}
      />
      <Media
        query="(min-width: 1001px)"
        render={() => (
          <>
            <div
              className="containerBGClase"
              style={{ backgroundImage: `url(${fondo})` }}
            ></div>
            {isChecked === true ? <ComenzarClase user={user} /> : null}
            {isChecked === false ? (
              <ComprobarClase
                onChecked={(inf) => setChecked(inf)}
                isChecked={isChecked}
              />
            ) : null}
          </>
        )}
      />
    </>
  );
};

const ComenzarClase = (props) => {
  const user = props.user;

  const { reserva, idclase } = useParams();

  const myPanelTab = useRef(null);
  const myPanel = useRef(null);
  const miEntrenamiento = useRef(null);
  const miEntrenamientoTab = useRef(null);
  const miEntrenamientototal = useRef(null);
  const miEntrenamientoTabtotal = useRef(null);
  const refRutinas = useRef(null);
  const [frecuenciaCustomer, setfrecuenciaCustomer] = useState(null);
  const iniciarRut = useRef(null);
  const nexRut = useRef(null);
  const actualTime = useRef(0);
  const actualRutinaTime = useRef(0);
  const actualCountDown = useRef(null);

  const [rutina, setRutina] = useState(null);
  const [actualMetodologia, setActualMetodologia] = useState(null);
  const [timeLeft, setTimeLeft] = useState(fancyTimeFormat(0));
  const [modalInfo, setModalInfo] = useState(null);
  const [infoRutinas, setInfoRutinas] = useState(null);
  const [nextRutina, setNextRutina] = useState("Siguiente ciclo");
  const [nombreRutinaActual, setNombreRutinaActual] = useState(null);
  const [SeriesRutina, setSeriesRutina] = useState(null);
  const [pitoCircuito, setpitoCircuito] = useState(false);

  const [tiempoRutinaActual, setTiempoRutinaActual] = useState(0);

  const [comentario, setComentario] = useState("");

  const [nombreCliente, setNombreCliente] = useState("Jhon Doe");
  const [Genero, setGenero] = useState(null);
  const [edad, setEdadCliente] = useState(0);
  const [loas, setloas] = useState(false);
  const [melo8, setmelo8] = useState(false);
  const [HrData, setHrData] = useState({heart_rate:0,kcal:0});
  const [nivelStr, setNivelCliente] = useState(null);
  const [estrellas, setEstrellas] = useState(1);
  const [have_valoration, sethave_valoration] = useState(false);
  const [nivelporentrenador, setnivelporentrenador] = useState(null);
  const [nivelporentrenadorcito, setnivelporentrenadorcito] = useState(false);
  const [lesiones, setLesiones] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [Actualidad, setActualidad] = useState(null);
  const [Respregunta1, SetRespregunta1] = useState(null);
  const [Respregunta2, SetRespregunta2] = useState(null);
  const [Respregunta3, SetRespregunta3] = useState(null);
  const [Respregunta4, SetRespregunta4] = useState(null);
  const [Respregunta5, SetRespregunta5] = useState(null);
  const [Respregunta6, SetRespregunta6] = useState(null);
  const [Respregunta7, SetRespregunta7] = useState(null);
  const [Respregunta8, SetRespregunta8] = useState(null);
  const [bandConnected, setbandConnected] = useState(localStorage.elevvaBandConected ?JSON.parse(localStorage.elevvaBandConected):false);
  const [hourSync, sethourSync] = useState(null);
  const [objReserva, setReserva] = useState(null);
  const [StartClock, setStartClock] = useState(false);
  const [closeDoor, setCloseDoor] = useState(false);
  var maxl0 = 220 - edad;
  const maximo = maxl0;
  const intenso = (maximo * 90) / 100;
  const moderado = (maximo * 80) / 100;
  const luz = (maximo * 70) / 100;
  const muy_suave = (maximo * 60) / 100;

  useEffect(() => {
    async function trackingworkoutlog() {
      initSocketConnection(
        (client) => {
          console.log("estatus connected client", client);
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (change) => {
          console.log("booking log data", change);
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (workoutlog) => {
          if (workoutlog && workoutlog != undefined) {
            setfrecuenciaCustomer(workoutlog);
            localStorage.setItem("lastWorkoutlog", JSON.stringify(workoutlog));
          }

          // Puedes agregar lógica adicional aquí si es necesario
        },
        (band) => {
          console.log(band);
          setbandConnected(true);

          setModalInfo(
            <ModalInfo
              error="false"
              handleClick={() => setModalInfo(null)}
              title="Hola entrenador."
              text="La banda del atleta se ha conectado"
              classesMsg=""
            />
          );
          // console.log('se conecto la banda');
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (bandDisconnected) => {
          console.log(bandDisconnected);
          setbandConnected(false);
          setModalInfo(
            <SetModalError
              handleClick={() => setModalInfo(null)}
              title="La Banda HR se ha desconectado"
              msgError="El atleta intentará conectarse de nuevo"
            />
          );

          console.log("se desconecto la banda");
          // Puedes agregar lógica adicional aquí si es necesario
        },
        (hrData) => {
          setHrData(hrData);
        },
        (lock) => {
          if (lock) {
            sethourSync(moment(lock * 1000));
            setStartClock(true);
          }
        },
        (reasonDisconnect) => {
          if (reasonDisconnect) {
            console.log("se desconecto", reasonDisconnect);
          }
        },(whoConected) => {
          if (whoConected) {
            console.log(whoConected);
    
 
          }
        },
      );
     if(localStorage.lastWorkoutlog){
      setfrecuenciaCustomer(JSON.parse(localStorage.getItem("lastWorkoutlog")))
     }
    }
    trackingworkoutlog();
  }, []);

  function postear_FINISH() {
    const formData = new FormData();
    formData.append("trainer_comment", comentario);
    formData.append("trainer_evaluation", nivelporentrenador[1]);
  

    instance
      .post(`/booking/book/finish/${reserva}`, formData)
      .then((resEn) => {
        const data = resEn.data;
        if (data) {
          booking_finish_trainer_emit({booking_id:reserva,trainer_id: localStorage.uuid});
          setTimeout(() => {
            window.location.href = `${basename}/${entrenadorType}/inicio`;
          }, 1000);
        }
      })
      .catch((err) => {
        setModalInfo(<SetModalError handleClick={() => setModalInfo(null)} />);
      });
  }
  function funcionConRetraso() {
    setModalInfo(
      <SetModaldespedida
        title="Hola entrenador"
        msg="Te quedan 5 minutos de clase,recuerda que debes ir terminando tu entrenamiento"
        handleClick={() => setModalInfo(null)}
      />
    );
  }

  function lopez() {
    if (nivelporentrenadorcito == true) finalizarEntrenamiento();
  }
  const moment = extendMoment(Moment);

  useEffect(() => {
    if (melo8 == true) ochoquestions();
  }, [melo8]);
  useEffect(() => {
    lopez();
  }, [nivelporentrenador]);
  useEffect(() => {
    if (loas == true) {
      postear_FINISH();
    }
  }, [loas]);
  useEffect(() => {

  
    
        if(StartClock){
          const fecha = objReserva.date.replace("T", " ").slice(0, 16);
           const fechaclar = moment(fecha).format("dddd DD [de] MMMM / HH:mm a");
           // console.log(fechaclar); // Lunes 29 de marzo / 11:00 am
           const timeStart =hourSync;
           const timeEnd = moment(fechaclar, "dddd DD [de] MMMM / HH:mm a");
         
           timeEnd.add(1, "hour");
         
           const diff = timeEnd.diff(timeStart, "seconds");
           if (diff < -500) {
             localStorage.removeItem("actualRutinaTime");
             // localStorage.removeItem("actualIndex");
             localStorage.removeItem("actualEjercicio");
           }
           actualTime.current = diff;
        
           setInterval(() => {
             if (actualTime.current > 0) {
               actualTime.current -= 1;
            
               setTimeLeft(fancyTimeFormat(actualTime.current));
               localStorage.timestampClass = 3600 - actualTime.current;
               // console.log(actualTime.current);
               if (actualTime.current == 300) {
                 funcionConRetraso();
               }
             }
           }, 1000);
          }
      
  }, [StartClock]);

  useEffect(() => {
    setTimeout(() => {
      const objcStart = {
        id_clase: idclase,
        uid: user.trainer.user,
        reserva: reserva,
        type: 20,
        agoraToken: localStorage.agoraToken,
      };
      connectPeerToPeer(objcStart); // Iniciar la clase con el obj.id_clase
    }, 1000);

    instance
      .get(`/booking/book/prepare/${reserva}`)
      .then((res) => {
        const info = res.data;
        const idx = getFirstIdx(info.session.routine);
        setAvatar("https://api.elevva.com.co" + info.customer.profile_pic);
        setGenero(info.customer.gender);
        setNombreCliente(info.customer.full_name);
        setNivelCliente(info.customer.initial_level);
        setEdadCliente(info.customer.age);
        setEstrellas(info.customer.level / 10);
        sethave_valoration(info.training.is_valoration);
        setInfoRutinas(info.session.routine);
        setActualMetodologia(idx);
        setReserva(info);

      function ejecutarEnSecuencia(){
        sync_clock();
      

       }
      
     
      ejecutarEnSecuencia();
        ;
       
        localStorage.room_id = info.room_id;
        const openDiv = document.querySelector(".PanelTab.right");
        openDiv.click();
        setLesiones(
          <Lesiones
            lesionesdesdetrainer={info.customer_injuries}
            infoLesiones={info.customer_injuries}
            position="absolute"
            isProfile={false}
            forceMediaMatch={false}
          />
        );

   
      })
      .catch((err) => {
        setModalInfo(<SetModalError handleClick={() => setModalInfo(null)} />);
       
      });

    return () => {
      clearInterval(timeOutcheckVideo);
      clearInterval(timeOutcheckAudio);
      disconnectPeer();
    };
  }, []);


  useEffect(() => {
    if (infoRutinas != null) {
      iniciarEntrenamiento();
      if (localStorage.actualIndex && localStorage.actualIndex < 1)
        showNextBtn();
    }
    return () => {};
  }, [infoRutinas]);
  useEffect(() => {
    // play(); ------------pitos
    setpitoCircuito(false);
  }, [pitoCircuito]);
  useEffect(() => {
    
   if(localStorage.actualIndex  ) {

     modifyRutinaColor(localStorage.actualIndex);
    
    }
  }, [infoRutinas]);

  useEffect(() => {
    
    modifyRutinaColor(localStorage.actualIndex);
  }, [Actualidad]);

  useEffect(() => {
   
    if (infoRutinas && frecuenciaCustomer &&frecuenciaCustomer.methodology) {
    
 workoutlog_data_emit(JSON.stringify({
        booking_id : Number(reserva),
      percentage : localStorage.percentageClass,
      kcal : HrData.kcal,
      duration_timestamp : localStorage.timestampClass,
      heart_rate :HrData.heart_rate,
      methodology : localStorage.MethodologyClass,
      methodology_series:localStorage.seriesClass,
      workoutset :localStorage.workoutSetClass,
      trainer_change:false
      }));

    }
  }, [timeLeft]);


  useEffect(() => {
   
    if (infoRutinas&& frecuenciaCustomer &&frecuenciaCustomer.methodology) {
     

      localStorage.actualEjercicio = infoRutinas[
        infoRutinas.indexOf(
          infoRutinas.filter((e) => e.id == frecuenciaCustomer.methodology)[0]
        )
      ].set.indexOf(
        infoRutinas[
          infoRutinas.indexOf(
            infoRutinas.filter((e) => e.id == frecuenciaCustomer.methodology)[0]
          )
        ].set.filter((e) => e.id == frecuenciaCustomer.workoutset)[0]
      );

      setActualidad(frecuenciaCustomer.workoutset);
    }
  }, [frecuenciaCustomer]);

  function modifyRutinaColor(idx) {
    if (!infoRutinas) return;
    const pos = idx;
    const pos2 = localStorage.actualEjercicio;

    for (let index0 = 0; index0 <= pos; index0++) {
      const rut3 = document.querySelector("#indicador-" + index0);
      for (let index1 = 0; index1 <= pos; index1++) {
        if (rut3) {
          let ciclo = rut3.querySelector("#ciclo-" + index1);
          if (ciclo) {
            ciclo.style = "opacity:1";
            let wao;
            if (index1 == pos) {
              wao = pos2;
            } else {
              wao = infoRutinas[index1].set.length;
            }
            for (let index = 0; index <= wao; index++) {
              let ejercicio = rut3.querySelector("#ejercicio-" + index);

              if (ejercicio) {
                ejercicio.style = "opacity:1";
              }
            }
          }
        }
      }
    }
  }

  function renderMetodologias(rutinas) {
    setNombreRutinaActual(infoRutinas[rutinas]["name"]);
    setSeriesRutina(infoRutinas[rutinas]["series"]);
  
    const timeStored = localStorage.actualRutinaTime;
    if (timeStored ) {
      actualRutinaTime.current = timeStored;
      setTiempoRutinaActual(timeStored);
      runExercises(infoRutinas,localStorage.actualIndex,true);
    } else {
      setTiempoRutinaActual(infoRutinas[rutinas]["time"]);
      actualRutinaTime.current = infoRutinas[rutinas]["time"];
     }
    let resplandorButton =  nexRut.current.querySelector("button");
    if(resplandorButton.classList.contains("resplandorButton")){

      resplandorButton.classList.remove("resplandorButton");
    }
    // nexRut.current.style.opacity = "0.5";

    countDownRutina();
    const LasRutinas = infoRutinas[rutinas]["set"];
    const buffer = [];
    for (const eName in LasRutinas) {
      if (Object.hasOwnProperty.call(LasRutinas, eName)) {
        const time = LasRutinas[eName]["time"];
        const repEjercicio = LasRutinas[eName]["repetitions"];
        const ejercicio = LasRutinas[eName]["exercise"].toLowerCase();
        const min = time / 60;
        buffer.push(
          <div className="name text-capitalize" key={eName}>
            {ejercicio}
            {repEjercicio > 0 ? (
              <div className="rep">Repeticiones: {repEjercicio}</div>
            ) : null}
            <div className="time" style={{ textTransform: "lowercase" }}>
              Tiempo {time < 60 ? time + " secs" : min + " Min"}
            </div>
          </div>
        );
      }
    }
    return buffer;
  }

  function iniciamodify() {

 
    booking_started_emit({booking_id:reserva})
 
  }


  function countDownRutina() {
    
    actualCountDown.current = setTimeout(() => {
      console.log(actualRutinaTime.current)
      if (actualRutinaTime.current > 0 ) {
        console.log('donde entra 1')
        actualRutinaTime.current -= 1;
        localStorage.actualRutinaTime = actualRutinaTime.current;
        setTiempoRutinaActual(actualRutinaTime.current);
        countDownRutina();
      } else {
        console.log('donde entra 2')
        // Can click on next button
        actualCountDown.current = null;

        if (nexRut.current) {
          let resplandorButton =  nexRut.current.querySelector("button");
          resplandorButton.classList.add("resplandorButton");
          // nexRut.current.style.opacity = "1";
        }

        localStorage.actualRutinaTime = 0;
      }
    }, 1000);
  }

  function iniciarEntrenamiento(first_time = false) {
    if (first_time === true) {
      // play3(); -----pito start

      workoutlog_data_emit(JSON.stringify({
        booking_id : Number(reserva),
      percentage :0,
      kcal : 0,
      duration_timestamp :0,
      heart_rate :0,
      methodology : infoRutinas[0].id,
      methodology_series: infoRutinas[0].series,
      workoutset :infoRutinas[0].set[0].id,
      trainer_change:false
      }));

      actualCountDown.current = localStorage.actualRutinaTime =
      infoRutinas[0]["time"] * infoRutinas[0]["series"];
     localStorage.MethodologyClass =infoRutinas[0].id;
     runExercises(infoRutinas,0,false);
      localStorage.actualIndex = getFirstIdx(infoRutinas);
    }
    let metodologiaActual = actualMetodologia;
    const storeActualIndex = localStorage.actualIndex;
    if (storeActualIndex) {
      metodologiaActual = storeActualIndex;

      setActualMetodologia(metodologiaActual);
      setRutina(renderMetodologias(metodologiaActual));

      showNextBtn();
    } else {
      localStorage.removeItem("actualRutinaTime");
    }
  }

  function showNextBtn() {
    iniciarRut.current.classList.add("d-none");
    nexRut.current.classList.remove("d-none");
  }

  function siguienteRutina() {

    if (frecuenciaCustomer) {
    
      const nextIndexrutina = getNextFrom(
        infoRutinas,
        actualMetodologia,
        frecuenciaCustomer
      );
        if (!nextIndexrutina) {
          // no more, so finish
          finalizarEntrenamiento();
        } else {

          // modificarPos(actualMetodologia, frecuenciaCustomer);
          localStorage.actualIndex = nextIndexrutina;
          console.log(actualCountDown);
          clearTimeout(actualCountDown.current);
         
          actualCountDown.current=localStorage.actualRutinaTime =
            infoRutinas[nextIndexrutina]["time"] * infoRutinas[nextIndexrutina]["series"];
          setRutina(renderMetodologias(nextIndexrutina));
          setActualMetodologia(nextIndexrutina); // el siguiente es ahora el actual
          const thefinal = getNextFrom(
            infoRutinas,
            nextIndexrutina,
            frecuenciaCustomer
          );

          if (!thefinal || thefinal == undefined) {
            setNextRutina("Finalizar entrenamiento");
          }
runExercises(infoRutinas,nextIndexrutina,true);

        }
      // }
    }
  }

//   async function next(
//     x,
//     y,
//     z,
//     percentage,
//     incrementometo,
//     contador
//   ) {
//     let laquees = gettrue(
   
//       x,
//       y,
//       z,
//       percentage,
//       incrementometo,
//       contador
//     );
 
//     if (laquees.length > 0) {
// setTimeout(() => {
//     workoutlog_data_emit({
//       booking_id : reserva,
//     percentage :Number(laquees[3]) > 87 ? Number(laquees[3]).toFixed() : Number(laquees[3]),
//     kcal : -1,
//     duration_timestamp :0,
//     heart_rate :-1,
//     methodology : laquees[1],
//     methodology_series:laquees[0],
//     workoutset :laquees[2]
//     });
//     }, laquees[4] * 1000);
    
//     } else {
//       return false;
//     }
//   }

  function runExercises(infoRutinas,m,bool){
    
    if(bool == true){
  
      change_exercise_emit({ methodology:infoRutinas[m].id});
    }
    
    const cuantasmetodologias = 100 / infoRutinas.length;
    const avanceMetodology=cuantasmetodologias * (localStorage.actualIndex || 0)
    const division = cuantasmetodologias / infoRutinas[m].set.length;
   
    var inicio =0;
    
      function ejecutarConRetraso(i,time) {
   
        return new Promise((resolve) => {
          setTimeout(() => {
           
            resolve();
          }, time * 1000);
        });
      }
      
      async function recorrerConEspera(avanceMetodology,division) {
       
        let seriesTotal =infoRutinas[m].series; 
        ;
        let inicioSeries =1;
        let contador =0;

        if (
          infoRutinas[m].type == 60 || infoRutinas[m].type == 40
        ) {
          while (contador < infoRutinas[m].time) {
            for (
              let i = inicio;
              i < infoRutinas[m].set.length;
              i++
            ) {
              if (infoRutinas[m].type == 40) {
                contador = contador - -60;
              } else if (infoRutinas[m].type == 60) {
                contador = contador - -30;
              } else {
                contador =
                  contador - -infoRutinas[m].set[i].time;
              }  
              localStorage.percentageClass =  (avanceMetodology + (division * i));
              localStorage.workoutSetClass = infoRutinas[m].set[i].id;
              localStorage.seriesClass = inicioSeries;
              localStorage.MethodologyClass = infoRutinas[m].id;
              await ejecutarConRetraso(i,contador);
            }
          }
        }
        else{

          while (inicioSeries <= seriesTotal) {
            for (let i = inicio; i < infoRutinas[m].set.length; i++) {
              localStorage.percentageClass =  (avanceMetodology + (division * i));
              localStorage.workoutSetClass = infoRutinas[m].set[i].id;
              localStorage.seriesClass = inicioSeries;
              localStorage.MethodologyClass = infoRutinas[m].id;
              await ejecutarConRetraso(i,infoRutinas[m].set[i].time);
            }
            localStorage.seriesClass =inicioSeries
            inicioSeries =inicioSeries + 1;
          }
        }

       
      }
      recorrerConEspera(avanceMetodology,division);
    
          
 
  }

  function finalizarEntrenamiento() {
    setCloseDoor(true);
    setTimeout(() => {
    setCloseDoor(false);
    },1000);
    
    if (localStorage.actualIndex) {
      if (idclase) {
        hacerPreguntas();
      }
    } else {
      setModalInfo(
        <SetModaldespedida
          timeOut="true"
          title="Hola entrenador"
          msg="Debes iniciar la rutina para poder finalizarla, no es común finalizarla al ingresar, si tienes algun problema comunicate a nuestro chat"
          handleClick={() => setModalInfo(null)}
        />
      );
    }
  }
  // console.log("nivelporentrenador", nivelporentrenador[0]);
  const refpregunta1 = useRef(null);
  function renderStars(refStep) {
    return (
      <>
        <div className="estrellas" ref={refStep} value={0}>
          <img
            src={star}
            alt="estrella"
            className="estrella"
            id="s1"
            onClick={async () => {
              setStars(refStep, 1);
            }}
          />

          <img
            src={star}
            alt="estrella"
            className="estrella"
            id="s2"
            onClick={async () => {
              setStars(refStep, 2);
            }}
          />
          <img
            src={star}
            alt="estrella"
            className="estrella"
            id="s3"
            onClick={async () => {
              setStars(refStep, 3);
            }}
          />
        </div>
        <div> {nivelporentrenador ? nivelporentrenador[0] : null}</div>
      </>
    );
  }
  function setStars(theRef, pos) {
    theRef.current.setAttribute("value", pos);
    if (pos == 1) {
      setnivelporentrenador(["Fácil", 10]);
      setnivelporentrenadorcito(true);
    } else if (pos == 2) {
      setnivelporentrenador(["Adecuado", 20]);
      setnivelporentrenadorcito(true);
    } else {
      setnivelporentrenador(["Dificil", 30]);
      setnivelporentrenadorcito(true);
    }
    for (let index = 1; index <= 3; index++) {
      const estrella = theRef.current.querySelector("#s" + index);
      if (pos >= index) {
        estrella.src = starfill;
      } else estrella.src = star;
    }
  }
  const changeOption = (event, question) => {
    const Respregunta = event.target.value;
    // console.log(Respregunta, question);
    if (question == 1) {
      let pregunta1;
      if (Genero == 1) {
        if (Respregunta < 60) pregunta1 = 10;
        else if (Respregunta > 59 && Respregunta < 121) pregunta1 = 20;
        else pregunta1 = 30;
      } else {
        if (Respregunta < 30) pregunta1 = 10;
        else if (Respregunta > 29 && Respregunta < 71) pregunta1 = 20;
        else pregunta1 = 30;
      }
      SetRespregunta1(pregunta1);
    } else if (question == 2) {
      let pregunta2;
      if (Genero == 1) {
        if (Respregunta > 44 && edad < 26) pregunta2 = 30;
        else if (Respregunta > 39 && edad > 25 && edad < 36) pregunta2 = 30;
        else if (Respregunta > 34 && edad > 35 && edad < 46) pregunta2 = 30;
        else if (Respregunta > 28 && edad > 45) pregunta2 = 30;
        else if (Respregunta > 34 && Respregunta < 44 && edad < 26)
          pregunta2 = 20;
        else if (Respregunta > 30 && Respregunta < 40 && edad > 25 && edad < 36)
          pregunta2 = 20;
        else if (Respregunta > 26 && Respregunta < 35 && edad > 35 && edad < 46)
          pregunta2 = 20;
        else if (Respregunta > 21 && Respregunta < 39 && edad > 45)
          pregunta2 = 20;
        else if (Respregunta < 35 && edad < 26) pregunta2 = 10;
        else if (Respregunta < 31 && edad > 25 && edad < 36) pregunta2 = 10;
        else if (Respregunta < 27 && edad > 25 && edad < 46) pregunta2 = 10;
        else if (Respregunta < 22 && edad > 45) pregunta2 = 10;
      } else {
        if (Respregunta > 36 && edad < 26) pregunta2 = 30;
        else if (Respregunta > 32 && edad > 25 && edad < 36) pregunta2 = 30;
        else if (Respregunta > 26 && edad > 35 && edad < 46) pregunta2 = 30;
        else if (Respregunta > 21 && edad > 45) pregunta2 = 30;
        else if (Respregunta > 28 && Respregunta < 37 && edad < 26)
          pregunta2 = 20;
        else if (Respregunta > 24 && Respregunta < 33 && edad > 25 && edad < 36)
          pregunta2 = 20;
        else if (Respregunta > 18 && Respregunta < 27 && edad > 35 && edad < 46)
          pregunta2 = 20;
        else if (Respregunta > 13 && Respregunta < 22 && edad > 45)
          pregunta2 = 20;
        else if (Respregunta < 29 && edad < 26) pregunta2 = 10;
        else if (Respregunta < 25 && edad > 25 && edad < 36) pregunta2 = 10;
        else if (Respregunta < 19 && edad > 25 && edad < 46) pregunta2 = 10;
        else if (Respregunta < 14 && edad > 45) pregunta2 = 10;
      }
      SetRespregunta2(pregunta2);
    } else if (question == 3) {
      let pregunta3;
      if (Respregunta > 29) pregunta3 = 30;
      else if (Respregunta < 30 && Respregunta > 19) pregunta3 = 20;
      else if (Respregunta < 20) pregunta3 = 10;
      SetRespregunta3(pregunta3);
    } else if (question == 4) {
      let pregunta4;
      if (Genero == 1) {
        if (Respregunta > 33 && edad < 30) pregunta4 = 30;
        else if (Respregunta > 26 && Respregunta < 33 && edad > 29 && edad < 40)
          pregunta4 = 30;
        else if (Respregunta > 20 && Respregunta < 26 && edad > 39)
          pregunta4 = 30;
        else if (Respregunta > 26 && Respregunta < 31 && edad < 30)
          pregunta4 = 20;
        else if (Respregunta > 20 && Respregunta < 25 && edad > 29 && edad < 40)
          pregunta4 = 20;
        else if (Respregunta > 15 && Respregunta < 20 && edad > 39)
          pregunta4 = 20;
        else if (Respregunta > 20 && Respregunta < 25 && edad < 30)
          pregunta4 = 10;
        else if (Respregunta > 15 && Respregunta < 20 && edad > 29 && edad < 40)
          pregunta4 = 10;
        else if (Respregunta > 11 && Respregunta < 14 && edad > 39)
          pregunta4 = 10;
        else pregunta4 = 10;
      } else {
        if (Respregunta > 25 && edad < 30) pregunta4 = 30;
        else if (Respregunta > 23 && Respregunta < 32 && edad > 29 && edad < 40)
          pregunta4 = 30;
        else if (Respregunta > 21 && Respregunta < 29 && edad > 39)
          pregunta4 = 30;
        else if (Respregunta > 19 && Respregunta < 23 && edad < 30)
          pregunta4 = 20;
        else if (Respregunta > 16 && Respregunta < 22 && edad > 29 && edad < 40)
          pregunta4 = 20;
        else if (Respregunta > 13 && Respregunta < 19 && edad > 39)
          pregunta4 = 20;
        else if (Respregunta > 13 && Respregunta < 17 && edad < 30)
          pregunta4 = 10;
        else if (Respregunta > 11 && Respregunta < 15 && edad > 29 && edad < 40)
          pregunta4 = 10;
        else if (Respregunta > 9 && Respregunta < 13 && edad > 39)
          pregunta4 = 10;
        else pregunta4 = 10;
      }

      SetRespregunta4(pregunta4);
    } else if (question == 5) {
      let pregunta5;
      if (Genero == 1) {
        if (Respregunta > 40 && edad < 30) pregunta5 = 30;
        else if (Respregunta > 45 && Respregunta < 76 && edad > 29 && edad < 40)
          pregunta5 = 30;
        else if (Respregunta > 38 && edad > 39) pregunta5 = 30;
        else if (Respregunta > 23 && Respregunta < 31 && edad < 30)
          pregunta5 = 20;
        else if (Respregunta > 25 && Respregunta < 37 && edad > 29 && edad < 40)
          pregunta5 = 20;
        else if (Respregunta > 20 && Respregunta < 34 && edad > 39)
          pregunta5 = 20;
        else if (Respregunta > 4 && Respregunta < 25 && edad < 30)
          pregunta5 = 10;
        else if (Respregunta >= 0 && Respregunta < 20 && edad > 29 && edad < 40)
          pregunta5 = 10;
        else if (Respregunta >= 0 && Respregunta < 16 && edad > 39)
          pregunta5 = 10;
        else pregunta5 = 10;
      } else {
        if (Respregunta > 36 && edad < 30) pregunta5 = 30;
        else if (Respregunta > 33 && Respregunta < 56 && edad > 29 && edad < 40)
          pregunta5 = 30;
        else if (Respregunta > 27 && edad > 39) pregunta5 = 30;
        else if (Respregunta > 21 && Respregunta < 23 && edad < 30)
          pregunta5 = 20;
        else if (Respregunta > 14 && Respregunta < 29 && edad > 29 && edad < 40)
          pregunta5 = 20;
        else if (Respregunta > 10 && Respregunta < 23 && edad > 39)
          pregunta5 = 20;
        else if (Respregunta > 4 && Respregunta < 18 && edad < 30)
          pregunta5 = 10;
        else if (Respregunta >= 0 && Respregunta < 13 && edad > 29 && edad < 40)
          pregunta5 = 10;
        else if (Respregunta >= 0 && Respregunta < 8 && edad > 39)
          pregunta5 = 10;
        else pregunta5 = 10;
      }

      SetRespregunta5(pregunta5);
    } else if (question == 6) {
      let pregunta6;
      if (Genero == 1) {
        if (Respregunta < 60) pregunta6 = 10;
        else if (Respregunta > 59 && Respregunta < 121) pregunta6 = 20;
        else pregunta6 = 30;
      } else {
        if (Respregunta < 30) pregunta6 = 10;
        else if (Respregunta > 29 && Respregunta < 71) pregunta6 = 20;
        else pregunta6 = 30;
      }
      SetRespregunta6(pregunta6);
    } else if (question == 7) {
      let pregunta7;
      if (Genero == 1) {
        if (Respregunta > 102) pregunta7 = 30;
        else if (Respregunta > 57 && Respregunta < 103) pregunta7 = 20;
        else pregunta7 = 10;
      } else {
        if (Respregunta > 60) pregunta7 = 30;
        else if (Respregunta > 35 && Respregunta < 61) pregunta7 = 20;
        else pregunta7 = 10;
      }
      SetRespregunta7(pregunta7);
    } else if (question == 8) {
      let pregunta8;
      if (Genero == 1) {
        if (Respregunta > 39) pregunta8 = 30;
        if (Respregunta < 40 && Respregunta > 19) pregunta8 = 20;
        if (Respregunta < 20) pregunta8 = 10;
      } else {
        if (Respregunta > 29) pregunta8 = 30;
        if (Respregunta < 30 && Respregunta > 14) pregunta8 = 20;
        if (Respregunta < 15) pregunta8 = 10;
      }
      SetRespregunta8(pregunta8);
    }
  };
  function ochoquestions() {
    const array = [];
    array.push(
      Respregunta1,
      Respregunta2,
      Respregunta3,
      Respregunta4,
      Respregunta5,
      Respregunta6,
      Respregunta7,
      Respregunta8
    );

    instance
      .post(`/booking/book/valoration/${reserva}`, {
        answers: array,
        trainer_comment: comentario,
      })
      .then((resEn) => {
        const data = resEn.data;
        if (data) {
          booking_finish_trainer_emit({booking_id:reserva,trainer_id: localStorage.uuid});
          setTimeout(() => {
            window.location.href = `${basename}/${entrenadorType}/inicio`;
          }, 1000);
          
        }
      })
      .catch((err) => {
        setModalInfo(<SetModalError handleClick={() => setModalInfo(null)} />);
      });
  }
  function Bye() {
    if (have_valoration === true) {
      //
      hacerPreguntas(3);
    } else {
      setloas(true);
    }
  }

  function hacerPreguntas(step = 2) {
    if (step == 1) {
      // pedir comentarios
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div
              className="custom-ui CancelarReserva "
              style={{ padding: "3%", width: "60vw" }}
            >
              <h1 className="title text-center">Finalizar la clase</h1>
              <textarea
                defaultValue={comentario}
                onChange={(e) => setComentario(e.target.value)}
                rows="5"
                style={{ width: "100%", color: "#9DACBD" }}
                placeholder="Escribenos la razón para finalizar la clase o algún comentario que desees hacer."
              ></textarea>
              <p className="msg">¿Estas seguro de cancelar la clase?</p>
              <br />
              <div className="buttons">
                <button
                  className="No"
                  onClick={() => {
                    setComentario(null);
                    onClose();
                  }}
                >
                  <strong>No</strong> quiero cancelar
                </button>
                <button
                  className="Si"
                  onClick={async () => {
                    onClose();
                    hacerPreguntas(3);
                  }}
                >
                  Finalizar el entrenamiento
                </button>
              </div>
            </div>
          );
        },
      });
    } else if (step == 2) {
      // Preguntar si fue fácil
      //De acuerdo a su percepción, el nivel de dificultad del entrenamiento para el atleta es
      //setentrenador   1         3       5
      //              Facil, adecuado, dificil
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <form
              onSubmit={(e) => {
                onClose() || e.preventDefault() || Bye();
              }}
            >
              <div className="custom-ui CancelarReserva  ">
                <h1 className="titleentre">Entrenamiento finalizado</h1>
                <p
                  className="msg"
                  style={{ margin: "0 10vw", fontSize: "1vw" }}
                >
                  Responde{" "}
                  {have_valoration == true
                    ? "esta pregunta que nos permite"
                    : "estas preguntas que nos permiten"}{" "}
                  mejorar continuamente nuestro servicio
                </p>
                {have_valoration == true ? null : (
                  <p className="msg1">
                    1. ¿Cómo estuvo la dificultad de la clase para el usuario?
                    {renderStars(refpregunta1)}
                  </p>
                )}
                <p className="msg1">
                  {have_valoration == true ? "1." : "2."} Creemos el perfil de
                  este usuario, ¿qué comentarios relevantes tienes sobre este
                  usuario para compartir con tus compañeros?
                </p>
                <div className="msg1_cl">
                  <textarea
                    onChange={(e) => setComentario(e.target.value)}
                    rows="5"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#9DACBD",
                      border: "1px solid #9DACBD",
                      borderRadius: "10px",
                      fontStyle: "italic",
                    }}
                    placeholder="Escribenos la razón para finalizar la clase o algún comentario que desees hacer."
                  ></textarea>
                </div>
                {nivelporentrenadorcito == true ? (
                  <div className="buttons">
                    <button
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="confirmfin me-2"
                    >
                      Confirmar finalización
                    </button>
                  </div>
                ) : null}
                {have_valoration == true ? (
                  <div className="buttons">
                    <button
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="confirmfin me-2"
                    >
                      Confirmar finalización
                    </button>
                  </div>
                ) : null}
              </div>
            </form>
          );
        },
      });
    } else if (step == 3) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <form
              onSubmit={async () => {
                onClose();
                hacerPreguntas(4);
              }}
            >
              <div
                className="custom-ui CancelarReserva  "
                style={{ padding: "3%", width: "60vw" }}
              >
                <h1 className="title text-center" style={{ fontSize: "2vw" }}>
                  Entrenamiento finalizado
                </h1>

                <p className="msg text-center">
                  <strong>
                    ¡Ayúdanos a crear el nivel de dificultad perfecto para
                    nuestro cliente!
                  </strong>
                </p>
                <p className="msg text-center">
                  Responde las siguientes preguntas según el los resultados de
                  los test físico del cliente.
                </p>
                <div className="lineclas_clase w-100 mt-2 mb-3"></div>
                <p className="msg text-center my-1">
                  1. ¿Cuántos segundos pudo sostener el cliente la plancha en
                  antebrazos?
                </p>
                <div className="text-center my-1">
                  <label
                    key={"3e"}
                    className="creditoPlan  msg mx-1 text-center"
                  >
                    <div className="d-grid m-3">
                      <div className="centrebolar">
                        <input
                          type="number"
                          required
                          placeholder="Ingresar valor en segundos"
                          onChange={(e) => changeOption(e, 1)}
                          name="question1"
                          className=" msg mx-1 text-center"
                        />
                      </div>
                      {/* <span>{e}</span> */}
                    </div>
                  </label>
                </div>
                <p className="msg text-center my-1">
                  2. ¿Cuántas repeticiones de sentadilla pudo hacer el cliente
                  en un minuto?
                </p>
                <div className="text-center my-1">
                  <label
                    key={"3e"}
                    className="creditoPlan  msg mx-1 text-center"
                  >
                    <div className="d-grid m-3">
                      <div className="centrebolar">
                        <input
                          type="number"
                          placeholder="Ingresar el # de repeticiones"
                          onChange={(e) => changeOption(e, 2)}
                          required
                          name="question2"
                          className=" msg mx-1 text-center"
                        />
                      </div>
                      {/* <span>{e}</span> */}
                    </div>
                  </label>
                </div>
                <p className="msg text-center my-1">
                  3. ¿Cuántas repeticiones de burpees pudo hacer el cliente en
                  un minuto?
                </p>
                <div className="text-center my-1">
                  <label
                    key={"3e"}
                    className="creditoPlan  msg mx-1 text-center"
                  >
                    <div className="d-grid m-3">
                      <div className="centrebolar">
                        <input
                          type="number"
                          placeholder="Ingresar el # de repeticiones"
                          onChange={(e) => changeOption(e, 3)}
                          required
                          name="question3"
                          className=" msg mx-1 text-center"
                        />
                      </div>
                      {/* <span>{e}</span> */}
                    </div>
                  </label>
                </div>
                <p className="msg text-center my-1">
                  4. ¿Cuántas repeticiones de push Ups pudo hacer el cliente en
                  un minuto?
                </p>
                <div className="text-center my-1">
                  <label
                    key={"3e"}
                    className="creditoPlan  msg mx-1 text-center"
                  >
                    <div className="d-grid m-3">
                      <div className="centrebolar">
                        <input
                          type="number"
                          placeholder="Ingresar el # de repeticiones"
                          onChange={(e) => changeOption(e, 4)}
                          required
                          name="question4"
                          className=" msg mx-1 text-center"
                        />
                      </div>
                      {/* <span>{e}</span> */}
                    </div>
                  </label>
                </div>
                <div className="buttons">
                  <button
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="confirmfin1 me-2"
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </form>
          );
        },
      });
    } else if (step == 4) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <form
              onSubmit={() => {
                setmelo8(true);
                onClose();
              }}
            >
              <div
                className="custom-ui CancelarReserva  "
                style={{ padding: "3%", width: "60vw" }}
              >
                <h1 className="title text-center" style={{ fontSize: "2vw" }}>
                  Entrenamiento finalizado
                </h1>
                <p className="msg text-center my-1">
                  5. ¿Cuántas repeticiones de Crunch pudo hace el cliente en un
                  minuto?
                </p>
                <div className="text-center my-1">
                  <label
                    key={"3e"}
                    className="creditoPlan  msg mx-1 text-center"
                  >
                    <div className="d-grid m-3">
                      <div className="centrebolar">
                        <input
                          type="number"
                          placeholder="Ingresar el # de repeticiones"
                          onChange={(e) => changeOption(e, 5)}
                          required
                          name="question5"
                          className=" msg mx-1 text-center"
                        />
                      </div>
                      {/* <span>{e}</span> */}
                    </div>
                  </label>
                </div>
                <p className="msg text-center my-1">
                  6. ¿Cuántos segundos pudo sostener el cliente la plancha en
                  palmas?
                </p>
                <div className="text-center my-1">
                  <label
                    key={"3e"}
                    className="creditoPlan  msg mx-1 text-center"
                  >
                    <div className="d-grid m-3">
                      <div className="centrebolar">
                        <input
                          type="number"
                          placeholder="Ingresar el # de repeticiones"
                          onChange={(e) => changeOption(e, 6)}
                          required
                          name="question6"
                          className=" msg mx-1 text-center"
                        />
                      </div>
                      {/* <span>{e}</span> */}
                    </div>
                  </label>
                </div>
                <p className="msg text-center my-1">
                  7. ¿Cuántos segundos pudo sostener el cliente la sentadilla
                  isométrica?
                </p>
                <div className="text-center my-1">
                  <label
                    key={"3e"}
                    className="creditoPlan  msg mx-1 text-center"
                  >
                    <div className="d-grid m-3">
                      <div className="centrebolar">
                        <input
                          type="number"
                          placeholder="Ingresar el # de repeticiones"
                          onChange={(e) => changeOption(e, 7)}
                          required
                          name="question7"
                          className=" msg mx-1 text-center"
                        />
                      </div>
                      {/* <span>{e}</span> */}
                    </div>
                  </label>
                </div>
                <p className="msg text-center my-1">
                  8. ¿Cuántas repeticiones de Superman pudo hace el cliente en
                  un minuto?
                </p>
                <div className="text-center my-1">
                  <label
                    key={"3e"}
                    className="creditoPlan  msg mx-1 text-center"
                  >
                    <div className="d-grid m-3">
                      <div className="centrebolar">
                        <input
                          type="number"
                          placeholder="Ingresar el # de repeticiones"
                          onChange={(e) => changeOption(e, 8)}
                          required
                          name="question8"
                          className=" msg mx-1 text-center"
                        />
                      </div>
                      {/* <span>{e}</span> */}
                    </div>
                  </label>
                </div>

                <div className="buttons">
                  <button
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="confirmfin1 me-2"
                  >
                    Confirmar finalización
                  </button>
                </div>
              </div>{" "}
            </form>
          );
        },
      });
    }
  }

  function getNextFrom(storeObject, theCurrentItem, status_classget) {
    // console.log(
    //   storeObject,
    //   theCurrentItem,
    //   status_classget.methodology_series
    // );
    let keys = Object.keys(storeObject);
    let indexactual = keys.indexOf(theCurrentItem);
    let nextIndex;
    let nextItem;
    // if (
    //   status_classget.methodology_series &&
    //   status_classget.methodology_series < storeObject[indexactual].series
    // ) {
    //   nextIndex = keys.indexOf(theCurrentItem);
    //   nextItem = keys[nextIndex];
    // } else {
      nextIndex = keys.indexOf(theCurrentItem) + 1;
      nextItem = keys[nextIndex];
    // }

    return nextItem;
  }

  const paneles = (
    <>
      <Panel
      closeDoor={closeDoor}
        align="left"
        classNameContent="myPanel"
        contentRef={myPanel}
        tabCerrar={cerrar}
        tabRef={myPanelTab}
        tabAvatar={avatar ? avatar : customAvatar}
        classNameTab="myPanelTab"
      >
        <div
          style={{
            background: "#7886F5",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          <img src={logo} alt="giro" width={100} className="m-3 p-auto" />
        </div>

        <div className="imgEntrenador1 imgEntrenador1_f">
          <div className="d-flex w-100">
            <div className="d-row widhtimg" style={{position:"relative"}}>
            <div style={{position:"absolute",bottom:"0",}}>  <img style={{width:'3.5vmin',height:"3.5vmin"}} alt="banda"
             src={bandConnected ?banda_connect_img:banda_disconnect_img}  /></div>
              <div>
                <img alt="avatar" src={avatar} className="rounded-circle " />
              </div>
              <div></div>
            </div>
            <div className="infoPeer lh-1 widhtinfoperr">
              <div className="name">{nombreCliente}</div>

              <div className="edadCliente my-1">{edad} años</div>

              <div className="calificacionEntrenador my-1">
                <img
                  src={estrellas >= 1 ? starfill : star}
                  alt="etrella"
                  className=""
                />
                <img
                  src={estrellas >= 2 ? starfill : star}
                  alt="etrella"
                  className=""
                />
                <img
                  src={estrellas >= 3 ? starfill : star}
                  alt="etrella"
                  className=""
                />
                <img
                  src={estrellas >= 4 ? starfill : star}
                  alt="etrella"
                  className=""
                />
                <img
                  src={estrellas >= 5 ? starfill : star}
                  alt="etrella"
                  className=""
                />
              </div>

              <div className="nivelCliente">
                <b>
                  Nivel:
                  <br />
                </b>
                {/* &nbsp; */}
                <span>
                  {nivelStr == 10
                    ? "Principiante"
                    : nivelStr == 20
                    ? "Intermedio"
                    : "Avanzado"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="lineclas_clase"></div>
        <div className="Cardio infoClas ">
          {/* <img src={corazon} alt="cardio" /> */}
          <div className="datos">
            {HrData && HrData.heart_rate > -1 ? (
              <span
                className="valor"
                style={
                  HrData.heart_rate >= intenso
                    ? { color: "#E16250", fontSize: "3vmin" }
                    : moderado <= HrData.heart_rate &&
                      HrData.heart_rate < intenso
                    ? { color: "#FFB6A4", fontSize: "3vmin" }
                    : luz <= HrData.heart_rate &&
                      HrData.heart_rate < moderado
                    ? { color: "#F5D7CF", fontSize: "3vmin" }
                    : muy_suave <= HrData.heart_rate &&
                      HrData.heart_rate < luz
                    ? { color: "#7988F6", fontSize: "3vmin" }
                    : { color: "#6EE1FB", fontSize: "3vmin" }
                }
              >
                {HrData.heart_rate}
              </span>
            ) : (
              <span>_ _ _ _ _</span>
            )}
            <br />
            Frecuencia cardiaca
          </div>
        </div>
        <div className="Calorias infoClas infoClas_F ">
          {/* <img src={flama} alt="cardio" /> */}
          <div className="datos">
            {HrData && HrData.kcal > 0 ? (
              <span className="valor" style={{ fontSize: "3vmin" }}>
                {HrData.kcal.toFixed(2)}
              </span>
            ) : (
              <span>_ _ _ _ _</span>
            )}
            <br />
            Calorias quemadas
          </div>
        </div>
        <div className="mx-auto ">{lesiones}</div>
      </Panel>
      <Panel
      closeDoor={closeDoor}
        align="right"
        id="miEntrenamiento"
        classNameContent="miEntrenamiento entrenadorRutina"
        contentRef={miEntrenamiento}
        tabCerrar={cerrar}
        tabRef={miEntrenamientoTab}
        tabAvatar={corazon}
        classNameTab="miEntrenamientoTab"
      >
        <div className="titulo">
          Ruta de entrenamiento
          <div className="nombreRutinaActual">
            <span>
              {nombreRutinaActual}
              {SeriesRutina > 1 && frecuenciaCustomer
                ? localStorage.seriesClass + "/" + SeriesRutina
                : null}
            </span>
            {nombreRutinaActual
              ? `: ${fancyTimeFormat(tiempoRutinaActual)} min`
              : null}
          </div>
        </div>
        <div className="rutaContainer">
          <div className="rutas infoClase" ref={refRutinas}>
            {rutina}
          </div>
        </div>
        <div
          ref={iniciarRut}
          className="Iniciar"
          style={{ cursor: "pointer" }}
          onClick={() => {
            iniciarEntrenamiento(true);
            iniciamodify();
            // play3(); -----------pito start
          }}
        >
          <button className="btnIniciar">Iniciar rutina</button>
        </div>
        <div ref={nexRut} className="Iniciar d-none">
          <button className="btnIniciar" onClick={() => siguienteRutina()}>
            {nextRutina}
          </button>
        </div>
       
        <div className="tiempo">
          <span>{timeLeft}</span>
          <br />
          Sesión de entrenamiento
        </div>
        <div className="Finalizar1 ">
          <button className="btnFinalizar" onClick={finalizarEntrenamiento}>
            Finalizar sesión
          </button>
        </div>
      </Panel>
      <Panel
        align="right"
        otropanel="otro"
        id="miEntrenamientototals"
        classNameContent="miEntrenamiento entrenadorRutina"
        contentRef={miEntrenamientototal}
        tabCerrar={cerrar}
        tabRef={miEntrenamientoTabtotal}
        tabAvatar={rutinaavatar}
        classNameTab="miEntrenamientoTab "
      >
        <div className="titulo">Ruta de entrenamiento </div>
        <div className="rutaContainer" style={{ height: "90%" }}>
          {infoRutinas &&
            infoRutinas.map((e, index) => (
              <div key={index} id={"indicador-" + index}>
                <div
                  className="nombreRutinaActual"
                  id={"ciclo-" + index}
                  style={{ opacity: "0.3" }}
                >
                  <span>{e.name}</span>{" "}
                </div>

                {e.set.map((el, index) => (
                  <div
                    key={index}
                    className=""
                    id={"ejercicio-" + index}
                    style={{ opacity: "0.3", marginBottom: "0.5rem" }}
                  >
                    <div
                      className="nombreRutinaActual"
                      style={{
                        fontWeight: "bold",
                        lineHeight: "1",
                        fontSize: "1.3rem",
                        marginbottom: " 0.5rem",
                      }}
                    >
                      {el.exercise}
                    </div>
                    {el.repetitions > 1 ? (
                      <div
                        className="nombreRutinaActual"
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          color: "#AAAAAA",
                        }}
                      >
                        {"Repeticiones : " + el.repetitions}
                      </div>
                    ) : null}
                    <div
                      className="nombreRutinaActual"
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        color: "#AAAAAA",
                      }}
                    >
                      Tiempo :{" "}
                      {el.time < 60 ? el.time + " secs" : el.time / 60 + " Min"}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </Panel>
    </>
  );
  return (
    <>
      <ContenedorClase
        paneles={paneles}
        trainerMusic={true}
        trainerpeer={true}
      />
      {modalInfo}
    </>
  );
};

export default EntrenadorClase;
