import React from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";

import "swiper/swiper-bundle.min.css";
import { laptop575 } from "../utils/utils";
import { Link } from "react-router-dom";
// export function AllImagenes2() {
//   SwiperCore.use([Pagination, Autoplay, Navigation]);

//   const imagenes = [
//     "https://api.elevva.com.co/uploads/trainings/mains/HIIT_ROPE_667X667-min.jpg",
//     "https://api.elevva.com.co/uploads/trainings/mains/HIIT_ROPE_667X667-min.jpg",
//     "https://api.elevva.com.co/uploads/trainings/mains/HIIT_ROPE_667X667-min.jpg",
//     "https://api.elevva.com.co/uploads/trainings/mains/HIIT_ROPE_667X667-min.jpg",
//     "https://api.elevva.com.co/uploads/trainings/mains/HIIT_ROPE_667X667-min.jpg",
//     "https://api.elevva.com.co/uploads/trainings/mains/HIIT_ROPE_667X667-min.jpg",
//     "https://api.elevva.com.co/uploads/trainings/mains/HIIT_ROPE_667X667-min.jpg",
//     "https://api.elevva.com.co/uploads/trainings/mains/HIIT_ROPE_667X667-min.jpg",
//   ];
//   const sw = window.screen.width;

//   const render_img = imagenes.map((imagen, index) => {
//     const def_img = imagen;

//     return (
//       <SwiperSlide key={index} className="sliderNetFlixvideolo">
//         <div className="content ">
//           <img
//             src={def_img}
//             style={{ borderRadius: "20px" }}
//             width={laptop575.matches ? "100%" : "70%"}
//             height="100%"
//           />
//         </div>
//       </SwiperSlide>
//     );
//   });

//   return laptop575.matches ? (
//     <>
//       <div
//         id={"demo2"}
//         className="position-relative reconocer1"
//         style={{ backgroundColor: "#FDF1E8" }}
//       >
//         <h2>Disciplinas recomendadas para Felipe</h2>
//         <div className="d-flex  my-1">
//           <div className="swiper-button-next-pin"></div>
//           <div className="swiper-button-prev-pin"></div>
//         </div>

//         <Swiper
//           data-id={"demo2"}
//           navigation={{
//             nextEl: ".swiper-button-next-pin",
//             prevEl: ".swiper-button-prev-pin",
//           }}
//           className="slidersNetflixReservas"
//           spaceBetween={10}
//           slidesPerView="auto"
//           slidesOffsetBefore={10}
//           slidesOffsetAfter={30}
//           slidesPerGroup={1}
//           slidesPerGroupSkip={1}
//           onUpdate={(swiper) => swiper.updateSlides()}
//         >
//           {render_img}
//         </Swiper>
//       </div>
//     </>
//   ) : (
//     <Swiper className="px-2" spaceBetween={-80} slidesPerView={1}>
//       {render_img}
//     </Swiper>
//   );
// }
export function Videos_two(props) {
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const imagenes = props.videosTime;

  const id = props.id;
  const textTitleOne = props.textTitleOne || null;
  const textTitleTwo = props.textTitleTwo || null;
  const sw = window.screen.width;

  const render_img =
    imagenes &&
    imagenes.map((imagen, index) => {
      const def_img = imagen;

      return (
        <SwiperSlide
          key={index}
          className="sliderNetFlixvideorozo py-1"
          style={{ borderRadius: "20px" }}
        >
          <Link
            className="content"
            to={`/ejercicios/card/${props.contenido}${index}`}
          >
            <img
              className="position-relative playervideo"
              src={def_img[1]}
              width={"100%"}
              height="100%"
              controls
            />
            <div
              className="d-flex pt-4"
              style={{ justifyContent: "space-around" }}
            >
              <span style={{ color: "#394F65" }}>{def_img[0]}</span>
              <span className="fw-bold" style={{ color: "#CB3D82" }}>
                30min
              </span>
            </div>
          </Link>
        </SwiperSlide>
      );
    });

  return laptop575.matches ? (
    <>
      <div id={"demo2"} className="position-relative reconocer1 mb-4">
        <div
          className="d-flex swiper-pagination_p  my-4 "
          style={{ justifyContent: "space-between" }}
        >
          <h2 style={{ color: "#394F65", fontSize: "25px" }}>
            <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
            <strong> {textTitleTwo}</strong>
          </h2>
          <div className="d-flex">
            <div className={`swiper-button-prev-${id}`}></div>
            <div className={`swiper-button-next-${id}`}></div>
          </div>
        </div>
        <div style={{ height: "180px" }}>
          {" "}
          <Swiper
            data-id={"demo2"}
            navigation={{
              nextEl: `.swiper-button-next-${id}`,
              prevEl: `.swiper-button-prev-${id}`,
            }}
            className="slidersNetflixReservas "
            spaceBetween={15}
            slidesPerView="auto"
            slidesOffsetBefore={10}
            slidesOffsetAfter={30}
            slidesPerGroup={1}
            slidesPerGroupSkip={1}
            onUpdate={(swiper) => swiper.updateSlides()}
          >
            {render_img}
          </Swiper>
        </div>
      </div>
    </>
  ) : (
    <Swiper className="px-2" spaceBetween={-80} slidesPerView={1}>
      {render_img}
    </Swiper>
  );
}
