import { UPDATE_DATA } from "../types";

const initialState = {
  db: [],
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA: {
      return {
        ...state,
        db: action.payload,
      };
    }

    default:
      return state;
  }
}
