import { INFO_CUSTOMER } from "../types";
import { instance } from "../utils/utils";
function action_customer() {
  return async (dispatch) => {
    instance
      .get("users/current/")
      .then((res) => res.data)
      .then((data) => {
        dispatch({ type: INFO_CUSTOMER, payload: data });
      })
      .catch((err) => console.error(err));
  };
}
export { action_customer };
