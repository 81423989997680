import Clasestream from "../Clase/Clasestream";

const ClaseCompanies = (props) => {
  const user = props.user;
  const imagenCompanies = process.env.PUBLIC_URL + "/img/logosEmpresas.png";
  return (
    <>
      <Clasestream user={user} imagenCompanies={imagenCompanies} />
    </>
  );
};
export default ClaseCompanies;
