import { useState } from "react";
import "./Hresults.css";
import React from "react";

import { laptop575 } from "../utils/utils";

import { useParams } from "react-router-dom";
import icon1 from "../assets/img/reservasimages/icon1.png";
import icon2 from "../assets/img/reservasimages/icon2.png";
import icon3 from "../assets/img/reservasimages/icon3.png";
import icon4 from "../assets/img/reservasimages/alimentate.png";

import "react-vis/dist/style.css";

import { ModeloResults2 } from "./modeloResults2.js";

const Hrresults = (props) => {
  const user = props.user;
  const [modalInfo, setModalInfo] = useState(null);
  const { rutina, uuid } = useParams();

  return laptop575.matches ? (
    <div className="resumenContainer">
      <div className="maxreservarResumen">
        <div className="superiorMaxResumen  ">
          <div className="SubTitle8resumen">Resumen de tu sesión</div>
          <div className="lineareservatuclaseonlyresumen"></div>
        </div>
      </div>

      <ModeloResults2
        age={user.customer.age}
        UUid={uuid}
        url={`customers/disciplines/?discipline=${rutina.replace("d", "")}`}
        url2={`customers/healthlog/?discipline=${uuid}`}
      />
      {modalInfo}

      <div className="titleresumensesion ">Recomendaciones</div>

      <div className="sliderResumen">
        <div className="recom">
          <div>
            <div className="recomendaciones">
              <div className="content">
                <div className="my-3 mx-3">
                  <span>
                    Somos compañeros en tu camino hacia un estilo de vida
                    saludable y sabemos cuán importante es generar hábitos para
                    optimizar tu entrenamiento y resultados ¡Felicidades
                    Elevva+!
                  </span>
                </div>
                <div className="d-flex">
                  <div className="itemRecom">
                    <img src={icon1} alt="Icon" />
                    <span>
                      <b>CREA UNA COSTUMBRE</b>: Haz del ejercicio una costumbre
                      cotidiana y reserva tus clases con antelación.{" "}
                    </span>
                  </div>
                  <br />
                  <div className="itemRecom">
                    <img src={icon2} alt="Icon" />
                    <span>
                      <b>CAMBIA TU ROPA:</b> La ropa que usaste durante tu
                      entrenamiento atrapa humedad que aumenta las posibilidades
                      del crecimiento de bacterias, hongos y gérmenes, lo que
                      conlleva a la aparición de granitos.{" "}
                    </span>
                  </div>

                  <div className="itemRecom">
                    <img src={icon3} alt="Icon" />
                    <span>
                      <b>TOMA UNA DUCHA DE AGUA FRIA Y DESPUES CALIENTE</b>: Los
                      baños de contraste (alternando agua fría y caliente) ayuda
                      a recuperarse más rápidamente, reduce el dolor muscular y
                      previene lesiones.{" "}
                    </span>
                  </div>

                  <div className="itemRecom">
                    <img src={icon4} alt="Icon" />
                    <span>
                      <b>ALIMENTATE</b>: Después de entrenar, es necesario que
                      recuperes energía a través de una buena alimentación, que
                      también te ayudará a reparar tejidos y recuperar fuerzas
                      para tu próximo reto.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <ModeloResults2
      age={user.customer.age}
      UUid={uuid}
      url={`customers/disciplines/?discipline=${rutina.replace("d", "")}`}
      url2={`customers/healthlog/?discipline=${uuid}`}
    />
  );
};
export default Hrresults;
