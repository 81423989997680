import { Redirect, useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import candado from "../../Perfil/iconos/candado.png";
import {
  ButtonMob,
  instance,
  NuevaAxiosURL,
  entrenadorType,
  formatNumber,
  Loadingspinner,
  ModalInfo,
  SetModalError,
  laptop575,
  laptop,
  basename,
} from "../../utils/utils";
import "./Perfil.css";
import VerCuenta from "./VerCuenta";
import flechaverde from "../../Perfil/iconosinfo/felchaverde.png";
import perfil from "../assets/icons/perfil.png";
import iconCam from "../../assets/img/general/warning2.png";
import inf from "../assets/icons/inf.png";
import sesiones from "../assets/icons/sesiones.png";
import disciplinas from "../assets/icons/disciplinas.png";
import ingresos from "../assets/icons/ingresos.png";
import { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";

import camera from "../../assets/img/general/camara.png";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableHead } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Moment from "moment";
import { extendMoment } from "moment-range";

import bg_header_table from "../assets/fondos/bg_header_table.png";

const PerfilEntrenador = (props) => {
  const user = props.user;
  const [actualView, setView] = useState();

  const selOption = [
    ["Información personal", perfil, "perfil"], // 0
    ["Información de sesiones", sesiones, "sesiones"], // 1
    ["Disciplinas habilitadas", disciplinas, "disciplinas"], // 2
    ["Ingresos", ingresos, "ingresos"], // 3
  ];

  return (
    <div className="entrenadorPerfil">
      <Cabecera selOption={selOption} onClick={(type) => setView(type)} />
      <div
        className={laptop575.matches ? null : " borderlineiconos mb-0 mx-auto"}
      ></div>
      {actualView == selOption[0][2] ? <InfoPersonal user={user} /> : null}
      {actualView == selOption[1][2] ? <InfoSesiones user={user} /> : null}
      {actualView == selOption[2][2] ? <InfoDisciplinas user={user} /> : null}
      {actualView == selOption[3][2] ? <InfoIngresos user={user} /> : null}
    </div>
  );
};

const InfoSesiones = (props) => {
  const [sesiones1, setsesiones1] = useState(null);

  function guardarPruebas(idx) {
    setsesiones1(idx);
    for (let i = 1; i < 6; i++) {
      // changeColordistwon.style = "display: none";
      const flecha =
        document.querySelector(".sesiones").children[0].children[0].children[
          i - 1
        ].children[1].children[0];
      flecha.style = "display: none";
      const colorear1 = document.getElementById(`${i}`);

      colorear1.style = "background-color: white !important";
    }
    const flecha2 =
      document.querySelector(".sesiones").children[0].children[0].children[
        idx - 1
      ].children[1].children[0];

    flecha2.style = "display:block";
    const colorear = document.getElementById(`${idx}`);

    colorear.style = "background-color: #F7F7F7 !important";
  }

  const user = props.user;
  return laptop575.matches ? (
    <div className="sesiones ">
      <div className="d-flex">
        <div className="accordion accordion-flush" id="accordionSesiones">
          <div className="d-flex unoporuno ">
            <div
              className=" w-100 px-3 py-1"
              id={1}
              onClick={() => guardarPruebas(1, user)}
            >
              <div className="my-3 info9">Sesiones ejecutadas</div>
              <div className="mb-1 infodet">
                Son las Sesiones que dictaste al 100%.
              </div>
            </div>
            <div>
              <img src={flechaverde} className="w-100 imagenw1" />
            </div>
          </div>
          <div className="d-flex unoporuno">
            <div
              className=" w-100 px-3 py-1"
              id={2}
              onClick={() => guardarPruebas(2, user)}
            >
              <div className="my-3 info9">Sesiones canceladas</div>

              <div className="mb-1 infodet">
                Son las sesiones que no dictaste.
              </div>
            </div>
            <div>
              <img src={flechaverde} className="w-100 imagenw1" />
            </div>
          </div>
          <div className="d-flex unoporuno">
            <div
              id={3}
              onClick={() => guardarPruebas(3, user)}
              className=" w-100 px-3 py-1"
            >
              <div className="my-3 info9">Sesiones penalizadas</div>
              <div className="mb-1 infodet">
                Son las sesiones que cancelaste dentro las 24 horas antes o no
                te presentaste.
              </div>
            </div>
            <div>
              <img src={flechaverde} className="w-100 imagenw1" />
            </div>
            {/* <LoadTableSesiones modo={3} user={user} /> */}
          </div>
          <div className="d-flex unoporuno">
            <div
              id={4}
              onClick={() => guardarPruebas(4, user)}
              className=" w-100 px-3 py-1"
            >
              <div className="my-3 info9">Sesiones incompletas</div>
              <div className="mb-1 infodet">
                Son las sesiones que por algún motivo no se pudieron dictar.
              </div>
            </div>
            <div>
              <img src={flechaverde} className="w-100 imagenw1" />
            </div>
          </div>
          <div className="d-flex unoporuno">
            <div
              id={5}
              onClick={() => guardarPruebas(5, user)}
              className=" w-100 px-3 py-1 "
            >
              <div className="my-3 info9">Sesiones de creador</div>
              <div className="mb-3 infodet">
                Son las sesiones que se dictan de disciplinas propias.
              </div>
              {/* <LoadTableSesiones modo={5} user={user} /> */}
            </div>
            <div>
              <img src={flechaverde} className="w-100 imagenw1" />
            </div>
          </div>
        </div>
        <div className="d-row">
          <div style={{ width: "30vw" }}>
            {sesiones1 == 1 ? (
              <LoadTableSesiones modo={"finished"} user={user} />
            ) : null}
            {sesiones1 == 2 ? (
              <LoadTableSesiones modo={"canceled"} user={user} />
            ) : null}
            {sesiones1 == 3 ? (
              <LoadTableSesiones modo={"penalized"} user={user} />
            ) : null}
            {sesiones1 == 4 ? (
              <LoadTableSesiones modo={"incomplete"} user={user} />
            ) : null}
            {sesiones1 == 5 ? (
              <LoadTableSesiones modo={"creator"} user={user} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="sesiones">
      <div className="title">Información de sesiones</div>
      <div
        className="accordion accordion-flush list_sesiones_info"
        id="accordionSesiones"
        style={{ margin: "0 1vw" }}
      >
        <AccordionItem
          dataBsParent="#accordionSesiones"
          title="Sesiones ejecutadas"
          item="1"
        >
          <div className="mb-3 info">
            Son las Sesiones que dictaste al 100%.
          </div>
          <LoadTableSesiones modo={"finished"} user={user} />
        </AccordionItem>
        <AccordionItem
          dataBsParent="#accordionSesiones"
          title="Sesiones canceladas"
          item="2"
        >
          <div className="mb-3 info">Son las sesiones que no dictaste.</div>
          <LoadTableSesiones modo={"canceled"} user={user} />
        </AccordionItem>
        <AccordionItem
          dataBsParent="#accordionSesiones"
          title="Sesiones penalizadas"
          item="3"
        >
          <div className="mb-3 info">
            Son las sesiones que cancelaste dentro las 24 horas antes o no te
            presentaste.
          </div>
          <LoadTableSesiones modo={"penalized"} user={user} />
        </AccordionItem>
        <AccordionItem
          dataBsParent="#accordionSesiones"
          title="Sesiones incompletas"
          item="4"
        >
          <div className="mb-3 info">
            Son las sesiones que por algún motivo no se pudieron dictar.
          </div>
          <LoadTableSesiones modo={"incomplete"} user={user} />
        </AccordionItem>
        <AccordionItem
          dataBsParent="#accordionSesiones"
          title="Sesiones de creador"
          item="5"
        >
          <div className="mb-3 info">
            Son las sesiones que se dictan de disciplinas propias.
          </div>
          <LoadTableSesiones modo={"creator"} user={user} />
        </AccordionItem>
      </div>
    </div>
  );
};

const LoadTableSesiones = (props) => {
  const [currentSlider, setCurrentSlider] = useState(0);
  const [theSwipper, setTheSwipper] = useState(null);
  const listennext = document.getElementById("next");
  const listenprev = document.getElementById("prev");
  if (listennext) {
    listennext.addEventListener("mousemove", () => {
      document.querySelector(".siguientenext").style = "color:#2BB7C1";
      document.querySelector(".anteriorprev").style = "color:white";
    });
  }
  if (listenprev) {
    listenprev.addEventListener("mousemove", () => {
      document.querySelector(".anteriorprev").style = "color:#2BB7C1";
      document.querySelector(".siguientenext").style = "color:white";
    });
  }

  const user = props.user;
  const modo = props.modo;

  const moment = extendMoment(Moment);

  const [data, setData] = useState(
    <TableRow>
      <TableCell align="center">
        <div className="form-text">Sin datos</div>
      </TableCell>
    </TableRow>
  );

  useEffect(() => {
    const copyEntrenamientos = [];
    instance
      .get(`booking/?${props.modo}`)
      .then((resEn) => {
        const info = resEn.data;

        if (info != null) {
          const pageNumber = [];
          for (let i = 1; i <= Math.ceil(info.length / 7); i++) {
            pageNumber.push(i);
          }
          for (const i of info) {
            const identrenamiento = i.training.id;
            const date = i.date;
            const id = i.id;
            const cover_picture = i.training.cover_picture;
            const description = i.training.description;
            const icon = i.training.icon;
            const name = i.training.name;

            if (info != null) {
              copyEntrenamientos[id] = {
                id: id,
                date: date,
                titulo: unescape(name).toLowerCase(),
                descripcion: description,
                img: cover_picture,
                rawImg: cover_picture,
                icon: icon,

                // categoria,
              };
            }
            function prevSlider() {
              const arreglo = info ? Math.ceil(info.length / 7) : null;
              // console.log("hello1", theSwipper, currentSlider);
              if (arreglo == arreglo - currentSlider) {
                setCurrentSlider(0);
              } else {
                setCurrentSlider(currentSlider - 1);
              }
            }

            function nextSlider() {
              const arreglo = info ? Math.ceil(info.length / 7) : null;

              if (arreglo == currentSlider + 1) {
                setCurrentSlider(arreglo - 1);
              } else {
                setCurrentSlider(currentSlider + 1);
              }
            }
            setData(
              copyEntrenamientos
                .sort(function (a, b) {
                  const fechaA = moment(a.date, "YYYY-MM-DD");
                  const fechaB = moment(b.date, "YYYY-MM-DD");
                  if (fechaA > fechaB) {
                    return -1;
                  }
                  if (fechaA < fechaB) {
                    return 1;
                  }
                  // names must be equal
                  return 0;
                })
                .filter((e) => e)
                .slice(currentSlider * 7, currentSlider * 7 + 7)
                .map((value, index) => {
                  const clase = value.titulo;
                  const idclase = value.id;
                  const fecha = moment(value.date.slice(0, 10)).format("L");
                  const hora = moment(value.date.slice(11, 16), "HH:mm").format(
                    "LT a"
                  );

                  // const strFecha = fecha.format("DD/MM/YYYY [-] HH:mm");
                  return (
                    <TableRow key={index} className="table-row">
                      {laptop575.matches ? (
                        <div>
                          <TableCell component="th" align="center" scope="row">
                            <div className="itemSesionesTabla d-flex">
                              <div
                                className="date mx-4 text-left"
                                style={{ textTransform: "capitalize" }}
                              >
                                {idclase}
                              </div>
                              <div className="date mx-4 text-left">
                                Sesión {fecha} {hora}
                              </div>
                              <div
                                className="clase mx-4 text-left"
                                style={{ textTransform: "capitalize" }}
                              >
                                {clase}
                              </div>
                            </div>
                            <nav className="CompletarFooter1 navFooterejercicios1">
                              <div className="siguiente1">
                                <div
                                  id="prev"
                                  onClick={prevSlider}
                                  className="anteriorprev"
                                >
                                  Anterior
                                </div>
                                <div className="numerosmedio">
                                  {/* {currentSlider}/ */}

                                  {info
                                    ? pageNumber.map((e) => (
                                        <div className="">{e}</div>
                                      ))
                                    : "10"}
                                </div>
                                <div
                                  id="next"
                                  onClick={nextSlider}
                                  className="siguientenext"
                                >
                                  Siguiente
                                </div>
                              </div>
                            </nav>
                          </TableCell>
                        </div>
                      ) : (
                        <TableCell component="th" align="center" scope="row">
                          <div className="itemSesionesTabla">
                            <div className="date">
                              Sesión {fecha} {hora}
                            </div>
                            <div className="clase">{clase}</div>
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
            );
          }
        }
      })

      .catch((err) => {
        console.log("Error load_clases_modo", err);
      });
    return () => {};
  }, [currentSlider, theSwipper]);
  return <MyTable data={data} />;
};

const MyTable = (props) => {
  const path = useLocation();
  const content = props.data;
  const header = props.header;
  return (
    <TableContainer
      component={Paper}
      className={
        path.pathname.includes("sesiones")
          ? "tableStyledOdd"
          : "tableStyledOddingresos"
      }
    >
      <Table aria-label="customized table">
        <TableHead>{header}</TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    </TableContainer>
  );
};

export const AccordionItem = (props) => {
  const path = useLocation();
  const parent = props.dataBsParent;
  const children = props.children;
  const title = props.title;
  const clase = props.clase;
  const key_1 = props.key;
  const item = props.item;
  function goToAcoordion(item) {
    if (path.pathname.includes("ejercicios") && laptop575.matches) {
      const toda = document.querySelector(".glosarioVideo .accordion");
      for (let i = 0; i < toda.children.length; i++) {
        const solouno1 = toda.children[i].querySelector(".flechaenvideos");
        solouno1.style = "display:none";
      }

      const solouno = document
        .querySelector(`#heading${item}`)
        .querySelector(".flechaenvideos");
      if (solouno) solouno.style = "display:block";
    }

    // const el = document.querySelector(`#heading${item}`);
  }

  return (
    <div className={` accordion-item ${clase} `}>
      <h2 className="accordion-header" id={`heading${item}`}>
        <button
          id={`heading${item}`}
          onClick={() =>
            setTimeout(() => {
              goToAcoordion(item);
            }, 500)
          }
          className={
            laptop575.matches
              ? "accordion-button collapsed"
              : "accordion-button collapsed"
          }
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseItem${item}`}
          aria-expanded="true"
          aria-controls={`collapseItem${item}`}
        >
          {title}
        </button>
      </h2>
      <div
        id={`collapseItem${item}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${item}`}
        data-bs-parent={parent}
      >
        <div
          style={
            laptop575.matches &&
            window.location.pathname == `${basename}/Usuario/cuerpo`
              ? {
                  position: "absolute",
                  zIndex: "9999",
                  backgroundColor: "white",
                  marginTop: "0vh",

                  width: "90%",
                }
              : null
          }
          className={
            laptop575.matches && path.pathname.includes("ejercicios")
              ? null
              : "accordion-body "
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export const AccordionItem1 = (props) => {
  const parent = props.dataBsParent;
  const children = props.children;
  const title = props.title;
  const item = props.item;
  function goToAcoordion(item) {
    const el = document.querySelector(`#heading${item}`);
  }
  return (
    <div className="accordion-item">
      <h2
        className="accordion-header"
        id={`heading${item}`}
        onChange={() =>
          setTimeout(() => {
            goToAcoordion(item);
          }, 500)
        }
      ></h2>
      <div
        id={`collapseItem${item}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${item}`}
        data-bs-parent={parent}
      >
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  );
};

const InfoDisciplinas = (props) => {
  const user = props.user;
  const [renderDisciplina, setRenderDisciplina] = useState(null);
  useEffect(() => {
    const formData = new FormData();
    formData.append("data", user.code);
    instance
      .get("trainers/trainings")
      .then((res) => {
        const info = res.data;

        if (info) {
          const arrDiscplina = [];
          for (const i of info) {
            const name = unescape(i.name).toLowerCase();
            let img = null;
            let icon = null;
            try {
              img = "https://api.elevva.com.co" + i.main_picture;
              icon = "https://api.elevva.com.co" + i.icon;
            } catch (e) {
              console.log(e);
            }
            arrDiscplina.push(
              <div className="contentDisciplina col-12  col-md-4" key={name}>
                <div
                  className="content itemReserva reservafull d-flex"
                  style={{
                    backgroundImage: `url(${img}) `,
                  }}
                >
                  <div className="negritod"> </div>
                  <img src={icon} alt="icono" style={{ zIndex: "1" }} />
                  <div className="name" style={{ zIndex: "1" }}>
                    {name}
                  </div>
                </div>
              </div>
            );
          }
          setRenderDisciplina(arrDiscplina);
        }
      })
      .catch((err) => {
        console.log("Error load_diciplinas_a", err);
      });
  }, []);
  return (
    <div className="disciplinasEhabi">
      {laptop575.matches ? (
        <>
          {" "}
          <div className="texto acomodartextinresos">
            Estas disciplinas son las que podrás dictar en la plataforma ELEVVA
            gracias a los certificados profesionales que aportaste. Si deseas
            habilitar otra disciplina deberás contactar a tu asesor ELEVVA y
            aportar los documentos que te acrediten como profesor de están nueva
            disciplina.
          </div>
        </>
      ) : (
        <>
          <div className="title">Disciplinas habilitadas</div>
          <div
            className="texto"
            style={{ padding: "20px", color: "#9DACBD", fontSize: "0.8rem" }}
          >
            Estas disciplinas son las que podrás dictar en la plataforma ELEVVA
            gracias a los certificados profesionales que aportaste. Si deseas
            habilitar otra disciplina deberás contactar a tu asesor ELEVVA y
            aportar los documentos que te acrediten como profesor de están nueva
            disciplina.
          </div>
        </>
      )}
      <div className="listaReservas mx-auto">
        <div className="row mx-auto">{renderDisciplina}</div>
      </div>
    </div>
  );
};

const InfoIngresos = (props) => {
  let history = useHistory();
  const user = props.user;
  const moment = extendMoment(Moment);
  const [data, setData] = useState(
    <TableRow>
      <TableCell align="center">
        <div className="form-text">Sin datos</div>
      </TableCell>
    </TableRow>
  );
  const [totalIngreso, setTotalIngreso] = useState(null);
  const [idcobrol, setidcobro] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [cobremelo, setcobremelo] = useState(false);

  useEffect(() => {
    instance
      .get(`trainers/account/income/`)

      .then((res) => {
        const info = res.data;
        const idCobro = info.hash;
        const summary = JSON.parse(res.data.summary);
        const total = res.data.total;
        console.log(summary);
        setTotalIngreso(total);

        if (info != null) {
          let bg_gray = true;
          setData(
            summary.map((value, index) => {
              const clase = value.description;
              const clase2 = value.description.split(":")[0].toLowerCase();
              const fecha = value.date;
              const strFecha = fecha;
              const ingreso = formatNumber(value.value);
              const to_do = value.booking_id;

              let style = {};
              if (bg_gray) {
                bg_gray = false;
              } else {
                bg_gray = true;
              }
              return (
                <Fragment key={index}>
                  <TableRow
                    className="table-row "
                    data-bs-placement="top"
                    title={to_do}
                    style={style}
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseItem${index}`}
                    aria-expanded="false"
                    aria-controls={`collapseItem${index}`}
                  >
                    <TableCell
                      component="th"
                      align="center"
                      scope="row"
                      className=""
                    >
                      <div className="itemSesionesTabla">
                        <div className="date">Fecha {strFecha} </div>
                        {/* <div
                          className="date"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {clase2.slice(0, -3)}{" "}
                        </div> */}
                      </div>
                    </TableCell>
                    {laptop575.matches ? (
                      <TableCell component="th" align="center" scope="row">
                        <div className="itemSesionesTabla">
                          <div className="date"> {clase}</div>
                        </div>
                      </TableCell>
                    ) : null}
                    <TableCell component="th" align="center" scope="row">
                      <div className="itemSesionesTabla">
                        <div className={`date ${to_do}`}>${ingreso}</div>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow style={style} className="">
                    <TableCell
                      colSpan="2"
                      id={`collapseItem${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                    >
                      <div className="accordion-body date ">{to_do}</div>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })
          );
        }
      })
      .catch((err) => {
        console.log("Error load_fondos_clases_a", err.response);
      });
  }, []);
  const header = (
    <TableRow
      className="headerTable"
      style={{ background: `#9EACED` }}
      // style={{ backgroundImage: `url(${bg_header_table})` }}
    >
      {laptop575.matches ? (
        <>
          {" "}
          <TableCell align="center">Sesión</TableCell>
          <TableCell align="center">Tipo de entrenamiento</TableCell>
          <TableCell align="center">Valor de sesión</TableCell>
        </>
      ) : (
        <>
          {" "}
          <TableCell align="center">Sesión</TableCell>
          <TableCell align="center">Valor de sesión</TableCell>
        </>
      )}
    </TableRow>
  );
  function confirmCobro() {
    setModalInfo(
      <ModalInfo
        error="none"
        handleClick={generarCobro}
        handleCancel={() => setModalInfo(null)}
        title="¿Está seguro de generar esta cuenta de cobro?"
        text="Recuerde que al generar la cuenta de cobro todas la clases que se liquiden pasarán automaticamente al proceso de cobro y no aparecerán más en la lista de clases por cobrar."
        classesMsg=""
      />
    );
  }
  function confirmCobroweb() {
    setcobremelo(true);
    setModalInfo(
      <ModalInfo
        error="none"
        handleClick={generarCobroweb}
        handleCancel={() => setModalInfo(null)}
        title="¿Está seguro de generar esta cuenta de cobro?"
        text="Recuerde que al generar la cuenta de cobro todas la clases que se liquiden pasarán automaticamente al proceso de cobro y no aparecerán más en la lista de clases por cobrar."
        classesMsg=""
      />
    );
  }

  function generarCobro() {
    setcobremelo(true);
    setModalInfo(
      <ModalInfo
        error="none"
        handleClick={() => setModalInfo(null)}
        title="Procesando"
        text={<Loadingspinner customStyle="border" size="5rem" />}
        classesMsg=""
      />
    );
    instance
      .post(`trainers/account/generate/`)

      .then((res) => {
        const daysselected = res.data;
        if (daysselected) {
          const idCobro = daysselected.hash;
          setModalInfo(<Redirect push={true} to={`/pdf/:${idCobro}`} />);
        }
      });
  }
  function generarCobroweb() {
    const contenedorinferior = document.getElementById("contenedorinferior");

    contenedorinferior.style.display = "none";
    instance
      .post(`trainers/account/generate/`)

      .then((res) => {
        const daysselected = res.data;
        if (daysselected) {
          const idCobro = daysselected.hash;
          setModalInfo(<VerCuenta cuentaweb={idCobro} />);
        }
      });
  }

  return laptop.matches ? (
    <>
      <div id="contenedorinferior">
        <div className=" tamañograndeingresos">
          {" "}
          <div className="texto my-3 acomodartextinresos">
            En ingresos podrás ver el estado de cuenta de tu gestión en ELEVVA y
            retirar tus ingresos. Ten presente que solo podrás retirar tus
            ingresos a mediados o finales de cada mes.
          </div>
          <div className="disciplinasE colordisciplinasE scrollturesumen">
            <div>
              <div className="ingresoscolor">
                <div>
                  <div className="totalIngresos ">
                    {" "}
                    <div
                      className="text-left"
                      style={{ color: "#394F65", fontSize: "1.4rem" }}
                    >
                      Total Ingresos
                    </div>
                    ${totalIngreso ? totalIngreso : "000"}
                  </div>
                  <div className="detail text-left">
                    Detalle de clases para esta cuenta de cobro
                  </div>
                </div>
                <div className="containerBtnGenerar" onClick={confirmCobroweb}>
                  <ButtonMob
                    disabled={totalIngreso ? false : true}
                    colorButton={"#CB3D82"}
                    className="BtnGenerar"
                    text="Generar cuenta de cobro"
                  />
                </div>
              </div>
              <div className="tablaIngresos">
                <MyTable data={data} header={header} />
              </div>
            </div>
          </div>
          <div className="w-100 py-1 d-flex" style={{ marginLeft: "16%" }}>
            <div style={{ width: "56%", backgroundColor: "#9EACED" }}></div>
            <div
              style={{
                color: "#394F65",
                fontWeight: "bold",
                fontSize: "2vw",
                margin: "0 1vw",
              }}
            >
              ${totalIngreso ? totalIngreso : "000"}
            </div>
          </div>
          <div className="w-100 py-1 d-flex" style={{ marginLeft: "16%" }}>
            <div style={{ width: "55%" }}></div>
            <div
              className="containerBtnGenerar"
              onClick={confirmCobroweb}
              style={{ width: "17vw", display: "flex" }}
            >
              <ButtonMob
                disabled={totalIngreso ? false : true}
                colorButton={"#CB3D82"}
                className="BtnGenerar py-3"
                text="Generar cuenta de cobro"
              />
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="colordisciplinasE" style={{ padding: "0 10vw" }}>
        {" "}
        {modalInfo}
      </div>
    </>
  ) : (
    <>
      {" "}
      <div className="disciplinasE">
        <div className="title">Ingresos</div>{" "}
        <div className="texto">
          En ingresos podrás ver el estado de cuenta de tu gestión en ELEVVA y
          retirar tus ingresos. Ten presente que solo podrás retirar tus
          ingresos a mediados o finales de cada mes.
        </div>
        <div className="title">Total Ingresos</div>
        <div className="totalIngresos"> ${totalIngreso}</div>
        <div className="containerBtnGenerar " onClick={confirmCobro}>
          <ButtonMob
            colorButton={"#CB3D82"}
            className="BtnGenerar "
            text="Generar cuenta de cobro"
          />
        </div>
        <div className="detail">
          Detalle de clases para esta cuenta de cobro
        </div>
        <div className="tablaIngresosweb">
          <MyTable data={data} header={header} />
        </div>
        {modalInfo}
      </div>
    </>
  );
};

const InfoPersonal = (props) => {
  const user = props.user;
  const [confirm, setModalConfirm] = useState(null);
  const formRef = useRef(null);
  const [previewPhotoProfile, setPreviewPhotoProfile] = useState(camera);
  const [photoProfile, setPhotoProfile] = useState(null);
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [displayCorrect, setDisplayCorrect] = useState(null);

  const changePhoto = (event) => {
    const photo = event.target.files[0];
    setPhotoProfile(photo);
    setPreviewPhotoProfile(URL.createObjectURL(photo));
  };

  useEffect(() => {
    if (photoProfile != null) {
      instance.get(`${NuevaAxiosURL}users/`).then((res) => {
        var formData = new FormData();
        formData.append("profile_pic", photoProfile);

        instance.patch(`trainers/`, formData).then((res) => {
          if (res.data) {
            window.location.reload();
          }
        });
      });
    }

    return () => {};
  }, [photoProfile]);

  // console.log("----phpy", previewPhotoProfile);
  useEffect(() => {
    instance
      .get(`${NuevaAxiosURL}users/`)
      .then((res) => {
        const info = res.data;

        laptop575.matches
          ? (formRef.current.children[1].children[0].children[0].children.namedItem(
              "nombres"
            ).value = info.first_name)
          : (formRef.current.children.namedItem("nombres").value =
              info.first_name);

        laptop575.matches
          ? (formRef.current.children[1].children[0].children[1].children.namedItem(
              "apellidos"
            ).value = info.last_name)
          : (formRef.current.children.namedItem("apellidos").value =
              info.last_name);
        laptop575.matches
          ? (formRef.current.children[1].children[0].children[2].children.namedItem(
              "email"
            ).value = info.email)
          : (formRef.current.children.namedItem("email").value = info.email);

        {
          laptop575.matches
            ? (formRef.current.children[1].children[1].children[0].children.namedItem(
                "direccion"
              ).value = info.address)
            : (formRef.current.children.namedItem("direccion").value =
                info.address);
        }

        setPreviewPhotoProfile(
          info.trainer ? info.trainer.profile_pic : user.img
        );
        {
          laptop575.matches
            ? (formRef.current.children[1].children[1].children[1].children[0].children.namedItem(
                "telefono"
              ).value = info.mobile_number)
            : (formRef.current.children.namedItem("telefono").value =
                info.mobile_number);
        }
      })
      .catch((err) => {
        console.log("Error load_lesiones_data_a", err);
      });
    return () => {};
  }, []);
  const labelInput = (
    name,
    labelClasses,
    label,
    inputClasses,
    placeholder,
    type,
    functionChange,
    disabled = false
  ) => {
    return (
      <>
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
        <input
          type={type}
          name={name}
          id={name}
          className={inputClasses}
          placeholder={placeholder}
          onInput={functionChange}
          disabled={disabled}
        />
      </>
    );
  };
  function modalactualizar() {
    setModalConfirm(null);
  }

  function Change_contraseña(event) {
    let change1 = event.target[0].value;
    let change2 = event.target[1].value;

    var formData = new FormData();
    formData.append("password", change1.trim()); //pass1
    formData.append("password2", change2.trim()); //pass1

    instance
      .post(`users/auth/change-password/`, formData)
      .then((res) => {
        if (res.data) {
          setModalConfirm(null);
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          seterroraGregado(msg_error);

          setDisplayCorrect("inherit");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      });

    event.preventDefault();
  }
  useEffect(() => {
    if (displayCorrect) handleAtualizarcontraseña();
  }, [displayCorrect]);
  function handleAtualizarcontraseña() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalConfirm(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div className="icon my-3">
            <img
              src={candado}
              alt="cara"
              style={
                laptop575.matches ? null : { height: "3rem", width: "3rem" }
              }
            />
          </div>
          <div className="title mb-3 py-1">Ingresa tu nueva contraseña</div>
          <form
            onSubmit={(e) => {
              Change_contraseña(e);
            }}
          >
            {" "}
            <label
              className=" text-left"
              style={
                laptop575.matches
                  ? {
                      width: "80%",
                      fontSize: "1rem",
                      margin: "auto",
                    }
                  : { width: "70%", paddingTop: "1rem" }
              }
            >
              Nueva contraseña
            </label>
            <input
              style={
                laptop575.matches
                  ? {
                      width: "80%",
                      fontSize: "1rem",
                    }
                  : { width: "70%" }
              }
              name="tester"
              placeholder="**********"
              required
            />
            {ErroraGregado ? (
              <div
                id="passmail_error1"
                className=""
                style={
                  laptop575.matches
                    ? {
                        display: `${displayCorrect}`,
                        color: "red",
                        fontSize: "0.8rem",
                        margin: "0 4rem",
                      }
                    : {
                        display: `${displayCorrect}`,
                        color: "red",
                        fontSize: "0.6rem",
                        margin: "0 2rem",
                        maxHeight: "2rem",
                      }
                }
              >
                {ErroraGregado.password}
              </div>
            ) : null}
            <label
              className=" text-left"
              style={
                laptop575.matches
                  ? {
                      width: "80%",
                      fontSize: "1rem",
                      margin: "auto",
                    }
                  : { width: "70%" }
              }
            >
              Confirmar nueva contraseña
            </label>
            <input
              style={
                laptop575.matches
                  ? {
                      width: "80%",
                      fontSize: "1rem",
                    }
                  : { marginBottom: "0vh", width: "70%" }
              }
              name="tester"
              placeholder="**********"
              required
            />
            <div
              id="passmail_error2"
              className=""
              style={
                laptop575.matches
                  ? {
                      display: `${displayCorrect}`,
                      color: "red",
                      fontSize: "0.8rem",
                      margin: "0 4rem",
                    }
                  : {
                      display: `${displayCorrect}`,
                      color: "red",
                      fontSize: "0.6rem",
                      margin: "0 2rem",
                      maxHeight: "2rem",
                    }
              }
            >
              {ErroraGregado ? ErroraGregado.password : null}
            </div>
            <div className="col-12">
              <div
                className="col-6 btnAction_pruebas letraspopfi1"
                onClick={modalactualizar}
              >
                <ButtonMob
                  text="Cancelar"
                  className={
                    laptop575.matches
                      ? "mt-3 mb-3 btn-danger letraspopfi2actualizar"
                      : " btn-danger letraspopfi2actualizar py-2"
                  }
                />
              </div>
              <div className="col-6 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"#9EACED"}
                  text="Confirmar"
                  className={
                    laptop575.matches
                      ? "mb-3 mt-3 letraspopfi2actualizar"
                      : " letraspopfi2actualizar py-2"
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  const labelInputweb = (
    name,
    labelClasses,
    label,
    inputClasses,
    placeholder,
    type,
    functionChange,
    disabled = false
  ) => {
    return (
      <div className="d-row containerweb my-2 mx-2">
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
        <input
          type={type}
          name={name}
          id={name}
          className={inputClasses}
          placeholder={placeholder}
          onInput={functionChange}
          disabled={disabled}
        />
      </div>
    );
  };

  return laptop575.matches ? (
    <>
      <div className="perfil ">
        <div ref={formRef} className="formSteps mx-auto ">
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              top: "0%",
              left: "-5%",
            }}
          >
            <img
              src={iconCam}
              alt="info"
              className="btnInfoEntre position-absolute"
              htmlFor="photo"
            />
            <input
              type="file"
              accept="image/*"
              className="photoProfile"
              name="photo"
              id="photo"
              onChange={changePhoto}
            />
            <div>
              <div className="contetfototoal">
                <label
                  htmlFor="photo"
                  className="labelPhotoentre rounded-circle"
                  style={{
                    backgroundImage: `url(${previewPhotoProfile})`,
                    backgroundSize: "100%",
                  }}
                ></label>
              </div>
            </div>
          </div>
          <div className="d-row " style={{ padding: "2.5vh 0", width: "70%" }}>
            <div className="d-flex ">
              {labelInputweb(
                "nombres",
                "d-flex",
                "Nombres",
                "form-control cuadroborder  my-2 colornuevotrainerblack",
                "Jhon",
                "text",
                null,
                true
              )}
              {labelInputweb(
                "apellidos",
                "d-flex",
                "Apellidos",
                "form-control cuadroborder my-2 colornuevotrainerblack",
                "Jhon",
                "text",
                null,
                true
              )}
              {labelInputweb(
                "email",
                "d-flex",
                "Correo Electrónico",
                "form-control cuadroborder my-2 colornuevotrainerblack",
                "correo@electronico.com",
                "email",
                null,
                true
              )}
            </div>
            <div className="d-flex">
              {labelInputweb(
                "direccion",
                "d-flex  ",
                "Dirección",
                "form-control cuadroborder widthdireccion my-2 colornuevotrainerblack ",
                "xx xx xx",
                "text",
                null,
                true
              )}
              <div className="containerweb7">
                {labelInputweb(
                  "telefono",
                  "d-flex col-4 ",
                  "Teléfono",
                  "form-control cuadroborder widthtelefono my-2 colornuevotrainerblack ",
                  "xx xx xx",
                  "number",
                  null,
                  true
                )}
              </div>
            </div>
            <div className="UpdateProfile" style={{ minHeight: "0px" }}>
              <button
                className="btnActualizar "
                onClick={handleAtualizarcontraseña}
              >
                Cambiar contraseña
              </button>
              {confirm}
            </div>
          </div>
        </div>
      </div>
      <div className="advertencia mx-auto d-flex">
        <div className="imagen rounded-circle">
          <img src={inf} alt="Info" />
        </div>
        <div className="texto">
          Ten en cuenta que lo único que podrás modificar de tu perfil es la
          fotografía. Para realizar los cambios que desees, deberás comunicarte
          con nuestras líneas de atención ELEVVA.
          <div className="tel">3022375073</div>
        </div>
      </div>
    </>
  ) : (
    <div className="perfil">
      <div className="titulo">Información Personal</div>
      <div ref={formRef} className="formSteps mx-auto">
        <div
          className="d-flex justify-content-center"
          style={{
            position: "relative",
            top: "0%",
            left: "0%",
          }}
        >
          <img
            src={iconCam}
            alt="info"
            className="btnInfo position-absolute"
            htmlFor="photo"
          />
          <input
            type="file"
            accept="image/*"
            className="photoProfile"
            name="photo"
            id="photo"
            onChange={changePhoto}
          />
          <label
            htmlFor="photo"
            className="labelPhoto rounded-circle"
            style={{
              backgroundImage: `url(${previewPhotoProfile})`,
              backgroundSize: "100%",
            }}
          ></label>
        </div>

        {labelInput(
          "nombres",
          "d-flex",
          "Nombres",
          "form-control cuadroborder colornuevotrainerblack",
          "Jhon",
          "text",
          null,
          true
        )}
        {labelInput(
          "apellidos",
          "d-flex",
          "Apellidos",
          "form-control cuadroborder colornuevotrainerblack",
          "Jhon",
          "text",
          null,
          true
        )}
        {labelInput(
          "email",
          "d-flex",
          "Correo Electrónico",
          "form-control cuadroborder colornuevotrainerblack",
          "correo@electronico.com",
          "email",
          null,
          true
        )}

        {labelInput(
          "direccion",
          "d-flex",
          "Dirección",
          "form-control cuadroborder colornuevotrainerblack",
          "xx xx xx",
          "text",
          null,
          true
        )}
        {labelInput(
          "telefono",
          "d-flex",
          "Teléfono",
          "form-control cuadroborder colornuevotrainerblack",
          "xx xx xx",
          "text",
          null
        )}
      </div>
      <div className="UpdateProfile" style={{ minHeight: "0px" }}>
        <button
          className="btnActualizar "
          onClick={handleAtualizarcontraseña}
          style={{ maxWidth: "18rem" }}
        >
          Cambiar contraseña
        </button>
        {confirm}
      </div>
      <div className="advertencia mx-auto">
        <div className="imagen rounded-circle">
          <img src={inf} alt="Info" />
        </div>
        <div className="texto">
          Ten en cuenta que lo único que podrás modificar de tu perfil es la
          fotografía. Para realizar los cambios que desees, deberás comunicarte
          con nuestras líneas de atención ELEVVA.
        </div>
        <div className="tel">3022375073</div>
      </div>
    </div>
  );
};

const Cabecera = (props) => {
  let { option } = useParams();
  let history = useHistory();
  const selOption = props.selOption;
  const [optActive, setActive] = useState(null);

  useEffect(() => {
    if (optActive == null) {
      if (option == "sesiones") {
        setActive(1);
      } else if (option == "disciplinas") {
        setActive(2);
      } else if (option == "ingresos") {
        setActive(3);
      } else if (option == "comentarios") {
        setActive(4);
      } else {
        setActive(0);
      }
    } else {
      props.onClick(selOption[optActive][2]);
    }
  }, [optActive]);

  function changeActive(index) {
    setActive(index);
    const opt = selOption[index][2];
    props.onClick(opt);
    history.push(`/${entrenadorType}/${opt}`);
  }

  return (
    <>
      <div className="cabeceraPerfil">
        {laptop575.matches ? (
          <>
            <div className="bievenidoainicio" style={{ marginLeft: "12.5vw" }}>
              <div className=" col-12 d-flex " style={{ marginTop: "3vh" }}>
                <div className="SubTitle8perfilcual  ">Bienvenido a</div>
                <div className="lineainiciousuario "></div>
              </div>
              <div className="titlec text-left col-12">Tu perfil</div>
              <div
                className="SubTitle8perfilcual text-left col-12"
                style={{ width: "100%" }}
              >
                Revisa tu perfil donde estarás informado de todas las novedades.
              </div>
            </div>
            <div className="Iconos ">
              {selOption.map((el, idx) => {
                const active = optActive == idx ? "active" : "";
                return (
                  <span
                    className={`icono letrascolorchange  ${active} `}
                    key={idx}
                    onClick={() => changeActive(idx)}
                  >
                    {" "}
                    <div className={`bordealo ${active} py-3 `}>{el[0]}</div>
                  </span>
                );
              })}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="title">Tu perfil</div>
            <div className="Iconos mx-auto">
              {selOption.map((el, idx) => {
                const active = optActive == idx ? "active" : "";
                return (
                  <span
                    className={`icono rounded-circle ${active}`}
                    key={idx}
                    onClick={() => changeActive(idx)}
                  >
                    <img src={el[1]} alt={el[0]} />
                  </span>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PerfilEntrenador;
