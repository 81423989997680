import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Pagination } from "swiper";

import "swiper/components/pagination/pagination.min.css";
import { Redirect, useHistory } from "react-router-dom";
import { ListaEntrenamientos } from "./listaentrenamientos.js";
import Filtrars from "../Filtrar/Filtrar.js";
import imgarriba1 from "../assets/img/Auth_images/arribaimagen.png";
import imgabajo1 from "../assets/img/Auth_images/abajoimagen.png";
import stargolden from "../assets/img/reservasimages/Grupo 6457.png";
import { isIOS } from "react-device-detect";
import devolution from "../assets/img/reservasimages/devolution.png";
import imagenBoom from "../assets/img/BANNER-POP-UP.jpg";
import {
  useComparecal1,
  useComparecal,
  useCompareduracion,
  useCompareduracion1,
  useCompareIn,
  useCompareIn1,
} from "../Filtrar/hooks";
import {
  Loadingspinner,
  ModalInfo,
  ModalInfo2,
  userObj,
  instance,
  basename,
  userType,
  laptop575,
} from "../utils/utils";
import "./Reservas.css";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import reloj from "../assets/img/reservasimages/reloj.png";
import pepas from "../assets/img/reservasimages/pepas.png";
import flechas from "../assets/img/reservasimages/flechas.png";
import corazon from "../assets/img/reservasimages/corazon.png";
import info from "../assets/img/reservasimages/info.png";
import search from "../Entrenador/assets/icons/search.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import lineorder from "../assets/img/reservasimages/lineorder.png";
import noHay from "../assets/img/reservasimages/noHay.svg";
import { Allvideos } from "./videoselevva";

SwiperCore.use([Pagination]);
const Reservas = (props) => {
  const user = userObj();
  const searchText = useRef(null);
  const Filtrar = "/" + user.type + "/reservas/Filtrar";
  const [bullets, setBullets] = useState([]);
  const [entrenamientos, setEntrenamientos] = useState(null);
  const [copyAllEntrenamientos, setCopyAllEntrenamientos] = useState(null);
  const [renderEntrenamiento, setRenderEntrenamiento] = useState(
    <Loadingspinner customStyle="border" size="3rem" />
  );
  const [entrenamientos4, setEntrenamientos4] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [entrenamientos5, setEntrenamientos5] = useState(null);
  const [entrenamientos9, setEntrenamientos9] = useState([]);
  function hideModal() {
    setModalInfo(null);
  }
  // useEffect(() => {
  //   if (!window.location.pathname.includes("only")) vercred();
  // }, []);
  function vercred() {
    setModalInfo(
      <ModalInfo
        imagenBoom={imagenBoom}
        handleClicktraining={() =>
          hideModal() || history.push(`/${userType}/reservas/only/23`)
        }
        handleClicksalsa={() =>
          hideModal() || history.push(`/${userType}/reservas/only/24`)
        }
        handleClickpilates={() =>
          hideModal() || history.push(`/${userType}/reservas/only/25`)
        }
        error="none"
        handleCancel={hideModal}
        reservaGrupalPromocion={true}
        classesMsg=""
      />
    );
  }

  const sw = window.screen.width;
  const [entrenamientos1, setEntrenamientos1] = useState(null);
  const [valuesearch, setValuesearch] = useState(null);
  let timerSearch = null;

  function moreInfo1(msg, title) {
    setModalInfo(
      <ModalInfo2
        error="none"
        display="flex"
        handleClick={hideModal}
        title={"Ordenar por"}
        text={
          <>
            <h6>Selecciona una categoria</h6>
            <div className="d-row">
              <div>
                <button
                  name="byProps"
                  onClick={(e) => sortPkmns(e, useComparecal)}
                  className="buttonsorder"
                >
                  Calorías:De menor a mayor
                </button>
              </div>
              <img src={lineorder} className="lineorder" />
            </div>
            <div className="d-row">
              <div>
                <button
                  name="byProps"
                  className="buttonsorder"
                  onClick={(e) => sortPkmns(e, useComparecal1)}
                >
                  Calorías:De mayor a menor
                </button>
              </div>
              <img src={lineorder} className="lineorder" />
            </div>
            <div className="d-row">
              <div>
                <button
                  name="byProps"
                  className="buttonsorder"
                  onClick={(e) => sortPkmns(e, useCompareduracion)}
                >
                  Tiempo:De menor a mayor
                </button>
              </div>
              <img src={lineorder} className="lineorder" />
            </div>
            <div className="d-row">
              <div>
                <button
                  name="byProps"
                  className="buttonsorder"
                  onClick={(e) => sortPkmns(e, useCompareduracion1)}
                >
                  Tiempo:De mayor a menor
                </button>
                <div className="d-row"></div>
                <img src={lineorder} className="lineorder" />
                <div className="d-row"></div>
                <div className="d-row">
                  <div>
                    <button
                      name="byProps"
                      className="buttonsorder"
                      onClick={(e) => sortPkmns(e, useCompareIn)}
                    >
                      Intensidad:De menor a mayor
                    </button>
                  </div>
                  <img src={lineorder} className="lineorder" />
                </div>

                <button
                  name="byProps"
                  className="buttonsorder"
                  onClick={(e) => sortPkmns(e, useCompareIn1)}
                >
                  Intensidad:De mayor a menor
                </button>
              </div>
            </div>
          </>
        }
        classesMsg="modalMsgReserva"
      />
    );
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   *
   * @param {String} property Key of the object to sort.
   */
  function dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }
  function searchinensidad(intensity, group, cost) {
    if (cost < 1) {
      if (intensity == 10 && group == false) {
        return "Entrenamiento Baja Intensidad";
      } else if (intensity == 20 && group == false) {
        return "Entrenamiento Media Intensidad";
      } else if (intensity == 30 && group == false) {
        return "Entrenamiento Alta Intensidad";
      } else return "Entrenamiento grupales";
    } else {
      if (intensity == 10 && group == false) {
        return "Entrenamiento Baja Intensidad";
      } else if (intensity == 20 && group == false) {
        return "Entrenamiento Media Intensidad";
      } else if (intensity == 30 && group == false) {
        return "Entrenamiento Alta Intensidad";
      } else return "Entrenamiento Grupales";
    }
  }
  useEffect(() => {
    const copyEntrenamientos = [];
    const copyBullets = bullets;
    const getData = async () => {
      await instance.get("trainings/").then(async (res) => {
        const data = res.data;

        for (const i of data) {
          const identrenamiento = i.id;
          const img = i.cover_picture; //.match(/(img[\d]*b.png)/)[0]
          copyBullets[identrenamiento] = {
            img: img,
            title: i.name,
          };

          if (data != null) {
            copyEntrenamientos[identrenamiento] = {
              id: identrenamiento,
              titulo: i.name.toLowerCase(),
              descripcion: i.description,
              creditosClase: i.credits,
              img: i.cover_picture,
              rawImg: i.main_picture,
              categories: i.categories
                .filter((e) => e)
                .map((e) =>
                  e == 10
                    ? "Fuerza"
                    : e == 20
                    ? "Resistencia"
                    : e == 30
                    ? "Flexibilidad"
                    : e == 40
                    ? "Coordinación"
                    : e == 50
                    ? "Cardiovascular"
                    : null
                ),
              icon: i.icon,
              Individual: i.group == false ? "Individual" : "Grupal",
              Lugar: i.where.map((info) =>
                info.where == 10
                  ? "Casa"
                  : info.where == 20
                  ? "Gimnasio"
                  : info.where == 20
                  ? "Exteriores"
                  : ""
              ),
              grupales: i.group,
              intensidadMsgGrupal:
                i.intensity == 10
                  ? "Entrenamiento Baja Intensidad"
                  : i.intensity == 20
                  ? "Entrenamiento Media Intensidad"
                  : "Entrenamiento Alta Intensidad",
              intensidadMsg: searchinensidad(i.intensity, i.group, i.credits),
              duracion: i.time,
              calorias: i.calories,
            };
          }
        }
      });

      setBullets(copyBullets);
      setCopyAllEntrenamientos(copyEntrenamientos);
      setEntrenamientos(copyEntrenamientos);
      setEntrenamientos5(copyEntrenamientos);
    };
    getData();
  }, []);

  useEffect(() => {
    if (valuesearch != null) {
      setRenderEntrenamiento(
        <Loadingspinner customStyle="border" size="3rem" />
      );
      timerSearch = setTimeout(() => {
        if (valuesearch == " ") {
          setEntrenamientos(copyAllEntrenamientos);
        } else {
          const newEntrenamientos = copyAllEntrenamientos
            .filter((e) => e)
            .filter((value) => {
              const toSearch = valuesearch.toLowerCase();
              const enTitulo = value.titulo.toLowerCase().includes(toSearch);
              const enDescripcion = value.descripcion
                .toLowerCase()
                .includes(toSearch);
              const enIntensidad = value.intensidadMsg
                .toLowerCase()
                .replace("entrenamiento ", "")
                .includes(toSearch);

              const enCategoria = value.intensidadMsgGrupal
                .toLowerCase()
                .replace("entrenamiento ", "")
                .includes(toSearch);

              return enTitulo || enDescripcion || enIntensidad || enCategoria;
            });

          if (newEntrenamientos.length > 0) {
            setEntrenamientos(newEntrenamientos);
          } else {
            setRenderEntrenamiento(
              <div className="noDisciplian mt-3">
                <img src={noHay} alt="no se encontro nada" />
                <div className="containerReservas_lo_sentimos">
                  Lo sentimos, no hay disciplinas disponibles con este criterio.
                  <br />
                  <br />
                  Prueba otra busqueda.
                </div>
              </div>
            );
          }
        }
      }, 500);
    }
    return () => {
      clearTimeout(timerSearch);
    };
  }, [valuesearch]);
  const history = useHistory();

  const sortPkmns = (e, hook) => {
    const name = e.target.name;
    if (name === "byProps") {
      let aqui = entrenamientos.filter((e) => e).sort(hook);
      setEntrenamientos1([...aqui]);
      setEntrenamientos([...aqui]);
      setCopyAllEntrenamientos([...aqui]);
      hideModal();
    }
  };

  useEffect(() => {
    if (entrenamientos != null) {
      setRenderEntrenamiento(() => {
        const sliderNoSort = [];

        for (const item of entrenamientos) {
          if (item == null) continue;

          const cat = item.intensidadMsg;
          const imgCover = item.img;
          const bg_img = item.rawImg;
          const icon = item.icon;
          const name = item.titulo;

          if (sliderNoSort[cat] == undefined) sliderNoSort[cat] = [];
          sliderNoSort[cat].push(
            <SwiperSlide
              key={name}
              onClick={() =>
                history.push(`/${userType}/reservas/only/${item.id}`)
              }
              className="sliderNetFlix"
              style={{
                backgroundImage: `url(${
                  laptop575.matches ? imgCover : bg_img
                })`,
              }}
            >
              <div
                className="content"
                style={
                  laptop575.matches
                    ? {
                        width: "69%",
                        height: "100%",
                      }
                    : {
                        width: "92%",
                      }
                }
              >
                <img className="iconNetflix" src={icon} alt={name} />
                <br />

                {name.length > 30 ? name.slice(0, 35) + "..." : name}
              </div>
            </SwiperSlide>
          );
        }

        const sliders = [];
        sliders["Entrenamientos grupales"] =
          sliderNoSort["Entrenamiento grupales"] ??
          sliderNoSort["Entrenamiento grupales"];
        sliders["Entrenamientos Grupales"] =
          sliderNoSort["Entrenamiento Grupales"] ??
          sliderNoSort["Entrenamiento Grupales"];
        sliders["Entrenamientos Según Tu Meta"] =
          sliderNoSort["Entrenamiento Baja Intensidad"] ??
          sliderNoSort["Entrenamiento Baja Intensidad"];
        sliders["Entrenamientos Intensidad Media & Baja"] =
          sliderNoSort["Entrenamiento Media Intensidad"] ??
          sliderNoSort["Entrenamiento Media Intensidad"];
        sliders["Entrenamientos Intensidad Alta"] =
          sliderNoSort["Entrenamiento Alta Intensidad"] ??
          sliderNoSort["Entrenamiento Alta Intensidad"];

        const render = [];
        let maxSliders = 1;
        if (sw < 481) {
          maxSliders = 3;
        } else if (sw >= 481 && sw < 1400) {
          maxSliders = 6;
        } else {
          maxSliders = 7;
        }
        render.push(
          <>
            {" "}
            {/* <div className="titleCategory mb-3">
              Videos mas vistos{" "}
              <Link to="/ejercicios">
                <span
                  style={
                    laptop575.matches
                      ? {
                          color: "#CB3D82",
                          fontStyle: "italic",
                          fontSize: "1.5vw",
                        }
                      : {
                          color: "#CB3D82",
                          fontStyle: "italic",
                          fontSize: "5vw",
                        }
                  }
                >
                  VER TODOS
                  <FontAwesomeIcon
                    style={
                      laptop575.matches
                        ? {
                            borderRadius: "100%",
                            backgroundColor: "#CB3D82",
                            padding: "5px",
                            width: "2.5vmin",
                            marginLeft: "1vmin",
                            height: "2.5vmin",
                            verticalAlign: "middle",
                          }
                        : {
                            borderRadius: "100%",
                            backgroundColor: "#CB3D82",
                            padding: "2.5px",
                            width: "4vmin",
                            marginLeft: "1vmin",
                            height: "4vmin",
                            verticalAlign: "middle",
                          }
                    }
                    size="2x"
                    icon={faPlus}
                    color="#ffff"
                  />
                </span>{" "}
              </Link>
            </div> */}
            {/* <div style={{ width: "100%" }}>
              <Allvideos />
            </div> */}
          </>
        );
        for (const category in sliders) {
          if (Object.hasOwnProperty.call(sliders, category)) {
            // console.log("category", category);
            const element = sliders[category];
            // console.log("element", element);
            if (!element) continue;
            render.push(
              <div key={category}>
                <div className="titleCategory mb-3">
                  {category == "Entrenamientos grupales" ? (
                    <div className={laptop575.matches ? "" : "mt-3"}>
                      <span style={{ color: "#394F65" }}>{category}</span>
                      <span
                        style={
                          laptop575.matches
                            ? {
                                color: "#CB3D82",
                                fontStyle: "italic",
                                fontSize: "1.5vw",
                              }
                            : {
                                color: "#CB3D82",
                                fontStyle: "italic",
                                fontSize: "5vw",
                              }
                        }
                      >
                        GRATIS
                      </span>
                    </div>
                  ) : (
                    <span style={{ color: "#394F65" }}>{category}</span>
                  )}
                </div>
                <Swiper
                  className="slidersNetflixReservas  pt-2 pb-4 "
                  spaceBetween={10}
                  slidesPerView="auto"
                  slidesOffsetBefore={10}
                  slidesOffsetAfter={30}
                  slidesPerGroup={1}
                  slidesPerGroupSkip={1}
                  // loop={element.length > maxSliders + 2 ? false : true}
                  loop={element.length < maxSliders + 2 ? false : true}
                  onUpdate={(swiper) => swiper.updateSlides()}
                >
                  {element}
                </Swiper>
              </div>
            );
          }
        }
        return (
          <>
            <div className="scrollslidernetflix">{render}</div>
          </>
        );
      });
    }

    return () => {};
  }, [entrenamientos]);

  if (!user.code) {
    return <Redirect to="/ingreso" />;
  }

  function zoomfiltro() {
    const magia = document.querySelector(".modalInfofilling");
    if (magia) {
      magia.style = "display:flex ";
    }
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };

    setModalInfo(
      <div
        className="modalInfofilling "
        id="modalInfofilling "
        style={styleModal}
      >
        <div className="modalInfoContenido MODALHEIGHFILT">
          <Filtrars
            entrenamientos9={entrenamientos9}
            entrenamientos5={entrenamientos5}
            entrenamientos2={entrenamientos}
            onChange={({ entrenamientos2, entrenamientos9 }) =>
              setEntrenamientos([...entrenamientos2]) ||
              setCopyAllEntrenamientos([...entrenamientos2]) ||
              setEntrenamientos4([...entrenamientos2]) ||
              setEntrenamientos9(entrenamientos9)
            }
          />
        </div>
      </div>
    );
  }
  const renderPrincipal = (
    <>
      <img className="imgarribareservas" src={imgarriba1} />
      <img className="imgabajoreservas" src={imgabajo1} />
      <div className="containerReservas">
        <>
          <div className="title text-center reservatitulo">
            Selecciona tu disciplina
            <br />
            de interes
          </div>
          {laptop575.matches ? (
            <div className=" " style={{ marginTop: "1vh", width: "100vw" }}>
              <div className="d-flex ">
                <div
                  className="SubTitle8perfilcual corrgircoso"
                  style={{
                    left: "0",
                    width: "6%",
                    marginLeft: "0",
                    color: "#9DACBD",
                  }}
                >
                  Reservas
                </div>
                <div className="lineainiciousuario corrgircoso1"></div>
              </div>
              <div className="title tituloreservartuclase ">
                Selecciona tu disciplina
              </div>
              <div className="textodebajoreservas tituloreservartuclasedebajo">
                En ELEVVA eres dueño de tu tiempo, agenda tu entrenamiento en
                los días y horario de tu preferencia
              </div>
            </div>
          ) : null}
          <div className="searchEjercicio mx-auto my-3 cajacontrol">
            <input
              ref={searchText}
              type="search"
              className="input_search coregir_resr"
              onChange={(e) => setValuesearch(e.target.value)}
            />
            <img src={search} alt="search icon" className="search_icon" />
            <div className="d-flex  p-auto">
              <div className="filtrarreservasmobile">
                <Link to={Filtrar}>
                  <button className="filters filtersweb">
                    <div className="btnfiltrarW">
                      <img src={pepas} className="imgfiltrarWf" />
                      <div className="letrafiltrarW">Filtrar</div>
                    </div>
                  </button>
                </Link>
              </div>
              <div className="filtrarreservasweb">
                <button className="filters filtersweb" onClick={zoomfiltro}>
                  <div className="btnfiltrarW">
                    <img src={pepas} className="imgfiltrarWf" />
                    <div className="letrafiltrarW">Filtrar</div>
                  </div>
                </button>
              </div>
              <button
                name="byProps"
                onClick={moreInfo1}
                className="filters filtersweb"
              >
                <div className="btnfiltrarW">
                  <img src={flechas} className="imgfiltrarWo" />
                  <div className="letrafiltrarW">Ordenar</div>
                </div>
              </button>
            </div>
          </div>

          <div className={isIOS ? "debajosearchapple" : "debajosearchandroid"}>
            {(entrenamientos1 &&
              entrenamientos9 &&
              entrenamientos9.length == 0) ||
            (entrenamientos4 && entrenamientos9 && entrenamientos9 === 3) ? (
              <ListaEntrenamientos entrenamientos={entrenamientos} />
            ) : (
              renderEntrenamiento
            )}
          </div>
        </>
      </div>
    </>
  );

  return (
    <>
      {modalInfo}
      {window.location.pathname === `${basename}/Usuario/reservas/Filtrar` ? (
        <Filtrars
          entrenamientos9={entrenamientos9}
          entrenamientos5={entrenamientos5}
          entrenamientos2={entrenamientos}
          onChange={({ entrenamientos2, entrenamientos9 }) =>
            setEntrenamientos([...entrenamientos2]) ||
            setCopyAllEntrenamientos([...entrenamientos2]) ||
            setEntrenamientos4([...entrenamientos2]) ||
            setEntrenamientos9(entrenamientos9)
          }
        />
      ) : (
        renderPrincipal
      )}
    </>
  );
};

export const ContaineReservaInfo = (props) => {
  const intensidadImg = props.intensidadImg;
  const bg_img = props.bg_img;
  const history = props.history;
  const creditosClase = props.creditosClase;
  const titulo = props.titulo;
  const intensidadMsg = props.intensidadMsg;
  const intensidadMsgGrupal = props.intensidadMsgGrupal;
  const duracion = props.duracion;
  const calorias = props.calorias;
  const grupales = props.grupales;
  const description = props.description;
  const icon = props.icon;

  function moreInfo(desc, titulo, icon) {
    props.moreInfo(desc, titulo, icon);
  }
  const [Modal, setModal] = useState(null);
  const capitalize = (s) => {
    s = s.toLowerCase();
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  return (
    <>
      <div
        className="Entrenamiento"
        style={{ backgroundImage: `url(${bg_img})` }}
      >
        <div id="coverfyoyc" className="bg_cover"></div>
        {laptop575.matches ? (
          <div
            style={{
              position: "relative",
              top: "40%",
              left: "3%",
            }}
            onClick={() => history.push(`/${userType}/reservas`)}
          >
            <img
              style={{
                cursor: "pointer",
              }}
              src={devolution}
              width={60}
            />
          </div>
        ) : null}

        <div className="Cabecera">
          {grupales == true ? "Clase grupal" : "Entrenamiento"}
          <br />
          <span className="container_name">
            {titulo}

            <br />

            {grupales == true && creditosClase == 0 ? "GRATIS" : null}
          </span>
        </div>
      </div>
      <div className="informacion d-flex ">
        <div className="step intensidad">
          <div>
            <img src={intensidadImg} alt={intensidadMsg} />
            <br />
            {grupales == true
              ? intensidadMsgGrupal.slice(14)
              : intensidadMsg.slice(14)}
          </div>
        </div>
        <div className="step duracion">
          <div>
            <img src={reloj} alt="Duración" />
            <br />
            <span>{duracion}</span>Min
            <br />
            Duración
          </div>
        </div>
        <div className="step calorias">
          <div>
            <img src={corazon} alt="Calorias" />
            <br />
            <div>{calorias}</div>Cal. promedio
          </div>
        </div>
        <div
          className="step more "
          onClick={() => moreInfo(description, capitalize(titulo), icon)}
        >
          <div>
            <img src={info} alt="Informacion" />
            <br />
            Información
          </div>
        </div>
      </div>
      {Modal}
    </>
  );
};

export default Reservas;
