import { UPDATE_DATA_CUSTOMER } from "../types";

const initialState = {
  dataBookings: [],
};

export default function customerBooks(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA_CUSTOMER: {
      return {
        ...state,
        dataBookings: action.payload,
      };
    }

    default:
      return state;
  }
}
