import { SwiperSlide } from "swiper/react";
import React from "react";
import "./listaentrenamientos.css";
import { laptop575, userType } from "../utils/utils";
import noHay from "../assets/img/reservasimages/noHay.svg";
import { useHistory } from "react-router-dom";

export function ListaEntrenamientos(props) {
  const history = useHistory();

  const entrenamientos = props.entrenamientos;

  if (entrenamientos != null) {
    return (
      <div>
        <div className="slidersNetflixReservas2  holi">
          {entrenamientos.length > 0 ? (
            entrenamientos
              .filter((e) => e)
              .map((e, i) => (
                <SwiperSlide
                  key={e.id}
                  onClick={() =>
                    history.push(`/${userType}/reservas/only/${e.id}`)
                  }
                  className="sliderNetFlix2"
                  style={{
                    backgroundImage: `url(${
                      laptop575.matches ? e.img : e.rawImg
                    })`,
                  }}
                >
                  <div className="content">
                    <img className="iconNetflix2" src={e.icon} alt={e.name} />

                    <br />
                    {e.titulo.length > 30
                      ? e.titulo.slice(0, 35) + "..."
                      : e.titulo}
                  </div>
                </SwiperSlide>
              ))
          ) : (
            <div className="noDisciplian">
              <img src={noHay} alt="no se encontro nada" />
              <div className="title">
                Lo sentimos, no hay disciplinas disponibles con este criterio.
                <br />
                <br />
                Prueba otra busqueda.
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
}
