import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  checkIfLogged,
  ButtonMob,
  userObj,
  userType,
  laptop575,
  basename,
} from "../utils/utils";
import "./Filtrar.css";
import { MultiRangeSlider } from "./Multirange.js";
import { MultiRangeSliderCal } from "./Multirangecal.js";
import fuerzamano from "../Filtrar/fuerzamano.png";
import resi from "../Filtrar/resi.png";
import flexi from "../Filtrar/flexi.png";
import coor from "../Filtrar/coor.png";
import cardio from "../Filtrar/cardio.png";
import fuerzamano1 from "../Filtrar/fuerzamano1.png";
import resi1 from "../Filtrar/resi1.png";
import flexi1 from "../Filtrar/flexi1.png";
import coor1 from "../Filtrar/coor1.png";
import cardio1 from "../Filtrar/cardio1.png";
import flecha from "../Filtrar/flecha.png";
import basura from "../Filtrar/basura.png";
import check from "../Filtrar/check.png";
import baja from "../Filtrar/baja.png";
import media from "../Filtrar/media.png";
import alta from "../Filtrar/alta.png";
import baja1 from "../Filtrar/baja1.png";
import media1 from "../Filtrar/media1.png";
import alta1 from "../Filtrar/alta1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import casa from "../Filtrar/casa.png";
import pesa from "../Filtrar/pesa.png";
import arbol from "../Filtrar/arbol.png";
import casa1 from "../Filtrar/casa1.png";
import pesa1 from "../Filtrar/pesa1.png";
import arbol0 from "../Filtrar/arbol0.png";
import bandas from "../Filtrar/bandas.png";
import bandas1 from "../Filtrar/bandas1.png";
import mats from "../Filtrar/mats.png";
import mats1 from "../Filtrar/mats1.png";
import mancuer from "../Filtrar/mancuer.png";
import mancuer1 from "../Filtrar/mancuer1.png";
import body from "../Filtrar/body.png";
import body1 from "../Filtrar/body1.png";
import pesas from "../Filtrar/pesas.png";
import pesas1 from "../Filtrar/pesas1.png";
import chaleco from "../Filtrar/chaleco.png";
import chaleco1 from "../Filtrar/chaleco1.png";
import bandus from "../Filtrar/bandus.png";
import bandus1 from "../Filtrar/bandus1.png";

import Media from "react-media";

import "react-phone-number-input/style.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

export default function Filtrars({
  entrenamientos2,
  onChange,
  entrenamientos5,
  entrenamientos9,
}) {
  const history = useHistory();

  // const entrenamientos = entrenamientos2;
  const [Individual, setIndividual] = useState("Individual");
  const [decision, setdecision] = useState("NO");
  const [Modal, setModal] = useState(null);
  const [lugarafiltrar, setlugarafiltrar] = useState(null);
  const [nivelafiltrar, setnivelafiltrar] = useState(null);
  const [categoriafiltrar, setcategoriasafiltrar] = useState(null);
  const [equiposfiltrar, setequiposafiltrar] = useState(null);
  const [entrenamientos, setentrenamientos] = useState(entrenamientos2);

  const [mini, setmini] = useState(null);
  const [maxi, setmaxi] = useState(null);
  const [min, setmin] = useState(null);
  const [max, setmax] = useState(null);

  // console.log("desicion", decision);

  const nivelnuevo = nivelafiltrar ? nivelafiltrar.toLowerCase() : null;
  // console.log("nivelnuevo", nivelnuevo);
  const Ocultar = () => {
    setTimeout(() => {
      setModal(null);
    }, 50000);
  };

  // console.log("filtrar", entrenamientos);
  // if (entrenamientos.length === []) setentrenamientos(entrenamientos2);
  const lugar = [
    // titulo, img unselected, img selected, ref container, ref image
    ["Casa", casa, casa1, useRef(null), useRef(null)],
    ["Gimnasio", pesa, pesa1, useRef(null), useRef(null)],
    ["Exteriores", arbol, arbol0, useRef(null), useRef(null)],
  ];
  const [damelugar, setdamelugar] = useState(new Array(lugar.length));
  const Nivel = [
    // titulo, img unselected, img selected, ref container, ref image
    ["Baja", baja, baja1, useRef(null), useRef(null)],
    ["Media", media, media1, useRef(null), useRef(null)],
    ["Alta", alta, alta1, useRef(null), useRef(null)],
  ];
  const [dameNivel, setdameNivel] = useState(new Array(Nivel.length));
  // console.log("damelugar", damelugar);
  // console.log("damenivel", dameNivel);
  const Categoria = [
    // titulo, img unselected, img selected, ref container, ref image
    ["Fuerza", fuerzamano, fuerzamano1, useRef(null), useRef(null)],
    ["Resistencia", resi, resi1, useRef(null), useRef(null)],
    ["Flexibilidad", flexi, flexi1, useRef(null), useRef(null)],
    ["Coordinación", coor, coor1, useRef(null), useRef(null)],
    ["Cardiovascular", cardio, cardio1, useRef(null), useRef(null)],
  ];
  const [dameCategoria, setdameCategoria] = useState(
    new Array(Categoria.length)
  );

  const Equipos = [
    // titulo, img unselected, img selected, ref container, ref image
    ["Bandas TRX", bandas, bandas1, useRef(null), useRef(null)],
    ["Mats Yoga", mats, mats1, useRef(null), useRef(null)],
    ["Mancuernas", mancuer, mancuer1, useRef(null), useRef(null)],
    [" Set body pump", body, body1, useRef(null), useRef(null)],
    ["Pesas rusas", pesas, pesas1, useRef(null), useRef(null)],
    ["Chaleco de peso", chaleco, chaleco1, useRef(null), useRef(null)],
    ["Bandas elásticas", bandus, bandus1, useRef(null), useRef(null)],
  ];
  const [dameEquipos, setdameEquipos] = useState(new Array(Equipos.length));

  const renderCircleImg = (arr_data, title, classes, onlyone) => {
    if (entrenamientos && entrenamientos.length > 0) {
      return (
        <>
          <div className="fw-bold lh-1  pre  letrasrealfil1">{title}</div>
          <div className="  moscou my-1" style={{ cursor: "pointer" }}>
            {arr_data.map((objetivo, index, this_array) => {
              return (
                <div
                  key={index}
                  ref={objetivo[3]}
                  className={classes}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    selectCircleImg(
                      objetivo[3],
                      objetivo[4],
                      index,
                      classes,
                      this_array,
                      onlyone
                    )
                  }
                >
                  <div
                    ref={objetivo[4]}
                    id="icon"
                    style={{ backgroundImage: `url(${objetivo[1]})` }}
                    className="rounded-circle rounded-circleer"
                  ></div>
                  <div id="text" className="lh-1 pt-1">
                    {objetivo[0]}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    } else {
      return (window.location.href = `/${userType}/reservas`);
    }
  };

  useEffect(() => {
    let guardado = [];
    let entrenamientosd = entrenamientos.filter((e) => e);
    if (dameCategoria) {
      for (let i = 0; i < dameCategoria.length; i++) {
        for (let j = 0; j < entrenamientosd.length; j++) {
          if (
            entrenamientosd[j] &&
            dameCategoria[i] &&
            entrenamientosd[j].categories.includes(dameCategoria[i])
          ) {
            guardado.push(entrenamientosd[j].id);
          }
        }
      }
    }

    if (entrenamientos) {
      onChange({
        entrenamientos9: 3,
        entrenamientos2: entrenamientos
          .filter((e) => e)
          .filter((e) =>
            e.Individual.toLowerCase().includes(Individual.toLowerCase())
          )
          .filter((e) =>
            lugarafiltrar
              ? e.Lugar.map((lugar) =>
                  lugar.includes(lugarafiltrar.toLowerCase())
                )
              : e
          )
          .filter((e) =>
            nivelafiltrar
              ? e.intensidadMsg
                  .toLowerCase()
                  .includes(nivelafiltrar.toLowerCase())
              : e
          )
          // .filter((e) =>
          //   equiposfiltrar ? e.descripcion.includes(equiposfiltrar) : e
          // )

          .filter((e) =>
            dameCategoria && guardado.length > 0 ? guardado.includes(e.id) : e
          )

          .filter((e) => e && min <= e.calorias && e.calorias <= max)
          .filter((e) => e && mini <= e.duracion && e.duracion <= maxi),
      });
    }
  }, [
    entrenamientos,
    Individual,
    lugarafiltrar,
    nivelafiltrar,
    equiposfiltrar,
    categoriafiltrar,
    mini,
    maxi,
    max,
    min,
  ]);

  const renderCircleText = (arr_data, title, classes, onlyone) => {
    return (
      <>
        <div className="fw-bold lh-1 py-3 preg">{title}</div>
        <div className="row g-0">
          {arr_data.map((objetivo, index, this_array) => {
            {
            }
            return (
              <div
                key={index}
                ref={objetivo[1]}
                className={classes}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  renderCircleText(
                    objetivo[1],
                    index,
                    classes,
                    this_array,
                    onlyone
                  )
                }
              >
                <div
                  id="text"
                  className="rounded-circle d-flex justify-content-center align-items-center lh-1"
                >
                  {objetivo[0]}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderActividadFisica = (
    <>
      {renderCircleImg(
        lugar,
        "¿Donde vas a entrenar?",
        "objetivo objetivofiller objetivos_small ",
        true
      )}
      {renderCircleImg(
        Nivel,
        "Nivel de intensidad",
        "objetivo objetivofiller objetivos_small ",
        true
      )}
      <div className="  fw-bold lh-1   letrasrealfil1">Calorias</div>
      <div
        className={laptop575.matches ? "moscou" : ""}
        style={{ position: "relative" }}
      >
        <form action="#">
          <MultiRangeSliderCal
            min={300}
            max={500}
            onChange={
              ({ min, max }) => setmin(min) || setmax(max)
              // console.log(`min = ${min}, max = ${max}`)
            }
          />
        </form>
      </div>
      {renderCircleImg(
        Categoria,
        "Categoria",
        "objetivo objetivofiller objetivos_small  ",
        false
      )}
    </>
  );

  const renderActividadFisica1 = (
    <>
      {renderCircleImg(
        Equipos,
        "",
        "objetivo objetivofiller objetivos_small ",
        false
      )}
    </>
  );
  if (!checkIfLogged()) {
    // Is Not logged
    const user = userObj();
    return <Redirect to="/" />;
  }

  const selectCircleImg = (
    refContainer,
    refImg,
    index,
    classess,
    arr,
    onlyone
  ) => {
    if (onlyone == true) {
      for (const iterator of arr) {
        // console.log("iterator", iterator);
        iterator[3].current.classList = classess;
        iterator[3].current.active = false;
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
        iterator[5] = true;
      }
    }

    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
      refImg.current.style.backgroundImage = `url(${arr[index][1]})`;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
      refImg.current.style.backgroundImage = `url(${arr[index][2]})`;
    }

    if (arr == lugar) {
      // console.log("ar", arr);
      // console.log("lugar", lugar);
      lugar[index][5] = refContainer.current.active;
      // console.log("lugarenarr", lugar[index][5]);
      const copy = damelugar;
      copy[index] = refContainer.current.active;

      setdamelugar(copy);
      setlugarafiltrar(lugar[index][0]);
    }

    if (arr == Nivel) {
      Nivel[index][5] = refContainer.current.active;
      const copy = dameNivel;
      copy[index] = refContainer.current.active;
      setdameNivel(copy);
      setnivelafiltrar(Nivel[index][0]);
    }
    if (arr == Categoria) {
      Categoria[index][5] = refContainer.current.active;
      const copy = dameCategoria;
      copy[index] = refContainer.current.active;
      setdameCategoria(copy);

      setcategoriasafiltrar(
        (dameCategoria[index] = refContainer.current.innerText)
      );
    }

    if (arr == Equipos) {
      Equipos[index][5] = refContainer.current.active;
      const copy = dameEquipos;
      copy[index] = refContainer.current.active;
      setdameEquipos(copy);
      setequiposafiltrar((dameEquipos[index] = refContainer.current.innerText));
    }
  };

  function switch1() {
    setIndividual("Grupal");
    document.querySelector(".letras1").style.left = "unset";
    document.querySelector(".letras").style =
      ";justify-content: flex-start; margin-left:7px; margin-right:none";
    document.querySelector("#switch").style = "justify-content: flex-start;";
    document.querySelector(".letras1 ").innerHTML = "Grupal";
    document.querySelector(".letras ").innerHTML = "Individual";
    if (Individual === "Grupal") {
      setIndividual("Individual");
      document.querySelector(".letras1 ").innerHTML = "Individual";
      document.querySelector(".letras ").innerHTML = "Grupal";
      document.querySelector(".letras1").style.left = "0";
      document.querySelector(".letras").style =
        "justify-content: flex-end; margin-left:none; margin-right:7px";
      document.querySelector("#switch").style = "justify-content: flex-end;";
    }
    if (Individual === "Individual") {
      setIndividual("Grupal");
      document.querySelector(".letras1 ").innerHTML = "Grupal";
      document.querySelector(".letras ").innerHTML = "Individual";
      document.querySelector(".letras1").style.left = "unset";
      document.querySelector(".letras").style =
        ";justify-content: flex-start; margin-left:7px; margin-right:none";
      document.querySelector("#switch").style = "justify-content: flex-start;";
    }
  }

  function switchsi() {
    setdecision("SI");
    document.querySelector(".letras0").style.left = "unset";
    document.querySelector(".letras2").style =
      ";justify-content: flex-start; margin-left:7px; margin-right:none";
    document.querySelector("#switch2").style = "justify-content: flex-start;";
    document.querySelector(".letras0 ").innerHTML = "SI";
    document.querySelector(".letras2 ").innerHTML = "NO";
    if (decision === "SI") {
      setdecision("NO");
      document.querySelector(".letras0 ").innerHTML = "NO";
      document.querySelector(".letras2 ").innerHTML = "SI";
      document.querySelector(".letras0").style.left = "0";
      document.querySelector(".letras2").style =
        "justify-content: flex-end; margin-left:none; margin-right:7px";
      document.querySelector("#switch2").style = "justify-content: flex-end;";
    }
    if (decision === "NO") {
      setdecision("SI");
      document.querySelector(".letras0 ").innerHTML = "SI";
      document.querySelector(".letras2 ").innerHTML = "NO";
      document.querySelector(".letras0").style.left = "unset";
      document.querySelector(".letras2").style =
        ";justify-content: flex-start; margin-left:7px; margin-right:none";
      document.querySelector("#switch2").style = "justify-content: flex-start;";
    }
  }

  // function cambioTemperatura() {
  //   document.getElementById("temp").innerHTML =
  //     document.getElementById("tiempo").value;
  // }

  const fil = (
    <SwiperSlide className="slider_form centrarfiltrar">
      <div className="formSteps mx-auto pt-3 formStepszoom ">
        <div>
          <div className="row mylgboom ">
            <div className="  text-left fw-bold lh-1 letrasrealfil ">
              ¿Cómo deseas tu clase?
            </div>

            <div className="">
              <button className="switch" id="switch">
                <span id="Individual" className="letras1">
                  Individual
                </span>
                <span id="Grupal" className="letras" onClick={switch1}>
                  Grupal
                </span>
              </button>
            </div>
          </div>
          <div>{renderActividadFisica}</div>
          <div>
            <form action="#">
              <div className=" fw-bold lh-1   letrasrealfil1">Duración:</div>
              {/* `{`${40} min`}` */}
              <MultiRangeSlider
                mini={40}
                maxi={60}
                onChange={({ mini, maxi }) =>
                  // console.log(`mini = ${mini}, maxi = ${maxi}`) ||
                  setmini(mini) || setmaxi(maxi)
                }
              />
            </form>
          </div>
          <div>
            <div className="row mylgboom">
              <div className="col-7 col-lg-auto text-left fw-bold lh-1   letrasrealfil">
                ¿Entrenas con equipos?
              </div>
              <div className="">
                <button className="switch2" id="switch2">
                  <span id="SI" className="letras0">
                    NO
                  </span>
                  <span id="NO" className="letras2" onClick={switchsi}>
                    SI
                  </span>
                </button>
              </div>
              {renderActividadFisica1}
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );
  const fil1 = (
    <SwiperSlide className="slider_form centrarfiltrar subirmasfil1">
      <div className=" mx-auto pt-3 formStepszoom ">
        <div
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "80vh",
            color: "#394F65",
          }}
        >
          <div className=" mylgboom">
            <div className="  text-left fw-bold lh-1 letrasrealfil">
              ¿Cómo deseas tu clase?
            </div>

            <div className="moscou">
              <button className="switch" id="switch">
                <span id="Individual" className="letras1">
                  Individual
                </span>
                <span id="Grupal" className="letras" onClick={switch1}>
                  Grupal
                </span>
              </button>
            </div>
          </div>
          <div>{renderActividadFisica}</div>
          <div className=" fw-bold lh-1   letrasrealfil1">Duración:</div>
          <div className="moscou" style={{ position: "relative" }}>
            <form action="#">
              {/* `{`${40} min`}` */}
              <MultiRangeSlider
                mini={40}
                maxi={60}
                onChange={({ mini, maxi }) =>
                  // console.log(`mini = ${mini}, maxi = ${maxi}`) ||
                  setmini(mini) || setmaxi(maxi)
                }
              />
            </form>
          </div>
          <div>
            <div className=" ">
              <div className="col-7 col-lg-auto text-left fw-bold lh-1   letrasrealfil">
                ¿Entrenas con equipos?
              </div>
              <div className="moscou my-1">
                <button className="switch2" id="switch2">
                  <span id="SI" className="letras0">
                    NO
                  </span>
                  <span id="NO" className="letras2" onClick={switchsi}>
                    SI
                  </span>
                </button>
              </div>
              {renderActividadFisica1}
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );
  function setear() {
    const magia = document.querySelector(".modalInfofilling");
    if (magia) {
      magia.style = "display:none ";
    }

    // setentrenamientos10(entrenamientos5);
    // return <Redirect to={"/Usuario/reservas"} />;
    history.push("/Usuario/reservas");
    onChange({
      entrenamientos2: entrenamientos5,
      entrenamientos9: [1],
    });
  }
  function recargar() {
    document.querySelector(".letras1 ").innerHTML = "Individual";
    document.querySelector(".letras ").innerHTML = "Grupal";
    document.querySelector(".letras1").style.left = "0";
    document.querySelector(".letras").style =
      "justify-content: flex-end; margin-left:none; margin-right:7px";
    document.querySelector("#switch").style = "justify-content: flex-end;";
    setIndividual("Individual");
    document.querySelector(".letras0 ").innerHTML = "NO";
    document.querySelector(".letras2 ").innerHTML = "SI";
    document.querySelector(".letras0").style.left = "0";
    document.querySelector(".letras2").style =
      "justify-content: flex-end; margin-left:none; margin-right:7px";
    document.querySelector("#switch2").style = "justify-content: flex-end;";
    setdecision("NO");
    if (damelugar) {
      setdamelugar(new Array(lugar.length));
      setlugarafiltrar(null);

      for (const iterator of lugar) {
        iterator[3].current.classList = laptop575.matches
          ? "objetivo col-2 objetivos_small "
          : "objetivo col-4 objetivos_small ";
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
      }
    }
    if (dameNivel) {
      setdameNivel(new Array(Nivel.length));
      setnivelafiltrar(null);

      for (const iterator of Nivel) {
        iterator[3].current.classList = laptop575.matches
          ? "objetivo col-2 objetivofiller  "
          : "objetivo col-4 objetivos_small ";
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
      }
    }
    if (dameCategoria) {
      setdameCategoria(Array(Categoria.length));
      setcategoriasafiltrar(null);

      for (const iterator of Categoria) {
        iterator[3].current.classList = laptop575.matches
          ? "objetivo col-2  objetivofiller mx-1"
          : "objetivo col-4 objetivos_small";
        iterator[3].current.active = false;
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
      }
    }
    if (dameEquipos) {
      setdameEquipos(Array(Equipos.length));
      setequiposafiltrar(null);

      for (const iterator of Equipos) {
        iterator[3].current.classList = laptop575.matches
          ? "objetivo col-2 objetivofiller "
          : "objetivo col-4 objetivos_small ";
        iterator[3].current.active = false;
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
      }
    }
  }

  function disapear() {
    const magia = document.querySelector(".modalInfofilling");

    magia.style = "display:none ";
  }
  return (
    <>
      <div className="CompletarPerfil1 text-center">
        <div className="barraw ">
          <div className="merg">
            <Link to="/Usuario/reservas">
              <button className="flecha" id="unicaformavolver">
                <img
                  src={flecha}
                  className="flechaimagen"
                  onClick={setear}
                  // onClick={() => history.push("/Usuario/reservas")}
                />
              </button>
            </Link>
          </div>
          <button className="basura merg" onClick={recargar}>
            <img src={basura} className="basuraimagen" />
            <div className="letrafilters"> Borrar</div>
          </button>
          {/* <Link to="/Usuario/reservas"> */}
          <button
            className="Filtrar merg"
            id="Filtremelo"
            onClick={
              window.location.pathname == `${basename}/Usuario/reservas`
                ? () => disapear()
                : () => history.push("/Usuario/reservas")
            }
          >
            <img src={check} className="filtrarimagen" />
            <div className="letrafilters">Filtrar</div>
          </button>
          {/* </Link> */}
        </div>

        <div className="content d-flex justify-content-center align-items-center">
          <Media query="(max-width: 3000px)">
            {(matches) =>
              matches ? (
                <Swiper
                  className=" "
                  spaceBetween={100}
                  slidesPerView={1}
                  autoHeight
                  allowTouchMove={false}
                >
                  {window.location.pathname == `${basename}/Usuario/reservas`
                    ? fil1
                    : fil}
                </Swiper>
              ) : (
                <div className="row g-0">{fil}</div>
              )
            }
          </Media>
        </div>
      </div>

      <ButtonMob
        className="butonfil merg "
        text={"Filtrar"}
        colorButton={"#CB3D82"}
        onClick={
          window.location.pathname == `${basename}/Usuario/reservas`
            ? () => disapear()
            : () => history.push("/Usuario/reservas")
        }
      />

      {Modal}
    </>
  );
}
