import { Redirect } from "react-router-dom";

import {
  basename,
  checkIfLogged,
  Loadingspinner,
  ModalInfo,
  SetModalError,
  NuevaAxiosURL,
  userObj,
  userType,
  ModalInfoEmpiezaya,
  laptop575,
} from "../utils/utils";
import "./Completar.css";
import imgniña from "./niñafoto.png";
import imgarriba1 from "../assets/img/Auth_images/arribaimagen.png";
import imgabajo1 from "../assets/img/Auth_images/abajoimagen.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/pagination/pagination.min.css";

import camera from "../assets/img/general/camara.png";
import warning2 from "../assets/img/Grupo 9625.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import woman from "../assets/img/extra/icon1b.png";
import weight from "../assets/img/extra/icon2b.png";
import documentA from "../assets/img/extra/icon3b.png";
import health from "../assets/img/extra/icon4b.png";
import abdom from "../assets/img/extra/icon5b.png";
import stopwatch from "../assets/img/extra/icon6b.png";

import womanB from "../assets/img/extra/icon1.png";
import weightB from "../assets/img/extra/icon2.png";
import documentB from "../assets/img/extra/icon3.png";
import healthB from "../assets/img/extra/icon4.png";
import abdomB from "../assets/img/extra/icon5.png";
import stopwatchB from "../assets/img/extra/icon6.png";

import carro from "../assets/img/UserIcons/iconos/CARRO A.png";
import carroB from "../assets/img/UserIcons/iconos/CARRO B.png";
import bici from "../assets/img/UserIcons/iconos/BICI A.png";
import biciB from "../assets/img/UserIcons/iconos/BICI B.png";
import bus from "../assets/img/UserIcons/iconos/BUS A.png";
import busB from "../assets/img/UserIcons/iconos/BUS B.png";

import yogaA from "../assets/img/UserIcons/iconos/Yoga.png";
import funcionalA from "../assets/img/UserIcons/iconos/funcional.png";
import correrA from "../assets/img/UserIcons/iconos/correr.png";
import rumbaA from "../assets/img/UserIcons/iconos/Rumba.png";
import ciclismoA from "../assets/img/UserIcons/iconos/Ciclismo.png";
import otrosA from "../assets/img/UserIcons/iconos/Otros.png";

import yogaB from "../assets/img/UserIcons/iconos/YogaB.png";
import funcionalB from "../assets/img/UserIcons/iconos/funcionalB.png";
import correrB from "../assets/img/UserIcons/iconos/correrB.png";
import rumbaB from "../assets/img/UserIcons/iconos/RumbaB.png";
import ciclismoB from "../assets/img/UserIcons/iconos/CiclismoB.png";
import otrosB from "../assets/img/UserIcons/iconos/OtrosB.png";
import React from "react";
import ReactTooltip from "react-tooltip";
import Media from "react-media";
import axios from "axios";
import "react-phone-number-input/style.css";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { useEffect, useRef, useState } from "react";
import Switch from "react-switch";

import { Lesiones } from "../Perfil/Perfil";

SwiperCore.use([Pagination]);
const Completar = (props) => {
  const moment = require("moment-timezone");
  const [Modal, setModal] = useState(null);

  const [confirmado, setModalConfirm] = useState(null);
  const [Paises, setPaises] = useState(null);
  const [ciudades, setciudades] = useState(null);

  useEffect(() => {
    axios.get("/data/paises.json").then((res) => setPaises(res.data));

    axios.get("/data/ciudades.json").then((res) => setciudades(res.data));
  }, []);

  const Ocultar = () => {
    setTimeout(() => {
      setModal(null);
    }, 50000);
  };
  const hideModal = () => {
    setModal(null);
  };

  const [currentSlider, setCurrentSlider] = useState(1);
  const [theSwipper, setTheSwipper] = useState(null);

  const [modificarLesiones, setModificarLesiones] = useState(false);
  const salvedadMedico = useRef(null);
  const [footBar, setFootBar] = useState(false);

  const [previewPhotoProfile, setPreviewPhotoProfile] = useState(camera);
  const [photoProfile, setPhotoProfile] = useState(null);
  const [chekeaprofile, setchekeaprofile] = useState(true);

  const [genero, setGenero] = useState("");

  const [nacimiento, setNacimiento] = useState("2000-01-01");

  const [estatura, setEstatura] = useState(null);
  const [peso, setPeso] = useState(null);
  const [tel, setTel] = useState(null);
  const [ciudad, setciudad] = useState(null);
  const [pais, setpais] = useState(null);

  const [direccion, setDireccion] = useState(null);
  const [tiempoActividad, settiempoActividad] = useState(null);
  const [agotamientoActividad, setAgotamientoActividad] = useState(null);
  const [sesionesActividad, setsesionesActividad] = useState(null);
  const [intensidadActividad, setintensidadActividad] = useState(null);
  const [franjaActividad, setfranjaActividad] = useState(null);
  const [medioTransporte, setmedioTransporte] = useState(null);
  const [tiempoTransporte, settiempoTransporte] = useState(null);
  const [preguntasMedicas, setPreguntasMedicas] = useState(false);
  const [preguntaEnfermedad, setPreguntaEnfermedad] = useState(false);

  const objetivos = [
    // titulo, img unselected, img selected, ref container, ref image
    ["Perder peso", woman, womanB, useRef(null), useRef(null)],
    ["Salud/Diversión", weight, weightB, useRef(null), useRef(null)],
    ["Mejorar físico", documentA, documentB, useRef(null), useRef(null)],
    ["Masa muscular", health, healthB, useRef(null), useRef(null)],
    ["Tonificar", abdom, abdomB, useRef(null), useRef(null)],
    ["Mejorar nivel", stopwatch, stopwatchB, useRef(null), useRef(null)],
  ];

  const [selectObjetivos, setselectObjetivos] = useState(
    new Array(objetivos.length)
  );
  const [selectObjetivos1, setselectObjetivos1] = useState(null);

  const actividad_fisica = [
    // titulo, img unselected, img selected, ref container, ref image
    ["Yoga pilates", yogaA, yogaB, useRef(null), useRef(null)],
    [
      "Entrenamiento funcional",
      funcionalA,
      funcionalB,
      useRef(null),
      useRef(null),
    ],
    ["Caminar /Correr", correrA, correrB, useRef(null), useRef(null)],
    ["Rumba", rumbaA, rumbaB, useRef(null), useRef(null)],
    ["Ciclismo", ciclismoA, ciclismoB, useRef(null), useRef(null)],
    ["Otros", otrosA, otrosB, useRef(null), useRef(null)],
  ];

  const [actividadFisica, setactividadFisica] = useState(
    new Array(actividad_fisica.length)
  );

  useEffect(() => {
    searchTerminos(); // search all the faqs
  }, []);
  const actividad_time = [
    ["Ninguna", useRef(null)],
    ["Menos de 3", useRef(null)],
    ["3 a 5 veces", useRef(null)],
    ["+ de 5 veces", useRef(null)],
  ];
  const actividad_agotado = [
    ["Normal", useRef(null)],
    ["Cansado", useRef(null)],
    ["Muy cansado", useRef(null)],
  ];
  const actividad_sesiones = [
    ["No hago sesiones", useRef(null)],
    ["Menos de 30 min", useRef(null)],
    ["Entre 30 y 60 min", useRef(null)],
    ["Más de 60 min", useRef(null)],
  ];
  const actividad_intensidad = [
    ["No hago sesiones", useRef(null)],
    ["Baja", useRef(null)],
    ["Media", useRef(null)],
    ["Alta", useRef(null)],
  ];
  const franja_horaria = [
    ["06:00 a 11:00", useRef(null)],
    ["11:00 a 17:00", useRef(null)],
    ["17:00 a 22:00", useRef(null)],
  ];

  const [contactoName, setContactoName] = useState(null);
  const [contactoTel, setContactoTel] = useState(null);

  const [cargarname, setcargarname] = useState(false);

  const [realizaDeporte, setRealizaDeporte] = useState(true);
  const formRefcargar = useRef(null);
  const [infoLesiones, setInfoLesiones] = useState(null);
  const [checkPreg1, setCheckPreg1] = useState(false);
  const [checkPreg2, setCheckPreg2] = useState(false);
  const [checkPreg3, setCheckPreg3] = useState(false);
  const [checkPreg4, setCheckPreg4] = useState(false);
  const [checkPreg5, setCheckPreg5] = useState(false);
  const [checkPreg6, setCheckPreg6] = useState(false);
  const [checkPreg7, setCheckPreg7] = useState(false);
  const [textodolor, settextodolor] = useState("");

  useEffect(() => {
    instance
      .get(`customers/injuries/?customer=${localStorage.uuid}`)
      .then((response) => {
        const info = response.data;

        if (info != null) {
          setInfoLesiones(info);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return "axios request cancelled";
        }
        return err;
      });
  }, []);
  useEffect(() => {
    instance.get(`${NuevaAxiosURL}users/`).then((res) => {
      const info = res.data;

      if (info != null) {
        if (
          info.customer.emergency_contact != "" &&
          info.customer.emergency_contact_phone != ""
        ) {
          setcargarname(true);
          // formRefcargar.current.children[0].children.namedItem(
          //   "name_contacto"
          // ).value = info.customer.emergency_contact;
          setContactoName(info.customer.emergency_contact);
          // formRefcargar.current.children[1].children.namedItem(
          //   "tel_contacto"
          // ).value = info.customer.emergency_contact_phone;
          setContactoTel(info.customer.emergency_contact_phone);
        }
      }
    });
  }, []);
  const preguntas_salud = [
    [
      "¿Algún médico le ha dicho que tiene problemas del corazón y que sólo debe hacer actividades físicas recomendadas por un médico?",
      "preg_salud1",
      checkPreg1,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Tiene dolor en el pecho cuando hace alguna actividad física?",
      "preg_salud2",
      checkPreg2,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿En el último mes, ¿ha tenido dolor en el pecho cuando no estaba haciendo actividades físicas?",
      "preg_salud3",
      checkPreg3,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Pierde el equilibrio por mareos, o ha perdido alguna vez el conocimiento?",
      "preg_salud4",
      checkPreg4,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Tiene problema en algún hueso o articulación que pueda ser agravado por un cambio en su actividad física?",
      "preg_salud5",
      checkPreg5,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Está tomando medicamentos recetados por el médico para la presión arterial o para el corazón (por ejemplo, pastillas diuréticas)?",
      "preg_salud6",
      checkPreg6,
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Sabe de cualquier otra razón en contra de que ejercite?",
      "preg_salud7",
      checkPreg7,
      "col-2 ps-3 align-self-center",
    ],
    // [
    //   "¿Sufres de alguna enfermedad?",
    //   "preg_salud8",
    //   checkPreg8,
    //   "col-2 ps-3 align-self-center",
    // ],
  ];

  const labelInput = (
    name,
    labelClasses,
    label,
    inputClasses,
    placeholder,
    type,
    functionChange
  ) => {
    if (label == "Fecha de nacimiento") {
      return (
        <>
          <label
            htmlFor={name}
            className={labelClasses}
            placeholder={placeholder}
          >
            {label}
          </label>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              disableFuture
              openTo="year"
              format="yyyy-MM-DD"
              views={["year", "month", "date"]}
              value={nacimiento === "2000-01-01" ? null : nacimiento}
              placeholder={nacimiento === "2000-01-01" ? "DD/MM/AAAA" : null}
              onChange={setNacimiento}
            />
          </MuiPickersUtilsProvider>
        </>
      );
    }
    return (
      <>
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
        <input
          type={type}
          name={name}
          className={inputClasses}
          placeholder={placeholder}
          onInput={functionChange}
        />
      </>
    );
  };
  const handleCheckedPreg = (index) => {
    switch (index) {
      case 0:
        setCheckPreg1(checkPreg1 ? false : true);
        break;
      case 1:
        setCheckPreg2(checkPreg2 ? false : true);
        break;
      case 2:
        setCheckPreg3(checkPreg3 ? false : true);
        break;
      case 3:
        setCheckPreg4(checkPreg4 ? false : true);
        break;
      case 4:
        setCheckPreg5(checkPreg5 ? false : true);
        break;
      case 5:
        setCheckPreg6(checkPreg6 ? false : true);
        break;
      case 6:
        setCheckPreg7(checkPreg7 ? false : true);
        break;
      // case 7:
      //   setCheckPreg8(checkPreg8 ? false : true);
      //   break;
      default:
        break;
    }
  };

  const [canNext, setCanNext] = useState(false);
  const [Numero, setnumero] = useState(null);

  useEffect(() => {
    if (checkCurrentForm(false)) {
      setCanNext(true);
    }
    return () => {};
  }, [
    currentSlider,
    photoProfile,
    genero,
    nacimiento,
    estatura,
    peso,
    tel,
    ciudad,
    direccion,
    selectObjetivos,
    selectObjetivos1,
    actividadFisica,
    tiempoActividad,
    agotamientoActividad,
    sesionesActividad,
    intensidadActividad,
    franjaActividad,
    medioTransporte,
    tiempoTransporte,
    contactoName,
    contactoTel,
    realizaDeporte,
  ]);

  function checkCurrentForm(show_warn = true) {
    const checkSlider = currentSlider;

    let lleno = false;

    function showModalWarn(faltaLLenar) {
      if (show_warn) {
        setModal(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Falta algo por llenar"
            text={
              "Faltan algunos datos por llenar: \n\n" + faltaLLenar.join("\n")
            }
            classesMsg={"FaltanItems"}
          />
        );
      }
    }
    const faltaLLenar = [];
    switch (checkSlider) {
      case 1:
        if (!photoProfile) faltaLLenar.push("▶ Foto de perfil");
        if (!genero) faltaLLenar.push("▶ Genero");
        if (nacimiento === "2000-01-01")
          faltaLLenar.push("▶ Fecha de nacimiento");

        if (!estatura) faltaLLenar.push("▶ Estatura");
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }

        return true;
      case 2:
        if (!peso) faltaLLenar.push("▶ Peso");

        if (!ciudad) faltaLLenar.push("▶ Ciudad de residencia");

        if (!direccion) faltaLLenar.push("▶ Dirección de residencia");
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;
      case 3:
        lleno = false;

        if (selectObjetivos1) lleno = true;

        if (!lleno) {
          faltaLLenar.push("▶ Objetivo principal");
        }
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;

      case 5:
        lleno = false;
        if (realizaDeporte) {
          for (const key in actividadFisica) {
            if (actividadFisica[key] === true) lleno = true;
          }
          if (!lleno) faltaLLenar.push("▶ Actividad fisica");
          if (null === tiempoActividad)
            faltaLLenar.push("▶ Tiempo de actividad");
          if (null === agotamientoActividad)
            faltaLLenar.push("▶ Agotamiento por actividad");
          if (null === sesionesActividad)
            faltaLLenar.push("▶ Sesiones por actividad");
          if (null === intensidadActividad)
            faltaLLenar.push("▶ Intensidad por actividad");
          if (null === franjaActividad)
            faltaLLenar.push("▶ Franja horaria de actividad");
        }
        if (null === medioTransporte) faltaLLenar.push("▶ Medio de transporte");
        if (null === tiempoTransporte)
          faltaLLenar.push("▶ Tiempo en el transporte");

        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;
      case 6:
        if (cargarname == false) {
          if (!contactoName)
            faltaLLenar.push("▶ Nombre del contacto de emergencia");
          if (!contactoTel)
            faltaLLenar.push("▶ Telefono del contacto de emergencia");

          if (faltaLLenar.length !== 0) {
            if (show_warn) {
              showModalWarn(faltaLLenar);
            }
            return false;
          }
        } else {
          return true;
        }

      default:
        return true;
    }
  }

  useEffect(() => {
    if (
      checkPreg1 == true ||
      checkPreg2 == true ||
      checkPreg3 == true ||
      checkPreg4 == true ||
      checkPreg5 == true ||
      checkPreg6 == true ||
      checkPreg7 == true
    ) {
      setPreguntasMedicas(true);
    }
    if (
      checkPreg1 == false &&
      checkPreg2 == false &&
      checkPreg3 == false &&
      checkPreg4 == false &&
      checkPreg5 == false &&
      checkPreg6 == false &&
      checkPreg7 == false
    ) {
      setPreguntasMedicas(false);
    }
    if (checkPreg7 == true) {
      setPreguntaEnfermedad(true);
    } else {
      setPreguntaEnfermedad(false);
    }
  }, [
    checkPreg1,
    checkPreg2,
    checkPreg3,
    checkPreg4,
    checkPreg5,
    checkPreg6,
    checkPreg7,
    // checkPreg8,
  ]);

  const renderSwitchh = (
    index,
    label,
    name,
    isCheck,
    textclasses,
    switchClasses
  ) => {
    return (
      <>
        <div className={textclasses}>{label}</div>
        <Switch
          name={name}
          onChange={() => handleCheckedPreg(index)}
          checked={isCheck}
          className={switchClasses}
          handleDiameter={20}
          height={25}
          width={65}
          onHandleColor="#FFFF"
          offHandleColor="#9EACED"
          offColor="#E0E7FF"
          onColor="#9EACED"
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "#9EACED",
                paddingRight: 2,
                fontWeight: "700",
              }}
            >
              NO
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "white",
                paddingRight: 2,
                fontWeight: "700",
              }}
            >
              SI
            </div>
          }
        />
      </>
    );
  };
  const renderCircleImg = (arr_data, title, classes, onlyone) => {
    return (
      <>
        <div className="fw-bold lh-1 py-3 preg letrasreal ">{title}</div>
        <div className="row g-0" style={{ padding: "0 2vw" }}>
          {arr_data.map((objetivo, index, this_array) => {
            return (
              <div
                key={index}
                ref={objetivo[3]}
                className={classes}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  selectCircleImg(
                    objetivo[3],
                    objetivo[4],
                    index,
                    classes,
                    this_array,
                    onlyone
                  )
                }
              >
                <div
                  ref={objetivo[4]}
                  id="icon"
                  style={{ backgroundImage: `url(${objetivo[1]})` }}
                  className="rounded-circle"
                ></div>
                <div id="text" className="lh-1 pt-1">
                  {objetivo[0]}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderCircleText = (arr_data, title, classes, onlyone) => {
    return (
      <>
        <div className="fw-bold lh-1 py-3 preg letrasreal">{title}</div>
        <div className="row g-0 marginimportantpaso5">
          {arr_data.map((objetivo, index, this_array) => {
            return (
              <div
                key={index}
                ref={objetivo[1]}
                className={classes}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  selectCircleText(
                    objetivo[1],
                    index,
                    classes,
                    this_array,
                    onlyone
                  )
                }
              >
                <div
                  id="text"
                  className="rounded-circle d-flex justify-content-center align-items-center lh-1"
                >
                  {objetivo[0]}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderActividadFisica = (
    <>
      <div className="">
        {renderCircleImg(
          actividad_fisica,
          "¿Qué tipo de actividad física realizas?",
          "objetivo col-4  objetivos_small anchorealizasactividad ",
          false
        )}
        {renderCircleText(
          actividad_time,
          "¿Cuántas veces a la semana haces actividad física?",
          "objetivo_text objetivo_text_small col-3",
          true
        )}
        {renderCircleText(
          actividad_sesiones,
          "¿Cuánto tiempo duran las sesiones que haces?",
          "objetivo_text objetivo_text_small col-3",
          true
        )}
        {renderCircleText(
          actividad_intensidad,
          "¿Cuál es el nivel de intensidad las sesiones que haces?",
          "objetivo_text objetivo_text_small col-3",
          true
        )}
        {renderCircleText(
          actividad_agotado,
          "¿Luego de tu actividad física, que tan cansado te sientes?",
          "objetivo_text objetivo_text_small col-4",
          true
        )}
        {renderCircleText(
          franja_horaria,
          "¿En que franja horaria te gustaría tomar tus entrenamientos?",
          "objetivo_text objetivo_text_small col-4",
          true
        )}
      </div>
    </>
  );

  const transportes = [
    // titulo, img unselected, img selected, ref container, ref image
    ["Carro/Moto", carro, carroB, useRef(null), useRef(null)],
    ["Bicicleta", bici, biciB, useRef(null), useRef(null)],
    ["Transporte  público", bus, busB, useRef(null), useRef(null)],
  ];

  const transportes_time = [
    ["De 2 a 3 horas", useRef(null)],
    ["Media hora", useRef(null)],
    ["Más de 3 horas", useRef(null)],
  ];

  if (!checkIfLogged()) {
    // Is Not logged
    const user = userObj();
    return <Redirect to="/" />;
  }
  const changePhoto = (event) => {
    const photo = event.target.files[0];

    setPhotoProfile(photo);
    setPreviewPhotoProfile(URL.createObjectURL(photo));
    localStorage.profile_customer = URL.createObjectURL(photo);
  };

  function searchTerminos() {
    const formData = new FormData();
  }

  const Paso1 = (
    <SwiperSlide className="slider_form ">
      <div className="paso1completar ">
        <div className="subtitle paso1subtitle">Queremos conocerte</div>
        <form className="formSteps mx-auto ">
          <div>
            <img
              src={warning2}
              alt="info"
              data-effect="solid"
              data-place="bottom"
              className="btnInfo position-absolute"
              data-event="click focus"
              data-background-color="#CB3D82"
              data-text-color="white"
              data-class="tooltipPhoto"
              data-tip="<strong>¿Por qué necesitamos tu foto?</strong><br/>Todas tus rutinas son personalizadas y solamente para ti. Si alguien más toma tus clases, es posible que pueda sufrir algún tipo de lesión."
              data-html={true}
            />
            <ReactTooltip globalEventOff="click" />
            <input
              type="file"
              accept="image/*"
              className="photoProfile"
              name="photo"
              id="photo"
              onChange={changePhoto}
            />
            <label
              htmlFor="photo"
              className="labelPhoto rounded-circle"
              style={{
                backgroundImage: `url(${previewPhotoProfile})`,
              }}
            ></label>
          </div>
          <div className="relativopaso1 ">
            <div className="correrletrassuperior">
              <div className="subtitlewebpaso1">Información general</div>
              <div className="letrasabajowebpaso1">
                ¡Conoce y explora nuestros entrenamientos disponibles y clases
                en vivo!
              </div>
            </div>
            <div className="posicionamientopaso1web ">
              <div className=" generoposiciona ">
                <div className="mt-4">
                  <label htmlFor="genero" className="d-flex">
                    Género
                  </label>
                </div>
                <div>
                  <div className="content-select">
                    <select
                      name="genero"
                      style={{ color: "#9DACBD " }}
                      className="form-select"
                      onChange={(event) => {
                        setGenero(event.target.value);
                      }}
                    >
                      <option style={{ color: "#9DACBD" }} value="">
                        Seleccionar
                      </option>
                      <option style={{ color: "#9DACBD" }} value="M">
                        Mujer
                      </option>
                      <option style={{ color: "#9DACBD" }} value="H">
                        Hombre
                      </option>
                      <option style={{ color: "#9DACBD" }} value="N/A">
                        N/A
                      </option>
                    </select>
                    <i></i>
                  </div>
                </div>
              </div>
              <div>
                <div className=" ">
                  <div>
                    {labelInput(
                      "born",
                      "d-flex  ",
                      "Fecha de nacimiento",
                      "form-control dateBorn",
                      "DD/MM/AAAA",
                      "date",
                      (event) =>
                        setNacimiento(
                          moment(event, "DD-MM-YYYY").format().slice(10)
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 ">
              <div>
                {labelInput(
                  "estatura",
                  "d-flex",
                  "Estatura (cm)",
                  "form-control estaturapaso1",
                  "Ej: 150",
                  "number",
                  (e) => {
                    setEstatura(e.target.value);
                  }
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </SwiperSlide>
  );

  const Paso2 = (
    <SwiperSlide className="slider_form ">
      <div className=" paso2completar">
        <form className="formSteps mx-auto paso2ubicar ">
          <div className="d-row">
            <div className="subtitle paso2ubicartext">
              Información adicional
            </div>
            <div>
              <div className="agradarpaso2">
                <div className="">
                  <div className="cajapeso">
                    {labelInput(
                      "peso",
                      "d-flex",
                      "Peso (Kg)",
                      "form-control",
                      "Ej: 70",
                      "number",
                      (e) => setPeso(e.target.value)
                    )}
                  </div>
                  <div className="  ">
                    <div
                      className={
                        laptop575.matches
                          ? " generoposicionaweb1 "
                          : " generoposiciona "
                      }
                    >
                      <div>
                        <label htmlFor="País" className="d-flex">
                          País
                        </label>
                      </div>
                      <div>
                        <div
                          className={
                            laptop575.matches
                              ? "content-selectweb"
                              : "content-select"
                          }
                        >
                          <select
                            name="País"
                            style={{ color: "#9DACBD " }}
                            className="form-select"
                            onChange={(event) => {
                              setpais(event.target.value);
                            }}
                          >
                            <option value="">Seleccionar</option>
                            {Paises &&
                              Paises.map((timezone) => (
                                <option
                                  style={{ color: "#9DACBD " }}
                                  value={[timezone.name, timezone.isoCode]}
                                >
                                  {timezone.name}
                                </option>
                              ))}
                          </select>
                          <i></i>
                        </div>
                      </div>
                    </div>
                    {laptop575.matches ? null : (
                      <div className=" generoposiciona ">
                        <div>
                          <label htmlFor="Ciudad" className="d-flex">
                            Ciudad
                          </label>
                        </div>
                        <div>
                          <div className="content-select">
                            <select
                              name="Ciudad"
                              style={{ color: "#9DACBD " }}
                              className="form-select"
                              onChange={(event) => {
                                setciudad(event.target.value);
                              }}
                            >
                              <option value="">Seleccionar</option>
                              {pais && pais.split(",")[1]
                                ? ciudades &&
                                  ciudades
                                    .filter(
                                      (e) =>
                                        e.countryCode ==
                                        String(pais.split(",")[1])
                                    )
                                    .sort(function (a, b) {
                                      var nameA = a.name.toLowerCase(); // ignore upper and lowercase
                                      var nameB = b.name.toLowerCase(); // ignore upper and lowercase
                                      if (nameA < nameB) {
                                        return -1;
                                      }
                                      if (nameA > nameB) {
                                        return 1;
                                      }
                                      // names must be equal
                                      return 0;
                                    })

                                    .map((timezone) => (
                                      <option
                                        style={{ color: "#9DACBD " }}
                                        value={timezone.name}
                                      >
                                        {timezone.name}
                                      </option>
                                    ))
                                : null}
                            </select>
                            <i></i>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <div className="direccionubicar">
                    {labelInput(
                      "direccion",
                      "d-flex",
                      "Dirección",
                      "form-control",
                      "Ej: Calle 57 # 24-65",
                      "text",
                      (e) => setDireccion(e.target.value)
                    )}
                    {laptop575.matches ? (
                      <div className=" generoposicionaweb ">
                        <div>
                          <label htmlFor="Ciudad" className="d-flex">
                            Ciudad
                          </label>
                        </div>
                        <div>
                          <div className="content-selectweb">
                            <select
                              name="Ciudad"
                              style={{ color: "#9DACBD " }}
                              className="form-select"
                              onChange={(event) => {
                                setciudad(event.target.value);
                              }}
                            >
                              <option value="">Seleccionar</option>
                              {pais && pais.split(",")[1]
                                ? ciudades &&
                                  ciudades
                                    .filter(
                                      (e) =>
                                        e.countryCode ==
                                        String(pais.split(",")[1])
                                    )
                                    .sort(function (a, b) {
                                      var nameA = a.name.toLowerCase(); // ignore upper and lowercase
                                      var nameB = b.name.toLowerCase(); // ignore upper and lowercase
                                      if (nameA < nameB) {
                                        return -1;
                                      }
                                      if (nameA > nameB) {
                                        return 1;
                                      }
                                      // names must be equal
                                      return 0;
                                    })

                                    .map((timezone) => (
                                      <option
                                        style={{ color: "#9DACBD " }}
                                        value={timezone.name}
                                      >
                                        {timezone.name}
                                      </option>
                                    ))
                                : null}
                            </select>
                            <i></i>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </SwiperSlide>
  );

  const selectCircleImg = (
    refContainer,
    refImg,
    index,
    classess,
    arr,
    onlyone
  ) => {
    if (onlyone == true) {
      for (const iterator of arr) {
        iterator[3].current.classList = classess;
        iterator[3].current.active = false;
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
        iterator[5] = false;
      }
    }
    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
      refImg.current.style.backgroundImage = `url(${arr[index][1]})`;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
      refImg.current.style.backgroundImage = `url(${arr[index][2]})`;
    }
    if (arr == objetivos) {
      // objetivos[index][5] = refContainer.current.active;
      // const copy = selectObjetivos;
      // copy[index] = refContainer.current.active;
      objetivos[index][5] = refContainer.current.active;

      setselectObjetivos1((index + 1) * 10);
    }
    if (arr == actividad_fisica) {
      actividad_fisica[index][5] = refContainer.current.active;
      const copy = actividadFisica;
      copy[index] = refContainer.current.active;
      setactividadFisica(copy);
    }
    if (arr == transportes) {
      transportes[index][5] = refContainer.current.active;
      setmedioTransporte(index);
    }
  };

  const selectCircleText = (refContainer, index, classess, arr, onlyone) => {
    if (onlyone == true) {
      for (const iterator of arr) {
        iterator[1].current.classList = classess;
        iterator[1].current.active = false;
        iterator[2] = false;
      }
    }
    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (arr == actividad_time) {
      actividad_time[index][2] = true;
      settiempoActividad(index);
    }
    if (arr == actividad_sesiones) {
      actividad_sesiones[index][2] = true;
      setsesionesActividad(index);
    }
    if (arr == actividad_intensidad) {
      actividad_intensidad[index][2] = true;
      setintensidadActividad(index);
    }
    if (arr == actividad_agotado) {
      actividad_agotado[index][2] = true;
      setAgotamientoActividad(index);
    }
    if (arr == franja_horaria) {
      franja_horaria[index][2] = true;
      setfranjaActividad(index);
    }
    if (arr == transportes_time) {
      transportes_time[index][2] = refContainer.current.active;
      settiempoTransporte(index);
    }
  };

  const Paso3 = (
    <SwiperSlide className="slider_form ">
      <div className=" paso3completar">
        <div className="d-row ">
          <div className="subtitle paso3ubicartext ">Tu objetivo principal</div>

          <div className="formSteps paso3auto row paso3pos ">
            {objetivos.map((objetivo, index) => {
              return (
                <div
                  key={index}
                  ref={objetivo[3]}
                  className="objetivo col-6 col-lg-4 "
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    selectCircleImg(
                      objetivo[3],
                      objetivo[4],
                      index,
                      "objetivo col-6 col-lg-1",
                      objetivos,
                      true
                    )
                  }
                >
                  <div
                    ref={objetivo[4]}
                    id="icon"
                    style={{ backgroundImage: `url(${objetivo[1]})` }}
                    className="rounded-circle"
                  ></div>
                  <div id="text">{objetivo[0]}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  const Paso4 = (
    <SwiperSlide className="slider_form">
      <div className="subtitle subtitlepaso4">¿Tienes alguna lesión?</div>
      <div className="subtitle_text  mx-auto borrandolo">
        Indícanos la zona del cuerpo y haz una breve descripción de tu lesión.
      </div>
      <div className=" formSteps  mx-auto pt-3 d-row  ">
        <div>
          <div className="formStepscompletar4">
            <Lesiones
              position="absolute "
              isProfile={false}
              // infoLesiones={infoLesiones}
              sendDataLes={modificarLesiones}
            />
          </div>
        </div>
      </div>
    </SwiperSlide>
  );
  const handleChecked = (checked) => {
    setRealizaDeporte(checked);
  };
  const Paso5 = (
    <SwiperSlide className="slider_form  " id="grande">
      <div className="subtitle paso5subtitle">Nivelación</div>
      <div className="formStepscompletar formSteps mx-auto pt-3 d-row ">
        <div className="d-row paso5ubicar">
          <div className="row sinoubicar">
            <div className="d-flex ">
              <div style={{ color: "#394F65" }}>
                <div className="col-7 col-lg-auto text-left fw-bold  letrasrealweb my-4">
                  ¿Realizas actividad física?
                </div>
                <div>
                  <div className="col-7 col-lg-auto text-left fw-bold lh-1 preg letrasreal1 ">
                    ¿Realizas
                  </div>
                  <div className="col-6 col-lg-auto  text-left text-nowrap fw-bold lh-1 preg letrasreal1 ">
                    actividad física?
                  </div>
                </div>
              </div>

              <div className="switchpaso5 my-4">
                <Switch
                  name="realiza_deporte"
                  onChange={handleChecked}
                  checked={realizaDeporte}
                  handleDiameter={20}
                  height={25}
                  width={65}
                  onHandleColor="#FFFF"
                  offHandleColor="#9EACED"
                  className="col-3 ps-4 marginswitch"
                  offColor="#E0E7FF"
                  onColor="#9EACED"
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "#9EACED",
                        paddingRight: 5,
                        fontWeight: "700",
                      }}
                    >
                      NO
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "white",
                        paddingLeft: 5,
                        fontWeight: "700",
                      }}
                    >
                      SI
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          <div className="Paso5slider">
            {realizaDeporte ? renderActividadFisica : null}
            <div className="d-row corr">
              <div className="d-row">
                <div className="fw-bold lh-1 py-3 preg letrasreal">
                  ¿Cuál es tu medio de transporte?
                </div>
                <div className="row g-0 marginimportantpaso5">
                  {transportes.map((objetivo, index) => {
                    return (
                      <div
                        key={index}
                        ref={objetivo[3]}
                        className="objetivo transporte col-4"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          selectCircleImg(
                            objetivo[3],
                            objetivo[4],
                            index,
                            "objetivo transporte col-4",
                            transportes,
                            true
                          )
                        }
                      >
                        <div
                          ref={objetivo[4]}
                          id="icon"
                          style={{ backgroundImage: `url(${objetivo[1]})` }}
                          className="rounded-circle"
                        ></div>
                        <div id="text">{objetivo[0]}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d-row">
                <div className="fw-bold lh-1 py-3 preg letrasreal">
                  ¿Cuánto tiempo duras en el medio de transporte a diario?
                </div>
                <div className="row g-0 marginimportantpaso5">
                  {transportes_time.map((objetivo, index, arr) => {
                    return (
                      <div
                        key={index}
                        ref={objetivo[1]}
                        className="objetivo_text objetivo_text_small col-4 "
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          selectCircleText(
                            objetivo[1],
                            index,
                            "objetivo_text objetivo_text_small col-4 ",
                            arr,
                            true
                          )
                        }
                      >
                        <div
                          id="text"
                          className="rounded-circle d-flex align-items-center"
                        >
                          {objetivo[0]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  const Paso6 = (
    <SwiperSlide className="slider_form  mx-auto ">
      <div className="subtitle">Preguntas de salud</div>
      <div
        className={
          laptop575.matches
            ? "formSteps step6acomodar  pt-3 "
            : "formSteps step6acomodar mx-auto pt-3 "
        }
      >
        <div className="d-row">
          <div
            className={
              laptop575.matches
                ? "d-inline-flex py-3 col-12 mx-2  "
                : "d-row-salud"
            }
          >
            <form
              ref={formRefcargar}
              className={laptop575.matches ? "d-flex col-8" : null}
            >
              <div className={laptop575.matches ? "col-6  mx-2 " : null}>
                {labelInput(
                  "name_contacto",
                  "d-flex",
                  "Contacto de emergencia",
                  "form-control",
                  "Ej: Ana Maria",
                  "text",
                  (event) => setContactoName(event.target.value)
                )}
              </div>

              <div className={laptop575.matches ? "col-6 mx-2 " : null}>
                {labelInput(
                  "tel_contacto",
                  "d-flex",
                  "Teléfono",
                  "form-control",
                  "Ej. 3006835437",
                  "number",
                  (event) => setContactoTel(event.target.value)
                )}
              </div>
            </form>
          </div>
          <div className="pt-3 preguntas row step6 Paso6slider">
            {preguntas_salud.map((value, index, this_array) => {
              return (
                <div key={index} className="d-inline-flex py-3 col-12  ">
                  {renderSwitchh(
                    index,
                    value[0],
                    value[1],
                    value[2],
                    "col-8 text-left fw-bold lh-1 d-flex align-items-center tamañoletrassalud",
                    value[3]
                  )}
                </div>
              );
            })}
            <div style={{ height: "100px", margin: "auto" }}>
              {preguntaEnfermedad ? (
                <textarea
                  placeholder="Cuentanos acerca de tu enfermedad"
                  rows="5"
                  onInput={(e) => settextodolor(e.target.value)}
                ></textarea>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  const instance = axios.create({
    baseURL: `${NuevaAxiosURL}`,

    headers: { Authorization: "Bearer " + localStorage.my_code },
  });
  const completeProfile = () => {
    // console.log(doloresPosteriores, doloresFrontales);

    const faltaLLenar = [];
    if (!photoProfile) faltaLLenar.push("Foto de perfil");
    if (!genero) faltaLLenar.push("Genero");
    if (!nacimiento) faltaLLenar.push("Nacimiento");
    if (!estatura) faltaLLenar.push("Estatura");
    if (!peso) faltaLLenar.push("Peso");

    if (!ciudad) faltaLLenar.push("Ciudad");
    if (!direccion) faltaLLenar.push("Direccion");
    if (cargarname == false) {
      if (!contactoName) faltaLLenar.push("Contacto de emergencia");

      if (!contactoTel) faltaLLenar.push("Teléfono de contacto de emergencia");
    }

    let lleno = false;

    if (selectObjetivos1) lleno = true;

    if (!lleno) faltaLLenar.push("Objetivos");

    if (realizaDeporte) {
      for (const key in actividadFisica) {
        if (actividadFisica[key] == true) lleno = true;
      }
      if (!lleno) faltaLLenar.push("Actividad física");
      if (null == tiempoActividad) faltaLLenar.push("Tiempo de actividad");
      if (null == agotamientoActividad)
        faltaLLenar.push("Agotamiento por actividad");
      if (null == sesionesActividad) faltaLLenar.push("Sesiones por actividad");
      if (null == intensidadActividad)
        faltaLLenar.push("Intensidad por actividad");
      if (null == franjaActividad) faltaLLenar.push("Franja horaria");
    }
    if (null == medioTransporte) faltaLLenar.push("Medio de transporte");
    if (null == tiempoTransporte) faltaLLenar.push("Tiempo en el transporte");

    if (faltaLLenar.length == 0) {
      if (preguntasMedicas == true) {
        return (salvedadMedico.current.style.display = "flex");
      } else {
        sendData();
      }
    } else {
      setModal(
        <ModalInfo
          error="true"
          handleClick={hideModal}
          title="Falta algo por llenar"
          text={
            "Faltan algunos datos por llenar: \n\n" + faltaLLenar.join("\n")
          }
          classesMsg={"FaltanItems"}
        />
      );
      Ocultar();
    }
  };

  const sendData = () => {
    setModal(
      <ModalInfo
        error="none"
        handleClick={hideModal}
        title="Procesando"
        text={<Loadingspinner customStyle="grow" size="5rem" />}
        classesMsg=""
      />
    );

    const imgName = photoProfile;

    sendDataFinal(imgName);
  };
  // var objetivosTrue = [];
  // selectObjetivos1.filter(function (value, index, arr) {
  //   if (value === true) {
  //     objetivosTrue.push((index + 1) * 10); // because not start on 0

  //   }
  // });

  const sendDataFinal = (imgName) => {
    var tipoActividadSel = [];
    actividadFisica.filter(function (value, index, arr) {
      if (value === true) {
        let new_index = index + 1;

        tipoActividadSel.push(new_index);
      }
      return true;
    });

    var formData = new FormData();
    var formData1 = new FormData();
    var formData2 = new FormData();

    formData.append("gender", genero == "M" ? 2 : genero == "H" ? 1 : 9);
    formData.append(
      "birthday",
      moment(nacimiento, "YYYY-MM-DD").format().slice(0, 10)
    );
    formData.append("height", estatura);
    formData.append("weight", peso);
    formData.append("profile_pic", imgName);
    formData.append("objectives", selectObjetivos1);

    if (realizaDeporte) {
      tipoActividadSel.forEach((item) =>
        formData2.append("preference_activity", item)
      );
      formData2.append("physical_active", realizaDeporte ? true : false);
      formData2.append("transportation", medioTransporte + 1);
      formData2.append("transportation_time", tiempoTransporte + 1);
      formData2.append("preference_activity_per_week", tiempoActividad);
      formData2.append("preference_intensity", intensidadActividad * 10);

      formData2.append("preference_session_time", sesionesActividad);
      formData2.append(
        "preference_fatigue_level",
        (agotamientoActividad + 1) * 10
      );
      formData2.append("preference_training_time", (franjaActividad + 1) * 10);
      formData2.append("terms", true);
    } else {
      formData2.append("physical_active", realizaDeporte ? true : false);
      formData2.append("transportation", medioTransporte + 1);
      formData2.append("transportation_time", tiempoTransporte + 1);
      formData2.append("terms", true);
    }
    formData.append("health_safe_heart", checkPreg1);
    formData.append("health_safe_chest", checkPreg2);
    formData.append("health_safe_chest_last", checkPreg3);
    formData.append("health_safe_balance", checkPreg4);
    formData.append("health_safe_bones", checkPreg5);
    formData.append("health_safe_medicated", checkPreg6);
    formData.append("health_safe_other", checkPreg7);
    formData.append("terms", true);
    if (checkPreg7) {
      formData.append("health_safe_other_text", textodolor);
    }
    formData.append("emergency_contact_phone", contactoTel);
    formData.append("emergency_contact", contactoName);
    formData1.append("address", direccion);
    formData1.append("city", ciudad);
    formData1.append("country", pais.split(",")[1]);
    instance
      .patch(`${NuevaAxiosURL}users/`, formData1)
      .then((res) => {})
      .catch((err) => {
        setModal(
          <SetModalError
            handleClick={() => setModal(null)}
            title="Algo salio mal"
          />
        );
      });
    instance
      .post(`${NuevaAxiosURL}customers/onboarding/`, formData2)
      .then((res) => {
        if (res.data) {
          setModificarLesiones(true); // Enviar los cambios en las lesiones
          setModificarLesiones(false);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setModal(
          <SetModalError
            handleClick={() => setModal(null)}
            title="Algo salio mal"
          />
        );
      });
    instance
      .patch(`${NuevaAxiosURL}customers/`, formData)
      .then((res) => {
        if (res.data) {
          setModificarLesiones(true); // Enviar los cambios en las lesiones
          setModificarLesiones(false);
          if (preguntasMedicas == true) {
            window.location.href = `${basename}/${userType}/reservas`;
          } else {
            window.location.href = `${basename}/${userType}/reservas/10`;
            // setModal(<Redirect to={`/${userType}/reservas/10`} />);
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        setModal(
          <SetModalError
            handleClick={() => setModal(null)}
            title="Algo salio mal"
          />
        );
      });
  };
  const renderFootBar = (
    <>
      <nav className="footerStep6 ubicarfooter" onClick={completeProfile}>
        <div className="lh-1 siguiente">
          Empieza a reservar{" "}
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="2x"
            color="#2A9DA5"
            className="ms-2"
          />
        </div>
      </nav>
    </>
  );

  function nextSlider() {
    if (checkCurrentForm()) {
      setCanNext(false);
      theSwipper.slideNext();

      if (currentSlider == 6) {
        if (preguntasMedicas == true) {
          salvedadMedico.current.style.display = "flex";
        }
      }
    }
  }

  function prevSlider() {
    theSwipper.slidePrev();
  }

  return (
    <>
      <ModalInfo
        handleClick={() => {
          salvedadMedico.current.style.display = "none";
          sendData();
        }}
        error="false"
        title="Antes de empezar a hacer actividad física"
        ref={salvedadMedico}
        display="none"
        text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
      />

      <div className="CompletarPerfil text-center">
        <div className="flexusuarioregistro">
          <div className="SubTitle8perfil">Perfil Usuario</div>
          <div className="lineacompletarususario"></div>
          <img className="imgarriba1" src={imgarriba1} />
          <img className="imgabajo1" src={imgabajo1} />
        </div>
        <div className="titleregistro">Completa tu perfil</div>
        <div className="titleregistrodebajo">
          Tenemos muchas opciones para ayudarte a alcanzar el objetivo que
          siempre has deseado, completa la información del formulario y crea tu
          perfil.
        </div>

        <div className="stepsBullets"></div>

        <img className="niñafoto" src={imgniña} />
        <div className="Separador"></div>
        <div className="content d-flex justify-content-center align-items-center contenidototal">
          <Media query=" (max-width: 3000px)">
            {(matches) =>
              matches ? (
                <>
                  <Swiper
                    className="mb-4 Swiperregistro "
                    spaceBetween={100}
                    slidesPerView={1}
                    autoHeight
                    allowTouchMove={false}
                    pagination={{
                      el: ".stepsBullets",
                      type: "custom",
                      renderCustom: function (swiper, current, total) {
                        setnumero(current);
                        if (theSwipper == null) {
                          setTheSwipper(swiper);
                        }
                        setCurrentSlider(current);
                        if (current == 6) setFootBar(true);
                        else setFootBar(false);
                        if (cargarname) {
                          return `
                                                        <div class='swiper-pagination-bullet Bullet   ${
                                                          current >= 0
                                                            ? "active "
                                                            : ""
                                                        }'>1</div>
                                                        <img class='swipersep  ${
                                                          current >= 1
                                                            ? "active"
                                                            : ""
                                                        }'  />
                                                        <div class='swiper-pagination-bullet Bullet   ${
                                                          current >= 2
                                                            ? "active"
                                                            : ""
                                                        }'>2</div>
                                                        <img class='swipersep  ${
                                                          current > 2
                                                            ? "active"
                                                            : "noactive"
                                                        }'  />
                                                        <div class='swiper-pagination-bullet Bullet   ${
                                                          current >= 3
                                                            ? "active"
                                                            : ""
                                                        }'>3</div>
                                                        <img class='swipersep  ${
                                                          current > 3
                                                            ? "active"
                                                            : "noactive"
                                                        }'  />
                                                        <div class='swiper-pagination-bullet Bullet  ${
                                                          current >= 4
                                                            ? "active"
                                                            : ""
                                                        }'>4</div>
                                                        <img class='swipersep  ${
                                                          current > 4
                                                            ? "active"
                                                            : "noactive"
                                                        }'  />
                                                        <div class='swiper-pagination-bullet Bullet   ${
                                                          current >= 5
                                                            ? "active"
                                                            : ""
                                                        }'>5</div>
                                                        <img class='swipersep  ${
                                                          current > 5
                                                            ? "active"
                                                            : ""
                                                        }'  />
                                                      
                                                    `;
                        } else
                          return `
                                                        <div class='swiper-pagination-bullet Bullet   ${
                                                          current >= 0
                                                            ? "active "
                                                            : ""
                                                        }'>1</div>
                                                        <img class='swipersep  ${
                                                          current >= 1
                                                            ? "active"
                                                            : ""
                                                        }'  />
                                                        <div class='swiper-pagination-bullet Bullet   ${
                                                          current >= 2
                                                            ? "active"
                                                            : ""
                                                        }'>2</div>
                                                        <img class='swipersep  ${
                                                          current > 2
                                                            ? "active"
                                                            : "noactive"
                                                        }'  />
                                                        <div class='swiper-pagination-bullet Bullet   ${
                                                          current >= 3
                                                            ? "active"
                                                            : ""
                                                        }'>3</div>
                                                        <img class='swipersep  ${
                                                          current > 3
                                                            ? "active"
                                                            : "noactive"
                                                        }'  />
                                                        <div class='swiper-pagination-bullet Bullet  ${
                                                          current >= 4
                                                            ? "active"
                                                            : ""
                                                        }'>4</div>
                                                        <img class='swipersep  ${
                                                          current > 4
                                                            ? "active"
                                                            : "noactive"
                                                        }'  />
                                                        <div class='swiper-pagination-bullet Bullet   ${
                                                          current >= 5
                                                            ? "active"
                                                            : ""
                                                        }'>5</div>
                                                        <img class='swipersep  ${
                                                          current > 5
                                                            ? "active"
                                                            : "noactive"
                                                        }'  />
                                                       <div class='swiper-pagination-bullet Bullet      ${
                                                         current >= 6
                                                           ? "active"
                                                           : ""
                                                       }'>6</div>
                                                    `;
                      },
                      clickable: false,
                    }}
                  >
                    <>
                      {" "}
                      {Paso1}
                      {Paso2}
                      {Paso3}
                      {Paso4}
                      {Paso5}
                      {cargarname ? null : Paso6}
                    </>
                  </Swiper>
                </>
              ) : (
                <div className="row-g-0">
                  {Paso1}
                  {Paso2}
                  {Paso3}
                  {Paso4}
                  {Paso5}
                  {cargarname ? null : Paso6}

                  {renderFootBar}
                </div>
              )
            }
          </Media>
        </div>
      </div>
      {Modal}
      {confirmado}

      <CompletarFooter
        cargarname={cargarname}
        contactoTel={contactoTel}
        Numero={Numero}
        current={currentSlider}
        completeProfile={completeProfile}
        nextSlider={nextSlider}
        prevSlider={prevSlider}
        chekeaprofile={chekeaprofile}
        canNext={canNext}
      />
    </>
  );
};

const CompletarFooter = (props) => {
  const cargarname = props.cargarname;
  const [modalInfo, setModalInfo] = useState(null);
  const chekeaprofile = props.chekeaprofile;

  const Numero = props.Numero;

  const renderFootBar1 = (
    <>
      <nav className="footerStep6 ubicarfooter " onClick={completeProfile}>
        <div className="lh-1 siguiente1">
          Empieza a reservar
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="2x"
            color="#2A9DA5"
            className="ms-2 ubicarfont"
          />
        </div>
      </nav>
    </>
  );

  const current = props.current;
  const valorllenotelefono = cargarname ? 5 : 6;
  const renderFootBar = current == valorllenotelefono ? true : false;
  const siguienteborrar = document.querySelector(".CompletarFooter .anterior");
  if (siguienteborrar) {
    if (current >= 1 && current < 2) {
      siguienteborrar.style = "background-color: transparent;";
    } else {
      siguienteborrar.style = "background-color: #CB3D82";
    }
  }
  // console.log("renderFootBar", renderFootBar);
  function completeProfile() {
    props.completeProfile();
  }
  function nextSlider() {
    props.nextSlider();
  }
  function prevSlider() {
    props.prevSlider();
  }
  function mostraryatusabes() {
    if (chekeaprofile === true) {
      const fin = document.getElementById("tyc1");
      if (fin) {
        fin.style = "display:none";
      }
      {
        setModalInfo(
          <ModalInfoEmpiezaya
            error="false"
            handleClick={() => {
              setModalInfo(null);
              completeProfile();
            }}
            title="¡Ahora eres Elevva +!"
            text={
              "Tu cuenta a sido creada exitosamente. Al dar click vivirás una nueva experiencia."
            }
            classesMsg={"FaltanItemsmobster"}
          />
        );
      }
    }
  }

  const classNext = `siguiente ${props.canNext == true ? "canNext" : ""}`;
  return modalInfo == null ? (
    <>
      <div className="numberbluediv">
        <div className="numberblue">0{Numero}</div>
      </div>
      <nav className="CompletarFooter navFooter  ">
        <div className="anterior" onClick={prevSlider}>
          <i className="fa fa-arrow-left m-1"></i> Anterior
        </div>
        {renderFootBar == true && props.canNext == true ? (
          <div className={classNext} onClick={mostraryatusabes}>
            Finalizar
          </div>
        ) : (
          <div className={classNext} onClick={nextSlider}>
            Siguiente <i className="fa fa-arrow-right m-1"></i>
          </div>
        )}
      </nav>
    </>
  ) : (
    <>{modalInfo}</>
  );
};

export default Completar;
// Autorizas a edad y estatura (datos senasibklwes)

// frecuancia cardiaca
