import { useHistory, useParams } from "react-router";
import {
  basename,
  instance,
  laptop575,
  ModalInfo,
  ModalInfoVideosFilter,
  userType,
} from "../utils/utils";
import intensidad_img from "../assets/img/reservasimages/icon_extra_1a.png";
import { useEffect, useState, useRef } from "react";
import { AllImagenes } from "../Ejercicios/allvideos";
import { Videos_two } from "../Ejercicios/othervideos";
import { Link } from "react-router-dom";
import { extendMoment } from "moment-range";
import Moment from "moment";
import aunno from "../assets/img/UserIcons/iconos/aunno.png";
import confirmado from "../assets/img/UserIcons/iconos/confirmad (1).png";
import minicalendar from "../assets/img/UserIcons/iconos/minicalendar.png";
import cursoya from "../assets/img/UserIcons/iconos/encurso.png";
import aerobico from "../assets/img/reservasimages/aerobico.png";
import reloj from "../assets/img/reservasimages/reloj.png";

import corazon from "../assets/img/reservasimages/corazon_reserva.png";
const EjerciciosReservasGrupales = (props) => {
  const moment = extendMoment(Moment);
  const [renderReservas, setRenderReservas] = useState(null);
  require("moment-timezone");
  const zonahorairachange = props.zonahorairachange;
  const [modalInfo, setModalInfo] = useState(null);

  const Ocultar = () => {
    setModalInfo(null);
  };
  function hideModal() {
    setModalInfo(null);
  }
  const [individuales, setIndividuales] = useState(false);
  useEffect(() => {
    const inicioday = moment().format().slice(0, 11) + "00:00";
    const finislongdays =
      moment().add(30, "days").format().slice(0, 11) + "00:00";
    const copyEntrenamientos = [];
    console.log(inicioday, finislongdays);
    instance
      .get(
        `booking/?available&date__lte=${finislongdays}&date__gte=${inicioday}`
      )
      .then((resEn) => {
        let clasesgroup = resEn.data.filter((e) => e.is_group == true);
        if (clasesgroup.length > 0) {
          const info = clasesgroup;
          console.log(info);

          if (info != null && info.length > 0) {
            for (const i of info) {
              const identrenamiento = i.training.id;

              const tz = i.tz;
              const date = moment.tz(i.date, tz).format();
              const id = i.id;
              const trainer = i.trainer;
              const is_group = i.is_group;
              const status = i.status;
              instance
                .get(`trainings/${identrenamiento}/`)
                .then(async (resEn) => {
                  const data1 = resEn.data;

                  if (data1 != null) {
                    copyEntrenamientos[id] = {
                      id: id,
                      date: date,
                      titulo: unescape(data1.name).toLowerCase(),
                      descripcion: data1.description,
                      img: data1.cover_picture,
                      status_reserva: status,
                      rawImg: data1.main_picture,
                      icon: data1.icon,
                      is_group: is_group,
                      intensidadMsg:
                        data1.intensity == 10
                          ? " Baja Intensidad"
                          : data1.intensity == 20
                          ? " Media Intensidad"
                          : " Alta Intensidad", //.match(/<p>([\s\w]*)<\/p>/)[1],
                      intensidadImg: intensidad_img,
                      duracion: data1.time, //.match(/([\d]*)<b class/)[1],
                      calorias: data1.calories,
                      entrenador: data1.trainers.filter((e) => e.id == trainer),
                      // categoria,
                    };
                  }

                  setRenderReservas(
                    copyEntrenamientos
                      .filter((e) => e)
                      .map((el, index) => {
                        const bg_img = el.img;
                        const intensidadImg = el.intensidadImg;
                        const entrenador = el.entrenador;
                        const is_group = el.is_group;

                        const name = el.titulo;
                        const icon = el.icon;
                        const status_reserva = el.status_reserva;
                        const fecha = moment
                          .tz(el.date, zonahorairachange)
                          .format("DD [de] MMMM");
                        const intensidadMsg = el.intensidadMsg;
                        const duracion = el.duracion;
                        const calorias = el.calorias;
                        const hora = moment
                          .tz(el.date, zonahorairachange)
                          .format("LT a");

                        // console.log(hora.format("LTS"));

                        const url = `${basename}/ejercicios/iniciar/ClaseCompanies/${id}`;

                        return (
                          <div
                            key={el.id + "/" + `${is_group}`}
                            className={index == 0 ? "" : "my-1"}
                          >
                            <Link key={index} to={url} className="col-12">
                              <div
                                className="Entrenamientomisclases reserva reserva1 "
                                style={{
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  src={bg_img}
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    // backgroundSize: "cover",
                                    // transform: "rotate(180deg) scale(1,-1)",
                                    height: "auto",
                                  }}
                                ></img>
                                <div className="bg_cover reserva"> </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    left: "1vw",
                                    top: "33%",

                                    background: "#9EACED",
                                    borderRadius: "50px",
                                  }}
                                >
                                  <img
                                    src={icon}
                                    alt="intensidad"
                                    style={{
                                      width: "4vw",
                                      heigth: "4vw",
                                    }}
                                  />
                                </div>
                                <div className="Cabecera reservamisclases">
                                  Entrenamiento
                                  {/* <span className="lineop"></span> */}
                                  <br />
                                  <span className="container_name">
                                    {name.toLowerCase()}
                                  </span>
                                </div>
                                <div
                                  className=""
                                  style={{
                                    position: "absolute",
                                    top: "0%",
                                    left: "0%",
                                  }}
                                >
                                  <div
                                    className=""
                                    style={{
                                      fontSize: "0.8rem",
                                      position: "absolute",
                                      fontWeight: "700",
                                      top: "15%",
                                      left: "10%",
                                      textAlign: "left",
                                      zIndex: "1",
                                    }}
                                  >
                                    {status_reserva == 10 ? (
                                      <div>
                                        {" "}
                                        <span>EN ESPERA DE</span>
                                        <br />
                                        <span> CONFIRMACIÓN</span>{" "}
                                      </div>
                                    ) : status_reserva == 20 ? (
                                      <div>
                                        {" "}
                                        <span>RESERVA</span>
                                        <br />
                                        <span> CONFIRMADA</span>{" "}
                                      </div>
                                    ) : status_reserva == 30 ? (
                                      <div>
                                        {" "}
                                        <span>RESERVA</span>
                                        <br />
                                        <span> EN CURSO</span>{" "}
                                      </div>
                                    ) : null}
                                  </div>
                                  <img
                                    width={150}
                                    style={{ transform: "rotate(180deg)" }}
                                    src={
                                      status_reserva == 10
                                        ? aunno
                                        : status_reserva == 20
                                        ? confirmado
                                        : status_reserva == 30
                                        ? cursoya
                                        : null
                                    }
                                  ></img>
                                </div>
                                {entrenador[0] ? (
                                  <div
                                    className="Cabecera reservamisclases letra_cabeza"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Tus clases preferidas a un click
                                    <br />
                                    <span style={{ fontWeight: "600" }}>
                                      {entrenador[0].name}/{fecha}/{hora}
                                    </span>
                                  </div>
                                ) : null}
                                <div className=" informacion informacionmisclases d-flex ">
                                  <div className="step intensidad">
                                    <div>
                                      <img
                                        src={intensidad_img}
                                        alt="intensidad"
                                      />
                                      <br />
                                      {intensidadMsg}
                                    </div>
                                  </div>
                                  <div className="step duracion">
                                    <div>
                                      <img src={reloj} alt="Duración" />
                                      <br />
                                      <span>{duracion}</span>Min
                                      <br />
                                      Duración
                                    </div>
                                  </div>
                                  <div className="step tamañosaerobico intensidad">
                                    <div className="aero">
                                      <img src={aerobico} alt="Duración" />
                                      <br />
                                      Tipo Aerobicos
                                    </div>
                                  </div>
                                  <div className="step calorias">
                                    <div>
                                      <img src={corazon} alt="Calorias" />
                                      <br />
                                      <div>{calorias}</div>Cal. promedio
                                    </div>
                                  </div>
                                  <div className="step ver tamañosver ">
                                    <div>
                                      <i className="fa fa-arrow-right m-1"></i>
                                      <br />
                                      <div>Iniciar</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                  );
                });
            }
          }
        }
      })
      .catch((err) => {
        console.error(
          "Error en api/clientes/reservas/cargar-cronograma-home",
          err
        );
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });
  }, [individuales]);
  const images = [
    [
      process.env.PUBLIC_URL + "/img/cardio_core.png",
      "Entrenamiento",
      "Cardio core",
      "",
      true,
      [
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/videomente1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/videomente2.png"],
        [
          "Hábitos alimenticios",
          process.env.PUBLIC_URL + "/img/videomente3.png",
        ],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/videomente4.png"],
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/videomente1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/videomente2.png"],
        [
          "Hábitos alimenticios",
          process.env.PUBLIC_URL + "/img/videomente3.png",
        ],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/videomente4.png"],
      ],
      "pin",
      "Box",
      "Todo el cuerpo",
      1,
      "Principiante",
    ],
    [
      process.env.PUBLIC_URL + "/img/tren_superior.png",
      "Entrenamiento",
      "Tren superior",
      "",
      false,
      [
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
        ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
        ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
      ],
      "pindos",
      "Pesa rusa",
      "Tren superior",
      2,
      "Intermedio",
    ],
    [
      process.env.PUBLIC_URL + "/img/hiit_training.png",
      "Entrenamiento",
      "Hiit training",
      "",
      false,
      [
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
        ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
        ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
      ],
      "pin",
      "Barra",
      "Espalda",
      3,
      "Avanzado",
    ],
  ];
  const [filtervideos, setfiltervideos] = useState(images);

  const [inputs, setInputs] = useState({
    ["Equipamiento"]: [[]],
    ["Disciplina"]: [[]],
    ["Zona a trabajar"]: [[]],
    ["Dificultad"]: [[]],
    ["Tiempo"]: [[]],
    ["Tipo"]: [[]],
  });
  function filtrarButtons(valuesearch, local) {
    console.log(valuesearch, local, inputs[local]);
    var pepe = inputs[local][0].filter((e) => e != valuesearch);
    console.log(pepe);
    setInputs((val) => ({
      ...val,
      [local]: [pepe],
    }));
    //  inputs[local].filter(e=>)
    // var videofiltro = inputs;

    //    setfiltervideos(videofiltro);
  }
  var handleChange = (event) => {
    let name = Object.keys(event);
    let value = Object.values(event);
    console.log(name, value);
    setInputs((val) => ({ ...val, [name]: value }));
  };
  useEffect(() => {
    let guardado = [];
    let entrenamientosd = images;
    if (
      inputs["Disciplina"][0] &&
      inputs["Equipamiento"][0] &&
      inputs["Zona a trabajar"][0] &&
      inputs["Dificultad"][0] &&
      inputs["Tipo"][0] &&
      inputs["Tiempo"][0]
    ) {
      let inputsAll = inputs["Disciplina"][0]
        .concat(inputs["Equipamiento"][0])
        .concat(inputs["Zona a trabajar"][0])
        .concat(inputs["Dificultad"][0])
        .concat(inputs["Tiempo"][0])
        .concat(inputs["Tipo"][0]);

      if (inputs["Disciplina"] && inputsAll.length > 0) {
        for (let i = 0; i < inputsAll.length; i++) {
          for (let j = 0; j < entrenamientosd.length; j++) {
            if (
              entrenamientosd[j][2].includes(inputsAll[i]) ||
              entrenamientosd[j][7].includes(inputsAll[i]) ||
              entrenamientosd[j][8].includes(inputsAll[i]) ||
              entrenamientosd[j][10].includes(inputsAll[i])
            ) {
              let revisarrepetirdos = guardado.map((e) => e[9]);
              if (!revisarrepetirdos.includes(entrenamientosd[j][9])) {
                guardado.push(entrenamientosd[j]);
              }
            }
          }
        }

        setfiltervideos(guardado);
      } else {
        setfiltervideos(images);
      }
    }
  }, [inputs]);

  return (
    <div
      className="d-flex m-auto"
      style={{
        height: "100vh",
      }}
    >
      {laptop575.matches ? (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex"
            style={{
              background: "rgb(241, 246, 249)",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
              flexDirection: "column",
            }}
          >
            <div
              className="py-2 mx-3 banner_one "
              style={{
                background: "rgb(241, 246, 249)",
                minHeight: "65px",
                color: "#394F65",
              }}
            >
              {" "}
              <Link
                to="/ejercicios"
                className=" position-absolute"
                style={{
                  left: "10%",
                  top: "35%",
                  justifyContent: "center",

                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {" "}
                <span className="d-flex m-auto">
                  <span
                    className="my-auto mx-1 px-2 py-1 d-flex"
                    style={{
                      border: "1px solid #394F65",
                      justifyContent: "center",
                      color: "#394F65",
                      borderRadius: "10px",
                    }}
                  >
                    {" "}
                    <i className="fa fa-arrow-left  "></i>
                  </span>
                  <span className="my-auto m-1" style={{ color: "#394F65" }}>
                    Regresar
                  </span>
                </span>
              </Link>
              <div
                className="d-flex position-absolute"
                style={{
                  left: "45%",
                  top: "30%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <img
                  style={{ width: "200px" }}
                  src={process.env.PUBLIC_URL + "/img/logosEmpresas.png"}
                />{" "}
              </div>
            </div>
          </div>
          <div className="w-100">
            <div
              className="containerBackgroundCreateuser d-flex "
              style={{
                padding: "0% 10%",
                justifyContent: "center",
                flexDirection: "column",
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/img/buscar.jpg"
                })`,
              }}
            >
              <span
                className="my-5"
                style={{ fontWeight: "700", color: "#ffff", fontSize: "40px" }}
              >
                Mis clases
              </span>
            </div>
          </div>
          <div className="listaReservasmisclasesCardif mx-auto my-4">
            <span style={{ color: "#9DACBD" }}>
              En elevva eres dueño de tu tiempo, agenda tus clases grupales en
              los días y horario de tu preferencia.
            </span>
          </div>
          <div className="grupales">
            <div className="listaReservasmisclasesCardif mx-auto   ">
              {individuales &&
              renderReservas &&
              renderReservas.filter((e) => e.key.includes("false")).length >
                0 ? (
                renderReservas.filter((e) => e.key.includes("false"))
              ) : !individuales &&
                renderReservas &&
                renderReservas.filter((e) => e.key.includes("true")).length >
                  0 ? (
                renderReservas.filter((e) => e.key.includes("true"))
              ) : renderReservas && renderReservas.length == 0 ? (
                <div
                  className=" "
                  style={{
                    marginTop: "4rem",
                    color: "#CB3D82",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    className="title mx-auto  text-center"
                    style={{ fontSize: "1.5vw" }}
                  >
                    <div className="title mx-auto">
                      <img src={minicalendar} />
                    </div>
                    <br></br>
                    No se encuentran reservas
                  </div>{" "}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default EjerciciosReservasGrupales;
