import { instance } from "../utils/utils";
import Moment from "moment";
import intensidad_img from "../assets/img/reservasimages/icon_extra_1a.png";
import { extendMoment } from "moment-range";
async function promise_four(finislongdays, inicioday) {
  const moment = extendMoment(Moment);
  const copyEntrenamientos19 = [];
  return instance
    .get(`booking/?date__lte=${finislongdays}&date__gte=${inicioday}`)
    .then(async (response) => response.data)
    .then(async (data) => {
      for (const iterator of data) {
        const identrenamiento = iterator.training.id;
        const tz = iterator.tz;
        const date = moment.tz(iterator.date, tz).format();

        const id = iterator.id;
        const status = iterator.status;
        await instance
          .get(`trainings/${identrenamiento}/`)
          .then(async (resEn) => {
            const data1 = resEn.data;

            if (data1 != null) {
              copyEntrenamientos19[id] = {
                id: id,
                status_reserva: status,
                date: date,
                titulo: data1.name.toLowerCase(),
                descripcion: data1.description,
                img: data1.cover_picture,
                is_group: data1.group,
                rawImg: data1.main_picture,
                icon: data1.icon,
                intensidadMsg: data1.intensity, //.match(/<p>([\s\w]*)<\/p>/)[1],
                intensidadImg: intensidad_img, //.match(/pictures\/extra\/([\w\d.]*)/)[1],
                duracion: data1.time, //.match(/([\d]*)<b class/)[1],
                calorias: data1.calories,
                // categoria,
              };
            }
          });
      }

      return copyEntrenamientos19.filter((e) => e);
    })
    .catch((err) => {
      console.error(err);
    });
}
export { promise_four };
