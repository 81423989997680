import { Link, Redirect, useParams } from "react-router-dom";

import Moment from "moment";
import { extendMoment } from "moment-range";
import { useEffect, useState } from "react";
import { ButtonMob, instance, ModalInfo } from "../utils/utils";
import calendar from "../assets/img/reservasimages/calendar.png";

import "../Reservas/Reservando.css";
const ReservarClaseCompanies = (props) => {
  let { id } = useParams();
  const [entrenamientos_grupales, setentrenamientos_grupales] = useState(null);
  const [Idclass, setIdclass] = useState(null);
  const [vamosReservar, setvamosReservar] = useState({});
  const [modalInfo, setModalInfo] = useState(null);
  const [earvalorgrupal, setearvalorgrupal] = useState(null);
  const [disablebutton, setdisablebutton] = useState(true);
  const [zonahorairachange, setZonahorairachange] = useState(
    props.zonahorairachange
  );

  const moment = extendMoment(Moment);

  function hideModal() {
    setModalInfo(null);
  }
  function searchClasegrupales(reservar) {
    const fech = moment.tz(reservar, zonahorairachange).format("YYYY-MM-DD LT");

    var formDatagroup = new FormData();
    formDatagroup.append("training", id);

    formDatagroup.append("date", fech);
    formDatagroup.append("tz", zonahorairachange);
    instance
      .post(`/booking/group/book/`, formDatagroup)
      .then((res) => {
        let arraydegroup = [];
        arraydegroup.push(res.data.id);

        ReservandoEntrenamientosgrupales(arraydegroup);
      })
      .catch((err) => {
        setdisablebutton(false);
        if (err.response) {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Lo sentimos"
              text={err.response.data[0]}
              classesMsg=""
            />
          );
        }
      });
  }
  function ReservandoEntrenamientosgrupales(id) {
    setModalInfo(
      <Redirect
        push={true}
        to={`/ejercicios/confirmadoCompanie/${id.map((em) => em)}`}
      />
    );
  }
  const clickHandlerRes = (event) => {
    if (earvalorgrupal) {
      searchClasegrupales(earvalorgrupal);
      setdisablebutton(true);
    }
  };

  const clickHandlerRes_salir = (event) => {
    setModalInfo(<Redirect push={true} to={`/ejercicios`} />);
  };
  require("moment-timezone");
  useEffect(() => {
    if (vamosReservar != {}) {
      setdisablebutton(false);
    }
  }, [vamosReservar]);
  const btnReservar = (
    <>
      <div className="containerResevar mb-5">
        <ButtonMob
          disabled={disablebutton}
          colorButton={"#CB3D82"}
          onClick={
            entrenamientos_grupales && entrenamientos_grupales.length > 0
              ? clickHandlerRes
              : clickHandlerRes_salir
          }
          text={
            entrenamientos_grupales && entrenamientos_grupales.length > 0
              ? "Reservar"
              : "Volver"
          }
          className="text-left ps-4 btnReserva izquierbuton izquierbuton_1 cajaReservagrupalpaso1"
          image1={calendar}
          image={calendar}
          classesImg="btnCalendar m-2"
        />
      </div>
    </>
  );
  useEffect(() => {
    instance.get(`trainings/${id}/`).then((res) => {
      console.log(res.data);
      setIdclass(res.data);
    });
    const inicioday = moment().format().slice(0, 11) + "00:00";

    instance
      .get(`/booking/group/${id}/schedule/`)
      .then((res) => {
        const data = res.data.filter((e) => e.schedule_date >= inicioday);
        console.log(data);
        if (data) {
          setentrenamientos_grupales(data);
        }
      })
      .catch((err) => {
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });
  }, []);
  return (
    <>
      <div
        className="bannervideos d-flex"
        style={{
          background: "rgb(241, 246, 249)",
          position: "sticky",
          top: "0",
          zIndex: "3",
          minheight: "10vmin",
          left: "0",
          flexDirection: "column",
        }}
      >
        <div
          className="py-2 mx-3 banner_one "
          style={{
            background: "rgb(241, 246, 249)",
            minHeight: "65px",
            color: "#394F65",
          }}
        >
          {" "}
          <Link
            to="/ejercicios"
            className=" position-absolute"
            style={{
              left: "10%",
              top: "35%",
              justifyContent: "center",

              fontWeight: "500",
              alignItems: "center",
            }}
          >
            {" "}
            <span className="d-flex m-auto">
              <span
                className="my-auto mx-1 px-2 py-1 d-flex"
                style={{
                  border: "1px solid #394F65",
                  justifyContent: "center",
                  color: "#394F65",
                  borderRadius: "10px",
                }}
              >
                {" "}
                <i className="fa fa-arrow-left  "></i>
              </span>
              <span className="my-auto m-1" style={{ color: "#394F65" }}>
                Regresar
              </span>
            </span>
          </Link>
          <div
            className="d-flex position-absolute"
            style={{
              left: "45%",
              top: "30%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <img
              style={{ width: "200px" }}
              src={process.env.PUBLIC_URL + "/img/logosEmpresas.png"}
            />{" "}
          </div>
        </div>
      </div>
      <div className="w-100">
        <div
          className="containerBackgroundCreateuser d-flex"
          style={{
            padding: "1.5vmin 5% 1.5vmin 55%",
            justifyContent: "center",
            flexDirection: "column",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/img/buscaralto.jpg"
            })`,
          }}
        >
          <span
            className="my-5"
            style={{ fontWeight: "700", color: "#ffff", fontSize: "40px" }}
          >
            Selecciona
            <br />
            tu día y hora de clase
          </span>
        </div>
      </div>
      <div
        className="d-flex position-absolute "
        style={{
          top: "0%",
          marginTop: "23vmin",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "40%", paddingLeft: "7%" }}>
          {" "}
          {Idclass ? (
            <div
              className="mx-auto position-relative caminito"
              style={{
                width: "100%",
                height: "100%",
                minHeight: "70vmin",
                backgroundSize: "cover",
                overflow: "hidden",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${Idclass.main_picture})`,
              }}
            >
              <div
                className="position-absolute"
                style={{
                  bottom: "10%",
                  left: "20%",
                  color: "white",
                }}
              >
                <div className="d-flex w-100">
                  <span
                    className="me-2"
                    style={{ whiteSpace: "nowrap", fontSize: "2vmin" }}
                  >
                    Clase grupal
                  </span>
                  <span className="lineaCom"></span>
                </div>

                <span
                  style={{
                    fontSize: "5vmin",
                    marginRight: "13vmin",
                    fontWeight: "bold",
                  }}
                >
                  {Idclass.name}
                </span>
              </div>
            </div>
          ) : null}
        </div>

        <div style={{ padding: "20vmin 1% 5vmin 1%", width: "60%" }}>
          {entrenamientos_grupales && entrenamientos_grupales.length > 0 ? (
            <div
              className="mx-auto"
              style={{
                overflow: "auto",
                height: "35vmin",
                maxWidth: "80vmin",
              }}
            >
              {entrenamientos_grupales.map((elem, id) => (
                <div
                  className="contendor contendor_grupales d-flex py-3"
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",

                    borderBottom: "1px solid rgba(149,149,149,0.5)",
                  }}
                >
                  <div
                    className="d-flex my-auto col-2"
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      background: "#9EACED",
                      borderRadius: "50%",
                      width: "10vmin",
                      verticalAlign: "middle",
                      height: "10vmin",
                    }}
                  >
                    <img src={elem.training.icon} />
                  </div>
                  <label
                    className="col-6 mx-2 my-auto"
                    style={{
                      textTransform: "capitalize",
                      marginBottom: "10px",
                      padding: "5px 0 5px 20px",
                      fontSize: "1rem",
                      color: "#394F65",
                    }}
                  >
                    {`${moment(elem.schedule_date, "YYYY-MM-DD").format(
                      "dddd "
                    )}`}
                    {`${moment(elem.schedule_date, "YYYY-MM-DD").format(
                      "DD [de] MMMM"
                    )}`}

                    <div className="d-flex">
                      <div style={{ fontWeight: "700" }}>
                        {`Prof. ${elem.trainer.full_name.replaceAll(
                          "%20",
                          ""
                        )}`}
                      </div>{" "}
                    </div>

                    <div>
                      {`${
                        elem.cost == 0
                          ? "gratis"
                          : elem.cost == 1
                          ? elem.cost + " crédito"
                          : elem.cost + " créditos"
                      }`}

                      {elem.current_capacity == elem.capacity ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          SIN CUPOS DISPONIBLES
                        </span>
                      ) : (
                        ` ${elem.capacity - elem.current_capacity}  ${
                          elem.capacity - elem.current_capacity > 1
                            ? "cupos disponibles"
                            : "cupo disponible"
                        }`
                      )}
                    </div>
                  </label>
                  <div className={"my-auto col-4"}>
                    <div className="d-flex my-auto w-100">
                      <div
                        className="mx-2 my-auto"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "700",
                            textTransform: "lowercase",
                          }}
                        >
                          {moment
                            .tz(elem.schedule_date, zonahorairachange)
                            .format("LT ")}
                          -{" "}
                          {moment
                            .tz(elem.schedule_date, zonahorairachange)
                            .add(1, "H")
                            .format("LT a")}
                        </div>
                      </div>

                      <label
                        className="content-inputCompanies position-relative"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          textTransform: "capitalize",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <input
                          value={elem.schedule_date}
                          type="radio"
                          name="radioteachersd"
                          key={`${elem.id}`}
                          onClick={(e) => {
                            setearvalorgrupal(e.target.value);
                            setdisablebutton(false);
                          }}
                        />
                        <i></i>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : entrenamientos_grupales && entrenamientos_grupales.length == 0 ? (
            <div
              className="title mx-auto"
              style={{
                width: "70%",
                textAlign: "center",
                fontSize: "3.0vmin",
                color: "#394F65",
                fontWeight: "400",
              }}
            >
              No se han programado clases grupales para este entrenamiento.
            </div>
          ) : null}
        </div>
      </div>

      {btnReservar}
      {modalInfo}
    </>
  );
};

export default ReservarClaseCompanies;
