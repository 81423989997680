import { Fragment, useEffect, useState, useRef } from "react";
import {
  Loadingspinner,
  ModalInfo,
  instance,
  NuevaAxiosURL,
} from "../utils/utils";
import Moment from "moment";
import { extendMoment } from "moment-range";
import minicalendar from "../assets/img/UserIcons/iconos/minicalendar.png";
import casete from "../assets/img/UserIcons/iconos/casete.png";

import "../Creditos/Creditos.css";

const ResumenTransacciones = (props) => {
  const user_data = props.user;
  const [dataTransaccions, setdataTransaccions] = useState(null);
  const moment = extendMoment(Moment);
  const laptop = window.matchMedia("(max-width: 920px)");
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [Year, setYear] = useState("");

  const añopresent = moment().format("YYYY");
  const anioCreditCard = [
    añopresent,
    añopresent - 1,
    añopresent - 2,
    añopresent - 3,
  ];
  function hideModal() {
    setModalInfo(null);
  }
  useEffect(() => {
    if (user_data.firts_name && user_data.email && user_data.last_name) {
      setIsLoaded(true);
    }

    instance
      .get(`${NuevaAxiosURL}customers/transactions/`)
      .then((resEn) => {
        setdataTransaccions(resEn.data);
      })
      .catch((err) => {
        console.error("Error en customers/plans/", err);
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });
  }, [isLoaded]);

  return (
    <>
      {laptop.matches === true ? (
        <div className="ContainerCreditos">
          <div className={`${isLoaded === false ? "" : "d-none"} my-3`}>
            <Loadingspinner customStyle="grow" size="5rem" />
          </div>
          <div className="title mx-auto ">Resumen de compras</div>
          <div>
            <div className="d-flex col-12 my-4 px-4 ">
              <div
                className="title m-auto"
                style={{ width: "78%", fontSize: "5vw" }}
              >
                Todas las transacciones
              </div>
              <div className=" mx-0 tipopersonP" style={{ width: "22%" }}>
                <select
                  onChange={(event) => {
                    setYear(event.target.value);
                  }}
                  name="Year"
                  style={{
                    fontSize: "3.0vw",
                    padding: " 1vh 1.5vw",
                    borderRadius: "15px",
                  }}
                  className="form-select"
                >
                  <option style={{ color: "#9DACBD" }} value="">
                    Año
                  </option>
                  {anioCreditCard.map((aniocreditcard) => (
                    <option
                      key={aniocreditcard}
                      style={{ color: "#9DACBD" }}
                      value={aniocreditcard}
                    >
                      {aniocreditcard}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {dataTransaccions &&
          dataTransaccions.filter(
            (e) =>
              moment(e.date.slice(0, 10), "YYYY-MM-DD").format("YYYY") == Year
          ).length > 0 ? (
            dataTransaccions
              .filter(
                (e) =>
                  moment(e.date.slice(0, 10), "YYYY-MM-DD").format("YYYY") ==
                  Year
              )
              .map((transaccion, idx) => (
                <div className="containerResumemtrans p-auto m-auto">
                  <div className="container  " key={idx}>
                    <div className="flex col-12 " style={{ fontSize: "3.5vw" }}>
                      <div className="d-row text-left col my-1">
                        <div
                          className="col text-left"
                          style={{
                            fontWeight: "700",
                            color: "#9DACBD",
                          }}
                        >
                          Clase
                        </div>
                        <div
                          style={{
                            color: "#9DACBD",
                          }}
                        >
                          {transaccion.description}
                        </div>
                      </div>

                      {transaccion.reference ? (
                        <div className="d-row text-left col my-1">
                          <div
                            className="col text-left"
                            style={{
                              fontWeight: "700",
                              color: "#9DACBD",
                              textTransform: "nowrap",
                            }}
                          >
                            Código reserva
                          </div>
                          <div
                            style={{
                              color: "#9DACBD",
                            }}
                          >
                            {transaccion.reference}
                          </div>
                        </div>
                      ) : null}

                      <div className="d-row text-left col my-1">
                        <div
                          className="col text-left"
                          style={{
                            fontWeight: "700",
                            color: "#9DACBD",
                          }}
                        >
                          Fecha
                        </div>
                        <div
                          style={{
                            color: "#9DACBD",
                          }}
                        >
                          {moment(
                            transaccion.date.slice(0, 10),
                            "YYYY-MM-DD"
                          ).format("dddd DD MMMM [de] YYYY")}
                        </div>
                      </div>
                      <div className="d-row text-left col my-1">
                        <div
                          className="col text-left"
                          style={{
                            fontWeight: "700",
                            color: "#9DACBD",
                          }}
                        >
                          Hora
                        </div>
                        <div
                          style={{
                            color: "#9DACBD",
                          }}
                        >
                          {" "}
                          {moment(
                            transaccion.date.slice(11, 15),
                            "HH:mm"
                          ).format("LT a")}
                        </div>
                      </div>
                      <div className="d-row text-left col my-1">
                        <div
                          className="col text-left"
                          style={{
                            fontWeight: "700",
                            color: "#9DACBD",
                          }}
                        >
                          Valor
                        </div>
                        <div
                          style={{
                            color: "#9DACBD",
                          }}
                        >
                          {Number(transaccion.amount) == 1
                            ? Number(transaccion.amount) + " crédito"
                            : Number(transaccion.amount) < 500
                            ? Number(transaccion.amount) + " créditos"
                            : transaccion.amount +
                              " " +
                              transaccion.currency}{" "}
                        </div>
                      </div>
                      <div className="d-row text-left col my-1">
                        <div
                          className="col text-left"
                          style={{
                            fontWeight: "700",
                            color: "#9DACBD",
                          }}
                        >
                          Estado
                        </div>
                        <div
                          style={{
                            color: "#9DACBD",
                          }}
                        >
                          {transaccion.status == 4
                            ? "Aprobada"
                            : transaccion.status == 6
                            ? "Declinada"
                            : transaccion.status == 7
                            ? "Fondos insuficientes"
                            : "Sin procesar"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" borderlineiconoscreditos my-3 mx-auto "></div>
                </div>
              ))
          ) : (
            <div>
              <div className="title mx-auto">
                <img src={Year == "" ? minicalendar : casete} />
              </div>
              <div
                className="title mx-auto"
                style={{ width: "78%", fontSize: "5vw" }}
              >
                {Year == ""
                  ? "Selecciona el periodo que deseas ver."
                  : "No hay transacciones disponibles para el año seleccionado."}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="">
          <div className="bievenidoainicio" style={{ marginLeft: "9vw" }}>
            <div className="d-flex">
              <div
                className="SubTitle8perfilcual corrgircoso"
                style={{ width: "12%" }}
              >
                Historial de compras
              </div>
              <div className="lineainiciousuario corrgircoso1"></div>
            </div>
            <div className="title creditos">Resumen de compras</div>
            <div
              className="textodebajoreservas tituloreservartuclasedebajo"
              style={{ marginLeft: "4.5vw" }}
            >
              Acá podrás revisar tu historial de compras y transacciones
              Realizadas con Elevva.
            </div>
          </div>
          <div className="ContainerCreditos">
            <div className={`${isLoaded === false ? "" : "d-none"} my-3`}>
              <Loadingspinner customStyle="grow" size="5rem" />
            </div>

            <div className="w-50" style={{ marginLeft: "7.5%" }}>
              <div>
                <div className="d-flex col-8 my-5 mx-0 ">
                  <div
                    className="title  my-auto col-3"
                    style={{ fontSize: "2rem", left: "0" }}
                  >
                    Todas las transacciones
                  </div>

                  <div className=" mx-0 tipopersonP col-3">
                    <select
                      onChange={(event) => {
                        setYear(event.target.value);
                      }}
                      name="Year"
                      style={{
                        fontSize: "1rem",
                        padding: " 1vh 1.5vw",
                        borderRadius: "20px",
                      }}
                      className="form-select   mt-4"
                    >
                      <option style={{ color: "#9DACBD" }} value="">
                        Año
                      </option>
                      {anioCreditCard.map((aniocreditcard) => (
                        <option
                          key={aniocreditcard}
                          style={{ color: "#9DACBD" }}
                          value={aniocreditcard}
                        >
                          {aniocreditcard}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              className=" borderlineiconoscreditos my-5  "
              style={{
                width: "79%",
                margin: "0rem 7.0%",
              }}
            ></div>
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "calc(88vh - 300px)",
                width: "94%",
              }}
            >
              {dataTransaccions &&
              dataTransaccions.filter(
                (e) =>
                  moment(e.date.slice(0, 10), "YYYY-MM-DD").format("YYYY") ==
                  Year
              ).length > 0 ? (
                dataTransaccions
                  .filter(
                    (e) =>
                      moment(e.date.slice(0, 10), "YYYY-MM-DD").format(
                        "YYYY"
                      ) == Year
                  )
                  .map((transaccion, idx) => (
                    <div
                      className="containerResumemtrans  "
                      style={{ margin: "0rem 7.5%" }}
                    >
                      <div
                        style={{
                          color: "#9DACBD",
                        }}
                        key={idx}
                      >
                        <div
                          className="flex col-12  "
                          style={{ fontSize: "1rem" }}
                        >
                          <div className="d-row text-left col ">
                            <div
                              className="col text-left"
                              style={{
                                fontWeight: "700",
                                color: "#9DACBD",
                              }}
                            >
                              Clase
                            </div>
                            <div
                              style={{
                                color: "#9DACBD",
                              }}
                            >
                              {transaccion.description}
                            </div>
                          </div>

                          {transaccion.reference ? (
                            <div className="d-row text-left col my-1">
                              <div
                                className="col text-left"
                                style={{
                                  fontWeight: "700",
                                  color: "#9DACBD",
                                  textTransform: "nowrap",
                                }}
                              >
                                Código reserva
                              </div>
                              <div
                                style={{
                                  color: "#9DACBD",
                                }}
                              >
                                {transaccion.reference}
                              </div>
                            </div>
                          ) : null}

                          <div className="d-row text-left col my-1">
                            <div
                              className="col text-left"
                              style={{
                                fontWeight: "700",
                                color: "#9DACBD",
                              }}
                            >
                              Fecha
                            </div>
                            <div
                              style={{
                                color: "#9DACBD",
                              }}
                            >
                              {moment(
                                transaccion.date.slice(0, 10),
                                "YYYY-MM-DD"
                              ).format("dddd DD MMMM [de] YYYY")}
                            </div>
                          </div>
                          <div className="d-row text-left col my-1">
                            <div
                              className="col text-left"
                              style={{
                                fontWeight: "700",
                                color: "#9DACBD",
                              }}
                            >
                              Hora
                            </div>
                            <div
                              style={{
                                color: "#9DACBD",
                              }}
                            >
                              {" "}
                              {moment(
                                transaccion.date.slice(11, 15),
                                "HH:mm"
                              ).format("LT a")}
                            </div>
                          </div>
                          <div className="d-row text-left col my-1">
                            <div
                              className="col text-left"
                              style={{
                                fontWeight: "700",
                                color: "#9DACBD",
                              }}
                            >
                              Valor
                            </div>
                            <div
                              style={{
                                color: "#9DACBD",
                              }}
                            >
                              {Number(transaccion.amount) == 1
                                ? Number(transaccion.amount) + " crédito"
                                : Number(transaccion.amount) < 500
                                ? Number(transaccion.amount) + " créditos"
                                : transaccion.amount +
                                  " " +
                                  transaccion.currency}{" "}
                            </div>
                          </div>
                          <div className="d-row text-left col my-1">
                            <div
                              className="col text-left"
                              style={{
                                fontWeight: "700",
                                color: "#9DACBD",
                              }}
                            >
                              Estado
                            </div>
                            <div
                              style={{
                                color: "#9DACBD",
                              }}
                            >
                              {transaccion.status == 4
                                ? "Aprobada"
                                : transaccion.status == 6
                                ? "Declinada"
                                : transaccion.status == 7
                                ? "Aprobada"
                                : "Sin procesar"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" borderlineiconoscreditos my-3 mx-auto "></div>
                    </div>
                  ))
              ) : (
                <div style={{ width: "90%" }}>
                  <div className="title mx-auto">
                    <img
                      className="title mx-auto my-4"
                      style={{
                        width: "5vw",
                        fontSize: "2rem",
                        textAlign: "center",
                        left: "45%",
                      }}
                      src={Year == "" ? minicalendar : casete}
                    />
                  </div>
                  <div
                    className="title mx-auto"
                    style={{
                      fontSize: "2rem",
                      left: "0",
                      textAlign: "center",
                      left: "4%",
                    }}
                  >
                    {Year == ""
                      ? "Selecciona el periodo que deseas ver."
                      : "No hay transacciones disponibles para el año seleccionado."}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResumenTransacciones;
