import { Fragment, useEffect, useState, useRef } from "react";
import { ResponsiveLine, Line } from "@nivo/line";

import Moment from "moment";
import { extendMoment } from "moment-range";
import { instance, laptop575 } from "../utils/utils";



const TrackingAgora = (props) => {
  const moment = extendMoment(Moment);
  const [dataf, setdata] = useState(null);
  const [dataclases, setdataclases] = useState(null);
  const [idclase, setidclase] = useState(null);
  const [grafica, setgrafica] = useState(null);

  const theme1 = {
    // background: "#2A9DA5",
    axis: {
      fontSize: "3px",

      domain: {
        line: {
          stroke: "#959595",
          strokeWidth: 0.5,
        },
      },
      ticks: {
        line: {
          stroke: "#959595",
          strokeWidth: 0.5,
        },
        text: {
          fill: "#555555",
        },
      },
      legend: {
        text: {
          fontSize: laptop575.matches ? "1vw" : "3vw",
          fill: "#2A9DA5",
          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "#ffffff",

        strokeWidth: 1,
      },
    },
  };
  useEffect(() => {
    instance
      .get("booking/?any")
      .then((response) => {
        if (response.data) {
          setdataclases(
            response.data.filter((e) => e.room_id !== 0).map((e) => e.id)
          );
        }
      })
      .catch((err) => console.log(err.response));
  }, []);
  useEffect(() => {
    if (idclase) {
      instance
        .get(`booking/book/${idclase}/log/`)
        .then((response) => {
          if (response.data) {
            console.log(response.data)
            setdata(response.data);
          }
        })
        .catch((err) => console.log(err.response));
    }
  }, [idclase]);
  let total_graficauser = [];
  let total_graficustomer = [];
  if (dataclases && dataf && grafica) {
    const elarrargrafica = grafica.split("/");
    dataf
      .map((e) => {
        if (e.logdata.type == 10 && e.logdata[`${elarrargrafica[0]}`]) {
          total_graficauser[e.id] = {
            x: e.date.slice(0, 25),
            y: e.logdata[`${elarrargrafica[0]}`][`${elarrargrafica[1]}`],
          };
        }
      })
      .filter((e) => e);
  }
  if (dataclases && dataf && grafica) {
    const elarrargrafica = grafica.split("/");
    dataf
      .map((e) => {
        if (e.logdata.type == 20 && e.logdata[`${elarrargrafica[0]}`]) {
          total_graficustomer[e.id] = {
            x: e.date.slice(0, 25),
            y: e.logdata[`${elarrargrafica[0]}`][`${elarrargrafica[1]}`],
          };
        }
      })
      .filter((e) => e);
  }

  const customerData = [
    {
      id: "customerData",
      data: total_graficauser.filter((e) => e),
    },
  ];
  const trainerData = [
    {
      id: "trainerData",
      data: total_graficustomer.filter((e) => e),
    },
  ];

  function getData(data) {
    let outer_buffer = [];
    for (let i = 0; i < data.length; i++) {
      let packet = {};
      let inner_buffer = [];
      for (let j = 0; j < data[i]["data"].length; j++) {
        let buffer = {
          x: moment(data[i]["data"][j]["x"]).format("YYYY-MM-DD HH:mm:ss"),
          y: data[i]["data"][j]["y"],
        };
        inner_buffer.push(buffer);
      }
      packet["data"] = inner_buffer;
      packet["id"] = "ID" + (i + 1);
      outer_buffer.push(packet);
    }
    return outer_buffer;
  }
  return (
    <div className=" m-auto text-center d-row" style={{ width: "90%" }}>
      <div className="w-100">
        {" "}
        <select
          className="w-50 "
          style={{ textAlign: "center" }}
          onChange={(event) => {
            setidclase(event.target.value);
          }}
        >
          <option style={{ color: "#BEBEBE" }} value="">
            -Seleccione las clase my friend-
          </option>
          {dataclases &&
            dataclases
              .sort((a, b) => b - a)
              .map((ef, i) => (
                <option key={i} style={{ color: "black" }}>
                  {ef}
                </option>
              ))}
        </select>
        <select
          className="w-50"
          style={{ textAlign: "center" }}
          onChange={(event) => {
            setgrafica(event.target.value);
          }}
        >
          <option value="">-Seleccione la gráfica que desea ver-</option>
          <option value="audioTrackStats/sendBitrate">
            audioTrackStats/sendBitrate
          </option>
          <option value="audioTrackStats/sendBytes">
            audioTrackStats/sendBytes
          </option>
          <option value="audioTrackStats/sendPackets">
            audioTrackStats/sendPackets
          </option>
          <option value="audioTrackStats/sendPacketsLost">
            audioTrackStats/sendPacketsLost
          </option>
          <option value="audioTrackStats/sendVolumeLevel">
            audioTrackStats/sendVolumeLevel
          </option>
          <option value="getRTCStats/Duration">getRTCStats/Duration</option>
          <option value="getRTCStats/OutgoingAvailableBandwidth">
            getRTCStats/OutgoingAvailableBandwidth
          </option>
          <option value="getRTCStats/RTT">getRTCStats/RTT</option>
          <option value="getRTCStats/RecvBitrate">
            getRTCStats/RecvBitrate
          </option>
          <option value="getRTCStats/RecvBytes">getRTCStats/RecvBytes</option>
          <option value="getRTCStats/SendBitrate">
            getRTCStats/SendBitrate
          </option>
          <option value="getRTCStats/SendBytes">getRTCStats/SendBytes</option>
          <option value="getRTCStats/UserCount">getRTCStats/UserCount</option>
          {/* <option value="remoteAudioTrack">remoteAudioTrack</option>
          <option value="remoteVideoTrack">remoteVideoTrack</option> */}
          <option value="videoTrackStats/captureFrameRate">
            videoTrackStats/captureFrameRate
          </option>
          <option value="videoTrackStats/captureResolutionHeight">
            videoTrackStats/captureResolutionHeight
          </option>
          <option value="videoTrackStats/captureResolutionWidth">
            videoTrackStats/captureResolutionWidth
          </option>
          <option value="videoTrackStats/encodeDelay">
            videoTrackStats/encodeDelay
          </option>
          <option value="videoTrackStats/sendBitrate">
            videoTrackStats/sendBitrate
          </option>
          <option value="videoTrackStats/sendBytes">
            videoTrackStats/sendBytes
          </option>
          <option value="videoTrackStats/sendFrameRate">
            videoTrackStats/sendFrameRate
          </option>
          <option value="videoTrackStats/sendPackets">
            videoTrackStats/sendPackets
          </option>
          <option value="videoTrackStats/sendPacketsLost">
            videoTrackStats/sendPacketsLost
          </option>
          <option value="videoTrackStats/sendResolutionHeight">
            videoTrackStats/sendResolutionHeight
          </option>
          <option value="videoTrackStats/totalFreezeTime">
            videoTrackStats/totalFreezeTime
          </option>
          <option value="videoTrackStats/sendResolutionWidth">
            videoTrackStats/sendResolutionWidth
          </option>
        </select>
      </div>

      {trainerData && customerData && grafica ? (
        <div
          className="w-100 d-flex "
          style={{ padding: "4% 0 ", height: "90vh" }}
        >
          <div
            className="w-50"
            style={{
              height: "100%",

              width: "100%",
            }}
          >
            <p
              style={{
                fontFamily: "WorkSans",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
            >
              Customer
            </p>
            <ResponsiveLine
              data={getData(customerData)}
              margin={{
                top: 50,
                right: 70,
                bottom: 130,
                left: 80,
              }}
              xScale={{
                type: "time",
                format: "%Y-%m-%d %H:%M:%S",
                precision: "minute",
              }}
              xFormat="time:%Y-%m-%d %H:%M:%S"
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",

                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              enableGridX={false}
              lineWidth={1}
              axisBottom={{
                format: "%Y-%m-%d %H:%M:%S",
                tickValues: "every 10 minutes",
                legend: "time scale",
                legendOffset: -12,
                orient: "bottom",
                tickValues: 11,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: "date",
                legendOffset: 100,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 4,
                tickPadding: 5,
                legend: grafica.split("/")[1],
                tickRotation: 0,
                legendOffset: -70,
                legendPosition: "middle",
              }}
              colors={["#2A9DA5"]}
              theme={theme1}
              pointSize={9}
              pointColor={"#ffffff"}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabel="y"
              enableArea={true}
              // areaBaselineValue={
              //   selOptionCuerpo[indice][4][0].data.map((e, i) => e.y).sort()[0]
              // }
              areaOpacity={0.3}
              defs={[
                {
                  id: "gradientC",

                  type: "linearGradient",

                  colors: [
                    { offset: 15, color: "#2A9DA5" },

                    { offset: 100, color: "#ffff" },
                  ],
                },
              ]}
              fill={[{ match: "*", id: "gradientC" }]}
              useMesh={true}
            />
          </div>
          <div
            className="w-50"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <p
              style={{
                fontWeight: "700",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
            >
              Trainer
            </p>
            <ResponsiveLine
              data={getData(trainerData)}
              margin={{
                top: 50,
                right: 70,
                bottom: 130,
                left: 80,
              }}
              xScale={{
                type: "time",
                format: "%Y-%m-%d %H:%M:%S",
                precision: "minute",
              }}
              xFormat="time:%Y-%m-%d %H:%M:%S"
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",

                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              enableGridX={false}
              lineWidth={1}
              axisBottom={{
                format: "%Y-%m-%d %H:%M:%S",
                tickValues: "every 10 minutes",
                legend: "time scale",
                legendOffset: -12,
                orient: "bottom",
                tickValues: 11,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: "date",
                legendOffset: 100,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 4,
                tickPadding: 5,
                legend: grafica.split("/")[1],
                tickRotation: 0,
                legendOffset: -70,
                legendPosition: "middle",
              }}
              colors={["#2A9DA5"]}
              theme={theme1}
              pointSize={9}
              pointColor={"#ffffff"}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabel="y"
              enableArea={true}
              // areaBaselineValue={
              //   selOptionCuerpo[indice][4][0].data.map((e, i) => e.y).sort()[0]
              // }
              areaOpacity={0.3}
              defs={[
                {
                  id: "gradientC",

                  type: "linearGradient",

                  colors: [
                    { offset: 15, color: "#2A9DA5" },

                    { offset: 100, color: "#ffff" },
                  ],
                },
              ]}
              fill={[{ match: "*", id: "gradientC" }]}
              useMesh={true}
            />
          </div>
        </div>
      ) : (
        <div>No has seleccionado la clase o la gráfica deseada </div>
      )}
    </div>
  );
};
export default TrackingAgora;
