import { useHistory, useParams } from "react-router";
import { laptop575, ModalInfoVideosFilter } from "../utils/utils";
import { useEffect, useState, useRef } from "react";
import { AllImagenes } from "../Ejercicios/allvideos";
import { Videos_two } from "../Ejercicios/othervideos";
import { Link } from "react-router-dom";
import { calculateJwkThumbprint } from "jose";
const VerTipo = (props) => {
  const { tipo } = useParams();
  const searchText = useRef(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [actualizar, setactualizar] = useState(false);
  const Ocultar = () => {
    setModalInfo(null);
  };

  const images = [
    [
      process.env.PUBLIC_URL + "/img/cardio_core.png",
      "Entrenamiento",
      "Cardio core",
      "",
      true,
      [
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/videomente1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/videomente2.png"],
        [
          "Hábitos alimenticios",
          process.env.PUBLIC_URL + "/img/videomente3.png",
        ],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/videomente4.png"],
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/videomente1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/videomente2.png"],
        [
          "Hábitos alimenticios",
          process.env.PUBLIC_URL + "/img/videomente3.png",
        ],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/videomente4.png"],
      ],
      "pin",
      "Box",
      "Todo el cuerpo",
      1,
      "Principiante",
    ],
    [
      process.env.PUBLIC_URL + "/img/tren_superior.png",
      "Entrenamiento",
      "Tren superior",
      "",
      false,
      [
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
        ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
        ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
      ],
      "pindos",
      "Pesa rusa",
      "Tren superior",
      2,
      "Intermedio",
    ],
    [
      process.env.PUBLIC_URL + "/img/hiit_training.png",
      "Entrenamiento",
      "Hiit training",
      "",
      false,
      [
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
        ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
        ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
        ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
        ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
        ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
      ],
      "pin",
      "Barra",
      "Espalda",
      3,
      "Avanzado",
    ],
  ];
  const [filtervideos, setfiltervideos] = useState(images);
  function changeOptionVideos(valuesearch) {
    var videofiltro;
    if (valuesearch != "") {
      videofiltro = filtervideos.filter((e) =>
        e[2].toLowerCase().includes(valuesearch.toLowerCase())
      );
    } else {
      videofiltro = images;
    }
    console.log(videofiltro);
    setfiltervideos(videofiltro);
  }

  const [inputs, setInputs] = useState({
    ["Equipamiento"]: [[]],
    ["Disciplina"]: [[]],
    ["Zona a trabajar"]: [[]],
    ["Dificultad"]: [[]],
    ["Tiempo"]: [[]],
    ["Tipo"]: [[]],
  });
  function filtrarButtons(valuesearch, local) {
    console.log(valuesearch, local, inputs[local]);
    var pepe = inputs[local][0].filter((e) => e != valuesearch);
    console.log(pepe);
    setInputs((val) => ({
      ...val,
      [local]: [pepe],
    }));
    //  inputs[local].filter(e=>)
    // var videofiltro = inputs;

    //    setfiltervideos(videofiltro);
  }
  var handleChange = (event) => {
    let name = Object.keys(event);
    let value = Object.values(event);
    console.log(name, value);
    setInputs((val) => ({ ...val, [name]: value }));
  };
  useEffect(() => {
    let guardado = [];
    let entrenamientosd = images;
    if (
      inputs["Disciplina"][0] &&
      inputs["Equipamiento"][0] &&
      inputs["Zona a trabajar"][0] &&
      inputs["Dificultad"][0] &&
      inputs["Tipo"][0] &&
      inputs["Tiempo"][0]
    ) {
      let inputsAll = inputs["Disciplina"][0]
        .concat(inputs["Equipamiento"][0])
        .concat(inputs["Zona a trabajar"][0])
        .concat(inputs["Dificultad"][0])
        .concat(inputs["Tiempo"][0])
        .concat(inputs["Tipo"][0]);

      if (inputs["Disciplina"] && inputsAll.length > 0) {
        for (let i = 0; i < inputsAll.length; i++) {
          for (let j = 0; j < entrenamientosd.length; j++) {
            if (
              entrenamientosd[j][2].includes(inputsAll[i]) ||
              entrenamientosd[j][7].includes(inputsAll[i]) ||
              entrenamientosd[j][8].includes(inputsAll[i]) ||
              entrenamientosd[j][10].includes(inputsAll[i])
            ) {
              let revisarrepetirdos = guardado.map((e) => e[9]);
              if (!revisarrepetirdos.includes(entrenamientosd[j][9])) {
                guardado.push(entrenamientosd[j]);
              }
            }
          }
        }

        setfiltervideos(guardado);
      } else {
        setfiltervideos(images);
      }
    }
  }, [inputs]);

  return (
    <div
      className="d-flex m-auto"
      style={{
        height: "100vh",
      }}
    >
      {/* {laptop575.matches ? (
        <div className="mx-auto" style={{ width: "7.1%" }}></div>
      ) : null} */}
      {laptop575.matches ? (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex"
            style={{
              background: "rgb(241, 246, 249)",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
              flexDirection: "column",
            }}
          >
            <div
              className="py-2 mx-3 banner_one "
              style={{
                background: "rgb(241, 246, 249)",

                color: "#394F65",
              }}
            >
              {" "}
              <Link
                to="/ejercicios"
                className=" position-absolute"
                style={{
                  left: "5%",
                  top: "35%",
                  justifyContent: "center",

                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {" "}
                <span className="d-flex m-auto">
                  <span
                    className="my-auto mx-1 px-2 py-1 d-flex"
                    style={{
                      border: "1px solid #394F65",
                      justifyContent: "center",
                      color: "#394F65",
                      borderRadius: "10px",
                    }}
                  >
                    {" "}
                    <i className="fa fa-arrow-left  "></i>
                  </span>
                  <span className="my-auto m-1" style={{ color: "#394F65" }}>
                    Regresar
                  </span>
                </span>
              </Link>
              <div
                className="d-flex position-absolute"
                style={{
                  left: "45%",
                  top: "30%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <img
                  style={{ width: "200px" }}
                  src={process.env.PUBLIC_URL + "/img/logosEmpresas.png"}
                />{" "}
              </div>
              {tipo == "Buscar" ? (
                <div className="form searchEjercicio mx-auto py-4 d-flex"></div>
              ) : (
                <Link
                  className="form searchEjercicio mx-auto py-2 d-flex"
                  style={{ justifyContent: "flex-end" }}
                  to="/ejercicios/Buscar"
                >
                  <input
                    placeholder="Buscar..."
                    ref={searchText}
                    type="search"
                    className="input_searchvideos ejercicios_c  py-2 "
                  ></input>
                </Link>
              )}
            </div>
          </div>
          {tipo == "Buscar" ? (
            <div
              className="containerBackgroundCreateuserSearch d-flex"
              style={{
                padding: "0% 4%",
                justifyContent: "center",
                flexDirection: "column",
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/img/buscaralto.jpg"
                })`,
              }}
            >
              <span
                style={{ fontWeight: "700", color: "#ffff", fontSize: "40px" }}
              >
                Buscar
              </span>
              <div
                className="form searchEjercicio py-2 d-flex"
                to="/ejercicios/Buscar"
              >
                <input
                  placeholder="Buscar..."
                  ref={searchText}
                  type="search"
                  className="input_searchvideossearch ejercicios_c  py-2 "
                  onChange={(e) => changeOptionVideos(e.target.value)}
                ></input>
                <img
                  onClick={() =>
                    setModalInfo(
                      <ModalInfoVideosFilter
                        error="false"
                        handleClick={() =>
                          setInputs({
                            ["Equipamiento"]: [[]],
                            ["Disciplina"]: [[]],
                            ["Zona a trabajar"]: [[]],
                            ["Dificultad"]: [[]],
                            ["Tiempo"]: [[]],
                            ["Tipo"]: [[]],
                          })
                        }
                        handleInput={(e) => handleChange(e)}
                        handleCancel={() => {
                          Ocultar();
                        }}
                      />
                    )
                  }
                  className="mx-2"
                  style={{ width: "35px", cursor: "pointer" }}
                  src={process.env.PUBLIC_URL + "/img/filter.svg"}
                />
              </div>
              <div
                className="d-flex my-1"
                style={{ justifyContent: "flex-start" }}
              >
                {Object.entries(inputs).map((e) => (
                  <>
                    {e[1][0].length > 0
                      ? e[1][0].map((but, i) => (
                          <button
                            key={i}
                            onClick={() => filtrarButtons(but, e[0])}
                            className="py-1 mx-1"
                            style={{
                              borderRadius: "20px",
                              border: "1px solid white",
                              background: "transparent",
                              color: "white",
                            }}
                          >
                            <span className="ms-1">{but} </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-x me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </button>
                        ))
                      : null}
                  </>
                ))}
              </div>
            </div>
          ) : null}
          <div
            id="routeA"
            style={{
              paddingLeft: "5%",
            }}
          >
            <AllImagenes
              textTitleOne="Entrenamiento funcional"
              textTitleTwo={tipo == "Buscar" ? "Disciplinas" : tipo}
              id={"vertipo"}
              videos={filtervideos}
              textealo={tipo}
              className="sliderNetFlixvideocomidas"
            />
          </div>
          <br />
          <div
            id="routeB"
            style={
              tipo == "Buscar"
                ? {
                    backgroundColor: "#ffff",
                    paddingLeft: "5%",
                    paddingTop: "3%",
                  }
                : {
                    backgroundColor: "#FDF1E8",
                    paddingLeft: "5%",
                    paddingTop: "3%",
                  }
            }
          >
            {filtervideos.map((e) => (
              <Videos_two
                textTitleOne="Cardio"
                textTitleTwo={e[2]}
                videosTime={e[5]}
                id={e[6]}
              />
            ))}
          </div>
        </div>
      ) : null}
      {modalInfo}{" "}
    </div>
  );
};
export default VerTipo;
