import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import "bootstrap/dist/js/bootstrap.bundle.min";
import WebFont from "webfontloader";
import { ErrorBoundary } from "react-error-boundary";
import store from "./Store";
import { Loadingspinner } from "./utils/utils";
WebFont.load({
  google: {
    families: ["Work Sans:100,200,300,400,500,600,700,800,900", "sans-serif"],
  },
});
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ErrorBoundary>
        <ClearBrowserCacheBoundary
          auto
          // fallback={<Loadingspinner />}
          duration={60000}
        >
          <App />
        </ClearBrowserCacheBoundary>
      </ErrorBoundary>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// reportWebVitals(console.log);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
