import {
  basename,
  ButtonMob,
  instance,
  laptop,
  ModalInfo,
  ModalInforeservas,
} from "../utils/utils";
import ScrollToTop from "../ScrollToTop";
import {
  Redirect,
  useParams,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import { ContaineReservaInfo } from "./Reservas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import stargolden from "../assets/img/reservasimages/Grupo 6457.png";
import intensidad_img from "../assets/img/reservasimages/icon_extra_1a.png";
import calendar from "../assets/img/reservasimages/calendar.png";
import checkfrom from "../assets/img/creditos/imagenes/checkcompletar.png";
import imgarriba1 from "../assets/img/Auth_images/arribaimagen.png";
import imgabajo1 from "../assets/img/Auth_images/abajoimagen.png";
import { isIOS } from "react-device-detect";
import "./Reservas.css";
import { useEffect, useState } from "react";

import ReactHtmlParser from "react-html-parser";

const ReservasOnly = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const path = useLocation();
  const [modalInfo, setModalInfo] = useState(null);
  const [data, setdata] = useState(null);

  const [entrenamientos_grupales, setentrenamientos_grupales] = useState(null);
  const laptop = window.matchMedia("(max-width: 920px)");

  function searchinensidad(intensity, group, cost) {
    if (cost < 1) {
      if (intensity == 10 && group == false) {
        return "Entrenamiento Baja Intensidad";
      } else if (intensity == 20 && group == false) {
        return "Entrenamiento Media Intensidad";
      } else if (intensity == 30 && group == false) {
        return "Entrenamiento Alta Intensidad";
      } else return "Entrenamiento grupales";
    } else {
      if (intensity == 10 && group == false) {
        return "Entrenamiento Baja Intensidad";
      } else if (intensity == 20 && group == false) {
        return "Entrenamiento Media Intensidad";
      } else if (intensity == 30 && group == false) {
        return "Entrenamiento Alta Intensidad";
      } else return "Entrenamiento Grupales";
    }
  }
  const creditosCliente =
    props.dataCustomer.customer && props.dataCustomer.customer.credits_balance;
  const Triage =
    props.dataCustomer.customer &&
    props.dataCustomer.customer.emergency_contact != ""
      ? false
      : true;
  const HadValoration =
    props.dataCustomer.customer && props.dataCustomer.customer.had_valoration;
  const CanReserve =
    props.dataCustomer.customer && props.dataCustomer.customer.can_reserve;
  const Onboarding =
    props.dataCustomer.customer && props.dataCustomer.customer.transportation;

  useEffect(() => {
    instance
      .get(`trainings/${id}/`)
      .then((res) => {
        setdata(res.data);
      })
      .catch((err) => console.log(err));
    instance
      .get(`/booking/group/${id}/schedule/`)
      .then((res) => {
        const data = res.data;
        if (data) {
          setentrenamientos_grupales(data[0]);
        }
      })
      .catch((err) => {
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });
    if (CanReserve == false) {
      setModalInfo(
        <>
          <ModalInfo
            error="none"
            handleClick={hideModal}
            title="Antes de empezar a hacer actividad física"
            text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
          />
        </>
      );
    }
  }, [props.dataCustomer]);
  function hideModal() {
    setModalInfo(null);
  }

  function moreInfo(msg, title, icon) {
    setModalInfo(
      <ModalInforeservas
        error="none"
        handleClick={hideModal}
        title={title}
        icon={icon}
        text={ReactHtmlParser(unescape(msg))}
        classesMsg="modalMsgReserva"
      />
    );
  }
  function wheresend(grupales, id, creditos) {
    localStorage.completarTriage = id;
    if (CanReserve == true) {
      if (grupales && Triage == false && creditos == 0) {
        history.push(`/Usuario/reservas/${id}`);
        // document.location.href = `${basename}/Usuario/reservas/${id}`;
      } else if (grupales && Triage == true && creditos == 0) {
        //si lleno contacto setea false

        document.location.href = `${basename}/Usuario/completarTriage`;
      } else {
        history.push("/Usuario/creditos");
      }
    } else {
      history.push("/Usuario/creditos");
    }
  }

  function elegirFecha() {
    setModalInfo(<Redirect to={path.pathname.replace("/only", "")} />);
  }
  return (
    <>
      <img className="imgarribareservas" src={imgarriba1} />
      <img className="imgabajoreservas" src={imgabajo1} />
      <div className="containerReservas">
        <ScrollToTop />
        <div className=" mt-3  text-center">
          <div className="maxreservar">
            <div className="d-flex ">
              <div className="SubTitle8reservatuclase">Reservar</div>
              <div className="lineareservatuclaseonly"></div>
            </div>
            <div className="title tituloreservartuclase ">Reserva tu clase</div>
            <div className="textodebajoreservas tituloreservartuclasedebajo">
              En ELEVVA eres dueño de tu tiempo, agenda tu entrenamiento en los
              días y horario de tu preferencia.
            </div>
          </div>
          <div
            className="breadcum backDisciplinas disciplinasmin"
            onClick={props.handleClickOnly}
          >
            <span>Disciplinas</span>
            <FontAwesomeIcon className="mx-2" icon={faPlay} color="#9EACED" />
            {data && data.name}
          </div>
        </div>
        <div className="separador py-2  separadormin"></div>
        <div className="slidersReservas row g-0 ">
          <div className="">
            {data ? (
              <ContaineReservaInfo
                history={history}
                intensidadImg={intensidad_img}
                bg_img={data.cover_picture}
                titulo={data.name}
                intensidadMsgGrupal={
                  data.intensity == 10
                    ? "Entrenamiento Baja Intensidad"
                    : data.intensity == 20
                    ? "Entrenamiento Media Intensidad"
                    : "Entrenamiento Alta Intensidad"
                }
                intensidadMsg={searchinensidad(
                  data.intensity,
                  data.group,
                  data.credits
                )}
                duracion={data.time}
                creditosClase={data.credits}
                calorias={data.calories}
                grupales={data.group}
                icon={data.icon}
                description={data.description}
                moreInfo={moreInfo}
              />
            ) : null}

            <div className=" maximoletrasreservas  ">
              {creditosCliente === 0 ? (
                <div
                  style={{ backgroundColor: "#EFE2D7" }}
                  className="letrasconviertemosbtertext"
                >
                  <div
                    style={{
                      fontWeight: "700",
                      color: "#CB3D82",
                      fontSize: "4vw",
                      textAlign: "center",
                    }}
                  >
                    ¡CONVIÉRTETE EN ELEVVA+!
                  </div>
                  <div
                    style={{
                      fontWeight: "700",
                      textAlign: "center",
                      fontSize: "4vw",
                    }}
                  >
                    Compra tus clases y disfruta de grandes beneficios
                  </div>
                </div>
              ) : (
                <div style={{ padding: "1rem 1.7rem", color: "#9DACBD" }}>
                  {data && data.description}{" "}
                </div>
              )}
              {creditosCliente == 0 ? (
                <div style={{ position: "relative" }}>
                  <div className="letrasconviertemosbtertextreservas">
                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Clase de valoración gratis.
                      </span>
                    </div>
                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Plan de entrenamiento bimensual.
                      </span>
                    </div>
                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Plan de alimentación sugerido.
                      </span>
                    </div>
                    <div
                      className="itemRecom  d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span
                        style={{
                          padding: "auto 2vw",
                          marginLeft: "2vw",
                        }}
                      >
                        Entrenamiento 1 a 1 con un profesional online.
                      </span>
                    </div>
                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Reservar tu clase el día y la hora que desees.
                      </span>
                    </div>

                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Asesoría 24 horas.
                      </span>
                    </div>
                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Generación de puntos Elevva y redención de premios.
                      </span>
                    </div>
                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Análisis de métricas y de progreso bimensual.
                      </span>
                    </div>
                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Vinculación de dispositivos ELEVVA durante tus clases.
                      </span>
                    </div>
                    <div
                      className="itemRecom d-flex"
                      style={{ padding: "1vw" }}
                    >
                      <img
                        src={stargolden}
                        alt="Icon"
                        style={{
                          margin: "0 1vw",
                          width: "6vw",
                          height: "6vw",
                        }}
                      />
                      <span style={{ padding: "1vw 0", marginLeft: "2vw" }}>
                        Clases grupales sin límite.
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {data && data.grupales == true && laptop.matches ? (
              <div
                style={{
                  color: "#394F65",
                  fontWeight: "700",
                  margin: "2vh",
                }}
              >
                CUPO :{" "}
                {entrenamientos_grupales
                  ? entrenamientos_grupales.capacity -
                    entrenamientos_grupales.current_capacity
                  : "0"}{" "}
                PERSONAS
              </div>
            ) : null}

            {creditosCliente && creditosCliente > 0 && CanReserve == true ? (
              <div
                style={
                  laptop.matches
                    ? {
                        position: "absolute",
                        width: "100%",
                        bottom: "5rem",
                      }
                    : null
                }
                className="containerResevar mt-4"
                onClick={
                  !HadValoration && Onboarding
                    ? () =>
                        (window.location.href = `${basename}/Usuario/reservas/10`)
                    : Onboarding && HadValoration
                    ? () => elegirFecha()
                    : !HadValoration && !Onboarding
                    ? () =>
                        (window.location.href = `${basename}/Usuario/completar`)
                    : null
                }
              >
                {laptop.matches ? (
                  <ButtonMob
                    text={
                      HadValoration == false && Onboarding
                        ? "Reservar valoración"
                        : Onboarding && HadValoration
                        ? "Reservar"
                        : !HadValoration && !Onboarding
                        ? "Completar perfil"
                        : null
                    }
                    className={
                      HadValoration ||
                      window.location.pathname ==
                        `${basename}/Usuario/reservas/10`
                        ? "text-left ps-4 btnReserva izquierbuton "
                        : "text-left ps-4 btnReservaHadval  "
                    }
                    image={calendar}
                    classesImg="btnCalendar ms-3"
                  />
                ) : (
                  <ButtonMob
                    colorButton={"#CB3D82"}
                    text={
                      HadValoration == false && Onboarding
                        ? "Reservar valoración"
                        : Onboarding && HadValoration
                        ? "Reservar"
                        : !HadValoration && !Onboarding
                        ? "Completar perfil"
                        : null
                    }
                    className="text-left ps-4 btnReserva izquierbuton"
                    image2={calendar}
                    classesImg="btnCalendar ms-3"
                  />
                )}
              </div>
            ) : data ? (
              <div
                style={
                  laptop.matches
                    ? {
                        position: "absolute",
                        width: "100%",
                        bottom: "4rem",
                      }
                    : null
                }
                className="containerResevar mt-4"
                onClick={() => wheresend(data.group, data.id, data.credits)}
              >
                {laptop.matches ? (
                  <ButtonMob
                    text={
                      data.group && Triage && data.credits == 0
                        ? "Reserva ya"
                        : data.group && !Triage && data.credits == 0
                        ? "Reservar"
                        : "Comprar créditos"
                    }
                    className="text-left ps-4 btnReserva "
                    image={data.group ? checkfrom : calendar}
                    classesImg="btnCalendar ms-3"
                  />
                ) : (
                  <ButtonMob
                    text={
                      data.group && Triage && data.credits == 0
                        ? "Reserva ya"
                        : data.group && !Triage && data.credits == 0
                        ? "Reservar"
                        : "Comprar créditos"
                    }
                    className="text-left ps-4 btnReserva izquierbuton"
                    image2={calendar}
                    classesImg="btnCalendar ms-3"
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {modalInfo}{" "}
    </>
  );
};
export default ReservasOnly;
