import { instance } from "../utils/utils";
import Moment from "moment";
import { extendMoment } from "moment-range";
async function promise_one(finislongdays, inicioday) {
  return instance
    .get(`booking/?date__lte=${finislongdays}&date__gte=${inicioday}`)
    .then(async (response) => response.data)
    .then(async (data) => data)
    .catch((err) => console.error(err));
}

async function promise_two(finislongdays, inicioday) {
  const arrar_group = [];
  return instance
    .get(`booking/group/?date__lte=${finislongdays}&date__gte=${inicioday}`)
    .then(async (response) => response.data)
    .then(async (data) => {
      for (const iterator of data) {
        arrar_group[iterator.id] = {
          training: {
            id: "d" + iterator.training.id,
            name: iterator.training.name,
            main_picture: iterator.training.main_picture,
          },

          id: iterator.id,
          is_group: true,
          date: iterator.schedule_date,
          bookings: iterator.bookings,
          capacity: iterator.capacity,
        };
      }
      return arrar_group.filter((e) => e);
    })
    .catch((err) => console.error(err));
}

async function promise_three(finislongdays, inicioday, idf, condition) {
  const moment = extendMoment(Moment);
  const info = [];
  return instance
    .get(`booking/?available&date__lte=${finislongdays}&date__gte=${inicioday}`)

    .then(async (response) => response.data)
    .then(async (data) => {
      for (const i of data) {
        info[i.id] = {
          id: i.id,
          fecha: i.date.replace("T", " ").slice(0, 16).slice(0, 10),
          hora: i.date.replace("T", " ").slice(0, 16).slice(11, 16),
          name: unescape(i.training.name).toLowerCase(),
          cost: i.cost,
          trainer: i.trainer,
          status: i.status,
        };
      }

      if (idf == null && condition == null) {
        return info.filter((e) => e);
      } else if (idf == 1 && condition == true) {
        return info
          .filter((e) => e)
          .sort(function (a, b) {
            var nameA = b.name.toLowerCase(); // ignore upper and lowercase
            var nameB = a.name.toLowerCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
      } else if (idf == 1 && condition == false) {
        return info
          .filter((e) => e)
          .sort(function (a, b) {
            var nameA = a.name.toLowerCase(); // ignore upper and lowercase
            var nameB = b.name.toLowerCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
      } else if (idf == 2 && condition == true) {
        return info
          .filter((e) => e)

          .sort(function (a, b) {
            const fechaA = moment(a.fecha, "YYYY-MM-DD");
            const fechaB = moment(b.fecha, "YYYY-MM-DD");
            if (fechaA > fechaB) {
              return -1;
            }
            if (fechaA < fechaB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
      } else if (idf == 2 && condition == false) {
        return info
          .filter((e) => e)
          .sort(function (a, b) {
            const fechaA = moment(b.fecha, "YYYY-MM-DD");
            const fechaB = moment(a.fecha, "YYYY-MM-DD");
            if (fechaA > fechaB) {
              return -1;
            }
            if (fechaA < fechaB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
      } else if (idf == 3 && condition == true) {
        return info
          .filter((e) => e)
          .sort(function (a, b) {
            const fechaA = b.cost;
            const fechaB = a.cost;
            if (fechaA > fechaB) {
              return -1;
            }
            if (fechaA < fechaB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
      } else if (idf == 3 && condition == false) {
        return info
          .filter((e) => e)
          .sort(function (a, b) {
            const fechaA = a.cost;
            const fechaB = b.cost;
            if (fechaA > fechaB) {
              return -1;
            }
            if (fechaA < fechaB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
      } else if (idf == 4 && condition == true) {
        return info
          .filter((e) => e)
          .sort(function (a, b) {
            const fechaA = b.hora;

            const fechaB = a.hora;
            if (fechaA > fechaB) {
              return -1;
            }
            if (fechaA < fechaB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
      } else if (idf == 4 && condition == false) {
        return info
          .filter((e) => e)
          .sort(function (a, b) {
            const fechaA = a.hora;

            const fechaB = b.hora;
            if (fechaA > fechaB) {
              return -1;
            }
            if (fechaA < fechaB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
      } else {
        return info.filter((e) => e);
      }
    })
    .catch((err) => console.error(err));
}

export { promise_one, promise_two, promise_three };
